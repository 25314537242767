import { FormatCode } from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/beatSettings/trackFiles/enums/formatCode'
import {
    FormatCodeKey,
    Mp3CodeValues,
} from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/beatSettings/trackFiles/types/types'

export const defineFormat = (hexString: string): FormatCodeKey | unknown => {
    if (hexString.startsWith(FormatCode.zip)) {
        return 'zip'
    }

    const mp3Codes = Object.values(FormatCode.mp3) as Mp3CodeValues[]

    if (mp3Codes.some((code) => hexString.startsWith(code))) {
        return 'mp3'
    }
    if (hexString.startsWith(FormatCode.rar)) {
        return 'rar'
    }
    if (hexString.startsWith(FormatCode['7z'])) {
        return '7z'
    }
    if (hexString.startsWith(FormatCode.wav)) {
        return 'wav'
    }
    return 'unknown'
}
