import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import React, { useEffect } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { LanguageType } from '../../translations/ru/common'
import { FormType, renderForm } from './form-utility'
import { useHistory, useParams } from 'react-router'
import { Redirect } from 'react-router-dom'
import { useEditingEntityStatus } from '../common/hooks/useEditingEntityStatus'
import { EditingEntityStatuses, RequestStatuses } from '../../BLL/AppReducer'
import { useSelector } from 'react-redux'
import { AppState } from '../../BLL/Store'
import { AppSettings } from 'settings/appSettings'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minWidth: '350px',
            paddingBottom: 20,
        },
        input: {
            display: 'none',
        },
        wrapper: {
            margin: 20,
        },
    })
)

interface IProps<ItemUpdateModel> {
    formData: FormType<ItemUpdateModel>
    handleUpdateItem: (id: string, values: ItemUpdateModel) => void
    status?: string
    loadItem: (id: string) => void
    item: ItemUpdateModel | null
    formTitle: string
    cancelRedirectUrl: string
    clear: () => void
    renderComponent?: React.ReactNode
}

type OwnProps<ItemUpdateModel> = IProps<ItemUpdateModel>

function EditingItemPopUp<ItemUpdateModel>(props: OwnProps<ItemUpdateModel>) {
    const classes = useStyles()
    let {
        handleUpdateItem,
        formData,
        loadItem,
        item,
        cancelRedirectUrl,
        formTitle,
        clear,
        renderComponent,
    } = props

    const { i18n } = useTranslation()
    const { adminExpertsCompilation, buttons } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType
    const { handleSubmit, register, control } = useForm<ItemUpdateModel>()

    const { id } = useParams<{ id: string }>()
    const history = useHistory()
    const { editingEntityStatus } = useEditingEntityStatus(id)

    const { requestStatus } = useSelector((state: AppState) => state.appReducer)

    useEffect(() => {
        if (id) {
            loadItem(id)
        }
        return () => clear()
    }, [id])

    const submitForm = handleSubmit((values) => {
        if (item && id) {
            handleUpdateItem(id, values as ItemUpdateModel)
        }
    })

    if (editingEntityStatus === EditingEntityStatuses.Success) {
        return <Redirect to={cancelRedirectUrl} />
    }

    return (
        <div>
            <Dialog open={true} aria-labelledby="form-dialog-title">
                {item ? (
                    <form>
                        <DialogTitle id="form-dialog-title">
                            {formTitle}
                        </DialogTitle>
                        <div className={classes.root}>
                            {renderForm<ItemUpdateModel>({
                                formData,
                                register,
                                // errors,
                                item,
                                control,
                            })}
                            <div className={classes.wrapper}>
                                {renderComponent}
                            </div>
                        </div>
                        <DialogActions>
                            <Button
                                style={{
                                    background: AppSettings.api.getBaseColor(),
                                    color: 'black',
                                    opacity:
                                        requestStatus ===
                                        RequestStatuses.InProgress
                                            ? 0.3
                                            : 0.9,
                                    fontWeight: 600,
                                }}
                                onClick={submitForm}
                                disabled={
                                    requestStatus === RequestStatuses.InProgress
                                }
                            >
                                {requestStatus === RequestStatuses.InProgress
                                    ? adminExpertsCompilation.loading
                                    : buttons.SAVE}
                            </Button>

                            <Button
                                style={{ color: 'black' }}
                                disabled={
                                    requestStatus === RequestStatuses.InProgress
                                }
                                onClick={() => history.push(cancelRedirectUrl)}
                            >
                                {buttons.CANCEL}
                            </Button>
                        </DialogActions>
                    </form>
                ) : (
                    <div style={{ padding: 20 }}>
                        {adminExpertsCompilation.loading}
                    </div>
                )}
            </Dialog>
        </div>
    )
}

export default EditingItemPopUp
