import { axiosCore } from './createAxios'
import { BaseResponse, NullableType } from 'BLL/types'

export const BeatmakerAPI = {
    prefixURL: '/BeatsManagement/BeatsManagement',

    getBeatmakerInfo() {
        return axiosCore
            .get<BeatmakerInfoType>(`${this.prefixURL}/GetBeatmakerInfo`)
            .then((r) => r.data)
    },

    updateBeatmakerProfile(dto: UpdateBeatmakerInfoDTO) {
        return axiosCore
            .put<BaseResponse<BeatmakerInfoType>>(
                `${this.prefixURL}/BeatmakerInfo`,
                dto
            )
            .then((r) => r.data)
    },
}

export type BeatmakerInfoType = {
    activeBlock: boolean
    addedDate: Date
    beatsInCatalogCount: number
    contacts: BeatmakerContactsType
    country: BeatmakerCountryType
    daysAfterLastLoadingBeatToCatalog: number
    doMastering: boolean
    domain: NullableType<string>
    engMode: boolean
    id: number
    isApproved: boolean
    isLocked: boolean
    isNewSchema: boolean
    lastCatalogBeatLoaded: Date
    name: string
    note: string
    parentBeatmakerId: number
    paypalCode: NullableType<number>
    rappersList: string
    rating: number
    ratingPosition: number
    uniqueUrlName: string
    userId: number
    userName: string
    vkontaktePage: string
}

export type BeatmakerContactsType = {
    email: string
    facebook: string
    googlePlus: string
    icq: string
    instagram: string
    twitch: string
    skype: string
    soundcloud: string
    telegram: string
    twitter: string
    viber: string
    vk: string
    whatsApp: string
    youtube: string
}

export type BeatmakerCountryType = {
    id: number
    name: string
    englishName: string
}

export type UpdateBeatmakerInfoDTO = {
    rappersList: string // rappersList
    software: string //
    nickname: string //uniqueUrlName
    aboutMe: string //note
    countryId: number
    contacts: BeatmakerContactsType
    hard?: string // этого нет на форме
    doMastering?: boolean // этого нет на форме
    uniqueUrlName?: string
}
