export const Mp3Codes = {
    code_1: '49443303',
    code_2: 'fffbe',
    code_3: 'fff3',
    code_4: 'fff2',
} as const

export const FormatCode = {
    zip: '504b',
    mp3: Mp3Codes,
    rar: '52617221',
    '7z': '377abcaf',
    wav: '52494646',
} as const
