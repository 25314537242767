import React, { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { LanguageType } from '../../../../../translations/ru/common'
import { BeatLicenseType } from '../../../../../DAL/Services'
import classes from './license-item.module.scss'
import Tooltip from '@material-ui/core/Tooltip'
import { NavLink } from 'react-router-dom'
import { addContracts } from '../../../../../BLL/ContractsReducer'
import { useDispatch } from 'react-redux'
import { LanguageEnum } from '../../../../../BLL/enums'
import { CustomerRM } from '../../../../routers/RouterManager'
import { BrowserView, deviceDetect, MobileView } from 'react-device-detect'
import { getLocaleDateString } from '../../../../utils/util-types'
import EditIcon from '../../../../assets/icons/edit.svg'
import DeleteIcon from '../../../../assets/icons/delete.svg'
import DargIcon from '../../../../assets/icons/drag-icon.svg'
import { DraggableProvided } from 'react-beautiful-dnd'
import { LicenseCard } from '../../../Mobile/MobLicenses/LicenseCard/LicenseCard'
import { CustomBrowserView } from '../../../../common/browserView/CustomBrowserView'
import { CustomMobileView } from '../../../../common/mobileView/CustomMobileView'

type PropsType = {
    license: BeatLicenseType
    provided: DraggableProvided
    isDragging: boolean
    isDrag: boolean
}

export const LicenseItem: FC<PropsType> = React.memo(
    ({ license, isDragging, provided, isDrag }) => {
        const dispatch = useDispatch()
        const { i18n } = useTranslation()
        const { buttons, licenses } = i18n.getResourceBundle(
            i18n.language,
            'common'
        ) as LanguageType

        const isEng = i18n.language === 'en'
        const lang = i18n.language as LanguageEnum

        const handleAddContract = useCallback(() => {
            dispatch(addContracts(lang, license._id))
        }, [dispatch, addContracts, license, lang])

        const isMobile = deviceDetect()?.isMobile

        return (
            <>
                <CustomBrowserView>
                    <div className={classes.wrapper}>
                        <div className={classes.title}>
                            <div
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={classes.dragElement}
                            >
                                {!isDrag && <img src={DargIcon} alt={'drag'} />}
                            </div>
                            {isEng ? license.titleEn : license.title}
                        </div>
                        <div className={classes.priceWrapper}>
                            <div className={classes.price}>
                                {!isEng && (
                                    <div className={classes.currency}>
                                        ₽ {license.price} /
                                    </div>
                                )}
                                <div className={classes.currency}>
                                    $ {license.priceDollar}
                                </div>
                            </div>
                        </div>
                        {license && (
                            <div className={classes.dateWrapper}>
                                {getLocaleDateString(
                                    i18n.language,
                                    license.createdDate
                                )}
                            </div>
                        )}
                        <div className={classes.actions}>
                            <Tooltip title={buttons.EDIT}>
                                <NavLink
                                    to={CustomerRM.contracts.getUrl.license.edit(
                                        license.id
                                    )}
                                >
                                    <img
                                        className={classes.icons}
                                        src={EditIcon}
                                        alt={'edit'}
                                    />
                                </NavLink>
                            </Tooltip>
                            <Tooltip title={buttons.DELETE}>
                                <NavLink
                                    to={CustomerRM.contracts.getUrl.license.delete(
                                        license.id
                                    )}
                                >
                                    <img
                                        className={classes.icons}
                                        src={DeleteIcon}
                                        alt={'delete'}
                                    />
                                </NavLink>
                            </Tooltip>
                        </div>
                        {/*) : (*/}
                        {/*    <MobileMenuLicenseItem license={license} />*/}
                        {/*)}*/}
                    </div>
                </CustomBrowserView>
                <CustomMobileView>
                    <div className={classes.Mobile}>
                        <LicenseCard
                            license={license}
                            provided={provided}
                            isDragging={isDragging}
                        />
                    </div>
                </CustomMobileView>
            </>
        )
    }
)
