import React, { useCallback } from 'react'
import { CustomBrowserView } from 'UI/common/browserView/CustomBrowserView'
import { Pagination } from 'UI/common/elements/pagination/Pagination'
import { TableHeader } from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/draggableTable/TableHeader'
import { MyBeatType } from 'DAL/BeatSettingsAPI'
import { ArchiveTableItem } from 'UI/CustomerAdmin/MyBeatsPage/Archive/archiveTableItem/ArchiveTableItem'

type Props = {
    totalCount: number
    pageSize: number
    page: number
    beats: MyBeatType[]
    ChangePageSize: (pageSize: number) => void
    ChangePage: (page: number) => void
}

export const ArchiveTable = ({
    totalCount,
    pageSize,
    page,
    beats,
    ChangePageSize,
    ChangePage,
}: Props) => {
    const handleChangePageSize = useCallback(
        (pageSize: number) => {
            ChangePageSize(pageSize)
        },
        [page]
    )

    const handleChangePage = useCallback(
        (page: number) => {
            ChangePage(page)
        },
        [pageSize]
    )

    return (
        <>
            <CustomBrowserView>
                <TableHeader />
            </CustomBrowserView>
            <ArchiveTableItem beats={beats} />
            <Pagination
                totalCount={totalCount}
                onChangePage={handleChangePage}
                currentPage={page}
                onChangeRowsPerPage={handleChangePageSize}
                pageSize={pageSize}
            />
        </>
    )
}
