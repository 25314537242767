export type MBActionType<P> = {
    type: string
    source?: Window // от кого пришёл экшн
    payload: P
}

export type SubscriberType<P> = {
    type: string
    callback: (action: MBActionType<P>) => void
    source?: Window[] // от кого можно принимать экшэны, undefined - от всех
}

export abstract class MessageBusAbstract<S> {
    abstract subscribe<P>(
        type: string,
        callback: (action: MBActionType<P>) => void,
        source?: S[]
    ): void

    abstract send<P>(action: MBActionType<P>): void
}

export class MessageBus extends MessageBusAbstract<Window> {
    subscribers: SubscriberType<any>[] = []
    isIframe = false
    sources: Window[] = []

    constructor(public origins?: string[]) {
        super()

        if (window.parent !== window) {
            // если iframe - подписаться на хоста
            this.isIframe = true
            window.parent.postMessage(
                JSON.stringify({
                    type: 'subscribe',
                }),
                '*'
            )
        } else {
            // иначе - ожидать подписчиков
            this.subscribers.push({
                type: 'subscribe',
                callback: (action) => {
                    action.source && this.sources.push(action.source)
                },
            })
        }

        window.addEventListener('message', (message) => {
            // проверка разрешённых iframe
            if (
                this.origins &&
                !this.origins.some((o) => o === message.origin)
            ) {
                return
            }

            // формирование экшена
            let source: Window | undefined = undefined
            // if (message.source instanceof Window)
            source = <Window>message.source
            try {
                const data: MBActionType<any> = {
                    ...JSON.parse(message.data),
                    source,
                }
                this.send(data)
            } catch (e) {
                //fuck
            }
        })
    }

    addTarget(window: Window) {
        this.sources.push(window)
    }

    subscribe<P>(
        type: string,
        callback: (action: MBActionType<P>) => void,
        source?: Window[]
    ) {
        let subscriberWrapper = { type, callback, source }
        this.subscribers.push(subscriberWrapper)

        // return unsubscribe function
        return () => {
            this.subscribers = this.subscribers.filter(
                (s) => s != subscriberWrapper
            )
        }
    }

    // unsubscribe

    send<P>(action: MBActionType<P>, target?: Window | null) {
        if (target) {
            target.postMessage(
                JSON.stringify({
                    type: action.type,
                    payload: action.payload,
                }),
                '*'
            )
        }

        // локальный редьюсер
        this.subscribers.forEach((s) => {
            if (
                (!s.source || s.source.some((ss) => ss === action.source)) && //
                action.type === s.type
            )
                s.callback(action)
        })

        // если не iframe - отправить всем подписчикам, кроме отправителя
        if (!this.isIframe) {
            this.sources.forEach((source) => {
                if (source !== action.source)
                    source.postMessage(
                        JSON.stringify({
                            type: action.type,
                            payload: action.payload,
                        }),
                        '*'
                    )
            })
        } else {
            // иначе - отправить хосту
            if (action.source !== window.parent) {
                window.parent.postMessage(
                    JSON.stringify({
                        type: action.type,
                        payload: action.payload,
                    }),
                    '*'
                )
            }
        }
    }
}
