import React, { FC } from 'react'
import classes from './inputs.module.scss'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'

type PropsType = {
    onClick: () => void
} & React.InputHTMLAttributes<HTMLInputElement>

export const CopyInput: FC<PropsType> = ({ onClick, ...props }) => {
    const { i18n } = useTranslation()
    const { buttons } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType
    return (
        <div className={classes.wrapperCopy}>
            <input
                type="text"
                className={`${classes.Input} ${classes.copy} ${
                    props.disabled && classes.disabled
                }`}
                {...props}
            />
            <button
                type="button"
                className={
                    !props.disabled
                        ? classes.copyButton
                        : `${classes.copyButton} ${classes.disabled}`
                }
                onClick={onClick}
            >
                {buttons.COPY}
            </button>
        </div>
    )
}
