import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { LanguageType } from 'translations/ru/common'
import { deleteDiscount } from 'BLL/CustomerDiscountsReducer'
import { Button } from 'UI/common/elements/buttons/Button'
import { CustomerRM } from 'UI/routers/RouterManager'
import s from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/beatspage.module.scss'

export const DeleteCoupon = () => {
    const dispatch = useDispatch()

    const { i18n } = useTranslation()
    const history = useHistory()
    const params = useParams<{ discountId: string }>()

    const { buttons, discounts } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    const handleDeleteDiscount = useCallback(() => {
        params.discountId && dispatch(deleteDiscount(params.discountId))
        history.push(CustomerRM.discounts.path.getUrl('coupons'))
    }, [params.discountId])
    return (
        <div>
            <p>{discounts.deleteDialog.description}</p>
            <div className={s.buttonsPopUp}>
                <Button
                    view={'Secondary'}
                    title={buttons.CANCEL}
                    onClick={() =>
                        history.push(
                            CustomerRM.discounts.path.getUrl('coupons')
                        )
                    }
                />
                <Button
                    view={'Delete'}
                    title={buttons.DELETE}
                    onClick={handleDeleteDiscount}
                />
            </div>
        </div>
    )
}
