import Button, { ButtonProps } from '@material-ui/core/Button'
import React, { CSSProperties, FC } from 'react'
import { AppSettings } from 'settings/appSettings'

type PropsType = {
    color?: string
    variant?: 'contained' | 'outlined' | 'text'
    size?: 'small' | 'large'
    isRequest?: boolean
    onClick?: () => void
    title: string
    height?: number
    width?: string | number
    textColor?: string
    style?: CSSProperties
    fontSize?: number
}
export const CustomButton: FC<PropsType & ButtonProps> = ({
    color = AppSettings.api.getBaseColor(),
    variant = 'contained',
    size = 'small',
    isRequest,
    onClick = () => {},
    title,
    disabled,
    fontSize = 12,
    height = 38,
    width = 'auto',
    textColor = 'black',
    style = {
        background: !disabled ? color : '#c2c2c2',
        color: textColor,
        fontWeight: 700,
        height: height,
        opacity: isRequest ? 0.4 : 0.9,
        width: width,
        fontSize: fontSize,
    },
    ...restProps
}) => {
    return (
        <Button
            variant={variant}
            color="primary"
            size={size}
            style={style}
            onClick={onClick}
            disabled={isRequest || disabled}
            {...restProps}
        >
            {title}
        </Button>
    )
}
