import React, { Suspense, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { init } from 'BLL/AppReducer'
import { ColorLinearProgress } from 'UI/common/progressBars/ColorLinearProgress'
import { Container } from '@material-ui/core'
import { Players } from 'Player/UI/Players/Players'
import { Routers } from 'UI/routers/Routers'
import { Menu } from 'UI/common/menus/Menu'
import { useAuthHostToken } from 'libs/AuthToken/Consumer/AuthTokenHook'
import { AppSettings } from 'settings/appSettings'
import { Snackbar } from 'UI/common/snackbar/Snackbar'
import { Loader } from 'UI/common/progressBars/Loader'
import { useSelector } from 'UI/common/hooks/redux-hooks'
import { useLocation } from 'react-router-dom'
import { MobileMenuDrawer } from 'UI/CustomerAdmin/Mobile/MobileCommon/MobileMenu/MobileMenuDrawer'
import { BmCoreLoader } from 'UI/common/progressBars/bmcoreLoader/BmcoreLoader'
import { CustomBrowserView } from 'UI/common/browserView/CustomBrowserView'
import { CustomMobileView } from 'UI/common/mobileView/CustomMobileView'
import { BindingCardModal } from 'UI/common/bindingCardModal/BindingCardModal'
import { Portal } from 'UI/common/portal/Portal'
import 'app.scss'

export const AuthWrapper = () => {
    const [ready, setReady] = useState(false)
    const iframeRef = useRef<HTMLIFrameElement | null>(null)

    const tokenExists = useAuthHostToken(
        null,
        ready,
        iframeRef.current?.contentWindow
    )

    return (
        <>
            {!tokenExists ? <BmCoreLoader /> : <App />}
            <iframe
                style={{
                    width: '0px',
                    height: '0px',
                    position: 'absolute',
                    border: '0',
                }}
                sandbox="allow-same-origin allow-scripts allow-forms allow-popups allow-popups-to-escape-sandbox"
                ref={iframeRef}
                src={AppSettings.urls.CORE_AUTH_IFRAME_URL}
                onLoad={() => {
                    setReady(true)
                }}
            />
        </>
    )
}

const App = () => {
    const isInit = useSelector((state) => state.appReducer.isInit)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(init())
    }, [dispatch])

    const location = useLocation()

    if (!isInit) {
        return <Loader />
    }
    return (
        <>
            <Container maxWidth="lg">
                <div className="App">
                    {location.pathname !== '/mobile' && (
                        <div className={'menuWrapper'}>
                            <CustomBrowserView size={1140}>
                                <Menu />
                            </CustomBrowserView>
                            <CustomMobileView size={1140}>
                                <MobileMenuDrawer />
                            </CustomMobileView>
                        </div>
                    )}
                    <Suspense fallback={<ColorLinearProgress />}>
                        <Routers />
                    </Suspense>
                    <Loader />
                </div>
            </Container>
            <Players />
            <Snackbar />
            <Portal>
                <BindingCardModal />
            </Portal>
        </>
    )
}
