import React from 'react'
import { ButtonTooltip } from 'UI/common/elements/buttons/ButtonTooltip'
import { useSelector } from 'UI/common/hooks/redux-hooks'
import { LanguageType } from 'translations/ru/common'
import { selectCountry } from 'BLL/selectors/app-selectors'
import { Control } from 'react-hook-form'
import { BeatmakerFormValues } from 'UI/CustomerAdmin/BeatmakerSettingsNew/wallet-settings/wallets/enot/types'
import { ControlledTextField } from 'UI/common/controls/controlledTextField/ControlledTextField'
import { ControlledSelect } from 'UI/common/controls/controlledSelect/ControlledSelect'
import s from 'UI/CustomerAdmin/BeatmakerSettingsNew/profile-settings/settings-profile.module.scss'

type Props = {
    beatmakerSettings: Pick<
        LanguageType,
        'beatmakerSettings'
    >['beatmakerSettings']
    control: Control<BeatmakerFormValues>
    setValue: (name: string, value: string) => void
}
export const MainInfo = ({ beatmakerSettings, control, setValue }: Props) => {
    const countries = useSelector(selectCountry)
    return (
        <div className={s.general}>
            <h2 className={s.title}>{beatmakerSettings.fields.generalInfo}</h2>
            <div className={s.generalOptions}>
                <div className={s.generalItem}>
                    <ControlledTextField
                        name={'userId'}
                        control={control}
                        label={beatmakerSettings.fields.myId}
                        disabled={true}
                    />
                </div>
                <div className={s.generalItem}>
                    <div className={s.labelNickname}>
                        <label className={s.itemTitle}>
                            {beatmakerSettings.fields.nickname}
                            <ButtonTooltip>
                                {beatmakerSettings.nicknameTooltip.text}
                            </ButtonTooltip>
                        </label>
                    </div>
                    <ControlledTextField name={'name'} control={control} />
                </div>
                <div className={s.generalItem}>
                    <ControlledTextField
                        name={'uniqueUrlName'}
                        control={control}
                        label={beatmakerSettings.fields.uniqueUrlName}
                    />
                </div>
                <div className={`${s.generalItem} ${s.selectWrapper}`}>
                    <label className={s.itemTitle}>
                        {beatmakerSettings.fields.country}
                    </label>
                    <ControlledSelect
                        name={'country'}
                        control={control}
                        options={countries.map(({ id, title }) => ({
                            value: id,
                            label: title,
                        }))}
                        setValue={setValue}
                    />
                </div>
            </div>
        </div>
    )
}
