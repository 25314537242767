import { SubscriptionType } from 'BLL/types'
import { LanguageType } from 'translations/ru/common'
import { AppSettings } from 'settings/appSettings'
import { StatusSubscriptionEnum, SubscriptionPlansEnum } from 'BLL/enums'
import s from 'UI/ManagerAdmin/AdminSubscriptions/subscriptions.module.css'
import { formatDate } from 'BLL/SupportingFile'
import React from 'react'

import { i18n } from 'i18next'
import { TableType } from '../types'
export const getAdminSubscriptionsTableData = (
    i18n: i18n
): TableType<SubscriptionType> => {
    const { adminSubscription, subscriptionType, subscriptionStatus } =
        i18n.getResourceBundle(i18n.language, 'common') as LanguageType
    return [
        {
            title: adminSubscription.tableColumns.beatmaker,
            field: 'userName',
            render: (subscription) => (
                <div>
                    <a
                        href={`${AppSettings.urls.MAIN_DOMAIN}/user/${subscription.userId}`}
                        target={'_blank'}
                        rel="noreferrer"
                    >
                        {subscription.userName}
                    </a>
                </div>
            ),
            sorting: false,
        },
        {
            title: adminSubscription.tableColumns.email,
            field: 'email',
            sorting: false,
        },
        {
            title: adminSubscription.tableColumns.subscriptionType,
            field: 'subscriptionPlan',
            render: (subscription) => {
                switch (subscription.subscriptionPlan) {
                    case SubscriptionPlansEnum.Expert:
                        return (
                            <div>
                                {
                                    subscriptionType[
                                        subscription.subscriptionPlan
                                    ]
                                }
                            </div>
                        )
                    case SubscriptionPlansEnum.Free:
                        return (
                            <div>
                                {
                                    subscriptionType[
                                        subscription.subscriptionPlan
                                    ]
                                }
                            </div>
                        )
                    case SubscriptionPlansEnum.Premium:
                        return (
                            <div>
                                {
                                    subscriptionType[
                                        subscription.subscriptionPlan
                                    ]
                                }
                            </div>
                        )
                    default:
                        return <div />
                }
            },
        },
        {
            title: adminSubscription.tableColumns.status,
            field: 'status',
            render: (subscription) => {
                return subscription.status ===
                    StatusSubscriptionEnum.Expired ? (
                    <div>
                        <span className={s.red}>
                            {subscriptionStatus[StatusSubscriptionEnum.Expired]}
                        </span>
                    </div>
                ) : (
                    <div>
                        {subscriptionStatus[StatusSubscriptionEnum.Active]}
                    </div>
                )
            },
            customSort: (a: SubscriptionType, b: SubscriptionType) =>
                new Date(b.expirationDate).getTime() -
                new Date(a.expirationDate).getTime(),
        },
        {
            title: adminSubscription.tableColumns.expirationDate,
            field: 'expirationDate',
            render: (rowData) => {
                return (
                    <div>
                        {rowData.expirationDate &&
                            formatDate(rowData.expirationDate)}
                    </div>
                )
            },
            customSort: (a: SubscriptionType, b: SubscriptionType) =>
                new Date(b.expirationDate).getTime() -
                new Date(a.expirationDate).getTime(),
        },
        {
            title: adminSubscription.tableColumns.lastSendingDate,
            field: 'emailData',
            render: (subscription) => (
                <div>
                    {' '}
                    {subscription.emailData.lastSendingDate &&
                        formatDate(subscription.emailData.lastSendingDate)}{' '}
                </div>
            ),
            sorting: false,
        },
        {
            title: adminSubscription.tableColumns.nextSendingDate,
            field: 'emailData',
            render: (subscription) => (
                <div>
                    {' '}
                    {subscription.emailData.nextSendingDate &&
                        formatDate(subscription.emailData.nextSendingDate)}{' '}
                </div>
            ),
            sorting: false,
        },
    ]
}
