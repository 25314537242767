import { emailMarketingAxios } from './createAxios'
import { EmailsHistoryType, GetSubscriptionsDataType } from '../BLL/types'
import { SubscriptionPlansEnum } from '../BLL/enums'

const subscriptionsAPI = {
    getSubscriptions(
        page: number,
        pageSize: number,
        plan: SubscriptionPlansEnum | null
    ) {
        return emailMarketingAxios
            .get<GetSubscriptionsDataType>(
                `subscriptions?page=${page}&pageSize=${pageSize}&plan=${plan}`
            )
            .then((res) => res.data)
    },

    getEmailsHistory(userId: number, templateKey: string) {
        return emailMarketingAxios
            .get<EmailsHistoryType[]>(
                `emails/history?userId=${userId}&templateKey=${templateKey}`
            )
            .then((r) => r.data)
    },
}

export default subscriptionsAPI
