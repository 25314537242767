import React, { CSSProperties, FC, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import { useTranslation } from 'react-i18next'
import { LanguageType } from '../../translations/ru/common'
import noImageEng from '../assets/image/noImageEng.png'
import noImage from '../assets/image/no_image.jpg'
import { AppSettings } from 'settings/appSettings'

interface IProps {
    imageFile: string
    setImageFile: (image: string | any) => void
    currentItem?: any
    title?: string
    style?: CSSProperties
    handlerFormData?: (formData: any) => void
}

export const RenderUploadPhoto: FC<IProps> = ({
    imageFile,
    setImageFile,
    handlerFormData,
    currentItem,
    title,
    style = {
        background: AppSettings.api.getBaseColor(),
        color: 'black',
        opacity: 0.8,
        fontWeight: 600,
    },
}) => {
    const handleSelectImage = async (event: any) => {
        // const imageBase64 = await image2base64(URL.createObjectURL(event.target.files[0]))
        const newFile = event.target.files && event.target.files[0]
        const formData = new FormData()
        formData.append('file', newFile)
        handlerFormData && handlerFormData(formData)
        const reader = new FileReader()
        reader.readAsDataURL(newFile)
        reader.onloadend = () => {
            setImageFile(reader.result)
        }
    }

    useEffect(() => {
        if (currentItem) {
            setImageFile(currentItem.image)
        }
    }, [currentItem, setImageFile])
    const { i18n } = useTranslation()
    const { adminExpertsCompilation } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType
    const buttonTitle = title ? title : adminExpertsCompilation.uploadPhoto

    return (
        <div>
            <input
                accept="image/*"
                id={'photo'}
                style={{ display: 'none' }}
                multiple
                type="file"
                onChange={handleSelectImage}
            />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <div style={{ maxWidth: '100%', position: 'relative' }}>
                    <img
                        style={{
                            width: 200,
                            height: 200,
                            position: 'relative',
                        }}
                        src={
                            imageFile
                                ? imageFile
                                : i18n.language === 'ru'
                                ? noImage
                                : noImageEng
                        }
                        alt={''}
                    />
                </div>
                <label htmlFor="photo">
                    <Button
                        variant="contained"
                        color="primary"
                        style={style}
                        component="span"
                    >
                        {buttonTitle}
                    </Button>
                </label>
            </div>
        </div>
    )
}
