import { InferActionsTypes } from './Store'
import { commonAsyncHandler, completeOperation } from './common-async-handler'
import { CashbackSettingsType, settingsAPI } from '../DAL/SettingsAPI'
import { NullableType, ThunkActionType } from './types'
import { AuthActionsTypes } from './AppReducer'
import {
    BeatmakerAPI,
    BeatmakerInfoType,
    UpdateBeatmakerInfoDTO,
} from '../DAL/BeatmakerAPI'
import { NotificationsAPI, SubscriptionNotifyType } from 'DAL/NotificationsAPI'

export type SettingsType = {
    cashback: {
        isActive: boolean
    }
}

const initState = {
    settings: {
        cashback: {
            isActive: false,
        } as CashbackSettingsType,
    },
    beatmakerInfo: null as NullableType<BeatmakerInfoType>,
    subscriptions: [] as SubscriptionNotifyType[],
}

type StateType = typeof initState
export type ActionsTypes =
    | InferActionsTypes<typeof beatmakerActionsType>
    | AuthActionsTypes

const BeatmakerSettingsReducer = (
    state = initState,
    action: ActionsTypes
): StateType => {
    switch (action.type) {
        case 'BEATMAKER_SETTINGS_REDUCER/SET_CASHBACK_SETTINGS':
            return {
                ...state,
                settings: { ...state.settings, cashback: action.settings },
            }
        case 'BEATMAKER_SETTINGS_REDUCER/SET_BEATMAKER_INFO':
            return {
                ...state,
                beatmakerInfo: action.beatmakerInfo,
            }
        case 'BEATMAKER_SETTINGS_REDUCER/SET_BEATMAKER_SUBSCRIPTIONS':
            return {
                ...state,
                subscriptions: action.subscriptions,
            }
        default:
            return state
    }
}

export const beatmakerActionsType = {
    setCashbackSettings: (settings: CashbackSettingsType) =>
        ({
            type: 'BEATMAKER_SETTINGS_REDUCER/SET_CASHBACK_SETTINGS',
            settings,
        } as const),
    setBeatmakerInfo: (beatmakerInfo: NullableType<BeatmakerInfoType>) =>
        ({
            type: 'BEATMAKER_SETTINGS_REDUCER/SET_BEATMAKER_INFO',
            beatmakerInfo,
        } as const),
    setBeatmakerSubscriptions: (subscriptions: SubscriptionNotifyType[]) =>
        ({
            type: 'BEATMAKER_SETTINGS_REDUCER/SET_BEATMAKER_SUBSCRIPTIONS',
            subscriptions,
        } as const),
}

export const getBeatmakerInfo =
    (): ThunkActionType<ActionsTypes> => async (dispatch, getState) => {
        await commonAsyncHandler(async () => {
            const beatmakerInfo = await BeatmakerAPI.getBeatmakerInfo()
            dispatch(beatmakerActionsType.setBeatmakerInfo(beatmakerInfo))
            return beatmakerInfo
        }, dispatch)
    }

export const updateBeatmakerProfile =
    (dto: UpdateBeatmakerInfoDTO): ThunkActionType<ActionsTypes> =>
    async (dispatch, getState) => {
        await commonAsyncHandler(async () => {
            const beatmakerInfo = await BeatmakerAPI.updateBeatmakerProfile(dto)
            completeOperation(beatmakerInfo, dispatch)
            return beatmakerInfo
        }, dispatch)
    }

export const getSubscriptionsNotify =
    (): ThunkActionType<ActionsTypes> => async (dispatch, getState) => {
        await commonAsyncHandler(async () => {
            const subscriptions =
                await NotificationsAPI.getSubscriptionsNotify()
            dispatch(
                beatmakerActionsType.setBeatmakerSubscriptions(subscriptions)
            )
            return subscriptions
        }, dispatch)
    }
export const updateSubscriptionsNotify =
    (subscriptions: SubscriptionNotifyType[]): ThunkActionType<ActionsTypes> =>
    async (dispatch, getState) => {
        await commonAsyncHandler(async () => {
            const response = await NotificationsAPI.updateSubscriptionsNotify(
                subscriptions
            )
            completeOperation(response, dispatch)
            return subscriptions
        }, dispatch)
    }

export const getCashbackSettings =
    (): ThunkActionType<ActionsTypes> => async (dispatch) => {
        await commonAsyncHandler(async () => {
            const cashbackSettings = await settingsAPI.getCashbackSettings()
            dispatch(beatmakerActionsType.setCashbackSettings(cashbackSettings))
            return cashbackSettings
        }, dispatch)
    }

export const updateCashbackSettings =
    (settings: SettingsType): ThunkActionType<ActionsTypes> =>
    async (dispatch) => {
        await commonAsyncHandler(async () => {
            const response = await settingsAPI.updateCashbackSettings(settings)
            completeOperation(response, dispatch, () =>
                dispatch(
                    beatmakerActionsType.setCashbackSettings(settings.cashback)
                )
            )
            return response
        }, dispatch)
    }

export default BeatmakerSettingsReducer
