import { useSelector } from 'UI/common/hooks/redux-hooks'
import {
    getEnotApiSecretKey,
    getEnotNumber,
    getEnotSecret,
    getHtmlEnotFileName,
} from 'BLL/selectors/wallet-selectors'
import { useDispatch } from 'react-redux'

export const useEnotWalletData = () => {
    const enotNumber = useSelector(getEnotNumber)
    const enotSecret = useSelector(getEnotSecret)
    const enotApiSecretKey = useSelector(getEnotApiSecretKey)
    const htmlEnotFileName = useSelector(getHtmlEnotFileName)
    const dispatch = useDispatch()

    return {
        enotNumber,
        enotSecret,
        enotApiSecretKey,
        htmlEnotFileName,
        dispatch,
    }
}
