import axios from 'axios'
import { addTokenToHeaders } from '../libs/AuthToken/AuthUtils'
import { AppSettings } from 'settings/appSettings'

let handle401: any = null

export const apiCoreAxios = axios.create({
    baseURL: AppSettings.urls.API_BASE_URL,
})
export const adminBeatsAxios = axios.create({
    baseURL: AppSettings.urls.CORE_BASE_URL,
    withCredentials: true,
})
export const shopsAxiosAPI = axios.create({
    baseURL: AppSettings.urls.SHOP_BASE_URL,
})
export const beatsPromotionAxios = axios.create({
    baseURL: AppSettings.urls.beatsPromotionApi,
})

export const axiosCore = axios.create({
    baseURL: AppSettings.urls.CORE_BASE_URL,
    withCredentials: true,
})

export const subscribersAxios = axios.create({
    baseURL: AppSettings.urls.PRODS_PAGE_API_URL,
})

export const beatsCompilationAxios = axios.create({
    baseURL: AppSettings.urls.beatsPromotionApi,
})

export const emailMarketingAxios = axios.create({
    baseURL: AppSettings.urls.EMAIL_MARKETING_API_URL,
})

export const contractsAxios = axios.create({
    baseURL: AppSettings.urls.beatsPromotionApi,
})

export const paymentsAxios = axios.create({
    baseURL: AppSettings.urls.CORE_BASE_URL + 'local-api/v1/manager',
    withCredentials: true,
})

export const widgetsAxios = axios.create({
    baseURL: AppSettings.urls.WIDGET_BASE_URL,
})

async function setLangHeader(config: any) {
    const language = await AppSettings.api.getLanguage()
    if (language) {
        config.headers['lang'] = language
    }
}

const setInterceptors = (instance: any) => {
    instance.interceptors.request.use(
        async (config: any) => {
            await addTokenToHeaders(config, 'user-token')
            await setLangHeader(config)
            return config
        },
        (error: any) => Promise.reject(error)
    )

    instance.interceptors.response.use(
        (response: any) => response,
        (error: any) => {
            /* if (error.response.status === 401 && !!handle401) {
                return getToken().then(() => {
                    setAuthHeader(error.config)
                    return axios.request(error.config)
                })
            }*/
            return Promise.reject(error)
        }
    )
}

setInterceptors(beatsPromotionAxios)
setInterceptors(adminBeatsAxios)
setInterceptors(apiCoreAxios)
setInterceptors(shopsAxiosAPI)
setInterceptors(axiosCore)
setInterceptors(subscribersAxios)
setInterceptors(beatsCompilationAxios)
setInterceptors(emailMarketingAxios)
setInterceptors(contractsAxios)
setInterceptors(widgetsAxios)

export const set401handler = (callback: any) => {
    handle401 = callback
}
