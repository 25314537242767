import React from 'react'
import Box from '@mui/material/Box'

type Props = {
    children?: React.ReactNode
    index: string
    value: string
}

export const TabPanel = ({ children, value, index }: Props) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
        >
            {value === index && <Box p={0}>{children}</Box>}
        </div>
    )
}
