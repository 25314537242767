import { StatusExpertEnum } from 'BLL/enums'
import { ExpertType } from 'BLL/types'
import { LanguageType } from 'translations/ru/common'
import { AppSettings } from 'settings/appSettings'
import ChangeStatusExpert from 'UI/ManagerAdmin/AdminExpertsCompilations/ChangeStatusExpert'
import { formatDate } from 'BLL/SupportingFile'
import Tooltip from '@material-ui/core/Tooltip'
import { NavLink } from 'react-router-dom'
import Fab from '@material-ui/core/Fab'
import SettingsApplication from '@material-ui/icons/SettingsApplications'
import React from 'react'
import { TableType } from '../types'
import { i18n } from 'i18next'

export const getExpertsTableData = (
    i18n: i18n,
    handleChangeStatusExpert: (status: StatusExpertEnum, id: string) => void,
    expertStatusUpdate: string,
    statusUpdate: string
): TableType<ExpertType> => {
    const { adminExpertsCompilation } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType
    return [
        {
            title: adminExpertsCompilation.tableColumns.beatmakerId,
            field: 'beatmakerId',
            render: (rowData: ExpertType) => (
                <div>
                    <a
                        href={`${AppSettings.urls.MAIN_DOMAIN}/user/${rowData.beatmakerId}`}
                        target={'_blank'}
                        rel="noreferrer"
                    >
                        {rowData.beatmakerId}
                    </a>
                </div>
            ),
        },
        {
            title: adminExpertsCompilation.tableColumns.name,
            field: 'beatmakerName',
        },
        {
            title: adminExpertsCompilation.tableColumns.status,
            field: 'status',
            render: (rowData: ExpertType) => (
                <div>
                    <ChangeStatusExpert
                        statusUpdate={statusUpdate}
                        status={rowData.status}
                        id={rowData._id}
                        changeStatusExpert={handleChangeStatusExpert}
                        expertStatusUpdate={expertStatusUpdate}
                    />
                </div>
            ),
        },
        {
            title: adminExpertsCompilation.tableColumns.lastCompilationDate,
            field: 'lastCompilationDate',
            render: (rowData: ExpertType) => (
                <div>
                    {' '}
                    {rowData.lastCompilationDate &&
                        formatDate(rowData.lastCompilationDate)}{' '}
                </div>
            ),
        },
        {
            title: '',
            field: 'description',
            render: (rowData: ExpertType) => (
                <div>
                    <Tooltip title={adminExpertsCompilation.edit}>
                        <NavLink
                            to={`/admin/expertsCompilations/${rowData._id}`}
                        >
                            <Fab
                                size="small"
                                color="primary"
                                style={{
                                    background: '#1f1f1f',
                                    color: AppSettings.api.getBaseColor(),
                                }}
                            >
                                <SettingsApplication />
                            </Fab>
                        </NavLink>
                    </Tooltip>
                </div>
            ),
            sorting: false,
        },
    ]
}
