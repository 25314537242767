import React from 'react'
import { ButtonTooltip } from 'UI/common/elements/buttons/ButtonTooltip'
import { Button } from 'UI/common/elements/buttons/Button'
import { useSelector } from 'UI/common/hooks/redux-hooks'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'
import { AppSettings } from 'settings/appSettings'
import { NewTextField } from 'UI/common/elements/textFields/newtextField/NewTextField'
import {
    selectBalance,
    selectRedirectURL,
} from 'BLL/selectors/coins-selectors/selectCoins'
import { BalanceCount } from 'UI/CustomerAdmin/CustomerCoins/enums'
import s from 'UI/CustomerAdmin/Mobile/MobCoinsPage/mobCoinsPage.module.scss'

type Props = {
    handlePaidCoins: () => void
    handleSetCoins: (value: string) => void
    coinsCount: number
    bonusCoins: number
    money: number
    sale: number
}

export const MobCoinsPage = ({
    coinsCount,
    bonusCoins,
    handlePaidCoins,
    handleSetCoins,
    money,
    sale,
}: Props) => {
    const redirectUrl = useSelector(selectRedirectURL)
    const balance = useSelector(selectBalance)

    const { i18n } = useTranslation()
    const { myCoins, buttons } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType
    const isEng = AppSettings.api.isEngVersion()

    if (redirectUrl) {
        window.location.href = redirectUrl
    }
    return (
        <div className={s.MobCoinsPage}>
            <div className={s.header}>
                <div className={s.item}>
                    <div className={s.tooltipBox}>
                        <ButtonTooltip>
                            <ul>
                                {Object.values(myCoins.bonusItems).map(
                                    (value) => (
                                        <li key={value}>{value}</li>
                                    )
                                )}
                            </ul>
                        </ButtonTooltip>
                        <label className={s.descr}>{myCoins.bonusCoins}</label>
                    </div>
                    <span className={s.value}>{bonusCoins}</span>
                </div>
                <div className={s.item}>
                    <label className={s.descr}>{myCoins.coinsBalance}</label>
                    <span className={s.value}>
                        {balance?.coins || BalanceCount.Zero}
                    </span>
                </div>
            </div>
            <div className={s.calculate}>
                <div className={s.conversion}>
                    <div className={s.size}>
                        <NewTextField
                            label={myCoins.coins}
                            value={coinsCount}
                            onValueChange={handleSetCoins}
                        />
                    </div>
                </div>
                <div className={s.conversion}>
                    <div className={s.size}>
                        <NewTextField
                            value={money}
                            disabled={true}
                            label={isEng ? 'USD' : 'RUB'}
                        />
                    </div>
                </div>
            </div>
            {!!sale && (
                <div className={s.sale}>
                    {myCoins.coinsSale} {sale}%
                </div>
            )}
            <div className={s.btnBox}>
                <Button
                    view={'Primary'}
                    title={buttons.BUY_COINS}
                    onClick={handlePaidCoins}
                />
            </div>
        </div>
    )
}
