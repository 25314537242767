import React, { FC } from 'react'
import classes from './buttons.module.scss'

type PropsType = {
    title: string
    id: string
    onChange: (e: any) => void
}
export const BrowseButtonOutline: FC<PropsType> = ({ title, onChange, id }) => {
    return (
        <div>
            <input
                accept={'image/*'}
                className={classes.inputButton}
                id={id}
                type="file"
                style={{ display: 'none' }}
                onChange={onChange}
            />
            <label htmlFor={id}>
                <div className={`${classes.browseButtonOutline}`}>{title}</div>
            </label>
        </div>
    )
}
