import { InferActionsTypes } from './Store'
import { AuthActionsTypes, actions as appActions } from './AppReducer'
import { NullableType, ThunkActionType } from './types'
import { commonAsyncHandler, completeOperation } from './common-async-handler'
import {
    ProdPageSettingsSTO,
    ProducerAPI,
    ProducerProfile,
    ProducerSettings,
    ProducerSettingsType,
} from 'DAL/ProducerAPI'

const initState = {
    profile: null as NullableType<ProducerProfile>,
    settings: null as NullableType<ProducerSettings>,
}

type StateType = typeof initState

export type ActionsTypes =
    | InferActionsTypes<typeof producerPageActions>
    | AuthActionsTypes

export const producerPageReducer = (
    state = initState,
    action: ActionsTypes
): StateType => {
    switch (action.type) {
        case 'PRODUCER_PAGE_REDUCER/SET_SETTINGS':
            return { ...state, ...action.settings }
        case 'PRODUCER_PAGE_REDUCER/SET_CNAME':
            return {
                ...state,
                settings: state.settings
                    ? {
                          ...state.settings,
                          herokuCname: action.cname,
                          subdomain: action.subdomain,
                          domain: action.domain,
                      }
                    : null,
            }
        default:
            return state
    }
}

export const producerPageActions = {
    fetchedProducerPageSettingsDetails: (settings: ProducerSettingsType) =>
        ({
            type: 'PRODUCER_PAGE_REDUCER/SET_SETTINGS',
            settings,
        } as const),
    updateSettingsCname: (
        cname: string | null,
        subdomain: string,
        domain: string
    ) =>
        ({
            type: 'PRODUCER_PAGE_REDUCER/SET_CNAME',
            cname,
            subdomain,
            domain,
        } as const),
}

export const fetchedProducerPageSettingsDetails =
    (): ThunkActionType<ActionsTypes> => async (dispatch) => {
        await commonAsyncHandler(async () => {
            const response = await ProducerAPI.getSettingsDetails()
            dispatch(
                producerPageActions.fetchedProducerPageSettingsDetails(response)
            )
            return response
        }, dispatch)
    }
export const updateProducerPageSettings =
    (dto: ProdPageSettingsSTO): ThunkActionType<ActionsTypes> =>
    async (dispatch) => {
        await commonAsyncHandler(async () => {
            const response = await ProducerAPI.updateSettings(dto)
            completeOperation(response, dispatch, () => {
                dispatch(
                    producerPageActions.updateSettingsCname(
                        response.data.cname,
                        dto.subdomain,
                        dto.domain
                    )
                )
            })
            return response
        }, dispatch)
    }
