import { InferActionsTypes } from './Store'
import {
    actions as appActions,
    AuthActionsTypes,
    EditingEntityStatuses,
} from './AppReducer'
import { ResultCodesEnum, ThunkActionType } from './types'
import { shopAPI } from 'DAL/Services'
import { CouponType, ShopsResponseType, ShopTypes } from './shop-types'
import { commonAsyncHandler } from './common-async-handler'
import { CONSTANTS } from './SupportingFile'

const initState = {
    shop: null as ShopTypes | null,
    coupons: [] as CouponType[],
    searchShopField: '',
}

type IState = typeof initState
export type ActionsTypes = InferActionsTypes<typeof actions> | AuthActionsTypes

const ShopAdminReducer = (state = initState, action: ActionsTypes): IState => {
    switch (action.type) {
        case 'ShopAdminReducer/SET_COUPON_STATUS':
            return {
                ...state,
                coupons: state.coupons.map((coupon) => {
                    if (coupon.id === action.couponId) {
                        return { ...coupon, status: action.status }
                    } else {
                        return coupon
                    }
                }),
            }
        case 'ShopAdminReducer/SET_COUPONS':
            return {
                ...state,
                coupons: action.coupons,
            }
        case 'ShopAdminReducer/ShopAdminReducer/SET_SHOP':
            return {
                ...state,
                shop: action.shop,
            }

        default:
            return state
    }
}
export default ShopAdminReducer

const actions = {
    setShop: (shop: ShopTypes) =>
        ({ type: 'ShopAdminReducer/ShopAdminReducer/SET_SHOP', shop } as const),
    setCoupons: (coupons: CouponType[]) =>
        ({ type: 'ShopAdminReducer/SET_COUPONS', coupons } as const),
    setCouponStatus: (couponId: number, status: number) =>
        ({
            type: 'ShopAdminReducer/SET_COUPON_STATUS',
            couponId,
            status,
        } as const),
}

export const getMyShop =
    (): ThunkActionType<ActionsTypes> => async (dispatch) => {
        await commonAsyncHandler(async () => {
            const shop = await shopAPI.getMyShop()
            dispatch(actions.setShop(shop))
            return shop
        }, dispatch)
    }

export const getMyCoupons =
    (): ThunkActionType<ActionsTypes> => async (dispatch) => {
        await commonAsyncHandler(async () => {
            let coupons = await shopAPI.getMyCoupons()
            dispatch(actions.setCoupons(coupons))
            return coupons
        }, dispatch)
    }

export const updateCoupon =
    (
        couponId: number,
        note: string,
        action: 'approve' | 'reject' | 'refactor'
    ): ThunkActionType<ActionsTypes> =>
    async (dispatch) => {
        await commonAsyncHandler(async () => {
            let res: ShopsResponseType<{}> | null = null
            switch (action) {
                case 'approve': {
                    res = await shopAPI.approveCoupon(couponId)
                    break
                }
                case 'refactor': {
                    res = await shopAPI.refactorCoupon(couponId, note)
                    break
                }
                case 'reject': {
                    res = await shopAPI.rejectCoupon(couponId)
                    break
                }
            }
            if (res) {
                if (res.resultCode === ResultCodesEnum.Success) {
                    dispatch(actions.setCouponStatus(couponId, res.data.status))
                    dispatch(
                        appActions.setEditingEntityStatus(
                            EditingEntityStatuses.Success
                        )
                    )
                    dispatch(
                        appActions.showSnackbar(
                            'success',
                            CONSTANTS.SUCCESS_MESSAGE
                        )
                    )
                } else {
                    dispatch(appActions.showSnackbar('error', res.messages[0]))
                }
            }
            return res
        }, dispatch)
    }
