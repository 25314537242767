import React, { ReactNode } from 'react'
import { MessageBus } from './MessageBus'

export const MessageBusContext = React.createContext<MessageBus | null>(null)

export const MessageBusProvider: React.FC<{
    bus: MessageBus
    children: ReactNode
}> = ({ bus, children }) => {
    return (
        <MessageBusContext.Provider value={bus}>
            {children}
        </MessageBusContext.Provider>
    )
}
