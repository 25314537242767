import React, { useEffect, useState } from 'react'
import { useSelector } from '../../../common/hooks/redux-hooks'
import { PlayerLogicTrackType } from '../../../../Player/Domain/PlayerLogic'
import beatLogo from '../../../assets/image/bmstv-logo.svg'
import { usePlayer } from '../../../../Player/Hooks/Hooks'
import { useDispatch } from 'react-redux'

export const CompilationPlayer = React.memo(() => {
    const beats = useSelector((state) => state.customerBeatsCompilation.beats)
    const [playerBeatDTO, setPlayerBeatDTO] = useState<PlayerLogicTrackType>(
        {} as PlayerLogicTrackType
    )
    const dispatch = useDispatch()
    useEffect(() => {
        if (beats.length) {
            setPlayerBeatDTO({
                id: beats[0].id,
                artist: '',
                title: '',
                format: 'm3u8',
                url: beats[0].playUrl as string,
                type: 'beat',
                images: {
                    middle: beatLogo,
                    original: beatLogo,
                    thumbnail: beatLogo,
                },
            })
        }
    }, [beats.length])

    const [player, isPlayingMe] = usePlayer(false, playerBeatDTO)

    useEffect(() => {
        if (Object.keys(playerBeatDTO).length) player.play(playerBeatDTO)
    }, [playerBeatDTO])

    useEffect(() => {
        return () => {
            player.setPlayList([])
        }
    }, [])

    return <div />
})
