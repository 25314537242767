import { FormControl, InputLabel, Paper } from '@material-ui/core'
import React from 'react'
import { Controller } from 'react-hook-form'

interface IProps<ItemUpdateModel> {
    item: ItemUpdateModel
    formData: FormType<ItemUpdateModel>
    register: any
    // errors: any
    control: any
}

export function renderForm<ItemUpdateModel>({
    formData,
    register,
    // errors,
    item,
    control,
}: IProps<ItemUpdateModel>) {
    return formData.map((fel) => {
        const props = fel.props ? fel.props : {}
        const options = fel.options ? fel.options : {}
        // @ts-ignore
        return (
            <div key={fel.name}>
                <Paper style={{ padding: 10 }}>
                    <FormControl>
                        <InputLabel shrink htmlFor={fel.name}>
                            {fel.label}
                        </InputLabel>
                        <Controller<any>
                            //@ts-ignore
                            render={fel.component}
                            // as={fel.component}
                            id={fel.name}
                            control={control}
                            name={fel.name}
                            label={fel.label}
                            variant="outlined"
                            defaultValue={item[fel.name]}
                            inputRef={register({ required: props.required })}
                            {...options}
                            {...props}
                        />
                        {/*{errors[fel.name] && (*/}
                        {/*    <div*/}
                        {/*        style={{*/}
                        {/*            color: 'red',*/}
                        {/*            fontSize: 12,*/}
                        {/*            fontWeight: 'bold',*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        {props.errorMessage}*/}
                        {/*    </div>*/}
                        {/*)}*/}
                    </FormControl>
                </Paper>
            </div>
        )
    })
}

export type FormElementType<T> = {
    name: Extract<keyof T, string> //use Extract for get from ALL keys only keys that is string (may be also Symbol, number ...)
    label: string
    component: React.ElementType
    options?: { [key: string]: string | number | boolean }
    props?: { [key: string]: string | number | boolean }
}

export type FormType<T> = FormElementType<T>[]
