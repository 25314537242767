import { AppState } from 'BLL/Store'

export const selectRequestStatus = (state: AppState) =>
    state.appReducer.requestStatus

export const selectRoles = (state: AppState) => state.appReducer.roles

export const selectCountry = (state: AppState) => state.appReducer.countries

export const selectSnackbar = (state: AppState) => state.appReducer.snackbar

export const selectBeatMaker = (state: AppState) => state.appReducer.isBeatmaker

export const selectExpert = (state: AppState) =>
    state.customerBeatsCompilation.expert

export const selectIsOwner = (state: AppState) => state.appReducer.isOwner

export const selectUserId = (state: AppState) => state.appReducer.userId

export const selectMoods = (state: AppState) => state.appReducer.moods

export const selectStyles = (state: AppState) => state.appReducer.styles
