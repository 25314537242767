import React from 'react'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'
import Paper from '@material-ui/core/Paper'
import Table from '../../common/table/Table'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { PaymentTypeBLL } from 'BLL/MySalesReducer'
import { SaleType } from 'DAL/mySalesAPI'
import { getPaymentsDetailTableData } from 'UI/common/table/tableData/GetPaymentsDetailTableData'
import { TableType } from 'UI/common/table/types'

const useStyles = makeStyles(() =>
    createStyles({
        tableWrapper: {},
        cardWrapper: {
            display: 'flex',
        },
        card: {
            display: 'flex',
            minWidth: 400,
            maxWidth: 400,
            flexWrap: 'wrap',
        },
        beatmaker: {
            display: 'flex',
            borderBottom: '1px solid grey',
            marginBottom: 10,
            '& span': {
                paddingRight: 5,
            },
        },
        column: {
            display: 'flex',
            '& span': {
                paddingRight: 5,
            },
        },
        validation: {
            display: 'flex',
            '& div': {
                padding: 10,
            },
        },
    })
)

type Props = {
    payments: PaymentTypeBLL[]
    fields: TableType<PaymentTypeBLL>
}

export const Payments = ({ payments, fields }: Props) => {
    const { i18n } = useTranslation()
    const { paymentsAdmin } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType
    const classes = useStyles()
    return (
        <Paper>
            <div className={classes.tableWrapper}>
                <Table<PaymentTypeBLL>
                    items={payments}
                    title={paymentsAdmin.tableTitle}
                    fields={fields}
                    detailPanel={[
                        {
                            tooltip: '',
                            render: (payment: PaymentTypeBLL) => {
                                return (
                                    payment.salesByPayment &&
                                    Object.values(payment.salesByPayment).map(
                                        (sales, index) => (
                                            <div
                                                key={index}
                                                style={{ margin: 10 }}
                                            >
                                                <Table<SaleType>
                                                    items={sales}
                                                    fields={getPaymentsDetailTableData(
                                                        i18n
                                                    )}
                                                    title={''}
                                                    toolbar={false}
                                                />
                                            </div>
                                        )
                                    )
                                )
                            },
                        },
                    ]}
                />
            </div>
        </Paper>
    )
}
