import React, { forwardRef, useCallback } from 'react'
import { SnackbarContent, useSnackbar } from 'notistack'
import { useSelector } from '../hooks/redux-hooks'
import success from '../../assets/icons/success.svg'
import error from '../../assets/icons/error.svg'
import info from '../../assets/icons/info.svg'
import warning from '../../assets/icons/warning.svg'
import Close from '../../assets/icons/close-icon.svg'
import classes from './snackbar.module.scss'

export const SnackMessage = forwardRef<
    HTMLDivElement,
    { id: string | number; message: string | React.ReactNode }
>((props, ref) => {
    const { closeSnackbar } = useSnackbar()
    const snackbar = useSelector((state) => state.appReducer.snackbar)

    const handleDismiss = useCallback(() => {
        closeSnackbar(props.id)
    }, [props.id, closeSnackbar])

    const Icons: { [key: string]: string } = {
        error,
        success,
        info,
        warning,
    }
    return (
        <SnackbarContent ref={ref}>
            <div className={`${classes.Snackbar} ${classes[snackbar.variant]}`}>
                <div className={classes.content}>
                    <img src={Icons[snackbar.variant]} alt={'error'} />
                    <p> {props.message}</p>
                </div>
                <div className={classes.close}>
                    <img src={Close} alt={'error'} onClick={handleDismiss} />
                </div>
            </div>
        </SnackbarContent>
    )
})
