import { AppSettings } from 'settings/appSettings'

export type MenuItems = InstanceType<typeof MenuItem>

export class MenuItem {
    constructor(
        public title: string,
        public link: string,
        public isShow = true,
        public isOutSide = false,
        public icon?: string
    ) {
        if (AppSettings.api.DEV_SHOW_ALL_MENU_ITEMS) {
            this.isShow = true
        }
    }
}
