import React from 'react'
import { CouponType } from 'BLL/shop-types'
import Table from '../common/table/Table'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'
import { TableType } from 'UI/common/table/types'

type Props = {
    coupons: CouponType[]
    fields: TableType<CouponType>
}

const ShopCoupons = ({ coupons, fields }: Props) => {
    const { i18n } = useTranslation()
    const { shopCoupon } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    return (
        <Table<CouponType>
            items={coupons}
            search={true}
            searchText={shopCoupon.searchText}
            title={shopCoupon.tableTitle}
            fields={fields}
        />
    )
}

export default ShopCoupons
