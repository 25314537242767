import React, { useEffect } from 'react'
import Header from '../../common/header/Header'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'
import Shops from './Shops'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'BLL/Store'
import {
    actions as shopActions,
    addShop,
    changeStatusShop,
    getShopById,
    getShops,
    updateShop,
} from '../../../BLL/AdminShopsReducer'
import Button from '@material-ui/core/Button'
import { useHistory, NavLink, Route } from 'react-router-dom'
import { RequestStatuses } from 'BLL/AppReducer'
import ShopEditingPopUp from './ShopEditingPopUp'
import { EditingShopType, ShopStatus, ShopTypesFull } from 'BLL/shop-types'
import ChangeStatusShops from './ChangeStatusShops'
import Tooltip from '@material-ui/core/Tooltip'
import Fab from '@material-ui/core/Fab'
import SettingsApplication from '@material-ui/icons/SettingsApplications'
import { AppSettings } from 'settings/appSettings'
import { getShopsTableData } from 'UI/common/table/tableData/GetShopsTableData'
import { TableType } from 'UI/common/table/types'

const AdminShops = () => {
    const { i18n } = useTranslation()
    const history = useHistory()
    const { navigation, buttons, managerAdmin, helpMessage } =
        i18n.getResourceBundle(i18n.language, 'common') as LanguageType
    const { shops, editingShop, shopStatusUpdate, statusUpdate, newShopId } =
        useSelector((state: AppState) => state.managerShops)
    const { requestStatus } = useSelector((state: AppState) => state.appReducer)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getShops())
    }, [])

    const handleAddedShop = () => {
        dispatch(addShop())
    }

    const handleGetShopById = (shopId: number) => {
        dispatch(getShopById(shopId))
    }

    const handleUpdateShop = (shop: EditingShopType) => {
        dispatch(
            updateShop({
                ...shop,
                userId: shop.userId && +shop.userId,
                feePercent: shop.feePercent && +shop.feePercent,
            })
        )
    }

    const clear = () => {
        dispatch(shopActions.setEditingShop(null))
    }

    const handleChangeStatusShop = (status: ShopStatus, id: number) => {
        dispatch(changeStatusShop(status, id))
    }

    useEffect(() => {
        if (editingShop) {
            history.push(`/admin/shops/edit/${editingShop.id}`)
        }
    }, [editingShop])

    useEffect(() => {
        if (newShopId) {
            history.push(`/admin/shops/edit/${newShopId}`)
        }
        return () => {
            dispatch(shopActions.setNewShopId(null))
        }
    }, [newShopId])

    const fields: TableType<ShopTypesFull> = [
        ...getShopsTableData(i18n),
        {
            title: managerAdmin.tableColumns.status,
            field: 'status',
            render: (shop) => (
                <div>
                    <ChangeStatusShops
                        status={shop.status}
                        id={shop.id}
                        changeStatusShop={handleChangeStatusShop}
                        shopStatusUpdate={shopStatusUpdate}
                        statusUpdate={statusUpdate}
                    />
                </div>
            ),
        },
        {
            title: '',
            field: 'id',
            render: (shop) => (
                <div>
                    <Tooltip title={helpMessage.edit}>
                        <NavLink to={`/admin/shops/edit/${shop.id}`}>
                            <Fab
                                size="small"
                                color="primary"
                                style={{
                                    background: '#1f1f1f',
                                    color: AppSettings.api.getBaseColor(),
                                }}
                            >
                                <SettingsApplication />
                            </Fab>
                        </NavLink>
                    </Tooltip>
                </div>
            ),
            sorting: false,
        },
    ]

    return (
        <div>
            <Header title={navigation.adminShops} />
            <Route
                path={'/admin/shops/edit/:id'}
                render={() => (
                    <ShopEditingPopUp
                        handleGetShopById={handleGetShopById}
                        requestStatus={requestStatus}
                        cancelRedirectUrl={'/admin/shops'}
                        clear={clear}
                        handleUpdateShop={handleUpdateShop}
                    />
                )}
            />
            <div style={{ display: 'flex', marginTop: 20 }}>
                <Button
                    variant="contained"
                    color="primary"
                    size={'small'}
                    style={{
                        background: AppSettings.api.getBaseColor(),
                        color: 'black',
                        fontWeight: 700,
                        height: 38,
                        opacity:
                            requestStatus === RequestStatuses.InProgress
                                ? 0.4
                                : 0.9,
                    }}
                    onClick={handleAddedShop}
                >
                    {buttons.ADD}
                </Button>
            </div>
            <Shops shops={shops} fields={fields} />
        </div>
    )
}

export default AdminShops
