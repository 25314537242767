import { AppState, InferActionsTypes } from './Store'
import { ThunkAction } from 'redux-thunk'
import {
    actions as appActions,
    AuthActionsTypes,
    EditingEntityStatuses,
} from './AppReducer'
import { shopAPI } from '../DAL/Services'
import {
    CouponStatus,
    CouponType,
    ShopTypes,
    UpdateCouponType,
} from './shop-types'
import { commonAsyncHandler, completeOperation } from './common-async-handler'
import { ResultCodesEnum, ThunkActionType } from './types'
import { CONSTANTS } from './SupportingFile'

const initState = {
    shops: [] as ShopTypes[],
    coupons: [] as CouponType[],
}

type IState = typeof initState
export type ActionsTypes = InferActionsTypes<typeof actions> | AuthActionsTypes

const CustomerCouponsReducer = (
    state = initState,
    action: ActionsTypes
): IState => {
    switch (action.type) {
        case 'CustomerCouponsReducer/SET_COUPONS':
            return {
                ...state,
                coupons: action.coupons,
            }
        case 'CustomerCouponsReducer/SET_SHOP':
            return {
                ...state,
                shops: action.shops,
            }
        case 'CustomerCouponsReducer/SET_COUPON':
            return {
                ...state,
                coupons: [...state.coupons, action.coupon],
            }
        case 'CustomerCouponsReducer/ShopAdminReducer/DELETE_COUPON':
            return {
                ...state,
                coupons: state.coupons.filter((c) => c.id !== action.couponId),
            }
        case 'CustomerCouponsReducer/UPDATE_COUPON':
            return {
                ...state,
                coupons: state.coupons.map((coupon) => {
                    if (coupon.id === action.coupon.id) {
                        return { ...coupon, ...action.coupon }
                    } else {
                        return coupon
                    }
                }),
            }
        case 'CustomerCouponsReducer/SET_COUPON_STATUS':
            return {
                ...state,
                coupons: state.coupons.map((coupon) => {
                    if (coupon.id === action.couponId) {
                        return { ...coupon, status: action.status }
                    } else {
                        return coupon
                    }
                }),
            }
        default:
            return state
    }
}
export default CustomerCouponsReducer

const actions = {
    setShops: (shops: ShopTypes[]) =>
        ({ type: 'CustomerCouponsReducer/SET_SHOP', shops } as const),
    updateCoupon: (coupon: UpdateCouponType) =>
        ({ type: 'CustomerCouponsReducer/UPDATE_COUPON', coupon } as const),
    deleteCoupon: (couponId: number) =>
        ({
            type: 'CustomerCouponsReducer/ShopAdminReducer/DELETE_COUPON',
            couponId,
        } as const),
    setCoupons: (coupons: CouponType[]) =>
        ({ type: 'CustomerCouponsReducer/SET_COUPONS', coupons } as const),
    setCoupon: (coupon: CouponType) =>
        ({ type: 'CustomerCouponsReducer/SET_COUPON', coupon } as const),
    setCouponStatus: (couponId: number, status: CouponStatus) =>
        ({
            type: 'CustomerCouponsReducer/SET_COUPON_STATUS',
            couponId,
            status,
        } as const),
}

export const getShops =
    (): ThunkActionType<ActionsTypes> => async (dispatch) => {
        await commonAsyncHandler(async () => {
            const shops = await shopAPI.getShops()
            dispatch(actions.setShops(shops))
            return shops
        }, dispatch)
    }

export const getCoupons =
    (): ThunkActionType<ActionsTypes> => async (dispatch) => {
        await commonAsyncHandler(async () => {
            const shops = await shopAPI.getShops()
            let coupons = await shopAPI.getCoupons()
            coupons = coupons.map((coupon) => {
                return {
                    ...coupon,
                    shopTitle: (
                        shops.find(
                            (shop) => shop.id === coupon.shopId
                        ) as ShopTypes
                    ).title,
                }
            })
            dispatch(actions.setCoupons(coupons))
            return coupons
        }, dispatch)
    }

export const getManagementCoupons =
    (): ThunkActionType<ActionsTypes> => async (dispatch) => {
        await commonAsyncHandler(async () => {
            const shops = await shopAPI.getShops()
            let coupons = await shopAPI.getManagementCoupons()
            coupons = coupons.map((coupon) => {
                return {
                    ...coupon,
                    shopTitle: (
                        shops.find(
                            (shop) => shop.id === coupon.shopId
                        ) as ShopTypes
                    ).title,
                }
            })
            dispatch(actions.setCoupons(coupons))
            return coupons
        }, dispatch)
    }

export const createCoupon =
    (shopId: number): ThunkActionType<ActionsTypes> =>
    async (dispatch, getState) => {
        await commonAsyncHandler(async () => {
            let shops = getState().customerCoupons.shops
            if (!shops.length) {
                shops = await shopAPI.getShops()
            }
            const res = await shopAPI.createCoupon(shopId)
            if (res.resultCode === ResultCodesEnum.Success) {
                const coupon: CouponType = {
                    ...res.data.item,
                    shopTitle: (
                        shops.find((shop) => shop.id === shopId) as ShopTypes
                    ).title,
                }
                dispatch(actions.setCoupon(coupon))
                dispatch(
                    appActions.showSnackbar(
                        'success',
                        CONSTANTS.SUCCESS_MESSAGE
                    )
                )
            }
            return res
        }, dispatch)
    }

export const deleteCoupon =
    (shopId: number, couponId: number): ThunkActionType<ActionsTypes> =>
    async (dispatch) => {
        await commonAsyncHandler(async () => {
            const response = await shopAPI.deleteCoupon(shopId, couponId)
            completeOperation(response, dispatch, () => {
                dispatch(actions.deleteCoupon(couponId))
                dispatch(
                    appActions.setEditingEntityStatus(
                        EditingEntityStatuses.Success
                    )
                )
            })
            return response
        }, dispatch)
    }

export const updateCoupon =
    (coupon: UpdateCouponType): ThunkActionType<ActionsTypes> =>
    async (dispatch) => {
        await commonAsyncHandler(async () => {
            const response = await shopAPI.editCoupon(coupon)
            completeOperation(response, dispatch, () => {
                dispatch(actions.updateCoupon(coupon))
                if (coupon.isClosed) {
                    dispatch(closeCoupon(coupon.id))
                } else {
                    dispatch(
                        appActions.setEditingEntityStatus(
                            EditingEntityStatuses.Success
                        )
                    )
                }
            })
            return response
        }, dispatch)
    }

export const closeCoupon =
    (couponId: number): ThunkActionType<ActionsTypes> =>
    async (dispatch) => {
        await commonAsyncHandler(async () => {
            const response = await shopAPI.closeCoupon(couponId)
            if (response.resultCode === ResultCodesEnum.Success) {
                dispatch(
                    appActions.setEditingEntityStatus(
                        EditingEntityStatuses.Success
                    )
                )
                dispatch(
                    actions.setCouponStatus(couponId, response.data.status)
                )
            }
            return response
        }, dispatch)
    }

export const leaveFeedback =
    (
        couponId: number,
        customerFeedback: string = '',
        customerShopRating: number
    ): ThunkActionType<ActionsTypes> =>
    async (dispatch, getState) => {
        await commonAsyncHandler(async () => {
            const response = await shopAPI.leaveFeedback(
                couponId,
                customerFeedback,
                customerShopRating
            )
            completeOperation(response, dispatch, () => {
                const coupon = getState().customerCoupons.coupons.find(
                    (c) => c.id === couponId
                ) as UpdateCouponType
                dispatch(
                    appActions.setEditingEntityStatus(
                        EditingEntityStatuses.Success
                    )
                )
                dispatch(
                    actions.updateCoupon({
                        ...coupon,
                        customerShopRating,
                        customerFeedback,
                    })
                )
            })
            return response
        }, dispatch)
    }
