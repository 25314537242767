import React from 'react'
import { Tab, Tabs } from '@material-ui/core'
import s from './customTabs.module.scss'

function a11yProps(index: string) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    }
}

type Props = {
    value: string | number
    onChange?: (event: React.ChangeEvent<{}>, value: number) => void
    tabs: string[]
    labels: string[]
}

export const CustomTabs = ({ value, onChange, tabs, labels }: Props) => {
    const mappedTabs = tabs.map((el, i) => (
        <Tab
            className={s.styledTab}
            key={`${i}-${el}`}
            {...a11yProps(el)}
            disableRipple
            label={labels[i]}
        ></Tab>
    ))

    return (
        <Tabs
            className={s.styledTabs}
            TabIndicatorProps={{ children: <span /> }}
            centered
            value={value}
            onChange={onChange}
        >
            {mappedTabs}
        </Tabs>
    )
}
