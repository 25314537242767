import React from 'react'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'
import { CustomerRM } from 'UI/routers/RouterManager'
import { NavLink } from 'react-router-dom'
import { MyBeatType } from 'DAL/BeatSettingsAPI'
import EditIcon from 'UI/assets/icons/edit.svg'
import DeleteIcon from 'UI/assets/icons/delete.svg'
import PublishIcon from 'UI/assets/icons/publish.svg'
import CancelPublishIcon from 'UI/assets/icons/cancel-publish.svg'
import s from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/beatItem/beatItem.module.scss'

type Props = {
    publicationId?: string
    beat: MyBeatType
}
export const MobileMenuItem = ({ publicationId, beat }: Props) => {
    const { i18n } = useTranslation()
    const { customerBeatsPublication, buttons } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType
    return (
        <div className={s.tooltip}>
            <div className={s.triangle} />
            <div className={s.description}>
                <ul className={s.actionsList}>
                    <li>
                        <NavLink
                            to={CustomerRM.myBeats.getUrl.beats.edit(beat.id)}
                        >
                            <img src={EditIcon} alt={'edit'} />
                            {buttons.EDIT}
                        </NavLink>
                    </li>
                    {!beat.addedToCatalogDate && (
                        <li>
                            <NavLink
                                to={CustomerRM.myBeats.getUrl.publications.edit(
                                    beat.id
                                )}
                            >
                                <img src={PublishIcon} alt={'publish'} />
                                {buttons.PUBLISHED}
                            </NavLink>
                        </li>
                    )}
                    {!beat.addedToCatalogDate && (
                        <li>
                            <NavLink
                                to={CustomerRM.myBeats.getUrl.publications.delete(
                                    beat.id
                                )}
                            >
                                <img src={DeleteIcon} alt={'delete'} />
                                {buttons.DELETE}
                            </NavLink>
                        </li>
                    )}
                    {publicationId && (
                        <li>
                            <NavLink
                                to={CustomerRM.myBeats.getUrl.publications.cancelPublications(
                                    publicationId
                                )}
                            >
                                <img src={CancelPublishIcon} alt={'cancel'} />
                                {customerBeatsPublication.cancelPublication}
                            </NavLink>
                        </li>
                    )}
                    {beat.addedToCatalogDate && (
                        <li>
                            <NavLink
                                to={CustomerRM.myBeats.getUrl.publications.cancel(
                                    beat.id
                                )}
                            >
                                <img src={DeleteIcon} alt={'delete'} />
                                {buttons.DELETE}
                            </NavLink>
                        </li>
                    )}
                </ul>
            </div>
        </div>
    )
}
