import { BaseResponse, EmailTemplateType } from '../BLL/types'
import { emailMarketingAxios } from './createAxios'

export type CreateTemplateDto = {
    name: string
    templateKey: string
}
export type UpdateTemplateDto = {
    id: string
    template: EmailTemplateType
    author: string
}

export const templatesAPI = {
    createEmailTemplate(createTemplateDto: CreateTemplateDto) {
        return emailMarketingAxios
            .post<BaseResponse<EmailTemplateType>>(`templates`, {
                createTemplateDto,
            })
            .then((r) => r.data)
    },

    getTemplatesAll() {
        return emailMarketingAxios
            .get<EmailTemplateType[]>(`templates`)
            .then((r) => r.data)
    },

    deleteTemplateById(id: string) {
        return emailMarketingAxios
            .delete<BaseResponse<null>>(`templates/${id}`)
            .then((r) => r.data)
    },

    getTemplateById(id: string) {
        return emailMarketingAxios
            .get<EmailTemplateType>(`templates/${id}`)
            .then((r) => r.data)
    },

    updateTemplate(updateTemplateDto: UpdateTemplateDto) {
        return emailMarketingAxios
            .put<BaseResponse<EmailTemplateType>>(
                `templates/${updateTemplateDto.id}`,
                updateTemplateDto
            )
            .then((r) => r.data)
    },

    getHtmlToRender(templateId: string) {
        return emailMarketingAxios
            .get<string>(`templates/htmlToRender/${templateId}`)
            .then((r) => r.data)
    },
}
