import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'
import { RolesTypeEnum } from 'BLL/enums'
import { useSelector } from '../hooks/redux-hooks'
import { AdminRM, CustomerRM } from 'UI/routers/RouterManager'
import { ListItemIcon, ListItemText } from '@material-ui/core'
import PersonIcon from '@material-ui/icons/Person'
import AudiotrackIcon from '@material-ui/icons/Audiotrack'
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import CallMadeIcon from '@material-ui/icons/CallMade'
import DescriptionIcon from '@material-ui/icons/Description'
import PeopleIcon from '@material-ui/icons/People'
import RepeatIcon from '@material-ui/icons/Repeat'
import SettingsIcon from '@material-ui/icons/Settings'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import newIcon from '../../assets/icons/new2.png'
import EqualizerIcon from '@material-ui/icons/Equalizer'
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople'
import EmailIcon from '@material-ui/icons/Email'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'
import PaymentIcon from '@material-ui/icons/Payment'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'
import StorefrontIcon from '@material-ui/icons/Storefront'
import ReceiptIcon from '@material-ui/icons/Receipt'
import StoreIcon from '@material-ui/icons/Store'
import LibraryMusicIcon from '@material-ui/icons/LibraryMusic'
import ChatIcon from '@material-ui/icons/Chat'
import { AppSettings } from 'settings/appSettings'

const useStyles = makeStyles({
    list: {
        width: 270,
    },
    fullList: {
        width: 'auto',
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
        color: AppSettings.api.getBaseColor(),
    },
    navLink: {
        textDecoration: 'none',
        color: '#1f1f1f',
    },
    lang: {
        display: 'flex',
        height: '100%',
        position: 'relative',
    },
    langBlock: {
        width: 50,
        margin: 15,
        cursor: 'pointer',
    },
})

class MenuItem {
    constructor(
        public title: string,
        public icon: any,
        public link: string,
        public isNew: boolean,
        public isShow = true
    ) {}
}

export const MainMenuDrawer = () => {
    const { i18n } = useTranslation()
    const { navigation } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType
    const isBeatmaker = useSelector((state) => state.appReducer.isBeatmaker)
    const roles = useSelector((state) => state.appReducer.roles)
    const expert = useSelector((state) => state.customerBeatsCompilation.expert)
    const isOwner = useSelector((state) => state.appReducer.isOwner)

    const classes = useStyles()
    const [state, setState] = React.useState({
        left: false,
    })

    const toggleDrawer = (side: string, open: boolean) => (event: any) => {
        if (
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return
        }

        setState({ ...state, [side]: open })
    }

    const beatmakerMenuList = [
        new MenuItem(
            navigation.myBeats,
            <AudiotrackIcon />,
            CustomerRM.myBeats.path.beats.list,
            false
        ),
        new MenuItem(
            navigation.mySales,
            <TrendingUpIcon />,
            CustomerRM.mySales.path.list,
            !AppSettings.api.isEngVersion()
        ),
        new MenuItem(
            navigation.beatsPromo,
            <CallMadeIcon />,
            CustomerRM.promotions.path.list,
            false,
            !AppSettings.api.isEngVersion()
        ),
        new MenuItem(
            navigation.contracts,
            <DescriptionIcon />,
            CustomerRM.contracts.path.license.list,
            !AppSettings.api.isEngVersion()
        ),
        new MenuItem(
            navigation.mySubscribers,
            <PeopleIcon />,
            CustomerRM.subscribers.path.list,
            false,
            !AppSettings.api.isEngVersion()
        ),
        new MenuItem(
            navigation.settings,
            <SettingsIcon />,
            CustomerRM.settings.path.getUrl('cashback'),
            false
        ),
        new MenuItem(
            navigation.coupons,
            <ReceiptIcon />,
            '/shop/customer',
            !AppSettings.api.isEngVersion(),
            !AppSettings.api.isEngVersion()
        ),
    ]

    const outSideLink = [
        new MenuItem(
            navigation.myProfile,
            <PersonIcon />,
            '/backToBeatmakerTV',
            false
        ),
    ]
    const sideList = (side: string) => (
        <div
            className={classes.list}
            role="presentation"
            onClick={toggleDrawer(side, false)}
            onKeyDown={toggleDrawer(side, false)}
        >
            <List>
                {outSideLink.map((l) => {
                    return (
                        l.isShow && (
                            <a
                                key={l.link}
                                className={classes.navLink}
                                href={l.link}
                                target={'_blank'}
                                rel="noreferrer"
                            >
                                <ListItem button>
                                    <ListItemIcon> {l.icon}</ListItemIcon>
                                    <ListItemText
                                        secondary={l.title}
                                        className={classes.navLink}
                                    />
                                    {l.isNew && (
                                        <img
                                            src={newIcon}
                                            alt={'new'}
                                            width={30}
                                        />
                                    )}
                                </ListItem>
                            </a>
                        )
                    )
                })}
                {isBeatmaker &&
                    beatmakerMenuList.map(
                        (item, index) =>
                            item.isShow && (
                                <NavLink
                                    key={index}
                                    to={item.link}
                                    className={classes.navLink}
                                >
                                    <ListItem button>
                                        <ListItemIcon>{item.icon}</ListItemIcon>
                                        <ListItemText
                                            secondary={item.title}
                                            className={classes.navLink}
                                        />
                                        {item.isNew && (
                                            <img
                                                src={newIcon}
                                                alt={'new'}
                                                width={30}
                                            />
                                        )}
                                    </ListItem>
                                </NavLink>
                            )
                    )}
                {expert && !AppSettings.api.isEngVersion() && (
                    <NavLink
                        to={CustomerRM.compilations.path.list}
                        className={classes.navLink}
                    >
                        <ListItem button>
                            <ListItemIcon>
                                <RepeatIcon />
                            </ListItemIcon>
                            <ListItemText
                                secondary={navigation.beatsCompilation}
                            />
                        </ListItem>
                    </NavLink>
                )}
                <NavLink
                    to={CustomerRM.myCoins.path.list}
                    className={classes.navLink}
                >
                    <ListItem button>
                        <ListItemIcon>
                            <MonetizationOnIcon />
                        </ListItemIcon>
                        <ListItemText secondary={navigation.myCoins} />
                    </ListItem>
                </NavLink>
                <NavLink
                    to={CustomerRM.cashback.path.list}
                    className={classes.navLink}
                >
                    <ListItem button>
                        <ListItemIcon>
                            <MonetizationOnIcon />
                        </ListItemIcon>
                        <ListItemText secondary={navigation.cashback} />
                    </ListItem>
                </NavLink>
                {isOwner && !AppSettings.api.isEngVersion() && (
                    <NavLink to="/shop/admin" className={classes.navLink}>
                        <ListItem button>
                            <ListItemIcon>
                                <StoreIcon />
                            </ListItemIcon>
                            <ListItemText secondary={navigation.shop} />
                            <img src={newIcon} alt={'new'} width={30} />
                        </ListItem>
                    </NavLink>
                )}
                {!AppSettings.api.isEngVersion() && (
                    <a
                        className={classes.navLink}
                        href={
                            'https://vk.me/join/WrhBCYZi20DLtikYVV4mauK66qf_lZ4Bxbg='
                        }
                        target={'_blank'}
                        rel="noreferrer"
                    >
                        <ListItem button>
                            <ListItemIcon>
                                {' '}
                                <ChatIcon />
                            </ListItemIcon>
                            <ListItemText
                                secondary={navigation.chatVK}
                                className={classes.navLink}
                            />

                            <img src={newIcon} alt={'new'} width={30} />
                        </ListItem>
                    </a>
                )}
            </List>
            <Divider />
        </div>
    )

    const is = (...args: string[]) =>
        roles.some(
            (r) => args.indexOf(r) > -1 || r === RolesTypeEnum.ADMINISTRATOR
        )

    const adminItemList = [
        {
            title: navigation.promoStats,
            role: RolesTypeEnum.PROMO_VIEWER,
            link: AdminRM.promo.path.list,
            icon: <EqualizerIcon />,
        },
        {
            title: navigation.adminShops,
            role: RolesTypeEnum.SHOPS_MANAGER,
            link: '/admin/shops',
            icon: <StorefrontIcon />,
        },
        {
            title: navigation.adminCoupons,
            role: RolesTypeEnum.SHOPS_MANAGER,
            link: '/admin/coupons',
            icon: <StorefrontIcon />,
        },
        {
            title: navigation.experts,
            role: RolesTypeEnum.COMPILATIONS_MANAGER,
            link: AdminRM.experts.path.list,
            icon: <EmojiPeopleIcon />,
        },
        {
            title: navigation.mailingSubscription,
            role: RolesTypeEnum.SUBSCRIPTION_MANAGER,
            link: AdminRM.subscriptions.path.list,
            icon: <EmailIcon />,
        },
        {
            title: navigation.emailTemplates,
            role: RolesTypeEnum.TEMPLATE_MANAGER,
            link: AdminRM.templates.path.list,
            icon: <LibraryBooksIcon />,
        },
        {
            title: navigation.adminPayments,
            role: RolesTypeEnum.PAYMENTS,
            link: AdminRM.payments.path.list,
            icon: <PaymentIcon />,
        },
        {
            title: navigation.orders,
            role: RolesTypeEnum.PAYMENTS,
            link: AdminRM.orders.path.list,
            icon: <AccountBalanceWalletIcon />,
        },
        {
            title: navigation.beats,
            role: '',
            link: AdminRM.beats.path.list,
            icon: <LibraryMusicIcon />,
        },
    ]

    const adminMenu = (side: string) => (
        <div
            className={classes.list}
            role="presentation"
            onClick={toggleDrawer(side, false)}
            onKeyDown={toggleDrawer(side, false)}
        >
            <List>
                {adminItemList.map((item, index) => {
                    if (is(item.role)) {
                        return (
                            <NavLink
                                key={index}
                                to={item.link}
                                className={classes.navLink}
                            >
                                <ListItem button>
                                    <ListItemIcon>{item.icon}</ListItemIcon>
                                    <ListItemText
                                        secondary={item.title}
                                        className={classes.navLink}
                                    />
                                </ListItem>
                            </NavLink>
                        )
                    }
                })}
            </List>
            <Divider />
        </div>
    )

    return (
        <div>
            <IconButton
                className={classes.menuButton}
                color="inherit"
                aria-label="Open drawer"
                onClick={toggleDrawer('left', true)}
            >
                <MenuIcon />
            </IconButton>

            <Drawer open={state.left} onClose={toggleDrawer('left', false)}>
                {sideList('left')}
                {is(
                    RolesTypeEnum.ADMINISTRATOR,
                    RolesTypeEnum.COMPILATIONS_MANAGER,
                    RolesTypeEnum.SUBSCRIPTION_MANAGER,
                    RolesTypeEnum.TEMPLATE_MANAGER,
                    RolesTypeEnum.PROMO_VIEWER
                ) ? (
                    !AppSettings.api.isEngVersion() && (
                        <div
                            style={{
                                fontWeight: 'bold',
                                textAlign: 'center',
                                marginTop: 15,
                            }}
                        >
                            {navigation.admin}
                            {adminMenu('left')}
                        </div>
                    )
                ) : (
                    <span />
                )}
            </Drawer>
        </div>
    )
}
