import React, { useState, useEffect, useRef, ReactNode } from 'react'
import s from './buttons.module.scss'
import icon from 'UI/assets/icons/btnTooltip.svg'

type PropsType = {
    children: ReactNode
}

export const ButtonTooltip = ({ children }: PropsType) => {
    const [show, setShow] = useState(false)
    const toolTipRef = useRef(null)

    const handleClickOutside = (event: any) => {
        //@ts-ignore
        if (toolTipRef && toolTipRef.current) {
            // @ts-ignore
            !toolTipRef.current.contains(event.target)! && setShow(false)
        }
    }
    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true)
        return () => {
            document.removeEventListener('click', handleClickOutside, true)
        }
    })
    return (
        <div className={s.buttonBox} ref={toolTipRef}>
            <button
                className={s.buttonTooltip}
                onClick={() => setShow(!show)}
                type={'button'}
            >
                <img src={icon} width={18} height={18} alt="Info" />
            </button>
            {show && (
                <div className={s.tooltip}>
                    <div className={s.triangle} />
                    <div className={s.description}>
                        <p className={s.text}>{children}</p>
                    </div>
                </div>
            )}
        </div>
    )
}
