import { Redirect } from 'react-router-dom'
import React from 'react'
import { useSelector } from 'react-redux'
import { AppState } from '../../../BLL/Store'

export const useRedirectIsOwnerHook = () => {
    const isOwner: boolean = useSelector(
        (state: AppState) => state.appReducer.isOwner
    )
    if (!isOwner) {
        return <Redirect to={'/'} />
    }
}
