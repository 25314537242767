import { beatsCompilationAxios } from './createAxios'
import {
    BeatFullType,
    CompilationType,
    ExpertType,
    GetCompilationType,
    BaseResponse,
} from '../BLL/types'
import { StatusExpertEnum } from '../BLL/enums'

export type UpdateExpertModel = {
    description: string
    image: string
    beatmakerId: number
}

export const compilationAPI = {
    async getCompilation() {
        return beatsCompilationAxios
            .get<BaseResponse<GetCompilationType>>('/compilation')
            .then((res) => res.data)
    },

    async updateCompilation(compilationId: string, beat: BeatFullType) {
        return beatsCompilationAxios
            .put<BaseResponse<CompilationType>>('/compilation', {
                compilationId,
                beat,
            })
            .then((res) => res.data)
    },

    async updateLastListenedBeatAddedAt(date: string) {
        return beatsCompilationAxios
            .put<BaseResponse>('/experts/LastListenedBeatAddedAt', {
                date,
            })
            .then((res) => res.data)
    },

    async getExpert() {
        return beatsCompilationAxios
            .get<BaseResponse<ExpertType>>('/experts')
            .then((res) => res.data)
    },

    async getExpertById(expertId: string) {
        return beatsCompilationAxios
            .get<BaseResponse<ExpertType>>(`/experts/update/${expertId}`)
            .then((res) => res.data)
    },

    getCompilationBeats() {
        return beatsCompilationAxios
            .get<BaseResponse<BeatFullType[]>>('/compilation/beats')
            .then((res) => res.data)
    },

    async getExperts() {
        return beatsCompilationAxios
            .get<BaseResponse<ExpertType[]>>('/experts/all')
            .then((res) => res.data)
    },

    async updateStatusExpert(status: StatusExpertEnum, id: string) {
        return beatsCompilationAxios
            .put<BaseResponse>('/experts/status', { status, id })
            .then((res) => res.data)
    },

    async addedExpert() {
        return beatsCompilationAxios
            .post<BaseResponse<ExpertType>>('/experts')
            .then((res) => res.data)
    },
    async updateExpert(id: string, values: UpdateExpertModel) {
        return beatsCompilationAxios
            .put<BaseResponse<ExpertType>>(`/experts/${id}`, { ...values })
            .then((res) => res.data)
    },

    async addedImage(image: any) {
        return beatsCompilationAxios
            .post<BaseResponse>('/experts/image', image, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((res) => res.data)
    },
}
