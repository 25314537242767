import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'

import { LanguageType } from '../../../../translations/ru/common'
import {
    LanguageEnum,
    RolesTypeEnum,
    WidgetThemeType,
} from '../../../../BLL/enums'
import { CustomerRM } from '../../../routers/RouterManager'
import ArrowLeft from '../../../assets/icons/arrow-left.svg'
import { WidgetSettingsBlock } from './WidgetSettingsBlock/WidgetSettingsBlock'
import { WidgetAnalyticsBlock } from './WidgetAnalyticsBlock/WidgetAnalyticsBlock'
import { WidgetPreviewBlock } from './WidgetPreviewBlock/WidgetPreviewBlock'
import { useRedirectCustomHook } from '../../../common/hooks/useRedirectCustomHook'
import { CustomBrowserView } from '../../../common/browserView/CustomBrowserView'
import classes from './WidgetsSettingsPage.module.scss'
import { Button } from '../../../common/elements/buttons/Button'
import { WidgetAnalyticsType } from '../../../../DAL/WidgetsAPI'
import { AppSettings } from 'settings/appSettings'
import { createWidgetTC } from '../../../../BLL/WidgetsReducer'
import { LangSelectType } from '../../../../translations/en/common'
import { WidgetUrlsBlock } from './WidgetUrlsBlock/WidgetUrlsBlock'
import { useSelector } from '../../../common/hooks/redux-hooks'
import { NullableType } from '../../../../BLL/types'

// TODO: Maybe WidgetType (use Utility Types)
export type WidgetFormType = {
    title: string
    height: number
    width: number
    isWidthResponsive: boolean
    lang: LangSelectType
    analytics: WidgetAnalyticsType
}

export const WidgetsSettingsPage = () => {
    const dispatch = useDispatch()

    const [urlSettings, setUrlSettings] = useState<
        Pick<WidgetFormType, 'width' | 'height'>
    >({
        width: 1024,
        height: 800,
    })

    const beatmakerId = useSelector<NullableType<number>>(
        (state) => state.appReducer.userId
    )

    /* i18n */
    const { i18n } = useTranslation()
    const { widget, buttons, languageList } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    /* React hook form */
    const {
        register,
        handleSubmit,
        control,
        setValue,
        watch,
        formState: { errors },
    } = useForm<WidgetFormType>()
    const onSubmit = handleSubmit(async (formValues: WidgetFormType) => {
        const data = {
            ...formValues,
            lang: adaptLang(formValues.lang.value),
            // Todo: Get real colors
            color: {
                theme: WidgetThemeType.DarkNeon,
                styles: 'color: red',
            },
        }

        await dispatch(createWidgetTC(data))
        setUrlSettings({ width: data.width, height: data.height })
    })

    useEffect(() => {
        const isEng = AppSettings.api.isEngVersion()

        setDefaultFormValue<boolean>('isWidthResponsive', false)
        setDefaultFormValue<boolean>('autoplay', false)
        setDefaultFormValue<LangSelectType>('lang', {
            value: isEng ? 1 : 2,
            label: isEng ? languageList.en : languageList.ru,
        })
        setDefaultFormValue<{ value: number; label: string }>('tracklist', {
            value: 1,
            label: widget.fields.selectAllBeats,
        })
    }, [])

    function setDefaultFormValue<T>(registerName: string, defaultValue: T) {
        setValue(registerName, defaultValue)
    }

    const adaptLang = (langValue: number) => {
        return langValue === 1 ? LanguageEnum.En : LanguageEnum.Ru
    }

    return (
        <>
            {useRedirectCustomHook(RolesTypeEnum.BEATMAKER)}
            <CustomBrowserView>
                <div className="commonArrowBack">
                    <NavLink to={CustomerRM.widgets.path.list}>
                        <img src={ArrowLeft} alt={'back-items'} />
                        <span>{widget.backToMainPage}</span>
                    </NavLink>
                </div>

                <form onSubmit={onSubmit}>
                    <WidgetSettingsBlock
                        errors={errors}
                        register={register}
                        control={control}
                        setValue={setValue}
                        watch={watch}
                    />

                    <WidgetAnalyticsBlock
                        widget={widget}
                        errors={errors}
                        register={register}
                        control={control}
                        setValue={setValue}
                    />

                    <WidgetPreviewBlock beatmakerId={beatmakerId} />

                    <WidgetUrlsBlock urlSettings={urlSettings} />

                    {/* Buttons */}
                    <div className={classes.wrapperButtonFooter}>
                        <NavLink to={CustomerRM.widgets.path.list}>
                            <Button view={'Secondary'} title={buttons.CANCEL} />
                        </NavLink>
                        <Button title={buttons.SAVE} />
                    </div>
                </form>
            </CustomBrowserView>
        </>
    )
}
