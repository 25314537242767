import c from 'UI/common/elements/textFields/newtextField/newTextField.module.scss'
import p from 'UI/common/elements/textFields/newtextField/price.module.scss'
import s from 'UI/common/elements/textFields/newtextField/search.module.scss'
import clsx from 'clsx'
import { TextFieldVariant } from 'UI/common/elements/textFields/newtextField/utils/types'

type Props = {
    variant?: TextFieldVariant
    currencyType?: 'RUB' | 'USD'
    customTheme?: 'dark' | 'light'
    disabled?: boolean
    className?: string
}
export const getFinalType = ({
    variant,
    currencyType,
    customTheme,
    disabled,
    className,
}: Props) => {
    const completedClass = customTheme === 'dark' && s.darkSearch

    const customClassName = {
        primary: clsx(c.inputStyle),
        search: clsx(`${s.search} ${completedClass}`),
        copy: clsx(`${c.copy} ${disabled && c.disabled}`),
        price: clsx(
            currencyType === 'USD' ? `${p.priceDollar}` : `${p.priceRub}`
        ),
    }

    return `${c.inputStyle} ${className || ''}
      ${variant && customClassName[variant]}
    `
}
