import React, { FC, useCallback, useEffect, useState } from 'react'
import classes from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/publicationSettings/publicationSettings.module.scss'
import { useSelector } from 'UI/common/hooks/redux-hooks'
import {
    getAllPublications,
    ITimeIntervals,
    setBeatPublication,
    TIME_INTERVALS,
} from 'BLL/CustomerBeatsPublicationReducer'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'
import { Button } from 'UI/common/elements/buttons/Button'
import { CustomDatePicker } from 'UI/common/elements/datePicker/CustomDatePicker'
import { CustomTimePicker } from 'UI/common/elements/timePicker/TimePicker'
import { PublicationType } from 'BLL/types'
import * as dateFns from 'date-fns'
import { addToCatalogNow } from 'BLL/CustomerMyBeatsReducer'
import { useDispatch } from 'react-redux'

const SHIFT_TIME_FORWARD = 30
type PropsType = {}

export const PublicationSettings: FC<PropsType> = () => {
    const [isChooseDay, setIsChooseDay] = useState(false)
    const { i18n } = useTranslation()
    const { buttons, customerBeatsPublication, myBeats } =
        i18n.getResourceBundle(i18n.language, 'common') as LanguageType
    const dispatch = useDispatch()
    const { beatId, publicationId } =
        useParams<{ beatId: string; publicationId: string }>()
    const publications = useSelector(getAllPublications)

    const [publicationDate, setPublicationDate] = useState<string>('')
    const [timeIntervals, setTimeIntervals] = useState(TIME_INTERVALS)
    const [publicationTime, setPublicationTime] = useState<string>('')
    const [publication, setPublication] = useState<PublicationType | null>(null)

    const handleIsChooseDay = () => {
        setIsChooseDay(!isChooseDay)
    }

    const settingsPopupValue = () => {
        const publicationDate = publication ? publication.date : ''
        let date = new Date(publicationDate)
        let now = new Date()
        const newPublicationDate =
            publicationDate && date > now
                ? dateFns.format(date, 'yyyy-MM-dd')
                : dateFns.format(now, 'yyyy-MM-dd')
        setPublicationDate(newPublicationDate)
    }

    useEffect(() => {
        settingsPopupValue()
    }, [])

    useEffect(() => {
        const publication = publications.find(
            (publication) => publication.beat.beatId === +beatId
        ) as PublicationType
        setPublication(publication)
        publication?.date && setIsChooseDay(true)
    }, [beatId])

    useEffect(() => {
        settingsPopupValue()
    }, [publication, publications])

    useEffect(() => {
        let now = new Date()
        let date = new Date(publicationDate)
        const isDate = dateFns.isDate(date)
        if (date < new Date(dateFns.format(now, 'yyyy-MM-dd')) || !isDate) {
            return
        }

        let hours
        let minutes
        if (publication?.date) {
            hours = new Date(publication?.date).getHours()
            minutes = new Date(publication?.date).getMinutes()
            if (hours < 10) {
                hours = '0' + hours
            }
            if (minutes === 0) {
                minutes = minutes + '0'
            }
        }
        if (!minutes && !hours && publicationDate) {
            hours = date.getHours()
            minutes = date.getMinutes()
            if (hours < 10) {
                hours = '0' + hours
            }
            if (minutes === 0) {
                minutes = minutes + '0'
            }
        }
        const timeIntervals =
            publicationDate === dateFns.format(now, 'yyyy-MM-dd')
                ? TIME_INTERVALS.filter((timeInterval: ITimeIntervals) => {
                      return (
                          timeInterval.hours * 60 + timeInterval.mins >
                          now.getHours() * 60 +
                              now.getMinutes() +
                              SHIFT_TIME_FORWARD
                      )
                  })
                : TIME_INTERVALS
        setTimeIntervals(timeIntervals)
        setPublicationTime(hours && minutes ? hours + ':' + minutes : '')
    }, [publicationDate])

    const onChangeDate = (date: Date | null) => {
        let now = new Date()
        const isValid = dateFns.isValid(date)
        if (!date || !isValid || date < now) {
            setPublicationDate('')
            return
        }
        setPublicationDate(dateFns.format(date, 'yyyy-MM-dd'))
        if (date > new Date()) {
            setTimeIntervals(TIME_INTERVALS)
        } else {
            setTimeIntervals(
                TIME_INTERVALS.filter((timeInterval: ITimeIntervals) => {
                    return (
                        timeInterval.hours * 60 + timeInterval.mins >
                        now.getHours() * 60 +
                            now.getMinutes() +
                            SHIFT_TIME_FORWARD
                    )
                })
            )
        }
    }

    const handleBeatPublication = useCallback(() => {
        const date = publicationDate + ' ' + publicationTime
        let publicationDateValue
        if (navigator.platform === 'iPhone') {
            publicationDateValue = new Date(
                date.replace(/-/g, '/')
            ).toISOString()
        } else {
            publicationDateValue = new Date(date).toISOString()
        }
        const newPublication = {
            _id: publication?._id,
            date: publicationDateValue,
            beat: {
                beatId: +beatId,
                beatTitle: publication?.beat.beatTitle,
            },
        } as PublicationType
        dispatch(setBeatPublication(newPublication))
    }, [publicationDate, publicationTime, publicationId, publication, beatId])

    const onSubmit = useCallback(
        (isNow: boolean) => {
            if (isNow) {
                dispatch(addToCatalogNow(+beatId))
                return
            }
            if (publicationTime) {
                handleBeatPublication()
            } else {
                // setError('publicationTime', { message: 'required' })
            }
        },
        [publicationDate, publicationTime, beatId]
    )

    return (
        <div className={classes.PublicationSettings}>
            <p>
                {myBeats.publicationsTextPopUp}{' '}
                <strong>{publication?.beat.beatTitle}</strong>
            </p>
            {!isChooseDay ? (
                <div className={classes.wrapperButton}>
                    <Button
                        view={'Secondary'}
                        title={buttons.CHOOSE_DAY}
                        onClick={handleIsChooseDay}
                    />
                    <Button
                        title={buttons.PUBLISHED}
                        onClick={() => onSubmit(true)}
                    />
                </div>
            ) : (
                <div>
                    <div className={classes.wrapperDateTime}>
                        <div>
                            <label className={classes.label}>
                                {customerBeatsPublication.date}
                            </label>
                            <CustomDatePicker
                                date={new Date(publicationDate)}
                                disabled={false}
                                minDate={new Date()}
                                handleChange={onChangeDate}
                                locale={i18n.language as 'ru' | 'en'}
                            />
                        </div>
                        <div>
                            <label className={classes.label}>{'Time'}</label>
                            <CustomTimePicker
                                time={publicationTime}
                                timeIntervals={timeIntervals}
                                handleChange={setPublicationTime}
                                disabled={!publicationDate}
                            />
                        </div>
                    </div>

                    <div className={classes.wrapperButton}>
                        <Button
                            view={'Secondary'}
                            title={buttons.CANCEL}
                            onClick={handleIsChooseDay}
                        />
                        <Button
                            title={buttons.SAVE}
                            onClick={() => onSubmit(false)}
                            disabled={!publicationDate || !publicationTime}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}
