import React, { useCallback } from 'react'
import { Route, useHistory } from 'react-router-dom'
import { CustomerRM } from 'UI/routers/RouterManager'
import { Modal } from 'UI/common/modal/modal'
import { Button } from 'UI/common/elements/buttons/Button'
import { useDispatch } from 'react-redux'
import { LanguageType } from 'translations/ru/common'
import { useTranslation } from 'react-i18next'
import s from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/beatspage.module.scss'
import { deleteFreeBeat } from 'BLL/CustomerFreeBeatsReducer'
import EditFreeBeat from '../editFreeBeat/EditFreeBeat'

type Props = {
    setModalIsActive: (isModal: boolean) => void
}
export const FreeBeatsPageRoutes = ({ setModalIsActive }: Props) => {
    const history = useHistory()

    const dispatch = useDispatch()

    const { i18n } = useTranslation()
    const { myBeats, buttons } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    const handleDeleteBeat = useCallback((beatId: string) => {
        dispatch(deleteFreeBeat(+beatId))
    }, [])

    return (
        <>
            <Route
                exact
                path={CustomerRM.freeBeats.path.beats.edit}
                render={() => (
                    <Modal
                        active={true}
                        setActive={setModalIsActive}
                        title={myBeats.editBeat}
                        cancelRedirect={() =>
                            history.push(CustomerRM.freeBeats.path.beats.base)
                        }
                    >
                        <EditFreeBeat />
                    </Modal>
                )}
            />
            <Route
                exact
                path={CustomerRM.freeBeats.path.beats.delete}
                render={({ match }) => (
                    <Modal
                        active={true}
                        setActive={setModalIsActive}
                        title={myBeats.deleteBeat}
                        cancelRedirect={() =>
                            history.push(CustomerRM.freeBeats.path.beats.base)
                        }
                    >
                        <div>
                            <p>{myBeats.removeBeat}</p>
                            <div className={s.buttonsPopUp}>
                                <Button
                                    view={'Secondary'}
                                    title={buttons.CANCEL}
                                    onClick={() =>
                                        history.push(
                                            CustomerRM.freeBeats.path.beats.base
                                        )
                                    }
                                />
                                <Button
                                    view={'Delete'}
                                    title={buttons.DELETE}
                                    onClick={() =>
                                        handleDeleteBeat(
                                            match.params['freeBeatId']
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </Modal>
                )}
            />
        </>
    )
}
