import { LanguageType } from 'translations/ru/common'
import { useSelector } from 'UI/common/hooks/redux-hooks'
import { selectSaleCount } from 'BLL/selectors/coins-selectors/selectCoins'
import { AppSettings } from 'settings/appSettings'
import { CustomerRM } from 'UI/routers/RouterManager'
import { MenuItem } from 'UI/common/menus/menuItems/menuItems'
import sale from 'UI/assets/icons/sale.svg'

export const BeatmakerMenuItems = (translate: LanguageType) => {
    const isSale = useSelector(selectSaleCount)
    const isEngMode = AppSettings.api.isEngVersion()

    return [
        new MenuItem(
            translate.navigation.myBeats,
            CustomerRM.myBeats.path.beats.base
        ),
        new MenuItem(
            translate.navigation.freeBeats,
            CustomerRM.freeBeats.path.beats.list
        ),
        new MenuItem(
            translate.navigation.mySales,
            CustomerRM.mySales.path.list
        ),
        new MenuItem(
            translate.navigation.beatsPromo,
            CustomerRM.promotions.path.list,
            !isEngMode
        ),
        new MenuItem(
            translate.navigation.contracts,
            CustomerRM.contracts.path.license.base
        ),
        new MenuItem(
            translate.navigation.mySubscribers,
            CustomerRM.subscribers.path.list
        ),
        new MenuItem(
            translate.navigation.settings,
            CustomerRM.settings.path.getUrl('profile')
        ),
        new MenuItem(
            translate.navigation.discounts,
            CustomerRM.discounts.path.getUrl('coupons')
        ),
        new MenuItem(
            translate.navigation.myCoins,
            CustomerRM.myCoins.path.list,
            true,
            false,
            isSale ? sale : undefined
        ),
        new MenuItem(
            translate.navigation.producerPage,
            CustomerRM.producerPage.path
        ),
        new MenuItem(
            translate.navigation.widgets,
            CustomerRM.widgets.path.list,
            false
        ),
    ]
}
