import React from 'react'
import { createRoot } from 'react-dom/client'
import { AuthWrapper } from 'App'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { set401handler } from 'DAL/createAxios'
import { SnackbarProvider } from 'notistack'
import { PlayerProvider } from 'Player/Hooks/Context'
import { playerLogic } from 'PlayerInstance'
import reportWebVitals from './reportWebVitals'
import { QueryClient, QueryClientProvider } from 'react-query'
import { MessageBusProvider } from 'libs/MessageBus/Context'
import { MessageBus } from 'libs/MessageBus/MessageBus'
import { SnackMessage } from 'UI/common/snackbar/CustomSnackbarMessage'
import store from 'BLL/Store'
import './i18n'
import './index.css'

if (process.env.REACT_APP_LANGUAGE_VERSION === 'en') {
    require('./eng-variable.css')
} else {
    require('./variable.css')
}

set401handler(() => {
    //alert('401');
})

playerLogic.pause()

const queryClient = new QueryClient()

const bus = new MessageBus()

createRoot(document.getElementById('root')!).render(
    <QueryClientProvider client={queryClient}>
        <Provider store={store}>
            <PlayerProvider player={playerLogic}>
                <BrowserRouter>
                    <SnackbarProvider
                        maxSnack={5}
                        autoHideDuration={5000}
                        content={(key, message) => (
                            <SnackMessage id={key} message={message} />
                        )}
                    >
                        <MessageBusProvider bus={bus}>
                            <AuthWrapper />
                        </MessageBusProvider>
                    </SnackbarProvider>
                </BrowserRouter>
            </PlayerProvider>
        </Provider>
    </QueryClientProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
