import { LanguageType } from 'translations/ru/common'
import { CustomerRM } from 'UI/routers/RouterManager'
import { MenuItem } from 'UI/common/menus/menuItems/menuItems'

export const OtherMenuItems = (translate: LanguageType) => {
    return [
        new MenuItem(
            translate.navigation.cashback,
            CustomerRM.cashback.path.list
        ),
    ]
}
