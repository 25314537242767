import React, { FC } from 'react'
import classes from './LicenseCard.module.scss'
import edit from '../../../../assets/icons/edit.svg'
import deleteIcon from '../../../../assets/icons/delete.svg'
import { BeatLicenseType } from '../../../../../DAL/Services'
import { DraggableProvided } from 'react-beautiful-dnd'
import { LanguageEnum } from '../../../../../BLL/enums'
import { useTranslation } from 'react-i18next'
import { LanguageType } from '../../../../../translations/ru/common'
import { getLocaleDateString } from '../../../../utils/util-types'
import Tooltip from '@material-ui/core/Tooltip'
import { NavLink } from 'react-router-dom'
import { CustomerRM } from '../../../../routers/RouterManager'

type PropsType = {
    license: BeatLicenseType
    provided: DraggableProvided
    isDragging: boolean
}
export const LicenseCard: FC<PropsType> = ({
    license,
    provided,
    isDragging,
}) => {
    const { i18n } = useTranslation()
    const {
        buttons,
        licenses,
        license: customerMyLicense,
    } = i18n.getResourceBundle(i18n.language, 'common') as LanguageType
    const isEng = i18n.language === 'en'
    const lang = i18n.language as LanguageEnum
    return (
        <div className={classes.LicenseCard}>
            <div className={classes.options}>
                <div className={classes.item}>
                    <span className={classes.title}>
                        {customerMyLicense.tableColumns.title}
                    </span>
                    <span className={classes.value}>
                        {isEng ? license.titleEn : license.title}
                    </span>
                </div>
                <div className={classes.item}>
                    <span className={classes.title}>
                        {customerMyLicense.tableColumns.price}:
                    </span>
                    <span className={classes.value}>
                        {!isEng && `₽ ${license.price} /`}${license.priceDollar}
                    </span>
                </div>
                <div className={classes.item}>
                    <span className={classes.title}>
                        {customerMyLicense.tableColumns.createDate}:
                    </span>
                    <span className={classes.value}>
                        {getLocaleDateString(
                            i18n.language,
                            license.createdDate
                        )}
                    </span>
                </div>
            </div>
            <div className={classes.btnBox}>
                <Tooltip title={buttons.EDIT}>
                    <NavLink
                        to={CustomerRM.contracts.getUrl.license.edit(
                            license.id
                        )}
                    >
                        <img
                            className={classes.icons}
                            src={edit}
                            alt={'edit'}
                        />
                    </NavLink>
                </Tooltip>
                <Tooltip title={buttons.DELETE}>
                    <NavLink
                        to={CustomerRM.contracts.getUrl.license.delete(
                            license.id
                        )}
                    >
                        <img
                            className={classes.icons}
                            src={deleteIcon}
                            alt={'delete'}
                        />
                    </NavLink>
                </Tooltip>
                {/*<button>*/}
                {/*    <img src={edit} alt="edit" />*/}
                {/*</button>*/}
                {/*<button>*/}
                {/*    <img src={deleteIcon} alt="delete" />*/}
                {/*</button>*/}
            </div>
        </div>
    )
}
