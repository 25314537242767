import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { LanguageType } from '../../../translations/ru/common'
import classes from './beats-promo.module.scss'
import { Modal } from '../../common/modal/modal'
import { Button } from '../../common/elements/buttons/Button'
import icon from '../../assets/icons/btnTooltip.svg'

type InfoPropsType = {
    showInfo: boolean
    setShowInfo: (value: boolean) => void
}

export const InfoPopUp: FC<InfoPropsType> = ({ showInfo, setShowInfo }) => {
    const { i18n } = useTranslation()
    const { promotionsType, calendar, customerBeatsPromotion } =
        i18n.getResourceBundle(i18n.language, 'common') as LanguageType
    return (
        <div className={classes.tooltipWrapper}>
            <Modal
                active={showInfo}
                setActive={setShowInfo}
                title={customerBeatsPromotion.infoAdvertising}
            >
                <div className={classes.insideWrapper}>
                    <ul>
                        <li>
                            <b>{promotionsType[0]}</b>
                            {calendar.htmlToltip.player}
                        </li>
                        <li>
                            <b>{promotionsType[1]}</b>{' '}
                            {calendar.htmlToltip.mainPage}
                        </li>
                        <li>
                            <b>{promotionsType[2]}</b>{' '}
                            {calendar.htmlToltip.catalog}
                        </li>
                        <li>
                            <b>{promotionsType[4]}</b>{' '}
                            {calendar.htmlToltip.PromoInTheChart}
                        </li>
                        <li>
                            <b>{promotionsType[5]}</b>{' '}
                            {calendar.htmlToltip.searchInCatalog}
                        </li>
                    </ul>
                    <a
                        href="https://beatmaker.tv/help/reklama_na_sajte"
                        target={'_blank'}
                        rel="noreferrer"
                    >
                        <Button title={calendar.more} />
                    </a>
                </div>
            </Modal>
            <div className={classes.info}>
                <img
                    src={icon}
                    width={18}
                    height={18}
                    alt="Info"
                    onClick={() => setShowInfo(true)}
                />
                <label>{customerBeatsPromotion.advertisingBeatsInfo}</label>
            </div>
        </div>
    )
}
