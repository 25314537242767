import Tooltip from '@material-ui/core/Tooltip'
import { StatusPublicationEnum } from 'BLL/enums'
import { PublicationType } from 'BLL/types'
import { MyBeatType } from 'DAL/BeatSettingsAPI'
import React, { FC, useCallback } from 'react'
import { DraggableProvided } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { NavLink, useHistory } from 'react-router-dom'
import { LanguageType } from 'translations/ru/common'
import CancelPublishIcon from 'UI/assets/icons/cancel-publish.svg'
import deleteIcon from 'UI/assets/icons/delete.svg'
import edit from 'UI/assets/icons/edit.svg'
import PublishIcon from 'UI/assets/icons/publish.svg'
import { BeatCover } from 'UI/common/components/BeatCover/BeatCover'
import { BeatLabeledDate } from 'UI/common/components/BeatAddedDate/BeatAddedDate'
import classes from 'UI/CustomerAdmin/Mobile/MobMyBeatsPage/common/styles/MobBeatItem.module.scss'
import { CustomerRM } from 'UI/routers/RouterManager'
import { MobBeatDescription } from 'UI/CustomerAdmin/Mobile/MobMyBeatsPage/common/MobBeatDescription/MobBeatDescription'
import { DragIcon } from 'UI/common/components/DragIcon/DragIcon'

type PropsType = {
    provided: DraggableProvided
    isDragging: boolean
    beat: MyBeatType
    publication?: PublicationType
    playHandler: () => void
    isPlayingMe: boolean
}
export const MobBeatItem: FC<PropsType> = ({
    provided,
    isDragging,
    beat,
    publication,
    playHandler,
    isPlayingMe,
}) => {
    const { i18n } = useTranslation()
    const { customerBeatsPublication, buttons, myBeats } =
        i18n.getResourceBundle(i18n.language, 'common') as LanguageType
    const history = useHistory()
    const handleEditBeat = useCallback(() => {
        history.push(CustomerRM.myBeats.getUrl.beats.edit(beat.id))
    }, [history, beat.id])

    return (
        <div className={classes.beatItem}>
            <div className={classes.beatBox}>
                <div className={classes.beatInfo}>
                    <BeatCover
                        beat={beat}
                        playHandler={playHandler}
                        isPlayingMe={isPlayingMe}
                        isMobile={true}
                    />
                    <div>
                        <span className={classes.track}>{beat.title}</span>
                        <p className={classes.trackDate}>
                            <BeatLabeledDate
                                date={beat.addedToCatalogDate}
                                title={
                                    customerBeatsPublication.tableColumns
                                        .publishedDate
                                }
                            />
                            <BeatLabeledDate
                                title={customerBeatsPublication.waitPublished}
                                date={publication?.date}
                            />
                        </p>
                    </div>
                </div>
                <DragIcon
                    provided={provided}
                    isDrag={isDragging}
                    isMobile={true}
                />
            </div>
            <MobBeatDescription
                downloads={beat.downloads}
                plays={beat.plays}
                chartPosition={beat.chartPosition}
            />
            <div className={classes.actions}>
                <Tooltip title={buttons.EDIT}>
                    <img
                        className={classes.icons}
                        src={edit}
                        onClick={handleEditBeat}
                        alt={'edit'}
                    />
                </Tooltip>

                {!beat.addedToCatalogDate ? (
                    <Tooltip title={buttons.DELETE}>
                        <NavLink
                            to={CustomerRM.myBeats.getUrl.publications.delete(
                                beat.id
                            )}
                        >
                            <img
                                className={classes.icons}
                                src={deleteIcon}
                                alt={'delete'}
                            />
                        </NavLink>
                    </Tooltip>
                ) : (
                    <Tooltip title={buttons.DELETE_FROM_CATALOG}>
                        <NavLink
                            to={CustomerRM.myBeats.getUrl.publications.cancel(
                                beat.id
                            )}
                        >
                            <img
                                className={classes.icons}
                                src={deleteIcon}
                                alt={'delete'}
                            />
                        </NavLink>
                    </Tooltip>
                )}
                {publication && (
                    <Tooltip title={buttons.PUBLISHED}>
                        <NavLink
                            to={CustomerRM.myBeats.getUrl.publications.edit(
                                beat.id
                            )}
                        >
                            <img
                                className={classes.icons}
                                src={PublishIcon}
                                onClick={handleEditBeat}
                                alt={'edit'}
                            />
                        </NavLink>
                    </Tooltip>
                )}
                {publication && (
                    <div>
                        {publication.status !==
                            StatusPublicationEnum.NotPublished && (
                            <Tooltip
                                title={
                                    customerBeatsPublication.cancelPublication
                                }
                            >
                                <NavLink
                                    to={CustomerRM.myBeats.getUrl.publications.cancelPublications(
                                        publication._id
                                    )}
                                >
                                    <img
                                        className={classes.icons}
                                        src={CancelPublishIcon}
                                        onClick={handleEditBeat}
                                        alt={'edit'}
                                    />
                                </NavLink>
                            </Tooltip>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}
