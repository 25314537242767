import React, { useCallback, useState } from 'react'
import { Button } from 'UI/common/elements/buttons/Button'
import { Coupons } from '../../CustomerDiscounts/coupons/Coupons'
import { GroupDiscount } from '../../CustomerDiscounts/groupDiscounts/GroupDiscount'
import { createDiscount } from 'BLL/CustomerDiscountsReducer'
import { useDispatch } from 'react-redux'
import { CustomerRM } from 'UI/routers/RouterManager'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'
import { NewCustomSelect } from 'UI/common/elements/selects/NewCustomSelect'
import s from './MobDiscountsPage.module.scss'

export const MobDiscountsPage = () => {
    const dispatch = useDispatch()

    const { i18n } = useTranslation()
    const { buttons, discounts } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    const [options, setOptions] = useState({ value: 'coupons' })

    const isCoupons = options?.value === 'coupons'

    const handleCreateDiscount = useCallback(() => {
        dispatch(createDiscount())
    }, [])

    return (
        <>
            <div className={s.selectField}>
                <NewCustomSelect
                    onChange={setOptions}
                    setValue={setOptions}
                    defaultValue={{
                        value: 'coupons',
                        label: discounts.coupons,
                    }}
                    options={[
                        { value: 'coupons', label: discounts.coupons },
                        { value: 'discounts', label: discounts.discounts },
                    ]}
                ></NewCustomSelect>
            </div>
            <div className={s.btnBox}>
                {isCoupons ? (
                    <Button
                        view={'Primary'}
                        title={buttons.ADD_NEW_DISCOUNT}
                        onClick={handleCreateDiscount}
                    />
                ) : (
                    <NavLink to={CustomerRM.discounts.create.path}>
                        <Button title={buttons.ADD_NEW_DISCOUNT} />
                    </NavLink>
                )}
            </div>
            {isCoupons ? <Coupons /> : <GroupDiscount />}
        </>
    )
}
