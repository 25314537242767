import React, { FC } from 'react'
import classes from './SaleTrack.module.scss'
import {
    getLinkContract,
    getLinkImage,
} from '../../../../CustomerMySales/MySalesInformation/MySalesInformation'
import { PaymentBeatDetails, SaleType } from '../../../../../../DAL/mySalesAPI'
import { AppSettings } from 'settings/appSettings'
import {
    getLocaleDateFullString,
    getLocaleDateString,
} from '../../../../../utils/util-types'
import { useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import { LanguageType } from '../../../../../../translations/ru/common'

type PropsType = {
    item: SaleType & PaymentBeatDetails
}
export const SaleTrack: FC<PropsType> = ({ item }) => {
    const { id } = useParams<{ id: string }>()
    const { i18n } = useTranslation()
    const { myBeats, buttons, mySales } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType
    const contractExist = AppSettings.api.isEngVersion()
        ? item.engPdfExists
        : item.pdfExists
    return (
        <div className={classes.saleTrack}>
            <div className={classes.track}>
                <img
                    width={50}
                    height={50}
                    src={getLinkImage(item.beatId)}
                    alt="beatImage"
                />
                <div>
                    <span className={classes.trackName}>{item.beatTitle}</span>
                    {item.paidDate && (
                        <span className={classes.published}>
                            {mySales.saleDetails.paid}:{' '}
                            {getLocaleDateString(i18n.language, item.paidDate)}
                        </span>
                    )}
                </div>
            </div>
            <div className={classes.actions}>
                <div className={classes.item}>
                    <span className={classes.itemTitle}>
                        {' '}
                        {mySales.saleDetails.license}:
                    </span>
                    <span className={classes.beatLicense}>
                        {AppSettings.api.isEngVersion()
                            ? item.licenseTitleEng
                            : item.licenseTitle}
                    </span>
                </div>
                {contractExist && (
                    <div className={classes.item}>
                        <span className={classes.itemTitle}>
                            {' '}
                            {mySales.saleDetails.contract}:
                        </span>

                        <a
                            className={classes.itemLink}
                            href={getLinkContract(
                                item.beatId,
                                item.paymentId,
                                item.buyerId
                            )}
                        >
                            {mySales.saleDetails.contract}
                        </a>
                    </div>
                )}
            </div>
        </div>
    )
}
