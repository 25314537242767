import { axiosCore } from './createAxios'
import { LanguageEnum } from '../BLL/enums'
import { BeatLicenseType } from './Services'
import { BaseResponse } from '../BLL/types'

export const LicenseAPI = {
    prefixURL: '/BeatsManagement/BeatLicenses',

    getLicenses() {
        return axiosCore
            .get<{ licenses: LicenseItem[] }>(`${this.prefixURL}/GetItems`)
            .then((r) =>
                r.data.licenses.map((license) =>
                    LicenseMapper.mapToBll(license)
                )
            )
    },

    getLicense(licenseId: string) {
        return axiosCore
            .get<LicenseItem>(`${this.prefixURL}/Details/${licenseId}`)
            .then((r) => LicenseMapper.mapToBll(r.data))
    },

    getLicenseOption(licenseId: string, lang?: LanguageEnum) {
        return axiosCore
            .get<{ options: OptionsItem[] }>(
                `${this.prefixURL}/GetOptions?licenseId=${licenseId}&lang=${lang}`
            )
            .then((r) => r.data)
    },

    createLicense(
        createLicenseDto: LicenseModel
    ): Promise<BaseResponse<{ itemId: string }>> {
        return axiosCore
            .post<BaseResponse<{ itemId: string }>>(
                `${this.prefixURL}/CreateItem`,
                createLicenseDto
            )
            .then((r) => r.data)
    },

    updateLicense(createLicenseDto: LicenseModel) {
        return axiosCore
            .put<BaseResponse>(`${this.prefixURL}/UpdateItem`, createLicenseDto)
            .then((r) => r.data)
    },

    deleteLicense(licenseId: string) {
        return axiosCore
            .delete<BaseResponse>(
                `${this.prefixURL}/DeleteItem?id=${licenseId}`
            )
            .then((r) => r.data)
    },

    updateOrderForLicenses(ids: string[]) {
        return axiosCore
            .put<BaseResponse>(`${this.prefixURL}/UpdateOrderForLicenses`, {
                ids,
            })
            .then((r) => r.data)
    },

    updateOrderForLicenseOptions(ids: string[], licenseId: string) {
        return axiosCore
            .put(`${this.prefixURL}/UpdateOrderForLicenseOptions`, {
                ids,
                licenseId,
            })
            .then((r) => r.data)
    },

    createLicenseOption(licenseId: string) {
        return axiosCore
            .post<{ data: { item: OptionsItem['point'] } }>(
                `${this.prefixURL}/CreateLicenseOption`,
                { licenseId }
            )
            .then((r) => r.data)
    },

    updateLicenseOption(updateLicenseOption: UpdateLicenseOption) {
        return axiosCore
            .put<BaseResponse>(
                `${this.prefixURL}/UpdateLicenseOption`,
                updateLicenseOption
            )
            .then((r) => r.data)
    },

    deleteLicenseOption(optionId: string) {
        return axiosCore
            .delete<BaseResponse>(
                `${this.prefixURL}/DeleteLicenseOption?optionId=${optionId}`
            )
            .then((r) => r.data)
    },
}
//7004a686-5c11-4d72-aea7-63c199b0518f

export type BeatLicense = {
    title: string
    priceInRubles: number
    priceInDollars: number
    recommended: boolean
    purchaseIsNotAvailable: boolean
    requireClientPassport: boolean
    removeBeatFromSale: boolean
}

export type LicenseModel = {
    license: BeatLicense
    translate: string
}

export type LicenseItem = {
    license: {
        id: string
        beatmakerId: number
        changedDate: Date
        createdDate: Date
        isDeleted: boolean
        order: number
        beatLicensePoints: null
        beatLicenseTranslates: null
    } & BeatLicense
    translate: string
}

export type OptionsItem = {
    point: {
        id: string
        beatLicenseId: string
        beatLicense: null
        title: string
        order: number
        beatLicensePointTranslates: null
    }
    translate: string
}

export type UpdateLicenseOption = {
    optionId: string
    title: string
    lang?: any
}

export const LicenseMapper = {
    mapToBll(licenseApiDto: LicenseItem): BeatLicenseType {
        return {
            _id: licenseApiDto.license.id,
            id: licenseApiDto.license.id,
            beatId: null,
            title: licenseApiDto.license.title,
            contract: false,
            price: licenseApiDto.license.priceInRubles,
            priceDollar: licenseApiDto.license.priceInDollars,
            titleEn: licenseApiDto.translate,
            recommended: licenseApiDto.license.recommended,
            removeBeatFromSale: licenseApiDto.license.removeBeatFromSale,
            purchaseIsNotAvailable:
                licenseApiDto.license.purchaseIsNotAvailable,
            requireClientPassport: licenseApiDto.license.requireClientPassport,
            createdDate: licenseApiDto.license.createdDate,
        }
    },
}
