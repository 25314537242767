import React from 'react'
import 'UI/common/progressBars/bmcoreLoader/bmcoreLoader.scss'

export const BmCoreLoader = () => {
    return (
        <div className="loader">
            <div className="line line1" />
            <div className="line line2" />
            <div className="line line3" />
        </div>
    )
}
