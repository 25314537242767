import React, { FC, useEffect, useState } from 'react'
import classes from './CustomerCashBackHeader.module.scss'
import { CustomCheckbox } from '../../../common/elements/CustomCheckbox'
import { ButtonTooltip } from '../../../common/elements/buttons/ButtonTooltip'
import rub from '../../../assets/icons/rub.svg'
import usd from '../../../assets/icons/usd.svg'
import { AppSettings } from 'settings/appSettings'
import { useTranslation } from 'react-i18next'
import { LanguageType } from '../../../../translations/ru/common'
import {
    getCashbackSettings,
    updateCashbackSettings,
} from '../../../../BLL/BeatmakerSettingsReducer'
import { useSelector } from '../../../common/hooks/redux-hooks'
import { useDispatch } from 'react-redux'
import { RequestStatuses } from '../../../../BLL/AppReducer'
import { MobCashback } from '../../Mobile/MobCashback/MobCashback'
import { CustomBrowserView } from '../../../common/browserView/CustomBrowserView'
import { CustomMobileView } from '../../../common/mobileView/CustomMobileView'

type PropsType = {
    amountRub: number
    amountUsd: number
}
export const CustomerCashbackHeader: FC<PropsType> = ({
    amountRub,
    amountUsd,
}) => {
    const isEng = AppSettings.api.isEngVersion()
    const dispatch = useDispatch()
    const isBeatmaker = useSelector((state) => state.appReducer.isBeatmaker)
    const cashbackSettings = useSelector(
        (state) => state.beatmakerSetting.settings.cashback
    )
    const requestStatus = useSelector((state) => state.appReducer.requestStatus)
    const [cashbackIsActive, setCashbackIsActive] = useState<boolean>(
        cashbackSettings.isActive
    )
    const { i18n } = useTranslation()
    const { navigation, cashbacks, settings } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType
    useEffect(() => {
        dispatch(getCashbackSettings())
    }, [])

    useEffect(() => {
        cashbackSettings && setCashbackIsActive(cashbackSettings.isActive)
    }, [cashbackSettings])

    const handleUpdateCashback = async () => {
        const settings = {
            cashback: {
                isActive: !cashbackIsActive,
            },
        }
        await dispatch(updateCashbackSettings(settings))
        setCashbackIsActive(!cashbackIsActive)
    }

    return (
        <div className={classes.header}>
            <CustomBrowserView>
                {isBeatmaker && (
                    <div className={classes.cashbackBox}>
                        <h1 className={classes.title}>{navigation.cashback}</h1>
                        <div className={classes.checkBox}>
                            <CustomCheckbox
                                checked={cashbackIsActive}
                                onChange={handleUpdateCashback}
                                disabled={
                                    requestStatus === RequestStatuses.InProgress
                                }
                            />
                            <label className={classes.checkBoxText}>
                                {settings.cashback}
                            </label>
                            <ButtonTooltip>{cashbacks.tooltip}</ButtonTooltip>
                        </div>
                    </div>
                )}
                <div className={classes.amountBox}>
                    <h2 className={classes.subtitle}>{cashbacks.helpText}</h2>
                    <span className={classes.price}>
                        <img src={usd} alt="" />
                        {amountUsd}
                    </span>
                    {!isEng && (
                        <span className={classes.price}>
                            <img src={rub} alt="" />
                            {amountRub}
                        </span>
                    )}
                </div>
            </CustomBrowserView>
            <CustomMobileView>
                <MobCashback
                    amountRub={amountRub}
                    amountUsd={amountUsd}
                    cashbackIsActive={cashbackIsActive}
                    handleUpdateCashback={handleUpdateCashback}
                />
            </CustomMobileView>
        </div>
    )
}
