import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'
import { useSelector } from 'UI/common/hooks/redux-hooks'
import Table from '../../../common/table/Table'
import Header from '../../../common/header/Header'
import { getTemplatesAll } from 'BLL/AdminTemplatesEmailReducer'
import { useDispatch } from 'react-redux'
import { CreateTemplateDialog } from '../create-dialog/CreateTemplateDialog'
import { Grid } from '@material-ui/core'
import { EmailTemplateType } from 'BLL/types'
import { Route, useHistory } from 'react-router-dom'
import { DeleteTemplateDialog } from '../delete-dialog/DeleteTemplateDialog'
import { AdminRM } from 'UI/routers/RouterManager'
import { getEmailTemplatesTableData } from 'UI/common/table/tableData/GetEmailTemplatesTableData'
import s from './templates.module.css'

export const TemplatesPage = () => {
    const { i18n } = useTranslation()
    const { navigation } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType
    const templates = useSelector(
        (state) => state.adminTemplatesEmail.templates
    )
    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        dispatch(getTemplatesAll())
    }, [dispatch])

    const handleDeleteTemplate = (id: string) => {
        history.push(AdminRM.templates.getUrl.delete(id))
    }

    return (
        <div className={s.detailPanelWrapper}>
            <Header title={navigation.emailTemplates} />
            <Grid container className={s.root} spacing={2}>
                <Grid item>
                    <CreateTemplateDialog />
                </Grid>
                <Grid item>
                    <Table
                        title={navigation.emailTemplates}
                        items={templates}
                        fields={getEmailTemplatesTableData(i18n)}
                        actions={[
                            {
                                icon: 'delete',
                                tooltip: 'Delete template',
                                onClick: (
                                    event: any,
                                    template: EmailTemplateType
                                ) => handleDeleteTemplate(template._id),
                            },
                            {
                                icon: 'edit',
                                tooltip: 'Edit template',
                                onClick: (
                                    event: any,
                                    template: EmailTemplateType
                                ) =>
                                    history.push(
                                        AdminRM.templates.getUrl.edit(
                                            template._id
                                        )
                                    ),
                            },
                        ]}
                    />
                </Grid>
            </Grid>
            <Route
                path={AdminRM.templates.path.delete}
                render={() => <DeleteTemplateDialog />}
            />
        </div>
    )
}
