import React, { FC, useCallback, useEffect, useState } from 'react'
import classes from './customer-subscribers.module.scss'
import 'dayjs/locale/ru'
import './RangeDatePicker.scss'
import {
    actions as subscribersActions,
    deleteSubscriber,
    getSubscribers,
    IDatePeriod,
    SourceType,
} from '../../../BLL/CustomerSubscribersReducer'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'
import { useDispatch } from 'react-redux'
import { useSelector } from 'UI/common/hooks/redux-hooks'
import { useRedirectCustomHook } from 'UI/common/hooks/useRedirectCustomHook'
import { RolesTypeEnum } from 'BLL/enums'
import Table from '../../common/table/Table'
import { ISubscribers } from 'DAL/SubscribersAPI'
import { CustomDatePicker } from 'UI/common/elements/datePicker/CustomDatePicker'
import * as dateFns from 'date-fns'
import { CustomSelect } from 'UI/common/elements/CustomSelect'
import { useForm } from 'react-hook-form'
import { Button } from 'UI/common/elements/buttons/Button'
import { generateAndDownloadTxtFile } from 'UI/utils/util-types'
import { MobFollowers } from '../Mobile/MobFollowers/MobFollowers'
import { Pagination } from 'UI/common/elements/pagination/Pagination'
import { CustomBrowserView } from 'UI/common/browserView/CustomBrowserView'
import { CustomMobileView } from 'UI/common/mobileView/CustomMobileView'
import { getSubscribersTableData } from 'UI/common/table/tableData/GetSubscribersTableData'

export const Subscribers: FC = () => {
    const { i18n } = useTranslation()
    const {
        customerBeatSubscribers,
        buttons,
        navigation,
        sourceType: sourceTypeLang,
    } = i18n.getResourceBundle(i18n.language, 'common') as LanguageType
    const dispatch = useDispatch()
    const {
        register,
        formState: { errors },
        control,
        watch,
    } = useForm()
    const [from, setFrom] = useState<Date | null>(
        dateFns.addDays(new Date(), -30)
    )
    const [to, setTo] = useState<Date | null>(new Date())

    const subscribers = useSelector(
        (state) => state.customerSubscribers.subscribers
    )
    const totalSubscribers = useSelector(
        (state) => state.customerSubscribers.totalSubscribers
    )
    const pageSize = useSelector((state) => state.customerSubscribers.pageSize)
    const currentPage = useSelector(
        (state) => state.customerSubscribers.currentPage
    )
    const datePeriod = useSelector(
        (state) => state.customerSubscribers.datePeriod
    )
    const sourceType = useSelector(
        (state) => state.customerSubscribers.sourceType
    )

    useEffect(() => {
        dispatch(getSubscribers())
    }, [])

    useEffect(() => {
        dispatch(getSubscribers())
    }, [pageSize, currentPage, datePeriod, sourceType])

    const handleSelectValue = (selectedPromoType: SourceType) => {
        dispatch(subscribersActions.setSourceType(selectedPromoType))
    }

    const handleClearFilter = () => {
        dispatch(subscribersActions.setClearFilter())
        dispatch(getSubscribers())
    }

    const handleDeleteSubscribe = (id: string) => {
        dispatch(deleteSubscriber(id))
    }

    const setPageSize = (pageSize: number) => {
        dispatch(subscribersActions.setPageSize(pageSize))
    }

    const setCurrentPage = (currentPage: number) => {
        dispatch(subscribersActions.setCurrentPage(currentPage))
    }

    const setDatePeriod = (datePeriod: IDatePeriod) => {
        dispatch(subscribersActions.setDatePeriod(datePeriod))
    }
    const options = Object.keys(sourceTypeLang).map((key) => {
        return { value: Number(key), label: sourceTypeLang[+key] }
    })

    useEffect(() => {
        let fromDate = from
        let toDate = to
        if (!fromDate) {
            fromDate = dateFns.addDays(new Date(), -30)
        }
        if (!toDate) {
            toDate = new Date()
        }
        setDatePeriod({
            fromDate: dateFns.format(fromDate, 'yyyy-MM-dd'),
            toDate: dateFns.format(toDate, 'yyyy-MM-dd'),
        })
    }, [from, to])

    useEffect(() => {
        watch('sourceType') && handleSelectValue(watch('sourceType'))
    }, [watch('sourceType')])

    const handleDownloadBuyersList = useCallback(() => {
        const items = subscribers.map((s) => {
            return `${s.subscriber.name}, ${s.subscriber.email}`
        })
        generateAndDownloadTxtFile(items, 'subscribers')
    }, [subscribers])

    return (
        <div>
            <div className={classes.topWrapper}>
                <div className={classes.pageTitle}>
                    {navigation.mySubscribers}
                </div>
            </div>
            <div>
                {useRedirectCustomHook(RolesTypeEnum.BEATMAKER)}
                <CustomBrowserView>
                    <div className={classes.wrapperFilter}>
                        <div className={classes.dateInner}>
                            <div>
                                <div className={classes.label}>
                                    {customerBeatSubscribers.period.start}
                                </div>
                                <CustomDatePicker
                                    date={from || new Date()}
                                    disabled={false}
                                    handleChange={setFrom}
                                    locale={i18n.language as 'ru' | 'en'}
                                />
                            </div>
                            <div>
                                <div className={classes.label}>
                                    {customerBeatSubscribers.period.end}
                                </div>
                                <CustomDatePicker
                                    date={to || new Date()}
                                    disabled={false}
                                    handleChange={setTo}
                                    locale={i18n.language as 'ru' | 'en'}
                                />
                            </div>
                            <div className={classes.selectItem}>
                                <label className={classes.titleItem}>
                                    {customerBeatSubscribers.pageSubscribed}
                                </label>
                                <CustomSelect
                                    control={control}
                                    register={register}
                                    options={options}
                                    name={'sourceType'}
                                    // errorMessage={errors?.styleId?.message}
                                />
                            </div>
                        </div>
                        <div>
                            <Button
                                view={'Outline'}
                                title={buttons.DOWNLOAD_EMAIL}
                                onClick={handleDownloadBuyersList}
                                disabled={!subscribers.length}
                            />
                        </div>
                    </div>
                    <div>
                        <Table<ISubscribers>
                            items={subscribers}
                            title={''}
                            fields={getSubscribersTableData(i18n)}
                        />
                        {!!totalSubscribers && (
                            <Pagination
                                currentPage={currentPage}
                                onChangePage={setCurrentPage}
                                onChangeRowsPerPage={setPageSize}
                                pageSize={pageSize}
                                totalCount={totalSubscribers}
                            />
                        )}
                    </div>
                </CustomBrowserView>
                <CustomMobileView>
                    <MobFollowers
                        subscribers={subscribers}
                        handleDeleteSubscribe={handleDeleteSubscribe}
                        from={from}
                        setFrom={setFrom}
                        setTo={setTo}
                        to={to}
                        handleDownloadBuyersList={handleDownloadBuyersList}
                        control={control}
                        register={register}
                        options={options}
                    />
                    {subscribers.length > pageSize && (
                        <div className={classes.pagination}>
                            <Pagination
                                currentPage={currentPage}
                                onChangePage={setCurrentPage}
                                onChangeRowsPerPage={setPageSize}
                                pageSize={pageSize}
                                totalCount={totalSubscribers}
                            />
                        </div>
                    )}
                </CustomMobileView>
            </div>
        </div>
    )
}
