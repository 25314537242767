import { InferActionsTypes } from './Store'
import {
    actions as appActions,
    AuthActionsTypes,
    EditingEntityStatuses,
} from './AppReducer'
import {
    commonAsyncHandler,
    verifyApiResultResponse,
} from './common-async-handler'
import {
    BeatSettingsAPI,
    MyBeatType,
    PaginationResponseType,
} from 'DAL/BeatSettingsAPI'
import { ThunkActionType } from './types'

const initState = {
    pagesCount: 0,
    pageSize: 100,
    page: 1,
    totalCount: 0,
    items: [] as MyBeatType[],
}

type StateType = typeof initState

export type ActionsTypes =
    | InferActionsTypes<typeof archiveBeatsActions>
    | AuthActionsTypes

const customerArchiveBeatsReducer = (
    state = initState,
    action: ActionsTypes
): StateType => {
    switch (action.type) {
        case 'ARCHIVED_BEATS_REDUCER/SET_BEATS':
            return {
                ...state,
                ...action.beatsData,
            }
        case 'ARCHIVED_BEATS_REDUCER/SET_PAGE':
            return {
                ...state,
                page: action.page,
            }
        case 'ARCHIVED_BEATS_REDUCER/SET_PAGE_COUNT':
            return {
                ...state,
                pageSize: action.pageSize,
            }
        default:
            return state
    }
}

export const archiveBeatsActions = {
    setBeatsData: (beatsData: PaginationResponseType<MyBeatType>) =>
        ({
            type: 'ARCHIVED_BEATS_REDUCER/SET_BEATS',
            beatsData,
        } as const),
    setPage: (page: number) =>
        ({ type: 'ARCHIVED_BEATS_REDUCER/SET_PAGE', page } as const),
    setPageSize: (pageSize: number) =>
        ({ type: 'ARCHIVED_BEATS_REDUCER/SET_PAGE_COUNT', pageSize } as const),
}

export const getArchiveBeats =
    (
        page: number = 1,
        pageSize: number = 50,
        term = ''
    ): ThunkActionType<ActionsTypes> =>
    async (dispatch, getState) => {
        await commonAsyncHandler(async () => {
            const beatsData = await BeatSettingsAPI.getBeatsArchive(
                pageSize,
                page,
                term
            )

            dispatch(archiveBeatsActions.setBeatsData(beatsData))
            return beatsData
        }, dispatch)
    }

export const deleteArchiveBeat =
    (beatId: number): ThunkActionType<ActionsTypes> =>
    async (dispatch, getState) => {
        await commonAsyncHandler(async () => {
            const response = await BeatSettingsAPI.deleteBeat(beatId)
            verifyApiResultResponse(response, dispatch, () => {
                dispatch(
                    appActions.setEditingEntityStatus(
                        EditingEntityStatuses.Success
                    )
                )
                const { pageSize, page } = getState().myBeatsPage
                dispatch(getArchiveBeats(page, pageSize))
            })
            return response
        }, dispatch)
    }

export const restoreArchiveBeat =
    (beatId: number): ThunkActionType<ActionsTypes> =>
    async (dispatch, getState) => {
        console.log(beatId, 'id')
        await commonAsyncHandler(async () => {
            const response = await BeatSettingsAPI.restoreBeatFromArchive(
                beatId
            )
            verifyApiResultResponse(response, dispatch, () => {
                dispatch(
                    appActions.setEditingEntityStatus(
                        EditingEntityStatuses.Success
                    )
                )
                const { pageSize, page } = getState().myBeatsPage
                dispatch(getArchiveBeats(page, pageSize))
            })
            return response
        }, dispatch)
    }

export default customerArchiveBeatsReducer
