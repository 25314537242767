import { EmailTemplateType } from 'BLL/types'
import { LanguageType } from 'translations/ru/common'
import { TableType } from '../types'
import { i18n } from 'i18next'

export const getEmailTemplatesTableData = (
    i18n: i18n
): TableType<EmailTemplateType> => {
    const { adminEditTemplate } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType
    return [
        { title: adminEditTemplate.tableColumns.name, field: 'name' },
        {
            title: adminEditTemplate.tableColumns.templateKey,
            field: 'templateKey',
        },
        { title: adminEditTemplate.tableColumns.redactor, field: 'author' },
    ]
}
