import React, { DragEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'
import { AppSettings } from 'settings/appSettings'
import { BrowseLinkButton } from 'UI/common/elements/buttons/BrowseLinkButton'
import { SelectedModal } from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/types/types'
import s from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/beatspage.module.scss'
import DragIcon from 'UI/assets/icons/drag.svg'

type Props = {
    uploadFilesHandler: (file: File | FileList) => void
    customMessage?: string
    type: SelectedModal
}
export const UploadFilesPopup = ({
    uploadFilesHandler,
    customMessage,
    type,
}: Props) => {
    const [drag, setDrag] = useState(false)

    const isEngMode = AppSettings.api.isEngVersion()
    const { i18n } = useTranslation()
    const { myBeats } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    const dragStartHandler = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        setDrag(true)
    }

    const dragLeaveHandler = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        setDrag(false)
    }

    const onDropHandler = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        if (e.dataTransfer.files.length) {
            uploadFilesHandler(e.dataTransfer.files)
        }
        setDrag(false)
    }

    const noDragMessage = isEngMode ? (
        <div className={s.dragMessage}>
            <span>Drag and drop,</span>
            <BrowseLinkButton
                handleSaveFile={uploadFilesHandler}
                title={'browse'}
            />
            {type !== 'mp3' ? (
                <span>or link your file</span>
            ) : (
                <span>your file</span>
            )}
        </div>
    ) : (
        <div className={s.dragMessage}>
            <span>Перетащите файл, </span>
            <BrowseLinkButton
                handleSaveFile={uploadFilesHandler}
                title={'выберите'}
            />
            {type !== 'mp3' && <span>или укажите ссылку</span>}
        </div>
    )
    return (
        <div className={s.containerWrapper}>
            {drag ? (
                <div
                    onDragStart={dragStartHandler}
                    onDragLeave={dragLeaveHandler}
                    onDragOver={dragStartHandler}
                    onDrop={onDropHandler}
                    className={s.dropArea}
                >
                    {customMessage
                        ? customMessage
                        : myBeats.popupUploadsMessage.drag}
                </div>
            ) : (
                <div
                    onDragStart={dragStartHandler}
                    onDragLeave={dragLeaveHandler}
                    onDragOver={dragStartHandler}
                    className={s.dragArea}
                >
                    <img src={DragIcon} alt={'drag'} />
                    <div>{customMessage ? customMessage : noDragMessage}</div>
                </div>
            )}
        </div>
    )
}
