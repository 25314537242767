import React, { FC, useEffect, useState } from 'react'
import styles from './Calendar.module.css'
import * as dateFns from 'date-fns'
import { getCurrentMonth, STATUSES } from '../../../../BLL/SupportingFile'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { ru } from 'date-fns/locale'
import CircularDeterminate from '../../../common/progressBars/Spiner'
import { useTranslation } from 'react-i18next'
import { BusyDaysType } from '../../../../BLL/types'
import { useSelector } from '../../../common/hooks/redux-hooks'
import {
    getAllBusyDays,
    getStatusBusyDays,
} from '../../../../BLL/CustomerBeatsPromotionReducer'

const MAX_COUNT_DAY_ACTIVE_BOOKING = 60

type PropsType = {
    monthNumber: number
    year: number
    selectedDate: Date | null
    busyDayInfo: BusyDaysType
    refreshState: () => void
    previousMonth: () => void
    nextMonth: () => void
    setBusyDaysInfo: (busyDayInfo: BusyDaysType) => void
    setDate: (day: Date) => void
    nextYear: () => void
    previousYear: () => void
}

export const Calendar: FC<PropsType> = (props) => {
    const [currentMonth, setCurrentMonth] = useState(
        new Date(props.year, props.monthNumber - 1)
    )
    const { i18n } = useTranslation()
    const { calendar } = i18n.getResourceBundle(i18n.language, 'common')
    const busyDays = useSelector(getAllBusyDays)
    const statusBusyDays = useSelector(getStatusBusyDays)
    useEffect(() => {
        setCurrentMonth(new Date(props.year, props.monthNumber - 1))
    }, [props.monthNumber, props.selectedDate, props.year])

    const onDateClick = (day: Date) => {
        let busyDayInfo = busyDays.find(
            (b) => b.data === dateFns.format(day, 'yyyy-MM-dd')
        ) as BusyDaysType
        if (!busyDayInfo) {
            busyDayInfo = {
                busySlots: 0,
                busyUserSlots: 0,
                totalSlots: 0,
                isBusy: false,
                data: dateFns.format(day, 'yyyy-MM-dd'),
            }
        }
        props.setBusyDaysInfo(busyDayInfo)
        props.setDate(day)
    }

    return (
        <div>
            <div
                className={
                    statusBusyDays === STATUSES.LOADING
                        ? `${styles.calendar} ${styles.loaderCalendar}`
                        : `${styles.calendar}`
                }
            >
                <RenderCalendar
                    busyDays={busyDays}
                    selectedDate={props.selectedDate}
                    onDateClick={onDateClick}
                    currentMonth={currentMonth}
                    nextMonth={props.nextMonth}
                    previousMonth={props.previousMonth}
                    year={props.year}
                />
            </div>
            {props.busyDayInfo && props.selectedDate && (
                <div className={styles.busyDayInfo}>
                    <div className={styles.totalSlots}>
                        {calendar.totalSlots}: {props.busyDayInfo.busySlots}/
                        {props.busyDayInfo.totalSlots}
                    </div>
                    <div>
                        {calendar.busyUserSlots}:{' '}
                        {props.busyDayInfo.busyUserSlots || 0}
                    </div>
                </div>
            )}
        </div>
    )
}

type CalendarHeaderType = {
    currentMonth: Date
    year: number
    previousMonth: () => void
    nextMonth: () => void
}
const RenderCalendarHeader: FC<CalendarHeaderType> = ({
    currentMonth,
    year,
    previousMonth,
    nextMonth,
}) => {
    const { i18n } = useTranslation()
    const dateFormat = i18n.language === 'en' ? 'MMMM' : 'LLLL'
    const showPreviousMonthButton =
        currentMonth.getMonth() + 1 > getCurrentMonth()
    const currentYear = new Date().getFullYear()

    return (
        <div
            className={`${styles.calendarHeader} ${styles.row} ${styles.calendarNavigation}`}
        >
            <div className={styles.monthAndYearNavigation}>
                <div className={styles.wrapperForArrow}>
                    {(showPreviousMonthButton || year > currentYear) && (
                        <div
                            onClick={previousMonth}
                            className={styles.previousMonth}
                        >
                            <NavigateBeforeIcon />
                        </div>
                    )}
                </div>
                <span className={styles.nameOfMonth}>
                    {dateFns.format(currentMonth, dateFormat, {
                        //@ts-ignore
                        locale: i18n.language === 'en' ? '' : ru,
                    })}
                </span>
                <div className={styles.wrapperForArrow}>
                    <div onClick={nextMonth} className={styles.nextMonth}>
                        <NavigateNextIcon />
                    </div>
                </div>
            </div>
            <div className={styles.monthAndYearNavigation}>
                <span className={styles.nameOfMonth}>{year}</span>
            </div>
        </div>
    )
}

type CalendarDaysType = {
    currentMonth: Date
}
const RenderCalendarDays: FC<CalendarDaysType> = ({ currentMonth }) => {
    const dateFormat = 'eeeeee'
    const days = []
    const startDate = dateFns.startOfWeek(currentMonth)
    const { i18n } = useTranslation()

    for (let i = 0; i < 7; i++) {
        days.push(
            <div className={`${styles.col} ${styles.colCenter}`} key={i}>
                {dateFns
                    .format(dateFns.addDays(startDate, i), dateFormat, {
                        locale: i18n.language !== 'en' ? ru : undefined,
                    })
                    .substring(0, 2)}
            </div>
        )
    }
    return <div className={`${styles.days} ${styles.row}`}>{days} </div>
}

type CalendarCellsType = {
    busyDays: BusyDaysType[]
    currentMonth: Date
    selectedDate: Date | null
    onDateClick: (day: Date) => void
}
const RenderCalendarCells: FC<CalendarCellsType> = ({
    busyDays,
    currentMonth,
    selectedDate,
    onDateClick,
}) => {
    const monthStart = dateFns.startOfMonth(currentMonth)
    const monthEnd = dateFns.endOfMonth(monthStart)
    const startDate = dateFns.startOfWeek(monthStart)
    const endDate = dateFns.endOfWeek(monthEnd)
    const dateFormat = 'd'
    const rows = []

    let days = []
    let day = startDate
    let formattedDate = ''

    while (day <= endDate) {
        for (let i = 0; i < 7; i++) {
            formattedDate = dateFns.format(day, dateFormat)
            const cloneDay = new Date(day.getTime())
            const selectedDay = cloneDay.toString() === selectedDate?.toString()
            const isTodayClass =
                dateFns.isToday(cloneDay) &&
                dateFns.isSameMonth(currentMonth, cloneDay)
                    ? `${styles.today}`
                    : ''
            const currentDay = new Date()

            let busyDay = null
            let busyUserSlots = null

            for (let i = 0; i < busyDays.length; i++) {
                if (busyDays[i].isBusy) {
                    const busyDayFromServer = new Date(busyDays[i].data)
                    if (
                        dateFns.getDate(cloneDay) ===
                        dateFns.getDate(busyDayFromServer)
                    ) {
                        if (
                            dateFns.isSameMonth(
                                currentMonth,
                                busyDayFromServer.getTime()
                            )
                        ) {
                            busyDay = dateFns.getDate(cloneDay)
                            busyUserSlots = busyDays[i].busyUserSlots
                        }
                    }
                } else {
                    const busyDayFromServer = new Date(busyDays[i].data)
                    if (
                        dateFns.getDate(cloneDay) ===
                        dateFns.getDate(busyDayFromServer)
                    ) {
                        if (busyDays[i].busyUserSlots > 0) {
                            busyUserSlots = busyDays[i].busyUserSlots
                        }
                    }
                }
            }

            const disabledClassSelectedDays = busyDay ? styles.isBusy : ''
            const classBusyUserSlots = ''
            const disabledClassMaxDate =
                new Date(cloneDay) >=
                dateFns.addDays(new Date(), MAX_COUNT_DAY_ACTIVE_BOOKING)
                    ? styles.disabled
                    : ''
            const disabledClassToCurrentDay =
                dateFns.isSameMonth(currentMonth, currentDay.getTime()) &&
                dateFns.getDate(cloneDay) <= dateFns.getDate(currentDay)
                    ? styles.disabled
                    : ''

            const disableClassForDatesThatLessThenStartAdsDate =
                cloneDay < new Date(2020, 0, 1) ? styles.disabled : ''

            days.push(
                <div
                    className={`
                        ${styles.col} ${styles.cell} ${isTodayClass}
                        ${
                            !dateFns.isSameMonth(day, monthStart)
                                ? styles.disabled
                                : ''
                        }
                        ${
                            busyUserSlots &&
                            dateFns.isSameMonth(day, monthStart)
                                ? styles.userBusyDay
                                : ''
                        }
                        ${selectedDay && styles.selectedDay}
                        ${disabledClassToCurrentDay}
                        ${disabledClassSelectedDays}
                        ${classBusyUserSlots}
                        ${disableClassForDatesThatLessThenStartAdsDate}
                        ${disabledClassMaxDate}
                      `}
                    key={day.getTime()}
                    onClick={() => onDateClick(cloneDay)}
                >
                    {/*{busyUserSlots !== 0 &&*/}
                    {/*    dateFns.isSameMonth(day, monthStart) &&*/}
                    {/*    busyUserSlots}*/}
                    {/*</div>*/}
                    <div className={selectedDay ? styles.currentDay : ''}>
                        <div className={`${styles.number}`}>
                            {formattedDate}
                        </div>
                        {busyUserSlots &&
                            dateFns.isSameMonth(day, monthStart) && (
                                <div className={styles.tooltipBusy} />
                            )}
                    </div>
                </div>
            )
            day = dateFns.addDays(day, 1)
        }
        rows.push(
            <div className={`${styles.row}`} key={day.getTime()}>
                {days}
            </div>
        )
        days = []
    }
    return <div className={`${styles.body}`}>{rows}</div>
}

type CalendarType = {
    currentMonth: Date
    year: number
    previousMonth: () => void
    nextMonth: () => void
    busyDays: BusyDaysType[]
    onDateClick: (day: Date) => void
    selectedDate: Date | null
}
const RenderCalendar: FC<CalendarType> = (props) => {
    const {
        busyDays,
        currentMonth,
        nextMonth,
        year,
        previousMonth,
        selectedDate,
        onDateClick,
    } = props
    return (
        <div>
            <RenderCalendarHeader
                currentMonth={currentMonth}
                nextMonth={nextMonth}
                previousMonth={previousMonth}
                year={year}
            />
            <RenderCalendarDays currentMonth={currentMonth} />
            <RenderCalendarCells
                currentMonth={currentMonth}
                onDateClick={onDateClick}
                selectedDate={selectedDate}
                busyDays={busyDays}
            />
        </div>
    )
}
