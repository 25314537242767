import React, { useEffect, useState } from 'react'
import { useRedirectCustomHook } from 'UI/common/hooks/useRedirectCustomHook'
import { RolesTypeEnum } from 'BLL/enums'
import { PaymentType } from 'DAL/mySalesAPI'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'
import Table from '../../common/table/Table'
import { useDispatch } from 'react-redux'
import { fetchItems, PaymentTypeBLL } from 'BLL/MySalesReducer'
import { useSelector } from 'UI/common/hooks/redux-hooks'
import { CurrencyEnum } from 'DAL/CashbackAPI'
import { NavLink, Route, useHistory } from 'react-router-dom'
import { CustomerRM } from 'UI/routers/RouterManager'
import { generateAndDownloadTxtFile } from 'UI/utils/util-types'
import { AppSettings } from 'settings/appSettings'
import { MySalesPrice } from './MySalesPrice/MySalesPrice'
import { Button } from 'UI/common/elements/buttons/Button'
import { MobMySales } from '../Mobile/MobMySales/MobMySales'
import { useDeviceDetect } from 'UI/common/hooks/useDeviceDetect'
import { Conditions } from 'UI/popUps/conditions/conditions'
import { Modal } from 'UI/common/modal/modal'
import { getMySalesTableDataEng } from 'UI/common/table/tableData/GetMySalesTableDataEng'
import s from './my-sales.module.scss'

export const MySales = () => {
    const { i18n } = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()
    const { navigation, mySales, buttons } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    const items = useSelector((state) => state.mySales.items)
    const rootPayments = useSelector((state) => state.mySales.rootPayments)
    const [stat, setStat] = useState<StatisticsType | null>(null)

    useEffect(() => {
        dispatch(fetchItems())
    }, [])

    useEffect(() => {
        if (rootPayments.length) {
            setStat(getStatistics(rootPayments))
        }
    }, [rootPayments])

    const handleDownloadBuyersList = () => {
        const items = rootPayments.map((p) => {
            return `${
                p.sales[0].clientFullname
                    ? p.sales[0].clientFullname
                    : p.sales[0].buyerName
            }, ${p.sales[0].buyerEmail}`
        })
        generateAndDownloadTxtFile(items, 'buyer')
    }
    const isEng = AppSettings.api.isEngVersion()
    const isMobile = useDeviceDetect()
    return (
        <div className={s.wrapperPage}>
            {useRedirectCustomHook(RolesTypeEnum.BEATMAKER)}
            {!isMobile ? (
                <>
                    <div className={s.topWrapper}>
                        <div className={s.pageTitle}>{navigation.mySales}</div>
                        <div className={s.searchWrapper}>
                            <Button
                                view={'Outline'}
                                title={mySales.emailsBuyers}
                                onClick={handleDownloadBuyersList}
                            />
                        </div>
                    </div>
                    {stat && (
                        <div className={s.Statistics}>
                            <MySalesPrice
                                beatsCount={stat.itemsSold} //проданых битов
                                rubAmounts={stat.conclusionRub.toFixed(2)} //для вывода
                                rubComission={stat.serviceFeeAmountRub.toFixed(
                                    2
                                )} //коммисия сайта
                                rubPaid={stat.grossRub.toFixed(2)} //общая сумма продаж
                                rubReceived={stat.beatmakerPureMoneyRub.toFixed(
                                    2
                                )} //полученная сумма
                                usdAmounts={stat.conclusionUsd.toFixed(2)}
                                usdComission={stat.serviceFeeAmountUsd.toFixed(
                                    2
                                )}
                                usdPaid={stat.grossUsd.toFixed(2)}
                                usdReceived={stat.beatmakerPureMoneyUsd.toFixed(
                                    2
                                )}
                            />
                            {!isEng && (
                                <div className={s.buttonWithdraw}>
                                    <NavLink
                                        to={CustomerRM.mySales.path.withdrawal}
                                    >
                                        <Button title={buttons.WITHDRAW} />
                                    </NavLink>
                                </div>
                            )}
                        </div>
                    )}

                    {items.length ? (
                        <Table<PaymentType>
                            items={rootPayments}
                            toolbar={false}
                            fields={getMySalesTableDataEng(i18n)}
                            title={''}
                            pageSize={10}
                            page={1}
                            totalCount={items.length}
                        />
                    ) : null}
                </>
            ) : (
                stat && (
                    <MobMySales
                        payments={rootPayments}
                        statistics={{
                            beatsCount: stat.itemsSold, //проданых битов
                            rubAmounts: stat.conclusionRub.toFixed(2), //для вывода
                            rubComission: stat.serviceFeeAmountRub.toFixed(2), //коммисия сайта
                            rubPaid: stat.grossRub.toFixed(2), //общая сумма продаж
                            rubReceived: stat.beatmakerPureMoneyRub.toFixed(2), //полученная сумма
                            usdAmounts: stat.conclusionUsd.toFixed(2),
                            usdComission: stat.serviceFeeAmountUsd.toFixed(2),
                            usdPaid: stat.grossUsd.toFixed(2),
                            usdReceived: stat.beatmakerPureMoneyUsd.toFixed(2),
                        }}
                    />
                )
            )}
            <Route
                exact
                path={CustomerRM.mySales.path.withdrawal}
                render={() => (
                    <Modal
                        active={true}
                        title={'Условия для вывода'}
                        cancelRedirect={() =>
                            history.push(CustomerRM.mySales.path.list)
                        }
                    >
                        <Conditions />
                    </Modal>
                )}
            />
        </div>
    )
}

export type StatisticsType = {
    itemsSold: number
    grossRub: number
    grossUsd: number
    discountRub: number
    discountUsd: number
    serviceFeeAmountRub: number
    serviceFeeAmountUsd: number
    beatmakerPureMoneyRub: number
    beatmakerPureMoneyUsd: number
    conclusionRub: number
    conclusionUsd: number
}
export const getStatistics = (
    rootPayment: PaymentTypeBLL[]
): StatisticsType => {
    return rootPayment.reduce(
        (acc: StatisticsType, payment) => {
            if (payment.currencyName === CurrencyEnum.usd) {
                acc.grossUsd = acc.grossUsd + payment.amount
                acc.discountUsd = acc.discountUsd + payment.cashbackAmount
                acc.serviceFeeAmountUsd =
                    acc.serviceFeeAmountUsd + payment.serviceFeeAmount
                acc.beatmakerPureMoneyUsd =
                    acc.beatmakerPureMoneyUsd + payment.beatmakerPureMoney
            } else {
                acc.grossRub = acc.grossRub + payment.amount
                acc.discountRub = acc.discountRub + payment.cashbackAmount
                acc.serviceFeeAmountRub =
                    acc.serviceFeeAmountRub + payment.serviceFeeAmount
                acc.beatmakerPureMoneyRub =
                    acc.beatmakerPureMoneyRub + payment.beatmakerPureMoney
            }
            acc.itemsSold = acc.itemsSold + payment.sales.length

            const sale = payment.sales.find((s) => !s.paidDate)
            if (sale) {
                if (payment.currencyName === CurrencyEnum.usd) {
                    acc.conclusionUsd =
                        acc.conclusionUsd + payment.beatmakerPureMoney
                } else {
                    acc.conclusionRub =
                        acc.conclusionRub + payment.beatmakerPureMoney
                }
            }

            return acc
        },
        {
            itemsSold: 0,
            grossRub: 0,
            grossUsd: 0,
            discountRub: 0,
            discountUsd: 0,
            serviceFeeAmountRub: 0,
            serviceFeeAmountUsd: 0,
            beatmakerPureMoneyRub: 0,
            beatmakerPureMoneyUsd: 0,
            conclusionRub: 0,
            conclusionUsd: 0,
        } as StatisticsType
    )
}
