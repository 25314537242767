import React from 'react'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'
import charts from 'UI/assets/icons/charts.svg'
import download from 'UI/assets/icons/download.svg'
import listen from 'UI/assets/icons/listen.svg'
import classes from 'UI/CustomerAdmin/Mobile/MobMyBeatsPage/common/styles/MobBeatItem.module.scss'

type Props = {
    downloads: number
    plays: number
    chartPosition: number | null
}

export const MobBeatDescription = ({
    downloads,
    plays,
    chartPosition,
}: Props) => {
    const { i18n } = useTranslation()
    const { myBeats } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    return (
        <div className={classes.description}>
            <div className={classes.item}>
                <span className={classes.itemText}>
                    <img
                        className={classes.itemIcon}
                        src={charts}
                        alt={'charts'}
                    />
                    {myBeats.tableColumns.chart}:
                </span>
                <span className={classes.itemCount}>{chartPosition}</span>
            </div>
            <div className={classes.item}>
                <span className={classes.itemText}>
                    <img
                        className={classes.itemIcon}
                        src={download}
                        alt={'download'}
                    />
                    {myBeats.tableColumns.downloads}:
                </span>
                <span className={classes.itemCount}>{downloads}</span>
            </div>
            <div className={classes.item}>
                <span className={classes.itemText}>
                    <img
                        className={classes.itemIcon}
                        src={listen}
                        alt={'listen'}
                    />
                    {myBeats.tableColumns.listening}:
                </span>
                <span className={classes.itemCount}>{plays}</span>
            </div>
        </div>
    )
}
