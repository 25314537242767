import React, { FC } from 'react'
import { useTheme } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { STATISTICS, STATUSES } from '../../../BLL/SupportingFile'
import CircularDeterminate from '../../common/progressBars/Spinner'
import { SubscriptionPlansEnum } from '../../../BLL/enums'
import { useTranslation } from 'react-i18next'
import { LanguageType } from '../../../translations/ru/common'
import { useSelector } from '../../common/hooks/redux-hooks'
import { AppSettings } from 'settings/appSettings'

interface TabPanelProps {
    children?: React.ReactNode
    dir?: string
    index: any
    value: any
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
        <Typography
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={4}>{children}</Box>}
        </Typography>
    )
}

function a11yProps(index: any) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    }
}

interface IProps {
    handleChangePlan: (plan: SubscriptionPlansEnum | null) => void
}

const StatisticsTabs: FC<IProps> = ({ handleChangePlan }) => {
    const theme = useTheme()
    const [value, setValue] = React.useState(0)

    const statistics = useSelector(
        (state) => state.adminSubscriptions.statistics
    )
    const statusGetSubscriptions = useSelector(
        (state) => state.adminSubscriptions.statusGetSubscriptions
    )

    const { i18n } = useTranslation()
    const { adminSubscription, statisticsTab } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue)
        switch (newValue) {
            case 1: {
                handleChangePlan(SubscriptionPlansEnum.Free)
                break
            }
            case 2: {
                handleChangePlan(SubscriptionPlansEnum.Expert)
                break
            }
            case 3: {
                handleChangePlan(SubscriptionPlansEnum.Premium)
                break
            }
            default: {
                handleChangePlan(null)
                break
            }
        }
    }

    return (
        <div>
            <AppBar
                position="static"
                style={{ background: AppSettings.api.getBaseColor() }}
            >
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                >
                    {statisticsTab.map((stat, index) => (
                        <Tab
                            key={index}
                            label={statisticsTab[index]}
                            {...a11yProps(index)}
                            style={{ color: 'black' }}
                        />
                    ))}
                </Tabs>
            </AppBar>
            {statusGetSubscriptions === STATUSES.LOADING ? (
                <div
                    style={{
                        textAlign: 'center',
                        marginTop: 40,
                        marginBottom: 40,
                    }}
                >
                    <CircularDeterminate />
                </div>
            ) : (
                STATISTICS.map((stat, index) => (
                    <TabPanel
                        key={index}
                        value={value}
                        index={index}
                        dir={theme.direction}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-around',
                                alignItems: 'center',
                            }}
                        >
                            <div
                                style={{
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                }}
                            >
                                <div>{adminSubscription.all}</div>
                                <div
                                    style={{
                                        color: '#7fc7ff',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {statistics && statistics.total}
                                </div>
                            </div>
                            <div
                                style={{
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                }}
                            >
                                <div>{adminSubscription.active}</div>
                                <div
                                    style={{
                                        color: '#77DD77',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {statistics && statistics.active}
                                </div>
                            </div>
                            <div
                                style={{
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                }}
                            >
                                <div>{adminSubscription.expired}</div>
                                <div
                                    style={{
                                        color: '#dd5758',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {statistics && statistics.expired}
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                ))
            )}
        </div>
    )
}

export default StatisticsTabs
