import React, { FC, useEffect } from 'react'
import classes from './CustomerCouponsModal.module.scss'
import { Modal } from '../../../common/modal/modal'
import { InputField } from '../../../common/elements/textFields/Input'
import { useForm } from 'react-hook-form'
import { CustomDatePicker } from '../../../common/elements/datePicker/CustomDatePicker'
import { Button } from '../../../common/elements/buttons/Button'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { LanguageType } from '../../../../translations/ru/common'
import { TextArea } from '../../../common/elements/textFields/textarea'
import { CustomRating } from '../../../common/elements/CustomRating'
import { AppState } from '../../../../BLL/Store'
import {
    CouponCurrency,
    CouponStatus,
    CouponType,
    UpdateCouponType,
} from '../../../../BLL/shop-types'
import {
    leaveFeedback,
    updateCoupon,
} from '../../../../BLL/CustomerCouponsReducer'
import { useHistory, useParams } from 'react-router-dom'
import { CustomerRM } from '../../../routers/RouterManager'
import { CustomSelect } from '../../../common/elements/selects/CustomSelect'
import { updateCoupon as ownerUpdateCoupons } from '../../../../BLL/ShopAdminReducer'

type FormValues = {
    id: number
    currency: { value: number; title: string }
    price: number
    productTitle: string
    purchaseDate: Date
    isClosed?: boolean
    shopNote: string
    customerFeedback?: string
    customerShopRating?: number
}

type PropsType = {
    location: 'owner' | 'customer'
}
export const CustomerCouponsModal: FC<PropsType> = ({ location }) => {
    const { i18n } = useTranslation()
    const dispatch = useDispatch()
    const { couponId } = useParams<{ couponId: string }>()
    const { buttons, shopCoupon, adminExpertsCompilation } =
        i18n.getResourceBundle(i18n.language, 'common') as LanguageType
    const history = useHistory()
    const [rating, setRating] = React.useState<number>(0)
    const [disabledRating, setDisabledRating] = React.useState<boolean>(false)
    const isOwner = useSelector((state: AppState) => state.appReducer.isOwner)
    const requestStatus = useSelector(
        (state: AppState) => state.appReducer.requestStatus
    )
    const coupon = useSelector((state: AppState) => {
        if (location === 'customer') {
            return state.customerCoupons.coupons.find(
                (coupon) => coupon.id === +couponId
            ) as CouponType
        } else {
            return state.shopAdmin.coupons.find(
                (coupon) => coupon.id === +couponId
            ) as CouponType
        }
    })

    const {
        register,
        setValue,
        handleSubmit,
        control,
        watch,
        formState: { errors },
    } = useForm<FormValues>()

    useEffect(() => {
        if (coupon) {
            const currencyValue = {
                value: coupon.currency as number,
                title: CouponCurrency[coupon.currency] as string,
            }
            coupon.productTitle && setValue('productTitle', coupon.productTitle)
            coupon.price && setValue('price', coupon.price)
            coupon.shopNote && setValue('shopNote', coupon.shopNote)
            coupon.customerFeedback &&
                setValue('customerFeedback', coupon.customerFeedback)
            coupon.customerShopRating && setRating(coupon.customerShopRating)
            handleMultiChange(currencyValue)
            setRating(coupon.customerShopRating)
            coupon.customerShopRating !== 0 && setDisabledRating(true)
            if (coupon.purchaseDate) {
                handleSelectDate(new Date(coupon.purchaseDate))
            } else {
                handleSelectDate(new Date())
            }
        }
    }, [coupon])

    const handleUpdateCoupon = (coupon: UpdateCouponType) => {
        dispatch(updateCoupon(coupon))
    }
    const onSubmit = handleSubmit(
        ({
            price,
            currency,
            purchaseDate,
            productTitle,
            isClosed,
            shopNote,
            customerFeedback,
        }) => {
            if (location === 'owner') {
                dispatch(ownerUpdateCoupons(+couponId, '', 'approve'))
                return
            }
            if (coupon.status !== CouponStatus.Approve) {
                handleUpdateCoupon({
                    id: +couponId,
                    price: +price,
                    currency: +currency,
                    purchaseDate,
                    productTitle,
                    isClosed,
                    shopNote,
                })
            } else {
                dispatch(leaveFeedback(coupon.id, customerFeedback, rating))
            }
        }
    )

    const handleSendCoupon = () => {
        if (location === 'owner' && watch('shopNote')) {
            dispatch(
                ownerUpdateCoupons(+couponId, watch('shopNote'), 'refactor')
            )
        } else {
            setValue('isClosed', true)
            onSubmit()
        }
    }

    const handleRejectCoupon = () => {
        dispatch(ownerUpdateCoupons(+couponId, '', 'reject'))
    }

    const handleSelectDate = (date: Date | null) => {
        date && setValue('purchaseDate', date)
    }

    const handleMultiChange = (currency: any) => {
        setValue('currency', currency.value)
        // setCurrency(currency)
    }
    const options = [
        { value: 0, title: 'RUB' },
        { value: 1, title: 'USD' },
    ]

    const handleClose = () => {
        if (location === 'customer') {
            history.push(CustomerRM.shops.coupons.path)
        } else {
            history.push(CustomerRM.shops.owner.path)
        }
    }

    if (!coupon) {
        return (
            <div style={{ padding: 20 }}>{adminExpertsCompilation.loading}</div>
        )
    }
    const isCustomerView = location === 'customer'
    const isDisabledField = location === 'owner'
    const couponIsComplete =
        coupon.status === CouponStatus.Approve && coupon.customerFeedback
    return (
        <div>
            <Modal
                active={true}
                setActive={() => {}}
                cancelRedirect={handleClose}
                title={shopCoupon.popupEdit.title}
            >
                <form onSubmit={onSubmit}>
                    <p className={classes.description}>
                        {shopCoupon.popupEdit.description}
                    </p>
                    {!isCustomerView && (
                        <div className={classes.commentBlock}>
                            <span className={classes.commentTitle}>
                                {shopCoupon.popupEdit.storeComment}:
                            </span>
                            {!isDisabledField ? (
                                watch('shopNote') && (
                                    <p className={classes.commentText}>
                                        {watch('shopNote')}
                                    </p>
                                )
                            ) : (
                                <div className={classes.fieldItemProduct}>
                                    <div className={classes.fieldSize}>
                                        <TextArea
                                            register={register('shopNote')}
                                            disabled={
                                                coupon.status ===
                                                CouponStatus.Approve
                                            }
                                        />
                                    </div>
                                </div>
                            )}
                            {coupon.status !== CouponStatus.Approve && (
                                <div className={classes.wrapperSend}>
                                    <Button
                                        view={'Secondary'}
                                        title={buttons.SEND}
                                        type={'button'}
                                        onClick={handleSendCoupon}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                    <div className={classes.fieldItemProduct}>
                        <label className={classes.fieldItemTitle}>
                            {shopCoupon.popupEdit.productName}
                        </label>
                        <div className={classes.fieldSize}>
                            <TextArea
                                register={register('productTitle', {
                                    required: true,
                                })}
                                disabled={
                                    isDisabledField ||
                                    coupon.status === CouponStatus.Approve
                                }
                                error={
                                    errors.productTitle && {
                                        ...errors.productTitle,
                                        message:
                                            shopCoupon.popupEdit
                                                .fieldIsRequired,
                                    }
                                }
                            />
                        </div>
                    </div>
                    <div className={classes.fieldItemCurrency}>
                        <label className={classes.fieldItemTitle}>
                            {shopCoupon.popupEdit.currency}
                        </label>
                        <div className={classes.fieldSize}>
                            <CustomSelect
                                // control={control}
                                // name={'currency'}
                                items={options}
                                width={350}
                                register={register('currency')}
                                // defaultValue={watch('currency')}
                                // value={watch('currency')}
                                // options={options}
                                disabled={
                                    isDisabledField ||
                                    coupon.status === CouponStatus.Approve
                                }
                            />
                        </div>
                    </div>
                    <div className={classes.fields}>
                        <div className={classes.fieldItemCost}>
                            <label className={classes.fieldItemTitle}>
                                {shopCoupon.popupEdit.cost}
                            </label>
                            <div className={classes.fieldSize}>
                                <InputField
                                    register={register('price', {
                                        required: true,
                                    })}
                                    disabled={
                                        isDisabledField ||
                                        coupon.status === CouponStatus.Approve
                                    }
                                    error={
                                        errors.price && {
                                            ...errors.price,
                                            message:
                                                shopCoupon.popupEdit
                                                    .fieldIsRequired,
                                        }
                                    }
                                />
                            </div>
                        </div>
                        <div className={classes.fieldItemPurchase}>
                            <label className={classes.fieldItemTitle}>
                                {shopCoupon.popupEdit.purchaseDate}
                            </label>
                            <div className={classes.fieldSize}>
                                <CustomDatePicker
                                    date={watch('purchaseDate')}
                                    disabled={
                                        isDisabledField ||
                                        coupon.status === CouponStatus.Approve
                                    }
                                    handleChange={handleSelectDate}
                                    locale={i18n.language as any}
                                />
                            </div>
                        </div>
                    </div>

                    {location === 'customer' && (
                        <div className={classes.commentBlock}>
                            <span className={classes.commentTitle}>
                                {shopCoupon.popupEdit.storeComment}:
                            </span>
                            {!isDisabledField ? (
                                watch('shopNote') && (
                                    <p className={classes.commentText}>
                                        {watch('shopNote')}
                                    </p>
                                )
                            ) : (
                                <div className={classes.fieldItemProduct}>
                                    <div className={classes.fieldSize}>
                                        <TextArea
                                            register={register('shopNote')}
                                            disabled={
                                                coupon.status ===
                                                CouponStatus.Approve
                                            }
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    {coupon.status === CouponStatus.Approve && (
                        <div className={classes.fieldItemProduct}>
                            <div className={classes.rating}>
                                <span className={classes.fieldItemTitle}>
                                    {shopCoupon.popupEdit.yourAssessment}:{' '}
                                </span>
                                <CustomRating
                                    disabledRating={disabledRating}
                                    rating={rating}
                                    setRating={setRating}
                                />
                            </div>
                            {rating ? (
                                <>
                                    <label className={classes.fieldItemTitle}>
                                        {shopCoupon.popupEdit.feedback}
                                    </label>
                                    <div className={classes.fieldSize}>
                                        <TextArea
                                            register={register(
                                                'customerFeedback',
                                                {
                                                    required: true,
                                                }
                                            )}
                                            disabled={
                                                isDisabledField ||
                                                !!coupon.customerFeedback
                                            }
                                            error={
                                                errors.customerFeedback && {
                                                    ...errors.customerFeedback,
                                                    message:
                                                        shopCoupon.popupEdit
                                                            .fieldIsRequired,
                                                }
                                            }
                                        />
                                        {/*<span className={classes.ErrorMessage}>*/}
                                        {/*    {errors.customerFeedback &&*/}
                                        {/*        'Field is required'}*/}
                                        {/*</span>*/}
                                    </div>
                                </>
                            ) : null}
                        </div>
                    )}
                    <div className={classes.btnBox}>
                        {coupon.status !== CouponStatus.Approve && (
                            <Button
                                view={isCustomerView ? 'Secondary' : 'Delete'}
                                title={
                                    isCustomerView
                                        ? buttons.SEND
                                        : buttons.REJECT
                                }
                                type={'button'}
                                onClick={
                                    isCustomerView
                                        ? handleSendCoupon
                                        : handleRejectCoupon
                                }
                            />
                        )}
                        {!couponIsComplete ? (
                            <Button
                                title={
                                    isCustomerView
                                        ? buttons.SAVE
                                        : buttons.APPROVE
                                }
                                type={'submit'}
                            />
                        ) : (
                            <Button
                                view={'Secondary'}
                                title={buttons.CLOSE}
                                type={'button'}
                                onClick={handleClose}
                            />
                        )}
                    </div>
                </form>
            </Modal>
        </div>
    )
}
