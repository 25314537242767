import { beatsPromotionAxios } from './createAxios'
import { PublicationType, BaseResponse } from '../BLL/types'

export const publicationsAPI = {
    async postPublication(publication: PublicationType) {
        return await beatsPromotionAxios
            .post<BaseResponse<PublicationType>>('/publications', {
                publication,
            })
            .then((r) => r.data)
    },
    async getPublications() {
        return await beatsPromotionAxios
            .get<BaseResponse<PublicationType[]>>('/publications')
            .then((r) => r.data)
    },
    async deletePublication(publicationId: string) {
        return await beatsPromotionAxios
            .put<BaseResponse<PublicationType>>('/publications', {
                publicationId,
            })
            .then((r) => r.data)
    },
}
