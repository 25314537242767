import React from 'react'
import { CoinsHistoryType } from 'DAL/CoinsAPI'
import { LanguageType } from 'translations/ru/common'
import { useTranslation } from 'react-i18next'
import { getLocaleDateString } from 'UI/utils/util-types'
import s from 'UI/CustomerAdmin/Mobile/MobCoinsPage/CoinsItem/coinsItem.module.scss'

type Props = {
    coinsHistory: CoinsHistoryType
}

export const CoinsItem = ({ coinsHistory }: Props) => {
    const { i18n } = useTranslation()
    const { myCoins } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    const isPayment = coinsHistory.addedMoney < 0

    return (
        <div className={s.coinsItem}>
            <div className={s.item}>
                <span className={s.description}>
                    {myCoins.tableColumns.operation}:
                </span>
                <span className={s.value}>
                    {isPayment
                        ? myCoins.tableColumns.payment
                        : myCoins.tableColumns.income}
                </span>
            </div>
            <div className={s.item}>
                <span className={s.descr}>{myCoins.tableColumns.amount}:</span>
                <span className={s.value}>
                    {coinsHistory.addedMoney <= 0
                        ? `- ${-coinsHistory.addedMoney}`
                        : `+ ${coinsHistory.addedMoney}`}
                </span>
            </div>
            <div className={s.item}>
                <span className={s.descr}>{myCoins.tableColumns.balance}:</span>
                <span className={s.value}>{coinsHistory.moneyAfter}</span>
            </div>
            <div className={s.item}>
                <span className={s.descr}>{myCoins.tableColumns.date}:</span>
                <span className={s.value}>
                    {getLocaleDateString(i18n.language, coinsHistory.addedDate)}
                </span>
            </div>
            <div className={s.item}>
                <span className={s.descr}>
                    {myCoins.tableColumns.description}:
                </span>
                <span className={s.value}>{coinsHistory.description}</span>
            </div>
        </div>
    )
}
