import React, { FC, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { MainMenuDrawer } from '../menu/MainMenuDrawer'
import Typography from '@material-ui/core/Typography/Typography'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { fade } from '@material-ui/core/styles/colorManipulator'
import { AppSettings } from 'settings/appSettings'
import { fetchedBalance } from 'BLL/CustomerCoinsReducer'
import { useDispatch } from 'react-redux'
import { useSelector } from '../hooks/redux-hooks'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            background: '#1f1f1f;',
            marginBottom: 50,
        },

        grows: {
            flexGrow: 1,
        },
        menuButton: {
            marginLeft: -12,
            marginRight: 20,
        },
        title: {
            display: 'none',
            color: AppSettings.api.getBaseColor(),
            textTransform: 'uppercase',
            fontSize: '1.1rem',
            [theme.breakpoints.up('sm')]: {
                display: 'block',
            },
        },
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: fade(theme.palette.common.white, 0.15),
            '&:hover': {
                backgroundColor: fade(theme.palette.common.white, 0.25),
            },
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(1),
                width: 'auto',
            },
        },
        searchIcon: {
            width: theme.spacing(9),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        inputRoot: {
            color: 'inherit',
            width: '100%',
        },
        inputInput: {
            paddingTop: theme.spacing(1),
            paddingRight: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            paddingLeft: theme.spacing(10),
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                width: 180,
                '&:focus': {
                    width: 290,
                },
            },
        },
        lng: {
            cursor: 'pointer',
            fontWeight: 'bold',
            marginLeft: 50,
        },
    })
)

type PropsType = {
    title: string
}

const Header: FC<PropsType> = ({ title }) => {
    const classes = useStyles()
    const { i18n } = useTranslation()
    const { myCoins } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType
    const balance = useSelector((state) => state.coinsReducer.balance)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchedBalance())
    }, [title])
    return (
        <Grid container>
            <div className={classes.root}>
                <AppBar className={classes.root}>
                    <Toolbar>
                        <MainMenuDrawer />
                        <Typography variant="h6" className={classes.title}>
                            {title}
                        </Typography>
                        <div className={classes.grows} />
                        <div>
                            {myCoins.coinsBalance}: {balance && balance.coins}
                        </div>
                    </Toolbar>
                </AppBar>
            </div>
        </Grid>
    )
}
export default Header
