import React from 'react'
import { FieldValues, useController, UseControllerProps } from 'react-hook-form'
import {
    NewTextField,
    Props,
} from 'UI/common/elements/textFields/newtextField/NewTextField'

export type ControlledTextFieldProps<TFieldValues extends FieldValues> =
    UseControllerProps<TFieldValues> & Omit<Props, 'onChange' | 'value'>

export const ControlledTextField = <TFieldValues extends FieldValues>({
    name,
    rules,
    shouldUnregister,
    control,
    defaultValue,
    customStyles,
    ...textFieldProps
}: ControlledTextFieldProps<TFieldValues>) => {
    const {
        field: { onChange, value },
    } = useController({
        name,
        control,
        shouldUnregister,
        rules,
        defaultValue,
    })

    return (
        <NewTextField
            {...{
                onChange,
                value,
                customStyles,
                ...textFieldProps,
            }}
        />
    )
}
