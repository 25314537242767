import { createSelector } from 'reselect'

const initialState = {
    sails: {
        day: [
            { x: 0, y: 1 },
            { x: 1, y: 3 },
            { x: 2, y: 0 },
            { x: 3, y: 5 },
            { x: 4, y: 10 },
            { x: 5, y: 10 },
            { x: 6, y: 0 },
            { x: 7, y: 7 },
            { x: 8, y: 4 },
            { x: 9, y: 5 },
            { x: 10, y: 18 },
            { x: 11, y: 10 },
            { x: 12, y: 3 },
            { x: 13, y: 5 },
            { x: 14, y: 7 },
            { x: 15, y: 3 },
            { x: 16, y: 2 },
            { x: 17, y: 1 },
            { x: 18, y: 0 },
            { x: 19, y: 4 },
            { x: 20, y: 7 },
            { x: 21, y: 7 },
            { x: 22, y: 6 },
            { x: 23, y: 3 },
            { x: 24, y: 4 },
        ],
        week: [
            { x: 'Monday', y: 34 },
            { x: 'Tuesday', y: 57 },
            { x: 'Wednesday', y: 12 },
            { x: 'Thursday', y: 16 },
            { x: 'Friday', y: 66 },
            { x: 'Saturday', y: 72 },
            { x: 'Sunday', y: 39 },
        ],
        month: [
            { x: 'January', y: 156 },
            { x: 'February', y: 300 },
            { x: 'March', y: 324 },
            { x: 'April', y: 534 },
            { x: 'May', y: 123 },
            { x: 'June', y: 345 },
            { x: 'July', y: 438 },
            { x: 'August', y: 867 },
            { x: 'September', y: 123 },
            { x: 'October', y: 453 },
            { x: 'November', y: 867 },
            { x: 'December', y: 345 },
        ],
        id: 1,
    },
    listeners: {
        day: [
            { x: 1, y: 200 },
            { x: 2, y: 100 },
            { x: 3, y: 100 },
        ],
        week: [
            { x: 1, y: 100 },
            { x: 2, y: 300 },
            { x: 3, y: 100 },
        ],
        month: [
            { x: 1, y: 50 },
            { x: 2, y: 400 },
            { x: 3, y: 100 },
        ],
        id: 2,
    },
}

const reducer = (state = initialState) => {
    return state
}

export default reducer

//reselect

const getStatistics = (state: any) => state.customerStatistics

export const getAllStatistics = createSelector([getStatistics], (s) => s)
