import { compilationAPI } from '../DAL/CompilationAPI'
import { STATUSES } from './SupportingFile'
import {
    BeatFullType,
    CompilationType,
    ExpertType,
    ResultCodesEnum,
    ThunkActionType,
} from './types'
import { InferActionsTypes } from './Store'
import {
    actions as appActions,
    AuthActionsTypes,
    RequestStatuses,
} from './AppReducer'
import { RolesTypeEnum, StatusExpertEnum } from './enums'
import { commonAsyncHandler } from './common-async-handler'

const initState = {
    beats: [] as BeatFullType[],
    compilation: {
        _id: '',
        beatmakerId: 0,
        beats: [],
    } as CompilationType,
    beatPlayNow: null as BeatFullType | null,
    lastListenedBeatAddedAt: null as Date | null,
    isPauseCompilation: false,
    statusCompilation: STATUSES.NOT_INIT as string,
    statusUpdate: STATUSES.NOT_INIT as string,
    statusGetBeats: STATUSES.NOT_INIT as string,
    expert: null as ExpertType | null,
}

type IState = typeof initState
export type ActionsTypes = InferActionsTypes<typeof actions> | AuthActionsTypes

const CustomerBeatsCompilationReducer = (
    state = initState,
    action: ActionsTypes
): IState => {
    switch (action.type) {
        case 'CUSTOMER_BEATS_COMPILATION/SET_COMPILATION':
            return {
                ...state,
                compilation: action.compilation,
            }
        case 'CUSTOMER_BEATS_COMPILATION/SET_LAST_LISTENED_BEAT':
            return {
                ...state,
                expert: {
                    ...state.expert,
                    lastListenedBeatAddedAt: action.date,
                } as ExpertType,
            }
        case 'CUSTOMER_BEATS_COMPILATION/SET_BEATS':
            return {
                ...state,
                beats: action.beats,
            }
        case 'CUSTOMER_BEATS_COMPILATION/SET_STATUS_COMPILATION':
            return {
                ...state,
                statusCompilation: action.status,
            }
        case 'CUSTOMER_BEATS_COMPILATION/SET_STATUS_UPDATE':
            return {
                ...state,
                statusUpdate: action.status,
            }
        case 'CUSTOMER_BEATS_COMPILATION/SET_PAUSE_COMPILATION':
            return {
                ...state,
                isPauseCompilation: action.isPause,
            }
        case 'CUSTOMER_BEATS_COMPILATION/SET_LAST_COMPILATION_DATE':
            return {
                ...state,
                expert: {
                    ...state.expert,
                    lastCompilationDate: action.date,
                } as ExpertType,
            }
        case 'CUSTOMER_BEATS_COMPILATION/SET_BEAT':
            return {
                ...state,
                compilation: {
                    ...state.compilation,
                    beats: [...state.compilation.beats, action.beat],
                },
            }
        case 'CUSTOMER_BEATS_COMPILATION/SET_STATUS_GET_BEATS':
            return {
                ...state,
                statusGetBeats: action.status,
            }

        case 'CUSTOMER_BEATS_COMPILATION/SET_EXPERT':
            return {
                ...state,
                expert: action.expert,
            }
        default:
            return state
    }
}

export default CustomerBeatsCompilationReducer

export const actions = {
    setBeats: (beats: BeatFullType[]) =>
        ({ type: 'CUSTOMER_BEATS_COMPILATION/SET_BEATS', beats } as const),
    setCompilation: (compilation: CompilationType) =>
        ({
            type: 'CUSTOMER_BEATS_COMPILATION/SET_COMPILATION',
            compilation,
        } as const),
    setLastListenedBeatAddedAt: (date: string) =>
        ({
            type: 'CUSTOMER_BEATS_COMPILATION/SET_LAST_LISTENED_BEAT',
            date,
        } as const),
    setStatusCompilation: (status: string) =>
        ({
            type: 'CUSTOMER_BEATS_COMPILATION/SET_STATUS_COMPILATION',
            status,
        } as const),
    setStatusUpdate: (status: string) =>
        ({
            type: 'CUSTOMER_BEATS_COMPILATION/SET_STATUS_UPDATE',
            status,
        } as const),
    setPauseCompilation: (isPause: boolean) =>
        ({
            type: 'CUSTOMER_BEATS_COMPILATION/SET_PAUSE_COMPILATION',
            isPause,
        } as const),
    setLastCompilationDate: (date: Date) =>
        ({
            type: 'CUSTOMER_BEATS_COMPILATION/SET_LAST_COMPILATION_DATE',
            date,
        } as const),
    setBeat: (beat: BeatFullType) =>
        ({ type: 'CUSTOMER_BEATS_COMPILATION/SET_BEAT', beat } as const),
    setStatusGetBeats: (status: string) =>
        ({
            type: 'CUSTOMER_BEATS_COMPILATION/SET_STATUS_GET_BEATS',
            status,
        } as const),
    setExpert: (expert: ExpertType | null) =>
        ({ type: 'CUSTOMER_BEATS_COMPILATION/SET_EXPERT', expert } as const),
}

export const getBeats =
    (): ThunkActionType<ActionsTypes> => async (dispatch) => {
        await commonAsyncHandler(async () => {
            const response = await compilationAPI.getCompilationBeats()
            dispatch(actions.setBeats(response.data))
            return response
        }, dispatch)
    }

export const getExpert =
    (): ThunkActionType<ActionsTypes> => async (dispatch, getState) => {
        dispatch(appActions.setRequestStatus(RequestStatuses.InProgress))
        try {
            const response = await compilationAPI.getExpert()
            dispatch(actions.setExpert(response.data))
            if (response.data.status === StatusExpertEnum.Active) {
                const roles = getState().appReducer.roles
                dispatch(appActions.setRoles([...roles, RolesTypeEnum.EXPERT]))
                await dispatch(getCompilation())
            }
            dispatch(appActions.setRequestStatus(RequestStatuses.Success))
        } catch (e) {
            dispatch(appActions.setRequestStatus(RequestStatuses.Error))
        }
    }

export const getCompilation =
    (): ThunkActionType<ActionsTypes> => async (dispatch, getState) => {
        await commonAsyncHandler(async () => {
            const beats: BeatFullType[] =
                getState().customerBeatsCompilation.beats
            const response = await compilationAPI.getCompilation()
            if (response.resultCode === ResultCodesEnum.Success) {
                dispatch(actions.setCompilation(response.data.compilation))
                dispatch(
                    actions.setLastListenedBeatAddedAt(
                        response.data.lastListenedBeatAddedAt
                    )
                )
                if (beats.length === 0) {
                    dispatch(getBeats())
                }
            } else if (response.resultCode === 2) {
                dispatch(actions.setPauseCompilation(true))
                dispatch(
                    actions.setLastCompilationDate(
                        response.data.lastCompilationDate
                    )
                )
            }
            return response
        }, dispatch)
    }

export const updateLastListenedBeatAddedAt =
    (beat: BeatFullType): ThunkActionType<ActionsTypes> =>
    async (dispatch, getState) => {
        await commonAsyncHandler(async () => {
            const beats: BeatFullType[] =
                getState().customerBeatsCompilation.beats
            const response = await compilationAPI.updateLastListenedBeatAddedAt(
                beat.addedToCatalogDate
            )
            if (response.resultCode === ResultCodesEnum.Success) {
                dispatch(filterBeats(beats, beat))
            }
            return response
        }, dispatch)
    }

export const updateCompilation =
    (beat: BeatFullType): ThunkActionType<ActionsTypes> =>
    async (dispatch, getState) => {
        await commonAsyncHandler(async () => {
            const beats: BeatFullType[] =
                getState().customerBeatsCompilation.beats
            const compilation: CompilationType =
                getState().customerBeatsCompilation.compilation
            const compilationSettings =
                getState().appReducer.promoSettings.compilations
            const response = await compilationAPI.updateCompilation(
                compilation._id,
                beat
            )
            if (response.resultCode === ResultCodesEnum.Success) {
                if (
                    response.data.beats.length >=
                    compilationSettings.compilationSize
                ) {
                    dispatch(getCompilation())
                } else {
                    dispatch(filterBeats(beats, beat))
                    dispatch(actions.setBeat(beat))
                }
            }
            return response
        }, dispatch)
    }

const filterBeats =
    (
        beats: BeatFullType[],
        beat: BeatFullType
    ): ThunkActionType<ActionsTypes> =>
    async (dispatch) => {
        const newBeats = beats.filter((b) => b.id !== beat.id)
        if (newBeats.length === 0) {
            dispatch(getBeats())
        } else {
            dispatch(actions.setBeats(newBeats))
        }
        dispatch(actions.setLastListenedBeatAddedAt(beat.addedToCatalogDate))
    }
