import React from 'react'
import { FieldValues, useController, UseControllerProps } from 'react-hook-form'
import {
    NewCustomSelect,
    Props,
} from 'UI/common/elements/selects/NewCustomSelect'

export type ControlledSelectProps<TFieldValues extends FieldValues> =
    UseControllerProps<TFieldValues> & Omit<Props, 'onChange' | 'value'>

export const ControlledSelect = <TFieldValues extends FieldValues>({
    name,
    rules,
    shouldUnregister,
    control,
    customStyles,
    ...selectProps
}: ControlledSelectProps<TFieldValues>) => {
    const {
        field: { onChange, value },
    } = useController({
        name,
        control,
        shouldUnregister,
        rules,
    })
    return (
        <NewCustomSelect
            {...{
                onChange,
                value,
                customStyles,
                ...selectProps,
            }}
        />
    )
}
