import React, { useCallback } from 'react'
import { Route, useHistory } from 'react-router-dom'
import { CustomerRM } from 'UI/routers/RouterManager'
import { Modal } from 'UI/common/modal/modal'
import { Button } from 'UI/common/elements/buttons/Button'
import { useDispatch } from 'react-redux'
import { LanguageType } from 'translations/ru/common'
import { useTranslation } from 'react-i18next'
import s from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/beatspage.module.scss'
import {
    deleteArchiveBeat,
    restoreArchiveBeat,
} from 'BLL/CustomerArchiveBeatsReducer'

type Props = {
    setModalIsActive: (isModal: boolean) => void
}
export const ArchiveRoutes = ({ setModalIsActive }: Props) => {
    const history = useHistory()
    const dispatch = useDispatch()

    const { i18n } = useTranslation()
    const { myBeats, buttons } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    const handleDeleteBeat = useCallback((beatId: string) => {
        dispatch(deleteArchiveBeat(+beatId))
    }, [])

    const handleRestoreBeat = useCallback((beatId: string) => {
        dispatch(restoreArchiveBeat(+beatId))
    }, [])

    return (
        <>
            <Route
                exact
                path={CustomerRM.myBeats.path.archive.restore}
                render={({ match }) => (
                    <Modal
                        active={true}
                        setActive={setModalIsActive}
                        title={myBeats.restoreBeat}
                        cancelRedirect={() =>
                            history.push(CustomerRM.myBeats.path.archive.base)
                        }
                    >
                        <div>
                            <p>{myBeats.restoreBeatInCatalog}</p>
                            <div className={s.buttonsPopUp}>
                                <Button
                                    view={'Secondary'}
                                    title={buttons.CANCEL}
                                    onClick={() =>
                                        history.push(
                                            CustomerRM.myBeats.path.archive.base
                                        )
                                    }
                                />
                                <Button
                                    view={'Primary'}
                                    title={buttons.RESTORE}
                                    onClick={() =>
                                        handleRestoreBeat(
                                            match.params['beatId']
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </Modal>
                )}
            />
            <Route
                exact
                path={CustomerRM.myBeats.path.archive.delete}
                render={({ match }) => (
                    <Modal
                        active={true}
                        setActive={setModalIsActive}
                        title={myBeats.deleteBeat}
                        cancelRedirect={() =>
                            history.push(CustomerRM.myBeats.path.archive.base)
                        }
                    >
                        <div>
                            <p>{myBeats.removeBeat}</p>
                            <div className={s.buttonsPopUp}>
                                <Button
                                    view={'Secondary'}
                                    title={buttons.CANCEL}
                                    onClick={() =>
                                        history.push(
                                            CustomerRM.myBeats.path.archive.base
                                        )
                                    }
                                />
                                <Button
                                    view={'Delete'}
                                    title={buttons.DELETE}
                                    onClick={() =>
                                        handleDeleteBeat(match.params['beatId'])
                                    }
                                />
                            </div>
                        </div>
                    </Modal>
                )}
            />
        </>
    )
}
