import React from 'react'
import { FileWithSelectedLicenses } from 'DAL/BeatSettingsAPI'
import FileIcon from 'UI/assets/icons/file.svg'
import DownloadFile from 'UI/assets/icons/download.svg'
import DeleteIcon from 'UI/assets/icons/delete.svg'
import s from './MobTrackLicenses.module.scss'

type Props = {
    file: FileWithSelectedLicenses
    handleDeleteFile: (fileId: number) => void
    handleDownloadFile: (file: FileWithSelectedLicenses) => void
}
export const MobTrackLicenses = ({
    file,
    handleDeleteFile,
    handleDownloadFile,
}: Props) => {
    return (
        <div className={s.mobTrackLicenses}>
            <div className={s.fileName}>
                <img src={FileIcon} alt="iconDo" />
                <span>{file.name + '.' + file.extension}</span>
            </div>
            <div className={s.btnBox}>
                <button type={'button'}>
                    <img
                        src={DownloadFile}
                        alt={'download-file'}
                        onClick={() => handleDownloadFile(file)}
                    />
                </button>
                <button type={'button'}>
                    <img
                        src={DeleteIcon}
                        alt=""
                        onClick={() => handleDeleteFile(file.id)}
                    />
                </button>
            </div>
        </div>
    )
}
