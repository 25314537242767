import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { LanguageType, ru } from 'translations/ru/common'
import { en } from 'translations/en/common'
import LanguageDetector from 'i18next-browser-languagedetector'
import { AppSettings } from 'settings/appSettings'

const options = {
    // order and from where user language should be detected
    order: [
        'querystring',
        'cookie',
        'localStorage',
        'navigator',
        'htmlTag',
        'path',
        'subdomain',
    ],
    // keys or params to lookup language from
    lookupQuerystring: 'lng',
    lookupCookie: 'i18next',
    lookupLocalStorage: 'i18nextLng',
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,
    // cache user language on
    caches: ['localStorage', 'cookie'],
    excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
    // optional expire and domain for set cookie
    cookieMinutes: 10,
    cookieDomain: 'myDomain',
    // optional htmlTag with lang attribute, the default is:
    htmlTag: document.documentElement,
    // only detect languages that are in the whitelist
    checkWhitelist: true,
}

type ResourcesType = {
    en: {
        common: LanguageType
    }
    ru: {
        common: LanguageType
    }
}

const resources: ResourcesType = {
    en: {
        common: en,
    },
    ru: {
        common: ru,
    },
}

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        detection: options,
        resources,
        lng:
            AppSettings.api.LANGUAGE_VERSION ||
            localStorage.getItem('i18nextLng') ||
            'ru',
        interpolation: {
            escapeValue: false,
        },
    })

export default i18n
