import { FreeBeatType, MyBeatType } from 'DAL/BeatSettingsAPI'
import PauseIcon from 'Player/assets/images/player-icons/pause_blue.svg'
import PlayIcon from 'Player/assets/images/player-icons/play_blue.svg'
import React from 'react'
import sMobile from 'UI/CustomerAdmin/Mobile/MobMyBeatsPage/common/styles/MobBeatItem.module.scss'
import s from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/beatItem/beatItem.module.scss'

type Props = {
    beat: MyBeatType | FreeBeatType
    playHandler: () => void
    isPlayingMe: boolean
    isMobile?: boolean
}

export const BeatCover = ({
    beat,
    isPlayingMe,
    playHandler,
    isMobile,
}: Props) => {
    const classes = isMobile ? sMobile : s

    return (
        <div className={classes.imageWrapper}>
            <img
                className={classes.beatImage}
                src={beat.images.thumbnail}
                onClick={playHandler}
                alt={'cover'}
            />
            <div className={classes.beatControl}>
                <img
                    src={isPlayingMe ? PauseIcon : PlayIcon}
                    alt={'play'}
                    onClick={playHandler}
                />
            </div>
        </div>
    )
}
