import { LanguageType } from 'translations/ru/common'
import { AppSettings } from 'settings/appSettings'
import { CustomerRM } from 'UI/routers/RouterManager'
import { MenuItem } from 'UI/common/menus/menuItems/menuItems'

export const ExpertsMenuItems = (translate: LanguageType) => {
    const isEngMode = AppSettings.api.isEngVersion()
    return [
        new MenuItem(
            translate.navigation.beatsCompilation,
            CustomerRM.compilations.path.list,
            !isEngMode
        ),
    ]
}
