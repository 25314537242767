import React, {
    useCallback,
    useEffect,
    useState,
    useRef,
    useLayoutEffect,
} from 'react'
import { useDispatch } from 'react-redux'
import { LanguageType } from 'translations/ru/common'
import { useTranslation } from 'react-i18next'
import { FormControl } from '@material-ui/core'
import { useRedirectCustomHook } from 'UI/common/hooks/useRedirectCustomHook'
import { RolesTypeEnum } from 'BLL/enums'
import { CustomerRM } from 'UI/routers/RouterManager'
import { SearchField } from 'UI/common/elements/textFields/search'
import { CustomBrowserView } from 'UI/common/browserView/CustomBrowserView'
import { useDeviceDetect } from 'UI/common/hooks/useDeviceDetect'
import { useBeatsPagination } from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/hooks/useBeatsPagination'
import { useDebounce } from 'UI/common/hooks/useDebounce'
import Spinner from 'UI/common/progressBars/Spinner'
import { RequestStatuses } from 'BLL/AppReducer'
import s from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/beatspage.module.scss'
import { getArchiveBeats } from 'BLL/CustomerArchiveBeatsReducer'
import { useArchiveBeatsData } from './hooks/useArchiveBeatsData'
import { ArchiveTable } from './archiveTable/ArchiveTable'
import { BeatsConstants } from 'UI/constants'
import { ArchiveRoutes } from './routes/ArchiveRoutes'

interface Props {
    children?: React.ReactNode
}

export const Archive = ({ children }: Props) => {
    const { beats, totalCount, requestStatus } = useArchiveBeatsData()
    const dispatch = useDispatch()

    const { i18n } = useTranslation()
    const { navigation, myBeats, helpMessage } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    const [_, setModalIsActive] = useState(false)

    const {
        term,
        pagePrev,
        pageSizePrev,
        setTerm,
        setPageSizePrev,
        setPagePrev,
    } = useBeatsPagination()

    const debounced = useDebounce(term)
    const firstRender = useRef(false)

    useEffect(() => {
        firstRender.current = true
    }, [])

    useLayoutEffect(() => {
        firstRender.current = false
    })

    useEffect(() => {
        dispatch(
            getArchiveBeats(
                BeatsConstants.DEFAULT_PAGE,
                BeatsConstants.DEFAULT_PAGE_SIZE
            )
        )
    }, [])

    useEffect(() => {
        if (firstRender.current) return

        dispatch(getArchiveBeats(pagePrev, pageSizePrev, term))
    }, [debounced])

    useEffect(() => {
        if (firstRender.current) return

        dispatch(getArchiveBeats(pagePrev, pageSizePrev, term))
    }, [pagePrev, pageSizePrev])

    const handleChangePageSize = useCallback((pageSize: number) => {
        setPageSizePrev(pageSize)
    }, [])

    const handleChangePage = useCallback((page: number) => {
        setPagePrev(page)
    }, [])

    const isMobile = useDeviceDetect()

    return (
        <div className={s.root}>
            {useRedirectCustomHook(
                RolesTypeEnum.BEATMAKER,
                CustomerRM.cashback.path.list
            )}
            <div>
                <div
                    className={isMobile ? s.MobMyBeatsPageHeader : s.topWrapper}
                >
                    <CustomBrowserView>
                        <div className={s.pageTitle}>{navigation.archive}</div>
                    </CustomBrowserView>
                    <div
                        className={
                            !isMobile ? s.searchWrapperArchive : s.fieldSize
                        }
                    >
                        <FormControl>
                            <SearchField
                                placeholder={helpMessage.search}
                                value={term}
                                onChange={({ target: { value } }) =>
                                    setTerm(value)
                                }
                            />
                        </FormControl>
                    </div>
                </div>
                <div className={s.tabsWrapper}>{children}</div>
                {beats.length ? (
                    <ArchiveTable
                        page={pagePrev}
                        pageSize={pageSizePrev}
                        totalCount={totalCount}
                        beats={beats}
                        ChangePageSize={handleChangePageSize}
                        ChangePage={handleChangePage}
                    />
                ) : (
                    requestStatus === RequestStatuses.InProgress && <Spinner />
                )}
                {requestStatus === RequestStatuses.Success &&
                    beats.length === 0 && (
                        <div className={s.emptyMessage}>
                            {myBeats.noArchiveBeats}
                        </div>
                    )}
            </div>
            <ArchiveRoutes setModalIsActive={setModalIsActive} />
        </div>
    )
}
