import React, { ReactNode } from 'react'
import { useEditingEntityStatus } from '../hooks/useEditingEntityStatus'
import { EditingEntityStatuses } from 'BLL/AppReducer'
import close from 'UI/assets/icons/close.svg'
import s from 'UI/common/popUpCommon/popUpCommon.module.scss'

type PopUpProps = {
    description?: string
    title?: string
    handleClose: () => void
    children?: ReactNode
}

export const PopUpCommon = ({
    children,
    description,
    title,
    handleClose,
}: PopUpProps) => {
    const { editingEntityStatus } = useEditingEntityStatus(
        EditingEntityStatuses.InProgress
    )
    if (editingEntityStatus === EditingEntityStatuses.Success) {
        handleClose()
    }
    return (
        <div className={s.wrapper}>
            <div className={s.header}>
                <h3 className={s.title}>{title}</h3>
                <button onClick={handleClose} type={'button'}>
                    <img src={close} alt="closeIcon" />
                </button>
                {description && (
                    <p className={s.headerDescription}>{description}</p>
                )}
            </div>
            <div className={s.description}>{children}</div>
        </div>
    )
}
