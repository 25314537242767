import { useState } from 'react'

export const useBeatsPagination = () => {
    const [term, setTerm] = useState('')
    const [pagePrev, setPagePrev] = useState<number>(1)
    const [pageSizePrev, setPageSizePrev] = useState<number>(100)

    return {
        term,
        pagePrev,
        pageSizePrev,
        setTerm,
        setPagePrev,
        setPageSizePrev,
    }
}
