export enum StatusSubscriptionEnum {
    Active = 0,
    Expired = 1,
}

export enum SubscriptionPlansEnum {
    Free = 20,
    Premium = 100,
    Expert = 9999,
}

export enum StatusCompilationEnum {
    NotPublished = 0,
    Published = 1,
    Complited = 2,
    Error = 3,
}

export enum LanguageEnum {
    Ru = 'ru',
    En = 'en',
}

export enum StatusExpertEnum {
    Active = 0,
    Paused = 1,
    Stopped = 2,
}

export enum StatusPublicationEnum {
    NotPublished = 0,
    Pending = 1,
    Published = 2,
    Error = 3,
}

export enum RolesTypeEnum {
    ADMINISTRATOR = 'ADMINISTRATOR',
    BEATMAKER = 'BEATMAKER',
    PROMO_VIEWER = 'PROMO_VIEWER',
    COMPILATIONS_MANAGER = 'COMPILATIONS_MANAGER',
    SUBSCRIPTION_MANAGER = 'SUBSCRIPTION_MANAGER',
    TEMPLATE_MANAGER = 'TEMPLATE_MANAGER',
    SHOPS_MANAGER = 'SHOPS_MANAGER',
    PAYMENTS = 'PAYMENTS',
    EXPERT = 'EXPERT',
}

export enum WidgetThemeType {
    DarkRed = 'dark-red',
    Light = 'light',
    DarkPink = 'dark-pink',
    DarkOrange = 'dark-orange',
    DarkNeon = 'dark-neon',
    Custom = 'custom',
}
