import { BeatLicenseType } from 'DAL/Services'
import { AppSettings } from 'settings/appSettings'
import { BeatDetailsType } from 'DAL/BeatSettingsAPI'

export const bindingsLicenseHandle = (
    currentBeat: BeatDetailsType,
    licenses: BeatLicenseType[]
): BeatLicenseType[] => {
    return currentBeat.bindings.map((bind) => {
        const license = licenses.find(
            (lic) => lic.id === bind.beatLicenseId
        ) as BeatLicenseType
        return {
            ...license,
            priceDollar: bind.priceInDollars || license.priceDollar,
            price: bind.priceInRubles || license.price,
            title: AppSettings.api.isEngVersion()
                ? license.titleEn
                : license.title,
        } as BeatLicenseType
    })
}
