import { Action } from 'redux'
import { ThunkAction } from 'redux-thunk'
import {
    LanguageEnum,
    StatusCompilationEnum,
    StatusExpertEnum,
    StatusPublicationEnum,
    StatusSubscriptionEnum,
    SubscriptionPlansEnum,
} from './enums'
import { AppState } from './Store'

export type BaseResponse<T = null> = {
    resultCode: ResultCodesEnum
    messages: string[] | MessagesType[]
    messages2: MessagesType[]
    message: string
    data: T
}

export type MessagesType = {
    message: string
    field: NullableType<string>
}

export const OptionsSelectLang = [
    { value: LanguageEnum.Ru, label: 'RUSSIAN' },
    { value: LanguageEnum.En, label: 'ENGLISH' },
]

export type NullableType<T> = null | T

export type EmailsHistoryType = {
    _id: any
    userId: number
    templateKey: string
    email: string
    sendingDate: Date
    html: string
    isViewed: boolean
    isRedirect: boolean
}

export type StatisticsType = {
    total: number
    active: number
    expired: number
}

export enum ResultCodesEnum {
    Success = 0,
    Error = 1,
}

export type GetSubscriptionsDataType = {
    totalCount: number
    pageSize: number
    pageCount: number
    currentPage: number
    pagingCounter: number
    hasPrevPage: boolean
    hasNextPage: boolean
    prev: boolean
    next: boolean
    docs: SubscriptionType[]
    statistics: StatisticsType
}

export type EmailTemplateType = {
    name: string
    templateKey: string
    html: string
    author: string
    modelProperty: string
    attemptIntervals: string[]
} & BaseEntityType

export type BaseEntityType = {
    _id: any
    createdAt: Date
    updatedAt: Date
}
export type SubscriptionType = {
    _id: any
    userId: number
    email: string
    expirationDate: Date
    diskSpaceInMb: number
    userName: string
    paymentLink: string
    subscriptionPlan: SubscriptionPlansEnum | null
    emailData: IEmailData
    emailsHistory: EmailsHistoryType[]
    status?: StatusSubscriptionEnum
}

export type IEmailData = {
    lastSendingDate?: string | null
    nextSendingDate: string | null
    nextSendingMessage: string | null
    lastSendingMessage?: string | null
}

export enum ActionResultCodes {
    Success = 0,
    Error = 1,
}

export type ExpertType = {
    _id: string
    beatmakerId: number
    beatmakerName: string
    status: StatusExpertEnum
    lastCompilationDate: Date
    lastListenedBeatAddedAt: string | null
    description: string
    image: string
}

export type BeatFullType = {
    id: number
    beatmakerId: number
    title: string
    playUrl: string
    addedToCatalogDate: string
}

export type GetCompilationType = {
    compilation: CompilationType
    lastListenedBeatAddedAt: string
    lastCompilationDate: Date
}

export type CompilationType = {
    _id: string
    beatmakerId: number
    beats: BeatFullType[]
    status?: StatusCompilationEnum
    publishingDate?: Date
    complitedDate?: Date
}

export type PublicationType = {
    _id?: any
    beat: BeatType
    userId?: number
    date: string
    status?: StatusPublicationEnum | null
    message?: string
}

export type PromoType = {
    stats: StatsType
    addedDate: string
    _id: string
    date: string
    beat: BeatType
    promoType: number
    amountCoins: number
    userId: number
    historyBeats: BeatType[]
}

export type StatsType = {
    _id: string
    shows: number
}

export type BusyDaysType = {
    isBusy: boolean
    totalSlots: number
    busySlots: number
    busyUserSlots: number
    data: string
}

export type PromoPriceType = {
    price: number
    type: keyof PromoCoreType
    engMode: boolean
}

export enum PromoTypeEnum {
    Player = 0,
    MainPage = 1,
    CatalogPage = 2,
    FullScreenPopup = 3,
    CatalogFirstPositions = 4,
    EngPlayer = 100,
    EngMainPage = 101,
    EngCatalogPage = 102,
    EngCatalogFirstPositions = 103,
}

export type PromoCoreType = {
    0: 'Player'
    1: 'Main Page'
    2: 'Catalog Page'
    3: 'Full Screen Popup'
    4: 'Catalog First Positions'
    100: 'Player'
    101: 'Main Page'
    102: 'Catalog Page'
    103: 'Catalog First Positions'
}

export type BusyDaysPeriodType = {
    endOfMonth?: string
    promoType: number
    startOfMonth: Date
}

export type BeatType = {
    beatId: number
    beatTitle: string
    publicationDate?: string
    publicationTime?: string
}

export type UpdatePromoType = {
    beatId: number
    promo: PromoType
}

export type SubscriptionEmailTemplate = {
    redirectUrl: string
    userName: string
    message: string
}

export interface IShop {
    city: string
    country: string
    id: number | string
    title: string
}

export interface IShops {
    [key: string]: IShop
}

export enum ContractsTypeEnum {
    All = 'all',
    Exclusive = 'exclusive',
}

export enum ContractsLangEnum {
    Russian = 'ru',
    English = 'en',
}

export type TemplateContractsType = {
    _id: any
    contractsType: ContractsTypeEnum
    title: string
    lang: ContractsLangEnum
    body: string
    variables: string[]
}

export type BeatmakerContractsType = {
    _id: any
    contractsType: ContractsTypeEnum
    licenseId: string
    beatmakerId: number
    lang: ContractsLangEnum
    body: string
}

export enum M3U8Statuses {
    None = 0,
    Created = 1,
    Error = 2,
    Mp3AbsentError = 3,
    CanotBeConvertedError = 4,
}

export type P2PKeysType = {
    publicKey: string
    privateKey: string
}

export type DownloadResponse = {
    Extra: {
        OK: boolean
        Message: string
    }
    Response: [{ k: string; v: boolean }]
    ShowSuccessSlideBox: boolean
}

export type ThunkActionType<T extends Action<any>> = ThunkAction<
    Promise<void>,
    AppState,
    unknown,
    T
>
