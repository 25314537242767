import React from 'react'
import s from '../bindingCard.module.scss'

type Props = {
    termsTitle: string
    terms: { term1: string; term2: string; term3: string }
}
export const TinkoffTerms = ({ termsTitle, terms }: Props) => {
    return (
        <>
            <h2 className={s.title}>{termsTitle}</h2>
            <p className={s.termText}>{terms.term1}</p>
            <p className={s.termText}>{terms.term2}</p>
            <p className={s.termText}>{terms.term3}</p>
        </>
    )
}
