import { CONSTANTS, STATUSES } from './SupportingFile'
import { compilationAPI, UpdateExpertModel } from '../DAL/CompilationAPI'
import { ExpertType, ResultCodesEnum, ThunkActionType } from './types'
import { StatusExpertEnum } from './enums'
import { InferActionsTypes } from './Store'
import {
    actions as authActions,
    AuthActionsTypes,
    EditingEntityStatuses,
} from './AppReducer'
import { commonAsyncHandler } from './common-async-handler'

const initState = {
    experts: [] as ExpertType[],
    statusLoadingExperts: STATUSES.NOT_INIT as string,
    statusUpdate: STATUSES.NOT_INIT as string,
    message: '',
    statusAddedExpert: STATUSES.NOT_INIT as string,
    expertStatusUpdate: '',
    currentExpert: null as ExpertType | null,
    newExpertId: null as string | null,
}

type IState = typeof initState
export type ActionsTypes = InferActionsTypes<typeof actions> | AuthActionsTypes

const AdminExpertsCompilationReducer = (
    state = initState,
    action: ActionsTypes
): IState => {
    switch (action.type) {
        case 'ADMIN_EXPERTS_COMPILATION_REDUCER/SET_EXPERTS':
            return {
                ...state,
                experts: action.experts,
            }
        case 'ADMIN_EXPERTS_COMPILATION_REDUCER/SET_STATUS_EXPERT':
            return {
                ...state,
                experts: state.experts.map((expert) => {
                    if (expert._id === action.id) {
                        return { ...expert, status: action.status }
                    } else return expert
                }),
            }
        case 'ADMIN_EXPERTS_COMPILATION_REDUCER/SET_STATUS_UPDATE':
            return {
                ...state,
                statusUpdate: action.status,
                expertStatusUpdate: action.expertId,
            }
        case 'ADMIN_EXPERTS_COMPILATION_REDUCER/SET_EXPERT':
            return {
                ...state,
                experts: [...state.experts, action.expert],
            }
        case 'ADMIN_EXPERTS_COMPILATION_REDUCER/SET_UPDATE_EXPERT':
            return {
                ...state,
                experts: state.experts.map((expert) => {
                    if (expert._id === action.id) {
                        return { ...expert, ...action.values }
                    } else {
                        return expert
                    }
                }),
            }
        case 'ADMIN_EXPERTS_COMPILATION_REDUCER/SET_CURRENT_EXPERT':
            return {
                ...state,
                currentExpert: action.expert,
            }
        case 'ADMIN_EXPERTS_COMPILATION_REDUCER/SET_NEW_EXPERT_ID':
            return {
                ...state,
                newExpertId: action.id,
            }
        default:
            return state
    }
}

export const actions = {
    setCurrentExperts: (expert: ExpertType | null) =>
        ({
            type: 'ADMIN_EXPERTS_COMPILATION_REDUCER/SET_CURRENT_EXPERT',
            expert,
        } as const),
    setExperts: (experts: ExpertType[]) =>
        ({
            type: 'ADMIN_EXPERTS_COMPILATION_REDUCER/SET_EXPERTS',
            experts,
        } as const),
    setNewExpertId: (id: string | null) =>
        ({
            type: 'ADMIN_EXPERTS_COMPILATION_REDUCER/SET_NEW_EXPERT_ID',
            id,
        } as const),
    setExpert: (expert: ExpertType) =>
        ({
            type: 'ADMIN_EXPERTS_COMPILATION_REDUCER/SET_EXPERT',
            expert,
        } as const),
    setUpdateExpert: (id: string, values: UpdateExpertModel) =>
        ({
            type: 'ADMIN_EXPERTS_COMPILATION_REDUCER/SET_UPDATE_EXPERT',
            id,
            values,
        } as const),
    setStatusUpdate: (status: string, expertId: string) =>
        ({
            type: 'ADMIN_EXPERTS_COMPILATION_REDUCER/SET_STATUS_UPDATE',
            status,
            expertId,
        } as const),
    setStatusExpert: (status: StatusExpertEnum, id: string) =>
        ({
            type: 'ADMIN_EXPERTS_COMPILATION_REDUCER/SET_STATUS_EXPERT',
            status,
            id,
        } as const),
}

export const getExperts =
    (): ThunkActionType<ActionsTypes> => async (dispatch) => {
        await commonAsyncHandler(async () => {
            const response = await compilationAPI.getExperts()
            if (response.resultCode === ResultCodesEnum.Success) {
                dispatch(actions.setExperts(response.data))
            }
            return response
        }, dispatch)
    }

export const getExpertById =
    (id: string): ThunkActionType<ActionsTypes> =>
    async (dispatch) => {
        await commonAsyncHandler(async () => {
            const response = await compilationAPI.getExpertById(id)
            if (response.resultCode === ResultCodesEnum.Success) {
                dispatch(actions.setCurrentExperts(response.data))
            }
            return response
        }, dispatch)
    }

export const changeStatusExpert =
    (status: StatusExpertEnum, id: string): ThunkActionType<ActionsTypes> =>
    async (dispatch) => {
        await commonAsyncHandler(async () => {
            dispatch(actions.setStatusUpdate(STATUSES.LOADING, id))
            const response = await compilationAPI.updateStatusExpert(status, id)
            if (response.resultCode === ResultCodesEnum.Success) {
                dispatch(actions.setStatusExpert(status, id))
                dispatch(actions.setStatusUpdate(STATUSES.SUCCESS, id))
                dispatch(
                    authActions.showSnackbar(
                        'success',
                        CONSTANTS.SUCCESS_MESSAGE
                    )
                )
            } else {
                dispatch(actions.setStatusUpdate(STATUSES.ERROR, id))
                dispatch(
                    authActions.showSnackbar('error', CONSTANTS.ERROR_MESSAGE)
                )
            }
            return response
        }, dispatch)
    }

export const addedExpert =
    (): ThunkActionType<ActionsTypes> => async (dispatch) => {
        await commonAsyncHandler(async () => {
            const response = await compilationAPI.addedExpert()
            if (response.resultCode === ResultCodesEnum.Success) {
                dispatch(actions.setExpert(response.data))
                dispatch(actions.setNewExpertId(response.data._id))
                dispatch(
                    authActions.showSnackbar(
                        'success',
                        CONSTANTS.SUCCESS_MESSAGE
                    )
                )
            } else {
                dispatch(
                    authActions.showSnackbar('error', CONSTANTS.ERROR_MESSAGE)
                )
            }
            return response
        }, dispatch)
    }

export const updateExpert =
    (id: string, values: UpdateExpertModel): ThunkActionType<ActionsTypes> =>
    async (dispatch) => {
        await commonAsyncHandler(async () => {
            const response = await compilationAPI.updateExpert(id, {
                ...values,
            })
            if (response.resultCode === ResultCodesEnum.Success) {
                dispatch(actions.setUpdateExpert(id, { ...response.data }))
                dispatch(
                    authActions.showSnackbar(
                        'success',
                        CONSTANTS.SUCCESS_MESSAGE
                    )
                )
                dispatch(
                    authActions.setEditingEntityStatus(
                        EditingEntityStatuses.Success
                    )
                )
            } else {
                dispatch(authActions.showSnackbar('error', response.message))
            }
            return response
        }, dispatch)
    }

export default AdminExpertsCompilationReducer
