import 'date-fns'
import React, { FC, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import DateFnsUtils from '@date-io/date-fns'
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { formatDate } from '../../../BLL/SupportingFile'

interface IProps {
    handleSelectDate: (date: string) => void
    title: string
    date?: string
    disabled?: boolean
}

export const DatePicker: FC<IProps> = ({
    handleSelectDate,
    title,
    date,
    disabled,
}) => {
    const [values, setReactSelect] = React.useState<Date>(new Date())

    useEffect(() => {
        if (date) {
            setReactSelect(new Date(date))
        }
    }, [date])

    const handleChange = (selectedOption: Date | null) => {
        if (selectedOption && selectedOption.toString() !== 'Invalid Date') {
            setReactSelect(selectedOption)
            handleSelectDate(formatDate(selectedOption))
        }
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container>
                <KeyboardDatePicker
                    autoOk
                    disableToolbar
                    variant="inline"
                    format="yyyy-MM-dd"
                    margin="normal"
                    id="date-picker-inline"
                    label={title}
                    value={values}
                    disabled={disabled}
                    onChange={handleChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </Grid>
        </MuiPickersUtilsProvider>
    )
}
