import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'
import { BeatLicenseType } from 'DAL/Services'
import { useSelector } from 'UI/common/hooks/redux-hooks'
import { FilesBindedToLicensesType } from 'DAL/BeatSettingsAPI'
import Carousel from 'react-elastic-carousel'
import { useDeviceDetect } from 'UI/common/hooks/useDeviceDetect'
import { CollapsedWrapper } from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/beatSettings/CollapsedWrapper'
import { LicenseItem } from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/beatSettings/trackLicenses/LicenseItem'
import { selectLicenses } from 'BLL/selectors/contracts-selectors'
import Arrow from 'UI/assets/icons/arrow-right-slide.svg'
import s from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/beatSettings/trackLicenses/trackLicense.module.scss'

type Props = {
    bindingsLicense: BeatLicenseType[]
    beatLicenseNotDefaultPrice: BeatLicenseType[]
    handleChangeBindLicensePrice: (
        price: number,
        licId: string,
        isDollar?: boolean
    ) => void
    handleSelectLicense: (license: BeatLicenseType, checked: boolean) => void
    handleChangeDefaultLicensePrice: (
        lic: BeatLicenseType,
        checked: boolean,
        priceDollar: number | null,
        price: number | null
    ) => void
    filesBindedToLicenses: FilesBindedToLicensesType | null
}

export const TrackLicenses = ({
    beatLicenseNotDefaultPrice,
    bindingsLicense,
    handleChangeBindLicensePrice,
    handleSelectLicense,
    handleChangeDefaultLicensePrice,
    filesBindedToLicenses,
}: Props) => {
    const licenses = useSelector(selectLicenses)

    const [collapsed, setCollapsed] = useState(false)

    const { i18n } = useTranslation()
    const { myBeats } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    const isMobile = useDeviceDetect()

    return (
        <div className={s.LicenseItem}>
            <CollapsedWrapper
                title={myBeats.beatSettings.license}
                collapsed={collapsed}
                setCollapsed={setCollapsed}
            />
            {collapsed && (
                <div className={s.licenses}>
                    <Carousel
                        itemsToShow={isMobile ? 1 : 3}
                        isRTL={false}
                        pagination={false}
                        renderArrow={({ type, onClick }) => {
                            return type === 'PREV' ? (
                                <img
                                    src={Arrow}
                                    alt={'arrow-left'}
                                    onClick={onClick}
                                    className={`${s.arrow} ${s.arrowLeft}`}
                                />
                            ) : (
                                <img
                                    src={Arrow}
                                    alt={'arrow-right'}
                                    onClick={onClick}
                                    className={s.arrow}
                                />
                            )
                        }}
                    >
                        {licenses.map((license) => (
                            <LicenseItem
                                key={license.id}
                                license={license}
                                bindingsLicense={bindingsLicense}
                                beatLicenseNotDefaultPrice={
                                    beatLicenseNotDefaultPrice
                                }
                                handleChangeBindLicensePrice={
                                    handleChangeBindLicensePrice
                                }
                                handleSelectLicense={handleSelectLicense}
                                handleChangeDefaultLicensePrice={
                                    handleChangeDefaultLicensePrice
                                }
                                filesBindedToLicenses={filesBindedToLicenses}
                            />
                        ))}
                    </Carousel>
                </div>
            )}
        </div>
    )
}
