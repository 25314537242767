import { deviceDetect } from 'react-device-detect'
import { useEffect, useState } from 'react'

export const useDeviceDetect = () => {
    const [isVisible, setIsVisible] = useState(false)

    const handleResize = (event?: any) => {
        if (
            event?.currentTarget.document.defaultView.innerWidth < 1000 ||
            deviceDetect().isMobile
        ) {
            setIsVisible(true)
        } else {
            setIsVisible(false)
        }
    }
    useEffect(() => {
        if (window.innerWidth < 1000) {
            setIsVisible(true)
        } else {
            setIsVisible(false)
        }
    }, [])

    useEffect(() => {
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])
    return deviceDetect()?.isMobile || isVisible
}
