import React, { FC, useState } from 'react'
import classes from './MobAdvertisingBeats.module.scss'
import { Button } from '../../../common/elements/buttons/Button'
import { AdvertisingBeat } from './AdvertisingBeat/AdvertisingBeat'
import { useTranslation } from 'react-i18next'
import { LanguageType } from '../../../../translations/ru/common'
import { ButtonTooltip } from '../../../common/elements/buttons/ButtonTooltip'
import { PromoType } from '../../../../BLL/types'
import { InfoPopUp } from '../../CustomerBeatsPromotion/InfoPromo'

type PropsType = {
    setCalendarIsOpen: (value: boolean) => void
    promotions: PromoType[]
}

export const MobAdvertisingBeats: FC<PropsType> = ({
    setCalendarIsOpen,
    promotions,
}) => {
    const { i18n } = useTranslation()
    const { buttons } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType
    const [showInfo, setShowInfo] = useState(false)
    return (
        <div className={classes.MobAdvertisingBeats}>
            <div className={classes.btnBox}>
                <Button
                    title={buttons.ADD_ADVERTISING}
                    onClick={() => setCalendarIsOpen(true)}
                />
            </div>
            <InfoPopUp showInfo={showInfo} setShowInfo={setShowInfo} />
            {promotions.map((promotion) => (
                <AdvertisingBeat key={promotion._id} promotion={promotion} />
            ))}
        </div>
    )
}
