import LinearProgress, {
    LinearProgressProps,
} from '@material-ui/core/LinearProgress'
import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles({
    colorPrimary: {
        backgroundColor: '#1f1f1f',
    },
    barColorPrimary: {
        backgroundColor: '#3598DB',
    },
})

export const ColorLinearProgress = ({ ...props }: LinearProgressProps) => {
    const classes = useStyles()
    return <LinearProgress classes={classes} {...props} />
}
