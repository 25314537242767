import { beatsPromotionAxios } from './createAxios'
import { BaseResponse } from '../BLL/types'
import { SettingsType } from '../BLL/BeatmakerSettingsReducer'

export type SettingsPromoServerType = {
    compilations: {
        compilationSize: number
        timeOutDays: number
    }
}

export type CashbackSettingsType = {
    isActive: boolean
}

export const settingsAPI = {
    getSettingsPromoServer() {
        return beatsPromotionAxios
            .get<SettingsPromoServerType>('/settings')
            .then((r) => r.data)
    },

    getCashbackSettings() {
        return beatsPromotionAxios
            .get<CashbackSettingsType>(`/cashbacks/settings`)
            .then((r) => r.data)
    },

    updateCashbackSettings(settings: SettingsType) {
        return beatsPromotionAxios
            .put<BaseResponse>(`/cashbacks/settings`, {
                settings: settings.cashback,
            })
            .then((r) => r.data)
    },
}
