import React from 'react'
import { CoinsHistory } from './CoinsHistory/CoinsHistory'
import { CustomerCoinsCalc } from './CustomerCoinsCalc/CustomerCoinsCalc'
import s from 'UI/CustomerAdmin/CustomerCoins/coinsPage.module.scss'

export const CustomerCoinsPage = () => {
    return (
        <div>
            <div className={s.coinsCalc}>
                <CustomerCoinsCalc />
            </div>
            <div className={s.coinsHistory}>
                <CoinsHistory />
            </div>
        </div>
    )
}
