import React, { ReactNode } from 'react'
import { FormControlLabel, Radio } from '@mui/material'
import s from './cardItem.module.scss'

type Props = {
    children?: ReactNode
    value: string
    onSelectCard: (cardId: string) => void
    isCurrent: boolean
}

export const CardItem = ({
    children,
    value,
    onSelectCard,
    isCurrent,
}: Props) => {
    return (
        <div className={s.cardItemWrapper}>
            <FormControlLabel
                value={value}
                control={
                    <Radio
                        checked={isCurrent}
                        onClick={() => onSelectCard(value)}
                        sx={{ '&': { color: 'gray' } }}
                    />
                }
                label={children}
            />
        </div>
    )
}
