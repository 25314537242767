import { beatsPromotionAxios } from './createAxios'
import {
    BeatType,
    BusyDaysPeriodType,
    BusyDaysType,
    PromoPriceType,
    PromoType,
    BaseResponse,
} from '../BLL/types'

export type CreatePromoDto = {
    date: string
    beat: BeatType
    promoType: number
    amountCoins: number
}

export type PaginateType<T = []> = {
    currentPage: number
    hasNextPage: boolean
    hasPrevPage: boolean
    items: T
    next: null | boolean
    pageCount: number
    pageSize: number
    pagingCounter: number
    prev: null | boolean
    totalCount: number
}
export const promoAPI = {
    async reservePromotion(promo: CreatePromoDto) {
        return await beatsPromotionAxios
            .post<BaseResponse<PromoType>>('/promo', promo)
            .then((r) => r.data)
    },

    async getPromotions(page: number, pageSize: number) {
        return await beatsPromotionAxios
            .get<PaginateType<PromoType[]>>(
                `/promo?page=${page}&pageSize=${pageSize}`
            )
            .then((r) => r.data)
    },

    async getPromotionsAll(date: string) {
        return await beatsPromotionAxios
            .get<BaseResponse<PromoType[]>>(`/promo/admin?date=${date}`)
            .then((r) => r.data)
    },

    async getBusyDays(period: BusyDaysPeriodType) {
        return await beatsPromotionAxios
            .put<BaseResponse<BusyDaysType[]>>('/promo/busyDays', period)
            .then((r) => r.data)
    },

    async updatePromo(promo: PromoType) {
        return await beatsPromotionAxios
            .put<BaseResponse>('/promo', promo)
            .then((r) => r.data)
    },

    async getPrice() {
        return await beatsPromotionAxios
            .get<{ promoPrice: PromoPriceType[] }>('/public/beats/price')
            .then((r) => r.data)
    },
}
