import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { LanguageType } from '../../../../translations/ru/common'
import { useDispatch } from 'react-redux'
import {
    updateCompilation,
    updateLastListenedBeatAddedAt,
} from '../../../../BLL/CustomerBeatsCompilationReducer'
import { useSelector } from '../../../common/hooks/redux-hooks'
import { useRedirectCustomHook } from '../../../common/hooks/useRedirectCustomHook'
import { RolesTypeEnum } from '../../../../BLL/enums'
import classes from './compilations.module.scss'
import { Equalizer } from '../equalizer/Equalizer'
import { CompilationPlayer } from '../compilation-player/CompilationPlayer'
import { getLocaleDateString } from '../../../utils/util-types'
import * as dateFns from 'date-fns'
import { Button } from '../../../common/elements/buttons/Button'
import { RequestStatuses } from '../../../../BLL/AppReducer'
import { Redirect } from 'react-router-dom'
import { CustomerRM } from '../../../routers/RouterManager'

export const Compilations: FC = () => {
    const dispatch = useDispatch()
    const { t, i18n } = useTranslation()
    const { buttons, customerBeatsCompilation, navigation } =
        i18n.getResourceBundle(i18n.language, 'common') as LanguageType

    const beats = useSelector((state) => state.customerBeatsCompilation.beats)
    const requestStatus = useSelector((state) => state.appReducer.requestStatus)
    const compilation = useSelector(
        (state) => state.customerBeatsCompilation.compilation
    )
    const isPauseCompilation = useSelector(
        (state) => state.customerBeatsCompilation.isPauseCompilation
    )
    const expert = useSelector((state) => state.customerBeatsCompilation.expert)
    const statusCompilation = useSelector(
        (state) => state.customerBeatsCompilation.statusCompilation
    )
    const statusUpdate = useSelector(
        (state) => state.customerBeatsCompilation.statusUpdate
    )
    const statusGetBeats = useSelector(
        (state) => state.customerBeatsCompilation.statusGetBeats
    )
    const promoSettings = useSelector((state) => state.appReducer.promoSettings)

    const handleAdd = () => {
        dispatch(updateCompilation(beats[0]))
    }

    const handleSkip = () => {
        dispatch(updateLastListenedBeatAddedAt(beats[0]))
    }

    const auth = useRedirectCustomHook(RolesTypeEnum.EXPERT)
    if (isPauseCompilation)
        return <Redirect to={CustomerRM.compilations.path.list} />
    return (
        <div>
            {auth}
            <div className={classes.topWrapper}>
                <div className={classes.pageTitle}>
                    {navigation.beatsCompilation}
                </div>

                <div className={classes.compilationCount}>
                    <span className={classes.text}>
                        {customerBeatsCompilation.beatsCompilationText}
                    </span>
                    <span>
                        {compilation.beats.length}/
                        {promoSettings.compilations.compilationSize}
                    </span>
                </div>
            </div>
            <div className={classes.wrapperPlayer}>
                <Equalizer />
                <CompilationPlayer />
                <div className={classes.wrapperButton}>
                    <Button
                        title={buttons.ADD_TO_COMPILATION}
                        onClick={handleAdd}
                        disabled={
                            requestStatus === RequestStatuses.InProgress ||
                            !beats.length
                        }
                    />
                    <Button
                        title={buttons.SKIP}
                        view={'Outline'}
                        onClick={handleSkip}
                        disabled={
                            requestStatus === RequestStatuses.InProgress ||
                            !beats.length
                        }
                    />
                </div>
            </div>
        </div>
    )
}
