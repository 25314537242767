import React, { FC, useState } from 'react'
import classes from './beat-title.module.scss'
import DropDownIcon from '../../../assets/icons/drop-down.svg'
import DropUpIcon from '../../../assets/icons/drop-up.svg'
import { PaymentTypeBLL } from '../../../../BLL/MySalesReducer'

type PropsType = {
    payment: PaymentTypeBLL
}

export const BeatTitle: FC<PropsType> = ({ payment }) => {
    const [isCollapsed, setIsCollapsed] = useState(false)
    return (
        <div className={classes.BeatNameWrapper}>
            {payment.sales.length > 1 && !isCollapsed ? (
                <div className={classes.beatName}>
                    <img
                        src={DropDownIcon}
                        alt={'drop-down'}
                        onClick={() => setIsCollapsed(true)}
                    />
                    {payment.sales[0].beatTitle}
                </div>
            ) : (
                !isCollapsed && (
                    <div className={classes.beatName}>
                        {payment.sales[0].beatTitle}
                    </div>
                )
            )}
            {isCollapsed && (
                <div className={classes.beatNameCollapsed}>
                    <img
                        src={DropUpIcon}
                        alt={'drop-up'}
                        onClick={() => setIsCollapsed(false)}
                    />
                    <div>
                        {payment.sales.map((sale) => (
                            <div className={classes.beatName} key={sale.id}>
                                {sale.beatTitle}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}
