import React, { useState } from 'react'
import { PlayerMob } from './PlayerMob/PlayerMob'
import { Player } from './Player/Player'
import { usePlayer } from '../../Hooks/Hooks'
import s from './Players.module.scss'

export const Players = () => {
    const [mobMode, setMobMode] = useState(false)

    const [isCollapsed, setIsCollapsed] = useState(false)

    const [player] = usePlayer(true, null)

    const track = player.currentTrack

    const toggleMobMode = () => {
        setMobMode(!mobMode)
    }

    const toggleCollapsed = () => {
        setIsCollapsed(!isCollapsed)
    }

    // если трека никакого нет - не показываем плеер
    if (!track) {
        return null
    }

    return (
        <>
            {isCollapsed ? (
                <div onClick={toggleCollapsed}>
                    <div className={s.bg} />
                    <button className={`${s.playBeatButton} ${s.play}`} />
                </div>
            ) : mobMode ? (
                <PlayerMob
                    toggleMobMode={toggleMobMode}
                    player={player}
                    track={track}
                />
            ) : (
                <Player
                    toggleMobMode={toggleMobMode}
                    toggleCollapsed={toggleCollapsed}
                    player={player}
                    track={track}
                />
            )}
        </>
    )
}
