import React, { FC } from 'react'
import classes from './AddNewLicense.module.scss'
import { MobReturn } from '../../MobileCommon/MobReturn'
import { Button } from '../../../../common/elements/buttons/Button'
import { InputField } from '../../../../common/elements/textFields/Input'
import { CustomCheckbox } from '../../../../common/elements/CustomCheckbox'
import drag from '../../../../assets/icons/drag-icon.svg'
import deleteIcon from '../../../../assets/icons/delete.svg'
import { CustomerRM } from '../../../../routers/RouterManager'
import { BeatLicenseType } from '../../../../../DAL/Services'
import { useTranslation } from 'react-i18next'
import { LanguageType } from '../../../../../translations/ru/common'
import { LanguageEnum } from '../../../../../BLL/enums'
import { AppSettings } from 'settings/appSettings'
import { NavLink } from 'react-router-dom'
import { PriceField } from '../../../../common/elements/textFields/price-input'
import { Controller } from 'react-hook-form'
import { FormControlLabel } from '@material-ui/core'
import { MyCheckbox } from '../../../../common/elements/checkbox/MyCheckbox'
import { LicenseOptions } from '../../../CustomerBeatsContracts/CustomerMyLicenses/license-options'
import { NullableType } from '../../../../../BLL/types'
import { useParams } from 'react-router'
import { ColorLinearProgress } from '../../../../common/progressBars/ColorLinearProgress'

type PropsType = {
    beatLicense: NullableType<BeatLicenseType>
    handleAddContract: () => void
    handleCreateOption: () => void
    register: any
    control: any
}
export const AddNewLicense: FC<PropsType> = ({
    beatLicense,
    handleAddContract,
    control,
    handleCreateOption,
    register,
}) => {
    const { i18n } = useTranslation()
    const { id } = useParams<{ id: string }>()
    const {
        licenses,
        buttons,
        license: customerMyLicense,
    } = i18n.getResourceBundle(i18n.language, 'common') as LanguageType
    const lang = i18n.language as LanguageEnum
    const isEng = AppSettings.api.isEngVersion()
    return (
        <div className={classes.AddNewLicense}>
            <MobReturn
                title={isEng ? beatLicense?.titleEn : beatLicense?.title}
                redirectUrl={CustomerRM.contracts.path.license.base}
            />
            {id && !beatLicense ? (
                <ColorLinearProgress />
            ) : (
                <>
                    {id && (
                        <div className={classes.btnBoxAdd}>
                            {!beatLicense?.contract ? (
                                <Button
                                    view={'Outline'}
                                    title={buttons.ADD_CONTRACT}
                                    onClick={handleAddContract}
                                />
                            ) : (
                                <NavLink
                                    to={CustomerRM.contracts.getUrl.contracts.edit(
                                        beatLicense?.id,
                                        i18n.language
                                    )}
                                    style={{
                                        textDecoration: 'none',
                                    }}
                                >
                                    <Button
                                        type={'button'}
                                        view={'Outline'}
                                        title={buttons.EDIT_CONTRACT}
                                    />
                                </NavLink>
                            )}
                        </div>
                    )}
                    <h2 className={classes.title}>
                        {customerMyLicense.licenseDetails.general}
                    </h2>
                    <div className={classes.information}>
                        <div className={classes.mainInfo}>
                            <div className={classes.item}>
                                <label className={classes.descr}>
                                    {
                                        customerMyLicense.licenseDetails
                                            .licenseNameEng
                                    }
                                </label>
                                <div className={classes.fieldSize}>
                                    <InputField
                                        defaultValue={
                                            beatLicense?.titleEn || ''
                                        }
                                        register={register('titleEn', {
                                            required: true,
                                        })}
                                    />
                                </div>
                            </div>
                            <div className={classes.item}>
                                <label className={classes.descr}>
                                    {
                                        customerMyLicense.licenseDetails
                                            .licenseCostEng
                                    }
                                </label>
                                <div className={classes.fieldSize}>
                                    <PriceField
                                        register={register('priceDollar', {
                                            required: true,
                                        })}
                                        defaultValue={
                                            beatLicense?.priceDollar || 0
                                        }
                                    />
                                </div>
                            </div>
                            {!isEng && (
                                <div className={classes.item}>
                                    <label className={classes.descr}>
                                        {
                                            customerMyLicense.licenseDetails
                                                .licenseNameRus
                                        }
                                    </label>
                                    <div className={classes.fieldSize}>
                                        <InputField
                                            defaultValue={
                                                beatLicense?.title || ''
                                            }
                                            register={register('title', {
                                                required: true,
                                            })}
                                        />
                                    </div>
                                </div>
                            )}
                            {!isEng && (
                                <div className={classes.item}>
                                    <label className={classes.descr}>
                                        {
                                            customerMyLicense.licenseDetails
                                                .licenseCostRus
                                        }
                                    </label>
                                    <div className={classes.fieldSize}>
                                        <PriceField
                                            type={'RUB'}
                                            register={register('price', {
                                                required: true,
                                            })}
                                            defaultValue={
                                                beatLicense?.price || 0
                                            }
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className={classes.options}>
                            <div className={classes.option}>
                                <div className={classes.item}>
                                    <MyCheckbox
                                        name={'removeBeatFromSale'}
                                        control={control}
                                        defaultChecked={
                                            beatLicense
                                                ? beatLicense[
                                                      'removeBeatFromSale'
                                                  ]
                                                : false
                                        }
                                        label={licenses.form.removeBeatFromSale}
                                    />
                                </div>
                                <p className={classes.descr}>
                                    {
                                        licenses.helpTextForCheckbox
                                            .removeBeatFromSale
                                    }
                                </p>
                            </div>
                            <div className={classes.option}>
                                <div className={classes.item}>
                                    <MyCheckbox
                                        name={'recommended'}
                                        control={control}
                                        defaultChecked={
                                            beatLicense
                                                ? beatLicense['recommended']
                                                : false
                                        }
                                        label={licenses.form.recommended}
                                    />
                                </div>
                                <p className={classes.descr}>
                                    {licenses.helpTextForCheckbox.recommended}
                                </p>
                            </div>
                            <div className={classes.option}>
                                <div className={classes.item}>
                                    <MyCheckbox
                                        name={'requireClientPassport'}
                                        control={control}
                                        defaultChecked={
                                            beatLicense
                                                ? beatLicense[
                                                      'requireClientPassport'
                                                  ]
                                                : false
                                        }
                                        label={
                                            licenses.form.requireClientPassport
                                        }
                                    />
                                </div>
                                <p className={classes.descr}>
                                    {
                                        licenses.helpTextForCheckbox
                                            .requireClientPassport
                                    }
                                </p>
                            </div>
                        </div>
                        {id && (
                            <div className={classes.list}>
                                <div className={classes.listEng}>
                                    <span className={classes.subtitle}>
                                        {
                                            customerMyLicense.licenseDetails
                                                .licenseOptions
                                        }
                                        :
                                    </span>
                                    {!isEng && <LicenseOptions isEng={false} />}
                                    <LicenseOptions isEng={true} />
                                    <button
                                        type={'button'}
                                        className={classes.newOptionBtn}
                                        onClick={handleCreateOption}
                                    >
                                        + {buttons.ADD_OPTIONS}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                    {/*<div className={classes.btnBox}>*/}
                    {/*    <NavLink*/}
                    {/*        to={CustomerRM.contracts.path.license.base}*/}
                    {/*        style={{ textDecoration: 'none' }}*/}
                    {/*    >*/}
                    {/*        <Button*/}
                    {/*            view={'Secondary'}*/}
                    {/*            title={buttons.CANCEL}*/}
                    {/*            type={'button'}*/}
                    {/*        />*/}
                    {/*    </NavLink>*/}
                    {/*    <Button*/}
                    {/*        view={'Primary'}*/}
                    {/*        title={buttons.SAVE_LICENSES}*/}
                    {/*        type={'submit'}*/}
                    {/*    />*/}
                    {/*</div>*/}
                </>
            )}
        </div>
    )
}
