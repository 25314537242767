import React, { FC } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import { useTranslation } from 'react-i18next'
import { EmailTemplateType } from '../../../BLL/types'
import { AppSettings } from 'settings/appSettings'

interface IProps {
    handleEditorChange: (template: EmailTemplateType) => void
    templateState: EmailTemplateType
    handleCloseHtmlTemplate: () => void
}

const ClosePopupDialog: FC<IProps> = ({
    handleEditorChange,
    templateState,
    handleCloseHtmlTemplate,
}) => {
    const [open, setOpen] = React.useState<boolean>(false)

    const { i18n } = useTranslation()
    const { adminEditTemplate, buttons } = i18n.getResourceBundle(
        i18n.language,
        'common'
    )

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const saveTemplate = () => {
        handleEditorChange(templateState)
        handleCloseHtmlTemplate()
        handleClose()
    }

    const notSaveTemplate = () => {
        handleCloseHtmlTemplate()
        handleClose()
    }

    return (
        <div>
            <Button
                variant="contained"
                onClick={handleClickOpen}
                style={{
                    background: AppSettings.api.getBaseColor(),
                    fontWeight: 600,
                    marginBottom: 20,
                }}
            >
                {buttons.BACK_TO_TEMPLATES}
            </Button>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {adminEditTemplate.closePopUpMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        onClick={saveTemplate}
                        style={{
                            background: AppSettings.api.getBaseColor(),
                            fontWeight: 600,
                        }}
                    >
                        {buttons.YES}
                    </Button>
                    <Button onClick={notSaveTemplate} autoFocus>
                        {buttons.NO}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ClosePopupDialog
