import React, { useState } from 'react'
import { DatePicker } from './'
import { useTranslation } from 'react-i18next'
import { BeatType, PromoType, StatsType } from 'BLL/types'
import { actions as appActions, RequestStatuses } from '../../../BLL/AppReducer'
import { useRedirectCustomHook } from 'UI/common/hooks/useRedirectCustomHook'
import { RolesTypeEnum } from 'BLL/enums'
import Header from '../../common/header/Header'
import * as dateFns from 'date-fns'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'BLL/Store'
import { useQuery } from 'react-query'
import { promoAPI } from 'DAL/PromoAPI'
import Table from '../../common/table/Table'
import { Paper } from '@material-ui/core'
import { LanguageType } from 'translations/ru/common'
import { getAdminPromoTableData } from 'UI/common/table/tableData/GetAdminPromoTableData'
import s from './AdminBeatsPromotion.module.css'

export type AdminPromoType = {
    beatId: number
    beatTitle: string
    promoType: number
    shows: number
    addedDate: string
    userId: number
    date: string
    _id: string
    amountCoins: number
    beat: BeatType
    historyBeats: BeatType[]
    stats: StatsType
}
export const AdminBeatsPromotion = () => {
    const { i18n } = useTranslation()
    const { promotionsType, adminBeatsPromotionTable, navigation } =
        i18n.getResourceBundle(i18n.language, 'common') as LanguageType
    const [selectedDate, setSelectDate] = useState<string>(
        dateFns.format(new Date(), 'yyyy-MM-dd')
    )

    const dispatch = useDispatch()
    const requestStatus = useSelector(
        (state: AppState) => state.appReducer.requestStatus
    )

    const { data } = useQuery(['beats-promo', selectedDate], async () => {
        dispatch(appActions.setRequestStatus(RequestStatuses.InProgress))
        const response = await promoAPI.getPromotionsAll(selectedDate)
        dispatch(appActions.setRequestStatus(RequestStatuses.Success))
        return response.data.map((p: PromoType) => ({
            beatId: p.beat.beatId,
            beatTitle: p.beat.beatTitle,
            promoType: p.promoType,
            shows: p.stats.shows,
            addedDate: p.addedDate,
            userId: p.userId,
            date: p.date,
            _id: p._id,
            amountCoins: p.amountCoins,
            beat: p.beat,
            historyBeats: p.historyBeats,
            stats: p.stats,
        })) as AdminPromoType[]
    })

    const handleSelectDate = (date: string) => {
        setSelectDate(dateFns.format(new Date(date), 'yyyy-MM-dd'))
    }

    return (
        <div className={s.wrapper}>
            {useRedirectCustomHook(RolesTypeEnum.PROMO_VIEWER)}
            <Header title={navigation.promoStats} />
            <Paper className={s.Paper}>
                <div className={s.datePicker}>
                    <div>
                        <DatePicker
                            handleSelectDate={handleSelectDate}
                            title={adminBeatsPromotionTable.promoDate}
                            date={selectedDate}
                        />
                    </div>
                </div>
                {requestStatus !== RequestStatuses.InProgress && data && (
                    <div>
                        <div className={s.Table}>
                            <Table<AdminPromoType>
                                items={data.filter((p) => p.promoType === 0)}
                                fields={getAdminPromoTableData(i18n)}
                                title={promotionsType[0]}
                            />
                        </div>
                        <div className={s.Table}>
                            <Table<AdminPromoType>
                                items={data.filter((p) => p.promoType === 1)}
                                fields={getAdminPromoTableData(i18n)}
                                title={promotionsType[1]}
                            />
                        </div>
                        <div className={s.Table}>
                            <Table<AdminPromoType>
                                items={data.filter((p) => p.promoType === 2)}
                                fields={getAdminPromoTableData(i18n)}
                                title={promotionsType[2]}
                            />
                        </div>
                        <div className={s.Table}>
                            <Table<AdminPromoType>
                                items={data.filter((p) => p.promoType === 3)}
                                fields={getAdminPromoTableData(i18n)}
                                title={promotionsType[3]}
                            />
                        </div>
                        <div className={s.Table}>
                            <Table<AdminPromoType>
                                items={data.filter((p) => p.promoType === 4)}
                                fields={getAdminPromoTableData(i18n)}
                                title={promotionsType[4]}
                            />
                        </div>
                    </div>
                )}
            </Paper>
        </div>
    )
}
