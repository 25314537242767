import React, { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BeatLicenseType } from '../../../../DAL/Services'
import { LanguageType } from '../../../../translations/ru/common'
import { LicenseItem } from '../License/license-item/license-item'
import classes from './contract.module.scss'
import { NavLink, Route, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
    deleteLicense,
    getSettingsContract,
    getUserLicenses,
    updateOrderForLicenses,
} from '../../../../BLL/ContractsReducer'
import { AppState } from '../../../../BLL/Store'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { CustomerRM } from '../../../routers/RouterManager'
import { Button } from '../../../common/elements/buttons/Button'
import { Modal } from '../../../common/modal/modal'
import { AppSettings } from 'settings/appSettings'
import { MobileView, BrowserView, deviceDetect } from 'react-device-detect'
import { CustomBrowserView } from '../../../common/browserView/CustomBrowserView'
import { CustomMobileView } from '../../../common/mobileView/CustomMobileView'

interface IProps {}

const Contracts: FC<IProps> = React.memo(() => {
    const dispatch = useDispatch()
    const { i18n } = useTranslation()
    const history = useHistory()
    const [isDrag, setIsDrag] = useState(false)
    const [modalIsActive, setModalIsActive] = useState(false)
    const { license, buttons, navigation, mySales } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType
    const beatLicenses = useSelector(
        (state: AppState) => state.beatmakerContracts.beatLicenses
    )

    useEffect(() => {
        dispatch(getUserLicenses())
        dispatch(getSettingsContract())
    }, [])

    const handleDragStop = (result: any) => {
        const licenses = reorder(
            beatLicenses,
            result.source.index,
            result.destination.index
        )
        const licenseIds = licenses.map((license) => license._id)
        dispatch(updateOrderForLicenses(licenseIds))
        setIsDrag(false)
    }

    const handleDragStart = () => {
        setIsDrag(true)
    }

    const reorder = (
        beatLicenses: BeatLicenseType[],
        startIndex: number,
        endIndex: number
    ): BeatLicenseType[] => {
        const [removed] = beatLicenses.splice(startIndex, 1)
        beatLicenses.splice(endIndex, 0, removed)
        return beatLicenses
    }

    const handleDeleteLicense = useCallback(
        (licenseId: string) => {
            dispatch(deleteLicense(licenseId))
        },
        [dispatch, deleteLicense]
    )
    const isEng = AppSettings.api.isEngVersion()
    return (
        <div>
            <CustomBrowserView>
                <div className={classes.topWrapper}>
                    <div className={classes.pageTitle}>
                        {navigation.contracts}
                    </div>
                    <div className={classes.searchWrapper}>
                        <NavLink
                            to={CustomerRM.contracts.getUrl.license.create()}
                            style={{ textDecoration: 'none' }}
                        >
                            <Button title={buttons.ADD} />
                        </NavLink>
                    </div>
                </div>
            </CustomBrowserView>
            <CustomMobileView>
                <div className={classes.boxBtn}>
                    <NavLink
                        to={CustomerRM.contracts.getUrl.license.create()}
                        style={{ textDecoration: 'none' }}
                    >
                        <Button view={'Primary'} title={buttons.ADD_LICENSE} />
                    </NavLink>
                </div>
            </CustomMobileView>
            <DragDropContext
                onDragEnd={handleDragStop}
                onDragStart={handleDragStart}
            >
                <CustomBrowserView>
                    <div className={classes.beatsHeader}>
                        <div className={classes.name}>
                            <span>{license.tableColumns.title}</span>
                        </div>

                        <div className={classes.beatCost}>
                            <span>{license.tableColumns.price}</span>
                        </div>
                        <div className={classes.date}>
                            <span>{license.tableColumns.createDate}</span>
                        </div>
                        <div className={classes.action}>
                            <span>{license.tableColumns.action}</span>
                        </div>
                    </div>
                </CustomBrowserView>
                <Droppable droppableId="droppable" type="COLUMN">
                    {(provided, snapshot) => (
                        <div
                            className={classes.wrapperDrag}
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {beatLicenses.map((license, index) => (
                                <Draggable
                                    draggableId={license._id}
                                    index={index}
                                    key={license._id}
                                >
                                    {(provided, snapshot) => (
                                        <div
                                            key={license._id}
                                            ref={provided.innerRef}
                                            style={getStyle({
                                                draggableStyle:
                                                    provided.draggableProps
                                                        .style,
                                                isDragging: snapshot.isDragging,
                                            })}
                                        >
                                            <div
                                                style={{
                                                    marginBottom: 2,
                                                    marginTop: 2,
                                                }}
                                            >
                                                <LicenseItem
                                                    license={license}
                                                    provided={provided}
                                                    isDrag={isDrag}
                                                    isDragging={
                                                        snapshot.isDragging
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <Route
                exact
                path={CustomerRM.contracts.path.license.delete}
                render={({ match }) => (
                    <Modal
                        active={true}
                        setActive={setModalIsActive}
                        title={license.deletePopUp.title}
                        cancelRedirect={() =>
                            history.push(CustomerRM.contracts.path.license.base)
                        }
                    >
                        <div>
                            <p>
                                {license.deletePopUp.desc}{' '}
                                <strong>
                                    {
                                        beatLicenses.find(
                                            (l) =>
                                                l.id ===
                                                match.params['licenseId']
                                        )?.[isEng ? 'titleEn' : 'title']
                                    }
                                    ?
                                </strong>
                            </p>
                            <div className={classes.buttonsPopUp}>
                                <Button
                                    view={'Secondary'}
                                    title={buttons.CANCEL}
                                    onClick={() =>
                                        history.push(
                                            CustomerRM.contracts.path.license
                                                .base
                                        )
                                    }
                                />
                                <Button
                                    view={'Delete'}
                                    title={buttons.DELETE}
                                    onClick={() =>
                                        handleDeleteLicense(
                                            match.params['licenseId']
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </Modal>
                )}
            />
        </div>
    )
})

export default Contracts

function getStyle({ draggableStyle, isDragging }: any) {
    return {
        ...draggableStyle,
        maxWidth: isDragging && '100%',
        maxHeight: isDragging && '65px',
        opacity: isDragging && 0.6,
    }
}
