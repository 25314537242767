import { PromoCoreType, PromoType } from 'BLL/types'
import { LanguageType } from 'translations/ru/common'
import { getLocaleDateString } from 'UI/utils/util-types'
import { NavLink } from 'react-router-dom'
import { CustomerRM } from 'UI/routers/RouterManager'
import Tooltip from '@material-ui/core/Tooltip'
import EditIcon from 'UI/assets/icons/edit.svg'
import SuccessIcon from 'UI/assets/icons/success-icon.svg'
import React from 'react'
import * as dateFns from 'date-fns'
import { i18n } from 'i18next'
import { TableType } from '../types'

export const getPromoTableData = (i18n: i18n): TableType<PromoType> => {
    const { customerBeatsPromotion, promotionsType } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType
    const dateNow = getLocaleDateString(i18n.language, new Date())
    return [
        {
            title: customerBeatsPromotion.tableColumns.promoType,
            field: 'promoType',
            render: (promotion) => (
                <div>
                    {promotionsType[promotion.promoType as keyof PromoCoreType]}
                </div>
            ),
        },
        {
            title: customerBeatsPromotion.tableColumns.beat,
            field: '_id',
            render: (promotion) => (
                <div style={{ fontWeight: 700, textDecoration: 'underline' }}>
                    {promotion.beat.beatTitle}
                </div>
            ),
        },
        {
            title: customerBeatsPromotion.tableColumns.reservedDate,
            field: 'addedDate',
            render: (promotion) => (
                <div>
                    {promotion.addedDate &&
                        getLocaleDateString(i18n.language, promotion.addedDate)}
                </div>
            ),
        },
        {
            title: customerBeatsPromotion.tableColumns.viewedDate,
            field: 'date',
            render: (promotion: PromoType) => (
                <div>
                    {promotion.date &&
                        getLocaleDateString(i18n.language, promotion.date)}
                </div>
            ),
        },

        {
            title: customerBeatsPromotion.tableColumns.management,
            field: 'date',
            render: (promotion: PromoType) => {
                const promoDate = dateFns.format(
                    new Date(promotion.date),
                    'yyyy-MM-dd'
                )
                const newDate = dateFns.format(new Date(), 'yyyy-MM-dd')

                return (
                    <div style={{ textAlign: 'right' }}>
                        {new Date(promoDate).getTime() >=
                            new Date(newDate).getTime() &&
                        promotion.promoType !== 3 ? (
                            <NavLink
                                to={CustomerRM.promotions.getUrl.edit(
                                    promotion._id
                                )}
                            >
                                <Tooltip title={customerBeatsPromotion.edit}>
                                    <img src={EditIcon} alt={'edit'} />
                                </Tooltip>
                            </NavLink>
                        ) : (
                            <div>
                                <img src={SuccessIcon} alt={'success'} />
                            </div>
                        )}
                    </div>
                )
            },
            sorting: false,
            headerStyle: {
                textAlign: 'right',
            },
        },
    ]
}
