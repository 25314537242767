import React, { FC, useEffect } from 'react'
import classes from 'UI/CustomerAdmin/CustomerDiscounts/discountEdit/discountEdit.module.scss'
import { InputField } from '../../../common/elements/textFields/Input'
import { CustomSelect } from '../../../common/elements/selects/CustomSelect'
import { useForm } from 'react-hook-form'
import { Button } from '../../../common/elements/buttons/Button'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../../../BLL/Store'
import { AppSettings } from 'settings/appSettings'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { LanguageType } from '../../../../translations/ru/common'
import { CustomerRM } from '../../../routers/RouterManager'
import { getUserLicenses } from '../../../../BLL/ContractsReducer'
import { CreateDiscountGroupDTO } from '../../../../DAL/DiscountAPI'
import { createBulkDiscount } from '../../../../BLL/CustomerDiscountsReducer'
import { BeatLicenseType } from '../../../../DAL/Services'
import { RequestStatuses } from '../../../../BLL/AppReducer'

type PropsType = {}
type FormValues = {
    beatmakerId: number
    buyedLicenseCount: number
    bonusLicenseCount: number
    license: string
}
export const DiscountEdit: FC<PropsType> = () => {
    const {
        register,
        setValue,
        formState: { errors },
        handleSubmit,
        control,
    } = useForm<FormValues>()
    const beatLicenses = useSelector(
        (state: AppState) => state.beatmakerContracts.beatLicenses
    )
    const userId = useSelector((state: AppState) => state.appReducer.userId)
    const requestStatus = useSelector(
        (state: AppState) => state.appReducer.requestStatus
    )
    const { i18n } = useTranslation()
    const history = useHistory()
    const { buttons, discounts } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType
    const isEng = AppSettings.api.isEngVersion()
    const dispatch = useDispatch()
    const handleClose = () => {
        history.push(CustomerRM.discounts.path.getUrl('group-discounts'))
    }

    const onSubmit = handleSubmit((values) => {
        if (userId) {
            const dto: CreateDiscountGroupDTO = {
                beatmakerId: userId,
                bonusLicenseCount: values.bonusLicenseCount,
                buyedLicenseCount: values.buyedLicenseCount,
                bonusLicenseId: values.license,
                buyedLicenseId: values.license,
            }
            dispatch(createBulkDiscount(dto))
        }
    })
    useEffect(() => {
        dispatch(getUserLicenses())
    }, [])
    return (
        <div>
            <form onSubmit={onSubmit}>
                <div className={classes.inputItems}>
                    <div className={classes.inputItem}>
                        <label className={classes.titleItem}>
                            {discounts.groupDiscounts.popUp.countCart}
                        </label>
                        <div className={classes.inputSize}>
                            <InputField
                                register={register('buyedLicenseCount', {
                                    required: true,
                                })}
                            />
                        </div>
                    </div>
                    <div className={classes.inputItem}>
                        <label className={classes.titleItem}>
                            {discounts.groupDiscounts.popUp.countFree}
                        </label>
                        <div className={classes.inputSize}>
                            <InputField
                                register={register('bonusLicenseCount', {
                                    required: true,
                                })}
                            />
                        </div>
                    </div>
                </div>
                <div className={classes.selectItem}>
                    <label className={classes.titleItem}>
                        {discounts.groupDiscounts.popUp.license}
                    </label>
                    <CustomSelect
                        // control={control}
                        register={register('license')}
                        // setValue={setValue}
                        items={beatLicenses.map((lic) => ({
                            value: lic.id,
                            title:
                                isEng && lic.titleEn ? lic.titleEn : lic.title,
                        }))}
                        name={'license'}
                        // noMobile={true}
                    />
                </div>
                <div className={classes.btnsBox}>
                    <Button
                        view={'Secondary'}
                        title={buttons.CANCEL}
                        type={'button'}
                        onClick={handleClose}
                    />
                    <Button
                        title={buttons.SAVE}
                        type={'submit'}
                        disabled={requestStatus === RequestStatuses.InProgress}
                    />
                </div>
            </form>
        </div>
    )
}
