import { createSelector } from 'reselect'
import { publicationsAPI } from 'DAL/PublicationsAPI'
import { shopAPI } from 'DAL/Services'
import { PublicationType, ResultCodesEnum, ThunkActionType } from './types'
import { StatusPublicationEnum } from './enums'
import { AppState, InferActionsTypes } from './Store'
import {
    actions as appActions,
    actions as authActions,
    AuthActionsTypes,
    EditingEntityStatuses,
} from './AppReducer'
import { commonAsyncHandler, completeOperation } from './common-async-handler'

class TimeInterval {
    constructor(public hours: number, public mins: number) {}

    getString() {
        let hoursString = this.hours < 10 ? '0' + this.hours : this.hours
        let minutesString = this.mins < 10 ? '0' + this.mins : this.mins
        return `${hoursString}:${minutesString}`
    }
}

export const TIME_INTERVALS = [...Array(24)].reduce(
    (acc = [], element, index) => {
        acc.push(new TimeInterval(index, 0))
        acc.push(new TimeInterval(index, 30))
        return acc
    },
    []
)

export interface ITimeIntervals {
    hours: number
    mins: number
    getString: () => string
}

const initState = {
    publications: [] as PublicationType[],
    statusMessage: '',
}

type IState = typeof initState
export type ActionsTypes = InferActionsTypes<typeof actions> | AuthActionsTypes

const customerBeatPublicationsReducer = (
    state = initState,
    action: ActionsTypes
): IState => {
    switch (action.type) {
        case 'PUBLICATION_BEATS/SET_NEW_PUBLICATION':
            return {
                ...state,
                publications: state.publications.map((b) => {
                    if (b.beat.beatId === action.publication.beat.beatId) {
                        return {
                            ...b,
                            _id: action.publication._id,
                            date: action.publication.date,
                            status: action.publication.status,
                        }
                    } else return b
                }),
            }
        case 'PUBLICATION_BEATS/SET_PUBLICATION':
            return {
                ...state,
                publications: state.publications.map((publication) => {
                    if (
                        publication.beat.beatId ===
                        action.publication.beat.beatId
                    ) {
                        return {
                            ...publication,
                            _id: action.publication._id,
                            date: action.publication.date,
                            message: action.publication.message,
                            status: action.publication.status,
                        }
                    } else {
                        return publication
                    }
                }),
            }
        case 'PUBLICATION_BEATS/SET_PUBLICATIONS':
            return {
                ...state,
                publications: action.beats,
            }
        case 'PUBLICATION_BEATS/SET_DELETE_PUBLICATION':
            return {
                ...state,
                publications: state.publications.map((publication) => {
                    if (publication._id === action.id) {
                        delete publication._id
                        return {
                            ...publication,
                            date: '',
                            status: StatusPublicationEnum.NotPublished,
                        }
                    } else {
                        return publication
                    }
                }),
            }
        default:
            return state
    }
}

export default customerBeatPublicationsReducer

export const actions = {
    addPublication: (publication: PublicationType) =>
        ({
            type: 'PUBLICATION_BEATS/SET_NEW_PUBLICATION',
            publication,
        } as const),
    setPublication: (publication: PublicationType) =>
        ({
            type: 'PUBLICATION_BEATS/SET_PUBLICATION',
            publication,
        } as const),
    setDeletePublication: (id: string) =>
        ({ type: 'PUBLICATION_BEATS/SET_DELETE_PUBLICATION', id } as const),
    setPublications: (beats: PublicationType[]) =>
        ({ type: 'PUBLICATION_BEATS/SET_PUBLICATIONS', beats } as const),
}

export const setBeatPublication =
    (publication: PublicationType): ThunkActionType<ActionsTypes> =>
    async (dispatch) => {
        await commonAsyncHandler(async () => {
            const response = await publicationsAPI.postPublication(publication)
            completeOperation(response, dispatch, () => {
                dispatch(
                    authActions.setEditingEntityStatus(
                        EditingEntityStatuses.Success
                    )
                )
                dispatch(getBeatsPublications())
            })
            return response
        }, dispatch)
    }

export const getBeatsPublications =
    (): ThunkActionType<ActionsTypes> => async (dispatch) => {
        await commonAsyncHandler(async () => {
            // request all unpublished beats (w\o paging) for merging with publications
            const unpublishedBeats = await shopAPI.getBeatsNotPublished()
            const publications = unpublishedBeats.map((beat) => ({
                beat: beat,
                date: '',
                status: StatusPublicationEnum.NotPublished,
                message: '',
            }))
            dispatch(actions.setPublications(publications))

            const publicationsResponse = await publicationsAPI.getPublications()
            if (publicationsResponse.resultCode === ResultCodesEnum.Success) {
                for (let i = 0; i < publicationsResponse.data.length; i++) {
                    const unpublishedBeat = unpublishedBeats.find(
                        (beat) =>
                            beat.beatId ===
                            publicationsResponse.data[i].beat.beatId
                    )
                    if (unpublishedBeat) {
                        dispatch(
                            actions.setPublication(publicationsResponse.data[i])
                        )
                    } else {
                        await publicationsAPI.deletePublication(
                            publicationsResponse.data[i]._id
                        )
                    }
                }
            } else {
                dispatch(
                    authActions.showSnackbar(
                        'error',
                        publicationsResponse.message
                    )
                )
            }
            return publicationsResponse
        }, dispatch)
    }

export const deleteBeatPublication =
    (id: string): ThunkActionType<ActionsTypes> =>
    async (dispatch) => {
        await commonAsyncHandler(async () => {
            const response = await publicationsAPI.deletePublication(id)
            completeOperation(response, dispatch, () => {
                dispatch(actions.setDeletePublication(id))
                dispatch(
                    appActions.setEditingEntityStatus(
                        EditingEntityStatuses.Success
                    )
                )
            })
            return response
        }, dispatch)
    }

//SELECTORS
const getPublicationsSel = (state: AppState): PublicationType[] =>
    state.customerBeatPublications.publications

export const getAllPublications = createSelector(
    [getPublicationsSel],
    (publications) => {
        return publications.sort((a, b) => {
            const dateA = new Date(a.date)
            const dateB = new Date(b.date)
            return dateA > dateB ? -1 : dateA < dateB ? 1 : 0
        })
    }
)
