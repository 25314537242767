import { useSelector } from 'UI/common/hooks/redux-hooks'
import {
    selectArchiveBeats,
    selectPagesCount,
    selectTotalCount,
} from 'BLL/selectors/archiveBeats-selectors'
import { selectRequestStatus } from 'BLL/selectors/app-selectors'

export const useArchiveBeatsData = () => {
    const beats = useSelector(selectArchiveBeats)
    const pagesCount = useSelector(selectPagesCount)
    const totalCount = useSelector(selectTotalCount)
    const requestStatus = useSelector(selectRequestStatus)

    return {
        beats,
        pagesCount,
        totalCount,
        requestStatus,
    }
}
