import { EmailsHistoryType, SubscriptionType } from 'BLL/types'
import { formatDate } from 'BLL/SupportingFile'
import s from 'UI/ManagerAdmin/AdminSubscriptions/email-detail.module.css'
import successImg from 'UI/assets/image/success.png'
import React from 'react'
import { TableType } from '../types'
import { i18n } from 'i18next'

export const getEmailHistoryTableData = (
    i18n: i18n,
    subscription: SubscriptionType
): TableType<EmailsHistoryType> => {
    const { adminSubscription } = i18n.getResourceBundle(
        i18n.language,
        'common'
    )
    return [
        {
            title: adminSubscription.tableColumns.sendingDate,
            field: 'sendingDate',
            render: (emailHistory: EmailsHistoryType) => (
                <div>{formatDate(emailHistory?.sendingDate)}</div>
            ),
        },
        {
            title: adminSubscription.tableColumns.message,
            field: 'email',
            render: () => (
                <div className={s.message}>
                    {subscription.emailData.lastSendingMessage}
                </div>
            ),
        },
        {
            title: adminSubscription.tableColumns.isViewed,
            field: 'isViewed',
            render: (emailHistory: EmailsHistoryType) =>
                emailHistory.isViewed ? (
                    <img style={{ width: 25 }} src={successImg} alt={''} />
                ) : (
                    <div />
                ),
        },
        {
            title: adminSubscription.tableColumns.isRedirect,
            field: 'isRedirect',
            render: (emailHistory: EmailsHistoryType) =>
                emailHistory.isRedirect ? (
                    <img style={{ width: 25 }} src={successImg} alt={''} />
                ) : (
                    <div />
                ),
        },
    ]
}
