import React, { FC } from 'react'
import classes from './MobFollowers.module.scss'
import { Button } from '../../../common/elements/buttons/Button'
import { SubItem } from './SubItem/SubItem'
import {
    CustomSelect,
    OptionsType,
} from '../../../common/elements/CustomSelect'
import { ISubscribers } from '../../../../DAL/SubscribersAPI'
import { useTranslation } from 'react-i18next'
import { LanguageType } from '../../../../translations/ru/common'
import { CustomDatePicker } from '../../../common/elements/datePicker/CustomDatePicker'
import { getLocaleDateString } from '../../../utils/util-types'

type PropsType = {
    subscribers: ISubscribers[]
    handleDeleteSubscribe: (id: string) => void
    from: Date | null
    setFrom: (date: Date | null) => void
    to: Date | null
    setTo: (date: Date | null) => void
    handleDownloadBuyersList: () => void
    options: OptionsType[]
    register?: any
    control?: any
}
export const MobFollowers: FC<PropsType> = ({
    subscribers,
    from,
    setFrom,
    to,
    setTo,
    handleDownloadBuyersList,
    control,
    options,
    register,
}) => {
    const { i18n } = useTranslation()
    const { customerBeatSubscribers, sourceType } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType
    return (
        <div className={classes.MobFollowers}>
            <div className={classes.dateBox}>
                <div className={classes.date}>
                    <span className={classes.text}>
                        {customerBeatSubscribers.period.start}
                    </span>
                    <CustomDatePicker
                        date={from || new Date()}
                        disabled={false}
                        handleChange={setFrom}
                        locale={i18n.language as 'ru' | 'en'}
                    />
                </div>
                <div className={classes.date}>
                    <span className={classes.text}>
                        {customerBeatSubscribers.period.end}
                    </span>
                    <CustomDatePicker
                        date={to || new Date()}
                        disabled={false}
                        handleChange={setTo}
                        locale={i18n.language as 'ru' | 'en'}
                    />
                </div>
            </div>
            <div className={classes.choose}>
                <CustomSelect
                    control={control}
                    register={register}
                    options={options}
                    name={'sourceType'}
                    placeholder={''}
                    value={''}
                    setValue={() => {}}
                    disabled={false}
                />
            </div>
            <div className={classes.btnBox}>
                <Button
                    title={'Download Email Followers'}
                    view={'Primary'}
                    onClick={handleDownloadBuyersList}
                />
            </div>
            <div className={classes.subsList}>
                {subscribers.map((subscribe) => {
                    return (
                        <SubItem
                            key={subscribe._id}
                            subsDate={getLocaleDateString(
                                i18n.language,
                                subscribe.subscribedAt
                            )}
                            nickname={subscribe.subscriber.name}
                            email={subscribe.subscriber.email}
                            subsPage={sourceType[+subscribe.sourceType]}
                        />
                    )
                })}
            </div>
        </div>
    )
}
