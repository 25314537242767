import { BeatDetailsType, UpdateBeatContract } from 'DAL/BeatSettingsAPI'
import { UpdateBeat } from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/beatSettings/BeatSettings'
import { BeatLicenseType } from 'DAL/Services'

export const submitDto = (
    values: UpdateBeat,
    beatId: string,
    currentBeat: BeatDetailsType,
    demoDownloading: boolean,
    tags: string[],
    uniqueBindingsLicense: BeatLicenseType[],
    beatLicenseNotDefaultPrice: BeatLicenseType[]
) => {
    const updateBeatContract: UpdateBeatContract = {
        name: values.name,
        description: values.description,
        id: +beatId,
        moodId: values.moodId.value,
        styleId: values.styleId.value,
        tempo: values.tempo,
        composing: currentBeat.beat.composing,
        demoDownloading,
        tagName: tags,
        licenses: uniqueBindingsLicense.map((lic) => {
            const isCustomPrice = beatLicenseNotDefaultPrice.find(
                (license) => license.id === lic.id
            )
            let license: {
                id: string
                customPrices: boolean
                priceInRubles?: number
                priceInDollars?: number
            } = {
                id: lic.id,
                customPrices: !!isCustomPrice,
            }
            if (lic.priceDollar && !!isCustomPrice && isCustomPrice.priceDollar)
                license.priceInDollars = isCustomPrice.priceDollar
            if (lic.price && !!isCustomPrice)
                license.priceInRubles = isCustomPrice.price

            return license
        }),
        sampling: currentBeat.beat.sampling,
    }

    return updateBeatContract
}
