import React, { FC } from 'react'
import classes from './inputs.module.scss'
import { UseFormRegisterReturn } from 'react-hook-form/dist/types/form'
import { FieldError } from 'react-hook-form'

type PropsType = {
    register?: UseFormRegisterReturn
    error?: FieldError | undefined
} & React.InputHTMLAttributes<HTMLInputElement>

export const InputField: FC<PropsType> = ({ register, error, ...props }) => {
    const className = error?.message
        ? `${classes.Input} ${classes.inputField} ${classes.error}`
        : `${classes.Input} ${classes.inputField}`
    return (
        <>
            <input className={className} {...register} {...props} />
            <span className={classes.errorMessage}>{error?.message}</span>
        </>
    )
}
