import React from 'react'
import s from '../../../walletSettings.module.scss'

type Props = {
    title?: string
    instruction?: string
}
export const TinkoffInstructionStep = ({ title, instruction }: Props) => {
    return (
        <div className={s.step}>
            <h3 className={s.stepTitle}>{title}:</h3>
            <span className={s.stepText}>{instruction}</span>
        </div>
    )
}
