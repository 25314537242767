import { UseFormRegisterReturn } from 'react-hook-form/dist/types/form'
import React from 'react'
import s from './inputs.module.scss'
import { FieldError } from 'react-hook-form'

type Props = {
    register?: UseFormRegisterReturn
    error?: FieldError | undefined
} & React.TextareaHTMLAttributes<HTMLTextAreaElement>

export const TextArea = ({ register, error, ...props }: Props) => {
    const className = error?.message
        ? `${s.Input} ${s.textAreaField} ${s.error}`
        : `${s.Input} ${s.textAreaField}`
    return (
        <>
            <textarea className={className} {...register} {...props} />
            <span className={s.errorMessage}>{error?.message}</span>
        </>
    )
}
