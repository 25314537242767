import React from 'react'
// import { BeatmakerSettings } from './MobBeatmakerSettings/BeatmakerSettings'
import { MobDiscountsPage } from './MobDiscountsPage/MobDiscountsPage'

export const Mobile = () => {
    return (
        <div>
            {/*<MobMyBeatsPage />*/}
            {/*<MobMySales />*/}
            {/*<MySalesInfo />*/}
            {/*<MobAdvertisingBeats />*/}
            {/*<MobAdvertisingOption />*/}
            {/*<MobLicenses />*/}
            {/*<AddNewLicense />*/}
            {/*<Contract />*/}
            {/*<BeatmakerSettings />*/}
            {/*<MobCashback />*/}
            {/*<MobFollowers />*/}
            {/*<MobCompilation />*/}
            {/*<MobCoinsPage />*/}
            {/*<MobMyShop />*/}
            {/*<MobCouponsPage />*/}
            {/*<MobProducerPage />*/}
            {/*<MobDiscountsPage />*/}
        </div>
    )
}
