import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import classes from './WidgetsListPage.module.scss'

import { useRedirectCustomHook } from '../../../common/hooks/useRedirectCustomHook'
import { RolesTypeEnum } from '../../../../BLL/enums'
import { LanguageType } from '../../../../translations/ru/common'
import { Button } from '../../../common/elements/buttons/Button'
import { CustomBrowserView } from '../../../common/browserView/CustomBrowserView'
import { useSelector } from '../../../common/hooks/redux-hooks'
import { WidgetType } from '../../../../DAL/WidgetsAPI'
import { getWidgetsTC } from '../../../../BLL/WidgetsReducer'
import { NavLink } from 'react-router-dom'
import { CustomerRM } from '../../../routers/RouterManager'
import { WidgetItem } from './WidgetItem/WidgetItem'

export const WidgetsListPage = React.memo(() => {
    const dispatch = useDispatch()

    const { i18n } = useTranslation()
    const { widget, buttons } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    const widgets = useSelector<WidgetType[]>((state) => state.widgets)

    useEffect(() => {
        dispatch(getWidgetsTC())
    }, [])

    return (
        <>
            {useRedirectCustomHook(RolesTypeEnum.BEATMAKER)}
            {/* header */}
            <CustomBrowserView>
                <div className="commonTopWrapper">
                    <div className="commonPageTitle">
                        {widget.mainPageTitle}
                    </div>

                    <div className="commonSearchWrapper">
                        <NavLink
                            to={CustomerRM.widgets.path.create}
                            style={{ textDecoration: 'none' }}
                        >
                            <Button title={buttons.ADD_WIDGET} />
                        </NavLink>
                    </div>
                </div>
            </CustomBrowserView>

            {/* List of widgets */}
            {widgets.length ? (
                <div>
                    <div className={classes.tableHeader}>{widget.name}</div>
                    {widgets.map((w) => (
                        <WidgetItem key={w._id} widget={w} widgets={widgets} />
                    ))}
                </div>
            ) : (
                <p>{widget.notFound}</p>
            )}
        </>
    )
})
