import React, { FC } from 'react'
import classes from './CouponItem.module.scss'
import { CouponStatus, CouponType } from '../../../../../BLL/shop-types'
import { Status, StatusType } from '../../../../common/status/Status'
import { getLocaleDateString } from '../../../../utils/util-types'
import { useTranslation } from 'react-i18next'
import { LanguageType } from '../../../../../translations/ru/common'
import Tooltip from '@material-ui/core/Tooltip'
import { NavLink } from 'react-router-dom'
import { CustomerRM } from '../../../../routers/RouterManager'
import { Button as MyButton } from '../../../../common/elements/buttons/Button'
import EditIcon from '../../../../assets/icons/edit.svg'
import DeleteIcon from '../../../../assets/icons/delete.svg'

type PropsType = {
    coupon: CouponType
    editType: 'coupons' | 'owner'
}

export const CouponItem: FC<PropsType> = ({ coupon, editType }) => {
    const { i18n } = useTranslation()
    const { buttons, shopCoupon } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType
    const status: { [key: number]: StatusType } = {
        0: 'NotActive',
        1: 'Waiting',
        2: 'Rejected',
        3: 'Clarification',
        4: 'Confirmed',
    }
    return (
        <div className={classes.CouponItem}>
            <div className={classes.item}>
                <span className={classes.descr}>
                    {shopCoupon.tableColumns.coupon}:
                </span>
                <span className={classes.value}>{coupon.code}</span>
            </div>
            <div className={classes.item}>
                <span className={classes.descr}>
                    {shopCoupon.tableColumns.status}:
                </span>
                <div className={classes.status}>
                    <Status status={status[coupon.status] as StatusType} />
                </div>
            </div>
            <div className={classes.item}>
                <span className={classes.descr}>
                    {shopCoupon.tableColumns.price}:
                </span>
                <span className={classes.value}>
                    {coupon.price ? coupon.price : '-'}
                </span>
            </div>
            <div className={classes.item}>
                <span className={classes.descr}>
                    {shopCoupon.tableColumns.addedDate}:
                </span>
                <span className={classes.value}>
                    {' '}
                    {getLocaleDateString(i18n.language, coupon.addedDate)}
                </span>
            </div>
            <div className={classes.item}>
                <span className={classes.descr}>
                    {shopCoupon.tableColumns.purchaseDate}:
                </span>
                <span className={classes.value}>
                    {' '}
                    {coupon.purchaseDate
                        ? getLocaleDateString(
                              i18n.language,
                              coupon.purchaseDate
                          )
                        : '-'}
                </span>
            </div>
            <div className={classes.btnBox}>
                {/*<button>*/}
                {/*    <img src={edit} alt="" />*/}
                {/*</button>*/}
                {/*<button>*/}
                {/*    <img src={deleteBtn} alt="" />*/}
                {/*</button>*/}
                {((coupon.status === CouponStatus.NotActive &&
                    editType === 'coupons') ||
                    (coupon.status === CouponStatus.SpecifyInformation &&
                        editType === 'coupons') ||
                    (coupon.status === CouponStatus.Approve &&
                        editType === 'coupons') ||
                    (coupon.status === CouponStatus.Pending &&
                        editType === 'owner')) && (
                    <Tooltip title={shopCoupon.edit}>
                        <NavLink
                            to={CustomerRM.shops[editType].edit.getUrl(
                                coupon.id
                            )}
                        >
                            {coupon.customerFeedback ? (
                                <MyButton
                                    view={'Details'}
                                    title={buttons.DETAILS}
                                />
                            ) : (
                                <img
                                    src={EditIcon}
                                    alt={'edit'}
                                    className={classes.icons}
                                />
                            )}
                        </NavLink>
                    </Tooltip>
                )}
                {editType === 'coupons' &&
                    coupon.status === CouponStatus.NotActive && (
                        <Tooltip title={shopCoupon.deletePopUpTitle}>
                            <NavLink
                                to={CustomerRM.shops.coupons.delete.getUrl(
                                    coupon.id
                                )}
                            >
                                <img
                                    src={DeleteIcon}
                                    alt={'delete'}
                                    className={classes.icons}
                                />
                            </NavLink>
                        </Tooltip>
                    )}
            </div>
        </div>
    )
}
