import React, { FC } from 'react'
import classes from './inputs.module.scss'
import { UseFormRegisterReturn } from 'react-hook-form/dist/types/form'
import { FieldError } from 'react-hook-form'

type PropsType = {
    type?: 'RUB' | 'USD'
    register?: UseFormRegisterReturn
    error?: FieldError | undefined
} & React.InputHTMLAttributes<HTMLInputElement>

export const PriceField: FC<PropsType> = ({
    type = 'USD',
    register,
    ...props
}) => {
    const className =
        type === 'USD'
            ? `${classes.Input} ${classes.priceDollar}`
            : `${classes.Input} ${classes.priceRub}`
    return <input className={className} {...register} {...props} />
}
