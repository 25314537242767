import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'
import { ModalValue } from 'UI/CustomerAdmin/BeatmakerSettingsNew/beatmaker-settings/types'
import { TabPanel } from 'UI/common/elements/tabPanel/TabPanel'
import { CustomTabs } from 'UI/common/elements/tabPanel/CustomTabs'
import { PaidBeats } from './PaidBeats'
import { Archive } from './Archive'

export const modalValues = {
    paidBeats: 0,
    archive: 1,
} as const

const tabPaths = {
    [modalValues.paidBeats]: '/my-beats/paid',
    [modalValues.archive]: '/my-beats/archive',
}

const params = {
    paid: 'paid',
    archive: 'archive',
}

export const MyBeatsPage = () => {
    const history = useHistory()
    let { tab } = useParams<{ tab: string }>()

    const { i18n } = useTranslation()
    const { settings } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    const [value, setValue] = useState<ModalValue>(modalValues.paidBeats)

    const settingsTabsLabel = [
        settings.tabs.paidBeats,
        settings.tabs.archivedBeats,
    ]

    useEffect(() => {
        if (!tab) {
            tab = params.paid
            history.push(tabPaths[modalValues.paidBeats])
        }

        tab === params.paid && setValue(modalValues.paidBeats)
        tab === params.archive && setValue(modalValues.archive)
    }, [tab])

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        history.push(tabPaths[newValue as keyof typeof tabPaths])
    }

    const children = (
        <CustomTabs
            value={value}
            tabs={Object.keys(modalValues)}
            labels={settingsTabsLabel}
            onChange={handleChange}
        />
    )

    return (
        <div>
            <TabPanel value={tab} index={'paid'}>
                <PaidBeats>{children}</PaidBeats>
            </TabPanel>
            <TabPanel value={tab} index={'archive'}>
                <Archive>{children}</Archive>
            </TabPanel>
        </div>
    )
}
