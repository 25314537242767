import React, { FC, useEffect, useState } from 'react'
import classes from './MySalesInformation.module.scss'
import usd from '../../../assets/icons/usd.svg'
import { NavLink } from 'react-router-dom'
import { CustomerRM } from '../../../routers/RouterManager'
import ArrowLeft from '../../../assets/icons/arrow-left.svg'
import { LanguageType } from '../../../../translations/ru/common'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { useSelector } from '../../../common/hooks/redux-hooks'
import { useRedirectCustomHook } from '../../../common/hooks/useRedirectCustomHook'
import { RolesTypeEnum } from '../../../../BLL/enums'
import { fetchItems } from '../../../../BLL/MySalesReducer'
import { useDispatch } from 'react-redux'
import {
    getLocaleDateFullString,
    paymentSystemToString,
} from '../../../utils/util-types'
import { AppSettings } from 'settings/appSettings'
import { deviceDetect } from 'react-device-detect'
import { MySalesInfo } from '../../Mobile/MobMySales/MySalesInfo/MySalesInfo'
import { CurrencyEnum } from '../../../../DAL/CashbackAPI'
import rub from '../../../assets/icons/rub.svg'
import { useDeviceDetect } from '../../../common/hooks/useDeviceDetect'
import { Button } from '../../../common/elements/buttons/Button'
import { ConfirmExchangeDialog } from '../confirm-exchange'

export const getLinkImage = (beatId: number) => {
    return `${AppSettings.urls.CORE_BASE_URL}Default/General/Image?id=${beatId}&type=BeatImageSmall`
}

export const getLinkContract = (
    beatId: number,
    paymentId: number,
    buyerId: number
) => {
    return `${AppSettings.urls.CORE_BASE_URL}BeatsManagement/BeatsSales/DownloadContract?beatId=${beatId}&paymentId=${paymentId}&buyerId=${buyerId}`
}

export const MySalesInformation = () => {
    const { id } = useParams<{ id: string }>()
    const { i18n } = useTranslation()
    const { mySales, buttons } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    const rootPayment = useSelector((state) =>
        state.mySales.rootPayments.find((p) => p.id === +id)
    )
    const dispatch = useDispatch()
    const auth = useRedirectCustomHook(RolesTypeEnum.BEATMAKER)
    useEffect(() => {
        dispatch(fetchItems())
    }, [])
    const [isConfirmExchange, setIsConfirmExchange] = useState(false)
    const isEng = AppSettings.api.isEngVersion()
    const isMobile = useDeviceDetect()
    const isUsd = rootPayment?.currencyName === CurrencyEnum.usd
    const currencyIcon = isUsd ? (
        <img src={usd} alt="" />
    ) : (
        <img src={rub} alt="" />
    )
    const statusRu = 'Средства выведены'
    return rootPayment ? (
        !isMobile ? (
            <div className={classes.wrapper}>
                <div className={classes.back}>
                    <NavLink to={CustomerRM.mySales.path.list}>
                        <img src={ArrowLeft} alt={'back-items'} />
                        <span>{buttons.BACK_TO_ITEMS}</span>
                    </NavLink>
                </div>
                <div className={classes.informationTop}>
                    <div className={classes.paidDate}>
                        <span className={classes.saleID}>
                            {mySales.saleDetails.saleId}: #{id}
                        </span>
                        <span className={classes.date}>
                            {mySales.saleDetails.paid}{' '}
                            {getLocaleDateFullString(
                                i18n.language,
                                rootPayment.confirmDate
                            )}
                        </span>
                    </div>
                    <div className={classes.statusContainer}>
                        <span className={classes.statusText}>
                            {mySales.saleDetails.status}:
                        </span>
                        {rootPayment.sales[0].paidDate ? (
                            <span className={classes.status}>
                                {isEng ? 'Funds Withdrawn' : statusRu}
                            </span>
                        ) : (
                            <span className={classes.status}>
                                <Button
                                    view={'Outline'}
                                    title={'Обменять на монеты'}
                                    onClick={() => setIsConfirmExchange(true)}
                                />
                            </span>
                        )}
                    </div>
                </div>
                <h3 className={classes.title}>
                    {mySales.saleDetails.salesInfo}
                </h3>
                <div className={classes.buyerInfo}>
                    <div className={classes.buyer}>
                        <span className={classes.buyerText}>
                            {mySales.saleDetails.buyer}:
                        </span>
                        <a
                            href={`${AppSettings.urls.MAIN_DOMAIN}user/${rootPayment.sales[0].buyerId}`}
                            target={'_blank'}
                            rel="noreferrer"
                        >
                            {rootPayment.sales[0].buyerName}
                        </a>
                    </div>
                    <div className={classes.email}>
                        <span className={classes.buyerEmail}>
                            {mySales.saleDetails.emailBuyer}:
                        </span>
                        <a href={`mailto:${rootPayment.sales[0].buyerEmail}`}>
                            {rootPayment.sales[0].buyerEmail}
                        </a>
                    </div>
                    <div className={classes.fullName}>
                        <span className={classes.buyerFullName}>
                            {mySales.saleDetails.fullName}:
                        </span>
                        <span>{rootPayment.sales[0].clientFullname}</span>
                    </div>
                </div>
                <div className={classes.informationBottom}>
                    <div className={classes.summary}>
                        <h4 className={classes.summaryTitle}>
                            {mySales.saleDetails.orderSummary}:
                        </h4>
                        <div className={classes.tableHeader}>
                            <span>{mySales.saleDetails.nameBeat}</span>
                            <span>{mySales.saleDetails.contract}</span>
                        </div>
                        {rootPayment.sales.map((item, index) => {
                            const contractExist = AppSettings.api.isEngVersion()
                                ? item.engPdfExists
                                : item.pdfExists
                            return (
                                <div
                                    key={item.id}
                                    className={classes.tableBeat}
                                >
                                    <div className={classes.beatInfo}>
                                        <img
                                            width={50}
                                            height={50}
                                            src={getLinkImage(item.beatId)}
                                            alt="beatImage"
                                        />
                                        <div className={classes.beatText}>
                                            <span className={classes.beatName}>
                                                {item.beatTitle}
                                            </span>
                                            <span
                                                className={classes.beatLicense}
                                            >
                                                {mySales.saleDetails.license}:{' '}
                                                <span>
                                                    {AppSettings.api.isEngVersion()
                                                        ? item.licenseTitleEng
                                                        : item.licenseTitle}
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                    {contractExist ? (
                                        <a
                                            className={classes.contractLink}
                                            href={getLinkContract(
                                                item.beatId,
                                                item.paymentId,
                                                item.buyerId
                                            )}
                                            target={'_blank'}
                                            rel="noreferrer"
                                        >
                                            {mySales.saleDetails.contract}
                                        </a>
                                    ) : (
                                        <span>-</span>
                                    )}
                                </div>
                            )
                        })}
                    </div>
                    <div className={classes.total}>
                        <div className={classes.points}>
                            <div className={classes.pointsItem}>
                                <span className={classes.itemText}>
                                    {mySales.saleDetails.totalGross}
                                </span>
                                <span className={classes.itemValue}>
                                    {rootPayment.money} {currencyIcon}
                                </span>
                            </div>
                            <div className={classes.pointsItem}>
                                <span className={classes.itemText}>
                                    {mySales.saleDetails.comissionSite}
                                </span>
                                <span className={classes.itemValue}>
                                    -{rootPayment.serviceFeeAmount}
                                    {currencyIcon}
                                </span>
                            </div>
                            <div className={classes.pointsItem}>
                                <span className={classes.itemText}>
                                    {mySales.saleDetails.cashback}
                                </span>
                                <span className={classes.itemValue}>
                                    -{rootPayment.cashbackAmount}
                                    {currencyIcon}
                                </span>
                            </div>
                            <div className={classes.pointsItem}>
                                <span className={classes.itemText}>
                                    {mySales.saleDetails.pay}
                                </span>
                                <span className={classes.itemValue}>
                                    {paymentSystemToString(
                                        rootPayment.paymentSystem
                                    )}
                                </span>
                            </div>
                        </div>
                        <div className={classes.totalPrice}>
                            <span className={classes.totalPriceText}>
                                {mySales.saleDetails.total}
                            </span>
                            <span className={classes.totalPriceValue}>
                                {currencyIcon}
                                {rootPayment.beatmakerPureMoney}
                            </span>
                        </div>
                    </div>
                </div>
                <ConfirmExchangeDialog
                    open={isConfirmExchange}
                    onClose={setIsConfirmExchange}
                />
            </div>
        ) : (
            <MySalesInfo rootPayment={rootPayment} />
        )
    ) : (
        <div />
    )
}
