import { PaymentSystemTypes } from '../../DAL/paymentsAPI'
import { actions as appActions } from '../../BLL/AppReducer'
import { CONSTANTS } from '../../BLL/SupportingFile'
import { Dispatch } from 'redux'
import enLocale from 'date-fns/locale/en-US'
import ruLocale from 'date-fns/locale/ru'
import * as dateFns from 'date-fns'

export type Nullable<T> = T | null

type StandardEnum<T> = {
    [id: string]: T | string
    [nu: number]: string
}

export const GetEnumAsKeyValueObject = <E extends StandardEnum<number>>(
    type: E,
    value: E
): { title: E[keyof E]; value: string | number }[] => {
    const items = Object.values(type)
        .filter((item) => typeof item === 'number')
        .map((keyItem) => {
            return { title: type[keyItem as keyof E], value: keyItem }
        })
    return items
}

export const GetEnumStringByEnumValue = <E extends StandardEnum<number>>(
    type: E,
    value: any
): string | undefined => {
    let getEnumAsKeyValueObject = GetEnumAsKeyValueObject(type, value)
    return getEnumAsKeyValueObject.find((i) => i.value === value)?.title
}

export const paymentSystemToString = (type: PaymentSystemTypes) => {
    switch (type) {
        case PaymentSystemTypes.Webmoney:
            return 'Webmoney'
        case PaymentSystemTypes.YandexMoney:
            return 'ЮMoney'
        case PaymentSystemTypes.Card:
            return 'Банковской картой (через Яндекс-деньги)'
        case PaymentSystemTypes.Sberbank:
            return ''
        case PaymentSystemTypes.Qiwi:
            return 'Qiwi'
        case PaymentSystemTypes.PaypalRub:
            return 'Paypal (RUB)'
        case PaymentSystemTypes.PaypalUsd:
            return 'Paypal (USD)'
        case null:
            return 'Не определено'
        default:
            return 'Не определено'
    }
}

export const generateAndDownloadTxtFile = (
    items: string[],
    fileName: string
) => {
    const itemsSend = items.map((i) => `${i}\n`)
    const element = document.createElement('a')
    const file = new Blob(itemsSend, { type: 'text/plain' })
    element.href = URL.createObjectURL(file)
    element.download = fileName + '.txt'
    document.body.appendChild(element) // Required for this to work in FireFox
    element.click()
}

export const copyBuffer = (text: string, dispatch: Dispatch) => {
    try {
        navigator.clipboard.writeText(text).then(() => {
            dispatch(appActions.showSnackbar('success', CONSTANTS.COPY))
        })
    } catch (e) {
        alert('You browser not allowed')
    }
}

const localeMap = {
    en: enLocale,
    ru: ruLocale,
}

const maskMap = {
    en: '__/__/____',
    ru: '__-__-____',
}

const formatDate = {
    en: 'MM/dd/yyyy',
    ru: 'dd-MM-yyyy',
}

const formatDateTime = {
    en: 'MM/dd/yyyy hh:mm a',
    ru: 'dd-MM-yyyy HH:mm',
}

const formatDateTimeLong = {
    en: 'PPPpp',
    ru: 'dd-MM-yyyy HH:mm',
}

export const getLocaleDateStringForHours = (
    language: string,
    date: Date | string
): string => {
    const lang = language as keyof typeof formatDate
    let dateValue = date
    if (!dateValue) return dateValue
    if (typeof dateValue === 'string') {
        dateValue = new Date(date)
    }
    console.log(dateValue)
    return dateFns.format(dateValue, formatDateTime['ru'], {
        locale: localeMap[lang],
    })
}

export const getLocaleDateString = (
    language: string,
    date: Date | string
): string => {
    const lang = language as keyof typeof formatDate
    let dateValue = date
    if (typeof dateValue === 'string') {
        dateValue = new Date(date)
    }
    return dateFns.format(dateValue, formatDate[lang], {
        locale: localeMap[lang],
    })
}

export const getLocaleDateFullString = (
    language: string,
    date: Date | string
): string => {
    const lang = language as keyof typeof formatDate
    let dateValue = date
    if (!dateValue) return dateValue
    if (typeof dateValue === 'string') {
        dateValue = new Date(date)
    }
    return dateFns.format(dateValue, formatDateTimeLong[lang], {
        locale: localeMap[lang],
    })
}
