import React, { ReactNode, useEffect, useState } from 'react'
import { Modal } from 'UI/common/modal/modal'
import { Button } from 'UI/common/elements/buttons/Button'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'UI/common/hooks/redux-hooks'
import { selectCards, selectWallet } from 'BLL/selectors/wallet-selectors'
import { fetchedCards, fetchedWallets } from 'BLL/CustomerWalletsReducer'
import { useDispatch } from 'react-redux'
import i18n from 'i18next'
import { LanguageType } from 'translations/ru/common'
import { selectBeatMaker } from 'BLL/selectors/app-selectors'
import s from 'UI/common/bindingCardModal/bindingCardModal.module.scss'

type Props = {
    children?: ReactNode
}

const REDIRECT_PATH = '/settings/wallets'

export const BindingCardModal = ({ children }: Props) => {
    const walletsInfo = useSelector(selectWallet)
    const cardList = useSelector(selectCards)
    const isBeatMaker = useSelector(selectBeatMaker)
    const dispatch = useDispatch()

    const [showModal, setShowModal] = useState(false)

    const {
        buttons,
        settings: {
            wallets: { tinkoff },
        },
    } = i18n.getResourceBundle(i18n.language, 'common') as LanguageType
    const history = useHistory()

    useEffect(() => {
        dispatch(fetchedWallets())
        dispatch(fetchedCards())
    }, [])

    useEffect(() => {
        const lastShownDate = localStorage.getItem('lastModalShownDate')
        const today = new Date().toDateString()

        if (lastShownDate !== today && isBeatMaker && walletsInfo && cardList) {
            const { yandexWallet, enotNumber } = walletsInfo
            const shouldShowModal = !(cardList && yandexWallet && enotNumber)
            if (shouldShowModal) {
                setShowModal(true)
                localStorage.setItem('lastModalShownDate', today)
            }
        }
    }, [isBeatMaker, walletsInfo])

    const redirectToWalletSettingsHandle = () => {
        setShowModal(false)
        history.push(REDIRECT_PATH)
    }
    return (
        <>
            {walletsInfo && (
                <div>
                    {children}
                    <Modal active={showModal} setActive={setShowModal}>
                        <div className={s.modalContentWrapper}>
                            {tinkoff.cardLinkInfo}
                            <Button onClick={redirectToWalletSettingsHandle}>
                                {buttons.GO}
                            </Button>
                        </div>
                    </Modal>
                </div>
            )}
        </>
    )
}
