import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { BeatType } from '../../../BLL/types'
import { useTranslation } from 'react-i18next'
import { LanguageType } from '../../../translations/ru/common'
import {
    actions as promoActions,
    getAllBeatsForPromo,
    getBeats,
    getPromoById,
    updatePromo,
} from '../../../BLL/CustomerBeatsPromotionReducer'
import { useSelector } from '../../common/hooks/redux-hooks'
import { useEditingEntityStatus } from '../../common/hooks/useEditingEntityStatus'
import { EditingEntityStatuses, RequestStatuses } from '../../../BLL/AppReducer'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import classes from './CustomerAdvertisingBeats/CustomerAdvertisingBeats.module.scss'
import { SearchField } from '../../common/elements/textFields/search'
import { CustomRadio } from '../../common/elements/CustomRadio'
import ArrowDownIcon from '../../assets/icons/arrow-bottom.svg'
import { Button } from '../../common/elements/buttons/Button'

const BeatSettingsPopup: FC = () => {
    const { id } = useParams<{ id: string }>()
    const [term, setTerm] = useState<string | null>(null)
    const [timerId, setTimerId] = useState<any>()
    const history = useHistory()
    const dispatch = useDispatch()
    const { i18n } = useTranslation()
    const { buttons, customerBeatsPromotion } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    const beats = useSelector(getAllBeatsForPromo)
    const promotion = useSelector(
        (state) => state.customerBeatsPromotion.currentPromo
    )
    const requestStatus = useSelector((state) => state.appReducer.requestStatus)
    const beatsPagination = useSelector(
        (state) => state.customerBeatsPromotion.beatsPagination
    )

    const [beatSelect, setBeatSelect] = React.useState<BeatType | null>(null)

    useEffect(() => {
        if (promotion) {
            setBeatSelect(promotion.beat)
        }
    }, [promotion])

    useEffect(() => {
        id && dispatch(getPromoById(id))
        dispatch(getBeats(beatsPagination.page + 1))
        return () => {
            dispatch(promoActions.clearBeats())
            setBeatSelect(null)
        }
    }, [id])

    useEffect(() => {
        handleSearchBeats()
    }, [term])

    const { editingEntityStatus } = useEditingEntityStatus(id)

    useEffect(() => {
        if (editingEntityStatus === EditingEntityStatuses.Success) {
            handleClose()
        }
    }, [editingEntityStatus])

    const handleClose = () => {
        history.push('/beats-promotion')
    }

    const handleSubmit = (event: any) => {
        event.stopPropagation()
        beatSelect &&
            promotion &&
            dispatch(
                updatePromo({ beatId: beatSelect.beatId, promo: promotion })
            )
    }

    const handleBeatSelect = (beat: BeatType) => {
        setBeatSelect(beat)
    }

    const handleGetBeatsMore = (event: any) => {
        event.stopPropagation()
        dispatch(getBeats(beatsPagination.page + 1))
    }

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        dispatch(promoActions.setPage(0))
        const value = event.currentTarget.value
        if (value) {
            setTerm(value)
        } else {
            setTerm(null)
        }
    }

    const handleSearchBeats = () => {
        clearTimeout(timerId)
        const timer = setTimeout(() => {
            if (term) {
                console.log('handleSearchBeats')
                dispatch(promoActions.clearBeats())
                dispatch(getBeats(1, term))
            } else {
                dispatch(getBeats(1))
            }
        }, 1000)
        setTimerId(timer)
    }

    return (
        <div>
            <p className={classes.descr}>
                {customerBeatsPromotion.beatsPromoNow}{' '}
                <span>{beatSelect?.beatTitle}</span>
            </p>
            <div className={classes.fieldBox}>
                <SearchField onChange={handleSearch} />
            </div>
            <div className={classes.fieldItems}>
                {beats.map((beat) => (
                    <CustomRadio
                        key={beat.beatId}
                        id={beat.beatId.toString()}
                        title={beat.beatTitle}
                        handleChange={() => handleBeatSelect(beat)}
                        checked={beat.beatId === beatSelect?.beatId}
                        value={beat.beatId}
                    />
                ))}
            </div>
            {beatsPagination.pagesCount > beatsPagination.page && (
                <div
                    className={classes.hiddenBlock}
                    onClick={handleGetBeatsMore}
                >
                    <img src={ArrowDownIcon} alt={'arrow-down'} />
                    <span>See more</span>
                </div>
            )}
            <div className={classes.btnBox}>
                <Button
                    view={'Secondary'}
                    title={buttons.CANCEL}
                    onClick={handleClose}
                />
                <Button
                    title={buttons.SAVE}
                    onClick={handleSubmit}
                    disabled={
                        requestStatus === RequestStatuses.InProgress ||
                        !beatSelect
                    }
                />
            </div>
        </div>
    )
}

export default BeatSettingsPopup
