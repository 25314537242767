import React, { FC, useState } from 'react'
import classes from './BeatmakerSettings.module.scss'
import ArrowUpIcon from '../../../assets/icons/arrow-top.svg'
import ArrowDownIcon from '../../../assets/icons/arrow-bottom.svg'
import { Notifications } from './Notifications/Notifications'
import { SettingsProfile } from '../../BeatmakerSettingsNew/profile-settings/SettingsProfile'
import { WalletSettings } from '../../BeatmakerSettingsNew/wallet-settings/WalletSettings'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'
type PropsType = {}

export const MobileBeatmakerSettings: FC<PropsType> = () => {
    const { i18n } = useTranslation()
    const { settings } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType
    const [collapsedSettings, setCollapsedSettings] = useState(true)
    const [collapsedNotification, setCollapsedNotification] = useState(false)
    const [collapsedWallets, setCollapsedWallets] = useState(false)
    return (
        <div className={classes.BeatmakerSettings}>
            <div>
                <div className={classes.contentItem}>
                    <div
                        className={classes.contentBox}
                        onClick={() => setCollapsedSettings(!collapsedSettings)}
                    >
                        <span className={classes.contentTitle}>
                            {settings.tabs.profile}
                        </span>
                        {collapsedSettings ? (
                            <img src={ArrowUpIcon} alt={'arrow-up'} />
                        ) : (
                            <img src={ArrowDownIcon} alt={'arrow-down'} />
                        )}
                    </div>
                    {collapsedSettings && (
                        <div className={classes.hideContent}>
                            <SettingsProfile />
                        </div>
                    )}
                </div>
                <div className={classes.contentItem}>
                    <div
                        className={classes.contentBox}
                        onClick={() =>
                            setCollapsedNotification(!collapsedNotification)
                        }
                    >
                        <span className={classes.contentTitle}>
                            {settings.tabs.notifications}
                        </span>
                        {collapsedNotification ? (
                            <img src={ArrowUpIcon} alt={'arrow-up'} />
                        ) : (
                            <img src={ArrowDownIcon} alt={'arrow-down'} />
                        )}
                    </div>
                    {collapsedNotification && (
                        <div className={classes.hideContent}>
                            <Notifications />
                        </div>
                    )}
                </div>
                <div className={classes.contentItem}>
                    <div
                        className={classes.contentBox}
                        onClick={() => setCollapsedWallets(!collapsedWallets)}
                    >
                        <span className={classes.contentTitle}>
                            {settings.tabs.wallets}
                        </span>
                        {collapsedWallets ? (
                            <img src={ArrowUpIcon} alt={'arrow-up'} />
                        ) : (
                            <img src={ArrowDownIcon} alt={'arrow-down'} />
                        )}
                    </div>
                    {collapsedWallets && (
                        <div className={classes.hideContent}>
                            <WalletSettings />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
