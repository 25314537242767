import { LanguageType } from 'translations/ru/common'
import { useSelector } from 'UI/common/hooks/redux-hooks'
import { selectBeatMaker } from 'BLL/selectors/app-selectors'
import { AppSettings } from 'settings/appSettings'
import { MenuItem } from 'UI/common/menus/menuItems/menuItems'
import expand from 'UI/assets/icons/expand.svg'

export const OutSideMenuItems = ({
    translate,
    userId,
}: {
    userId: number | null
    translate: LanguageType
}) => {
    const isBeatMaker = useSelector(selectBeatMaker)
    const isEngMode = AppSettings.api.isEngVersion()

    return [
        new MenuItem(
            translate.navigation.myProfile,
            `${AppSettings.urls.MAIN_DOMAIN}${
                isBeatMaker ? 'beatmaker' : 'user'
            }/${userId}`,
            true,
            false,
            expand
        ),
        new MenuItem(
            translate.navigation.chatVK,
            'https://vk.me/join/WrhBCYZi20DLtikYVV4mauK66qf_lZ4Bxbg=',
            !isEngMode && isBeatMaker,
            false,
            expand
        ),
        new MenuItem(
            translate.navigation.faq,
            'https://beatmaker.tv/help/faq',
            !isEngMode,
            false,
            expand
        ),
    ]
}
