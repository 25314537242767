import React, { useEffect, useState } from 'react'
import { getMyCoupons, getMyShop, updateCoupon } from 'BLL/ShopAdminReducer'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'BLL/Store'
import ShopCoupons from './ShopCoupons'
import { useRedirectIsOwnerHook } from '../common/hooks/useRedirectIsOwnerHook'
import { useTranslation } from 'react-i18next'
import { CouponStatus, CouponType, UpdateCouponType } from 'BLL/shop-types'
import Tooltip from '@material-ui/core/Tooltip'
import { NavLink, Route } from 'react-router-dom'
import Fab from '@material-ui/core/Fab'
import SettingsApplication from '@material-ui/icons/SettingsApplications'
import { LanguageType } from 'translations/ru/common'
import { AppSettings } from 'settings/appSettings'
import { SearchField } from '../common/elements/textFields/search'
import { CustomerRM } from '../routers/RouterManager'
import { CustomerCouponsModal } from '../CustomerAdmin/CustomerCoupons/CustomerCouponsModal/CustomerCouponsModal'
import { CouponItem } from '../CustomerAdmin/Mobile/MobCouponsPage/CouponItem/CouponItem'
import { CustomBrowserView } from '../common/browserView/CustomBrowserView'
import { CustomMobileView } from '../common/mobileView/CustomMobileView'
import { getCouponsTableData } from 'UI/common/table/tableData/CouponsTableData'
import { TableType } from 'UI/common/table/types'
import s from '../CustomerAdmin/CustomerCoupons/customerCoupons.module.scss'

export const ShopAdmin = () => {
    const [term, setTerm] = useState('')
    const { shop, coupons } = useSelector((state: AppState) => state.shopAdmin)
    const dispatch = useDispatch()
    const { i18n } = useTranslation()
    const { adminExpertsCompilation, shopCoupon, navigation, helpMessage } =
        i18n.getResourceBundle(i18n.language, 'common') as LanguageType

    useEffect(() => {
        dispatch(getMyShop())
        dispatch(getMyCoupons())
    }, [])

    const handleRefactorCoupon = (coupon: UpdateCouponType) => {
        dispatch(updateCoupon(coupon.id, coupon.shopNote, 'refactor'))
    }

    const handleRejectCoupon = (couponId: number) => {
        dispatch(updateCoupon(couponId, '', 'reject'))
    }

    const handleApproveCoupon = (couponId: number) => {
        dispatch(updateCoupon(couponId, '', 'approve'))
    }

    const fields: TableType<CouponType> = [
        {
            title: '',
            field: 'id',
            render: (coupon) => (
                <div>
                    {coupon.status === CouponStatus.Pending && (
                        <Tooltip title={adminExpertsCompilation.edit}>
                            <NavLink
                                to={`/shop/admin/coupons/edit/${coupon.id}`}
                            >
                                <Fab
                                    size="small"
                                    color="primary"
                                    style={{
                                        background: '#1f1f1f',
                                        color: AppSettings.api.getBaseColor(),
                                    }}
                                >
                                    <SettingsApplication />
                                </Fab>
                            </NavLink>
                        </Tooltip>
                    )}
                </div>
            ),
        },
    ]

    return (
        <div>
            {useRedirectIsOwnerHook()}
            <div className={s.topWrapper}>
                <CustomBrowserView>
                    <div className={s.pageTitle}>{navigation.adminCoupons}</div>
                </CustomBrowserView>
                <div className={s.searchWrapper}>
                    <SearchField
                        placeholder={helpMessage.search}
                        value={term}
                        onChange={({ target: { value } }) => setTerm(value)}
                    />
                </div>
            </div>
            <CustomMobileView>
                {coupons
                    .sort((a, b) => b.id - a.id)
                    .map((coupon) => (
                        <CouponItem
                            key={coupon.id}
                            coupon={coupon}
                            editType={'owner'}
                        />
                    ))}
                {/*<MobileViewCoupon*/}
                {/*    coupons={coupons.sort((a, b) => b.id - a.id)}*/}
                {/*    location={'admin'}*/}
                {/*/>*/}
            </CustomMobileView>
            <CustomBrowserView>
                <ShopCoupons
                    coupons={coupons}
                    fields={getCouponsTableData(i18n, 'owner')}
                />
            </CustomBrowserView>

            <Route
                path={CustomerRM.shops.owner.edit.path}
                render={({ match }) => (
                    <CustomerCouponsModal location={'owner'} />
                    // <CustomerEditCoupon
                    //     redirectUrl={CustomerRM.shops.coupons.path}
                    //     router={router}
                    //     title={shopCoupon.edit}
                    //     location={'customer'}
                    //     updateCoupon={handleUpdateCoupon}
                    // />
                )}
            />

            {/*<Route*/}
            {/*    path={'/shop/admin/coupons/edit/:couponId'}*/}
            {/*    render={(router) => (*/}
            {/*        <CustomerEditCoupon*/}
            {/*            redirectUrl={'/shop/admin'}*/}
            {/*            router={router}*/}
            {/*            title={shopCoupon.edit}*/}
            {/*            location={'admin'}*/}
            {/*            updateCoupon={handleRefactorCoupon}*/}
            {/*            handleRejectCoupon={handleRejectCoupon}*/}
            {/*            handleApproveCoupon={handleApproveCoupon}*/}
            {/*        />*/}
            {/*    )}*/}
            {/*/>*/}
        </div>
    )
}
