import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'BLL/Store'
import Header from '../../common/header/Header'
import ShopCoupons from '../../ShopAdmin/ShopCoupons'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'
import { getManagementCoupons, getShops } from 'BLL/CustomerCouponsReducer'
import { useHistory, useLocation } from 'react-router-dom'
import { BrowserView } from 'react-device-detect'
import { getCouponsTableData } from 'UI/common/table/tableData/CouponsTableData'

function useQuery() {
    return new URLSearchParams(useLocation().search)
}

type ShopSelectType = {
    value: number
    label: string
}
const AdminCoupons = () => {
    const query = useQuery()
    const shopId = query.get('shopId')
    const history = useHistory()
    const { shops, coupons } = useSelector(
        (state: AppState) => state.customerCoupons
    )
    const { requestStatus } = useSelector((state: AppState) => state.appReducer)
    const dispatch = useDispatch()
    const { i18n } = useTranslation()
    const { navigation, buttons, shopCoupon } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType
    const [selectValue, setSelectValue] = useState<ShopSelectType>()

    useEffect(() => {
        dispatch(getShops())
        dispatch(getManagementCoupons())
    }, [])

    useEffect(() => {
        if (shopId && shops.length && shopId !== 'null') {
            setSelectValue({
                value: +shopId,
                label: shops.find((s) => s.id === +shopId)!.title,
            })
        }
    }, [shopId, shops])

    const selectOptions = [
        { value: null, label: shopCoupon.all },
        ...shops.map((shop) => {
            return { value: shop.id, label: shop.title }
        }),
    ]
    let arrayCoupons = [...coupons]
    if (selectValue && selectValue.value) {
        arrayCoupons = coupons.filter((c) => c.shopId === selectValue.value)
    }

    const handleSetSelect = (shopSelected: ShopSelectType) => {
        setSelectValue(shopSelected)
        history.push(`/shop/customer?shopId=${shopSelected.value}`)
    }

    const selectProps = {
        selectValue,
        setSelectValue: handleSetSelect,
        placeholder: shopCoupon.tableColumns.shop,
        options: selectOptions,
    }

    return (
        <div>
            <Header title={navigation.adminCoupons} />
            <div style={{ marginTop: 20 }}>
                {/*<div style={{ display: 'flex' }}>*/}
                {/*    <div style={{ minWidth: 210, zIndex: 999 }}>*/}
                {/*        <SelectElement {...selectProps} />*/}
                {/*    </div>*/}
                {/*</div>*/}
                <BrowserView>
                    <ShopCoupons
                        coupons={arrayCoupons}
                        fields={getCouponsTableData(i18n, 'owner')}
                    />
                </BrowserView>
            </div>
        </div>
    )
}

export default AdminCoupons
