import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { deleteTemplateById } from '../../../../BLL/AdminTemplatesEmailReducer'
import { useTranslation } from 'react-i18next'
import { LanguageType } from '../../../../translations/ru/common'

export const DeleteTemplateDialog = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { id } = useParams<{ id: string }>()
    const { i18n } = useTranslation()
    const { buttons } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    const handleDelete = async () => {
        await dispatch(deleteTemplateById(id))
        handleClose()
    }

    const handleClose = () => {
        history.push('/admin/templates/')
    }

    return (
        <div>
            <Dialog
                open={true}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {'Подтвердите действие'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Вы действительно хотите удалить шаблон
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        {buttons.CANCEL}
                    </Button>
                    <Button onClick={handleDelete} color="primary" autoFocus>
                        {buttons.YES}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
