import React from 'react'
import { ProgressBar } from '../ProgressBar/ProgressBar'
import { PlayerControls } from '../PlayerControls/PlayerControls'
import { TrackDescr } from '../TrackDescr/TrackDescr'
import s from './Player.module.scss'
import { PlayerLogic, PlayerLogicTrackType } from '../../../Domain/PlayerLogic'
import { SoundRange } from '../SoundRange/SoundRange'

type PropsType = {
    toggleMobMode: () => void
    player: PlayerLogic
    track: PlayerLogicTrackType
    toggleCollapsed: () => void
}

export const Player = (props: PropsType) => {
    return (
        <div className={s.player}>
            <ProgressBar player={props.player} />

            <div className="common-container">
                <div className={s.playerContainer}>
                    <TrackDescr track={props.track} mobMode={false} />
                    <div className={s.playerControls}>
                        <PlayerControls
                            mobMode={false}
                            player={props.player}
                            track={props.track}
                        />
                    </div>
                    <div className={s.soundRangeContainer}>
                        <SoundRange player={props.player} />
                        <button
                            className={s.btnCollapsed}
                            onClick={props.toggleCollapsed}
                        >
                            {' '}
                        </button>
                    </div>
                    <div className={s.btnOpenContainer}>
                        <button
                            className={s.btnOpen}
                            onClick={props.toggleMobMode}
                        >
                            {' '}
                        </button>
                        <button
                            className={s.btnCollapsed}
                            onClick={props.toggleCollapsed}
                        >
                            {' '}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
