import { AxiosRequestConfig } from 'axios'

const AUTH_ADMIN_TOKEN_KEY = 'AUTH_ADMIN_TOKEN_KEY'

export async function saveToken(token: string) {
    sessionStorage.setItem(AUTH_ADMIN_TOKEN_KEY, token)
}

export async function getToken() {
    return sessionStorage.getItem(AUTH_ADMIN_TOKEN_KEY)
}

export const addTokenToHeaders = async (
    config: AxiosRequestConfig,
    key = 'auth-token'
) => {
    let token = await getToken()
    if (token) {
        config.headers[key] = token
    }
}
