import { AdminPromoType } from 'UI/ManagerAdmin/AdminBeatsPromotion'
import { AppSettings } from 'settings/appSettings'
import { formatDate } from 'BLL/SupportingFile'
import React from 'react'

import { i18n } from 'i18next'
import { TableType } from '../types'

export const getAdminPromoTableData = (
    i18n: i18n
): TableType<AdminPromoType> => {
    const { adminBeatsPromotionTable, customerBeatsPromotion } =
        i18n.getResourceBundle(i18n.language, 'common')
    return [
        {
            title: adminBeatsPromotionTable.beatmaker,
            field: 'userId',
            render: (rowData) => (
                <a
                    href={`${AppSettings.urls.MAIN_DOMAIN}/user/${rowData.userId}`}
                    target={'_blank'}
                    rel="noreferrer"
                >
                    {rowData.userId}
                </a>
            ),
        },
        { title: adminBeatsPromotionTable.beatTitle, field: 'beatTitle' },
        {
            title: adminBeatsPromotionTable.reservedDate,
            field: 'addedDate',
            render: (rowData) =>
                rowData.addedDate ? (
                    <div>{formatDate(rowData.addedDate)}</div>
                ) : (
                    <div />
                ),
        },
        { title: customerBeatsPromotion.countShows, field: 'shows' },
    ]
}
