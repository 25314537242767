import React, { useState } from 'react'
import { Control, UseFormRegister } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import FormControlLabel from '@mui/material/FormControlLabel'

import classes from '../WidgetsSettingsPage.module.scss'

import { LanguageType } from '../../../../../translations/ru/common'
import { InputField } from '../../../../common/elements/textFields/Input'
import {
    CustomSelect,
    OptionsType,
} from '../../../../common/elements/CustomSelect'
import { MyCheckbox } from '../../../../common/elements/checkbox/MyCheckbox'
import { Button } from '../../../../common/elements/buttons/Button'
import { CustomCheckbox } from '../../../../common/elements/CustomCheckbox'
import SongIcon from '../../../../assets/icons/song.svg'
import { WidgetFormType } from '../WidgetsSettingsPage'
import { LangType } from '../../../../../translations/en/common'
import { WidgetAddBeatModal } from './WidgetAddBeatModal/WidgetAddBeatModal'

type WidgetSettingsBlockPropsType = {
    register: UseFormRegister<WidgetFormType>
    errors: any
    setValue: (name: keyof WidgetFormType, value: any) => void
    control: Control<WidgetFormType>
    watch: (value: string) => any
}

export const WidgetSettingsBlock = (props: WidgetSettingsBlockPropsType) => {
    /* i18n */
    const { i18n } = useTranslation()
    const { widget, buttons, languageList, formMessages } =
        i18n.getResourceBundle(i18n.language, 'common') as LanguageType

    const [isAddBeatsModal, setIsAddBeatsModal] = useState(false)
    const [isSelectPlayingBeatModal, setSelectPlayingBeatModal] =
        useState(false)

    const languages: LangType[] = [
        { id: 1, title: languageList.en },
        { id: 2, title: languageList.ru },
    ]

    const langOption: OptionsType[] = languages.map((l) => ({
        value: l.id,
        label: l.title,
    }))

    const tracklist: any[] = [
        { id: 1, title: widget.fields.selectAllBeats },
        { id: 2, title: widget.fields.selectCustomBeats },
    ]

    const tracklistOption: OptionsType[] = tracklist.map((t) => ({
        value: t.id,
        label: t.title,
    }))

    return (
        <>
            {/* Main title */}
            <div className="commonTopWrapper  commonTopWrapper--widgetBlockTitle">
                <div className="commonPageTitle">
                    {widget.settingsBlockTitle}
                </div>
            </div>

            {/* Form */}
            <div className={classes.widgetBox}>
                {/* Widget name */}
                <div className={classes.fieldBox}>
                    <label className={classes.label}>
                        {widget.fields.title}
                    </label>
                    <InputField
                        defaultValue={'Widget Instagram'}
                        register={props.register('title', {
                            required: formMessages.requiredTitle,
                            minLength: {
                                value: 3,
                                message: formMessages.minTitle,
                            },
                            maxLength: {
                                value: 50,
                                message: formMessages.maxTitle,
                            },
                        })}
                        error={props.errors?.title}
                    />
                </div>

                {/* Widget size */}
                <div className={classes.fieldsContainer}>
                    {/* Widget width */}
                    <div className={classes.fieldBoxHalfSize}>
                        <label className={classes.label}>
                            {widget.fields.width}
                        </label>
                        <InputField
                            defaultValue={1024}
                            type={'number'}
                            register={props.register('width', {
                                valueAsNumber: true,
                                required: formMessages.requiredTitle,
                                min: {
                                    value: 100,
                                    message: formMessages.minWidgetWidth,
                                },
                                maxLength: {
                                    value: 5000,
                                    message: formMessages.maxWidgetWidth,
                                },
                            })}
                        />
                    </div>

                    {/* Widget height */}
                    <div className={classes.fieldBoxHalfSize}>
                        <label className={classes.label}>
                            {widget.fields.height}
                        </label>
                        <InputField
                            defaultValue={800}
                            type={'number'}
                            register={props.register('height', {
                                valueAsNumber: true,
                                required: formMessages.requiredTitle,
                                min: {
                                    value: 100,
                                    message: formMessages.minWidgetHeight,
                                },
                                maxLength: {
                                    value: 10000,
                                    message: formMessages.maxWidgetHeight,
                                },
                            })}
                            error={props.errors?.height}
                        />
                    </div>
                </div>

                {/* IsWidthResponsive checkbox */}
                <div className={classes.fieldBox}>
                    <MyCheckbox
                        name={'isWidthResponsive'}
                        control={props.control}
                        defaultChecked={false}
                        label={widget.fields.isWidthResponsive}
                        labelSize={'12px'}
                    />
                </div>

                {/* Language select */}
                <div className={classes.fieldBox}>
                    <label className={classes.label}>
                        {widget.fields.lang}
                    </label>
                    <CustomSelect
                        control={props.control}
                        register={props.register}
                        setValue={props.setValue}
                        options={langOption}
                        name={'lang'}
                    />
                </div>

                {/* Tracklist select */}
                <div className={classes.fieldBox}>
                    <label className={classes.label}>
                        {widget.fields.tracklistSelect}
                    </label>
                    <CustomSelect
                        control={props.control}
                        register={props.register}
                        setValue={props.setValue}
                        options={tracklistOption}
                        name={'tracklist'}
                    />
                </div>

                {/* Add beats block */}
                {props.watch('tracklist')?.value === 2 && (
                    <div className={classes.fieldBox}>
                        <Button
                            view={'Outline'}
                            title={buttons.ADD_BEATS}
                            type={'button'}
                            onClick={() => setIsAddBeatsModal(true)}
                        />
                    </div>
                )}

                {/* Select the beat to play automatically */}
                <div className={classes.fieldBoxMB0}>
                    <p className={classes.label}>
                        {widget.fields.selectPlayingBeatTitle}
                    </p>
                </div>
                <div className="commonBeatInfoWrapper">
                    <img src={SongIcon} alt={'song'} />
                    <div className="commonBeatInfo">
                        <FormControlLabel
                            control={
                                <CustomCheckbox
                                    name={'playingBeat'}
                                    checked={isSelectPlayingBeatModal}
                                    onChange={() =>
                                        setSelectPlayingBeatModal(true)
                                    }
                                />
                            }
                            label={widget.fields.automaticallyProduceBeat}
                            labelPlacement={'end'}
                        />
                    </div>
                </div>
            </div>

            <WidgetAddBeatModal
                isAddBeatsModal={isAddBeatsModal}
                setIsAddBeatsModal={setIsAddBeatsModal}
            />
        </>
    )
}
