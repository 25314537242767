import React from 'react'
import { PlayerLogicTrackType } from '../../../Domain/PlayerLogic'
import s from './TrackDescr.module.scss'

type PropsType = {
    track: PlayerLogicTrackType
    mobMode: boolean
}

export const TrackDescr = (props: PropsType) => {
    return (
        <div className={props.mobMode ? s.trackDescrMob : s.trackDescr}>
            <img
                className={props.mobMode ? s.trackCoverMob : s.trackCover}
                src={
                    props.mobMode
                        ? props.track.images.middle
                        : props.track.images.thumbnail
                }
                alt={`${props.track.title} cover`}
            />
            <div className={s.trackText}>
                <p className={s.trackArtist}>{props.track.artist}</p>
                <p className={s.trackTitle}>{props.track.title}</p>
            </div>
        </div>
    )
}
