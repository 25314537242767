import React, { Fragment } from 'react'
import { FieldError } from 'react-hook-form'
import { deviceDetect } from 'react-device-detect'
import Select from 'react-select'
import s from './newCustomSelect.module.scss'

export type OptionsType = { value: string | number; label: string | number }

export type Props = {
    options: OptionsType[]
    setValue?: (name: string, value: string) => void
    placeholder?: string
    errorMessage?: FieldError | undefined
    disabled?: boolean
    noMobile?: boolean
    onChange?: () => void
    customStyles?: Record<string, any>
}

export const NewCustomSelect = ({
    options,
    errorMessage,
    setValue,
    disabled,
    noMobile,
    onChange,
    customStyles,
    ...restProps
}: Props) => {
    const isMobile = noMobile ? false : deviceDetect()?.isMobile

    const handleChange = (value: OptionsType) => {
        if (isMobile) {
            const selectValue = { value: value.value, label: value.label }
            setValue &&
                setValue(selectValue.value.toString(), value.value.toString())
        }
    }
    const mobileProps = isMobile
        ? {
              onChange: handleChange,
          }
        : {}

    return (
        <div className={customStyles ? customStyles.selectWrapper : ''}>
            <Select
                className={customStyles ? customStyles.select : s.select}
                options={options}
                openMenuOnFocus={true}
                {...mobileProps}
                components={{
                    IndicatorSeparator: () => null,
                }}
                isDisabled={disabled}
                onChange={onChange}
                {...restProps}
            />
            <span
                className={
                    customStyles ? customStyles.errorMessage : s.errorMessage
                }
            >
                {errorMessage?.message}
            </span>
        </div>
    )
}
