import React, { ComponentPropsWithoutRef } from 'react'
import s from './buttons.module.scss'

type Props = {
    view?: 'Delete' | 'Outline' | 'Primary' | 'Secondary' | 'Details'
} & ComponentPropsWithoutRef<'button'>

export const Button = ({
    onClick,
    title,
    disabled,
    children,
    view = 'Primary',
    ...props
}: Props) => {
    const className = disabled
        ? `${s.Button} ${s.disabled}`
        : `${s.Button} ${s[view]}`
    return (
        <button
            className={className}
            onClick={onClick}
            disabled={disabled}
            {...props}
        >
            {title}
            {children}
        </button>
    )
}
