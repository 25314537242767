import React, { FC } from 'react'
import classes from './MySalesInfo.module.scss'
import { MobReturn } from '../../MobileCommon/MobReturn'
import { MySalesOrder } from './MySalesOrder/MySalesOrder'
import { SaleTrack } from './SaleTrack/SaleTrack'
import { MySalesPayment } from './MySalesPayment/MySalesPayment'
import { PaymentTypeBLL } from '../../../../../BLL/MySalesReducer'
import { CustomerRM } from '../../../../routers/RouterManager'
import { useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import { LanguageType } from '../../../../../translations/ru/common'
import { AppSettings } from 'settings/appSettings'

type PropsType = {
    rootPayment: PaymentTypeBLL
}
export const MySalesInfo: FC<PropsType> = ({ rootPayment }) => {
    const { id } = useParams<{ id: string }>()
    const { i18n } = useTranslation()
    const { myBeats, buttons, mySales } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType
    return (
        <div className={classes.MySalesInfo}>
            <MobReturn
                title={buttons.BACK_TO_ITEMS}
                redirectUrl={CustomerRM.mySales.path.list}
            />
            <h2 className={classes.title}>{mySales.saleDetails.salesInfo}</h2>
            <MySalesOrder />
            <h3 className={classes.subtitle}>
                {mySales.saleDetails.orderSummary}:
            </h3>
            <div className={classes.saleTracks}>
                {rootPayment.sales.map((item, index) => {
                    return <SaleTrack key={item.id} item={item} />
                })}
            </div>
            <MySalesPayment />
        </div>
    )
}
