import React, { useEffect, useCallback } from 'react'
import { Button } from 'UI/common/elements/buttons/Button'
import { ButtonTooltip } from 'UI/common/elements/buttons/ButtonTooltip'
import { useSelector } from 'UI/common/hooks/redux-hooks'
import { useDispatch } from 'react-redux'
import {
    coinsActions,
    fetchedBalance,
    fetchedBonusCoins,
    fetchedPaidCoins,
} from 'BLL/CustomerCoinsReducer'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'
import { AppSettings } from 'settings/appSettings'
import { MobCoinsPage } from '../../Mobile/MobCoinsPage/MobCoinsPage'
import { CustomBrowserView } from 'UI/common/browserView/CustomBrowserView'
import { CustomMobileView } from 'UI/common/mobileView/CustomMobileView'
import {
    selectBalance,
    selectBonus,
    selectCoins,
    selectMoney,
    selectRedirectURL,
    selectSaleCount,
} from 'BLL/selectors/coins-selectors/selectCoins'
import { BalanceCount } from 'UI/CustomerAdmin/CustomerCoins/enums'
import { NewTextField } from 'UI/common/elements/textFields/newtextField/NewTextField'
import { useDebounce } from 'UI/common/hooks/useDebounce'
import s from 'UI/CustomerAdmin/CustomerCoins/CustomerCoinsCalc/customerCoinsCalc.module.scss'

export const CustomerCoinsCalc = () => {
    const redirectUrl = useSelector(selectRedirectURL)
    const balance = useSelector(selectBalance)
    const coinsCount = useSelector(selectCoins)
    const bonusCoins = useSelector(selectBonus)
    const money = useSelector(selectMoney)
    const saleCount = useSelector(selectSaleCount)
    const dispatch = useDispatch()

    const { i18n } = useTranslation()
    const { myCoins, buttons } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    const isEng = AppSettings.api.isEngVersion()

    const debouncedCoinsCount = useDebounce(coinsCount)

    useEffect(() => {
        dispatch(fetchedBalance())
    }, [])

    useEffect(() => {
        dispatch(fetchedBonusCoins(+debouncedCoinsCount))
    }, [debouncedCoinsCount])

    const handleCoinsChange = (coins: string) => {
        dispatch(coinsActions.setCoins(+coins))
    }

    const handlePaidCoins = useCallback(() => {
        dispatch(fetchedPaidCoins(coinsCount))
    }, [coinsCount])

    if (redirectUrl) {
        window.location.href = redirectUrl
    }

    return (
        <div>
            <CustomBrowserView>
                <h1 className={s.title}>{myCoins.coins}</h1>
                {!!saleCount && (
                    <div className={s.sale}>
                        {myCoins.coinsSale} {saleCount}%
                    </div>
                )}
                <div className={s.calcBlock}>
                    <div className={s.fields}>
                        <div className={s.fieldItemsBox}>
                            <div className={s.fieldItem}>
                                <NewTextField
                                    label={myCoins.coins}
                                    value={coinsCount}
                                    onValueChange={handleCoinsChange}
                                />
                            </div>
                            <div className={s.fieldItem}>
                                <NewTextField
                                    value={money}
                                    disabled={true}
                                    label={isEng ? 'USD' : 'RUB'}
                                />
                            </div>
                            <div className={s.boxBtn}>
                                <Button
                                    view={'Primary'}
                                    title={buttons.BUY_COINS}
                                    onClick={handlePaidCoins}
                                />
                            </div>
                        </div>
                        <div className={s.tooltipBox}>
                            <ButtonTooltip>
                                <ul>
                                    {Object.values(myCoins.bonusItems).map(
                                        (value) => (
                                            <li key={value}>{value}</li>
                                        )
                                    )}
                                </ul>
                            </ButtonTooltip>
                            <span className={s.coins}>
                                {myCoins.bonusCoins} {bonusCoins}
                            </span>
                        </div>
                    </div>
                    <div className={s.balance}>
                        <span className={s.balanceText}>
                            {myCoins.coinsBalance}
                        </span>
                        <span className={s.balanceCount}>
                            {balance?.coins || BalanceCount.Zero}
                        </span>
                    </div>
                </div>
            </CustomBrowserView>
            <CustomMobileView>
                <MobCoinsPage
                    sale={saleCount}
                    handlePaidCoins={handlePaidCoins}
                    handleSetCoins={handleCoinsChange}
                    coinsCount={coinsCount}
                    bonusCoins={bonusCoins}
                    money={money}
                />
            </CustomMobileView>
        </div>
    )
}
