import React from 'react'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'
import Paper from '@material-ui/core/Paper'
import Table from '../../common/table/Table'
import { ShopTypesFull } from 'BLL/shop-types'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { TableType } from 'UI/common/table/types'

const useStyles = makeStyles(() =>
    createStyles({
        tableWrapper: {
            marginTop: 20,
            marginBottom: 40,
        },
    })
)

type Props = {
    shops: ShopTypesFull[]
    fields: TableType<ShopTypesFull>
}

const Shops = ({ shops, fields }: Props) => {
    const { i18n } = useTranslation()
    const { managerAdmin, helpMessage } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    const classes = useStyles()

    return (
        <Paper>
            <div className={classes.tableWrapper}>
                <Table<ShopTypesFull>
                    items={shops}
                    search={true}
                    searchText={helpMessage.search}
                    title={managerAdmin.tableTitle}
                    fields={fields}
                />
            </div>
        </Paper>
    )
}

export default Shops
