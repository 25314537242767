import React, { FC } from 'react'
import classes from './inputs.module.scss'

type PropsType = {
    customStyles?: string
} & React.InputHTMLAttributes<HTMLInputElement>

export const SearchField: FC<PropsType> = ({ ...props }) => {
    const completedClass =
        props.customStyles === 'lightSearch' && classes.lightSearch

    return (
        <input
            className={`${classes.Input} ${classes.search} ${completedClass}`}
            {...props}
        />
    )
}
