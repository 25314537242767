import { CashbackType, CurrencyEnum } from 'DAL/CashbackAPI'
import { LanguageType } from 'translations/ru/common'
import { AppSettings } from 'settings/appSettings'
import s from 'UI/common/table/tableData.module.scss'
import UsdIcon from 'UI/assets/icons/usd.svg'
import RubIcon from 'UI/assets/icons/rub.svg'
import { getLocaleDateString } from 'UI/utils/util-types'
import React from 'react'
import { i18n } from 'i18next'
import { TableType } from '../types'

export const getCashbackTableData = (i18n: i18n): TableType<CashbackType> => {
    const { cashbacks } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType
    const isEng = AppSettings.api.isEngVersion()
    return [
        {
            title: cashbacks.tableColumns.operation,
            field: '_id',
            render: (data) => {
                return (
                    <div>
                        {data.closedAmount
                            ? cashbacks.tableColumns.payment
                            : cashbacks.tableColumns.income}
                    </div>
                )
            },
        },
        {
            title: cashbacks.tableColumns.amount,
            field: '_id',
            render: (data) => {
                const isClosed = data.closedAmount
                const amount = isClosed ? data.closedAmount : data.amount
                return (
                    <div>
                        {data.currency === CurrencyEnum.usd ? (
                            <span className={s.getCashbackTableDataPrice}>
                                {isClosed ? '- ' : '+ '}
                                <img src={UsdIcon} alt="" />
                                {amount}
                            </span>
                        ) : (
                            <span className={s.getCashbackTableDataPriceRub}>
                                {isClosed ? '- ' : '+ '}
                                <img src={RubIcon} alt="" /> {amount}
                            </span>
                        )}
                    </div>
                )
            },
        },
        {
            title: cashbacks.tableColumns.paymentDate,
            field: 'paymentDate',
            render: (cashback) => {
                return (
                    <div>
                        {cashback.paymentDate &&
                            getLocaleDateString(
                                i18n.language,
                                cashback.paymentDate
                            )}
                    </div>
                )
            },
        },
        {
            title: cashbacks.tableColumns.expirationDate,
            field: 'expirationDate',
            render: (cashback) => {
                const isClosed = cashback.closedAmount
                return (
                    <div>
                        {!isClosed
                            ? getLocaleDateString(
                                  i18n.language,
                                  cashback.expirationDate
                              )
                            : '--------'}
                    </div>
                )
            },
        },
    ]
}
