import { useQuery } from 'react-query'
import { adminBeatsAPI, GetBeatsModelType } from '../../../DAL/adminBeatsAPI'
import React from 'react'
import {
    GetEnumAsKeyValueObject,
    GetEnumStringByEnumValue,
} from '../../utils/util-types'
import { M3U8Statuses } from '../../../BLL/types'
import { Grid, Paper, TablePagination } from '@material-ui/core'
import { useRedirectCustomHook } from '../../common/hooks/useRedirectCustomHook'
import { RolesTypeEnum } from '../../../BLL/enums'

const fetchBeats = (page = 0, pageSize = 100, params: GetBeatsModelType) => {
    return adminBeatsAPI.getBeats(page, pageSize, params)
}

const fetchBeatFilesDetails = (id: number) =>
    adminBeatsAPI.getBeatFilesDetails(id)

export const AdminBeats = () => {
    const [page, setPage] = React.useState(1)
    const [beatId, setBeatId] = React.useState<number | null>(null)
    const [m3u8Status, setM3U8Status] = React.useState<M3U8Statuses>(
        M3U8Statuses.Error
    )
    const pageSize = 100

    const { isLoading, data } = useQuery(['all-admin-beats', page], () => {
        const params: GetBeatsModelType = {
            M3U8Status: m3u8Status,
        }
        if (beatId) params.beatId = beatId
        return fetchBeats(page, pageSize, params)
    })
    const auth = useRedirectCustomHook(RolesTypeEnum.ADMINISTRATOR)

    return (
        <Paper style={{ padding: 30 }}>
            {auth}
            <Grid
                container
                spacing={2}
                style={{ display: 'flex', flexDirection: 'column' }}
            >
                <>
                    <Grid item>
                        <div>
                            Filters:
                            <hr />
                            <div>
                                beatId:{' '}
                                <input
                                    type="number"
                                    onChange={(e) =>
                                        setBeatId(+e.currentTarget.value)
                                    }
                                />
                            </div>
                            <div>
                                m3u8 status:{' '}
                                <select
                                    onChange={(e) =>
                                        setM3U8Status(+e.currentTarget.value)
                                    }
                                >
                                    {GetEnumAsKeyValueObject(
                                        M3U8Statuses,
                                        M3U8Statuses
                                    ).map((value) => (
                                        <option
                                            value={value.value}
                                            key={value.value}
                                        >
                                            {value.title}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </Grid>
                    <Grid item>
                        {data && (
                            <TablePagination
                                onChangePage={(e, page) => {
                                    setPage(page)
                                }}
                                page={page}
                                count={data.totalCount}
                                rowsPerPageOptions={[pageSize]}
                                component="div"
                                rowsPerPage={pageSize}
                                backIconButtonProps={{
                                    'aria-label': 'Previous Page',
                                }}
                                nextIconButtonProps={{
                                    'aria-label': 'Next Page',
                                }}
                            />
                        )}

                        {isLoading ? (
                            <span>...loading</span>
                        ) : (
                            <table style={{ border: '1px' }}>
                                <thead>
                                    <th>id</th>
                                    <th>title</th>
                                    <th>beatmakerId</th>
                                    <th>playUrl</th>
                                    <th>m3u8</th>
                                    <th>m3u8 error</th>
                                    <th></th>
                                </thead>
                                <tbody>
                                    {data?.items?.map((b) => {
                                        return (
                                            <tr key={b.id}>
                                                <td>{b.id}</td>
                                                <td>{b.name}</td>
                                                <td>{b.beatmakerId}</td>
                                                <td>{b.playUrl}</td>
                                                <td>
                                                    {GetEnumStringByEnumValue(
                                                        M3U8Statuses,
                                                        b.m3U8Status
                                                    )}
                                                </td>
                                                <td>{b.m3U8StatusMessage}</td>
                                                <td>
                                                    <button
                                                        onClick={async () => {
                                                            const data =
                                                                await fetchBeatFilesDetails(
                                                                    b.id
                                                                )
                                                            alert(
                                                                JSON.stringify(
                                                                    data
                                                                )
                                                            )
                                                        }}
                                                    >
                                                        Details
                                                    </button>
                                                    <button
                                                        onClick={async () => {
                                                            const data =
                                                                await adminBeatsAPI.generateM3U8(
                                                                    b.id
                                                                )
                                                            alert(
                                                                JSON.stringify(
                                                                    data
                                                                )
                                                            )
                                                        }}
                                                    >
                                                        Generate m3u8
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        )}
                    </Grid>
                </>
            </Grid>
        </Paper>
    )
}
