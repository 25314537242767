import { BeatsConstants } from '../../UI/constants'
import { LanguageType } from '../ru/common'
import { ISourceType } from 'BLL/SupportingFile'

export type LangType = {
    id: number
    title: LangListType
}
export type LangListType = 'Русский' | 'Английский' | 'Russian' | 'English'

export type LangSelectType = { value: number; label: LangListType }

export const en: LanguageType = {
    languageList: {
        ru: 'Russian' as LangListType,
        en: 'English' as LangListType,
    },
    buttons: {
        SAVE: 'Save',
        SAVE_CHANGES: 'Save Changes',
        SAVE_LICENSES: 'Save Licenses',
        SAVE_CONTRACT: 'Save Contract',
        DONE: 'Done',
        SAVE_AND_RESULT: 'Save and result',
        CANCEL: 'Cancel',
        ACCEPT: 'Accept',
        CLOSE: 'Close',
        SEND: 'Send',
        RESERVE: 'Reserve',
        CONTINUE: 'Continue',
        PUBLISHED: 'Publish Now',
        SAVE_AND_PUBLISHED: 'Save and published',
        MORE: 'More',
        DELETE: 'Delete',
        RESTORE: 'Restore',
        LIST: 'List options',
        YES: 'Yes',
        ADD: 'Add',
        ADD_ADVERTISING: '+ Add New Advertising',
        ADD_OPTIONS: 'Add options',
        ADD_LICENSE: '+ Add New License',
        ADD_WIDGET: 'Add widget',
        ADD_BEATS: 'Add beats',
        SKIP: 'Skip the Beat',
        NO: 'No',
        CLEAR_FILTER: 'Clear filter',
        BACK_TO_TEMPLATES: 'Back to license',
        REJECT: 'Reject',
        SEND_NOTE: 'Send note',
        EDIT: 'Edit',
        DEFAULT_TEMPLATE: 'Load default template',
        DOWNLOAD_PDF: 'Download PDF',
        DELETE_FROM_CATALOG: 'Remove from Catalog',
        BACK_TO_ITEMS: 'Back to List',
        EDIT_CONTRACT: 'Edit contract',
        ADD_CONTRACT: 'Create contract',
        SHOW: 'Show',
        WRITE_MESSAGE: 'Write a message',
        WITHDRAW: 'Withdraw',
        DETAILS: 'Details',
        GO_TO_PAYMENT: 'Go to Payment',
        GO: 'Go',
        CHOOSE_DAY: 'Choose day',
        BUY_COINS: 'Buy coins',
        LETS_GO: 'Let’s go!',
        ADD_TO_COMPILATION: 'Add to Compilation',
        ADD_COUPON: 'Add coupon',
        COPY: 'Copy',
        DOWNLOAD_EMAIL: 'Download Email`s',
        ADD_NEW_DISCOUNT: '+ Add New Discount',
        GO_TO_PRODUCER_PAGE: 'Go to my Producer Page',
        APPROVE: 'Approve',
        DEACTIVATE: 'Deactivate',
    },
    statuses: {
        Active: 'Active',
        Waiting: 'Waiting',
        Rejected: 'Rejected',
        Clarification: 'Clarification',
        Confirmed: 'Confirmed',
        Closed: 'Closed',
        NotActive: 'Not Active',
    },
    helpMessage: {
        edit: 'Edit',
        search: 'Search',
        loading: 'loading...',
    },
    messages: {
        ERROR_MESSAGE: 'Error. Please contact the Administrator',
        SUCCESS_MESSAGE: 'Operation completed successfully',
        COPY_SUCCESS: 'Copy successfully',
        COPY_FAILED: 'Your browser does not support, copy manually',
    },
    customerBeatsCompilation: {
        startText: 'Collect a selection of your favorite anonymous beats',
        beatsCompilationText: 'Beats in a compilation:',
        noBeats: 'No new bits, try again later',
        countBeatsCompilation: 'Count of bits in compilation',
        thanksAndPublished:
            'Thank you! Your collection of bits will be published.',
        nextCompilation:
            'The ability to create a new compilation will be opened:',
        notExpertMessage:
            'You are not an expert or your expert status is suspended.',
    },
    customerBeatsPublication: {
        publicationNow: 'Publish Now',
        deletePopUpDialogMessage:
            'Are you sure you want to cancel publishing as scheduled?',
        tableTitle: 'Unpublished bits',
        noBeatsForPublished: 'No bits to publish',
        notPublished: 'Not published',
        waitPublished: 'Expects',
        edit: 'Edit',
        selectDateAndTime: 'Select date and time',
        requireField: 'Required field',
        cancelPublication: 'Cancel publication',
        invalidDateMessage: 'Date cannot be less than current date',
        time: 'Time',
        date: 'Date',
        tableColumns: {
            beatTitle: 'Beat title',
            publishedDate: 'Published on',
            status: 'Status',
            error: 'Error',
            management: 'Management',
        },
    },
    statisticsTab: ['ALL', 'FREE', 'EXPERT', 'PREMIUM'],
    statusExpert: ['Active', 'Paused', 'Disabled'],
    statusShop: ['New', 'Active', 'Paused', 'Deleted'],
    templatesType: {
        0: 'Subscription End Date Notification',
        1: 'Cashback notification',
    } as ISourceType,
    promotionsType: {
        0: 'Player',
        1: 'Main Page - "In the spotlight" block',
        2: 'Catalog Page - "Pay attention" block',
        3: 'Full Screen Popup',
        4: 'Catalog Page - top 3',
        5: 'Catalog Page - search (genre, mood, tags)',
        100: 'Player',
        101: 'Main Page',
        102: 'Catalog Page',
        103: 'Catalog First Positions',
    },
    subscriptionType: {
        20: 'Free',
        100: 'Premium',
        9999: 'Expert',
    },
    subscriptionStatus: {
        0: 'Active',
        1: 'Expired',
    },
    sourceType: {
        1: 'Producer page',
    } as ISourceType,
    pagination: {
        lines: 'Lines',
        from: 'from',
        firstTooltip: 'First',
        previousTooltip: 'Previous',
        nextTooltip: 'Next',
        lastTooltip: 'Last',
        perPage: 'per Page',
    },
    customerBeatsPromotion: {
        selectBeatForPromo: 'Select a beat for advertising',
        edit: 'Edit',
        beatsPromoNow: 'Beat promo:',
        selectBeat: 'Select Beat',
        tableTitle: 'Promotions',
        tableNoPromoMessage: 'No promotions',
        showStatistics: 'Show statistics',
        statistics: 'Statistics',
        countShows: 'Listens:',
        tableColumns: {
            beat: 'Beat Name',
            promoType: 'AD Option',
            reservedDate: 'Booking Date',
            viewedDate: 'Show Date',
            management: 'Action',
        },
        selectAdOption: 'Select AD option',
        infoAdvertising: 'Info Advertising',
        advertisingBeatsInfo: 'Advertising beats info',
        cost: 'Cost',
        coins: 'coins',
    },
    customerBeatSubscribers: {
        tableTitle: 'Your subscribers',
        noSubscribers: 'No subscribers',
        pageSubscribed: 'Choose Page Subscription',
        placeholderSelect: 'Subscribers',
        selectPeriod: 'Period select',
        deletePopUpDialogMessage:
            'Are you sure you want to delete the Subscriber?',
        tableColumns: {
            subscriptionDate: 'Subscription Date',
            email: 'Email',
            name: 'Nickname',
            pageSubscription: 'Subscription Page',
        },
        period: {
            start: 'From',
            end: 'To',
        },
    },
    navigation: {
        beatsOfPublication: 'Beats publication',
        beatsPromo: 'Advertising beats',
        mySubscribers: 'Followers',
        beatsCompilation: 'Compilation Beats',
        promoStats: 'Promotions statistics',
        experts: 'Experts',
        mailingSubscription: 'Mailing on subscriptions',
        admin: 'Administration',
        emailTemplates: 'Email templates',
        shop: 'My Shop',
        coupons: 'My coupons',
        adminShops: 'Shops',
        adminCoupons: 'Coupons',
        adminPayments: 'Withdrawal',
        contracts: 'Licenses and Contracts',
        orders: 'Orders',
        beats: 'Beats',
        settings: 'Beatmaker settings',
        faq: 'FAQ',
        chatVK: 'Chat VK',
        cashback: 'Cashback',
        myBeats: 'My beats',
        beatEdit: 'Beat edit',
        templateEditor: 'Edit template',
        myProfile: 'My profile',
        mySales: 'My sales',
        myCoins: 'Coins',
        discounts: 'Discounts',
        producerPage: 'Producer Page',
        widgets: 'Widgets',
        freeBeats: 'My free beats',
        archive: 'Archive',
    },
    calendar: {
        htmlToltip: {
            player: '- beat (maximum 5 beats per day) is fixed in the player and when the user visits the site automatically starts playing (from all reserved bits the one with the least auditions will always be asked).',
            mainPage:
                '- beat is fixed on the main page of the site in the IN THE CENTER OF ATTENTION block.',
            catalog: '-beat is attached at the top of the BEAT DIRECTORY page.',
            PromoInTheChart:
                '- beat will be shown at one of the first three positions on the chart, on the BEAT CATALOG page.',
            searchInCatalog:
                '- beats will be displayed in the catalog only when searching, every 6th position, in case one or more search filters matched the genre, mood or tags of the beat.',
        },
        more: 'More',
        selectPromoType: 'Choose Place for ads',
        selectDate: 'Select of Date',
        promoType: 'Promotion type',
        price: 'Price',
        coins: 'coins',
        totalSlots: 'Free Lots:',
        busySlots: 'Busy slots',
        busyUserSlots: 'Your Lots:',
    },
    adminBeatsPromotionTable: {
        beatmaker: 'Beatmaker',
        beatTitle: 'Beat title',
        reservedDate: 'Reserved date',
        emptyDataSourceMessage: 'No promo',
        promoDate: 'Promo date',
    },
    adminExpertsCompilation: {
        tableColumns: {
            beatmakerId: 'Beatmaker ID',
            name: 'Name',
            status: 'Status',
            lastCompilationDate: 'Last compilation date',
        },
        loading: 'Loading...',
        addExpert: 'Add expert',
        fieldIsRequired: 'Field is required',
        description: 'Description',
        uploadPhoto: 'Upload photo',
        changeArtWork: 'Change cover',
        edit: 'Edit',
        emptyDataSourceMessage: 'No items',
    },
    adminSubscription: {
        tableColumns: {
            beatmaker: 'Beatmaker',
            email: 'E-mail',
            subscriptionType: 'Subscription type',
            status: 'Status',
            expirationDate: 'Expiration date',
            lastSendingDate: 'Last sending date',
            nextSendingDate: 'Next sending date',
            sendingDate: 'Sending date',
            message: 'Message',
            isViewed: 'Viewed',
            isRedirect: 'Redirect',
        },
        emptyDataSourceMessage: 'No subscriptions',
        showMailing: 'Show mailing',
        subscriptions: 'Subscriptions',
        noMailing: 'No mailing',
        all: 'ALL',
        active: 'ACTIVE',
        expired: 'EXPIRED',
    },
    adminEditTemplate: {
        tableColumns: {
            name: 'Name',
            templateKey: 'Template key',
            createDate: 'Created date',
            changeDate: 'Changed date',
            redactor: 'Redactor',
            modelProperty: 'JSON example',
        },
        emptyDataSourceMessage: 'No template',
        templateTitle: 'Template title',
        paramsToTemplate: 'Available variables for Template',
        result: 'Result',
        notSavedMessage: 'There is data not saved in the template',
        closePopUpMessage: 'To keep the made changes?',
    },
    license: {
        tableColumns: {
            title: 'License Name',
            price: 'Beat cost (Default)',
            createDate: 'Creation Date',
            contract: 'Contract',
            action: 'Action',
        },
        tableTitle: 'My licenses',
        licenseDetails: {
            general: 'General information',
            licenseNameEng: 'License Name',
            licenseCostEng: 'License cost',
            licenseNameRus: 'License Name (Russian)',
            licenseCostRus: 'License cost',
            licenseOptions: 'License options',
        },
        contract: {
            contract: 'Contract',
            variables: 'Variables available for the contract',
        },
        deletePopUp: {
            title: 'Delete',
            desc: 'Do you really want to delete license:',
        },
    },
    shopCoupon: {
        tableTitle: 'Coupons',
        searchText: 'Search',
        deletePopUpTitle: 'Delete coupon',
        deleteDescription: 'Are you sure?',
        all: 'All',
        edit: 'Edit',
        checkBoxPopUpText: 'Send a coupon to the store for review',
        checkBoxPopUpRefactorText: 'Send to Refine Details',
        tableColumns: {
            approve: 'Approve',
            coupon: 'Coupon',
            addedDate: 'Creation Date',
            status: 'Status',
            action: 'Action',
            price: 'Cost',
            productTitle: 'Product Title',
            purchaseDate: 'Purchase Date',
            currency: 'Currency',
            shop: 'Shop',
            shopNote: 'Note from shop',
            feedBack: 'Feedback',
            rating: 'Rating',
        },
        currency: {
            0: 'RUB',
            1: 'USD',
        },
        popupEdit: {
            title: 'Edit coupon',
            description: 'This coupon gives you a 15% discount.',
            storeComment: 'Store comment',
            productName: 'Product Name',
            currency: 'Currency',
            cost: 'Cost',
            fieldIsRequired: 'Field is required',
            purchaseDate: 'Purchase date',
            yourAssessment: 'Your Assessment',
            feedback: 'Feedback',
        },
    },
    managerAdmin: {
        tableTitle: 'Shops',
        editPopUpTitle: 'Edit shop',
        tableColumns: {
            title: 'Title',
            status: 'Status',
            feePercent: 'Percent',
            city: 'City',
            country: 'Country',
            description: 'Description',
            allWorldDelivery: 'All World Delivery',
            shortDescription: 'Short Description',
            userId: 'Manager ID',
            image: 'Shop image',
            contacts: {
                phone: 'Phone',
                email: 'Email',
                website: 'Web site',
                address: 'Address',
            },
        },
    },

    paymentsAdmin: {
        tableTitle: 'Payments',
        closePayment: 'Close payment',
        placeholder: 'Input ID and press Enter',
        tableColumns: {
            beatmakerName: 'Beatmaker',
            sumBeatsPrice: 'Sales',
            sumForCash: 'Amount to be paid',
            fromWallet: 'From wallet',
            toWallet: 'To wallet',
            validation: 'Validation',
        },
        detailTableColumns: {
            beatmakerName: 'Name',
            beatTitle: 'Beat title',
            licenseTitle: 'License',
            beatmakerMoney: 'Price',
            discountSize: 'Discount %',
            paymentSource: 'Page',
            money: 'Sum fo pay',
            cashback: 'Cashback paid',
            percent: 'Percent',
            paymentSystem: 'Payment system',
            info: 'Info',
            moneyWithdrawalId: 'MWD_ID',
            profile: 'Profile',
        },
        wallet: {
            title: 'Wallets',
        },
        validation: {
            attention: 'Attention',
            validationTooltip: 'Info',
            title: 'Validation',
            shazam: 'Shazam',
            contacts: 'Contacts',
            passport: 'Passport',
            videoScreens: 'Video screens',
            description: 'Description',
            verified: 'Verified',
        },
        allMoney: 'Total amount of funds not withdrawn',
    },
    contracts: {
        helpText: 'Contract Setup Instructions',
        variables: 'Available Variables for Contract',
        informationText:
            '(!) Variables must be specified in to correctly display data in the generated ' +
            'contract the text of the contract in double bracketsExample:',
        alertText:
            'Are you sure you want to load the default template, the current template will be replaced?',
        alertDialogTitle: 'Attention',
        settings: {
            CONTRACT_DATE: 'Beat sales date',
            CLIENT_NAME: 'Name of buyer',
            BEAT_TITLE: 'Beat name',
            BEAT_PRICE: 'Beat price',
            PASSPORT_CLIENT_DATA: 'Buyer passport data',
            ADDRESS_CLIENT_DATA: 'Address',
            LICENSE_NAME: 'License name',
            CURRENCY: 'Currency',
        },
    },
    orders: {
        tableColumns: {
            id: 'Order ID',
            beatmakerId: 'Beatmaker ID',
            fromPaymentSystem: 'From Payment System',
            date: 'Date',
            managerId: 'Manager ID',
            paymentSystem: 'Payment System',
            walletNumber: 'Wallet Number',
        },
    },
    cashbacks: {
        tableColumns: {
            operation: 'Operation',
            amount: 'Amount',
            currency: 'Currency',
            status: 'Status',
            expirationDate: 'Valid Until',
            paymentDate: 'Date of Entry',
            payment: 'Payment',
            income: 'Income',
        },
        helpText: 'Amount cashback',
        statuses: {
            notUser: 'Not used',
            partiallyOverdue: 'Partially Overdue',
            isAvailable: 'Is available',
            used: 'Used',
        },
        tooltip:
            ' Cashback system gives an additional discount for regular buyers, for the author of the beat the discount difference will be returned by coins with an increase of 30%',
    },
    myBeats: {
        beatSettings: {
            general: 'General information',
            trackDetails: 'Track details',
            license: 'Licenses and cost',
            files: 'Files for licenses',
            audiofileForStreaming: 'Audiofile for streaming',
            supportArt: 'Supports: JPG, PNG, TIF',
            changeCover: 'Change cover',
            licenseFiles: 'License files:',
            changeCost: 'Change cost',
            cost: 'Cost',
            addTag: '+ Add',
        },
        noLicenseMessage: 'Select a license',
        showArchive: 'Show beats in archive',
        publicationsTextPopUp: 'Do you really want to publish beat',
        fileProcessing: 'Processing file, wait for finish',
        publish: 'Publish',
        changePrice: 'Change price',
        licenseAndPrice: {
            title: 'License and price',
            message:
                'Below is a list of your licenses. Check the ones available for the download bit and specify the purchase price of the tool for the marked license.',
        },
        filesForPlayer: {
            demoDownloading: 'Allow beat download',
            title: 'File for player',
            message:
                'Download the file that will be played on your profile page, in the chart or bit directory of our website, as well as on your personal website through the embedded widget. Remember to protect this bit version with your tag.',
        },
        filesForSale: {
            title: 'Files for sale',
            message:
                'Download the files that your customer will receive after purchasing your tool. Map downloaded files to license types. You can assign the same file to different licenses.',
        },
        deleteBeat: 'Delete the beat',
        removeFromCatalog:
            'Are you sure you want to remove the beat from the catalog',
        removeBeat: 'Do you really want to delete the beat permanently?',
        restoreBeat: 'Restore the beat',
        restoreBeatInCatalog: 'Restore the beat in the catalog?',
        general: 'General information',
        selling: 'Selling Preferences',
        details: 'Track details',
        popupUploadsMessage: {
            drag: 'Release files to download',
            noDrag: 'Drag and drop, browse or link your files',
            urlFileCloud: 'Or link to a file in the cloud',
            selectFileButton: 'Select files',
        },
        tableColumns: {
            title: 'Name',
            chart: 'Position',
            downloads: 'Downloads',
            listening: 'Listens',
            action: '',
        },
        addBeat: 'Upload beat',
        beatWithTag:
            'Make sure you download .mp3 with the tag, the file is used for playback on the site and can be downloaded or recorded.',
        addTags: '+ Add tag',
        addAnotherSample: 'Add another Sample/Loop',
        sampleSource: 'URL',
        sampleTitle: 'Title',
        editLicense: 'Edit list',
        formsField: {
            beatTitle: 'Beat title',
            tags: 'Tags',
            trackType: 'Track type',
            releaseDate: 'Release date',
            urlOfYourTrack: 'Link',
            audioFilesForDownload: 'Audio files for download',
            audioFileForStreaming: 'Audio file for streaming (optional)',
            mp3: 'Un-Tagget .MP3',
            updateFileMp3: 'Update MP3',
            uploadFile: 'Upload file',
            uploadFileDescription: 'Upload only .mp3 files with an audio tag',
            wav: 'Un-Tagget .WAV',
            trackout: 'TRACKOUT .ZIP',
            mp3WithTag: 'Tagget Beat .MP3',
            notFoSale: 'Not for sale',
            private: 'Private',
            isExclusive: 'Enable make offers only for exclusive license',
            artwork: 'Upload Artwork',
            freeDownloadOption: {
                title: 'Free download options',
                no: 'No',
                yesWithTags: 'Yes, with voice tag (MP3 Version)',
                yesUntagged: 'Yes, untagged (MP3 Version - 320 kbps)',
                allowAnonymousDownload: 'Allow Anonymous Guest Downloads',
            },
            loops: 'I used 3rd party loops or/and samples',
            genre: 'Genre',
            mood: 'Mood',
            description: 'Description',
            bmp: 'BPM',
            creationMethod: {
                title: 'Creation method',
                composing: 'Author essay',
                sampling: 'Sampling',
            },
            errors: {
                minBpmError: `cannot be less ${BeatsConstants.MIN_BPM}`,
                maxBpmError: `cannot be more ${BeatsConstants.MAX_BPM}`,
                maxLengthError: (maxLength: number) =>
                    `Maximum length ${maxLength} characters`,
                numberError: 'Must be a number',
            },
        },
        editBeat: 'Edit beat',
        noBeats:
            'You have no uploaded beats, click upload and add your first beat',
        noArchiveBeats: 'You have no archive bits',
        deletePopUpDialogMessage: {
            title: 'Delete file',
        },
    },
    licenses: {
        form: {
            title: 'License Name',
            titleEn: 'License name in English',
            removeBeatFromSale: 'Remove beat from sale',
            recommended: 'Recommended',
            requireClientPassport: 'Require Client Address',
            purchaseIsNotAvailable: 'Purchase is not available',
        },
        helpTextForCheckbox: {
            removeBeatFromSale:
                'If there is a check mark, then when the buyer pays for this type of license, the bit will be automatically withdrawn from sale.',
            recommended:
                'If there is a tick mark, then this license will be visually highlighted as recommended for purchase on the bit page, as well as on the "Producer Page"',
            requireClientPassport:
                "If you also need the buyer's passport data in addition to the name for your contract variant, check the box and we will collect this data from the client",
            purchaseIsNotAvailable:
                '(feature is not working at this time) If you check the box, the buyer will not be able to pay for this type of license. Instead, he will be asked to contact you to discuss details.',
            priceInRubles:
                'The price is assigned automatically to each beat. You can price an individual beat on the beat edit page.',
            priceInDollars:
                'License settings in English are used in English versions of the producer page, widget and BEATMAKER.TV website (BEATMAKERS.TV)',
            defaultTrackPrice: 'Default track price',
        },
        licenseOptions: {
            title: 'License options',
            titleRu: 'for russian',
            titleEn: 'for english',
        },
    },
    mySales: {
        saleDetails: {
            paid: 'Paid',
            status: 'STATUS',
            salesInfo: 'Sales information',
            buyer: 'Buyer',
            emailBuyer: 'Email Buyer',
            fullName: 'Full Name',
            orderSummary: 'Order summary',
            nameBeat: 'Name beat',
            contract: 'Contract',
            license: 'License',
            saleId: 'Sale ID',
            totalGross: 'Total Gross',
            comissionSite: 'Comission Site',
            cashback: 'Paid By Cashback',
            pay: 'Pay via',
            total: 'Total',
        },

        rootPayment: {
            fields: {
                id: 'ID',
                beatName: 'Beat Name',
                dateTime: 'Date & Time',
                total: 'Total',
                money: 'Sum',
                amount: 'Amount',
                withdrawalDate: 'Withdrawal Date',
                actions: 'Action',
                cashback: 'Closed by cashback',
                serviceFeeAmount: 'Service Fee',
                beatmakerPureMoney: 'Total',
                paidDate: 'Paid Date',
                changeToCoins: 'Exchange for coins',
            },
        },
        orderSummary: {
            title: 'Sale',
            fields: {
                id: 'id',
                dateTime: 'Date & Time',
                paymentSystem: 'Pay through',
                paymentSource: 'Service',
            },
        },
        orderPayment: {
            title: 'Transfer',
            fields: {
                money: 'Sum',
                coupon: 'Discount coupon',
                cashback: 'Closed by cashback',
                percent: 'Commission',
                conclusion: 'Total',
                status: 'Status',
            },
            isDirectWallet: 'Paid for personal wallet',
            changeToCoins: 'Exchange for coins',
            moneyWithdrawal: 'Money withdrawn',
        },
        orderCustomer: {
            title: 'Buyer',
            fields: {
                name: 'Nickname',
                fullName: 'Full name',
                email: 'E-mail',
            },
        },
        orderItems: {
            title: 'Beats',
            fields: {
                license: 'License',
                saveContract: 'Contract',
                coupon: 'Sales',
            },
        },
        statistics: {
            fields: {
                itemsSold: 'Sold Beats',
                gross: 'Paid',
                discount: 'Discount',
                total: 'Amount received',
                conclusion: 'Withdraw amounts',
                serviceFee: 'Site comission',
            },
            conclusionTitle: 'Conditions for output',
            conclusionText:
                'To withdraw money that went to the wallets of the site, write to personal messages of the support account https://beatmaker.tv/support.',
            condition: 'Conditions for output:',
            condition1: '- On the balance more than 1000р.',
            condition2: '- At least 3 days have passed since the sale.',
            condition3: '- You have at least one wallet to withdraw.',
            condition4:
                '- For accounts that have a premium subscription or expert connected, it is possible to set up direct payment acceptance for personal wallets YooMoney and Paypal.',
            wallets: 'My wallets',
            directWallets: 'Personal wallet',
        },
        emailsBuyers: 'Download Email Buyers',
        confirmDialog: {
            title: 'Exchange of money for coins',
            text: 'Coins will be bought for the entire amount. Confirm?',
        },
        deletePopup: {
            title: 'Do you really want to delete license',
        },
    },
    settings: {
        cashback: 'Participation in the program "Cashback"',
        tabs: {
            cashback: 'Cashback',
            wallets: 'Wallets Settings',
            profile: 'Settings Profile',
            notifications: 'Notifications',
            security: 'Account and Security',
            paidBeats: 'Paid',
            freeBeats: 'Free',
            archivedBeats: 'Archive',
        },
        wallets: {
            tinkoff: {
                confirmRemoveCard: 'Are you sure you want to delete?',
                removeCard: 'Remove card',
                bindNewCard: 'Link a new card',
                cards: 'Linking card',
                cardList: 'List of cards',
                cardLinkInfo: 'Link your bank card to start selling beats',
                extra: 'More detailed instructions, as well as information about commissions, you can read',
                steps: {
                    step1: 'Step 1',
                    step2: 'Step 2',
                },
                stepsInstructions: {
                    step1: `Link a card by clicking the "Link New Card" button. You can link up to three cards in total. 
                            Once successfully linked, the card will appear in the list below.`,
                    step2: `Activate the card you want to receive payments on by clicking on it. 
                            Once successfully activated, the indicator to the left of the card number will
                            turn blue. Only one card can be active at a time.`,
                },
                terms: {
                    term1: '1. The commission for the site. For the «Basic» plan, the commission will be 20%. For «Premium», it will be 10% and for «Expert», it will also be 10%. The minimum commission cannot be less than 50p.',
                    term2: '2. Withdrawal of funds. The withdrawal will be made automatically exactly 24 hours later. If a card is not linked, the withdrawal will occur within a couple of hours after linking the card, provided that 24 hours have already passed.',
                    term3: '3. Linking the card. If the Seller has not linked the card within 60 days, the transaction is canceled and the money is returned to the buyer.',
                },
                termsTitle: 'Terms of use of the service',
            },
            paypal: {
                inputTitle: 'Email from Paypal',
                notWalletMessage: 'Wallet not specified',
                paypalActive: 'Activated',
                linkTitle: 'Settings',
                title: 'Binding Instruction',
                subTitle:
                    'The binding procedure itself consists of one simple step:',
                manuals: {
                    1: 'On the Beatmakers.tv side of this page, you need to provide your Paypal wallet number (email)',
                },
                steps: {
                    step1: 'Step 1',
                    step1Desc:
                        'Please enter your Paypal wallet, same email from your Paypal account (may not match the email used to sign in to our beatmakers.tv service)',
                },
            },
            yooMoney: {
                title: 'Привязка кошелька YooMoney',
                manuals: {
                    1: 'Подписку Премиум или Эксперт на аккаунте Beatmaker.tv оплатить подписку можно по ссылке -',
                    2: 'Идентифицированный кошелек YooMoney – все об идентификации -',
                },
                steps: {
                    step1: 'Step 1',
                    step2: 'Step 2',
                    step3: 'Step 3',
                    step4: 'Step 4',
                    step5: 'Step 5',
                    step6: 'Step 6',
                    step7: 'Step 7',
                },
                stepsInstructions: {
                    step1: 'Copy the secret URL',
                    step2: 'Go to the YooMoney website. Link to the page of the settings we need -',
                    step3: 'Paste the key from Beatmaker.tv into the "Site URL" field',
                    step4: 'Enable the "Send HTTP Notifications" feature',
                    step5: "Generating YooMoney's secret key.",
                    step6: 'Paste your secret key and wallet number below, and then be sure to click save.',
                    step7: 'We activate the wallet.',
                },
                requirements:
                    'To connect your YooMoney personal wallet, you need to have 2 things:',
                secretKey: 'Secret key',
                secretURL: 'Secret URL',
                identification: 'Identification',
                walletActivate: 'Wallet activation',
                testingPayment:
                    'To activate your wallet, follow the link and make a test payment.',
                activateDescription:
                    'The site will redirect you to the page for selecting a payment method, choose to pay using a card (don’t forget to log out on the YooMoney website before paying) and pay 10 rubles. If everything is done correctly, the money will be sent to the specified wallet and the wallet status will change to “Activated”.',
            },
        },
    },
    myCoins: {
        coins: 'Coins',
        buyCoinsInputTitle: 'Coins count:',
        bonusCoins: 'Coins bonus count:',
        bonusItemTitle: 'Buying one more coins, you get a bonus',
        bonusItems: {
            500: 'from 500 coins - bonus size 100 coins',
            300: 'from 300 coins - bonus size 40 coins',
            200: 'from 200 coins - bonus size 15 coins',
            100: 'from 100 coins - bonus size 10 coins',
        },
        tableTitle: 'Coin charge history',
        tableColumns: {
            operation: 'Operation',
            description: 'Description',
            amount: 'Amount',
            balance: 'Balance',
            date: 'Date',
            payment: 'Payment',
            income: 'Income',
        },
        coinsBalance: 'Coins balance',
        coinsSale: 'now! discount on coins',
    },
    beatEdit: {
        filesTableColumn: {
            fileName: 'File name',
            createdDate: 'Creation Date',
            fileSize: 'File Size',
            action: 'Action',
        },
    },
    notifications: {
        subscriptions: {
            0: 'Private messages',
            1: 'Comments to my applications',
            2: 'Comments to my beat',
            3: 'Comments to my articles',
            100: 'Breaking news site',
        },
        notifyTitle: {
            0: 'Never',
            2: 'Every day',
            5: 'Immediately',
        },
    },
    discounts: {
        coupons: 'Coupons',
        discounts: 'Group Discounts',
        tabs: {
            coupons: 'Coupons',
            groupDiscounts: 'Group Discounts',
        },
        tableColumn: {
            coupon: 'Coupon',
            status: 'Status',
            discounts: 'Discounts %',
            licenses: 'Licenses',
            dueDate: 'Due Date',
            type: 'Type',
            purchasedBeats: 'Purchased beats',
            action: 'Action',
            name: 'Name',
        },
        deleteDialog: {
            title: 'Delete Group Discount',
            description: 'Are you sure?',
        },
        allLicense: 'All licenses',
        editPopUp: {
            title: 'Edit Discount',
            status: 'Status',
            license: 'License',
            due: 'Due Date',
            from: 'Volid from',
            discount: 'Discount, %',
            purchased: 'Purchased beats',
            amountBeats: 'Amount beats',
            code: 'Code coupon',
        },
        groupDiscounts: {
            buy: 'Buy',
            tracks: 'Tracks',
            get: 'Get',
            free: 'Free',
            popUp: {
                title: 'Add New Discount',
                countCart: 'Count of beat in cart',
                countFree: 'Count of free track',
                license: 'License',
            },
        },
    },
    beatmakerSettings: {
        fields: {
            myId: 'My ID',
            nickname: 'Nickname',
            country: 'Choose Country',
            instagram: 'Instagram',
            facebook: 'Facebook',
            twitch: 'Twitch',
            twitter: 'Twitter',
            email: 'Email',
            soundcloud: 'Soundcloud',
            whatsApp: 'WhatsApp',
            youtube: 'Youtube',
            vk: 'VK',
            telegram: 'Telegram',
            viber: 'Viber',
            software: 'Software',
            clients: 'Clients',
            description: 'Description',
            social: 'Social Network',
            secInfo: 'Secondary Information',
            generalInfo: 'General Information',
            uniqueUrlName: 'My page address',
        },
        wallets: {
            form: 'Fill Form Below',
            emailToPaypal: 'Email Paypal*',
        },
        nicknameTooltip: {
            text: 'Nickname changes within 24 hours',
        },
    },
    producerPage: {
        title: 'Producer Page',
        goToProducerPage: ' Go to producer page settings',
        customizeInfo:
            'Customize the page to your liking. Change the colors of an individual component. Just Do It!',
        domainName: 'Domain name',
        subDomainName: 'Subdomain name',
        descriptionDomainName:
            'Click here to learn how to setup your Domain Name',
        descriptionSubDomainName:
            'Click here to learn how to setup your Domain Name',
    },
    errorMessages: {
        emailNotValid: 'Email is not valid',
    },
    widget: {
        mainPageTitle: 'Widget',
        settingsBlockTitle: 'Widget settings',
        analyticsBlockTitle: 'Analytics',
        backToMainPage: 'Back to Widget',
        name: 'List of widgets',
        notFound: 'No widget has been added yet',
        fields: {
            title: 'Widget Name',
            width: 'Widget width (pixels) ',
            height: 'Widget height (pixels)',
            isWidthResponsive: 'Adapt the widget to the width',
            lang: 'Language widget',
            selectPlayingBeatTitle: 'Select a beat to play automatically',
            automaticallyProduceBeat: 'Automatically produce beat on load',
            iframe: 'Copy and paste this in to your web page',
            url: 'URL Widget',
            tracklistSelect: 'Select a tracklist',
            selectAllBeats: 'Load all beats from catalog',
            selectCustomBeats: 'Select beats to load ',
        },
        popup: {
            addBeatsTitle: 'Add beats to widget',
            deleteTitle: 'Delete',
            deleteMessage: 'Do you really want to delete widget',
            publishedDate: 'Published on',
            chooseAll: 'Choose all',
        },
    },
    formMessages: {
        requiredTitle: "The field mustn't be empty",
        minTitle: 'Length must be more than 3 characters',
        maxTitle: 'The length of the title should not exceed 50 characters',
        minWidgetHeight: 'Min height 100 px',
        maxWidgetHeight: 'Max height 5000 px',
        minWidgetWidth: 'Min width 100 px',
        maxWidgetWidth: 'Max width 10000 px',
    },
}
