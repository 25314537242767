import React, { FC, useEffect, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { EditingShopType } from '../../../BLL/shop-types'
import { Redirect } from 'react-router-dom'
import { useHistory, useParams } from 'react-router'
import { useSelector } from 'react-redux'
import { AppState } from '../../../BLL/Store'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { EditingEntityStatuses, RequestStatuses } from '../../../BLL/AppReducer'
import { useTranslation } from 'react-i18next'
import { LanguageType } from '../../../translations/ru/common'
import EditFormShop from './EditShopForm'
import { useForm } from 'react-hook-form'
import { useEditingEntityStatus } from '../../common/hooks/useEditingEntityStatus'
import { RenderUploadPhoto } from '../../common/RenderUploadPhoto'
import { AppSettings } from 'settings/appSettings'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minWidth: '100%',
            paddingBottom: 20,
        },
        input: {
            display: 'none',
        },
        wrapper: {
            margin: 20,
        },
        wrapperPhoto: {
            margin: 20,
        },
    })
)

type OwnProps = {
    handleGetShopById: (shopId: number) => void
    handleUpdateShop: (shop: EditingShopType) => void
    clear: () => void
    requestStatus: RequestStatuses
    cancelRedirectUrl: string
}

const ShopEditingPopUp: FC<OwnProps> = ({
    handleGetShopById,
    requestStatus,
    cancelRedirectUrl,
    clear,
    handleUpdateShop,
}) => {
    const classes = useStyles()
    const history = useHistory()
    const { id: shopId } = useParams<{ id: string }>()
    const shop = useSelector(
        (state: AppState) => state.managerShops.editingShop
    )
    const { i18n } = useTranslation()
    const { helpMessage, buttons, managerAdmin } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType
    const { handleSubmit, register } = useForm<EditingShopType>()
    const { editingEntityStatus } = useEditingEntityStatus(shopId)
    const [image, setImage] = useState<string>('')

    const onSubmit = handleSubmit((values: EditingShopType) => {
        shop &&
            handleUpdateShop({
                ...values,
                status: shop.status,
                id: shop.id,
                image,
            })
    })

    useEffect(() => {
        if (shopId) {
            handleGetShopById(+shopId)
        }
        return () => clear()
    }, [shopId])

    if (editingEntityStatus === EditingEntityStatuses.Success) {
        return <Redirect to={cancelRedirectUrl} />
    }

    return (
        <div>
            <Dialog open={true} aria-labelledby="form-dialog-title">
                {shop ? (
                    <form onSubmit={onSubmit}>
                        <DialogTitle id="form-dialog-title">
                            {managerAdmin.editPopUpTitle}
                        </DialogTitle>
                        <div className={classes.root}>
                            <EditFormShop shop={shop} register={register} />
                            <div className={classes.wrapperPhoto}>
                                <RenderUploadPhoto
                                    imageFile={image}
                                    setImageFile={setImage}
                                    currentItem={shop}
                                />
                            </div>
                        </div>
                        <DialogActions>
                            <Button
                                style={{
                                    background: AppSettings.api.getBaseColor(),
                                    color: 'black',
                                    opacity:
                                        requestStatus ===
                                        RequestStatuses.InProgress
                                            ? 0.3
                                            : 0.9,
                                    fontWeight: 600,
                                }}
                                onClick={onSubmit}
                                disabled={
                                    requestStatus === RequestStatuses.InProgress
                                }
                            >
                                {requestStatus === RequestStatuses.InProgress
                                    ? helpMessage.loading
                                    : buttons.SAVE}
                            </Button>

                            <Button
                                style={{ color: 'black' }}
                                disabled={
                                    requestStatus === RequestStatuses.InProgress
                                }
                                onClick={() => history.push(cancelRedirectUrl)}
                            >
                                {buttons.CANCEL}
                            </Button>
                        </DialogActions>
                    </form>
                ) : (
                    <div style={{ padding: 20 }}>{helpMessage.loading}</div>
                )}
            </Dialog>
        </div>
    )
}

export default ShopEditingPopUp
