import React, { useState, FC } from 'react'
import classes from './AdvertisingBeat.module.scss'
import stat from '../../../../assets/icons/statistics.svg'
import statActive from '../../../../assets/icons/statistics2.svg'
import edit from '../../../../assets/icons/edit.svg'
import { PromoCoreType, PromoType } from '../../../../../BLL/types'
import { LanguageType } from '../../../../../translations/ru/common'
import { getLocaleDateString } from '../../../../utils/util-types'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { CustomerRM } from '../../../../routers/RouterManager'
import Tooltip from '@material-ui/core/Tooltip'

type PropsType = {
    promotion: PromoType
}
export const AdvertisingBeat: FC<PropsType> = ({ promotion }) => {
    const { i18n } = useTranslation()
    const { customerBeatsPromotion, promotionsType } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType
    const dateNow = getLocaleDateString(i18n.language, new Date())
    const [collapsed, setCollapsed] = useState(false)
    return (
        <div className={classes.AdvertisingBeat}>
            <div
                className={
                    !collapsed
                        ? classes.info
                        : `${classes.info} ${classes.disable}`
                }
            >
                <div className={classes.item}>
                    <span className={classes.title}>
                        {customerBeatsPromotion.tableColumns.promoType}
                    </span>
                    <span className={classes.value}>
                        {
                            promotionsType[
                                promotion.promoType as keyof PromoCoreType
                            ]
                        }
                    </span>
                </div>
                <div className={classes.item}>
                    <span className={classes.title}>
                        {customerBeatsPromotion.tableColumns.beat}
                    </span>
                    <span className={classes.trackName}>
                        {promotion.beat.beatTitle}
                    </span>
                </div>
                <div className={classes.item}>
                    <span className={classes.title}>
                        {customerBeatsPromotion.tableColumns.reservedDate}
                    </span>
                    <span className={classes.value}>
                        {promotion.addedDate &&
                            getLocaleDateString(
                                i18n.language,
                                promotion.addedDate
                            )}
                    </span>
                </div>
                <div className={classes.item}>
                    <span className={classes.title}>
                        {customerBeatsPromotion.tableColumns.viewedDate}
                    </span>
                    <span className={classes.value}>
                        {promotion.date &&
                            getLocaleDateString(i18n.language, promotion.date)}
                    </span>
                </div>
            </div>
            {getLocaleDateString(i18n.language, promotion.date) >= dateNow &&
            promotion.promoType !== 3 ? (
                <div className={classes.btnBox}>
                    <NavLink
                        to={CustomerRM.promotions.getUrl.edit(promotion._id)}
                    >
                        <Tooltip title={customerBeatsPromotion.edit}>
                            <img src={edit} alt={'edit'} />
                        </Tooltip>
                    </NavLink>
                </div>
            ) : (
                <div
                    className={classes.btnBox}
                    onClick={() => setCollapsed(!collapsed)}
                >
                    {collapsed ? (
                        <div className={classes.active}>
                            <span>Statistics:</span>
                            <img src={stat} alt={'stats'} />
                        </div>
                    ) : (
                        <div className={classes.hidden}>
                            <span>Statistics:</span>
                            <img src={statActive} alt={'statHide'} />
                        </div>
                    )}
                </div>
            )}

            {collapsed && (
                <div className={classes.hideContent}>
                    <div className={classes.item}>
                        <span className={classes.title}>
                            {customerBeatsPromotion.countShows}{' '}
                        </span>
                        <span className={classes.value}>
                            {' '}
                            {promotion.stats.shows}
                        </span>
                    </div>
                </div>
            )}
        </div>
    )
}
