import React from 'react'
import { CustomSelect } from 'UI/common/elements/selects/CustomSelect'
import { useTranslation } from 'react-i18next'
import LeftArrowIcon from 'UI/assets/icons/arrow-left-slide'
import RightArrowIcon from 'UI/assets/icons/arrow-right-slide'
import { LanguageType } from 'translations/ru/common'
import { usePagination } from 'UI/common/elements/pagination/usePagination'
import s from 'UI/common/elements/pagination/pagination.module.scss'
import { PaginationButtons } from 'UI/common/elements/pagination/PaginationButtons'

type Props = {
    currentPage?: number
    totalCount: number
    pageSize?: number
    onChangePage: (pageNumber: number) => void
    onChangeRowsPerPage?: (pageSize: number) => void
}

export const Pagination = ({
    currentPage = 1,
    pageSize = 20,
    totalCount,
    onChangePage,
    onChangeRowsPerPage,
}: Props) => {
    const { i18n } = useTranslation()
    const { pagination } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    const pagesCount = Math.ceil(totalCount / pageSize)
    const {
        handleMainPageClicked,
        onNextPage,
        onPreviousPage,
        isFirstPage,
        isLastPage,
        paginationRange,
    } = usePagination({
        pagesCount,
        currentPage,
        onChangePage,
    })

    return (
        <div className={`${s.wrapperPagination}`}>
            <button
                className={s.pagination}
                onClick={onPreviousPage}
                disabled={isFirstPage}
            >
                <LeftArrowIcon width={10} height={10} />
            </button>
            <PaginationButtons
                paginationRange={paginationRange}
                onClick={handleMainPageClicked}
                currentPage={currentPage}
            />
            <button
                className={s.pagination}
                onClick={onNextPage}
                disabled={isLastPage}
            >
                <RightArrowIcon width={10} height={10} />
            </button>
            <div className={s.wrapperSelect}>
                <div>{pagination.lines}</div>
                <CustomSelect
                    items={[20, 30, 50, 100].map((v) => ({
                        value: v,
                        title: v.toString(),
                    }))}
                    onChange={(e) => {
                        onChangeRowsPerPage &&
                            onChangeRowsPerPage(+e.target.value)
                    }}
                    value={pageSize}
                />
                <div>{pagination.perPage}</div>
            </div>
        </div>
    )
}
