import React, { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'
import { useDispatch } from 'react-redux'
import { fetchedUpdatePaypal } from 'BLL/CustomerWalletsReducer'
import { actions as appActions } from 'BLL/AppReducer'
import { useSelector } from 'UI/common/hooks/redux-hooks'
import classes from 'UI/CustomerAdmin/BeatmakerSettingsNew/wallet-settings/walletSettings.module.scss'
import { InputField } from 'UI/common/elements/textFields/Input'
import { Button } from 'UI/common/elements/buttons/Button'

export const PayPalForm: FC = () => {
    const [emailForPayPal, setEmailForPayPal] = useState('')
    const regexMatchEmail = /^([\w.*-]+@([\w-]+\.)+[\w-]{2,4})?$/
    const { i18n } = useTranslation()
    const { settings, buttons, beatmakerSettings, errorMessages } =
        i18n.getResourceBundle(i18n.language, 'common') as LanguageType
    const dispatch = useDispatch()
    const handleBindPaypal = useCallback(() => {
        if (emailForPayPal.match(regexMatchEmail)) {
            dispatch(fetchedUpdatePaypal(emailForPayPal))
        } else {
            dispatch(
                appActions.showSnackbar('error', errorMessages.emailNotValid)
            )
        }
    }, [emailForPayPal])
    const wallets = useSelector((state) => state.walletsReducer.wallets)
    useEffect(() => {
        if (wallets?.paypalWallet) {
            setEmailForPayPal(wallets.paypalWallet)
        }
    }, [wallets])
    return (
        <div className={classes.walletForm}>
            <h2 className={classes.title}>{beatmakerSettings.wallets.form}</h2>
            <div className={classes.emailForm}>
                <label className={classes.emailLabel}>
                    {settings.wallets.paypal.inputTitle}*
                </label>
                <div className={classes.inputSize}>
                    <InputField
                        type={'email'}
                        value={emailForPayPal}
                        onChange={({ target: { value } }) =>
                            setEmailForPayPal(value)
                        }
                    />
                </div>
            </div>
            <div className={classes.steps}>
                <div className={classes.step}>
                    <p className={classes.stepDescr}>
                        {settings.wallets.paypal.steps.step1Desc}
                    </p>
                </div>
            </div>
            <div className={classes.btnBox}>
                <Button
                    title={buttons.SAVE_CHANGES}
                    type={'button'}
                    onClick={handleBindPaypal}
                />
            </div>
        </div>
    )
}
