import { PaymentTypeBLL } from 'BLL/MySalesReducer'
import { LanguageType } from 'translations/ru/common'
import { BeatTitle } from 'UI/CustomerAdmin/CustomerMySales/beat-title/beat-title'
import { getLocaleDateStringForHours } from 'UI/utils/util-types'
import { CurrencyEnum } from 'DAL/CashbackAPI'
import UsdIcon from 'UI/assets/icons/usd.svg'
import RubIcon from 'UI/assets/icons/rub.svg'
import { NavLink } from 'react-router-dom'
import { CustomerRM } from 'UI/routers/RouterManager'
import { Button as MyButton } from 'UI/common/elements/buttons/Button'
import React from 'react'
import { TableType } from '../types'
import { i18n } from 'i18next'
import s from 'UI/common/table/tableData.module.scss'

export const getMySalesTableDataEng = (
    i18n: i18n
): TableType<PaymentTypeBLL> => {
    const { mySales, buttons } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType
    return [
        {
            title: mySales.rootPayment.fields.id,
            field: 'id',
            render: (payment) => {
                return (
                    <div className={s.getMySalesTableDataEngId}>
                        {payment.id}
                    </div>
                )
            },
        },
        {
            title: mySales.rootPayment.fields.beatName,
            field: 'id',
            render: (payment) => {
                return <BeatTitle payment={payment} />
            },
        },
        {
            title: mySales.rootPayment.fields.dateTime,
            field: 'confirmDate',
            render: (payment) => {
                return (
                    <div className={s.getMySalesTableDataEngDateTime}>
                        {getLocaleDateStringForHours(
                            i18n.language,
                            payment.confirmDate
                        )}
                    </div>
                )
            },
        },
        {
            title: mySales.rootPayment.fields.total,
            field: 'money',
            render: (payment) => {
                return (
                    <div className={s.getMySalesTableDataEngTotal}>
                        <img
                            src={
                                payment.currencyName === CurrencyEnum.usd
                                    ? UsdIcon
                                    : RubIcon
                            }
                            alt={'currency'}
                        />
                        {payment.money}
                    </div>
                )
            },
        },

        {
            title: mySales.rootPayment.fields.withdrawalDate,
            field: 'beatmakerPureMoney',
            render: (payment) => {
                return payment.sales[0].paidDate ? (
                    <div className={s.getMySalesTableDataEngDateTime}>
                        {getLocaleDateStringForHours(
                            i18n.language,
                            payment.sales[0].paidDate
                        )}
                    </div>
                ) : (
                    <div>-</div>
                )
            },
        },
        {
            title: mySales.rootPayment.fields.actions,
            field: 'id',
            render: (payment) => {
                return (
                    <NavLink
                        to={CustomerRM.mySales.getUrl.show(payment.id)}
                        style={{ textDecoration: 'none' }}
                    >
                        <MyButton view={'Details'} title={buttons.DETAILS} />
                    </NavLink>
                )
            },
        },
    ]
}
