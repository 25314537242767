import React from 'react'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'
import { YooMoneyInstruction } from 'UI/CustomerAdmin/BeatmakerSettingsNew/wallet-settings/wallets/yooMoney/YooMoneyInstruction'
import s from 'UI/CustomerAdmin/BeatmakerSettingsNew/wallet-settings/walletSettings.module.scss'

export const YooMoney = () => {
    const { i18n } = useTranslation()
    const { settings, orders, customerBeatSubscribers, buttons } =
        i18n.getResourceBundle(i18n.language, 'common') as LanguageType

    const items = [
        {
            id: 1,
            manual: settings.wallets.yooMoney.manuals['1'],
            action: customerBeatSubscribers.placeholderSelect,
            href: 'https://beatmaker.tv/Default/Payment/MakeSubscription',
        },
        {
            id: 2,
            manual: settings.wallets.yooMoney.manuals['2'],
            action: settings.wallets.yooMoney.identification,
            href: 'https://yoomoney.ru/page?id=536136',
        },
    ]

    return (
        <div className={s.instructionContainer}>
            <div className={s.commonInfo}>
                <h2 className={s.title}>
                    {settings.wallets.yooMoney.requirements}
                </h2>
                <ol className={s.list}>
                    {items.map((el) => (
                        <li key={el.id}>
                            {el.manual}{' '}
                            <a
                                className={s.link}
                                rel={'noreferrer'}
                                target={'_blank'}
                                href={el.href}
                            >
                                {el.action}
                            </a>
                        </li>
                    ))}
                </ol>
            </div>
            <YooMoneyInstruction
                settings={settings}
                orders={orders}
                buttons={buttons}
            />
        </div>
    )
}
