import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import classes from './CustomerAdvertisingBeats.module.scss'
import { SearchField } from '../../../common/elements/textFields/search'
import ArrowDownIcon from '../../../assets/icons/arrow-bottom.svg'
import { Button } from '../../../common/elements/buttons/Button'
import { CustomRadio } from '../../../common/elements/CustomRadio'
import { useSelector } from '../../../common/hooks/redux-hooks'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import {
    actions as promoActions,
    getBeats,
} from '../../../../BLL/CustomerBeatsPromotionReducer'
import { useEditingEntityStatus } from '../../../common/hooks/useEditingEntityStatus'
import {
    EditingEntityStatuses,
    RequestStatuses,
} from '../../../../BLL/AppReducer'
import { useTranslation } from 'react-i18next'
import { LanguageType } from '../../../../translations/ru/common'
import { BeatType } from '../../../../BLL/types'

type PropsType = {
    promoTypeTitle: string
    setIsOpenSelectBeat: (value: boolean) => void
    beats: BeatType[]
    pagesCount: number
    page: number
    sendPromoInfo: () => void
    selectedBeat: (beat: BeatType) => void
}
export const CustomerAdvertisingBeats: FC<PropsType> = ({
    promoTypeTitle,
    setIsOpenSelectBeat,
    beats,
    selectedBeat,
    pagesCount,
    page,
    sendPromoInfo,
}) => {
    const [collapsed, setCollapsed] = useState(false)
    const [term, setTerm] = useState<string | null>(null)
    const [timerId, setTimerId] = useState<any>()

    const handleClose = () => {
        setIsOpenSelectBeat(false)
    }

    const [beatSelect, setBeatSelect] = useState<number | null>(null)

    const requestStatus = useSelector((state) => state.appReducer.requestStatus)

    const dispatch = useDispatch()
    const history = useHistory()
    useEffect(() => {
        handleGetBeats()
        return () => {
            dispatch(promoActions.clearBeats())
            setBeatSelect(null)
        }
    }, [])

    useEffect(() => {
        handleSearchBeats()
    }, [term])

    const { editingEntityStatus } = useEditingEntityStatus('createPromo')

    useEffect(() => {
        if (editingEntityStatus === EditingEntityStatuses.Success) {
            handleClose()
        }
    }, [editingEntityStatus])

    const handleSearchBeats = () => {
        clearTimeout(timerId)
        const timer = setTimeout(() => {
            if (term) {
                console.log('handleSearchBeats')
                dispatch(promoActions.clearBeats())
                dispatch(getBeats(1, term))
            } else {
                dispatch(getBeats(1))
            }
        }, 1000)
        setTimerId(timer)
    }

    const handleGetBeats = () => {
        console.log('handleGetBeats')
        dispatch(getBeats(page + 1))
    }

    const handleSendPromo = () => {
        sendPromoInfo()
    }

    const onBeatSelect = (beatId: number) => {
        let beat = beats.find((beat) => beat.beatId === beatId)
        setBeatSelect(beatId)
        beat && selectedBeat(beat)
    }
    const { i18n } = useTranslation()
    const { buttons, customerBeatsPromotion } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        dispatch(promoActions.setPage(0))
        const value = event.currentTarget.value
        if (value) {
            setTerm(value)
        } else {
            setTerm(null)
        }
    }

    return (
        <div>
            <p className={classes.descr}>
                {customerBeatsPromotion.selectBeatForPromo}{' '}
                <span>{promoTypeTitle}:</span>
            </p>
            <div className={classes.fieldBox}>
                <SearchField onChange={handleSearch} />
            </div>
            <div className={classes.fieldItems}>
                {beats.map((beat) => (
                    <CustomRadio
                        key={beat.beatId}
                        id={beat.beatId.toString()}
                        title={beat.beatTitle}
                        checked={beatSelect === beat.beatId}
                        handleChange={onBeatSelect}
                        value={beat.beatId}
                    />
                ))}
            </div>
            {pagesCount > page && (
                <div className={classes.hiddenBlock} onClick={handleGetBeats}>
                    <img src={ArrowDownIcon} alt={'arrow-down'} />
                    <span>See more</span>
                </div>
            )}
            <div className={classes.btnBox}>
                <Button
                    view={'Secondary'}
                    title={buttons.CANCEL}
                    onClick={handleClose}
                />
                <Button
                    title={buttons.RESERVE}
                    onClick={handleSendPromo}
                    disabled={
                        requestStatus === RequestStatuses.InProgress ||
                        !beatSelect
                    }
                />
            </div>
        </div>
    )
}
