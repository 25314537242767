import { useSelector } from 'UI/common/hooks/redux-hooks'
import { selectLicenses } from 'BLL/selectors/contracts-selectors'
import {
    selectMoods,
    selectStyles,
    selectUserId,
} from 'BLL/selectors/app-selectors'
import {
    selectCurrentBeat,
    selectFilesBindedToLicenses,
} from 'BLL/selectors/myBeats-selectors'
import { selectPublications } from 'BLL/selectors/beatPublications-selectors'

export const UseBeatSettingsSelectors = () => {
    const currentBeat = useSelector(selectCurrentBeat)
    const filesBindedToLicenses = useSelector(selectFilesBindedToLicenses)
    const publications = useSelector(selectPublications)
    const beatmakerId = useSelector(selectUserId)
    const licenses = useSelector(selectLicenses)
    const moods = useSelector(selectMoods)
    const styles = useSelector(selectStyles)
    return {
        currentBeat,
        filesBindedToLicenses,
        publications,
        beatmakerId,
        licenses,
        moods,
        styles,
    }
}
