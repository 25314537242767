import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'
import { useHistory, useParams, NavLink } from 'react-router-dom'
import { GroupDiscount } from './groupDiscounts/GroupDiscount'
import { Coupons } from './coupons/Coupons'
import { CustomerRM } from 'UI/routers/RouterManager'
import { Button } from 'UI/common/elements/buttons/Button'
import { createDiscount } from 'BLL/CustomerDiscountsReducer'
import { useDispatch } from 'react-redux'
import { TabPanel } from 'UI/common/elements/tabPanel/TabPanel'
import { ModalValue } from 'UI/CustomerAdmin/CustomerDiscounts/types'
import { CustomTabs } from 'UI/common/elements/tabPanel/CustomTabs'
import s from 'UI/CustomerAdmin/CustomerDiscounts/customerDiscounts.module.scss'

export const modalValues = {
    coupons: 0,
    'group-discounts': 1,
} as const

export const Discounts = () => {
    const dispatch = useDispatch()

    const { tab } = useParams<{ tab: string }>()
    const history = useHistory()

    const { i18n } = useTranslation()
    const { navigation, buttons, discounts } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    const [value, setValue] = useState<ModalValue>(modalValues.coupons)

    useEffect(() => {
        tab === 'coupons' && setValue(modalValues.coupons)
        tab === 'group-discounts' && setValue(modalValues['group-discounts'])
    }, [tab])

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        newValue === modalValues.coupons &&
            history.push(CustomerRM.discounts.path.getUrl('coupons'))
        newValue === modalValues['group-discounts'] &&
            history.push(CustomerRM.discounts.path.getUrl('group-discounts'))
    }

    const handleCreateDiscount = useCallback(() => {
        dispatch(createDiscount())
    }, [])

    return (
        <div>
            <div className={s.topWrapper}>
                <div className={s.pageTitle}>{navigation.discounts}</div>
                <div className={s.searchWrapper}>
                    {tab === 'coupons' ? (
                        <Button
                            title={buttons.ADD}
                            onClick={handleCreateDiscount}
                        />
                    ) : (
                        <NavLink to={CustomerRM.discounts.create.path}>
                            <Button title={buttons.ADD} />
                        </NavLink>
                    )}
                </div>
            </div>
            <div className={s.tabsWrapper}>
                <CustomTabs
                    value={value}
                    onChange={handleChange}
                    tabs={Object.keys(modalValues)}
                    labels={Object.values(discounts.tabs)}
                ></CustomTabs>
            </div>
            <TabPanel value={tab} index={'coupons'}>
                <Coupons />
            </TabPanel>
            <TabPanel value={tab} index={'group-discounts'}>
                <GroupDiscount />
            </TabPanel>
        </div>
    )
}
