import { AppState } from 'BLL/Store'

export const selectBalance = (state: AppState) => state.coinsReducer.balance

export const selectCoins = (state: AppState) => state.coinsReducer.coinsCount

export const selectBonus = (state: AppState) => state.coinsReducer.bonusCoins

export const selectMoney = (state: AppState) => state.coinsReducer.money
export const selectRedirectURL = (state: AppState) =>
    state.coinsReducer.redirectUrl

export const selectCoinsHistory = (state: AppState) =>
    state.coinsReducer.coinsHistory

export const selectDefaultPrice = (state: AppState) =>
    state.coinsReducer.defaultPrice

export const selectCoinsPrice = (state: AppState) =>
    state.coinsReducer.promoSettings

export const selectSaleCount = (state: AppState) => state.coinsReducer.saleCount
