import React from 'react'
import { Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RolesTypeEnum } from 'BLL/enums'
import { selectRoles } from 'BLL/selectors/app-selectors'

export const useRedirectCustomHook = (
    role: RolesTypeEnum,
    redirectUrl = '/'
) => {
    const roles: RolesTypeEnum[] = useSelector(selectRoles)

    if (
        !(
            roles.indexOf(role) > -1 ||
            roles.indexOf(RolesTypeEnum.ADMINISTRATOR) > -1
        )
    ) {
        return <Redirect to={redirectUrl} />
    } else {
        return true
    }
}
