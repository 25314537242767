import { BeatSettingsAPI, FreeBeatType, MyBeatType } from 'DAL/BeatSettingsAPI'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { fetchBeatReorder } from 'BLL/CustomerMyBeatsReducer'
import { DragDropContext, DropResult } from 'react-beautiful-dnd'
import { CustomBrowserView } from 'UI/common/browserView/CustomBrowserView'
import { Pagination } from 'UI/common/elements/pagination/Pagination'
import { TableHeader } from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/draggableTable/TableHeader'
import { DroppableTableItem } from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/draggableTable/DroppableTableItem'

type PropsTest<T extends MyBeatType | FreeBeatType> = {
    totalCount: number
    pageSize: number
    page: number
    term: string
    beats: T[]
    ChangePageSize: (pageSize: number) => void
    ChangePage: (page: number) => void
}

export const DraggableTable = <T extends MyBeatType | FreeBeatType>({
    totalCount,
    pageSize,
    page,
    term,
    beats,
    ChangePageSize,
    ChangePage,
}: PropsTest<T>) => {
    const dispatch = useDispatch()

    const [beatsReorder, setBeatsReorder] = useState(beats)
    const [isDrag, setIsDrag] = useState(false)

    useEffect(() => {
        setBeatsReorder(beats)
    }, [beats])

    const handleDragStart = () => {
        setIsDrag(true)
    }

    const reorder = (beats: T[], startIndex: number, endIndex: number): T[] => {
        const [removed] = beats.splice(startIndex, 1)
        beats.splice(endIndex, 0, removed)
        return beats
    }

    const handleChangePageSize = useCallback(
        (pageSize: number) => {
            ChangePageSize(pageSize)
        },
        [page]
    )

    const handleChangePage = useCallback(
        (page: number) => {
            ChangePage(page)
        },
        [pageSize]
    )

    const handleDragStop = async (result: DropResult) => {
        if (!result.destination) {
            setIsDrag(false)
            return
        }
        if (result.destination.droppableId === 'droppable') {
            const newBeats = reorder(
                beatsReorder,
                result.source.index,
                result.destination.index
            )
            const afterId = result.destination.index
                ? result.destination.index - 1
                : null
            if (afterId !== null) {
                dispatch(
                    fetchBeatReorder(+result.draggableId, beats[afterId].id)
                )
            } else {
                await handleReorderBeat(page, +result.draggableId)
            }
            setBeatsReorder(newBeats)
        } else {
            await handleReorderBeat(
                +result.destination.droppableId,
                +result.draggableId
            )
            handleChangePage(+result.destination.droppableId)
        }
        setIsDrag(false)
    }

    const handleReorderBeat = async (page: number, beatId: number) => {
        if (page > 1) {
            const beatsPages = await BeatSettingsAPI.getBeats(
                pageSize,
                page - 1,
                term,
                false
            )
            dispatch(
                fetchBeatReorder(
                    beatId,
                    beatsPages.items[beatsPages.items.length - 1].id
                )
            )
        } else {
            dispatch(fetchBeatReorder(beatId))
        }
    }

    return (
        <DragDropContext
            onDragEnd={handleDragStop}
            onDragStart={handleDragStart}
        >
            <CustomBrowserView>
                <TableHeader />
            </CustomBrowserView>
            <DroppableTableItem beatsReorder={beatsReorder} isDrag={isDrag} />
            <Pagination
                totalCount={totalCount}
                onChangePage={handleChangePage}
                currentPage={page}
                onChangeRowsPerPage={handleChangePageSize}
                pageSize={pageSize}
            />
        </DragDropContext>
    )
}
