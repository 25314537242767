import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import classes from '../WidgetsSettingsPage.module.scss'

import { CopyInput } from '../../../../common/elements/textFields/copy-input'
import { LanguageType } from '../../../../../translations/ru/common'
import { copyBuffer } from '../../../../utils/util-types'
import { WidgetFormType } from '../WidgetsSettingsPage'

type PropsType = {
    urlSettings: Pick<WidgetFormType, 'width' | 'height'>
}

export const WidgetUrlsBlock: FC<PropsType> = ({ urlSettings }) => {
    const dispatch = useDispatch()

    /* i18n */
    const { i18n } = useTranslation()
    const { widget } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    const copyBufferHandler = (value: string) => {
        copyBuffer(value, dispatch)
    }

    return (
        <>
            {/* Copy and paste this in to your web page */}
            <div className={classes.fieldBox}>
                <div className={classes.label}>{widget.fields.iframe}</div>
                <CopyInput
                    value={`<iframe src='https://staging3.beatmaker.tv/widget' width=${urlSettings.width}px height=${urlSettings.height}px >`}
                    onClick={() =>
                        copyBufferHandler(
                            `<iframe src='https://staging3.beatmaker.tv/widget' width=${urlSettings.width}px height=${urlSettings.height}px >`
                        )
                    }
                />
            </div>

            {/* URL Widget */}
            <div className={classes.fieldBox}>
                <div className={classes.label}>{widget.fields.url}</div>
                <CopyInput
                    value={'https://beatmaker.tv/track/25'}
                    onClick={() =>
                        copyBufferHandler('https://beatmaker.tv/track/25')
                    }
                />
            </div>
        </>
    )
}
