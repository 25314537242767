import React from 'react'
import MaterialTable from 'material-table'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'
import { RequestStatuses } from 'BLL/AppReducer'

type FieldsType = {
    title: string
    field: string
}

interface IProps<ItemModel extends Object> {
    items: ItemModel[]
    fields: FieldsType[]
    title: string
    search?: boolean
    searchText?: string
    detailPanel?: any
    requestStatus?: RequestStatuses
    onChangePage?: (page: number) => void
    onChangeRowsPerPage?: (pageSize: number) => void
    actions?: any
    pageSize?: number
    totalCount?: number
    page?: number
    components?: any
    options?: any
    toolbar?: boolean
}

function Table<ItemModel extends Object>({
    items,
    fields,
    title,
    search = false,
    searchText,
    detailPanel,
    requestStatus = RequestStatuses.Success,
    onChangePage,
    onChangeRowsPerPage,
    actions,
    pageSize = 20,
    totalCount,
    page,
    components,
    toolbar = false,
    options,
}: IProps<ItemModel>) {
    const { i18n } = useTranslation()
    const { pagination } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    return (
        <div>
            <MaterialTable<ItemModel>
                title={title}
                columns={fields.map((field) => ({
                    ...field,
                    cellStyle: {
                        borderBottom: '1px solid #282727',
                        fontWeight: 400,
                        whiteSpace: 'break-spaces',
                    },
                }))}
                isLoading={requestStatus === RequestStatuses.InProgress}
                options={{
                    toolbar,
                    actionsColumnIndex: -1,
                    paging: false,
                    emptyRowsWhenPaging: false,
                    search,
                    pageSizeOptions: [20, 50, 100],
                    pageSize: pageSize,
                    padding: 'dense',
                    sorting: true,
                    headerStyle: {
                        whiteSpace: 'nowrap',
                        background: '#171717',
                        color: '#FFFFFF',
                        borderBottom: '1px solid #282727',
                        fontWeight: 700,
                        fontSize: '14px',
                        '&:hover': {
                            color: '#FFFFFF',
                        },
                    },
                    rowStyle: {
                        whiteSpace: 'nowrap',
                        background: '#101010',
                        color: '#FFFFFF',
                        borderBottom: '1px solid #282727',
                        height: '60px',
                        fontSize: '14px',
                    },
                    showEmptyDataSourceMessage: false,
                    ...options,
                }}
                localization={{
                    toolbar: {
                        searchPlaceholder: searchText,
                    },
                    pagination: {
                        labelRowsSelect: pagination.lines,
                        labelDisplayedRows: `{from}-{to} ${pagination.from} {count}`,
                        firstTooltip: pagination.firstTooltip,
                        lastTooltip: pagination.lastTooltip,
                        nextTooltip: pagination.nextTooltip,
                        previousTooltip: pagination.previousTooltip,
                    },
                    header: {
                        actions: '',
                    },
                }}
                totalCount={totalCount}
                data={items}
                page={page}
                detailPanel={detailPanel}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                actions={actions}
                components={components}
            />
        </div>
    )
}

export default Table
