import React, { Dispatch, SetStateAction } from 'react'
import { Route, useHistory } from 'react-router-dom'
import { CustomerRM } from 'UI/routers/RouterManager'
import { Modal } from 'UI/common/modal/modal'
import { Button } from 'UI/common/elements/buttons/Button'
import { LanguageType } from 'translations/ru/common'
import i18n from 'i18next'
import s from './cardsRoute.module.scss'

type Props = {
    cardId: string
    removeCardHandle: (cardId: string) => void
    setModalIsActive: Dispatch<SetStateAction<boolean>>
}

export const CardsRoute = ({
    cardId,
    removeCardHandle,
    setModalIsActive,
}: Props) => {
    const history = useHistory()

    const {
        buttons,
        settings: { wallets },
    } = i18n.getResourceBundle(i18n.language, 'common') as LanguageType

    const onClickHandler = () => {
        removeCardHandle(cardId)
    }
    return (
        <Route
            exact
            path={CustomerRM.settings.path.wallets.delete(cardId)}
            render={() => (
                <Modal
                    active={true}
                    setActive={setModalIsActive}
                    title={wallets.tinkoff.removeCard}
                    cancelRedirect={() =>
                        history.push(CustomerRM.settings.path.wallets.base)
                    }
                >
                    <div className={s.contentWrapper}>
                        <p>{wallets.tinkoff.confirmRemoveCard}</p>
                        <div className={s.btnBox}>
                            <Button
                                view={'Secondary'}
                                title={buttons.CANCEL}
                                onClick={() =>
                                    history.push(
                                        CustomerRM.settings.path.wallets.base
                                    )
                                }
                            />
                            <Button
                                view={'Delete'}
                                title={buttons.DELETE}
                                onClick={onClickHandler}
                            />
                        </div>
                    </div>
                </Modal>
            )}
        />
    )
}
