import React, { FC } from 'react'

interface IProps {
    htmlToRender: string
}
const RenderHtmlTemplate: FC<IProps> = ({ htmlToRender }) => {
    return (
        <div style={{ background: 'white' }}>
            <div dangerouslySetInnerHTML={{ __html: htmlToRender }}></div>
        </div>
    )
}

export default RenderHtmlTemplate
