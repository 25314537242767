import { DiscountType } from 'DAL/DiscountAPI'
import { LanguageType } from 'translations/ru/common'
import { AppSettings } from 'settings/appSettings'
import { Status } from 'UI/common/status/Status'
import { getLocaleDateString } from 'UI/utils/util-types'
import Tooltip from '@material-ui/core/Tooltip'
import { NavLink } from 'react-router-dom'
import { CustomerRM } from 'UI/routers/RouterManager'
import EditIcon from 'UI/assets/icons/edit.svg'
import DeleteIcon from 'UI/assets/icons/delete.svg'
import React from 'react'
import { i18n } from 'i18next'
import s from 'UI/common/table/tableData.module.scss'
import { TableType } from 'UI/common/table/types'

export const getDiscountsTableData = (i18n: i18n): TableType<DiscountType> => {
    const { discounts, buttons, statuses } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType
    const isEng = AppSettings.api.isEngVersion()
    return [
        {
            title: discounts.tableColumn.coupon,
            field: 'code',
            render: (data) => {
                return (
                    <div className={s.getDiscountsTableDataCode}>
                        {data.code}
                    </div>
                )
            },
        },
        {
            title: discounts.tableColumn.status,
            field: 'status',
            render: (data) => {
                return <Status status={data.status ? 'Active' : 'Closed'} />
            },
        },
        {
            title: discounts.tableColumn.discounts,
            field: 'discountSize',
            render: (discount) => (
                <div style={{ fontWeight: 700, width: 30 }}>
                    {discount.discountSize}
                </div>
            ),
        },
        {
            title: discounts.tableColumn.licenses,
            field: 'licenseId',
            render: (data) => {
                let license = data.licenses?.find(
                    (l) => l.id === data.licenseId
                )
                return (
                    <div>
                        {(license &&
                            (isEng ? license.titleEn : license.title)) ||
                            discounts.allLicense}
                    </div>
                )
            },
        },
        {
            title: discounts.tableColumn.dueDate,
            field: 'finishDate',
            render: (data) => {
                return (
                    <div>
                        {getLocaleDateString(i18n.language, data.finishDate)}
                    </div>
                )
            },
        },
        {
            title: discounts.tableColumn.purchasedBeats,
            field: 'closedBeatsCount',
            render: (data) => {
                return (
                    <div className={s.getDiscountsTableDataClosedBeatsCount}>
                        <span>
                            {data.closedBeatsCount} / {data.beatsCount}
                        </span>
                    </div>
                )
            },
        },
        {
            title: discounts.tableColumn.action,
            field: 'closedBeatsCount',
            render: (data) => {
                return (
                    <div className={s.getCouponsTableDataAction}>
                        <Tooltip title={buttons.EDIT}>
                            <NavLink
                                to={CustomerRM.discounts.edit.getUrl(data.id)}
                            >
                                <img
                                    className={s.icons}
                                    src={EditIcon}
                                    alt={'edit'}
                                />
                            </NavLink>
                        </Tooltip>
                        <Tooltip title={buttons.DELETE}>
                            <NavLink
                                to={CustomerRM.discounts.deleteCoupon.getUrl(
                                    data.id
                                )}
                            >
                                <img
                                    className={s.icons}
                                    src={DeleteIcon}
                                    alt={'delete'}
                                />
                            </NavLink>
                        </Tooltip>
                    </div>
                )
            },
        },
    ]
}
