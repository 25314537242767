import React, { FC } from 'react'
import Rating from '@mui/material/Rating'
import { styled } from '@mui/material/styles'
import StarIcon from '@mui/icons-material/Star'

type PropsType = {
    rating: number
    setRating: (value: number) => void
    disabledRating?: boolean
}

const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
        color: '#ff6d75',
    },
    '& .MuiRating-iconHover': {
        color: '#ff3d47',
    },
})

export const CustomRating: FC<PropsType> = ({
    rating,
    setRating,
    disabledRating,
}) => {
    return (
        <div>
            <StyledRating
                name="simple-controlled"
                value={rating / 2}
                precision={0.5}
                disabled={disabledRating}
                onChange={(event, newValue) => {
                    newValue && setRating(newValue * 2)
                }}
                emptyIcon={
                    <StarIcon
                        style={{ opacity: 0.3, color: '#FFFFFF' }}
                        fontSize="inherit"
                    />
                }
            />
        </div>
    )
}
