import React from 'react'
import classes from '../WidgetsSettingsPage.module.scss'
import { NullableType } from '../../../../../BLL/types'

type PropsType = {
    beatmakerId: NullableType<number>
}

export const WidgetPreviewBlock = (props: PropsType) => {
    return (
        <div className={classes.widgetBoxFullScreen}>
            <iframe
                style={{
                    width: '100%',
                    height: '408px',
                }}
                sandbox="allow-same-origin allow-scripts allow-forms allow-popups allow-popups-to-escape-sandbox"
                src={`https://propage-dev.beatmaker.tv:4200/?devBeatmakerId=${props.beatmakerId}`}
            />
        </div>
    )
}
