import React, { Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import { MenuItems } from 'UI/common/menus/menuItems/menuItems'
import s from 'UI/common/menus/menu.module.scss'

type Props = {
    items: MenuItems[]
    location: string
    isExternal?: boolean
}

export const Items = ({ items, location, isExternal }: Props) => {
    const itemClassName = (link: string) => {
        return location === link.split('/')[1]
            ? `${s.item} ${s.active}`
            : s.item
    }

    return (
        <div>
            {items.map((item: MenuItems, i: number) => {
                const uniqueKey = `${item.title}_${i}`
                return (
                    item.isShow && (
                        <Fragment key={uniqueKey}>
                            {isExternal ? (
                                <div className={s.item}>
                                    <a
                                        href={item.link}
                                        target={'_blank'}
                                        rel="noreferrer"
                                    >
                                        {item.title}
                                        {item.icon && (
                                            <img
                                                className={s.icon}
                                                src={item.icon}
                                                alt={item.title}
                                            />
                                        )}
                                    </a>
                                </div>
                            ) : (
                                <div className={itemClassName(item.link)}>
                                    <NavLink to={item.link} className={s.link}>
                                        {item.title}
                                        {item.icon && (
                                            <img
                                                className={s.navLinkIcon}
                                                src={item.icon}
                                                alt={item.title}
                                            />
                                        )}
                                    </NavLink>
                                </div>
                            )}
                        </Fragment>
                    )
                )
            })}
        </div>
    )
}
