import React from 'react'
import { MyBeatType } from 'DAL/BeatSettingsAPI'
import { ArchiveItem } from 'UI/CustomerAdmin/MyBeatsPage/Archive/archiveItem/ArchiveItem'

type Props = {
    beats: MyBeatType[]
}

export const ArchiveTableItem = ({ beats }: Props) => {
    return (
        <div>
            {beats.map((beat) => (
                <ArchiveItem beat={beat} key={beat.id} />
            ))}
        </div>
    )
}
