import React, { useCallback, useState } from 'react'
import { useHistory, NavLink } from 'react-router-dom'
import { MyBeatType } from 'DAL/BeatSettingsAPI'
import { PublicationType } from 'BLL/types'
import { StatusPublicationEnum } from 'BLL/enums'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'
import { useSelector } from 'UI/common/hooks/redux-hooks'
import { usePlayer } from 'Player/Hooks/Hooks'
import Tooltip from '@material-ui/core/Tooltip'
import { PlayerLogicTrackType } from 'Player/Domain/PlayerLogic'
import { BeatLabeledDate } from 'UI/common/components/BeatAddedDate/BeatAddedDate'
import { CustomerRM } from 'UI/routers/RouterManager'
import { DraggableProvided } from 'react-beautiful-dnd'
import { MobBeatItem } from 'UI/CustomerAdmin/Mobile/MobMyBeatsPage/Paid/MobBeatItem/MobBeatItem'
import { CustomMobileView } from 'UI/common/mobileView/CustomMobileView'
import { CustomBrowserView } from 'UI/common/browserView/CustomBrowserView'
import { useDeviceDetect } from 'UI/common/hooks/useDeviceDetect'
import { MobileMenuItem } from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/beatItem/mobileMenu/mobileMenuItem'
import EditIcon from 'UI/assets/icons/edit.svg'
import DeleteIcon from 'UI/assets/icons/delete.svg'
import PublishIcon from 'UI/assets/icons/publish.svg'
import CancelPublishIcon from 'UI/assets/icons/cancel-publish.svg'
import dotsMenu from 'UI/assets/icons/dotsMenu.svg'
import s from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/beatItem/beatItem.module.scss'
import { BeatCover } from 'UI/common/components/BeatCover/BeatCover'
import { DragIcon } from 'UI/common/components/DragIcon/DragIcon'

type Props = {
    beat: MyBeatType
    index: number
    publication?: PublicationType
    provided: DraggableProvided
    isDragging: boolean
    isDrag: boolean
}

export const BeatItem = ({
    beat,
    index,
    publication,
    provided,
    isDragging,
    isDrag,
}: Props) => {
    const artist = useSelector(
        (state) => state.appReducer.userDetails?.userName
    )

    const history = useHistory()
    const { i18n } = useTranslation()
    const { customerBeatsPublication, buttons } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    let playerBeatDTO: PlayerLogicTrackType = {
        id: beat.id,
        artist: artist ? artist : '',
        title: beat.title,
        format: beat.format,
        url: beat.playUrl as string,
        type: 'beat',
        images: beat.images,
    }

    const [player, isPlayingMe] = usePlayer(false, playerBeatDTO)

    const [show, setShow] = useState(false)

    const handleEditBeat = useCallback(() => {
        history.push(CustomerRM.myBeats.getUrl.beats.edit(beat.id))
    }, [history, beat.id])

    const playHandler = () => {
        if (isPlayingMe) {
            player.pause()
        } else {
            player.play(playerBeatDTO)
        }
    }

    const isMobile = useDeviceDetect()

    return (
        <div className={isMobile ? '' : s.card}>
            <div className={s.beatInfo}>
                <CustomBrowserView>
                    <DragIcon provided={provided} isDrag={isDrag} />
                    <BeatCover
                        isPlayingMe={isPlayingMe}
                        playHandler={playHandler}
                        beat={beat}
                    />
                    <div>
                        <div className={s.beatTitle}>{beat.title}</div>
                        <div className={s.publicationInfo}>
                            <BeatLabeledDate
                                title={
                                    customerBeatsPublication.tableColumns
                                        .publishedDate
                                }
                                date={beat.addedToCatalogDate}
                            />
                            <BeatLabeledDate
                                date={publication?.date}
                                title={customerBeatsPublication.waitPublished}
                            />
                        </div>
                    </div>
                </CustomBrowserView>
                <CustomMobileView>
                    <MobBeatItem
                        provided={provided}
                        isDragging={isDragging}
                        publication={publication}
                        beat={beat}
                        playHandler={playHandler}
                        isPlayingMe={isPlayingMe}
                    />
                </CustomMobileView>
            </div>
            <CustomBrowserView>
                <div className={s.beatChart}>
                    <div>{beat.chartPosition ?? '—'}</div>
                    <div>{beat.downloads}</div>
                    <div>{beat.plays}</div>
                </div>
            </CustomBrowserView>
            <CustomBrowserView>
                <div className={s.information}>
                    <div className={s.actions}>
                        <Tooltip title={buttons.EDIT}>
                            <img
                                className={s.icons}
                                src={EditIcon}
                                onClick={handleEditBeat}
                                alt={'edit'}
                            />
                        </Tooltip>

                        {!beat.addedToCatalogDate ? (
                            <Tooltip title={buttons.DELETE}>
                                <NavLink
                                    to={CustomerRM.myBeats.getUrl.publications.delete(
                                        beat.id
                                    )}
                                >
                                    <img
                                        className={s.icons}
                                        src={DeleteIcon}
                                        alt={'delete'}
                                    />
                                </NavLink>
                            </Tooltip>
                        ) : (
                            <Tooltip title={buttons.DELETE_FROM_CATALOG}>
                                <NavLink
                                    to={CustomerRM.myBeats.getUrl.publications.cancel(
                                        beat.id
                                    )}
                                >
                                    <img
                                        className={s.icons}
                                        src={DeleteIcon}
                                        alt={'delete'}
                                    />
                                </NavLink>
                            </Tooltip>
                        )}
                        {publication && (
                            <Tooltip title={buttons.PUBLISHED}>
                                <NavLink
                                    to={CustomerRM.myBeats.getUrl.publications.edit(
                                        beat.id
                                    )}
                                >
                                    <img
                                        className={s.icons}
                                        src={PublishIcon}
                                        onClick={handleEditBeat}
                                        alt={'edit'}
                                    />
                                </NavLink>
                            </Tooltip>
                        )}
                        {publication && (
                            <div>
                                {publication.status !==
                                    StatusPublicationEnum.NotPublished && (
                                    <Tooltip
                                        title={
                                            customerBeatsPublication.cancelPublication
                                        }
                                    >
                                        <NavLink
                                            to={CustomerRM.myBeats.getUrl.publications.cancelPublications(
                                                publication._id
                                            )}
                                        >
                                            <img
                                                className={s.icons}
                                                src={CancelPublishIcon}
                                                onClick={handleEditBeat}
                                                alt={'edit'}
                                            />
                                        </NavLink>
                                    </Tooltip>
                                )}
                            </div>
                        )}
                    </div>
                    <div className={s.smallDesktopViewActions}>
                        <button onClick={() => setShow(!show)}>
                            <img src={dotsMenu} alt="Open Menu" />
                        </button>
                        {show && (
                            <MobileMenuItem
                                beat={beat}
                                publicationId={publication?._id}
                            />
                        )}
                    </div>
                </div>
            </CustomBrowserView>
        </div>
    )
}
