import { LanguageType } from 'translations/ru/common'
import { CustomerRM } from 'UI/routers/RouterManager'
import { MenuItem } from 'UI/common/menus/menuItems/menuItems'

export const ShopsMenuItems = (translate: LanguageType, isOwner: boolean) => {
    return [
        new MenuItem(
            translate.navigation.coupons,
            CustomerRM.shops.coupons.path,
            false
        ),
        new MenuItem(
            translate.navigation.shop,
            CustomerRM.shops.owner.path,
            false,
            isOwner
        ),
    ]
}
