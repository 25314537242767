import React, { useEffect, useState } from 'react'
import { YooMoneyStep } from 'UI/CustomerAdmin/BeatmakerSettingsNew/wallet-settings/wallets/yooMoney/YooMoneyStep'
import { copyBuffer } from 'UI/common/elements/textFields/newtextField/utils/copyBuffer'
import { NewTextField } from 'UI/common/elements/textFields/newtextField/NewTextField'
import { Button } from 'UI/common/elements/buttons/Button'
import { AppSettings } from 'settings/appSettings'
import {
    fetchedDeactivateYandex,
    fetchedUpdateYandex,
} from 'BLL/CustomerWalletsReducer'
import { useYooMoneyData } from 'UI/CustomerAdmin/BeatmakerSettingsNew/wallet-settings/wallets/yooMoney/useYooMoneyData'
import { useDispatch } from 'react-redux'
import { LanguageType } from 'translations/ru/common'
import img1 from 'UI/assets/image/instruction-1.png'
import img2 from 'UI/assets/image/instruction-2.png'
import s from 'UI/CustomerAdmin/BeatmakerSettingsNew/wallet-settings/walletSettings.module.scss'

type Props = {
    settings: LanguageType['settings']
    orders: LanguageType['orders']
    buttons: LanguageType['buttons']
}
export const YooMoneyInstruction = ({ settings, orders, buttons }: Props) => {
    const { wallets, beatmakerId } = useYooMoneyData()
    const dispatch = useDispatch()

    const [secret, setSecret] = useState('')
    const [wallet, setWallet] = useState('')

    useEffect(() => {
        setSecret(wallets?.yandexSecret || '')
        setWallet(wallets?.yandexWallet?.toString() || '')
    }, [wallets])

    const secretUrl = `${
        AppSettings.urls.CORE_BASE_URL
    }payment/secretUserUrl/yandex/${wallets && wallets.id}`

    const handleUpdateYandex = () => {
        dispatch(fetchedUpdateYandex(secret, +wallet))
    }
    const handleDeactivateYandex = () => {
        dispatch(fetchedDeactivateYandex())
    }

    return (
        <div className={s.instruction}>
            <h2 className={s.title}>{settings.wallets.yooMoney.title}</h2>
            <YooMoneyStep
                title={settings.wallets.yooMoney.steps.step1}
                instruction={settings.wallets.yooMoney.stepsInstructions.step1}
                secretUrl={secretUrl}
                onCopy={() => copyBuffer(secretUrl, dispatch)}
            />
            <YooMoneyStep
                title={settings.wallets.yooMoney.steps.step2}
                instruction={`${settings.wallets.yooMoney.stepsInstructions.step2} - `}
                link="https://yoomoney.ru/transfer/myservices/http-notification?_openstat=settings%3Bother%3Bmoney%3Bhttp%3Bset"
                linkText="YooMoney"
            />
            <YooMoneyStep
                title={settings.wallets.yooMoney.steps.step3}
                instruction={`${settings.wallets.yooMoney.stepsInstructions.step3}`}
                imgSrc={img1}
            />
            <YooMoneyStep
                title={settings.wallets.yooMoney.steps.step4}
                instruction={settings.wallets.yooMoney.stepsInstructions.step4}
            />
            <YooMoneyStep
                title={settings.wallets.yooMoney.steps.step5}
                instruction={settings.wallets.yooMoney.stepsInstructions.step5}
                imgSrc={img2}
            />
            <YooMoneyStep
                title={settings.wallets.yooMoney.steps.step6}
                instruction={settings.wallets.yooMoney.stepsInstructions.step6}
            >
                <div className={s.fieldsBox}>
                    <div className={s.inputBox}>
                        <NewTextField
                            label={orders.tableColumns.walletNumber}
                            value={wallet}
                            onChange={({ target: { value } }) =>
                                setWallet(value)
                            }
                        />
                    </div>
                    <div className={s.inputBox}>
                        <NewTextField
                            label={settings.wallets.yooMoney.secretKey}
                            value={secret}
                            onChange={({ target: { value } }) =>
                                setSecret(value)
                            }
                        />
                    </div>
                </div>
            </YooMoneyStep>
            <div className={s.btnBox}>
                <Button
                    title={buttons.SAVE}
                    type={'button'}
                    onClick={handleUpdateYandex}
                />
            </div>
            <YooMoneyStep
                title={settings.wallets.yooMoney.steps.step7}
                instruction={settings.wallets.yooMoney.stepsInstructions.step7}
            >
                <span className={s.stepText}>
                    <ul>
                        <li>
                            {settings.wallets.yooMoney.testingPayment}{' '}
                            <a
                                className={s.link}
                                href={`${AppSettings.urls.MAIN_DOMAIN}Default/Payment/DirectWalletsTest?userId=${beatmakerId}`}
                                rel={'noreferrer'}
                                target={'_blank'}
                            >
                                {settings.wallets.yooMoney.walletActivate}
                            </a>
                        </li>
                    </ul>
                </span>
                <div>
                    <span className={s.stepText}>
                        {settings.wallets.yooMoney.activateDescription}
                    </span>
                </div>
            </YooMoneyStep>
            {wallets?.yandexIsActive && (
                <div className={s.btnBox}>
                    <Button
                        view={'Delete'}
                        title={buttons.DEACTIVATE}
                        type={'button'}
                        onClick={handleDeactivateYandex}
                    />
                </div>
            )}
        </div>
    )
}
