import s from 'UI/common/elements/pagination/pagination.module.scss'
import { DOTS } from 'UI/common/elements/pagination/usePagination'
import React from 'react'

type Props = {
    paginationRange: (number | string)[]
    currentPage: number
    onClick: (pageNumber: number) => void
}

export const PaginationButtons = ({
    paginationRange,
    onClick,
    currentPage,
}: Props) => {
    return (
        <>
            {paginationRange.map((key, index) => {
                const setButtonStyle =
                    Number(key) === currentPage ? `${s.currentPage}` : ''

                return (
                    <button
                        key={index}
                        className={`${setButtonStyle} ${s.pagination}`}
                        onClick={() => onClick(+key)}
                        disabled={key === DOTS}
                    >
                        {key}
                    </button>
                )
            })}
        </>
    )
}
