import { subscribersAxios } from './createAxios'
import { SourceTypeEnum } from '../BLL/CustomerSubscribersReducer'
import { PaginateType } from './PromoAPI'
import { BaseResponse } from '../BLL/types'

export interface ISubscribeGetParams {
    currentPage: number
    pageSize: number
    sourceType: SourceTypeEnum
    fromDate: string
    toDate: string
}

export interface ISubscribersResult<T> {
    totalCount: number
    pageSize: number
    pageCount: number
    currentPage: number
    pagingCounter: number
    hasPrevPage: boolean
    hasNextPage: boolean
    prev: boolean
    next: boolean
    items: Array<T>
}

export interface ISubscriber {
    _id?: any
    email: string
    name: string
}

export interface ISubscribers {
    _id?: any
    subscriber: ISubscriber
    sourceType: SourceTypeEnum
    subscribedAt: Date
    userId: number
}

export interface IResponseDeleteResult {
    resultCode: number
    message: string
}

export const subscribersAPI = {
    async getSubscribers(params: ISubscribeGetParams) {
        const { sourceType, currentPage, pageSize, fromDate, toDate } = params
        let type = 0
        if (sourceType) {
            type = sourceType
        }
        return await subscribersAxios
            .get<{ subscribers: PaginateType<ISubscribers[]> }>(
                `/subscribers?page=${currentPage}&pageSize=${pageSize}&type=${type}&fromDate=${fromDate}&toDate=${toDate}`
            )
            .then((r) => r.data)
    },

    async deleteSubscriber(id: string) {
        return await subscribersAxios
            .delete<BaseResponse>(`/subscribers?id=${id}`)
            .then((r) => r.data)
    },
}
