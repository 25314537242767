import React, { useEffect } from 'react'
import Header from '../../common/header/Header'
import { useRedirectCustomHook } from 'UI/common/hooks/useRedirectCustomHook'
import { RolesTypeEnum } from 'BLL/enums'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'
import { useSelector } from 'UI/common/hooks/redux-hooks'
import { useDispatch } from 'react-redux'
import { getWithdrawals, paymentsActions } from 'BLL/AdminPaymentsReducer'
import Paper from '@material-ui/core/Paper'
import Table from '../../common/table/Table'
import { MoneyWithdrawal } from 'DAL/paymentsAPI'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { getOrdersTableData } from 'UI/common/table/tableData/GetOrdersTableData'

const useStyles = makeStyles(() =>
    createStyles({
        tableWrapper: {
            marginTop: 20,
            marginBottom: 10,
        },
    })
)

export const Orders = () => {
    const classes = useStyles()
    const { i18n } = useTranslation()
    const { navigation } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    const moneyWithdrawals = useSelector(
        (state) => state.paymentsAdmin.moneyWithdrawals
    )
    const page = useSelector((state) => state.paymentsAdmin.page)
    const pageSize = useSelector((state) => state.paymentsAdmin.pageSize)
    const totalCount = useSelector((state) => state.paymentsAdmin.totalCount)

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getWithdrawals(page ? page : page + 1, pageSize))
    }, [page, pageSize, dispatch])

    const handleChangePage = (page: number) => {
        dispatch(paymentsActions.setPage(page))
    }
    const handleChangePageSize = (pageSize: number) => {
        dispatch(paymentsActions.setPageSize(pageSize))
    }

    return (
        <div>
            {useRedirectCustomHook(RolesTypeEnum.PAYMENTS)}
            <Header title={navigation.orders} />
            <Paper>
                <div className={classes.tableWrapper}>
                    <Table<MoneyWithdrawal>
                        items={moneyWithdrawals}
                        title={''}
                        fields={getOrdersTableData(i18n)}
                        totalCount={totalCount}
                        pageSize={pageSize}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangePageSize}
                    />
                </div>
            </Paper>
        </div>
    )
}
