import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'BLL/Store'
import ShopCoupons from '../../ShopAdmin/ShopCoupons'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'
import {
    createCoupon,
    deleteCoupon,
    getCoupons,
    getShops,
    updateCoupon,
} from 'BLL/CustomerCouponsReducer'
import { Route, useHistory, useLocation } from 'react-router-dom'
import { RequestStatuses } from 'BLL/AppReducer'
import { UpdateCouponType } from 'BLL/shop-types'
import { CustomerRM } from 'UI/routers/RouterManager'
import { FormControl } from '@material-ui/core'
import { SearchField } from 'UI/common/elements/textFields/search'
import s from './customerCoupons.module.scss'
import { CustomSelect } from 'UI/common/elements/CustomSelect'
import { useForm } from 'react-hook-form'
import { Button } from 'UI/common/elements/buttons/Button'
import { Modal } from 'UI/common/modal/modal'
import { CustomerCouponsModal } from './CustomerCouponsModal/CustomerCouponsModal'
import { CouponItem } from '../Mobile/MobCouponsPage/CouponItem/CouponItem'
import { MobCouponsPage } from '../Mobile/MobCouponsPage/MobCouponsPage'
import { CustomBrowserView } from 'UI/common/browserView/CustomBrowserView'
import { CustomMobileView } from 'UI/common/mobileView/CustomMobileView'
import { getCouponsTableData } from 'UI/common/table/tableData/CouponsTableData'

function useQuery() {
    return new URLSearchParams(useLocation().search)
}

export type ShopSelectType = {
    value: number | null
    label: string
}
const CustomerCoupons = () => {
    const query = useQuery()
    const shopId = query.get('shopId')
    const add = query.get('add')
    const history = useHistory()
    const shops = useSelector((state: AppState) => state.customerCoupons.shops)
    const coupons = useSelector(
        (state: AppState) => state.customerCoupons.coupons
    )
    const requestStatus = useSelector(
        (state: AppState) => state.appReducer.requestStatus
    )
    const [term, setTerm] = useState('')
    const dispatch = useDispatch()
    const { i18n } = useTranslation()
    const { navigation, buttons, shopCoupon, helpMessage } =
        i18n.getResourceBundle(i18n.language, 'common') as LanguageType
    const [selectValue, setSelectValue] = useState<ShopSelectType>()
    const {
        register,
        formState: { errors },
        control,
        watch,
    } = useForm()
    useEffect(() => {
        dispatch(getShops())
        dispatch(getCoupons())
    }, [])

    useEffect(() => {
        if (add === 'true' && shopId && shopId !== 'null') {
            dispatch(createCoupon(+shopId))
            history.push(CustomerRM.shops.coupons.getUrl(shopId))
        }
    }, [add, shopId])

    useEffect(() => {
        if (shopId && shops.length && shopId !== 'null') {
            setSelectValue({
                value: +shopId,
                label: shops.find((s) => s.id === +shopId)!.title,
            })
        }
    }, [shopId, shops])

    const selectOptions = [
        { value: null, label: shopCoupon.all },
        ...shops.map((shop) => {
            return { value: shop.id, label: shop.title }
        }),
    ]
    let arrayCoupons = [...coupons]
    if (selectValue && selectValue.value) {
        arrayCoupons = coupons.filter((c) => c.shopId === selectValue.value)
    }

    const handleSetSelect = (shopSelected: ShopSelectType) => {
        setSelectValue(shopSelected)
        if (shopSelected.value) {
            history.push(CustomerRM.shops.coupons.getUrl(shopSelected.value))
        } else {
            history.push(CustomerRM.shops.coupons.path)
        }
    }

    const [selectShop, setSelectShop] = useState<ShopSelectType>({
        value: null,
        label: shopCoupon.all,
    })

    useEffect(() => {
        watch('shop') && handleSetSelect(watch('shop'))
        selectShop.value && handleSetSelect(selectShop)
    }, [watch('shop'), selectShop])

    const handleCreateCoupon = useCallback(() => {
        if (selectValue && selectValue.value) {
            dispatch(createCoupon(selectValue.value))
        }
    }, [selectValue])

    const handleDeleteCoupon = useCallback(
        (couponId: string) => {
            const coupon = coupons.find((c) => c.id === +couponId)
            if (coupon) {
                dispatch(deleteCoupon(coupon.shopId, +couponId))
            }
        },
        [coupons]
    )

    const handleUpdateCoupon = (coupon: UpdateCouponType) => {
        dispatch(updateCoupon(coupon))
    }

    const status = requestStatus === RequestStatuses.InProgress

    return (
        <div>
            <CustomBrowserView>
                <div className={s.topWrapper}>
                    <div className={s.pageTitle}>{navigation.coupons}</div>
                    <div className={s.searchWrapper}>
                        <FormControl>
                            <SearchField
                                placeholder={helpMessage.search}
                                value={term}
                                onChange={({ target: { value } }) =>
                                    setTerm(value)
                                }
                            />
                        </FormControl>
                    </div>
                </div>
                <div className={s.wrapperCouponAdd}>
                    <div className={s.selectItem}>
                        <label className={s.titleItem}>
                            {shopCoupon.tableColumns.shop}
                        </label>
                        <CustomSelect
                            control={control}
                            register={register}
                            options={selectOptions}
                            name={'shop'}
                        />
                    </div>
                    <Button
                        title={buttons.ADD_COUPON}
                        onClick={handleCreateCoupon}
                        disabled={status || !watch('shop')?.value}
                    />
                </div>
            </CustomBrowserView>

            <CustomMobileView>
                <form>
                    <MobCouponsPage
                        control={control}
                        register={register}
                        handleCreateCoupon={handleCreateCoupon}
                        selectOptions={selectOptions}
                        setTerm={setTerm}
                        term={term}
                        setSelectShop={setSelectShop}
                        selectShop={selectShop}
                    />
                </form>
            </CustomMobileView>

            <div>
                <CustomBrowserView>
                    <ShopCoupons
                        coupons={arrayCoupons}
                        fields={getCouponsTableData(i18n, 'coupons')}
                    />
                </CustomBrowserView>
                <CustomMobileView>
                    {arrayCoupons.map((coupon) => (
                        <CouponItem
                            key={coupon.id}
                            coupon={coupon}
                            editType={'coupons'}
                        />
                    ))}
                </CustomMobileView>
            </div>
            <Route
                path={CustomerRM.shops.coupons.delete.path}
                render={({ match }) => (
                    <Modal
                        active={true}
                        setActive={() => {}}
                        title={shopCoupon.deletePopUpTitle}
                        cancelRedirect={() =>
                            history.push(CustomerRM.shops.coupons.path)
                        }
                    >
                        <div>
                            <p>{shopCoupon.deleteDescription}</p>
                            <div className={s.buttonsPopUp}>
                                <Button
                                    view={'Secondary'}
                                    title={buttons.CANCEL}
                                    onClick={() =>
                                        history.push(
                                            CustomerRM.shops.coupons.path
                                        )
                                    }
                                />
                                <Button
                                    view={'Delete'}
                                    title={buttons.DELETE}
                                    onClick={() =>
                                        handleDeleteCoupon(
                                            match.params['couponId']
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </Modal>
                )}
            />
            <Route
                path={CustomerRM.shops.coupons.edit.path}
                render={({ match }) => (
                    <CustomerCouponsModal location={'customer'} />
                )}
            />
        </div>
    )
}

export default CustomerCoupons
