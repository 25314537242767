import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../../BLL/Store'
import { actions, EditingEntityStatuses } from '../../../BLL/AppReducer'

export const useEditingEntityStatus = (entityId?: number | null | string) => {
    const editingEntityStatus = useSelector(
        (state: AppState) => state.appReducer.editingEntityStatus
    )
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(
            actions.setEditingEntityStatus(EditingEntityStatuses.InProgress)
        )
        return () => {
            dispatch(actions.setEditingEntityStatus(EditingEntityStatuses.None))
        }
    }, [entityId, dispatch])

    return { editingEntityStatus }
}
