import React, { useCallback, useState } from 'react'
import Table from 'UI/common/table/Table'
import { FileWithSelectedLicenses } from 'DAL/BeatSettingsAPI'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'
import { Button } from 'UI/common/elements/buttons/Button'
import {
    downloadFileLicense,
    removeBeatFileForSale,
} from 'BLL/CustomerMyBeatsReducer'
import { useDispatch } from 'react-redux'
import { MobTrackLicenses } from 'UI/CustomerAdmin/Mobile/MobMyBeatsPage/Paid/MobTracksLicenses/MobTrackLicenses'
import { CustomMobileView } from 'UI/common/mobileView/CustomMobileView'
import { CustomBrowserView } from 'UI/common/browserView/CustomBrowserView'
import { Modal } from 'UI/common/modal/modal'
import { getTrackFilesTableData } from 'UI/common/table/tableData/GetTrackFilesTableData'
import { CollapsedWrapper } from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/beatSettings/CollapsedWrapper'
import { SelectedModal } from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/types/types'
import DownloadFile from 'UI/assets/icons/download.svg'
import DeleteFile from 'UI/assets/icons/delete-files.svg'
import s from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/beatSettings/trackFiles/trackFiles.module.scss'

type Props = {
    files: FileWithSelectedLicenses[]
    setModalIsActive: (value: {
        isActive: boolean
        type: SelectedModal
    }) => void
    beatId: string
}

export const TrackFiles = ({ files, setModalIsActive, beatId }: Props) => {
    const dispatch = useDispatch()

    const [collapsed, setCollapsed] = useState(false)
    const [deleteFileId, setDeleteFileId] = useState<null | number>(null)

    const { i18n } = useTranslation()
    const { beatEdit, myBeats, buttons } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    const handleDeleteFile = useCallback(() => {
        deleteFileId && dispatch(removeBeatFileForSale(deleteFileId))
    }, [deleteFileId])

    const handleDownloadFile = async (file: FileWithSelectedLicenses) => {
        if (file.id) {
            dispatch(downloadFileLicense(+beatId, file))
        }
    }

    const fields = [
        ...getTrackFilesTableData(i18n),
        {
            title: beatEdit.filesTableColumn.action,
            field: 'id',
            headerStyle: {
                textAlign: 'right',
            },
            render: (file: FileWithSelectedLicenses) => (
                <div className={s.actions}>
                    <button type={'button'}>
                        <img
                            src={DownloadFile}
                            alt={'download-file'}
                            onClick={() => handleDownloadFile(file)}
                        />
                    </button>
                    <button type={'button'}>
                        <img
                            src={DeleteFile}
                            alt={'delete-file'}
                            onClick={() => setDeleteFileId(file.id)}
                        />
                    </button>
                </div>
            ),
        },
    ]
    return (
        <div className={s.trackFiles}>
            <div className={s.wrapperTitle}>
                <CollapsedWrapper
                    collapsed={collapsed}
                    setCollapsed={setCollapsed}
                    title={myBeats.beatSettings.files}
                />
                {collapsed && (
                    <div className={s.wrapperButton}>
                        <Button
                            view={'Outline'}
                            title={myBeats.formsField.uploadFile}
                            type={'button'}
                            onClick={() =>
                                setModalIsActive({
                                    isActive: true,
                                    type: 'sale',
                                })
                            }
                        />
                    </div>
                )}
            </div>
            {collapsed && (
                <>
                    <CustomBrowserView>
                        {!!files.length && (
                            <Table<FileWithSelectedLicenses>
                                items={files}
                                title={''}
                                fields={fields}
                                toolbar={false}
                            />
                        )}
                    </CustomBrowserView>
                    <CustomMobileView>
                        {files.map((file) => {
                            return (
                                <MobTrackLicenses
                                    key={file.id}
                                    file={file}
                                    handleDeleteFile={setDeleteFileId}
                                    handleDownloadFile={handleDownloadFile}
                                />
                            )
                        })}
                    </CustomMobileView>
                </>
            )}
            <Modal
                active={!!deleteFileId}
                setActive={() => setDeleteFileId(null)}
                title={myBeats.deletePopUpDialogMessage.title}
            >
                <div>
                    <p>{myBeats.deletePopUpDialogMessage.title}</p>
                    <div className={s.buttonsPopUp}>
                        <Button
                            view={'Secondary'}
                            type={'button'}
                            title={buttons.CANCEL}
                            onClick={() => setDeleteFileId(null)}
                        />
                        <Button
                            view={'Delete'}
                            type={'button'}
                            title={buttons.DELETE}
                            onClick={handleDeleteFile}
                        />
                    </div>
                </div>
            </Modal>
        </div>
    )
}
