import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'
import { NewTextField } from 'UI/common/elements/textFields/newtextField/NewTextField'
import LightGallery from 'lightgallery/react'
import lgZoom from 'lightgallery/plugins/zoom'
import s from 'UI/CustomerAdmin/BeatmakerSettingsNew/wallet-settings/walletSettings.module.scss'

type Props = {
    title?: string
    instruction?: string
    secretUrl?: string
    onCopy?: () => void
    imgSrc?: string
    link?: string
    linkText?: string
    children?: ReactNode
}
export const YooMoneyStep = ({
    title,
    instruction,
    secretUrl,
    onCopy,
    imgSrc,
    link,
    linkText,
    children,
}: Props) => {
    const { i18n } = useTranslation()
    const { settings } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    return (
        <div className={s.step}>
            <h3 className={s.stepTitle}>{title}:</h3>
            <span className={s.stepText}>
                {instruction}
                {link && (
                    <a
                        href={link}
                        className={s.link}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {linkText}
                    </a>
                )}
            </span>
            {secretUrl && (
                <div className={s.copyInputBox}>
                    <label className={s.disabledLabel}>
                        {settings.wallets.yooMoney.secretURL}
                    </label>
                    <NewTextField
                        value={secretUrl}
                        variant={'copy'}
                        onClick={onCopy}
                        disabled={true}
                    />
                </div>
            )}
            {imgSrc && (
                <div className={s.imgBox}>
                    <LightGallery
                        plugins={[lgZoom]}
                        mode="lg-fade"
                        enableDrag={false}
                        zoom={false}
                        download={false}
                        counter={false}
                    >
                        <a href={imgSrc}>
                            <img src={imgSrc} className={s.imgStyle} alt={''} />
                        </a>
                    </LightGallery>
                </div>
            )}
            {children}
        </div>
    )
}
