import React, { useEffect, useState } from 'react'
import { LanguageType } from 'translations/ru/common'
import i18n from 'i18next'
import { useSelector } from 'UI/common/hooks/redux-hooks'
import { useDispatch } from 'react-redux'
import { Button } from 'UI/common/elements/buttons/Button'
import {
    selectAddCard,
    selectCards,
    selectWallet,
} from 'BLL/selectors/wallet-selectors'
import Spinner from 'UI/common/progressBars/Spinner'
import { NewTextField } from 'UI/common/elements/textFields/newtextField/NewTextField'
import { CardsList } from './cardsList/cardsList'
import {
    fetchedAddCard,
    fetchedCards,
    selectActiveCard,
} from 'BLL/CustomerWalletsReducer'
import { EMPTY_CARD_LIST_VALUE, MAX_CARD_COUNT } from './constants/constants'
import s from 'UI/CustomerAdmin/BeatmakerSettingsNew/wallet-settings/wallets/bindingCard/bindingCard.module.scss'
import { TinkoffInstruction } from './instructions/TinkoffInstruction'
import { TinkoffTerms } from './terms/TinkoffTerms'

export const BindingCard = () => {
    const walletsInfo = useSelector(selectWallet)
    const redirectUrl = useSelector(selectAddCard)
    const cardList = useSelector(selectCards)
    const dispatch = useDispatch()

    const [activeCard, setActiveCard] = useState('')

    const onSelectCard = (cardId: string) => {
        setActiveCard(cardId)
    }

    const {
        settings: { wallets },
        beatmakerSettings,
    } = i18n.getResourceBundle(i18n.language, 'common') as LanguageType

    const cardHandler = () => {
        dispatch(fetchedAddCard())
    }

    useEffect(() => {
        dispatch(fetchedCards())
    }, [])

    useEffect(() => {
        if (activeCard) {
            dispatch(selectActiveCard(activeCard))
        }
    }, [activeCard, dispatch])

    if (!cardList) {
        return (
            <div className={s.spinnerWrapper}>
                <Spinner />
            </div>
        )
    }

    if (redirectUrl) {
        window.location.href = redirectUrl
    }

    return (
        <div>
            <TinkoffInstruction settings={wallets.tinkoff}></TinkoffInstruction>
            <div className={s.wrapper}>
                <TinkoffTerms
                    termsTitle={wallets.tinkoff.termsTitle}
                    terms={wallets.tinkoff.terms}
                />
                <p className={s.extra}>
                    {wallets.tinkoff.extra}{' '}
                    <a
                        className={s.link}
                        rel={'noreferrer'}
                        target={'_blank'}
                        href={'https://beatmaker.tv/help/priem_oplaty_za_bity'}
                    >
                        здесь.
                    </a>
                </p>
                <div className={s.bindingCardWrapper}>
                    <div className={s.contentWrapper}>
                        <NewTextField
                            label={beatmakerSettings.fields.myId}
                            defaultValue={walletsInfo?.userId}
                            disabled={true}
                            className={s.textField}
                        />
                        <div className={s.btnBox}>
                            <Button
                                onClick={cardHandler}
                                disabled={cardList.length >= MAX_CARD_COUNT}
                            >
                                {wallets.tinkoff.bindNewCard}
                            </Button>
                        </div>
                    </div>
                    {cardList && cardList.length > EMPTY_CARD_LIST_VALUE && (
                        <>
                            <h3 className={s.title}>
                                {wallets.tinkoff.cardList}
                            </h3>
                            <CardsList
                                cardList={cardList}
                                onSelectCard={onSelectCard}
                            />
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}
