import { format } from 'date-fns'

export const CONSTANTS = {
    SAVE: 'Сохранить',
    CANCEL: 'Отменить',
    ACCEPT: 'Подтвердить',
    CLOSE: 'Закрыть',
    SEND: 'Отправить',
    RESERVE: 'Забронировать',
    PUBLISHED: 'Опубликовать',
    ERROR_MESSAGE: 'ERROR_MESSAGE',
    SUCCESS_MESSAGE: 'SUCCESS_MESSAGE',
    MORE: 'Загрузить еще',
    DELETE: 'Удалить',
    YES: 'Да',
    ADD: 'Добавить',
    SKIP: 'Пропустить',
    NO: 'Нет',
    COPY: 'COPY_SUCCESS',
    COPY_FAILED: 'COPY_FAILED',
}

export const WALLETS = {
    None: -1,
    Yandex: 0,
    Webmoney: 1,
    Sberbank: 2,
    Qiwi: 3,
    Paypal: 4,
}

export const PAYMENT_SOURCES = {
    0: 'None',
    1: 'BeatmakerTV',
    2: 'BeatmakersTV',
    3: 'ProducerPage',
    4: 'Widget',
}

export const STATUSES = {
    LOADING: 'LOADING',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
    WARNING: 'WARNING',
    NOT_INIT: 'NOT_INIT',
}

export const STATISTICS = ['ВСЕ', 'БЕСПЛАТНЫЕ', 'ЭКСПЕРТЫ', 'ПРЕМИУМ']

export interface ISourceType {
    [key: number]: string
}

export const formatDate = (date: any) => format(new Date(date), 'yyyy-MM-dd')
export const formatDatePublications = (date: any) =>
    format(new Date(date), 'yyyy-MM-dd hh:mm')

export const getDate = (date: string) => {
    if (date) {
        return date.slice(0, 10)
    } else {
        let q = new Date()
        let year = q.getFullYear()
        let month = q.getMonth() + 1
        let day = q.getDate()
        return `${year}-0${month}-${day}`
    }
}

export const createStatusColorObj = (status: number, flag: string) => {
    let statusColor = { color: '', fontWeight: '', backgroundColor: '' }

    if (flag === 'shop' && status === 1) {
        statusColor.backgroundColor = 'rgba(255, 166, 0, .3)'
    } else if (flag === 'customer' && status === 4) {
        statusColor.backgroundColor = 'rgba(168, 255, 97, .4)'
    }

    switch (status) {
        case 0:
            statusColor.color = 'black'
            return statusColor
        case 1:
            statusColor.color = 'orange'
            return statusColor
        case 2:
            statusColor.color = 'red'
            return statusColor
        case 3:
            statusColor.color = 'rgb(99, 103, 181)'
            statusColor.backgroundColor = 'rgba(236, 219, 255, 1)'
            return statusColor
        case 4:
            statusColor.color = 'rgb(11, 186, 81)'
            statusColor.fontWeight = 'bold'
            return statusColor
        default:
            return statusColor
    }
}

export const createStatus = (status: number) => {
    switch (status) {
        case 0:
            return 'Создан'
        case 1:
            return 'Ожидает подтверждения'
        case 2:
            return 'Отклонен продавцом'
        case 3:
            return 'Уточните информацию'
        case 4:
            return 'Подтверждён'
        default:
            return 'Ошибка, сообщите админу'
    }
}

export const createCurrency = (currency: number) => (currency ? 'USD' : 'RUB')

export const getLineName = (value: string) => {
    interface IVal {
        [key: string]: any
    }

    let val: IVal | string = {}

    switch (value) {
        case 'sails':
            val.menuTitle = 'Продажи'
            val.lineName = 'Покупатели'
            val.graphicName = 'График покупателей'
            break
        case 'listeners':
            val.menuTitle = val.lineName = 'Слушатели'
            val.graphicName = 'График слушателей'
            break
        default:
            val = 'Graphic'
    }

    return val
}

export const getCurrentMonth = () => {
    return new Date().getMonth() + 1
}

export const getCurrentYear = () => {
    return new Date().getFullYear()
}

export const dateToUtc = (date: Date) => {
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hours = date.getHours()
    const minutes = date.getMinutes()
    return new Date(Date.UTC(year, month, day, hours, minutes))
}
