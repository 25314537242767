import React, { useEffect, useState } from 'react'
import { CalendarDialog } from './Calendar/CalendarDialog'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'
import { useSelector } from 'UI/common/hooks/redux-hooks'
import { useDispatch } from 'react-redux'
import {
    actions as promoActions,
    getAllPromo,
    getPromoPrice,
    getPromos,
} from 'BLL/CustomerBeatsPromotionReducer'
import { useRedirectCustomHook } from 'UI/common/hooks/useRedirectCustomHook'
import { RolesTypeEnum } from 'BLL/enums'
import Table from 'UI/common/table/Table'
import { PromoType } from 'BLL/types'
import { Route, useHistory } from 'react-router-dom'
import BeatSettingsPopup from './BeatSettingsPopup'
import { CustomerRM } from 'UI/routers/RouterManager'
import { ReactComponent as StatisticsIcon2 } from '../../assets/icons/statistics2.svg'
import { ReactComponent as StatisticsIcon } from '../../assets/icons/statistics.svg'
import { Button } from 'UI/common/elements/buttons/Button'
import s from './beats-promo.module.scss'
import { Modal } from 'UI/common/modal/modal'
import { MobAdvertisingBeats } from '../Mobile/MobAdvertisingBeats/MobAdvertisingBeats'
import { Pagination } from 'UI/common/elements/pagination/Pagination'
import { InfoPopUp } from './InfoPromo'
import { CustomBrowserView } from 'UI/common/browserView/CustomBrowserView'
import { CustomMobileView } from 'UI/common/mobileView/CustomMobileView'
import { RequestStatuses } from 'BLL/AppReducer'
import {
    selectPage,
    selectPageSize,
    selectTotalCount,
} from 'BLL/selectors/promotion-selectors'
import { selectRequestStatus } from 'BLL/selectors/app-selectors'
import { getPromoTableData } from 'UI/common/table/tableData/GetPromoTableData'

export const Promotions = () => {
    const promotions = useSelector(getAllPromo)
    const requestStatus = useSelector(selectRequestStatus)
    const page = useSelector(selectPage)
    const pageSize = useSelector(selectPageSize)
    const totalCount = useSelector(selectTotalCount)
    const dispatch = useDispatch()

    const history = useHistory()

    const { i18n } = useTranslation()
    const { customerBeatsPromotion, navigation, buttons } =
        i18n.getResourceBundle(i18n.language, 'common') as LanguageType

    const [showInfo, setShowInfo] = useState(false)
    const [calendarIsOpen, setCalendarIsOpen] = useState(false)

    const handleChangePage = (page: number) => {
        dispatch(promoActions.setPage(page))
    }

    const handleChangeRowsPerPage = (pageSize: number) => {
        dispatch(promoActions.setPageSize(pageSize))
        dispatch(promoActions.setPage(1))
    }

    useEffect(() => {
        dispatch(getPromoPrice())
    }, [])

    useEffect(() => {
        dispatch(getPromos())
    }, [page, pageSize])

    const fields = [...getPromoTableData(i18n)]

    const detailPanel = [
        {
            tooltip: customerBeatsPromotion.showStatistics,
            icon: StatisticsIcon2,
            openIcon: StatisticsIcon,
            render: (promotion: PromoType) => {
                return (
                    <div className={s.detail}>
                        <div>
                            {customerBeatsPromotion.countShows}{' '}
                            {promotion.stats.shows}
                        </div>
                    </div>
                )
            },
        },
    ]

    return (
        <div>
            {useRedirectCustomHook(RolesTypeEnum.BEATMAKER)}
            <Route
                path={CustomerRM.promotions.path.edit}
                render={() => (
                    <Modal
                        setActive={() => {}}
                        title={customerBeatsPromotion.selectAdOption}
                        active={true}
                        cancelRedirect={() => history.push('/beats-promotion')}
                    >
                        <BeatSettingsPopup />
                    </Modal>
                )}
            />
            <CustomBrowserView>
                <div className={s.topWrapper}>
                    <div className={s.pageTitle}>{navigation.beatsPromo}</div>
                    <div className={s.searchWrapper}>
                        <Button
                            title={buttons.ADD}
                            onClick={() => setCalendarIsOpen(true)}
                        />
                    </div>
                </div>
                {promotions.length ? (
                    <>
                        <InfoPopUp
                            showInfo={showInfo}
                            setShowInfo={setShowInfo}
                        />
                        {
                            <div>
                                <Table<PromoType>
                                    items={promotions}
                                    fields={fields}
                                    title={''}
                                    detailPanel={detailPanel}
                                />
                                <Pagination
                                    currentPage={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={
                                        handleChangeRowsPerPage
                                    }
                                    pageSize={pageSize}
                                    totalCount={totalCount}
                                />
                            </div>
                        }
                    </>
                ) : (
                    requestStatus !== RequestStatuses.InProgress && (
                        <div className={s.emptyMessage}>
                            {customerBeatsPromotion.tableNoPromoMessage}
                        </div>
                    )
                )}
            </CustomBrowserView>
            <CustomMobileView>
                <MobAdvertisingBeats
                    setCalendarIsOpen={setCalendarIsOpen}
                    promotions={promotions}
                />
            </CustomMobileView>
            <CustomMobileView>
                <div className={s.pagination}>
                    <Pagination
                        currentPage={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        pageSize={pageSize}
                        totalCount={totalCount}
                    />
                </div>
            </CustomMobileView>
            <CalendarDialog
                calendarIsOpen={calendarIsOpen}
                setCalendarIsOpen={setCalendarIsOpen}
            />
        </div>
    )
}
