import React, { FC } from 'react'
import { createStyles } from '@material-ui/core'
import { formatDate } from '../../../BLL/SupportingFile'
import TablePagination from '@material-ui/core/TablePagination'
import { MobileView } from 'react-device-detect'
import DeletePopupDialog from './DeletePopupDialog'
import { ISubscribers } from '../../../DAL/SubscribersAPI'
import { useTranslation } from 'react-i18next'
import { LanguageType } from '../../../translations/ru/common'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() =>
    createStyles({
        tableWrapper: {
            minWidth: '100%',
            marginTop: 8,
        },
        toolbar: {
            display: 'flex',
            flexDirection: 'column',
        },
        wrapperBlock: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: 10,
            margin: 10,
            boxShadow: '0px 0px 5px 0px rgba(148,148,148,1)',
            borderRadius: 5,
            fontSize: 13,
        },
        wrapper: {
            display: 'flex',
            flexDirection: 'column',
        },
        title: {
            fontWeight: 'bold',
        },
    })
)

interface IProps {
    subscribers: ISubscribers[]
    totalSubscribers: number
    pageSize: number
    currentPage: number
    handleDeleteSubscribe: (id: string) => void
    setPageSize: (pageSize: number) => void
    setCurrentPage: (currentPage: number) => void
}

export const SubscribersMobile: FC<IProps> = ({
    subscribers,
    totalSubscribers,
    pageSize,
    setPageSize,
    setCurrentPage,
    currentPage,
    handleDeleteSubscribe,
}) => {
    const classes = useStyles()
    const { i18n } = useTranslation()
    const { customerBeatSubscribers, sourceType, pagination } =
        i18n.getResourceBundle(i18n.language, 'common') as LanguageType

    const handleChangeRowsPerPage = (event: any) => {
        setPageSize(event.target.value)
    }

    const handleChangePage = (event: any, page: number) => {
        setCurrentPage(page + 1)
    }
    return (
        <div className={classes.tableWrapper}>
            <MobileView>
                {subscribers.map((subscriber: ISubscribers) => (
                    <div className={classes.wrapper} key={subscriber._id}>
                        <div className={classes.wrapperBlock}>
                            <div>
                                <div>
                                    <span className={classes.title}>
                                        {
                                            customerBeatSubscribers.tableColumns
                                                .subscriptionDate
                                        }
                                        :{' '}
                                    </span>
                                    {formatDate(subscriber.subscribedAt)}
                                </div>
                                <div>
                                    <span className={classes.title}>
                                        {
                                            customerBeatSubscribers.tableColumns
                                                .email
                                        }
                                        :{' '}
                                    </span>
                                    {subscriber.subscriber.email}
                                </div>
                                <div>
                                    <span className={classes.title}>
                                        {
                                            customerBeatSubscribers.tableColumns
                                                .name
                                        }
                                        :{' '}
                                    </span>
                                    {subscriber.subscriber.name}
                                </div>
                                <div>
                                    <span className={classes.title}>
                                        {
                                            customerBeatSubscribers.tableColumns
                                                .pageSubscription
                                        }
                                        :{' '}
                                    </span>
                                    {sourceType[subscriber.sourceType]}
                                </div>
                            </div>
                            <div>
                                <DeletePopupDialog
                                    id={subscriber._id}
                                    handleDeleteSubscribe={
                                        handleDeleteSubscribe
                                    }
                                />
                            </div>
                        </div>
                    </div>
                ))}
                <TablePagination
                    rowsPerPageOptions={[5, 10, 20, 50]}
                    count={totalSubscribers}
                    component={'div'}
                    rowsPerPage={pageSize}
                    page={currentPage - 1}
                    labelRowsPerPage={pagination.lines}
                    labelDisplayedRows={({ from, to, count }) =>
                        `${from}-${to} ${pagination.from} ${count}`
                    }
                    backIconButtonProps={{
                        'aria-label': 'Previous Page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Next Page',
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </MobileView>
        </div>
    )
}
