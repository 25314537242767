import React from 'react'
import { DiscountType } from 'DAL/DiscountAPI'
import { Status } from 'UI/common/status/Status'
import { LanguageType } from 'translations/ru/common'
import { AppSettings } from 'settings/appSettings'
import { getLocaleDateString } from 'UI/utils/util-types'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { CustomerRM } from 'UI/routers/RouterManager'
import { CouponItem } from './CouponTableItem'
import EditIcon from '../../../../assets/icons/edit.svg'
import DeleteIcon from 'UI/assets/icons/delete.svg'
import s from 'UI/CustomerAdmin/Mobile/MobDiscountsPage/Coupon/coupon.module.scss'

type Props = {
    discount: DiscountType
}

export const Coupon = ({ discount }: Props) => {
    const { i18n } = useTranslation()

    const { discounts } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    const isEng = AppSettings.api.isEngVersion()
    const license = discount.licenses?.find((l) => l.id === discount.licenseId)

    return (
        <div className={s.coupon}>
            <div className={s.list}>
                <CouponItem title={discounts.tableColumn.coupon}>
                    <span className={s.value}>{discount.code}</span>
                </CouponItem>
                <CouponItem title={discounts.tableColumn.status}>
                    <div className={s.statusBox}>
                        <Status
                            status={discount.status ? 'Active' : 'Closed'}
                        />
                    </div>
                </CouponItem>
                <CouponItem title={discounts.tableColumn.discounts}>
                    <span className={s.value}>{discount.discountSize} %</span>
                </CouponItem>
                <CouponItem title={discounts.tableColumn.licenses}>
                    <span className={s.value}>
                        {(license &&
                            (isEng ? license.titleEn : license.title)) ||
                            discounts.allLicense}
                    </span>
                </CouponItem>
                <CouponItem title={discounts.tableColumn.dueDate}>
                    <span className={s.value}>
                        {getLocaleDateString(
                            i18n.language,
                            discount.finishDate
                        )}
                    </span>
                </CouponItem>
                <CouponItem title={discounts.tableColumn.purchasedBeats}>
                    <span className={s.value}>
                        {discount.closedBeatsCount} / {discount.beatsCount}
                    </span>
                </CouponItem>
            </div>
            <CouponItem title={discounts.tableColumn.action}>
                <div className={s.btnBox}>
                    <NavLink to={CustomerRM.discounts.edit.getUrl(discount.id)}>
                        <img className={s.icons} src={EditIcon} alt={'edit'} />
                    </NavLink>
                    <NavLink
                        to={CustomerRM.discounts.deleteCoupon.getUrl(
                            discount.id
                        )}
                    >
                        <img
                            className={s.icons}
                            src={DeleteIcon}
                            alt={'delete'}
                        />
                    </NavLink>
                </div>
            </CouponItem>
        </div>
    )
}
