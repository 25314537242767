import React from 'react'
import classes from './MobCommon.module.scss'
import arrow from '../../../assets/icons/arrow-left.svg'
import { NavLink } from 'react-router-dom'

type PropsType = {
    title?: string | null
    redirectUrl: string
}
export const MobReturn: React.FC<PropsType> = ({ title, redirectUrl }) => {
    return (
        <div className={classes.MobReturn}>
            <NavLink to={redirectUrl}>
                <button>
                    <img src={arrow} alt="" />
                </button>
            </NavLink>
            <h1>{title}</h1>
        </div>
    )
}
