import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { fetchedWallets } from 'BLL/CustomerWalletsReducer'
import { useSelector } from 'UI/common/hooks/redux-hooks'
import { AppSettings } from 'settings/appSettings'
import { PayPalForm } from 'UI/CustomerAdmin/BeatmakerSettingsNew/wallet-settings/wallets/payPal/PayPal'
import { YooMoney } from 'UI/CustomerAdmin/BeatmakerSettingsNew/wallet-settings/wallets/yooMoney/YooMoney'
import { EnotForm } from './wallets/enot/Enot'
import { selectWallet } from 'BLL/selectors/wallet-selectors'
import { QiwiP2P } from './wallets/qiwiP2P/QiwiP2P'
import { WalletPayVia, Wallets } from './walletPayVia/WalletPayVia'
import { BindingCard } from 'UI/CustomerAdmin/BeatmakerSettingsNew/wallet-settings/wallets/bindingCard/BindingCard'
import s from 'UI/CustomerAdmin/BeatmakerSettingsNew/wallet-settings/walletSettings.module.scss'

export const WalletSettings = () => {
    const wallets = useSelector(selectWallet)
    const dispatch = useDispatch()

    const isEng = AppSettings.api.isEngVersion()

    const CurrentWalletComponent = {
        Tinkoff: <BindingCard />,
        PayPal: <PayPalForm />,
        YooMoney: <YooMoney />,
        Enot: <EnotForm />,
        QiwiP2P: <QiwiP2P />,
    }

    const [currentWallet, setCurrentWallet] = useState<Wallets>('Tinkoff')

    useEffect(() => {
        dispatch(fetchedWallets())
    }, [])

    const handleChangeCurrentWallet = (wallet: Wallets) => {
        setCurrentWallet(wallet)
    }

    return (
        <div className={s.wrapper}>
            <div className={s.wallet}>
                <div className={s.walletMethod}>
                    <div className={s.methods}>
                        {!isEng && (
                            <WalletPayVia
                                isSelected={currentWallet === 'Tinkoff'}
                                isActivated={false}
                                payMethod={'Tinkoff'}
                                handleChangeCurrentWallet={
                                    handleChangeCurrentWallet
                                }
                            />
                        )}
                    </div>
                    <div className={s.methods}>
                        <WalletPayVia
                            isSelected={currentWallet === 'PayPal'}
                            isActivated={!!wallets?.paypalIsActive}
                            payMethod={'PayPal'}
                            handleChangeCurrentWallet={
                                handleChangeCurrentWallet
                            }
                        />
                    </div>
                    <div className={s.methods}>
                        {!isEng && (
                            <WalletPayVia
                                isSelected={currentWallet === 'YooMoney'}
                                isActivated={!!wallets?.yandexIsActive}
                                payMethod={'YooMoney'}
                                handleChangeCurrentWallet={
                                    handleChangeCurrentWallet
                                }
                            />
                        )}
                    </div>
                    <div className={s.methods}>
                        {!isEng && (
                            <WalletPayVia
                                isSelected={currentWallet === 'Enot'}
                                isActivated={false}
                                payMethod={'Enot'}
                                handleChangeCurrentWallet={
                                    handleChangeCurrentWallet
                                }
                            />
                        )}
                    </div>
                    {/*wallet temporary is removed*/}
                    {/*<div className={s.methods}>*/}
                    {/*    {!isEng && (*/}
                    {/*        <WalletPayVia*/}
                    {/*            isSelected={currentWallet === 'QiwiP2P'}*/}
                    {/*            isActivated={false}*/}
                    {/*            payMethod={'QiwiP2P'}*/}
                    {/*            handleChangeCurrentWallet={*/}
                    {/*                handleChangeCurrentWallet*/}
                    {/*            }*/}
                    {/*        />*/}
                    {/*    )}*/}
                    {/*</div>*/}
                </div>
                <>{CurrentWalletComponent[currentWallet]}</>
            </div>
        </div>
    )
}
