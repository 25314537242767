import { apiCoreAxios, axiosCore } from './createAxios'
import { BaseResponse, P2PKeysType } from 'BLL/types'

export const WalletsAPI = {
    async getWallets(): Promise<WalletsType> {
        return apiCoreAxios
            .get<WalletsType>(`/api/wallets/direct`)
            .then((r) => r.data)
    },

    async getEnotWallet() {
        return apiCoreAxios
            .get<WalletsType>(`/api/wallets/enot`)
            .then((r) => r.data)
    },

    async getQiwiWallet() {
        return apiCoreAxios.get(`/api/wallets/qiwi`).then((r) => r.data)
    },

    async updatePaypal(email: string): Promise<BaseResponse<null>> {
        return axiosCore
            .put<BaseResponse<null>>(
                `/beatsManagement/wallets/UpdatePaypalWallet`,
                { walletNumber: email }
            )
            .then((r) => r.data)
    },

    async updateEnotWallet(data: UpdateEnoWalletType) {
        return apiCoreAxios
            .put<BaseResponse<null>>(`/api/wallets/enot`, data)
            .then((r) => r.data)
    },

    async updateQiwiP2PKeys(keys: P2PKeysType): Promise<BaseResponse<null>> {
        return axiosCore
            .put<BaseResponse<null>>(
                `/beatsManagement/wallets/UpdateQiwiP2PKeys`,
                keys
            )
            .then((r) => r.data)
    },

    async updateYandex(
        secret: string,
        walletNumber: number
    ): Promise<BaseResponse<null>> {
        return axiosCore
            .put<BaseResponse<null>>(
                `/beatsManagement/wallets/UpdateYandexSecret`,
                { secret, walletNumber }
            )
            .then((r) => r.data)
    },
    async deactivateYandex(): Promise<BaseResponse<null>> {
        return axiosCore
            .post<BaseResponse<null>>(
                `BeatsManagement/Wallets/DeactivateYandexWallet`
            )
            .then((r) => r.data)
    },

    async tinkoffWallets() {
        return apiCoreAxios
            .get<WalletsType>('api/wallets/tinkoff')
            .then((r) => r.data)
    },

    async addCard() {
        return axiosCore
            .get<BaseResponse<RedirectURLForAddingCard>>(
                `payment/tinkoff/payout/cards/url`
            )
            .then((r) => r.data)
    },

    async getCards() {
        return axiosCore
            .get<BaseResponse<Cards>>(`payment/tinkoff/payout/cards`)
            .then((r) => r.data.data.cardList)
    },

    async activateCard(CardId: string) {
        return axiosCore
            .post(`payment/tinkoff/payout/cards/${CardId}/activate`)
            .then((r) => r.data)
    },

    async deleteCard(cardId: string) {
        return axiosCore
            .delete(`payment/tinkoff/payout/cards/${cardId}`)
            .then((r) => r.data)
    },
}

export type WalletsWithdrawal = {
    addedDate: Date
    isForeground: boolean
    number: string
    phoneNumber: string
    type: number
    userId: number
}

export type UpdateWalletsDto = {
    sberbankNumber: string
    sberbankPhoneNumber: string
    yandex: string
    webmoney: string
    qiwiPhoneNumber: string
}

export type WalletsType = {
    id: string
    userId: number
    enotNumber: string
    enotSecret: string
    enotApiSecretKey: string
    enotIsActive: boolean
    htmlEnotFileName: string
    yandexWallet: number
    yandexSecret: string
    yandexIsActive: boolean
    paypalWallet: string
    paypalIsActive: boolean
    qiwiIsActive: boolean
    qiwiShopId: number
    qiwiApiId: number
    qiwiApiKey: string
    qiwiNotificationPassword: string
    qiwiP2PPublickKey: string
    qiwiP2PPrivateKey: string
    addedDate: Date
    tinkoffCardId: string
    tinkoffCustomerKey: string
    tinkoffIsActive: boolean
}

export type Cards = {
    cardList: CardList[]
}

export type UpdateEnoWalletType = {
    enotHtmlFileName: string
    enotNumber: string
    enotSecret: string
    enotSecretForForm: string
}

export type CardList = {
    CardId: string
    ExpDate: string
    IsCurrent: boolean
    Pan: string
    RebillId: number
    Status: string
}

export type RedirectURLForAddingCard = {
    url: string
}
