import React from 'react'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'
import ChartIcon from 'UI/assets/icons/charts.svg'
import DownloadIcon from 'UI/assets/icons/download.svg'
import ListenIcon from 'UI/assets/icons/listen.svg'
import s from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/draggableTable/draggableTable.module.scss'

export const TableHeader = () => {
    const { i18n } = useTranslation()
    const { myBeats } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    const tableHeaderColumn = [
        {
            id: 1,
            className: 'name',
            alt: '',
            img: '',
            title: myBeats.tableColumns.title,
        },
        {
            id: 2,
            className: 'chartPlace',
            alt: 'chart',
            img: ChartIcon,
            title: myBeats.tableColumns.chart,
        },
        {
            id: 3,
            className: 'download',
            alt: 'download',
            img: DownloadIcon,
            title: myBeats.tableColumns.downloads,
        },
        {
            id: 4,
            className: 'listens',
            alt: 'listen',
            img: ListenIcon,
            title: myBeats.tableColumns.listening,
        },
        {
            id: 5,
            className: 'action',
            alt: '',
            img: '',
            title: myBeats.tableColumns.action,
        },
    ]

    return (
        <div className={s.beatsHeader}>
            {tableHeaderColumn.map((el) => (
                <div key={el.id} className={s[el.className]}>
                    {el.img && <img src={el.img} alt={el.alt} />}
                    <span>{el.title}</span>
                </div>
            ))}
        </div>
    )
}
