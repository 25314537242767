import { AppState, InferActionsTypes } from './Store'
import { ThunkAction } from 'redux-thunk'
import {
    actions as appActions,
    AuthActionsTypes,
    EditingEntityStatuses,
} from './AppReducer'
import {
    commonAsyncHandler,
    verifyApiResultResponse,
} from './common-async-handler'
import {
    BeatDetailsType,
    BeatSettingsAPI,
    FreeBeatType,
    PaginationResponseType,
} from 'DAL/BeatSettingsAPI'
import { UpdateFreeBeat } from 'UI/CustomerAdmin/FreeBeatsPage/editFreeBeat/types'
import { ThunkActionType } from './types'

const initState = {
    pagesCount: 0,
    pageSize: 100,
    page: 1,
    totalCount: 0,
    items: [] as FreeBeatType[],
    currentBeat: null as null | BeatDetailsType,
}

type StateType = typeof initState

export type ActionsTypes =
    | InferActionsTypes<typeof FreeBeatsActions>
    | AuthActionsTypes

const customerFreeBeatsReducer = (
    state = initState,
    action: ActionsTypes
): StateType => {
    switch (action.type) {
        case 'FREE_BEATS_REDUCER/SET_BEATS':
            return {
                ...state,
                ...action.beatsData,
            }
        case 'FREE_BEATS_REDUCER/SET_PAGE':
            return {
                ...state,
                page: action.page,
            }
        case 'FREE_BEATS_REDUCER/SET_PAGE_COUNT':
            return {
                ...state,
                pageSize: action.pageSize,
            }
        case 'FREE_BEATS_REDUCER/UPDATE_BEAT':
            return {
                ...state,
                items: state.items.map((item) => {
                    if (item.id === action.beat.id) {
                        return {
                            ...item,
                            ...action.beat,
                            title: action.beat.name,
                        }
                    }
                    return item
                }),
            }
        default:
            return state
    }
}

export const FreeBeatsActions = {
    setBeatsData: (beatsData: PaginationResponseType<FreeBeatType>) =>
        ({
            type: 'FREE_BEATS_REDUCER/SET_BEATS',
            beatsData,
        } as const),
    setPage: (page: number) =>
        ({ type: 'FREE_BEATS_REDUCER/SET_PAGE', page } as const),
    updateBeat: (beat: UpdateFreeBeat) =>
        ({ type: 'FREE_BEATS_REDUCER/UPDATE_BEAT', beat } as const),
    deleteFile: (fileId: number) =>
        ({ type: 'FREE_BEATS_REDUCER/DELETE_FILE', fileId } as const),
    setPageSize: (pageSize: number) =>
        ({ type: 'FREE_BEATS_REDUCER/SET_PAGE_COUNT', pageSize } as const),
}

export const getFreeBeats =
    (
        page: number = 1,
        pageSize: number = 50,
        term = '',
        isArchive = false
    ): ThunkActionType<ActionsTypes> =>
    async (dispatch, getState) => {
        await commonAsyncHandler(async () => {
            const beatsData = await BeatSettingsAPI.getFreeBeats(
                pageSize,
                page,
                term,
                isArchive
            )
            dispatch(FreeBeatsActions.setBeatsData(beatsData))
            return beatsData
        }, dispatch)
    }

export const uploadFreeBeat =
    (
        formData: any,
        onUploadProgress: (progressEvent: any) => void,
        cancelToken: any
    ): ThunkActionType<ActionsTypes> =>
    async (dispatch, getState) => {
        debugger
        await commonAsyncHandler(async () => {
            const response = await BeatSettingsAPI.uploadFreeBeat(
                formData,
                onUploadProgress,
                cancelToken
            )

            verifyApiResultResponse(response, dispatch)
            return response
        }, dispatch)
    }

export const deleteFreeBeat =
    (beatId: number): ThunkActionType<ActionsTypes> =>
    async (dispatch, getState) => {
        await commonAsyncHandler(async () => {
            const response = await BeatSettingsAPI.deleteFreeBeat(beatId)
            verifyApiResultResponse(response, dispatch, () => {
                dispatch(
                    appActions.setEditingEntityStatus(
                        EditingEntityStatuses.Success
                    )
                )
                const { pageSize, page } = getState().freeBeatsPage
                dispatch(getFreeBeats(page, pageSize))
            })
        }, dispatch)
    }

export const updateFreeBeat =
    (update: UpdateFreeBeat): ThunkActionType<ActionsTypes> =>
    async (dispatch, getState) => {
        await commonAsyncHandler(async () => {
            const response = await BeatSettingsAPI.updateFreeBeat(update)
            verifyApiResultResponse(response, dispatch, () => {
                dispatch(
                    appActions.setEditingEntityStatus(
                        EditingEntityStatuses.Success
                    )
                )
                dispatch(FreeBeatsActions.updateBeat(update))
            })
        }, dispatch)
    }

export default customerFreeBeatsReducer
