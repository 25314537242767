import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'
import { FormControlLabel } from '@material-ui/core'
import { useSelector } from 'UI/common/hooks/redux-hooks'
import { useDispatch } from 'react-redux'
import { uploadBeatImage } from 'BLL/CustomerMyBeatsReducer'
import { ImageCropper } from '../../imageCropper/ImageCropper'
import { Modal } from 'UI/common/modal/modal'
import { AppSettings } from 'settings/appSettings'
import { TagInputField } from 'UI/common/elements/textFields/TagInput'
import { CustomCheckbox } from 'UI/common/elements/CustomCheckbox'
import { Button } from 'UI/common/elements/buttons/Button'
import { copyBuffer } from 'UI/common/elements/textFields/newtextField/utils/copyBuffer'
import { useFormContext } from 'react-hook-form'
import SongIcon from 'UI/assets/icons/song.svg'
import s from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/beatSettings/mainForm/mainInfo.module.scss'
import { NewTextField } from 'UI/common/elements/textFields/newtextField/NewTextField'
import { selectCurrentBeat } from 'BLL/selectors/myBeats-selectors'
import { ControlledTextField } from 'UI/common/controls/controlledTextField/ControlledTextField'

type Props = {
    artwork: string
    setArtWork: (value: string) => void
    tags: string[]
    setTags: (tags: string[]) => void
    beatId: number
    setModalIsActive: (value: {
        isActive: boolean
        type: 'mp3' | 'sale'
    }) => void
    setDemoDownloading: (value: boolean) => void
    demoDownloading: boolean
    beatName: string
}

export const MainInfo = ({
    artwork,
    setArtWork,
    beatId,
    tags,
    setTags,
    setModalIsActive,
    demoDownloading,
    setDemoDownloading,
    beatName,
}: Props) => {
    const currentBeat = useSelector(selectCurrentBeat)
    const dispatch = useDispatch()

    const {
        formState: { errors },
        control,
    } = useFormContext()

    const [imageModalIsActive, setImageModalIsActive] = useState(false)

    useEffect(() => {
        currentBeat &&
            setDemoDownloading(currentBeat.contextBeat.demoDownloading)
    }, [currentBeat])

    const { i18n } = useTranslation()
    const { myBeats, adminExpertsCompilation } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    const handleSaveArtWork = async (formData: FormData) => {
        dispatch(uploadBeatImage(beatId, formData))
    }

    const handleCopyLink = useCallback(
        () =>
            copyBuffer(
                `${AppSettings.urls.MAIN_DOMAIN}beat/${beatId}`,
                dispatch
            ),
        [beatId]
    )

    const handleUploadMp3 = useCallback(() => {
        setModalIsActive({ isActive: true, type: 'mp3' })
    }, [])

    return (
        <div className={s.mainFormWrapper}>
            <div className={s.fieldsLeft}>
                <div className={s.beatName}>
                    <ControlledTextField
                        label={`${myBeats.formsField.beatTitle}*`}
                        name={'name'}
                        control={control}
                        error={errors.name}
                        required={true}
                    />
                </div>
                <div className={s.tags}>
                    <div>{myBeats.formsField.tags}</div>
                    <TagInputField tags={tags} setTags={setTags} />
                </div>
                <NewTextField
                    label={myBeats.formsField.urlOfYourTrack}
                    variant={'copy'}
                    value={`${AppSettings.urls.MAIN_DOMAIN}beat/${beatId}`}
                    onClick={handleCopyLink}
                    disabled={true}
                />
            </div>
            <div className={s.fieldsCenter}>
                <div>{myBeats.beatSettings.audiofileForStreaming}</div>
                <div className="commonBeatInfoWrapper">
                    <img src={SongIcon} alt={'song'} />
                    <div className="commonBeatInfo">
                        <div className="commonBeatFullName">
                            {`${beatName}.mp3`}
                        </div>
                        <FormControlLabel
                            control={
                                <CustomCheckbox
                                    name={'demoDownloading'}
                                    checked={demoDownloading}
                                    onChange={({ target: { checked } }) =>
                                        setDemoDownloading(checked)
                                    }
                                />
                            }
                            label={myBeats.filesForPlayer.demoDownloading}
                            labelPlacement={'end'}
                        />
                    </div>
                </div>
                <Button
                    view={'Outline'}
                    title={myBeats.formsField.updateFileMp3}
                    type={'button'}
                    onClick={handleUploadMp3}
                />
            </div>
            <div className={s.fieldsRight}>
                <div className={s.wrapperImage}>
                    {artwork ? (
                        <img src={artwork} alt={'beatImage'} />
                    ) : (
                        <div className={s.noImage} />
                    )}
                    <div className={s.supportsText}>
                        {myBeats.beatSettings.supportArt}
                    </div>
                    <Button
                        view={'Outline'}
                        type={'button'}
                        onClick={() => setImageModalIsActive(true)}
                        title={adminExpertsCompilation.changeArtWork}
                    />
                </div>
                <Modal
                    active={imageModalIsActive}
                    setActive={setImageModalIsActive}
                    title={myBeats.beatSettings.changeCover}
                >
                    <ImageCropper
                        saveFile={handleSaveArtWork}
                        handleClose={setImageModalIsActive}
                        setArtWork={setArtWork}
                    />
                </Modal>
            </div>
        </div>
    )
}
