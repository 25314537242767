import React, { useCallback } from 'react'
import { Route, useHistory } from 'react-router-dom'
import { CustomerRM } from 'UI/routers/RouterManager'
import { Modal } from 'UI/common/modal/modal'
import { Button } from 'UI/common/elements/buttons/Button'
import { PublicationSettings } from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/publicationSettings/PublicationSettings'
import { deleteBeatPublication } from 'BLL/CustomerBeatsPublicationReducer'
import { deleteBeat, removeFromCatalog } from 'BLL/CustomerMyBeatsReducer'
import { useDispatch } from 'react-redux'
import { LanguageType } from 'translations/ru/common'
import { useTranslation } from 'react-i18next'
import s from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/beatspage.module.scss'

type Props = {
    setModalIsActive: (isModal: boolean) => void
}
export const BeatsPageRoutes = ({ setModalIsActive }: Props) => {
    const history = useHistory()

    const dispatch = useDispatch()

    const { i18n } = useTranslation()
    const { myBeats, customerBeatsPublication, buttons } =
        i18n.getResourceBundle(i18n.language, 'common') as LanguageType

    const handleDeletePublication = useCallback((publicationId: string) => {
        dispatch(deleteBeatPublication(publicationId))
    }, [])

    const handleDeleteBeat = useCallback((beatId: string) => {
        dispatch(deleteBeat(+beatId))
    }, [])

    const handleRemoveFromCatalog = useCallback((beatId: string) => {
        dispatch(removeFromCatalog(+beatId))
    }, [])

    return (
        <>
            <Route
                exact
                path={CustomerRM.myBeats.path.publications.cancelPublications}
                render={({ match }) => (
                    <Modal
                        active={true}
                        setActive={setModalIsActive}
                        title={customerBeatsPublication.cancelPublication}
                        cancelRedirect={() =>
                            history.push(CustomerRM.myBeats.path.beats.base)
                        }
                    >
                        <div>
                            <p>
                                {
                                    customerBeatsPublication.deletePopUpDialogMessage
                                }
                            </p>
                            <div className={s.buttonsPopUp}>
                                <Button
                                    view={'Secondary'}
                                    title={buttons.CANCEL}
                                    onClick={() =>
                                        history.push(
                                            CustomerRM.myBeats.path.beats.base
                                        )
                                    }
                                />
                                <Button
                                    view={'Delete'}
                                    title={buttons.DELETE}
                                    onClick={() =>
                                        handleDeletePublication(
                                            match.params['publicationId']
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </Modal>
                )}
            />
            <Route
                exact
                path={CustomerRM.myBeats.path.publications.edit}
                render={() => (
                    <Modal
                        active={true}
                        setActive={setModalIsActive}
                        title={myBeats.publish}
                        cancelRedirect={() =>
                            history.push(CustomerRM.myBeats.path.beats.base)
                        }
                    >
                        <PublicationSettings />
                    </Modal>
                )}
            />
            <Route
                exact
                path={CustomerRM.myBeats.path.publications.delete}
                render={({ match }) => (
                    <Modal
                        active={true}
                        setActive={setModalIsActive}
                        title={myBeats.deleteBeat}
                        cancelRedirect={() =>
                            history.push(CustomerRM.myBeats.path.beats.base)
                        }
                    >
                        <div>
                            <p>{myBeats.removeFromCatalog}</p>
                            <div className={s.buttonsPopUp}>
                                <Button
                                    view={'Secondary'}
                                    title={buttons.CANCEL}
                                    onClick={() =>
                                        history.push(
                                            CustomerRM.myBeats.path.beats.base
                                        )
                                    }
                                />
                                <Button
                                    view={'Delete'}
                                    title={buttons.DELETE}
                                    onClick={() =>
                                        handleDeleteBeat(match.params['beatId'])
                                    }
                                />
                            </div>
                        </div>
                    </Modal>
                )}
            />
            <Route
                exact
                path={CustomerRM.myBeats.path.publications.cancel}
                render={({ match }) => (
                    <Modal
                        active={true}
                        setActive={setModalIsActive}
                        title={myBeats.deleteBeat}
                        cancelRedirect={() =>
                            history.push(CustomerRM.myBeats.path.beats.base)
                        }
                    >
                        <div>
                            <p>{myBeats.removeFromCatalog}</p>
                            <div className={s.buttonsPopUp}>
                                <Button
                                    view={'Secondary'}
                                    title={buttons.CANCEL}
                                    onClick={() =>
                                        history.push(
                                            CustomerRM.myBeats.path.beats.base
                                        )
                                    }
                                />
                                <Button
                                    view={'Delete'}
                                    title={buttons.DELETE}
                                    onClick={() =>
                                        handleRemoveFromCatalog(
                                            match.params['beatId']
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </Modal>
                )}
            />
        </>
    )
}
