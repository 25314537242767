import React, { Fragment } from 'react'
import Select from 'react-select'
import { Controller, FieldError } from 'react-hook-form'
import { deviceDetect } from 'react-device-detect'
import s from 'UI/common/elements/customSelect.module.scss'

export type OptionsType = { value: any; label: any }

type PropsType = {
    options: OptionsType[]
    setValue?: (name: any, value: any) => void
    value?: any
    placeholder?: string
    register?: any
    control?: any
    name?: any
    errorMessage?: FieldError | undefined
    disabled?: boolean
    noMobile?: boolean
    onChange?: any
}

export const CustomSelect = ({
    options,
    control,
    name,
    errorMessage,
    register,
    setValue,
    disabled,
    noMobile,
    value,
    onChange,
}: PropsType) => {
    const isMobile = noMobile ? false : deviceDetect()?.isMobile
    const colourStyles = {
        option: (provided: any, state: any) => ({
            ...provided,
            backgroundColor: '#171717',
            height: 36,
            zIndex: 26,
            color: '#FFFFFF',
            ':hover': {
                backgroundColor: '#282828',
                cursor: 'pointer',
            },
        }),
        control: (styles: any) => {
            return {
                ...styles,
                backgroundColor: '#171717',
                color: 'white',
                border: '1px solid rgba(255, 255, 255, 0.3)',
                boxSizing: 'border-box',
                boxShadow: 'none',
                filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2))',
                borderRadius: '2px',
                ':hover': {
                    border: '1px solid rgba(255, 255, 255, 0.3)',
                },
            }
        },
        singleValue: (styles: any) => ({ ...styles, color: 'white' }),
    }
    const handleChange = (value: any) => {
        if (isMobile) {
            const selectValue = { value: value.value, label: value.label }
            setValue && setValue(selectValue, value)
        }
    }
    const mobileProps = isMobile
        ? {
              onChange: handleChange,
          }
        : {}

    return (
        <Fragment>
            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <Select
                        styles={colourStyles}
                        {...field}
                        options={options}
                        openMenuOnFocus={true}
                        {...mobileProps}
                        components={{
                            IndicatorSeparator: () => null,
                        }}
                        isDisabled={disabled}
                    />
                )}
            />
            <span className={s.errorMessage}>{errorMessage?.message}</span>
        </Fragment>
    )
}
