import React from 'react'
import s from './ProgressBar.module.scss'
import { PlayerLogic } from '../../../Domain/PlayerLogic'

type PropsType = {
    player: PlayerLogic
}

export const ProgressBar = (props: PropsType) => {
    let progressBarHandler = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        const x = e.clientX
        const totalWidth = e.currentTarget.offsetWidth
        const xInPercents = (x / totalWidth) * 100
        props.player.setTrackPositionByPercent(xInPercents)
    }

    return (
        <div className={s.progressBar} onClick={progressBarHandler}>
            <span
                style={{ width: `${props.player.loadingProgressPercent}%` }}
                className={s.loading}
            >
                {' '}
            </span>
            <span
                style={{ width: `${props.player.playingProgressPercent}%` }}
                className={s.songLength}
            >
                {' '}
            </span>
        </div>
    )
}
