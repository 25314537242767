import React from 'react'
import { PaymentTypeBLL } from 'BLL/MySalesReducer'
import { LanguageType } from 'translations/ru/common'
import { CurrencyEnum } from 'DAL/CashbackAPI'
import { TableType } from '../types'
import { i18n } from 'i18next'

export const getPaymentsTableData = (i18n: i18n): TableType<PaymentTypeBLL> => {
    const { paymentsAdmin } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType
    return [
        {
            title: paymentsAdmin.tableColumns.beatmakerName,
            field: 'id',
            render: (payment) => {
                return <div>{payment.sales[0].beatmakerName}</div>
            },
        },
        {
            title: paymentsAdmin.tableColumns.sumForCash,
            field: 'id',
            render: (payment) => {
                return payment.currencyName === CurrencyEnum.rub ? (
                    <div>₽ {payment.beatmakerPureMoney}</div>
                ) : (
                    <div>$ {payment.beatmakerPureMoney}</div>
                )
            },
            customSort: (a, b) => b.beatmakerPureMoney - a.beatmakerPureMoney,
        },
    ]
}
