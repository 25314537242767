import { BeatLicenseType } from 'DAL/Services'
import { Dispatch, SetStateAction } from 'react'

type Props = {
    lic: BeatLicenseType
    checked: boolean
    priceDollar: number | null
    price: number | null
    beatLicenseNotDefaultPrice: BeatLicenseType[]
    setBeatLicenseNotDefaultPrice: Dispatch<SetStateAction<BeatLicenseType[]>>
    setBindingsLicense: Dispatch<SetStateAction<BeatLicenseType[]>>
    bindingsLicense: BeatLicenseType[]
}
export const ChangeDefaultLicensePriceHandle = ({
    checked,
    beatLicenseNotDefaultPrice,
    lic,
    setBeatLicenseNotDefaultPrice,
    price,
    priceDollar,
    setBindingsLicense,
    bindingsLicense,
}: Props) => {
    const licenses = [...beatLicenseNotDefaultPrice, lic]
    if (checked) {
        setBeatLicenseNotDefaultPrice(licenses)
        price && priceDollar
            ? setBindingsLicense([
                  ...bindingsLicense.map((bl) =>
                      bl.id === lic.id
                          ? {
                                ...bl,
                                price,
                                priceDollar,
                            }
                          : bl
                  ),
              ])
            : setBindingsLicense([...bindingsLicense, lic])
    } else {
        setBeatLicenseNotDefaultPrice(
            beatLicenseNotDefaultPrice.filter(
                (license) => license.id !== lic.id
            )
        )
        setBindingsLicense([
            ...bindingsLicense.map((bl) => {
                const defaultLicense = licenses.find(
                    (license) => license.id === bl.id
                )
                if (defaultLicense) {
                    return {
                        ...defaultLicense,
                    }
                } else {
                    return bl
                }
            }),
        ])
    }
}
