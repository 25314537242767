import React, { ComponentPropsWithoutRef } from 'react'
import s from 'UI/CustomerAdmin/Mobile/MobDiscountsPage/Coupon/coupon.module.scss'

type Props = {
    title?: string
} & ComponentPropsWithoutRef<'div'>

export const CouponItem = ({ title, children }: Props) => {
    return (
        <div className={s.item}>
            <span className={s.descr}>{title}:</span>
            {children}
        </div>
    )
}
