import React, { useEffect, useState } from 'react'
import { InputField } from 'UI/common/elements/textFields/Input'
import { useSelector } from 'UI/common/hooks/redux-hooks'
import {
    getQiwip2pPublicKey,
    getQiwip2pSecretKey,
} from 'BLL/selectors/wallet-selectors/selectWallets'
import i18n from 'i18next'
import { LanguageType } from 'translations/ru/common'
import { Button } from 'UI/common/elements/buttons/Button'
import { fetchUpdateQiwiP2PKeys } from 'BLL/CustomerWalletsReducer'
import { useDispatch } from 'react-redux'
import { P2PKeysType } from 'BLL/types'
import { FieldError } from 'react-hook-form'
import s from 'UI/CustomerAdmin/BeatmakerSettingsNew/wallet-settings/walletSettings.module.scss'

type ErrorType = {
    publicKey: string
    secretKey: string
}

enum Fields {
    publicKey = 'publicKey',
    secretKey = 'secretKey',
}

export const QiwiP2P = () => {
    const _publickKey = useSelector(getQiwip2pPublicKey)
    const _secretKey = useSelector(getQiwip2pSecretKey)
    const dispatch = useDispatch()

    const [publicKey, setPublickKey] = useState('')
    const [secretKey, setSecretKey] = useState('')
    const [disable, setDisable] = useState(false)
    const [error, setError] = useState<ErrorType>({
        publicKey: '',
        secretKey: '',
    })
    const { buttons } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    const fieldIsError =
        !publicKey.trim() ||
        publicKey.trim().length > 30 ||
        !secretKey.trim() ||
        secretKey.trim().length > 30

    useEffect(() => {
        _publickKey && setPublickKey(_publickKey)
        _secretKey && setSecretKey(_secretKey)
    }, [_publickKey, _secretKey])

    const updateQiwiP2PKeys = () => {
        if (fieldIsError) {
            validateField(Fields.publicKey, publicKey)
            validateField(Fields.secretKey, secretKey)
            setDisable(true)
            return
        }
        const data: P2PKeysType = {
            publicKey: publicKey.trim(),
            privateKey: secretKey.trim(),
        }
        dispatch(fetchUpdateQiwiP2PKeys(data))
    }

    const validateField = (field: string, value: string) => {
        !value.trim()
            ? setError((state) => ({
                  ...state,
                  [field]: 'Поле обязательно для заполнения',
              }))
            : value.trim().length > 30
            ? setError((state) => ({
                  ...state,
                  [field]: 'Длина поля должна быть не более 30 символов',
              }))
            : setError((state) => ({ ...state, [field]: '' }))
    }

    return (
        <div>
            <div className={s.fieldsBox}>
                <div className={s.inputBox}>
                    <label>Публичный ключ</label>
                    <InputField
                        value={publicKey}
                        onChange={({ target: { value } }) => {
                            setPublickKey(value)
                            setError((state) => ({ ...state, publicKey: '' }))
                            setDisable(false)
                        }}
                        error={
                            { message: error?.publicKey.trim() } as FieldError
                        }
                    />
                </div>
            </div>
            <div className={s.fieldsBox}>
                <div className={s.inputBox}>
                    <label>Секретный ключ</label>
                    <InputField
                        value={secretKey}
                        onChange={({ target: { value } }) => {
                            setSecretKey(value)
                            setError((state) => ({ ...state, secretKey: '' }))
                            setDisable(false)
                        }}
                        error={
                            { message: error.secretKey.trim() } as FieldError
                        }
                    />
                </div>
            </div>
            <div className={s.btnBox}>
                <Button
                    title={buttons.SAVE_CHANGES}
                    type={'button'}
                    onClick={updateQiwiP2PKeys}
                    disabled={disable}
                />
            </div>
        </div>
    )
}
