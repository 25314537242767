import { paymentsAxios } from './createAxios'
import { BaseResponse, NullableType } from '../BLL/types'
import { MySalesType } from './mySalesAPI'

export type ClosePaymentsType = {
    rootPaymentIds: number[]
    fromWallet: WalletTypes
    toWallet: WalletTypes
}

export enum WalletTypes {
    Yandex = 0,
    Webmoney = 1,
    Sberbank = 2,
    Qiwi = 3,
    Paypal = 4,
}

export enum PaymentSources {
    None = 0,
    BeatmakerTV = 1,
    BeatmakersTV = 2,
    ProducerPage = 3,
    Widget = 4,
}

export type PaymentWalletType = {
    addedDate: string
    type: WalletTypes
    number: number
    phoneNumber: NullableType<string>
    userId: number
    isForeground: boolean
}

export enum BeatmakerValidationStatus {
    NotChecked = 0,
    RiskZone = 1,
    OK = 2,
    KO = 3,
}

export enum PaymentSystemTypes {
    Free = -1,
    Webmoney = 0,
    YandexMoney = 1,
    Card = 2,
    Sberbank = 3,
    Qiwi = 4,
    PaypalRub = 5,
    PaypalUsd = 6,
    YandexKassa = 7,
}

export type PaymentType = {
    id: number
    userId: number
    initDate: string
    confirmDate: string
    paymentSystemObject: NullableType<any>
    status: 1
    cashbackAmount: number
    paymentSource: PaymentSources
    publicId: string
    amount: number
    realMoney: NullableType<number>
    itemId: number
    paymentType: 10
    data: string //JSON.parse
    directWalletsId: NullableType<string>
    walletNumber: NullableType<string>
    paymentSystem: PaymentSystemTypes
    isGroupPayment: boolean
    rootPaymentId: number
    businessUserId: NullableType<number>
    altId: string
    moneyWithdrawal: MoneyWithdrawalType
}

export type MoneyWithdrawalType = {
    addedDate: string
    beatmakerId: number
    fromPaymentSystem: PaymentSystemTypes
    id: number
    managerId: number
    paymentSystem: PaymentSystemTypes
    payments: []
    walletNumber: string
}

export type BeatmakerSaleType = {
    email: string
    id: number
    beatmakerId: number
    beatmakerName: string
    percent: number
    money: number
    saleDate: string
    beatId: number
    beatTitle: string
    paidDate: NullableType<string>
    buyerId: number
    buyerName: string
    buyerEmail: string
    paymentType: 10
    paymentSystem: PaymentSystemTypes
    paymentId: number
    isDirectPayment: boolean
    sourceName: NullableType<string>
    beatmakerMoney: number
}

export type BeatmakerValidationType = {
    beatmakerId: number
    shazam: BeatmakerValidationStatus
    contacts: BeatmakerValidationStatus
    passport: BeatmakerValidationStatus
    videoScreens: BeatmakerValidationStatus
    verified: boolean
    description: string
}

export type TablePaymentType = {
    beatmakerId: number
    beatmakerName: string
    sumBeatsPriceUsd: number //Sum of all bits sold
    sumBeatsPriceRub: number //Sum of all bits sold
    sumForCashUsd: number //Sum for cashing
    sumForCashRub: number //Sum for cashing
    beatmakerValidation: BeatmakerValidationType
    wallets: PaymentWalletType[]
    blockPayment: NullableType<{
        reason: string
        beatmakerId: string
    }>
    fromWallet: NullableType<WalletTypes>
    toWallet: NullableType<WalletTypes>
    details: Array<TableDetailsPaymentType>
}

export type TableDetailsPaymentType = BeatmakerSaleType & {
    licenseTitle: string
    licenseTitleEng: string
    paymentSource: PaymentSources
    directWalletsId: NullableType<string>
    moneyWithdrawalId: number
    cashbackAmount: number
} & CouponData

export type CouponData = {
    DiscountSize: NullableType<number>
    Code: NullableType<string>
}

export type TablePaymentDetailType = {
    beatId: number
    beatName: string
    licenseTitle: string
    beatPrice: number
    percent: number
    beatmakerMoney: number
    buyerId: number
    buyerName: string
}

export type ResponseTypePayments<T> = {
    items: T
    page: number
    pageSize: number
    pagesCount: number
    totalCount: number
}

export type MoneyWithdrawal = {
    id: number
    beatmakerId: number
    paymentSystem: WalletTypes
    fromPaymentSystem: WalletTypes
    walletNumber: string
    addedDate: Date
    managerId: number
    payments: PaymentType
}

export type ValidationsType = {
    beatmakerId: number
    contacts: number
    description: string
    passport: number
    shazam: number
    verified: boolean
    videoScreens: number
}

export type WalletsType = {
    addedDate: string
    isForeground: boolean
    number: null | number
    phoneNumber: null | number
    type: number
    userId: number
}

export type BlockPaymentsType = {
    beatmakerId: number
    reason: string
}

export type BeatmakerWallets = { [key: string]: WalletsType[] }
export type BeatmakerValidation = { [key: string]: ValidationsType }
export type PaymentResponse = {
    beatmakerIdWithBeatmakersValidations: BeatmakerValidation
    beatmakerIdWithWallets: BeatmakerWallets
    blockPayments: BlockPaymentsType[]
    items: MySalesType[]
}

export const paymentsAPI = {
    async getPayments(
        onlyNotPaid: boolean = false,
        beatmakerId?: number,
        page: number = 1,
        pageSize: number = 10
    ) {
        const requestUrl = beatmakerId
            ? `/payments/all?beatmakerId=${beatmakerId}&page=${page}&pageSize=${pageSize}&onlyNotPaid=${onlyNotPaid}`
            : `/payments/all?page=${page}&pageSize=${pageSize}&onlyNotPaid=${onlyNotPaid}`
        return paymentsAxios
            .get<PaymentResponse>(requestUrl)
            .then((r) => r.data)
    },

    async closePayments(payload: ClosePaymentsType) {
        return paymentsAxios
            .post<BaseResponse>('payments/close', payload)
            .then((r) => r.data)
    },

    async getWithdrawals(page: number, pageSize: number) {
        return paymentsAxios
            .get<ResponseTypePayments<MoneyWithdrawal[]>>(
                `payments/withdrawals?page=${page}&pageSize=${pageSize}`
            )
            .then((r) => r.data)
    },
}
