import { useEffect, useState } from 'react'
import { useMessageBus } from '../../MessageBus/Hooks'
import { saveToken } from '../AuthUtils'

function inIframe() {
    try {
        return window.self !== window.top
    } catch (e) {
        return true
    }
}

export const addTarget = (window?: Window | null) => {
    const bus = useMessageBus()

    useEffect(() => {
        if (window) {
            bus?.addTarget(window)
        }
    }, [window])
}

export const useAuthHostToken = (
    devToken: string | null,
    ready = true,
    target?: Window | null
) => {
    const refreshToken = async (token: string) => {
        await saveToken(token)
        setToken(token)
    }

    if (process.env.NODE_ENV === 'development' && !inIframe() && !!devToken) {
        refreshToken(devToken)
    }
    const bus = useMessageBus()
    const [token, setToken] = useState<string | null>(null)

    useEffect(() => {
        if (ready) {
            const unsubscribe = bus?.subscribe<{ token: string }>(
                'auth-token-sent',
                async (action) => {
                    await refreshToken(action.payload.token)
                }
            )
            bus?.send(
                {
                    type: 'auth-token-requested',
                    payload: {},
                },
                target
            )

            return unsubscribe
        }
    }, [ready])

    return !!token
}
