import { ShopTypesFull } from 'BLL/shop-types'
import { LanguageType } from 'translations/ru/common'
import { TableType } from '../types'
import { i18n } from 'i18next'

export const getShopsTableData = (i18n: i18n): TableType<ShopTypesFull> => {
    const { managerAdmin } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType
    return [
        { title: managerAdmin.tableColumns.title, field: 'title' },
        { title: managerAdmin.tableColumns.feePercent, field: 'feePercent' },
        { title: managerAdmin.tableColumns.city, field: 'city' },
        { title: managerAdmin.tableColumns.country, field: 'country' },
    ]
}
