import React, { KeyboardEvent, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { closePayments, getPayments } from 'BLL/AdminPaymentsReducer'
import Header from '../../common/header/Header'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'
import { useRedirectCustomHook } from 'UI/common/hooks/useRedirectCustomHook'
import { RolesTypeEnum } from 'BLL/enums'
import { useSelector } from 'UI/common/hooks/redux-hooks'
import { Payments } from './Payments'
import {
    BeatmakerValidationStatus,
    BeatmakerValidationType,
    WalletTypes,
} from 'DAL/paymentsAPI'
import { Button, MenuItem, Paper, Select } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import { WALLETS } from 'BLL/SupportingFile'
import { useHistory, useLocation } from 'react-router-dom'
import { MaterialInput } from 'UI/common/elements/materialInput'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { RequestStatuses } from 'BLL/AppReducer'
import { AdminRM } from 'UI/routers/RouterManager'
import { PageWrapper } from 'UI/common/pageWrapper/PageWrapper'
import { PaymentTypeBLL } from 'BLL/MySalesReducer'
import { copyBuffer } from 'UI/utils/util-types'
import { AppSettings } from 'settings/appSettings'
import { getPaymentsTableData } from 'UI/common/table/tableData/GetPaymentsTableData'
import { TableType } from 'UI/common/table/types'
import s from './paymenst.module.css'

function useQuery() {
    return new URLSearchParams(useLocation().search)
}

export const AdminPayments = () => {
    const query = useQuery()
    const history = useHistory()
    let beatmakerId = query.get('beatmakerId')
    const dispatch = useDispatch()
    const { i18n } = useTranslation()
    const { navigation, paymentsAdmin } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    const paymentsRub = useSelector((state) => state.paymentsAdmin.paymentsRub)
    const paymentsUsd = useSelector((state) => state.paymentsAdmin.paymentsUsd)
    const beatmakerIdWithWallets = useSelector(
        (state) => state.paymentsAdmin.beatmakerIdWithWallets
    )
    const beatmakerIdWithBeatmakersValidations = useSelector(
        (state) => state.paymentsAdmin.beatmakerIdWithBeatmakersValidations
    )

    const requestStatus = useSelector((state) => state.appReducer.requestStatus)
    let [fromWallet, setFromWallet] = useState<{ [key: string]: number }>({
        '-1': -1,
    })
    let [toWallet, setToWallet] = useState<{ [key: string]: number }>({
        '-1': -1,
    })
    const [fields, setFields] = useState<TableType<PaymentTypeBLL> | null>(null)
    const [id, setId] = useState<string | null>(null)
    const [onlyNotPaid, setOnlyNotPaid] = useState<boolean>(true)

    const handleChange = useCallback(
        (event: React.ChangeEvent<any>, id: number) => {
            if (event.target.name === 'fromWallet') {
                fromWallet = { ...fromWallet, [id]: +event.target.value }
                setFromWallet(fromWallet)
            } else {
                toWallet = { ...toWallet, [id]: +event.target.value }
                setToWallet(toWallet)
            }
        },
        [fromWallet, setToWallet, toWallet, setFromWallet]
    )

    const handleClosePayments = useCallback(
        (paymentId: number, beatmakerId: number) => {
            const targetWallet = toWallet[String(beatmakerId)]
            const sourceWallet = fromWallet[String(beatmakerId)]

            if (paymentsRub && targetWallet >= 0 && sourceWallet >= 0) {
                let payment = paymentsRub.find(
                    (payment) => payment.id === paymentId
                )
                if (!payment && paymentsUsd) {
                    payment = paymentsUsd.find(
                        (payment) => payment.id === paymentId
                    )
                }
                const rootPaymentIds = payment?.rootPaymentIds
                if (rootPaymentIds) {
                    dispatch(
                        closePayments(
                            {
                                rootPaymentIds,
                                toWallet: toWallet[beatmakerId],
                                fromWallet: fromWallet[beatmakerId],
                            },
                            beatmakerId
                        )
                    )
                    setFromWallet({ '-1': -1 })
                    setToWallet({ '-1': -1 })
                }
            }
        },
        [
            fromWallet,
            setToWallet,
            toWallet,
            setFromWallet,
            paymentsRub,
            dispatch,
        ]
    )

    useEffect(() => {
        const selectOptions = (
            Object.keys(WALLETS) as Array<keyof typeof WALLETS>
        ).map((key) => {
            return (
                <MenuItem key={key} value={WALLETS[key]}>
                    {key}
                </MenuItem>
            )
        })
        const fields = [
            ...getPaymentsTableData(i18n),
            {
                title: paymentsAdmin.tableColumns.fromWallet,
                field: 'fromWallet',
                sorting: false,
                render: (payment: PaymentTypeBLL) => (
                    <div>
                        <Select
                            variant={'outlined'}
                            style={{ width: 150, height: 45 }}
                            name="fromWallet"
                            value={
                                fromWallet[payment.sales[0].beatmakerId] >= -1
                                    ? fromWallet[payment.sales[0].beatmakerId]
                                    : -1
                            }
                            onChange={(e) =>
                                handleChange(e, payment.sales[0].beatmakerId)
                            }
                            defaultValue={-1}
                        >
                            {selectOptions}
                        </Select>
                    </div>
                ),
            },
            {
                title: paymentsAdmin.tableColumns.toWallet,
                field: 'toWallet',
                sorting: false,
                render: (payment: PaymentTypeBLL) => (
                    <div>
                        <Select
                            variant={'outlined'}
                            style={{ width: 150, height: 45 }}
                            name="toWallet"
                            value={
                                toWallet[payment.sales[0].beatmakerId] >= -1
                                    ? toWallet[payment.sales[0].beatmakerId]
                                    : -1
                            }
                            onChange={(e) =>
                                handleChange(e, payment.sales[0].beatmakerId)
                            }
                            defaultValue={-1}
                        >
                            {selectOptions}
                        </Select>
                    </div>
                ),
            },
            {
                title: '',
                field: 'beatmakerId',
                sorting: false,
                render: (payment: PaymentTypeBLL) => (
                    <div>
                        <Button
                            variant={'contained'}
                            size={'small'}
                            style={{
                                background: AppSettings.api.getBaseColor(),
                            }}
                            disabled={
                                !onlyNotPaid ||
                                requestStatus === RequestStatuses.InProgress
                            }
                            onClick={() =>
                                handleClosePayments(
                                    payment.id,
                                    payment.sales[0].beatmakerId
                                )
                            }
                        >
                            {paymentsAdmin.closePayment}
                        </Button>
                    </div>
                ),
            },
            {
                title: '',
                field: 'beatmakerValidation',
                sorting: false,
                render: (payment: PaymentTypeBLL) => {
                    let style = {
                        padding: 5,
                        background: '#e5e5e5',
                        borderRadius: 5,
                        cursor: 'pointer',
                        color: '#000',
                        textAlign: 'center',
                    }
                    let title = paymentsAdmin.validation.title
                    const validation =
                        beatmakerIdWithBeatmakersValidations &&
                        beatmakerIdWithBeatmakersValidations[
                            payment.sales[0].beatmakerId
                        ]
                    if (validation) {
                        const result = Object.values(validation).filter(
                            (value) => {
                                return (
                                    value ===
                                        BeatmakerValidationStatus.RiskZone ||
                                    value === BeatmakerValidationStatus.KO
                                )
                            }
                        )
                        if (result.length) {
                            style = {
                                ...style,
                                background: '#dd5758',
                                color: '#fff',
                            }
                            title = paymentsAdmin.validation.attention
                        }
                    }

                    return (
                        <div>
                            <ClickAwayListener onClickAway={() => {}}>
                                <div>
                                    <TooltipComponent
                                        style={style}
                                        title={title}
                                    >
                                        {validation &&
                                            (
                                                Object.keys(
                                                    validation
                                                ) as Array<
                                                    keyof BeatmakerValidationType
                                                >
                                            ).map((key) => (
                                                <div key={key}>
                                                    {validation[key] ===
                                                        BeatmakerValidationStatus.RiskZone ||
                                                    validation[key] ===
                                                        BeatmakerValidationStatus.KO ? (
                                                        <div
                                                            style={{
                                                                color: 'red',
                                                            }}
                                                        >
                                                            {key} :{' '}
                                                            {
                                                                BeatmakerValidationStatus[
                                                                    validation[
                                                                        key
                                                                    ] as BeatmakerValidationStatus
                                                                ]
                                                            }
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            {key} :{' '}
                                                            {key === 'verified'
                                                                ? validation[
                                                                      key
                                                                  ].toString()
                                                                : BeatmakerValidationStatus[
                                                                      validation[
                                                                          key
                                                                      ] as BeatmakerValidationStatus
                                                                  ]}
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                    </TooltipComponent>
                                </div>
                            </ClickAwayListener>
                        </div>
                    )
                },
            },
            {
                title: '',
                field: 'beatmakerId',
                sorting: false,
                render: (payment: PaymentTypeBLL) => (
                    <div
                        style={{
                            padding: 5,
                            background: '#e5e5e5',
                            borderRadius: 5,
                            cursor: 'pointer',
                            color: '#000',
                            textAlign: 'center',
                        }}
                    >
                        <TooltipComponent title={paymentsAdmin.wallet.title}>
                            <div>
                                {beatmakerIdWithWallets &&
                                    beatmakerIdWithWallets[
                                        payment.sales[0].beatmakerId
                                    ]?.map((w, i) => (
                                        <div key={i}>
                                            <div style={{ display: 'flex' }}>
                                                <div
                                                    style={{
                                                        fontWeight: 700,
                                                        marginRight: 5,
                                                    }}
                                                >
                                                    {WalletTypes[w.type]}
                                                </div>
                                                {/*<div style={{marginRight: 5}}>Number:</div>*/}
                                                <span
                                                    className={s.wallets}
                                                    onClick={() =>
                                                        copyBufferHandler(
                                                            String(w.number)
                                                        )
                                                    }
                                                >
                                                    {w.number}
                                                </span>
                                                {w.phoneNumber && (
                                                    <>
                                                        <div
                                                            style={{
                                                                marginLeft: 5,
                                                            }}
                                                        >
                                                            Phone:
                                                        </div>
                                                        <span
                                                            className={
                                                                s.wallets
                                                            }
                                                            onClick={() =>
                                                                copyBufferHandler(
                                                                    String(
                                                                        w.phoneNumber
                                                                    )
                                                                )
                                                            }
                                                        >
                                                            {w.phoneNumber}
                                                        </span>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </TooltipComponent>
                    </div>
                ),
            },
        ]
        setFields(fields as TableType<PaymentTypeBLL>)
    }, [
        paymentsRub,
        fromWallet,
        toWallet,
        i18n.language,
        beatmakerId,
        requestStatus,
        handleClosePayments,
        handleChange,
        i18n,
        onlyNotPaid,
        paymentsAdmin.closePayment,
        paymentsAdmin.tableColumns.fromWallet,
        paymentsAdmin.tableColumns.toWallet,
        paymentsAdmin.validation.attention,
        paymentsAdmin.validation.title,
        paymentsAdmin.wallet.title,
        beatmakerIdWithWallets,
    ])

    const handleSearchPayments = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            if (id) {
                history.push(AdminRM.payments.getUrl.queryByBeatmakerId(id))
            } else {
                history.push(AdminRM.payments.path.list)
            }
        }
    }

    const copyBufferHandler = (text: string) => {
        copyBuffer(text, dispatch)
    }

    useEffect(() => {
        if (beatmakerId) {
            dispatch(getPayments(onlyNotPaid, Number(beatmakerId)))
            setId(beatmakerId)
        } else {
            dispatch(getPayments(onlyNotPaid))
        }
    }, [beatmakerId, onlyNotPaid, dispatch])

    const getAllNotWithdrawn = (payments: PaymentTypeBLL[]) => {
        return payments.reduce((acc, item) => {
            acc = acc + item.beatmakerPureMoney
            return acc
        }, 0)
    }

    return (
        <div>
            {useRedirectCustomHook(RolesTypeEnum.PAYMENTS)}
            <Header title={navigation.adminPayments} />
            <PageWrapper>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        color: '#fff',
                        width: 250,
                        marginBottom: 10,
                    }}
                >
                    <span>Beatmaker ID</span>
                    <MaterialInput
                        onKeyPress={handleSearchPayments}
                        value={id ? id : ''}
                        onChange={(e) => setId(e.currentTarget.value)}
                        placeholder={paymentsAdmin.placeholder}
                    />
                </div>
                {paymentsRub && paymentsUsd && (
                    <Paper className={s.allStatistics}>
                        <div>{paymentsAdmin.allMoney}</div>
                        ₽: {getAllNotWithdrawn(paymentsRub)} / $:{' '}
                        {getAllNotWithdrawn(paymentsUsd)}
                    </Paper>
                )}
                {/*<div>*/}
                {/*  <input id={"onlyNotPaid"} type={"checkbox"} checked={onlyNotPaid}*/}
                {/*         onChange={() => setOnlyNotPaid(!onlyNotPaid)}/>*/}
                {/*  <label htmlFor={"onlyNotPaid"} style={{color: "white"}}>Показать только не оплаченные</label>*/}
                {/*</div>*/}
                {paymentsRub && paymentsUsd && fields && (
                    <Payments
                        payments={[...paymentsRub, ...paymentsUsd]}
                        fields={fields}
                    />
                )}
            </PageWrapper>
        </div>
    )
}

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 500,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip)

const TooltipComponent = (props: any) => {
    return (
        <HtmlTooltip
            interactive={true}
            // leaveDelay={100}
            title={
                <React.Fragment>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {props.children}
                    </div>
                </React.Fragment>
            }
        >
            <div style={props.style}>{props.title}</div>
        </HtmlTooltip>
    )
}
