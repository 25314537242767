import React from 'react'
import ArrowUpIcon from 'UI/assets/icons/arrow-top.svg'
import ArrowDownIcon from 'UI/assets/icons/arrow-bottom.svg'
import s from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/beatSettings/trackLicenses/trackLicense.module.scss'

type Props = {
    collapsed: boolean
    setCollapsed: (value: boolean) => void
    title: string
}
export const CollapsedWrapper = ({ collapsed, setCollapsed, title }: Props) => {
    return (
        <div className={s.titleBlock} onClick={() => setCollapsed(!collapsed)}>
            <span>{title}</span>
            {collapsed ? (
                <img src={ArrowUpIcon} alt={'arrow-up'} />
            ) : (
                <img src={ArrowDownIcon} alt={'arrow-down'} />
            )}
        </div>
    )
}
