import React from 'react'
import {
    createStyles,
    LinearProgressProps,
    Theme,
    withStyles,
} from '@material-ui/core'
import LinearProgress from '@material-ui/core/LinearProgress'
import Box from '@material-ui/core/Box'
import { AppSettings } from 'settings/appSettings'
import classes from 'UI/common/progressBars/spinner.module.scss'
import { useSelector } from '../hooks/redux-hooks'
import { RequestStatuses } from '../../../BLL/AppReducer'

const BorderLinearProgress = withStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 3,
            borderRadius: 0,
        },
        colorPrimary: {
            backgroundColor:
                theme.palette.grey[theme.palette.type === 'light' ? 800 : 700],
        },
        bar: {
            borderRadius: 5,
            backgroundColor: (props: any) =>
                props.value === 100 &&
                props.requestStatus === RequestStatuses.Success
                    ? '#30B800'
                    : props.requestStatus === RequestStatuses.Error
                    ? '#DC1749'
                    : AppSettings.api.getBaseColor(),
        },
    })
)(LinearProgress)

export function LinearProgressWithLabel(
    props: LinearProgressProps & {
        value: number
        file: FileList | null
        progressLoaded: number
    }
) {
    const fileName = props.file ? props.file[0].name : ''
    const fileSize = props.file ? +(props.file[0].size / 1048576).toFixed(1) : 0
    const requestStatus = useSelector((state) => state.appReducer.requestStatus)
    return (
        <>
            <Box
                width={props.value === 100 ? '80%' : '70%'}
                mr={1}
                className={classes.leaner}
            >
                <div className={classes.fileName}>{fileName}</div>
                <BorderLinearProgress
                    variant="determinate"
                    {...props}
                    requestStatus={requestStatus}
                />
                <div className={classes.progressFooter}>
                    {props.value !== 100 ? (
                        <div
                            className={classes.sizeCount}
                        >{`${props.progressLoaded} of ${fileSize} MB`}</div>
                    ) : (
                        <div
                            className={classes.sizeCount}
                        >{`${props.progressLoaded} MB`}</div>
                    )}
                    {props.value &&
                        requestStatus === RequestStatuses.InProgress && (
                            <div className={classes.progressCount}>
                                {props.value
                                    ? `Uploading... ${Math.round(props.value)}%`
                                    : ''}
                            </div>
                        )}
                    {requestStatus === RequestStatuses.Success &&
                        props.value === 100 && (
                            <div className={classes.progressFinished}>
                                Finished
                            </div>
                        )}
                    {requestStatus === RequestStatuses.Error && (
                        <div className={classes.progressFailed}>Failed</div>
                    )}
                </div>
            </Box>
        </>
    )
}

// {`Uploading... ${Math.round(props.value)}%`}
