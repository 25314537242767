import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'
import { useSelector } from 'UI/common/hooks/redux-hooks'
import { useFormContext } from 'react-hook-form'
import { OptionsType } from 'UI/common/elements/CustomSelect'
import s from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/beatSettings/trackDetails/trackDetails.module.scss'
import { ControlledSelect } from 'UI/common/controls/controlledSelect/ControlledSelect'
import { selectMoods, selectStyles } from 'BLL/selectors/app-selectors'
import { CollapsedWrapper } from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/beatSettings/CollapsedWrapper'
import { ControlledTextField } from 'UI/common/controls/controlledTextField/ControlledTextField'
import { ControlledTextArea } from 'UI/common/controls/controlledTextArea/ControlledTextArea'

export const TrackDetails = () => {
    const moods = useSelector(selectMoods)
    const styles = useSelector(selectStyles)

    const {
        formState: { errors },
        control,
    } = useFormContext()

    const [collapsed, setCollapsed] = useState(false)

    const { i18n } = useTranslation()
    const { myBeats } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    const stylesOption: OptionsType[] = styles.map((style) => ({
        value: style.id,
        label: style.title,
    }))

    const moodsOption: OptionsType[] = moods.map((style) => ({
        value: style.id,
        label: style.title,
    }))

    useEffect(() => {
        errors?.styleId?.message && setCollapsed(true)
        errors?.moodId?.message && setCollapsed(true)
    }, [errors])

    return (
        <div className={s.TrackDetails}>
            <CollapsedWrapper
                collapsed={collapsed}
                setCollapsed={setCollapsed}
                title={myBeats.beatSettings.trackDetails}
            />
            {collapsed && (
                <div className={s.contentWrapper}>
                    <div className={s.selectWrapper}>
                        <div className={s.select}>
                            <div>{myBeats.formsField.genre}</div>
                            <ControlledSelect
                                name={'styleId'}
                                options={stylesOption}
                                control={control}
                                errorMessage={errors?.styleId?.message}
                            />
                        </div>
                        <div className={s.select}>
                            <div>{myBeats.formsField.mood}</div>
                            <ControlledSelect
                                name={'moodId'}
                                options={moodsOption}
                                control={control}
                                errorMessage={errors?.moodId?.message}
                            />
                        </div>
                        <div className={s.select}>
                            <ControlledTextField
                                label={myBeats.formsField.bmp}
                                name={'tempo'}
                                control={control}
                                required={false}
                            />
                        </div>
                    </div>
                    <div>{myBeats.formsField.description}</div>
                    <ControlledTextArea
                        control={control}
                        name={'description'}
                    />
                </div>
            )}
        </div>
    )
}
