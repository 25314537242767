import { ISubscribers, subscribersAPI } from '../DAL/SubscribersAPI'
import { formatDate, STATUSES } from './SupportingFile'
import { InferActionsTypes } from './Store'
import { ThunkActionType } from './types'
import { actions as appActions, AuthActionsTypes } from './AppReducer'
import { commonAsyncHandler, completeOperation } from './common-async-handler'

export interface IDatePeriod {
    fromDate: string
    toDate: string
}

export enum SourceTypeEnum {
    ProducerPage = 1,
}

export type SourceType = {
    value: number
    label: string
}

const initState = {
    subscribers: [] as ISubscribers[],
    pageSize: 20,
    currentPage: 1,
    pageCount: 1,
    totalSubscribers: 0,
    status: STATUSES.NOT_INIT,
    datePeriod: {
        fromDate: '',
        toDate: '',
    } as IDatePeriod,
    sourceType: { value: 0, label: '' },
}

type IState = typeof initState
export type ActionsTypes = InferActionsTypes<typeof actions> | AuthActionsTypes

const CustomerSubscribersReducer = (
    state = initState,
    action: ActionsTypes
): IState => {
    switch (action.type) {
        case 'CUSTOMER_SUBSCRIBERS/SET_SUBSCRIBERS':
            return {
                ...state,
                subscribers: action.subscribers,
                totalSubscribers: action.totalCount,
                pageCount: action.pageCount,
            }
        case 'CUSTOMER_SUBSCRIBERS/SET_STATUS':
            return {
                ...state,
                status: action.status,
            }
        case 'CUSTOMER_SUBSCRIBERS/SET_PAGE_SIZE':
            return {
                ...state,
                pageSize: action.pageSize,
            }
        case 'CUSTOMER_SUBSCRIBERS/SET_CURRENT_PAGE':
            return {
                ...state,
                currentPage: action.currentPage,
            }
        case 'CUSTOMER_SUBSCRIBERS/SET_DATE_PERIOD':
            return {
                ...state,
                datePeriod: {
                    fromDate: action.datePeriod.fromDate,
                    toDate: action.datePeriod.toDate,
                },
            }
        case 'CUSTOMER_SUBSCRIBERS/SET_SOURCE_TYPE':
            return {
                ...state,
                sourceType: action.sourceType,
            }
        case 'CUSTOMER_SUBSCRIBERS/SET_CLEAR_FILTER':
            return {
                ...state,
                sourceType: { value: 0, label: '' },
                datePeriod: {
                    fromDate: '',
                    toDate: '',
                },
            }
        case 'CUSTOMER_SUBSCRIBERS/SET_DELETE_SUBSCRIBER':
            return {
                ...state,
                subscribers: state.subscribers.filter(
                    (sb: ISubscribers) => sb._id !== action.id
                ),
                totalSubscribers: state.totalSubscribers - 1,
            }
        default:
            return state
    }
}

export const actions = {
    setSubscribers: (
        subscribers: ISubscribers[],
        totalCount: number,
        pageCount: number
    ) =>
        ({
            type: 'CUSTOMER_SUBSCRIBERS/SET_SUBSCRIBERS',
            subscribers,
            totalCount,
            pageCount,
        } as const),
    setStatus: (status: string) =>
        ({ type: 'CUSTOMER_SUBSCRIBERS/SET_STATUS', status } as const),
    setPageSize: (pageSize: number) =>
        ({ type: 'CUSTOMER_SUBSCRIBERS/SET_PAGE_SIZE', pageSize } as const),
    setCurrentPage: (currentPage: number) =>
        ({
            type: 'CUSTOMER_SUBSCRIBERS/SET_CURRENT_PAGE',
            currentPage,
        } as const),
    setDatePeriod: (datePeriod: IDatePeriod) =>
        ({ type: 'CUSTOMER_SUBSCRIBERS/SET_DATE_PERIOD', datePeriod } as const),
    setSourceType: (sourceType: SourceType) =>
        ({
            type: 'CUSTOMER_SUBSCRIBERS/SET_SOURCE_TYPE',
            sourceType,
        } as const),
    setClearFilter: () =>
        ({ type: 'CUSTOMER_SUBSCRIBERS/SET_CLEAR_FILTER' } as const),
    setDeleteSubscriber: (id: string) =>
        ({ type: 'CUSTOMER_SUBSCRIBERS/SET_DELETE_SUBSCRIBER', id } as const),
}

export const getSubscribers =
    (): ThunkActionType<ActionsTypes> => async (dispatch, getState) => {
        await commonAsyncHandler(async () => {
            const currentPage = getState().customerSubscribers.currentPage
            const pageSize = getState().customerSubscribers.pageSize

            let fromDate = getState().customerSubscribers.datePeriod.fromDate
            let toDate = getState().customerSubscribers.datePeriod.toDate
            let sourceType = getState().customerSubscribers.sourceType
            if (fromDate && toDate) {
                fromDate = formatDate(fromDate)
                toDate = formatDate(toDate)
            }
            const response = await subscribersAPI.getSubscribers({
                currentPage,
                pageSize,
                sourceType: sourceType.value,
                fromDate,
                toDate,
            })
            dispatch(
                actions.setSubscribers(
                    response.subscribers.items,
                    response.subscribers.totalCount,
                    response.subscribers.pageCount
                )
            )
            return response
        }, dispatch)
    }

export const deleteSubscriber =
    (id: string): ThunkActionType<ActionsTypes> =>
    async (dispatch) => {
        await commonAsyncHandler(async () => {
            const response = await subscribersAPI.deleteSubscriber(id)
            completeOperation(response, dispatch, () => {
                dispatch(actions.setDeleteSubscriber(id))
            })
            return response
        }, dispatch)
    }

export default CustomerSubscribersReducer
