import React, { FC, useEffect, useState } from 'react'
import { EditingShopType, ShopContactsType } from '../../../BLL/shop-types'
import { MaterialInput } from 'UI/common/elements/materialInput'
import {
    Checkbox,
    CheckboxProps,
    FormControl,
    FormControlLabel,
    InputBaseProps,
    InputLabel,
} from '@material-ui/core'
import { LanguageType } from '../../../translations/ru/common'
import { useTranslation } from 'react-i18next'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapperFormElement: {
            margin: 10,
            minWidth: 350,
        },
        root: {
            width: '100%',
        },
    })
)

type OwnProps = {
    shop: EditingShopType
    register: any
}

export type FormElementType<T, C> = {
    name: Extract<keyof T, string> | any
    label: string
    defaultValue: string | number | boolean | null
    props?: { [key: string]: string | number | boolean }
    component: (props: any) => JSX.Element
}

export type FormType<T, C> = FormElementType<T, C>[]

const EditFormShop: FC<OwnProps> = ({ shop, register }) => {
    const classes = useStyles()
    const { i18n } = useTranslation()
    const { managerAdmin } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType
    const [fields, setFields] = useState<FormType<
        EditingShopType,
        ShopContactsType
    > | null>(null)
    useEffect(() => {
        setFields([
            {
                name: 'userId',
                label: managerAdmin.tableColumns.userId,
                defaultValue: shop.userId,
                component: (props) => <MaterialInput {...props} />,
            },
            {
                name: 'title',
                label: managerAdmin.tableColumns.title,
                defaultValue: shop.title,
                component: (props: InputBaseProps) => (
                    <MaterialInput {...props} />
                ),
            },
            {
                name: 'country',
                label: managerAdmin.tableColumns.country,
                defaultValue: shop.country,
                component: (props: InputBaseProps) => (
                    <MaterialInput {...props} />
                ),
            },
            {
                name: 'city',
                label: managerAdmin.tableColumns.city,
                defaultValue: shop.city,
                component: (props: InputBaseProps) => (
                    <MaterialInput {...props} />
                ),
            },
            {
                name: 'feePercent',
                label: managerAdmin.tableColumns.feePercent,
                defaultValue: shop.feePercent,
                component: (props: InputBaseProps) => (
                    <MaterialInput multiline {...props} />
                ),
            },
            {
                name: 'shortDescription',
                label: managerAdmin.tableColumns.shortDescription,
                defaultValue: shop.shortDescription,
                component: (props: InputBaseProps) => (
                    <MaterialInput multiline rows={2} {...props} />
                ),
            },
            {
                name: 'description',
                label: managerAdmin.tableColumns.description,
                defaultValue: shop.description,
                component: (props: InputBaseProps) => (
                    <MaterialInput multiline rows={6} {...props} />
                ),
            },
            {
                name: 'contacts.address',
                label: managerAdmin.tableColumns.contacts.address,
                defaultValue: shop.contacts && shop.contacts.address,
                component: (props: InputBaseProps) => (
                    <MaterialInput {...props} />
                ),
            },
            {
                name: 'contacts.email',
                label: managerAdmin.tableColumns.contacts.email,
                defaultValue: shop.contacts && shop.contacts.email,
                component: (props: InputBaseProps) => (
                    <MaterialInput {...props} />
                ),
            },
            {
                name: 'contacts.phone',
                label: managerAdmin.tableColumns.contacts.phone,
                defaultValue: shop.contacts && shop.contacts.phone,
                component: (props: InputBaseProps) => (
                    <MaterialInput {...props} />
                ),
            },
            {
                name: 'contacts.website',
                label: managerAdmin.tableColumns.contacts.website,
                defaultValue: shop.contacts && shop.contacts.website,
                component: (props: InputBaseProps) => (
                    <MaterialInput {...props} />
                ),
            },
            {
                name: 'allWorldDelivery',
                label: managerAdmin.tableColumns.allWorldDelivery,
                defaultValue: shop.allWorldDelivery,
                component: (props: CheckboxProps) => (
                    <FormControlLabel
                        control={<Checkbox {...props} />}
                        label={managerAdmin.tableColumns.allWorldDelivery}
                    />
                ),
                props: { type: 'checkbox' },
            },
        ])
    }, [])
    return (
        <div className={classes.root}>
            {fields &&
                fields.map(
                    (
                        field: FormElementType<
                            EditingShopType,
                            ShopContactsType
                        >,
                        index: number
                    ) => {
                        const Component = field.component
                        return (
                            <div
                                key={index}
                                className={classes.wrapperFormElement}
                            >
                                <FormControl fullWidth>
                                    {field.props &&
                                    field.props.type === 'checkbox' ? (
                                        <Component
                                            inputRef={register}
                                            name={field.name}
                                            defaultValue={field.defaultValue}
                                            defaultChecked={field.defaultValue}
                                        />
                                    ) : (
                                        <>
                                            <InputLabel
                                                shrink
                                                htmlFor={field.name}
                                            >
                                                {field.label}
                                            </InputLabel>
                                            <Component
                                                inputRef={register}
                                                name={field.name}
                                                defaultValue={
                                                    field.defaultValue
                                                }
                                                defaultChecked={
                                                    field.defaultValue
                                                }
                                            />
                                        </>
                                    )}
                                </FormControl>
                            </div>
                        )
                    }
                )}
        </div>
    )
}

export default EditFormShop
