import React, { FC, useEffect } from 'react'
import classes from './notifications.module.scss'
import { Notification } from './ notification/notification'
import { Button } from '../../../common/elements/buttons/Button'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { LanguageType } from '../../../../translations/ru/common'
import {
    getSubscriptionsNotify,
    updateSubscriptionsNotify,
} from '../../../../BLL/BeatmakerSettingsReducer'
import { useDispatch } from 'react-redux'
import { useSelector } from '../../../common/hooks/redux-hooks'
import { SendMessageAfterEnum } from '../../../../DAL/NotificationsAPI'

type PropsType = {}

export const Notifications: FC<PropsType> = () => {
    const { i18n } = useTranslation()
    const subscriptions = useSelector(
        (state) => state.beatmakerSetting.subscriptions
    )
    const { settings, buttons, notifications } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType
    const {
        register,
        setValue,
        formState: { errors },
        handleSubmit,
        control,
    } = useForm()

    const dispatch = useDispatch()

    const onSubmit = handleSubmit((values: any) => {
        const newSubscriptions = subscriptions.map((sub) => {
            return {
                ...sub,
                sendMessageAfter:
                    SendMessageAfterEnum[
                        values[sub.subsriptionType]
                            .value as SendMessageAfterEnum
                    ],
            }
        })
        //@ts-ignore
        dispatch(updateSubscriptionsNotify(newSubscriptions))
    })
    useEffect(() => {
        dispatch(getSubscriptionsNotify())
    }, [])
    return (
        <div className={classes.wrapper}>
            <form onSubmit={onSubmit}>
                <div className={classes.notificationsBox}>
                    <div className={classes.notifications}>
                        {subscriptions.map((s) => {
                            return (
                                <Notification
                                    key={s.subsriptionType}
                                    subscription={s}
                                    register={register}
                                    control={control}
                                    setValue={setValue}
                                    title={
                                        notifications.subscriptions[
                                            s.subsriptionType
                                        ]
                                    }
                                />
                            )
                        })}
                    </div>
                    <div className={classes.btnBox}>
                        <Button title={buttons.SAVE_CHANGES} type={'submit'} />
                    </div>
                </div>
            </form>
        </div>
    )
}
