import { BeatLicenseType } from 'DAL/Services'
import { AppSettings } from 'settings/appSettings'
import { BeatDetailsType } from 'DAL/BeatSettingsAPI'

export const beatLicenseNotDefaultPriceHandle = (
    currentBeat: BeatDetailsType,
    licenses: BeatLicenseType[]
) => {
    return currentBeat.bindings
        .filter((bind) => !!bind.priceInRubles || !!bind.priceInDollars)
        .map((bind) => {
            const license = licenses.find(
                (lic) => lic.id === bind.beatLicenseId
            ) as BeatLicenseType
            const title = AppSettings.api.isEngVersion()
                ? license.titleEn
                : license.title
            return {
                ...license,
                priceDollar: bind.priceInDollars,
                price: bind.priceInRubles,
                title: title ? title : '',
            }
        })
}
