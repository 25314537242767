import React from 'react'
import { LanguageType } from 'translations/ru/common'
import { TinkoffInstructionStep } from './TinkoffInstructionStep'
import s from '../../../walletSettings.module.scss'

type Props = {
    settings: LanguageType['settings']['wallets']['tinkoff']
}
export const TinkoffInstruction = ({ settings }: Props) => {
    return (
        <div className={s.instructionContainer}>
            <h2 className={s.title}>{settings.cards}</h2>
            <TinkoffInstructionStep
                title={settings.steps.step1}
                instruction={settings.stepsInstructions.step1}
            />
            <TinkoffInstructionStep
                title={settings.steps.step2}
                instruction={settings.stepsInstructions.step2}
            />
        </div>
    )
}
