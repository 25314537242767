import React, { ReactNode } from 'react'
import { PlayerLogic } from '../Domain/PlayerLogic'

export const PlayerContext = React.createContext<PlayerLogic | null>(null)

export const PlayerProvider: React.FC<{
    player: PlayerLogic
    children: ReactNode
}> = ({ player, children }) => {
    return (
        <PlayerContext.Provider value={player}>
            {children}
        </PlayerContext.Provider>
    )
}
