import React from 'react'
import { FieldValues, useController, UseControllerProps } from 'react-hook-form'
import {
    NewTextArea,
    Props,
} from 'UI/common/elements/textFields/newTextArea/NewTextArea'

export type ControlledTextAreaProps<TFieldValues extends FieldValues> =
    UseControllerProps<TFieldValues> & Omit<Props, 'onChange' | 'value'>

export const ControlledTextArea = <TFieldValues extends FieldValues>({
    name,
    rules,
    shouldUnregister,
    control,
    defaultValue,
    customStyles,
    ...textFieldProps
}: ControlledTextAreaProps<TFieldValues>) => {
    const {
        field: { onChange, value },
        fieldState: { error },
    } = useController({
        name,
        control,
        shouldUnregister,
        rules,
        defaultValue,
    })

    return (
        <NewTextArea
            {...{
                value,
                onChange,
                customStyles,
                error,
                ...textFieldProps,
            }}
        />
    )
}
