import React, { useEffect } from 'react'
import { getDiscounts } from 'BLL/CustomerDiscountsReducer'
import { useDispatch } from 'react-redux'
import Table from '../../../common/table/Table'
import { useSelector } from 'UI/common/hooks/redux-hooks'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'
import { DiscountType } from 'DAL/DiscountAPI'
import { CustomerRM } from 'UI/routers/RouterManager'
import { Modal } from 'UI/common/modal/modal'
import { useHistory, Route } from 'react-router-dom'
import { CouponsEdit } from '../couponEdit/CouponsEdit'
import { Coupon } from '../../Mobile/MobDiscountsPage/Coupon/Coupon'
import { useDeviceDetect } from 'UI/common/hooks/useDeviceDetect'
import { selectDiscounts } from 'BLL/selectors/discounts-selectors/selectDiscounts'
import { DeleteCoupon } from 'UI/CustomerAdmin/CustomerDiscounts/couponDelete/DeleteCoupon'
import { RequestStatuses } from 'BLL/AppReducer'
import { selectRequestStatus } from 'BLL/selectors/app-selectors'
import Spinner from 'UI/common/progressBars/Spinner'
import { getDiscountsTableData } from 'UI/common/table/tableData/GetDiscountsTableData'

export const Coupons = React.memo(() => {
    const requestStatus = useSelector(selectRequestStatus)
    const discounts = useSelector(selectDiscounts)
    const dispatch = useDispatch()

    const history = useHistory()

    const { i18n } = useTranslation()
    const { discounts: discountsPage, shopCoupon } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    useEffect(() => {
        dispatch(getDiscounts())
    }, [])

    const isMobile = useDeviceDetect()

    if (!discounts.length && requestStatus === RequestStatuses.InProgress)
        return <Spinner />

    return (
        <div>
            {!isMobile && discounts.length ? (
                <Table<DiscountType>
                    items={discounts}
                    toolbar={false}
                    fields={getDiscountsTableData(i18n)}
                    title={''}
                />
            ) : (
                discounts.map((discount) => (
                    <Coupon key={discount.id} discount={discount} />
                ))
            )}

            <Route
                path={CustomerRM.discounts.edit.path}
                render={({ match }) => (
                    <Modal
                        active={true}
                        setActive={() => {}}
                        title={discountsPage.editPopUp.title}
                        cancelRedirect={() =>
                            history.push(
                                CustomerRM.discounts.path.getUrl('coupons')
                            )
                        }
                    >
                        <CouponsEdit
                            discount={discounts.find(
                                (d) => d.id === match.params['couponId']
                            )}
                        />
                    </Modal>
                )}
            />
            <Route
                path={CustomerRM.discounts.deleteCoupon.path}
                render={() => (
                    <Modal
                        active={true}
                        setActive={() => {}}
                        title={shopCoupon.deletePopUpTitle}
                        cancelRedirect={() =>
                            history.push(
                                CustomerRM.discounts.path.getUrl('coupons')
                            )
                        }
                    >
                        <DeleteCoupon />
                    </Modal>
                )}
            />
        </div>
    )
})
