import { selectMoods, selectStyles } from 'BLL/selectors/app-selectors'
import { useSelector } from 'react-redux'
import { OptionsType } from 'UI/common/elements/CustomSelect'
import { BeatCreationMethod } from 'UI/CustomerAdmin/FreeBeatsPage/editFreeBeat/enums'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'

export const useBeatOptions = () => {
    const { i18n } = useTranslation()
    const { myBeats } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    const moods = useSelector(selectMoods)
    const styles = useSelector(selectStyles)

    const stylesOption: OptionsType[] = styles.map((style) => ({
        value: style.id,
        label: style.title,
    }))

    const moodsOption: OptionsType[] = moods.map((mood) => ({
        value: mood.id,
        label: mood.title,
    }))

    const creationOption: OptionsType[] = [
        {
            value: BeatCreationMethod.Composing,
            label: myBeats.formsField.creationMethod.composing,
        },
        {
            value: BeatCreationMethod.Sampling,
            label: myBeats.formsField.creationMethod.sampling,
        },
    ]

    return { stylesOption, moodsOption, creationOption }
}
