import { PaymentBeatDetails, PaymentType, SaleType } from 'DAL/mySalesAPI'
import { LanguageType } from 'translations/ru/common'
import { AppSettings } from 'settings/appSettings'
import { PaymentSystemTypes } from 'DAL/paymentsAPI'
import { CurrencyEnum } from 'DAL/CashbackAPI'
import React from 'react'
import { TableType } from '../types'
import { i18n } from 'i18next'

export const getPaymentsDetailTableData = (
    i18n: i18n
): TableType<SaleType & PaymentBeatDetails & { payment: PaymentType }> => {
    const { paymentsAdmin } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType
    return [
        {
            title: paymentsAdmin.detailTableColumns.profile,
            field: 'id',
            render: (rootPayment) => {
                return (
                    <a
                        href={`${AppSettings.urls.MAIN_DOMAIN}artist/${rootPayment.buyerId}`}
                        target={'_blank'}
                        rel="noreferrer"
                    >
                        {rootPayment.buyerName}
                    </a>
                )
            },
        },
        {
            title: paymentsAdmin.detailTableColumns.beatTitle,
            field: 'beatTitle',
            render: (data) => {
                return (
                    <div
                        style={{
                            width: 150,
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {data.beatTitle}
                    </div>
                )
            },
        },
        {
            title: paymentsAdmin.detailTableColumns.licenseTitle,
            field: i18n.language === 'ru' ? 'licenseTitle' : 'licenseTitleEng',
        },
        // {
        //   title: paymentsAdmin.detailTableColumns.paymentSource, field: "id", render: (details) => {
        //     return <div>{PAYMENT_SOURCES[details.paymentSource]}</div>;
        //   }
        // },
        {
            title: paymentsAdmin.detailTableColumns.beatmakerMoney,
            field: 'money',
            render: (details) => {
                return details.paymentSystem ===
                    PaymentSystemTypes.PaypalUsd ? (
                    <div>
                        {details.money} {CurrencyEnum.usd}
                    </div>
                ) : (
                    <div>
                        {details.money} {CurrencyEnum.rub}
                    </div>
                )
            },
        },
        {
            title: paymentsAdmin.detailTableColumns.discountSize,
            field: 'id',
            render: (details) =>
                details.couponData ? (
                    <div>
                        {JSON.parse(details.couponData).DiscountSize}% /{' '}
                        {(
                            (JSON.parse(details.couponData).DiscountSize /
                                100) *
                            details.money
                        ).toFixed(2)}{' '}
                        {details.paymentSystem === PaymentSystemTypes.PaypalUsd
                            ? CurrencyEnum.usd
                            : CurrencyEnum.rub}
                    </div>
                ) : (
                    <div>-</div>
                ),
        },
        {
            title: paymentsAdmin.detailTableColumns.percent,
            field: 'percent',
            render: (details) => {
                // const couponMoney = details.DiscountSize ? details.DiscountSize / 100 * details.money : 0
                // const percentMoney = details.percent / 100 * (details.money - details.cashbackAmount - couponMoney)
                return details.percent ? (
                    <div>{details.percent}%</div>
                ) : (
                    <div>-</div>
                )
            },
        },
        {
            title: paymentsAdmin.detailTableColumns.paymentSystem,
            field: 'paymentSystem',
            render: (details) => {
                return <div>{PaymentSystemTypes[details.paymentSystem]}</div>
            },
        },
        // {
        //   title: paymentsAdmin.detailTableColumns.cashback, field: "cashbackAmount",
        //   render: (details) => details.cashbackAmount ?
        //    <div>{details.cashbackAmount} {details.paymentSystem === PaymentSystemTypes.PaypalUsd ? CurrencyEnum.usd : CurrencyEnum.rub}</div> :
        //    <div>-</div>
        // },

        // {
        //   title: paymentsAdmin.detailTableColumns.money, field: "beatmakerMoney", render: (details) => {
        //     const couponMoney = details.DiscountSize ? details.DiscountSize / 100 * details.money : 0
        //     const percentMoney = details.percent / 100 * (details.money - details.cashbackAmount - couponMoney)
        //     return <div>
        //       {(details.money - couponMoney - percentMoney).toFixed(2)} {details.paymentSystem === PaymentSystemTypes.PaypalUsd ? CurrencyEnum.usd : CurrencyEnum.rub}
        //     </div>
        //   }
        // },
    ]
}
