import React, { FC } from 'react'
import { Controller } from 'react-hook-form'
import FormControlLabel from '@mui/material/FormControlLabel'
import Typography from '@material-ui/core/Typography/Typography'
import { createStyles } from '@material-ui/core/styles'

import { CustomCheckbox } from '../CustomCheckbox'

type PropsType = {
    name: any
    control: any
    defaultChecked?: boolean
    label: string
    labelPlacement?: 'start' | 'end'
    labelSize?: string
}

export const MyCheckbox: FC<PropsType> = React.memo(
    ({
        defaultChecked = false,
        control,
        label,
        labelPlacement = 'end',
        name,
        labelSize,
    }) => {
        // https://stackoverflow.com/a/52965079
        const styles: any = createStyles({
            formControlLabel: { fontSize: labelSize },
        })

        return (
            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <FormControlLabel
                        control={
                            <CustomCheckbox
                                {...field}
                                defaultChecked={defaultChecked}
                            />
                        }
                        label={
                            <Typography style={styles.formControlLabel}>
                                {label}
                            </Typography>
                        }
                        labelPlacement={labelPlacement}
                    />
                )}
            />
        )
    }
)
