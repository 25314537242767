import React, { useCallback, useEffect } from 'react'
import classes from './CustomerMyLicenses.module.scss'
import { TextArea } from '../../../common/elements/textFields/textarea'
import { CustomCheckbox } from '../../../common/elements/CustomCheckbox'
import dragIcon from '../../../assets/icons/drag-icon.svg'
import deleteIcon from '../../../assets/icons/delete.svg'
import { Button } from '../../../common/elements/buttons/Button'
import { NavLink } from 'react-router-dom'
import { CustomerRM } from '../../../routers/RouterManager'
import ArrowLeft from '../../../assets/icons/arrow-left.svg'
import { LanguageType } from '../../../../translations/ru/common'
import { useTranslation } from 'react-i18next'
import { LanguageEnum } from '../../../../BLL/enums'
import { useEditingEntityStatus } from '../../../common/hooks/useEditingEntityStatus'
import { useForm, Controller } from 'react-hook-form'
import {
    addContracts,
    createLicense,
    createLicenseOption,
    getLicenseById,
    getLicensesOptions,
    getSettingsContract,
    actions as licenseActions,
    updateLicense,
} from '../../../../BLL/ContractsReducer'
import { useSelector } from '../../../common/hooks/redux-hooks'
import { BeatLicenseType } from '../../../../DAL/Services'
import { EditingEntityStatuses } from '../../../../BLL/AppReducer'
import { useHistory, useParams } from 'react-router'
import { useDispatch } from 'react-redux'
import { AppSettings } from 'settings/appSettings'
import { InputField } from '../../../common/elements/textFields/Input'
import { PriceField } from '../../../common/elements/textFields/price-input'
import { LicenseOptions } from './license-options'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import { ColorLinearProgress } from '../../../common/progressBars/ColorLinearProgress'
import { MobileView, BrowserView, deviceDetect } from 'react-device-detect'
import { AddNewLicense } from '../../Mobile/MobLicenses/AddNewLicense/AddNewLicense'
import { MyCheckbox } from '../../../common/elements/checkbox/MyCheckbox'
import { CustomMobileView } from '../../../common/mobileView/CustomMobileView'
import { CustomBrowserView } from '../../../common/browserView/CustomBrowserView'

type FormValues = {
    title: string
    titleEn: string
    price: number
    priceDollar: number
    removeBeatFromSale: boolean
    recommended: boolean
    requireClientPassport: boolean
    purchaseIsNotAvailable: boolean
}

export const CustomerMyLicenses = () => {
    const { id } = useParams<{ id: string }>()
    const dispatch = useDispatch()
    const history = useHistory()
    const { i18n } = useTranslation()
    const {
        licenses,
        buttons,
        license: customerMyLicense,
    } = i18n.getResourceBundle(i18n.language, 'common') as LanguageType
    const lang = i18n.language as LanguageEnum
    const { register, handleSubmit, setValue, control, watch } =
        useForm<FormValues>()
    const fields: (keyof FormValues)[] = [
        'title',
        'titleEn',
        'price',
        'priceDollar',
        'removeBeatFromSale',
        'recommended',
        'requireClientPassport',
        'purchaseIsNotAvailable',
    ]
    const onSubmit = async (data: FormValues) => {
        const licenseDto = {
            license: {
                id,
                title: data.title || data.titleEn,
                priceInDollars: Number(data.priceDollar),
                priceInRubles: data.price ? Number(data.price) : data.price,
                purchaseIsNotAvailable: data.purchaseIsNotAvailable,
                recommended: data.recommended,
                removeBeatFromSale: data.removeBeatFromSale,
                requireClientPassport: data.requireClientPassport,
            },
            translate: data.titleEn,
        }
        if (id) {
            dispatch(updateLicense(licenseDto))
        } else {
            dispatch(createLicense(licenseDto))
        }
    }

    const beatLicense = useSelector(
        (state) => state.beatmakerContracts.editLicense
    )

    useEffect(() => {
        if (id) {
            dispatch(getLicenseById(id))
            dispatch(getSettingsContract())
            dispatch(getLicensesOptions(id, lang))
        }
        return () => {
            dispatch(licenseActions.setLicenseEdit(null))
        }
    }, [id])

    useEffect(() => {
        if (beatLicense && !id) {
            history.push(
                CustomerRM.contracts.getUrl.license.edit(beatLicense._id)
            )
        }
        if (beatLicense && id) {
            fields.forEach((field) => {
                setValue(
                    field as keyof FormValues,
                    // @ts-ignore
                    beatLicense[field as keyof FormValues]
                )
            })
        }
    }, [beatLicense, id])

    const handleCreateOption = useCallback(() => {
        beatLicense && dispatch(createLicenseOption(beatLicense._id))
    }, [dispatch, createLicenseOption, beatLicense])

    const handleAddContract = useCallback(() => {
        beatLicense && dispatch(addContracts(lang, beatLicense._id))
    }, [dispatch, addContracts, beatLicense, lang])

    const isEng = AppSettings.api.isEngVersion()

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <CustomBrowserView>
                    <div className={classes.container}>
                        <div className={classes.back}>
                            <NavLink
                                to={CustomerRM.contracts.path.license.base}
                            >
                                <img src={ArrowLeft} alt={'back-items'} />
                                <span>
                                    {isEng
                                        ? beatLicense?.titleEn
                                        : beatLicense?.title}
                                </span>
                            </NavLink>
                        </div>
                        {id && !beatLicense ? (
                            <div className={classes.preloader}>
                                <ColorLinearProgress />
                            </div>
                        ) : (
                            <>
                                {id && (
                                    <div className={classes.licensesTop}>
                                        <h1 className={classes.title}>
                                            {
                                                customerMyLicense.licenseDetails
                                                    .general
                                            }
                                        </h1>
                                        <div className={classes.boxBtn}>
                                            {!beatLicense?.contract ? (
                                                <Button
                                                    type={'button'}
                                                    view={'Outline'}
                                                    onClick={handleAddContract}
                                                    title={buttons.ADD_CONTRACT}
                                                />
                                            ) : (
                                                <NavLink
                                                    to={CustomerRM.contracts.getUrl.contracts.edit(
                                                        beatLicense?.id,
                                                        i18n.language
                                                    )}
                                                    style={{
                                                        textDecoration: 'none',
                                                    }}
                                                >
                                                    <Button
                                                        type={'button'}
                                                        view={'Outline'}
                                                        title={
                                                            buttons.EDIT_CONTRACT
                                                        }
                                                    />
                                                </NavLink>
                                            )}
                                        </div>
                                    </div>
                                )}
                                <div className={classes.settings}>
                                    <div className={classes.textFields}>
                                        <div className={classes.textFieldItem}>
                                            <label>
                                                {
                                                    customerMyLicense
                                                        .licenseDetails
                                                        .licenseNameEng
                                                }
                                            </label>
                                            <InputField
                                                defaultValue={
                                                    beatLicense?.titleEn || ''
                                                }
                                                register={register('titleEn', {
                                                    required: true,
                                                })}
                                            />
                                        </div>
                                        {!isEng && (
                                            <div
                                                className={
                                                    classes.textFieldItem
                                                }
                                            >
                                                <label>
                                                    {
                                                        customerMyLicense
                                                            .licenseDetails
                                                            .licenseNameRus
                                                    }
                                                </label>
                                                <InputField
                                                    defaultValue={
                                                        beatLicense?.title || ''
                                                    }
                                                    register={register(
                                                        'title',
                                                        {
                                                            required: true,
                                                        }
                                                    )}
                                                />
                                            </div>
                                        )}
                                        <div className={classes.textFieldItem}>
                                            <label>
                                                {
                                                    customerMyLicense
                                                        .licenseDetails
                                                        .licenseCostEng
                                                }
                                            </label>
                                            <PriceField
                                                register={register(
                                                    'priceDollar',
                                                    {
                                                        required: true,
                                                    }
                                                )}
                                                defaultValue={
                                                    beatLicense?.priceDollar ||
                                                    0
                                                }
                                            />
                                        </div>
                                        {!isEng && (
                                            <div
                                                className={
                                                    classes.textFieldItem
                                                }
                                            >
                                                <label>
                                                    {
                                                        customerMyLicense
                                                            .licenseDetails
                                                            .licenseCostRus
                                                    }
                                                </label>
                                                <PriceField
                                                    type={'RUB'}
                                                    register={register(
                                                        'price',
                                                        {
                                                            required: true,
                                                        }
                                                    )}
                                                    defaultValue={
                                                        beatLicense?.price || 0
                                                    }
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div className={classes.selections}>
                                        <div className={classes.selectionBox}>
                                            <div
                                                className={
                                                    classes.selectionItem
                                                }
                                            >
                                                <MyCheckbox
                                                    name={'removeBeatFromSale'}
                                                    control={control}
                                                    defaultChecked={
                                                        beatLicense
                                                            ? beatLicense[
                                                                  'removeBeatFromSale'
                                                              ]
                                                            : false
                                                    }
                                                    label={
                                                        licenses.form
                                                            .removeBeatFromSale
                                                    }
                                                />
                                            </div>
                                            <p className={classes.description}>
                                                {
                                                    licenses.helpTextForCheckbox
                                                        .removeBeatFromSale
                                                }
                                            </p>
                                        </div>
                                        <div className={classes.selectionBox}>
                                            <div
                                                className={
                                                    classes.selectionItem
                                                }
                                            >
                                                <MyCheckbox
                                                    name={'recommended'}
                                                    control={control}
                                                    defaultChecked={
                                                        beatLicense
                                                            ? beatLicense[
                                                                  'recommended'
                                                              ]
                                                            : false
                                                    }
                                                    label={
                                                        licenses.form
                                                            .recommended
                                                    }
                                                />
                                            </div>
                                            <p className={classes.description}>
                                                {
                                                    licenses.helpTextForCheckbox
                                                        .recommended
                                                }
                                            </p>
                                        </div>
                                        <div className={classes.selectionBox}>
                                            <div
                                                className={
                                                    classes.selectionItem
                                                }
                                            >
                                                <MyCheckbox
                                                    name={
                                                        'requireClientPassport'
                                                    }
                                                    control={control}
                                                    defaultChecked={
                                                        beatLicense
                                                            ? beatLicense[
                                                                  'requireClientPassport'
                                                              ]
                                                            : false
                                                    }
                                                    label={
                                                        licenses.form
                                                            .requireClientPassport
                                                    }
                                                />
                                            </div>
                                            <p className={classes.description}>
                                                {
                                                    licenses.helpTextForCheckbox
                                                        .requireClientPassport
                                                }
                                            </p>
                                        </div>
                                    </div>
                                    {id && (
                                        <div className={classes.options}>
                                            <h2 className={classes.subtitle}>
                                                {
                                                    customerMyLicense
                                                        .licenseDetails
                                                        .licenseOptions
                                                }
                                                :
                                            </h2>
                                            <div className={classes.option}>
                                                <LicenseOptions isEng={true} />
                                                {!isEng && (
                                                    <LicenseOptions
                                                        isEng={false}
                                                    />
                                                )}
                                            </div>
                                            <button
                                                type={'button'}
                                                className={classes.newOptionBtn}
                                                onClick={handleCreateOption}
                                            >
                                                + {buttons.ADD_OPTIONS}
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </CustomBrowserView>
                <CustomMobileView>
                    <AddNewLicense
                        beatLicense={beatLicense}
                        handleAddContract={handleAddContract}
                        control={control}
                        handleCreateOption={handleCreateOption}
                        register={register}
                    />
                </CustomMobileView>
                <div className={classes.buttonsBox}>
                    <NavLink
                        to={CustomerRM.contracts.path.license.base}
                        style={{ textDecoration: 'none' }}
                    >
                        <Button
                            view={'Secondary'}
                            title={buttons.CANCEL}
                            type={'button'}
                        />
                    </NavLink>

                    <Button
                        title={buttons.SAVE}
                        type={'submit'}
                        onClick={handleSubmit(onSubmit)}
                    />
                </div>
            </form>
        </>
    )
}
