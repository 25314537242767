import React, { useEffect } from 'react'
import { ControlledTextField } from 'UI/common/controls/controlledTextField/ControlledTextField'
import { Button } from 'UI/common/elements/buttons/Button'
import i18n from 'i18next'
import { LanguageType } from 'translations/ru/common'
import { useForm } from 'react-hook-form'
import { useEnotWalletData } from 'UI/CustomerAdmin/BeatmakerSettingsNew/wallet-settings/wallets/enot/hooks/useEnotWalletData'
import { UpdateEnoWalletType } from 'DAL/walletsAPI'
import { fetchedUpdateEnotWallet } from 'BLL/CustomerWalletsReducer'
import c from 'UI/CustomerAdmin/BeatmakerSettingsNew/wallet-settings/walletSettings.module.scss'
import s from './enot.module.scss'

export const EnotForm = () => {
    const {
        enotNumber,
        enotApiSecretKey,
        enotSecret,
        htmlEnotFileName,
        dispatch,
    } = useEnotWalletData()

    const disableForm = htmlEnotFileName === null

    const { buttons } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    useEffect(() => {
        htmlEnotFileName && setValue('enotHtmlFileName', htmlEnotFileName)
        enotNumber && setValue('enotNumber', enotNumber)
        enotSecret && setValue('enotSecret', enotSecret)
        enotApiSecretKey && setValue('enotSecretForForm', enotApiSecretKey)
    }, [enotNumber, enotSecret, enotApiSecretKey])

    const {
        control,
        setValue,
        formState: { errors },
        handleSubmit,
    } = useForm()

    const onSubmit = handleSubmit((data: UpdateEnoWalletType) => {
        dispatch(fetchedUpdateEnotWallet(data))
    })

    return (
        <div className={c.walletForm}>
            <form onSubmit={onSubmit}>
                <div className={s.fieldsWrapper}>
                    <ControlledTextField
                        name={'enotHtmlFileName'}
                        control={control}
                        error={errors}
                        variant={'primary'}
                        label={'Имя html файла для проверки Вашего домена'}
                    />
                    <ControlledTextField
                        name={'enotNumber'}
                        control={control}
                        className={s.textFieldWith}
                        error={errors}
                        variant={'primary'}
                        label={'Номер кошелька'}
                    />
                    <ControlledTextField
                        name={'enotSecret'}
                        control={control}
                        variant={'primary'}
                        label={'Секретный ключ'}
                        error={errors}
                    />
                    <ControlledTextField
                        name={'enotSecretForForm'}
                        control={control}
                        variant={'primary'}
                        label={'enotApi SecretKey'}
                        error={errors}
                    />
                </div>
                <Button title={buttons.SAVE_CHANGES} />
            </form>
        </div>
    )
}
