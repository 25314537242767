import { AppState } from 'BLL/Store'

export const selectPagesCount = (state: AppState) =>
    state.myBeatsPage.pagesCount
export const selectTotalCount = (state: AppState) =>
    state.myBeatsPage.totalCount
export const selectBeats = (state: AppState) => state.myBeatsPage.items
export const selectCurrentBeat = (state: AppState) =>
    state.myBeatsPage.currentBeat

export const selectFilesBindedToLicenses = (state: AppState) =>
    state.myBeatsPage.filesBindedToLicenses
