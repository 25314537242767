import { actions as appActions } from 'BLL/AppReducer'
import { CONSTANTS } from 'BLL/SupportingFile'
import { Dispatch } from 'redux'

export const copyBuffer = (text: string, dispatch: Dispatch) => {
    try {
        navigator.clipboard.writeText(text).then(() => {
            dispatch(appActions.showSnackbar('success', CONSTANTS.COPY))
        })
    } catch (e) {
        dispatch(appActions.showSnackbar('error', CONSTANTS.COPY_FAILED))
    }
}
