import React, { useState } from 'react'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'
import classes from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/imageCropper/imageCropper.module.scss'
import { Button } from 'UI/common/elements/buttons/Button'
import { BrowseButtonOutline } from 'UI/common/elements/buttons/BrowseButtonOutline'
import DragIcon from 'UI/assets/icons/drag.svg'

type PropsType = {
    saveFile: (formData: any) => void
    handleClose: (value: boolean) => void
    setArtWork: (image: string) => void
}
export const ImageCropper: React.FC<PropsType> = ({
    saveFile,
    handleClose,
    setArtWork,
}) => {
    const [image, setImage] = useState('')
    const [cropper, setCropper] = useState<any>()
    const [fileOrigin, setFileOrigin] = useState<any>()

    const { i18n } = useTranslation()
    const { adminExpertsCompilation, buttons } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType
    const onChange = (e: any) => {
        e.preventDefault()
        let files
        if (e.dataTransfer) {
            files = e.dataTransfer.files
        } else if (e.target) {
            files = e.target.files
        }

        const reader = new FileReader()
        reader.onload = () => {
            setImage(reader.result as any)
        }
        reader.readAsDataURL(files[0])
        setFileOrigin(files[0])
    }

    const handleSave = async () => {
        if (typeof cropper !== 'undefined') {
            const photoCropper = cropper.getCroppedCanvas().toDataURL()
            await cropper
                .getCroppedCanvas()
                .toBlob(async (value: Blob | null) => {
                    if (value) {
                        const file = new File([value], fileOrigin.name, {
                            type: value.type,
                            lastModified: Date.now(),
                        })
                        const formData = new FormData()
                        formData.append('file', file)
                        saveFile(formData)
                        setArtWork(photoCropper)
                        handleClose(false)
                    }
                })
        }
    }

    return (
        <div className={classes.cropperWrapper}>
            {image ? (
                <Cropper
                    className={classes.cropper}
                    initialAspectRatio={1}
                    preview=".img-preview"
                    src={image}
                    viewMode={1}
                    guides={true}
                    minCropBoxHeight={10}
                    minCropBoxWidth={10}
                    background={false}
                    responsive={true}
                    autoCropArea={1}
                    aspectRatio={1}
                    checkOrientation={false}
                    onInitialized={(instance) => {
                        setCropper(instance)
                    }}
                />
            ) : (
                <div className={classes.cropperImage}>
                    <img src={DragIcon} alt={'drag'} />
                </div>
            )}
            <div className={classes.wrapperButton}>
                <BrowseButtonOutline
                    title={adminExpertsCompilation.uploadPhoto}
                    onChange={onChange}
                    id={'photo_cropper'}
                />
                <Button
                    title={buttons.SAVE}
                    onClick={handleSave}
                    type={'button'}
                    disabled={!image}
                />
            </div>
        </div>
    )
}
