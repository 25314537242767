import React, { FC, useEffect, useState } from 'react'
import { Button, FormControl, InputLabel, Paper } from '@material-ui/core'
import AceEditor from 'react-ace'

import 'ace-builds/src-noconflict/mode-handlebars'
import 'ace-builds/src-noconflict/theme-monokai'
import ClosePopupDialog from '../ClosePopupDialog'
import { EmailTemplateType } from '../../../../BLL/types'
import { useTranslation } from 'react-i18next'
import { LanguageType } from '../../../../translations/ru/common'
import classes from './editor.module.css'
import { useSelector } from '../../../common/hooks/redux-hooks'
import { useHistory, useParams } from 'react-router'
import { useDispatch } from 'react-redux'
import {
    actions as templateActions,
    getHtmlToRender,
    getTemplateById,
    updateTemplate,
} from '../../../../BLL/AdminTemplatesEmailReducer'
import Header from '../../../common/header/Header'
import RenderHtmlTemplate from '../RenderHtmlTemplate'
import { MaterialInput } from '../../../common/MaterialInput'
import { useForm } from 'react-hook-form'
import { AdminRM } from '../../../routers/RouterManager'
import { AppSettings } from 'settings/appSettings'

type FormValues = {
    name: string
    templateKey: string
    modelProperty: string
}

export const Editor: FC = () => {
    const [templateState, setTemplate] = useState<null | EmailTemplateType>(
        null
    )
    const [isChanged, setIsChanged] = useState<boolean>(false)

    const dispatch = useDispatch()
    const history = useHistory()

    const { id } = useParams<{ id: string }>()
    const currentTemplate = useSelector(
        (state) => state.adminTemplatesEmail.currentTemplate
    )
    const htmlToRender = useSelector(
        (state) => state.adminTemplatesEmail.htmlToRender
    )
    const { register, watch, getValues, setError } = useForm<FormValues>()

    const { i18n } = useTranslation()
    const { adminEditTemplate, buttons, navigation } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    useEffect(() => {
        dispatch(getTemplateById(id))
        return () => {
            dispatch(templateActions.setHtmlToRender(''))
            dispatch(templateActions.setCurrentTemplate(null))
        }
    }, [id, dispatch])

    useEffect(() => {
        setIsChanged(false)
        setTemplate(currentTemplate)
        dispatch(getHtmlToRender(id))
    }, [currentTemplate, dispatch, setTemplate, setIsChanged, id])

    const changeTemplate = (html: string) => {
        setIsChanged(true)
        currentTemplate &&
            setTemplate({
                ...currentTemplate,
                html,
            })
    }
    const name = watch('name')
    const templateKey = watch('templateKey')
    const modelProperty = watch('modelProperty')

    useEffect(() => {
        setIsChanged(true)
    }, [name, templateKey, modelProperty])

    const handleUpdateTemplate = (template: EmailTemplateType) => {
        const name = getValues('name')
        const templateKey = getValues('templateKey')
        const modelProperty = getValues('modelProperty')
        if (!name) setError('name', { message: 'Field is required' })
        if (!templateKey)
            setError('templateKey', { message: 'Field is required' })
        if (!templateKey || !name) return

        dispatch(
            updateTemplate({
                id,
                author: 'Artem',
                template: {
                    ...template,
                    name,
                    templateKey,
                    modelProperty,
                },
            })
        )
    }

    const handleCloseHtmlTemplate = async () => {
        history.push(AdminRM.templates.path.list)
    }

    return (
        <div>
            <Header title={navigation.templateEditor} />
            {currentTemplate && (
                <div className={classes.root}>
                    {isChanged && templateState ? (
                        <div>
                            <ClosePopupDialog
                                handleEditorChange={handleUpdateTemplate}
                                templateState={templateState}
                                handleCloseHtmlTemplate={
                                    handleCloseHtmlTemplate
                                }
                            />
                            <div className={classes.info}>
                                <span style={{ color: 'red' }}>*</span>
                                {adminEditTemplate.notSavedMessage}
                            </div>
                        </div>
                    ) : (
                        <Button
                            variant="contained"
                            onClick={handleCloseHtmlTemplate}
                            style={{
                                background: AppSettings.api.getBaseColor(),
                                fontWeight: 600,
                                marginBottom: 20,
                            }}
                        >
                            {buttons.BACK_TO_TEMPLATES}
                        </Button>
                    )}
                    <Paper className={classes.form}>
                        <FormControl className={classes.fromControl}>
                            <InputLabel
                                shrink
                                htmlFor={adminEditTemplate.tableColumns.name}
                            >
                                {adminEditTemplate.tableColumns.name}*
                            </InputLabel>
                            <MaterialInput
                                id={adminEditTemplate.tableColumns.name}
                                {...register('name', { required: true })}
                                defaultValue={currentTemplate.name}
                                className={classes.field}
                                // error={!!errors.name}
                            />
                        </FormControl>
                        <FormControl className={classes.fromControl}>
                            <InputLabel
                                shrink
                                htmlFor={
                                    adminEditTemplate.tableColumns.templateKey
                                }
                            >
                                {adminEditTemplate.tableColumns.templateKey}*
                            </InputLabel>
                            <MaterialInput
                                id={adminEditTemplate.tableColumns.templateKey}
                                {...register('templateKey', { required: true })}
                                defaultValue={currentTemplate.templateKey}
                                className={classes.field}
                                // error={!!errors.templateKey}
                            />
                        </FormControl>
                        <div className={classes.helpText}>
                            (!) Для правильного отображения данных в
                            сгенерированном шаблоне переменные необходимо
                            указывать в двойных скобках{' '}
                            <span>{'{{userName}}'}</span>
                        </div>

                        {/*<div style={{*/}
                        {/*    color: "white",*/}
                        {/*    marginBottom: 10*/}
                        {/*}}>{adminEditTemplate.paramsToTemplate}: {currentTemplate?.modelProperty.map((prop) => <span*/}
                        {/*  style={{ color: "#ff8f0c", marginRight: 10 }}>{"{{" + prop + "}}"}</span>)}*/}

                        {/*</div>*/}
                        {templateState && (
                            <div className={classes.editorWrapper}>
                                <AceEditor
                                    mode="handlebars"
                                    theme="monokai"
                                    height={'800px'}
                                    width={'100%'}
                                    value={templateState.html}
                                    onChange={(html) => changeTemplate(html)}
                                    name="4yr9834r934nfi3f3fi34f34f34ufnik"
                                    editorProps={{ $blockScrolling: true }}
                                />

                                <FormControl
                                    className={classes.fromControl}
                                    fullWidth
                                >
                                    <InputLabel
                                        shrink
                                        htmlFor={
                                            adminEditTemplate.tableColumns
                                                .modelProperty
                                        }
                                    >
                                        {
                                            adminEditTemplate.tableColumns
                                                .modelProperty
                                        }
                                        *
                                    </InputLabel>
                                    <MaterialInput
                                        id={
                                            adminEditTemplate.tableColumns
                                                .modelProperty
                                        }
                                        {...register('modelProperty', {
                                            required: true,
                                        })}
                                        defaultValue={
                                            currentTemplate.modelProperty
                                        }
                                        // error={!!errors.name}
                                        rows={15}
                                        fullWidth
                                        multiline
                                    />
                                </FormControl>
                                {/*<PropertyInput model={templateState.modelProperty}*/}
                                {/*               setModel={(modelProperty: string[]) => setTemplate({*/}
                                {/*                   ...currentTemplate,*/}
                                {/*                   modelProperty*/}
                                {/*               })}/>*/}

                                <Button
                                    variant="contained"
                                    onClick={() =>
                                        handleUpdateTemplate(templateState)
                                    }
                                    style={{
                                        background:
                                            AppSettings.api.getBaseColor(),
                                        fontWeight: 600,
                                        marginTop: 20,
                                    }}
                                >
                                    {buttons.SAVE_AND_RESULT}
                                </Button>
                            </div>
                        )}
                    </Paper>
                    <div className={classes.info}>
                        {adminEditTemplate.result}
                    </div>
                    <div className={classes.renderHtmlWrapper}>
                        {htmlToRender && (
                            <RenderHtmlTemplate htmlToRender={htmlToRender} />
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}

export default Editor
