import React, { FC } from 'react'
import { ITimeIntervals } from 'BLL/CustomerBeatsPublicationReducer'
import classes from 'UI/common/elements/timePicker/timePicker.module.scss'
import { CustomSelect } from '../selects/CustomSelect'

type PropsType = {
    handleChange: (time: string) => void
    time?: string | undefined
    disabled?: boolean
    timeIntervals: ITimeIntervals[]
}

export const CustomTimePicker: FC<PropsType> = ({
    time,
    handleChange,
    disabled,
    timeIntervals,
}) => {
    const handleOnChange = (event: React.ChangeEvent<{ value: any }>): void => {
        event.target.value && handleChange(event.target.value)
    }
    const items = timeIntervals.map((t) => ({
        value: t.getString(),
        title: t.getString(),
    }))
    return (
        <div className={classes.TimePicker}>
            <CustomSelect
                items={items}
                width={160}
                isClock={true}
                value={time}
                onChange={handleOnChange}
                disabled={disabled}
            />
        </div>
    )
}
