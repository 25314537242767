import React, {
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react'
import { useDispatch } from 'react-redux'
import { LanguageType } from 'translations/ru/common'
import { useTranslation } from 'react-i18next'
import { getBeats, uploadBeat } from 'BLL/CustomerMyBeatsReducer'
import { useHistory, useLocation } from 'react-router-dom'
import { UploadBeatModal } from './uploadBeatModal/UploadBeatModal'
import { FormControl } from '@material-ui/core'
import { useRedirectCustomHook } from 'UI/common/hooks/useRedirectCustomHook'
import { RolesTypeEnum } from 'BLL/enums'
import { CustomerRM } from 'UI/routers/RouterManager'
import { Button } from 'UI/common/elements/buttons/Button'
import { SearchField } from 'UI/common/elements/textFields/search'
import { CustomMobileView } from 'UI/common/mobileView/CustomMobileView'
import { CustomBrowserView } from 'UI/common/browserView/CustomBrowserView'
import { useDeviceDetect } from 'UI/common/hooks/useDeviceDetect'
import { DraggableTable } from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/draggableTable/DraggableTable'
import { BeatsPageRoutes } from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/routes/BeatsPageRoutes'
import { useBeatsPagination } from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/hooks/useBeatsPagination'
import { useBeatsData } from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/hooks/useBeatsData'
import { useDebounce } from 'UI/common/hooks/useDebounce'
import Spinner from 'UI/common/progressBars/Spinner'
import { RequestStatuses } from 'BLL/AppReducer'
import s from './beatspage.module.scss'
import { BeatsConstants } from 'UI/constants'

function useQuery() {
    return new URLSearchParams(useLocation().search)
}

interface Props {
    children?: React.ReactNode
}

export const PaidBeats = ({ children }: Props) => {
    const { beats, totalCount, requestStatus } = useBeatsData()
    const dispatch = useDispatch()

    const { i18n } = useTranslation()
    const { navigation, myBeats, helpMessage } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    const [modalIsActive, setModalIsActive] = useState(false)

    const {
        term,
        pagePrev,
        pageSizePrev,
        setTerm,
        setPageSizePrev,
        setPagePrev,
    } = useBeatsPagination()

    const debounced = useDebounce(term)
    const firstRender = useRef(false)

    useEffect(() => {
        firstRender.current = true
    }, [])

    useLayoutEffect(() => {
        firstRender.current = false
    })

    useEffect(() => {
        dispatch(
            getBeats(
                BeatsConstants.DEFAULT_PAGE,
                BeatsConstants.DEFAULT_PAGE_SIZE
            )
        )
    }, [])

    useEffect(() => {
        if (firstRender.current) return

        dispatch(getBeats(pagePrev, pageSizePrev, term))
    }, [debounced])

    useEffect(() => {
        if (firstRender.current) return

        dispatch(getBeats(pagePrev, pageSizePrev))
    }, [pagePrev, pageSizePrev])

    const handleChangePageSize = useCallback((pageSize: number) => {
        setPageSizePrev(pageSize)
    }, [])

    const handleChangePage = useCallback((page: number) => {
        setPagePrev(page)
    }, [])

    const saveFileHandler = async (
        formData: any,
        onUploadProgress: (progressEvent: any) => void,
        cancelToken: any
    ) => {
        await dispatch(uploadBeat(formData, onUploadProgress, cancelToken))
        dispatch(getBeats(pagePrev, pageSizePrev))
    }

    const handleAddBeat = useCallback(() => {
        setModalIsActive(true)
    }, [])

    const isMobile = useDeviceDetect()

    return (
        <div className={s.root}>
            {useRedirectCustomHook(
                RolesTypeEnum.BEATMAKER,
                CustomerRM.cashback.path.list
            )}
            <div>
                <div
                    className={isMobile ? s.MobMyBeatsPageHeader : s.topWrapper}
                >
                    <CustomBrowserView>
                        <div className={s.pageTitle}>{navigation.myBeats}</div>
                    </CustomBrowserView>
                    <div className={!isMobile ? s.searchWrapper : s.fieldSize}>
                        <FormControl>
                            <SearchField
                                placeholder={helpMessage.search}
                                value={term}
                                onChange={({ target: { value } }) =>
                                    setTerm(value)
                                }
                            />
                        </FormControl>
                        <CustomBrowserView>
                            <Button
                                title={myBeats.addBeat}
                                onClick={handleAddBeat}
                            />
                        </CustomBrowserView>
                    </div>
                    <CustomMobileView>
                        <Button
                            title={myBeats.addBeat}
                            onClick={handleAddBeat}
                        />
                    </CustomMobileView>
                </div>
                <div className={s.tabsWrapper}>{children}</div>
                {beats.length ? (
                    <DraggableTable
                        page={pagePrev}
                        pageSize={pageSizePrev}
                        totalCount={totalCount}
                        beats={beats}
                        term={term}
                        ChangePageSize={handleChangePageSize}
                        ChangePage={handleChangePage}
                    />
                ) : (
                    requestStatus === RequestStatuses.InProgress && <Spinner />
                )}
                {requestStatus === RequestStatuses.Success &&
                    beats.length === 0 && (
                        <div className={s.emptyMessage}>{myBeats.noBeats}</div>
                    )}
            </div>
            <BeatsPageRoutes setModalIsActive={setModalIsActive} />
            <UploadBeatModal
                modalIsActive={modalIsActive}
                setModalIsActive={setModalIsActive}
                type={'mp3'}
                saveFileHandler={saveFileHandler}
            />
        </div>
    )
}
