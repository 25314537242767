import { DiscountGroupType } from 'DAL/DiscountAPI'
import { LanguageType } from 'translations/ru/common'
import { AppSettings } from 'settings/appSettings'
import s from 'UI/common/table/tableData.module.scss'
import DiscountsGroupIcon from 'UI/assets/icons/discount-group.svg'
import Tooltip from '@material-ui/core/Tooltip'
import { NavLink } from 'react-router-dom'
import { CustomerRM } from 'UI/routers/RouterManager'
import DeleteIcon from 'UI/assets/icons/delete.svg'
import React from 'react'
import { i18n } from 'i18next'
import { TableType } from 'UI/common/table/types'

export const getGroupsDiscountsTableData = (
    i18n: i18n
): TableType<DiscountGroupType> => {
    const { discounts, buttons } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType
    const isEng = AppSettings.api.isEngVersion()
    return [
        {
            title: discounts.tableColumn.name,
            field: 'buyedLicenseId',
            render: (data) => {
                return (
                    <div className={s.getDiscountsTableDataName}>
                        <div>
                            <img src={DiscountsGroupIcon} alt={'discount'} />
                        </div>
                        <span>
                            {discounts.groupDiscounts.buy}{' '}
                            {data.buyedLicenseCount}{' '}
                            {discounts.groupDiscounts.tracks} ,{' '}
                            {discounts.groupDiscounts.get}{' '}
                            {data.bonusLicenseCount}{' '}
                            {discounts.groupDiscounts.free}!
                        </span>
                    </div>
                )
            },
        },

        {
            title: discounts.tableColumn.licenses,
            field: 'buyedLicenseId',
            render: (data) => {
                return (
                    <div>
                        {isEng
                            ? data.buyedLicense?.titleEn
                            : data.buyedLicense?.title}
                    </div>
                )
            },
        },
        {
            title: discounts.tableColumn.action,
            field: 'beatmakerId',
            headerStyle: {
                textAlign: 'right',
            },
            render: (data) => {
                return (
                    <div className={s.getDiscountsTableDataActions}>
                        <Tooltip title={buttons.DELETE}>
                            <NavLink
                                to={CustomerRM.discounts.delete.getUrl(data.id)}
                            >
                                <img
                                    className={s.icons}
                                    src={DeleteIcon}
                                    alt={'delete'}
                                />
                            </NavLink>
                        </Tooltip>
                    </div>
                )
            },
        },
    ]
}
