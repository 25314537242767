import {
    EnvironmentSettings,
    EnvironmentsTypes,
} from 'settings/environmentSettings'

export type EnvironmentVariable = { [key: string]: string | undefined }

class APISettings {
    public readonly LANGUAGE_VERSION: string
    public readonly DEV_SHOW_ALL_MENU_ITEMS: boolean

    constructor(private envVariables: EnvironmentVariable) {
        this.LANGUAGE_VERSION = envVariables.REACT_APP_LANGUAGE_VERSION || 'ru'
        this.DEV_SHOW_ALL_MENU_ITEMS = JSON.parse(
            envVariables.REACT_APP_DEV_SHOW_ALL_MENU_ITEMS || 'false'
        )
    }

    isEngVersion() {
        return this.LANGUAGE_VERSION === 'en'
    }

    getBaseColor() {
        return this.isEngVersion() ? '#ffc400' : '#3598DB'
    }

    async getLanguage() {
        return (await localStorage.getItem('i18nextLng')) || 'ru'
    }
}

class AppSettingsClass {
    constructor(
        public env: EnvironmentSettings,
        public api: APISettings,
        public urls: { [key: string]: string }
    ) {}
}

const env = new EnvironmentSettings(
    (process.env.REACT_APP_ENV || 'DEVELOPMENT') as EnvironmentsTypes
)
const api = new APISettings(process.env)

const urls = {
    beatsPromotionApi:
        process.env.REACT_APP_BEATS_PROMO_API_URL ||
        'https://staging-beatmaker-promo-api-15c73eba918b.herokuapp.com',
    // getUserToken:
    //     process.env.REACT_APP_GET_USER_TOKEN_URL ||
    //     'https://staging3.beatmaker.tv/auth/auth/getToken',
    loginPageUrl:
        process.env.REACT_APP_LOGIN_PAGE_URL ||
        'https://staging3.beatmaker.tv/login?redirectToReferer=true',

    CORE_BASE_URL:
        process.env.REACT_APP_CORE_BASE_URL || 'https://staging3.beatmaker.tv/',
    API_BASE_URL:
        process.env.REACT_APP_API_BASE_URL ||
        'https://api.staging3.beatmaker.tv/',
    SHOP_BASE_URL:
        process.env.REACT_APP_SHOP_BASE_URL ||
        'https://shop-api.staging3.beatmaker.tv/api/',
    EMAIL_MARKETING_API_URL:
        process.env.REACT_APP_EMAIL_MARKETING_API_URL ||
        'https://st-beatmaker-email-marketing-6f75498d158b.herokuapp.com/',
    PRODS_PAGE_API_URL:
        process.env.REACT_APP_PRODS_PAGE_API_URL ||
        'https://st-prods-page-api-6be5b34e5607.herokuapp.com/',
    MAIN_DOMAIN:
        process.env.REACT_APP_MAIN_DOMAIN || 'https://staging3.beatmaker.tv/',
    CORE_AUTH_IFRAME_URL:
        process.env.REACT_APP_CORE_AUTH_IFRAME_URL ||
        'https://staging3.beatmaker.tv/test/test',
    REDIRECT_URL:
        process.env.REACT_APP_REDIRECT_URL ||
        'https://st-eng-admin-beatmaker-tv.herokuapp.com/',
    WIDGET_BASE_URL:
        process.env.REACT_APP_WIDGET_BASE_URL ||
        'https://st-btv-widget-api.herokuapp.com/api/',
}

export const AppSettings = new AppSettingsClass(env, api, urls)
