import React, { FC, useEffect } from 'react'
import classes from './notification.module.scss'
import { CustomSelect } from '../../../../common/elements/CustomSelect'
import { useTranslation } from 'react-i18next'
import { LanguageType } from '../../../../../translations/ru/common'
import {
    SendMessageAfterEnum,
    SubscriptionNotifyType,
} from '../../../../../DAL/NotificationsAPI'

type PropsType = {
    title: string
    subscription: SubscriptionNotifyType
    register: any
    control: any
    setValue: any
}

export const Notification: FC<PropsType> = ({
    title,
    subscription,
    register,
    control,
    setValue,
}) => {
    const { i18n } = useTranslation()
    const { notifications } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType
    const options = (
        Object.keys(notifications.notifyTitle) as Array<
            keyof typeof SendMessageAfterEnum
        >
    ).map((key) => {
        return {
            value: key,
            //@ts-ignore
            label: notifications.notifyTitle[key],
        }
    })
    useEffect(() => {
        if (subscription) {
            setValue(subscription.subsriptionType.toString(), {
                value: subscription.sendMessageAfter,
                label: notifications.notifyTitle[subscription.sendMessageAfter],
            })
        }
    }, [subscription])
    return (
        <div className={classes.notification}>
            {/*<h2 className={classes.title}>{title}</h2>*/}
            <label className={classes.description}>{title}</label>
            <CustomSelect
                name={subscription.subsriptionType.toString()}
                options={options}
                register={register}
                control={control}
                setValue={setValue}
            />
        </div>
    )
}
