import { AppState } from 'BLL/Store'

export const selectPagesCount = (state: AppState) =>
    state.freeBeatsPage.pagesCount
export const selectTotalCount = (state: AppState) =>
    state.freeBeatsPage.totalCount
export const selectFreeBeats = (state: AppState) => state.freeBeatsPage.items
export const selectCurrentBeat = (state: AppState) =>
    state.freeBeatsPage.currentBeat
export const selectFreeBeatById = (id: number) => (state: AppState) =>
    state.freeBeatsPage.items.find((i) => i.id === id)
