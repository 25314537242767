import React from 'react'

type Props = {
    color?: string
    width?: number
    height?: number
    className?: string
    isActive?: boolean
}

const SvgComponent = ({
    color = 'white',
    width = 30,
    height = 30,
    className,
}: Props) => {
    return (
        <div className={className}>
            <svg
                width={width}
                height={height}
                viewBox="0 0 19 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g transform="scale(-1, 1) translate(-19, 0)">
                    <path
                        d="M1.8002 30.4L16.2002 16L1.8002 1.60003"
                        stroke={color}
                        strokeWidth="3"
                    />
                </g>
            </svg>
        </div>
    )
}

export default SvgComponent
