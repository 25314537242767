import React, { useEffect } from 'react'
import { Button } from 'UI/common/elements/buttons/Button'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'
import { useForm } from 'react-hook-form'
import {
    getBeatmakerInfo,
    updateBeatmakerProfile,
} from 'BLL/BeatmakerSettingsReducer'
import { useDispatch } from 'react-redux'
import { useSelector } from 'UI/common/hooks/redux-hooks'
import { UpdateBeatmakerInfoDTO } from 'DAL/BeatmakerAPI'
import { AppSettings } from 'settings/appSettings'
import { SocialMedia } from 'UI/CustomerAdmin/BeatmakerSettingsNew/profile-settings/social-form/SocialMedia'
import { MainInfo } from 'UI/CustomerAdmin/BeatmakerSettingsNew/profile-settings/main-info-form/MainInfo'
import { AdditionalInfo } from 'UI/CustomerAdmin/BeatmakerSettingsNew/profile-settings/additional-info-form/AdditionalInfo'
import { selectBeatmakerInfo } from 'BLL/selectors/beatmaker-setting-selectors'
import { BeatmakerFormValues } from 'UI/CustomerAdmin/BeatmakerSettingsNew/wallet-settings/wallets/enot/types'
import s from './settings-profile.module.scss'

export const SettingsProfile = () => {
    const beatmakerInfo = useSelector(selectBeatmakerInfo)
    const dispatch = useDispatch()

    const isEng = AppSettings.api.isEngVersion()

    const { i18n } = useTranslation()
    const { buttons, beatmakerSettings } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    const {
        setValue,
        formState: { errors },
        handleSubmit,
        control,
    } = useForm<BeatmakerFormValues>()

    useEffect(() => {
        dispatch(getBeatmakerInfo())
    }, [])

    useEffect(() => {
        if (beatmakerInfo) {
            const beatInfoArray = Object.keys(beatmakerInfo) as Array<
                keyof typeof beatmakerInfo
            >
            beatInfoArray.map((field) => {
                if (field === 'country') {
                    setValue(field, {
                        value: beatmakerInfo[field].id,
                        label: isEng
                            ? beatmakerInfo[field].englishName
                            : beatmakerInfo[field].name,
                    })
                } else {
                    setValue(field, beatmakerInfo[field])
                }
            })
        }
    }, [beatmakerInfo])

    const onSubmit = handleSubmit((values: BeatmakerFormValues) => {
        const beatmakerInfoDto: UpdateBeatmakerInfoDTO = {
            contacts: values.contacts,
            aboutMe: values.aboutMe,
            rappersList: values.rappersList,
            countryId: values.country.value,
            nickname: values.name,
            software: values.software,
            uniqueUrlName: values.uniqueUrlName,
        }
        dispatch(updateBeatmakerProfile(beatmakerInfoDto))
    })

    return (
        <form onSubmit={onSubmit} className={s.wrapper}>
            <MainInfo
                beatmakerSettings={beatmakerSettings}
                control={control}
                setValue={setValue}
            />
            <SocialMedia
                error={errors}
                beatmakerSettings={beatmakerSettings}
                isEng={isEng}
                control={control}
            />
            <AdditionalInfo
                control={control}
                beatmakerSettings={beatmakerSettings}
            />
            <div className={s.btnsBox}>
                <Button title={buttons.SAVE_CHANGES} />
            </div>
        </form>
    )
}
