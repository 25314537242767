import { axiosCore } from './createAxios'
import { BaseResponse } from '../BLL/types'

export const ProducerAPI = {
    prefixURL: 'ProdsPage/Management',

    changePageSettings(defaultBeatsSorting: SortByEnum) {
        return axiosCore
            .post<BaseResponse>(`${this.prefixURL}/UpdatePageSettings`, {
                defaultBeatsSorting,
            })
            .then((r) => r.data)
    },

    updateContacts(contacts: ContactTypeDTO) {
        return axiosCore
            .post<BaseResponse>(`${this.prefixURL}/Contacts`, contacts)
            .then((r) => r.data)
    },

    getSettingsDetails() {
        return axiosCore
            .get<ProducerSettingsType>(`${this.prefixURL}/Details`)
            .then((r) => r.data)
    },

    updateSettings(dto: ProdPageSettingsSTO) {
        return axiosCore
            .put<BaseResponse<{ cname: string | null }>>(
                `${this.prefixURL}/UpdateSettings`,
                dto
            )
            .then((r) => r.data)
    },
}

export enum SortByEnum {
    Date = 0,
    OrderNumber = 1, // beatmaker ordered his beats by himself with d’n’d// topper beat has order 1
    ChartTop = 2,
}

export type ContactTypeDTO = {
    ru: ContactType[]
    eng: ContactType[]
}
export type ContactType = {
    type: string
    link: string
}

export type ProdPageSettingsSTO = {
    //[RegularExpression("^[a-zA-Z0-9-]{3,20}$", ErrorMessage = "Разрешены только буквы A-Z, цифры, а так же дефис")]
    subdomain: string
    //[RegularExpression(@"^[a-zA-Z0-9-]{3,20}(\.[a-zA-Z0-9-]{2,10}){1,}$", ErrorMessage = "Название домена не валидно")]
    domain: string
}

export type ProducerSettingsType = {
    profile: ProducerProfile
    settings: ProducerSettings
}

export type ProducerProfile = {
    beatmakerId: number
    colorsTheme: null
    contacts: null
    defaultBeatsSorting: number
    footerTextEng: null
    footerTextRu: null
    id: string
    sloganTextEng: null
    sloganTextRu: null
}

export type ProducerSettings = {
    addedDate: Date
    beatmaker: null
    beatmakerId: number
    domain: string
    domainUpdateDate: Date
    herokuCname: string | null
    id: string
    imagesVersion: number
    subdomain: string
    subdomainUpdateDate: Date
}
