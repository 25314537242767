import { FileWithSelectedLicenses } from 'DAL/BeatSettingsAPI'
import { LanguageType } from 'translations/ru/common'
import React from 'react'
import { i18n } from 'i18next'
import { TableType } from 'UI/common/table/types'

export const getTrackFilesTableData = (
    i18n: i18n
): TableType<FileWithSelectedLicenses> => {
    const { beatEdit } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType
    return [
        {
            title: beatEdit.filesTableColumn.fileName,
            field: 'name',
            render: (file) => <div>{file.name + '.' + file.extension}</div>,
        },
        {
            title: beatEdit.filesTableColumn.createdDate,
            field: 'id',
            render: (file) => <div>-</div>,
        },
        {
            title: beatEdit.filesTableColumn.fileSize,
            field: 'id',
            render: (file) => <div>-</div>,
        },
    ]
}
