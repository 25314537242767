import { BeatLicenseType } from 'DAL/Services'
import { Dispatch, SetStateAction } from 'react'

type Props = {
    isDollar?: boolean
    setBeatLicenseNotDefaultPrice: Dispatch<SetStateAction<BeatLicenseType[]>>
    beatLicenseNotDefaultPrice: BeatLicenseType[]
    licId: string
    price: number
}
export const ChangeBindLicensePriceHandle = ({
    isDollar,
    setBeatLicenseNotDefaultPrice,
    beatLicenseNotDefaultPrice,
    licId,
    price,
}: Props) => {
    if (isDollar) {
        setBeatLicenseNotDefaultPrice(
            beatLicenseNotDefaultPrice.map((lic) =>
                lic.id === licId
                    ? {
                          ...lic,
                          priceDollar: price,
                      }
                    : lic
            )
        )
    } else {
        setBeatLicenseNotDefaultPrice(
            beatLicenseNotDefaultPrice.map((lic) =>
                lic.id === licId
                    ? {
                          ...lic,
                          price,
                      }
                    : lic
            )
        )
    }
}
