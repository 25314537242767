import React from 'react'
import { RequestStatuses } from 'BLL/AppReducer'
import { ColorLinearProgress } from 'UI/common/progressBars/ColorLinearProgress'
import { useSelector } from '../hooks/redux-hooks'
import { selectRequestStatus } from 'BLL/selectors/app-selectors'
import s from './loader.module.scss'

export const Loader = () => {
    const requestStatus = useSelector(selectRequestStatus)
    return (
        <div className={s.loader}>
            {requestStatus === RequestStatuses.InProgress && (
                <ColorLinearProgress />
            )}
        </div>
    )
}
