import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'
import { Notifications } from 'UI/CustomerAdmin/BeatmakerSettingsNew/notifications/Notifications'
import { WalletSettings } from 'UI/CustomerAdmin/BeatmakerSettingsNew/wallet-settings/WalletSettings'
import { SettingsProfile } from 'UI/CustomerAdmin/BeatmakerSettingsNew/profile-settings/SettingsProfile'
import { ModalValue } from 'UI/CustomerAdmin/BeatmakerSettingsNew/beatmaker-settings/types'
import { TabPanel } from 'UI/common/elements/tabPanel/TabPanel'
import { CustomTabs } from 'UI/common/elements/tabPanel/CustomTabs'
import s from 'UI/CustomerAdmin/BeatmakerSettingsNew/beatmaker-settings/beatmakerSettings.module.scss'

export const modalValues = {
    profile: 0,
    notifications: 1,
    wallets: 2,
} as const

export const BeatmakerSettings = () => {
    const history = useHistory()
    const { tab } = useParams<{ tab: string }>()

    const { i18n } = useTranslation()
    const { navigation, settings } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    const [value, setValue] = useState<ModalValue>(modalValues.profile)

    const settingsTabsLabel = [
        settings.tabs.profile,
        settings.tabs.notifications,
        settings.tabs.wallets,
    ]

    useEffect(() => {
        tab === 'profile' && setValue(modalValues.profile)
        tab === 'notifications' && setValue(modalValues.notifications)
        tab === 'wallets' && setValue(modalValues.wallets)
    }, [tab])

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        newValue === modalValues.profile && history.push(`/settings/profile`)
        newValue === modalValues.notifications &&
            history.push(`/settings/notifications`)
        newValue === modalValues.wallets && history.push(`/settings/wallets`)
    }

    return (
        <div>
            <div className={s.topWrapper}>
                <div className={s.pageTitle}>{navigation.settings}</div>
            </div>
            <CustomTabs
                value={value}
                tabs={Object.keys(modalValues)}
                labels={settingsTabsLabel}
                onChange={handleChange}
            />
            <TabPanel value={tab} index={'profile'}>
                <SettingsProfile />
            </TabPanel>
            <TabPanel value={tab} index={'notifications'}>
                <Notifications />
            </TabPanel>
            <TabPanel value={tab} index={'wallets'}>
                <WalletSettings />
            </TabPanel>
        </div>
    )
}
