import React, { FC, useState } from 'react'
import classes from './MySalesTrackInfo.module.scss'
import { Button } from '../../../../common/elements/buttons/Button'
import { PaymentTypeBLL } from '../../../../../BLL/MySalesReducer'
import DropDownIcon from '../../../../assets/icons/drop-down.svg'
import DropUpIcon from '../../../../assets/icons/drop-up.svg'
import { getLocaleDateStringForHours } from '../../../../utils/util-types'
import { LanguageType } from '../../../../../translations/ru/common'
import { AppSettings } from 'settings/appSettings'
import { useTranslation } from 'react-i18next'
import { CurrencyEnum } from '../../../../../DAL/CashbackAPI'
import UsdIcon from '../../../../assets/icons/usd.svg'
import RubIcon from '../../../../assets/icons/rub.svg'
import { CustomerRM } from '../../../../routers/RouterManager'
import { NavLink } from 'react-router-dom'

type PropsType = {
    payment: PaymentTypeBLL
}
export const MySalesTrackInfo: FC<PropsType> = ({ payment }) => {
    const isEng = AppSettings.api.isEngVersion()
    const { i18n } = useTranslation()
    const { mySales, buttons } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType
    return (
        <div className={classes.MySalesTrackInfo}>
            <div className={classes.item}>
                <span className={classes.title}>
                    {mySales.rootPayment.fields.beatName}:
                </span>
                <span className={classes.trackName}>
                    <BeatTitle payment={payment} />
                </span>
            </div>
            <div className={classes.item}>
                <span className={classes.title}>
                    {mySales.rootPayment.fields.id}:
                </span>
                <span className={classes.value}>{payment.id}</span>
            </div>
            <div className={classes.item}>
                <span className={classes.title}>
                    {mySales.rootPayment.fields.dateTime}
                </span>
                <span className={classes.value}>
                    {getLocaleDateStringForHours(
                        i18n.language,
                        payment.confirmDate
                    )}
                </span>
            </div>
            <div className={classes.item}>
                <span className={classes.title}>
                    {mySales.rootPayment.fields.total}
                </span>
                <span className={classes.value}>
                    <img
                        src={
                            payment.currencyName === CurrencyEnum.usd
                                ? UsdIcon
                                : RubIcon
                        }
                        alt={'currency'}
                    />{' '}
                    {payment.money}
                </span>
            </div>
            <div className={classes.item}>
                <span className={classes.title}>
                    {mySales.rootPayment.fields.withdrawalDate}
                </span>
                {payment.sales[0].paidDate ? (
                    <span className={classes.value}>
                        {getLocaleDateStringForHours(
                            i18n.language,
                            payment.sales[0].paidDate
                        )}
                    </span>
                ) : (
                    '-'
                )}
            </div>
            <div className={classes.boxBtnDetails}>
                <NavLink
                    to={CustomerRM.mySales.getUrl.show(payment.id)}
                    style={{ textDecoration: 'none' }}
                >
                    <Button view={'Details'} title={buttons.DETAILS} />
                </NavLink>
            </div>
        </div>
    )
}

export const BeatTitle: FC<PropsType> = ({ payment }) => {
    const [isCollapsed, setIsCollapsed] = useState(false)
    return (
        <div className={classes.BeatNameWrapper}>
            {payment.sales.length > 1 && !isCollapsed ? (
                <div className={classes.beatName}>
                    <img
                        src={DropDownIcon}
                        alt={'drop-down'}
                        onClick={() => setIsCollapsed(true)}
                    />
                    {payment.sales[0].beatTitle}
                </div>
            ) : (
                !isCollapsed && (
                    <div className={classes.beatName}>
                        {payment.sales[0].beatTitle}
                    </div>
                )
            )}
            {isCollapsed && (
                <div className={classes.beatNameCollapsed}>
                    <img
                        src={DropUpIcon}
                        alt={'drop-up'}
                        onClick={() => setIsCollapsed(false)}
                    />
                    <div>
                        {payment.sales.map((sale) => (
                            <div className={classes.beatName} key={sale.id}>
                                {sale.beatTitle}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}
