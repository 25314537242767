import React, { useState } from 'react'
import { CardItem } from './CardItem'
import { CardList } from 'DAL/walletsAPI'
import Tooltip from '@material-ui/core/Tooltip'
import { NavLink, useHistory } from 'react-router-dom'
import { CustomerRM } from 'UI/routers/RouterManager'
import { LanguageType } from 'translations/ru/common'
import i18n from 'i18next'
import { useDispatch } from 'react-redux'
import { CardsRoute } from '../routes/cardsRoute'
import { removeCard } from 'BLL/CustomerWalletsReducer'
import DeleteIcon from 'UI/assets/icons/delete.svg'
import s from './cardsList.module.scss'

type Props = {
    cardList: CardList[]
    onSelectCard: (cardId: string) => void
}

export const CardsList = ({ cardList, onSelectCard }: Props) => {
    const dispatch = useDispatch()
    const [_, setModalIsActive] = useState(false)

    const history = useHistory()

    const { buttons } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    const removeCardHandler = (cardId: string) => {
        if (cardId) {
            dispatch(removeCard(cardId))
        }
        history.push(CustomerRM.settings.path.wallets.base)
    }

    return (
        <div className={s.listWrapper}>
            {cardList &&
                cardList.map((el) => (
                    <div className={s.cardItemWrapper} key={el.CardId}>
                        <CardItem
                            value={el.CardId}
                            onSelectCard={onSelectCard}
                            isCurrent={el.IsCurrent}
                        >
                            {el.Pan}
                        </CardItem>
                        <div className={s.tooltip}>
                            <Tooltip title={buttons.DELETE}>
                                <NavLink
                                    to={CustomerRM.settings.path.wallets.delete(
                                        el.CardId
                                    )}
                                >
                                    <img
                                        className={s.icons}
                                        src={DeleteIcon}
                                        alt={'delete'}
                                    />
                                </NavLink>
                            </Tooltip>
                        </div>
                        <CardsRoute
                            cardId={el.CardId}
                            removeCardHandle={removeCardHandler}
                            setModalIsActive={setModalIsActive}
                        />
                    </div>
                ))}
        </div>
    )
}
