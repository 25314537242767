import React, { FC, useState, useEffect, useRef } from 'react'
import classes from './MobileBtnTooltip.module.scss'
import icon from '../../../../assets/icons/btnTooltip.svg'

type PropsType = {}

export const MobButtonTooltip: FC<PropsType> = ({ children }) => {
    const [show, setShow] = useState(false)
    const toolTipRef = useRef(null)

    const handleClickOutside = (event: any) => {
        //@ts-ignore
        if (toolTipRef && toolTipRef.current) {
            // @ts-ignore
            !toolTipRef.current.contains(event.target)! && setShow(false)
        }
    }
    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true)
        return () => {
            document.removeEventListener('click', handleClickOutside, true)
        }
    })
    return (
        <div className={classes.buttonBox} ref={toolTipRef}>
            <button
                className={classes.buttonTooltip}
                onClick={() => setShow(!show)}
            >
                <img src={icon} alt="Info" />
            </button>
            {show && (
                <div className={classes.tooltip}>
                    <div className={classes.triangle} />
                    <div className={classes.description}>
                        <p className={classes.text}>{children}</p>
                    </div>
                </div>
            )}
        </div>
    )
}
