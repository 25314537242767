import { FormType } from './form-utility'
import { LanguageType } from '../../translations/ru/common'
import { MaterialInput } from 'UI/common/elements/materialInput'
import { UpdateExpertModel } from '../../DAL/CompilationAPI'

export const getExpertFormData = ({
    i18n,
}: any): FormType<UpdateExpertModel> => {
    const { adminExpertsCompilation } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType
    return [
        {
            name: 'beatmakerId',
            label: adminExpertsCompilation.tableColumns.beatmakerId,
            component: MaterialInput,
            props: {
                required: true,
                errorMessage: adminExpertsCompilation.fieldIsRequired,
            },
        },
        {
            name: 'description',
            label: adminExpertsCompilation.description,
            component: MaterialInput,
            options: {
                multiline: true,
                rows: 6,
                disabled: false,
            },
            props: {
                required: true,
                errorMessage: adminExpertsCompilation.fieldIsRequired,
            },
        },
    ]
}
