import { apiCoreAxios, axiosCore } from './createAxios'
import { BaseResponse, DownloadResponse } from 'BLL/types'
import { UpdateFreeBeat } from 'UI/CustomerAdmin/FreeBeatsPage/editFreeBeat/types'

export const BeatSettingsAPI = {
    prefix: '/BeatsManagement/BeatsManagement',

    getBeats: async (
        pageSize: number,
        page: number,
        term = '',
        isArchive: boolean
    ) => {
        return apiCoreAxios
            .get<PaginationResponseType<MyBeatType>>(
                `api/beats/my/full?pageSize=${pageSize}&page=${page}&term=${term}&archive=${isArchive}`
            )
            .then((res) => res.data)
    },

    getBeatsArchive: async (pageSize: number, page: number, term = '') => {
        return apiCoreAxios
            .get<PaginationResponseType<MyBeatType>>(
                `api/beats/my/full?pageSize=${pageSize}&page=${page}&term=${term}&archive=true`
            )
            .then((res) => res.data)
    },

    async restoreBeatFromArchive(beatId: number) {
        return axiosCore
            .post<BaseResponse<void>>(
                `${this.prefix}/RestoreBeatFromArchive?id=${beatId}`
            )
            .then((res) => res.data)
    },

    async getBeatDetails(beatId: number) {
        return axiosCore
            .get<BeatDetailsType>(`${this.prefix}/BeatDetails?id=${beatId}`)
            .then((res) => res.data)
    },

    async updateBeat(updateBeatContract: UpdateBeatContract) {
        return axiosCore
            .put<BaseResponse<UpdateBeatContract>>(
                `${this.prefix}/UpdateBeat`,
                updateBeatContract
            )
            .then((res) => res.data)
    },

    async getTags(term: string) {
        return axiosCore
            .get<string[]>(`${this.prefix}/GetTags?term=${term}`)
            .then((res) => Object.values(res.data).map((v) => v))
    },

    async uploadBeat(
        formData: any,
        onUploadProgress: (progressEvent: any) => void,
        cancelToken: any
    ) {
        return axiosCore
            .post<BaseResponse<UpdateBeatContract>>(
                `${this.prefix}/UploadBeat`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    onUploadProgress: onUploadProgress,
                    cancelToken,
                }
            )
            .then((res) => res.data)
    },

    async uploadNewBeatFile(
        formData: any,
        onUploadProgress: (progressEvent: any) => void,
        beatId: number,
        cancelToken: any
    ) {
        return axiosCore
            .post<BaseResponse<UpdateBeatContract>>(
                `${this.prefix}/UploadNewFile?beatId=${beatId}`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    onUploadProgress: onUploadProgress,
                    cancelToken,
                }
            )
            .then((res) => convertDtoToLowerCase(res.data))
    },

    async uploadBeatFileForSale(
        beatId: number,
        formData: any,
        onUploadProgress: (progressEvent: any) => void,
        cancelToken: any
    ) {
        return axiosCore
            .post<BaseResponse>(
                `${this.prefix}/UploadBeatFileForSale?beatId=${beatId}`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    onUploadProgress: onUploadProgress,
                    cancelToken,
                }
            )
            .then((res) => res.data)
    },

    async uploadBeatImage(beatId: number, formData: any) {
        return axiosCore
            .post<BaseResponse>(
                `${this.prefix}/UploadBeatImage?beatId=${beatId}`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            )
            .then((res) => convertDtoToLowerCase(res.data))
    },

    async removeBeatFileForSale(fileId: number) {
        return axiosCore
            .delete<BaseResponse>(
                `${this.prefix}/RemoveBeatFileForSale?id=${fileId}`
            )
            .then((res) => res.data)
    },

    async bindFileForLicense(fileId: number, licenseId: string) {
        return axiosCore
            .post<BaseResponse>(
                `${this.prefix}/BindFile?fileId=${fileId}&licenseId=${licenseId}`
            )
            .then((res) => convertDtoToLowerCase(res.data))
    },

    async unbindFileForLicense(fileId: number, licenseId: string) {
        return axiosCore
            .delete<BaseResponse>(
                `${this.prefix}/UnbindFile?fileId=${fileId}&licenseId=${licenseId}`
            )
            .then((res) => convertDtoToLowerCase(res.data))
    },

    async addFileByLink(addFileByLinkDto: SetLinkForBeatContract) {
        return axiosCore
            .post<BaseResponse>(
                `${this.prefix}/SetLinkForBeat`,
                addFileByLinkDto
            )
            .then((res) => convertDtoToLowerCase(res.data))
    },

    async getFilesBindingsToLicenses(beatId: number) {
        return axiosCore
            .get<FilesBindedToLicensesType>(
                `${this.prefix}/GetBeatFilesBinding?id=${beatId}`
            )
            .then((res) => convertDtoToLowerCase(res.data))
    },

    async beatReorder(beatId: number, putAfterId?: number) {
        const url = putAfterId
            ? `/api/beats/my/${beatId}/reorder/${putAfterId}`
            : `/api/beats/my/${beatId}/reorder`
        return apiCoreAxios
            .put<FilesBindedToLicensesType>(url)
            .then((res) => res.data)
    },

    async addToCatalogNow(beatId: number) {
        return axiosCore
            .post<BaseResponse>(`${this.prefix}/AddToCatalog?id=${beatId}`)
            .then((res) => res.data)
    },

    async deleteBeat(beatId: number) {
        return axiosCore
            .delete<BaseResponse>(`${this.prefix}/DeleteBeat?id=${beatId}`)
            .then((res) => res.data)
    },

    async removeFromCatalog(beatId: number) {
        return axiosCore
            .delete<BaseResponse>(
                `${this.prefix}/RemoveFromCatalog?id=${beatId}`
            )
            .then((res) => res.data)
    },

    async downloadFileById(fileId: number) {
        return axiosCore.post(
            `/beatsManagement/beatsSales/downloadBeat`,
            {
                fileId,
            },
            {
                responseType: 'blob',
                headers: { Accept: 'application/octet-stream' },
            }
        )
    },
    async checkFile(beatId: number, fileId: number) {
        return axiosCore.post<DownloadResponse>(
            `/beatsManagement/beatsSales/checkFile`,
            {
                beatId,
                fileId,
            }
        )
    },
    async getFreeBeats(
        pageSize: number,
        page: number,
        term = '',
        isArchive: boolean
    ) {
        return axiosCore
            .get<PaginationResponseType<FreeBeatType>>(
                `${this.prefix}/GetPaginatedFullFreeBeats?pageSize=${pageSize}&page=${page}&term=${term}&archive=${isArchive}`
            )
            .then((res) => res.data)
    },

    async uploadFreeBeat(
        formData: any,
        onUploadProgress: (progressEvent: any) => void,
        cancelToken: any
    ): Promise<BaseResponse> {
        return axiosCore
            .post<BaseResponse>(`${this.prefix}/UploadFreeBeatApi`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: onUploadProgress,
                cancelToken,
            })
            .then((res) => res.data)
    },

    async deleteFreeBeat(beatId: number): Promise<BaseResponse> {
        return axiosCore
            .post<BaseResponse>(`${this.prefix}/DeleteFreeBeatApi?id=${beatId}`)
            .then((res) => res.data)
    },

    async updateFreeBeat(update: UpdateFreeBeat): Promise<BaseResponse> {
        return axiosCore
            .post<BaseResponse>(`${this.prefix}/UpdateFreeBeatApi`, update)
            .then((res) => res.data)
    },
}

export type FreeBeatType = {
    id: number
    title: string
    beatmakerId: number
    tempo: number
    fileName: string
    downloads: number
    plays: number
    addedDate: string
    description: string
    styleId: number
    moodId: number
    sampling: boolean
    composing: boolean
    isDeleted: boolean
    playUrl: string
    format: string
    images: ImagesBeatType
}

export type MyBeatType = {
    addedToCatalogDate: null | Date | string
    beatmakerId: number
    chartPosition: number
    downloads: number
    id: number
    format: string
    images: ImagesBeatType
    isDeleted: boolean
    isOpen: boolean
    isSold: boolean
    orderNumber: number
    playUrl: null | string
    plays: number
    title: string
}

export type ImagesBeatType = {
    middle: string
    original: string
    thumbnail: string
}

export type PaginationResponseType<T> = {
    page: number
    pageSize: number
    pagesCount: number
    totalCount: number
    items: T[]
}

export type UpdateBeatContract = {
    id: number
    name: string
    description: string
    demoDownloading: boolean
    tempo: number
    moodId: number | null
    styleId: number | null
    licenses: LicenseContract[]
    composing: boolean
    sampling: boolean
    tagName: null | string[]
}

export type LicenseContract = {
    id: string
    customPrices: boolean
    priceInRubles?: number | null
    priceInDollars?: number | null
}

export type FilesBindedToLicensesType = {
    licenses: AvailableLicense[]
    files: FileWithSelectedLicenses[]
    beatId: number
}

export type AvailableLicense = {
    title: string
    id: string
}

export type FileWithSelectedLicenses = {
    extension: string
    id: number
    name: string
    selectedLicenses: string[]
}

export type SetLinkForBeatContract = {
    link: string
    beatId: number
}

export type FullMyBeatType = {
    addedDate: Date
    addedDateString: string
    addedToCatalogDate: null | Date
    allowAttachTracks: boolean
    beatmakerId: number
    beatmakerName: string
    chartPosition: number
    composing: boolean
    demoDownloading: boolean
    description: string
    dislikesCount: number
    downloads: number
    encodedName: string
    exclusiveFileTitle: null | string
    fileServers: string
    hostingDays: number
    id: number
    imageVersion: number
    isDeleted: boolean
    isNew: boolean
    isOpen: boolean
    isPaid: boolean
    isPremium: boolean
    isSold: boolean
    leasingFileTitle: null | string
    leasingPrice: null | number
    likesCount: number
    minPrice: number
    minPriceInDollars: number
    moodId: number
    moodName: null | string
    name: string
    normUrl: string
    oldPrice: number
    plays: number
    premiumFileTitle: null | string
    premiumPrice: null | number
    price: number
    sampling: boolean
    styleId: number
    styleName: null | string
    tags: null | string[]
    tempo: number
    urlForDownload: string
    vkChartPostInfo: null | string
    vkChartRepostsCout: number
}

export type BeatDetailsType = {
    beat: FullMyBeatType
    contextBeat: ContextBeatType
    bindings: BindingsLicenseType[]
    tags: string[]
}

export type BindingsLicenseType = {
    addedDate: string
    beat: MyBeatType
    beatId: number
    beatLicense: null | string
    beatLicenseId: string
    priceInDollars: number
    priceInRubles: number
}

export type ContextBeatType = {
    beatmaker: null | string
    beatmakerId: number
    demoDownloading: boolean
    fileName: string
    id: number
    m3U8Status: number
    minPrice: number
    minPriceInDollars: number
    name: string
    playUrl: string
}

function convertDtoToLowerCase(dto: any) {
    return recursive(dto)

    function recursive(dto: any) {
        let result = {} as any
        const keys = Object.keys(dto)
        for (let key of keys) {
            if (Array.isArray(dto[key])) {
                if (dto[key].length && typeof dto[key][0] === 'string') {
                    const newKey = key[0].toLowerCase() + key.slice(1)
                    result[newKey] = dto[key]
                } else {
                    const newKey = key[0].toLowerCase() + key.slice(1)
                    result[newKey] = dto[key].map((el: any) => recursive(el))
                }
            } else if (typeof dto[key] === 'object') {
                const newKey = key[0].toLowerCase() + key.slice(1)
                result[newKey] = recursive(dto[key])
            } else {
                const newKey = key[0].toLowerCase() + key.slice(1)
                result[newKey] = dto[key]
            }
        }
        return result
    }
}
