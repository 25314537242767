import React from 'react'
import { LanguageType } from 'translations/ru/common'
import { BeatmakerFormValues } from 'UI/CustomerAdmin/BeatmakerSettingsNew/wallet-settings/wallets/enot/types'
import { ControlledTextArea } from 'UI/common/controls/controlledTextArea/ControlledTextArea'
import { Control } from 'react-hook-form/dist/types/form'
import s from 'UI/CustomerAdmin/BeatmakerSettingsNew/profile-settings/settings-profile.module.scss'

type Props = {
    control: Control<BeatmakerFormValues>
    beatmakerSettings: Pick<
        LanguageType,
        'beatmakerSettings'
    >['beatmakerSettings']
}

export const AdditionalInfo = ({ beatmakerSettings, control }: Props) => {
    return (
        <div className={s.secondary}>
            <h2 className={s.title}>{beatmakerSettings.fields.secInfo}</h2>
            <div className={s.secondaryOptions}>
                <div className={s.secondaryItem}>
                    <ControlledTextArea
                        label={beatmakerSettings.fields.software}
                        control={control}
                        name={'software'}
                    />
                </div>
                <div className={s.secondaryItem}>
                    <ControlledTextArea
                        label={beatmakerSettings.fields.clients}
                        control={control}
                        name={'rappersList'}
                    />
                </div>
                <div className={s.secondaryItem}>
                    <ControlledTextArea
                        label={beatmakerSettings.fields.description}
                        control={control}
                        name={'aboutMe'}
                    />
                </div>
            </div>
        </div>
    )
}
