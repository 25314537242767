import { contractsAxios } from './createAxios'
import {
    BeatmakerContractsType,
    ContractsLangEnum,
    BaseResponse,
} from '../BLL/types'
import { LanguageEnum } from '../BLL/enums'

const contractsAPI = {
    getContracts() {
        return contractsAxios
            .get<BaseResponse<BeatmakerContractsType[]>>(`/contracts`)
            .then((res) => res.data)
    },

    addContracts(lang: LanguageEnum, licenseId: string) {
        return contractsAxios
            .post<BaseResponse<BeatmakerContractsType[]>>(`/contracts`, {
                lang,
                licenseId,
            })
            .then((res) => res.data)
    },

    getContractById(licenseId: string, lang: string) {
        return contractsAxios
            .get<BaseResponse<BeatmakerContractsType>>(
                `/contracts/edit/${licenseId}/${lang}`
            )
            .then((res) => res.data)
    },

    updateContract(contract: BeatmakerContractsType) {
        return contractsAxios
            .put<BaseResponse>(`/contracts`, { contract })
            .then((res) => res.data)
    },

    getSettingsContract() {
        return contractsAxios
            .get<BaseResponse<ContractLicenseType>>(`/contracts/settings`)
            .then((res) => res.data)
    },

    getBaseContract(lang: LanguageEnum, licenseId: string) {
        return contractsAxios
            .post<BeatmakerContractsType>(`/contracts/base-template/${lang}`, {
                licenseId,
            })
            .then((res) => res.data)
    },

    getExampleContract(html: string, lang: string) {
        return contractsAxios
            .post<any>(
                `public/contracts/example/pdf/${lang}`,
                { html },
                { responseType: 'arraybuffer' }
            )
            .then((res) => res.data)
    },
}

export default contractsAPI

export type ContractLicenseType = {
    CONTRACT_DATE: ContractLicenseEnum
    CLIENT_NAME: ContractLicenseEnum
    BEAT_TITLE: ContractLicenseEnum
    BEAT_PRICE: ContractLicenseEnum
    PASSPORT_CLIENT_DATA?: ContractLicenseEnum
    ADDRESS_CLIENT_DATA?: ContractLicenseEnum
    LICENSE_NAME: ContractLicenseEnum
    CURRENCY: ContractLicenseEnum
}

export enum ContractLicenseEnum {
    CONTRACT_DATE = 'CONTRACT_DATE',
    CLIENT_NAME = 'CLIENT_NAME',
    BEAT_TITLE = 'BEAT_TITLE',
    BEAT_PRICE = 'BEAT_PRICE',
    PASSPORT_CLIENT_DATA = 'PASSPORT_CLIENT_DATA',
    ADDRESS_CLIENT_DATA = 'ADDRESS_CLIENT_DATA',
    LICENSE_NAME = 'LICENSE_NAME',
    CURRENCY = 'CURRENCY',
}
