import React, { useState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import MenuIcon from '@material-ui/icons/Menu'
import { Drawer, List } from '@mui/material'
import { Menu } from 'UI/common/menus/Menu'
import s from 'UI/CustomerAdmin/Mobile/MobileCommon/MobileMenu/mobileMenu.module.scss'

export const MobileMenuDrawer = () => {
    const [open, setOpen] = useState(false)

    const toggleDrawer = () => {
        setOpen(!open)
    }

    const sideList = () => (
        <List onClick={toggleDrawer}>
            <Menu />
        </List>
    )

    return (
        <>
            <IconButton
                className={s.menuButton}
                color="default"
                aria-label="Open drawer"
                onClick={toggleDrawer}
            >
                {open ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
            <Drawer open={open} onClose={toggleDrawer}>
                {sideList()}
            </Drawer>
        </>
    )
}
