import React, { FC, useEffect, useState } from 'react'
import classes from './inputs.module.scss'
import { useDispatch } from 'react-redux'
import { getTags, myBeatsActions } from 'BLL/CustomerMyBeatsReducer'
import { useSelector } from '../../hooks/redux-hooks'
import CloseIcon from '../../../assets/icons/close-white.svg'
import { Button } from '../buttons/Button'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'

type TagPropsType = {
    tag: string
    handleDeleteTag: (tag: string) => void
}
const Tag: FC<TagPropsType> = ({ tag, handleDeleteTag }) => {
    return (
        <div className={classes.tag}>
            #{tag}{' '}
            <img
                src={CloseIcon}
                alt={'delete'}
                onClick={() => handleDeleteTag(tag)}
            />
        </div>
    )
}

type PropsType = {
    tags: string[]
    setTags: (tags: string[]) => void
} & React.InputHTMLAttributes<HTMLInputElement>

export const TagInputField: FC<PropsType> = ({ tags, setTags, ...props }) => {
    const [tagValue, setTagValue] = useState('')
    const tagsData = useSelector((state) => state.myBeatsPage.tags)
    const [timerId, setTimerId] = useState<number>()
    const dispatch = useDispatch()

    const { i18n } = useTranslation()
    const { myBeats } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    useEffect(() => {
        if (tagValue) {
            const timer = setTimeout(() => {
                dispatch(getTags(tagValue))
            }, 300)
            setTimerId(Number(timer))
        } else {
            dispatch(myBeatsActions.setTags([]))
        }
        return () => {
            clearTimeout(timerId)
        }
    }, [tagValue, dispatch])

    const handleDeleteTag = (tag: string) => {
        setTags(tags.filter((t) => t !== tag))
    }
    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault()
            handleSetTags(tagValue)
            setTagValue('')
        }
    }

    const handleSetTags = (tagValue: string) => {
        if (tags.every((t) => t !== tagValue)) {
            setTags([...tags, tagValue])
        }
    }

    const handleAddTag = () => {
        if (tagValue) {
            handleSetTags(tagValue)
            setTagValue('')
        }
    }

    const addTagSelect = (tag: string) => {
        handleSetTags(tag)
        setTagValue('')
        dispatch(myBeatsActions.setTags([]))
    }
    return (
        <>
            <div className={classes.tagInputWrapper}>
                <input
                    className={`${classes.Input} ${classes.inputField} ${classes.tagInputField}`}
                    {...props}
                    value={tagValue}
                    disabled={tags.length >= 5}
                    onKeyPress={handleKeyPress}
                    onChange={(event) => setTagValue(event.target.value)}
                />
                <Button
                    view={'Primary'}
                    onClick={handleAddTag}
                    title={myBeats.beatSettings.addTag}
                    disabled={!tagValue}
                />
            </div>
            {tagsData.length ? (
                <div className={classes.tagsData}>
                    {tagsData
                        .filter((t) => tags.indexOf(t) === -1)
                        .map((t) => (
                            <div key={t} onClick={() => addTagSelect(t)}>
                                {t}
                            </div>
                        ))}
                </div>
            ) : null}
            <div className={classes.tagsWrapper}>
                {tags.map((tag) => (
                    <Tag
                        key={tag}
                        tag={tag}
                        handleDeleteTag={handleDeleteTag}
                    />
                ))}
            </div>
        </>
    )
}
