import React, { FC } from 'react'
import TextField from '@mui/material/TextField'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DatePicker from '@mui/lab/DatePicker'
import classes from 'UI/common/elements/datePicker/customDatepicker.module.scss'
import ruLocale from 'date-fns/locale/ru'
import enLocale from 'date-fns/locale/en-US'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import './material.scss'
import * as dateFns from 'date-fns'

const outerTheme = createTheme({
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: '#282828',
                    color: '#FFFFFF',
                },
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    color: '#FFFFFF',
                    fontWeight: 400,
                    fontSize: 18,
                },
            },
        },
    },
})

const localeMap = {
    en: enLocale,
    ru: ruLocale,
}
const maskMap = {
    en: '__/__/____',
    ru: '__-__-____',
}

type PropsType = {
    handleChange: (date: Date | null) => void
    date: Date
    disabled: boolean
    minDate?: Date
    locale: keyof typeof maskMap
}
export const CustomDatePicker: FC<PropsType> = ({
    handleChange,
    date,
    disabled,
    minDate,
    locale,
    ...props
}) => {
    const handleOnChange = (date: Date | null) => {
        if (date && dateFns.isValid(date)) {
            handleChange(date)
        }
    }
    return (
        <LocalizationProvider
            dateAdapter={AdapterDateFns}
            locale={localeMap[locale]}
        >
            <div className={classes.DatePicker}>
                <ThemeProvider theme={outerTheme}>
                    <DatePicker
                        mask={maskMap[locale]}
                        disabled={disabled}
                        minDate={minDate}
                        value={date}
                        onChange={handleOnChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                InputProps={{
                                    ...params.InputProps,
                                    className: classes.dateInput,
                                }}
                            />
                        )}
                    />
                </ThemeProvider>
            </div>
        </LocalizationProvider>
    )
}
