import React from 'react'
import { FileWithSelectedLicenses } from 'DAL/BeatSettingsAPI'
import { FormControlLabel } from '@material-ui/core'
import { CustomCheckbox } from 'UI/common/elements/CustomCheckbox'

type Props = {
    file: FileWithSelectedLicenses
    exist: boolean
    handleOnChangeBindFiles: (fileId: number, isBind: boolean) => void
}
export const FileItem = ({ file, exist, handleOnChangeBindFiles }: Props) => {
    return (
        <div>
            <FormControlLabel
                control={
                    <CustomCheckbox
                        name={'bindingForLicense' + file.name}
                        checked={exist}
                        onChange={({ target: { checked } }) =>
                            handleOnChangeBindFiles(file.id, checked)
                        }
                    />
                }
                label={file.name + '.' + file.extension}
                labelPlacement={'end'}
            />
        </div>
    )
}
