import React, { useState } from 'react'
import { useHistory, NavLink } from 'react-router-dom'
import { FreeBeatType } from 'DAL/BeatSettingsAPI'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'
import { useSelector } from 'UI/common/hooks/redux-hooks'
import { usePlayer } from 'Player/Hooks/Hooks'
import Tooltip from '@material-ui/core/Tooltip'
import { PlayerLogicTrackType } from 'Player/Domain/PlayerLogic'
import { CustomerRM } from 'UI/routers/RouterManager'
import { getLocaleDateString } from 'UI/utils/util-types'
import { CustomMobileView } from 'UI/common/mobileView/CustomMobileView'
import { CustomBrowserView } from 'UI/common/browserView/CustomBrowserView'
import { useDeviceDetect } from 'UI/common/hooks/useDeviceDetect'
import DragIcon from 'UI/assets/icons/drag-icon.svg'
import EditIcon from 'UI/assets/icons/edit.svg'
import DeleteIcon from 'UI/assets/icons/delete.svg'
import PlayIcon from 'Player/assets/images/player-icons/play_blue.svg'
import PauseIcon from 'Player/assets/images/player-icons/pause_blue.svg'
import dotsMenu from 'UI/assets/icons/dotsMenu.svg'
import s from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/beatItem/beatItem.module.scss'
import { MobFreeBeatItem } from 'UI/CustomerAdmin/Mobile/MobFreeBeatsPage/MobFreeBeatItem/MobFreeBeatItem'
import { MobileMenuItem } from './mobileMenu/mobileMenuItem'
import { BeatCover } from 'UI/common/components/BeatCover/BeatCover'

type Props = {
    beat: FreeBeatType
    index: number
}

export const FreeBeatsItem = ({ beat }: Props) => {
    const artist = useSelector(
        (state) => state.appReducer.userDetails?.userName
    )

    const { i18n } = useTranslation()
    const { customerBeatsPublication, buttons } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    let playerBeatDTO: PlayerLogicTrackType = {
        id: beat.id,
        artist: artist ? artist : '',
        title: beat.title,
        format: beat.format,
        url: beat.playUrl as string,
        type: 'beat',
        images: beat.images,
    }

    const [player, isPlayingMe] = usePlayer(false, playerBeatDTO)
    const [show, setShow] = useState(false)

    const playHandler = () => {
        if (isPlayingMe) {
            player.pause()
        } else {
            player.play(playerBeatDTO)
        }
    }

    const isMobile = useDeviceDetect()

    return (
        <div className={isMobile ? '' : s.card}>
            <div className={s.beatInfo}>
                <CustomBrowserView>
                    <div className={s.dragElement}>
                        <img
                            src={DragIcon}
                            alt={'drag'}
                            className={s.disabled}
                        />
                    </div>
                    <BeatCover
                        beat={beat}
                        playHandler={playHandler}
                        isPlayingMe={isPlayingMe}
                    />
                    <div>
                        <div className={s.beatTitle}>{beat.title}</div>
                        <div className={s.publicationInfo}>
                            {beat.addedDate && (
                                <span>
                                    {`${
                                        customerBeatsPublication.tableColumns
                                            .publishedDate
                                    }: ${getLocaleDateString(
                                        i18n.language,
                                        beat.addedDate
                                    )}`}
                                </span>
                            )}
                        </div>
                    </div>
                </CustomBrowserView>
                <CustomMobileView>
                    <MobFreeBeatItem
                        beat={beat}
                        playHandler={playHandler}
                        isPlayingMe={isPlayingMe}
                    />
                </CustomMobileView>
            </div>
            <CustomBrowserView>
                <div className={s.beatChart}>
                    <div>{'—'}</div>
                    <div>{beat.downloads}</div>
                    <div>{beat.plays}</div>
                </div>
            </CustomBrowserView>
            <CustomBrowserView>
                <div className={s.information}>
                    <div className={s.actions}>
                        <Tooltip title={buttons.EDIT}>
                            <NavLink
                                to={CustomerRM.freeBeats.getUrl.beats.edit(
                                    beat.id
                                )}
                            >
                                <img
                                    className={s.icons}
                                    src={EditIcon}
                                    alt={'edit'}
                                />
                            </NavLink>
                        </Tooltip>
                        <Tooltip title={buttons.DELETE_FROM_CATALOG}>
                            <NavLink
                                to={CustomerRM.freeBeats.getUrl.beats.delete(
                                    beat.id
                                )}
                            >
                                <img
                                    className={s.icons}
                                    src={DeleteIcon}
                                    alt={'delete'}
                                />
                            </NavLink>
                        </Tooltip>
                    </div>
                    <div className={s.smallDesktopViewActions}>
                        <button onClick={() => setShow(!show)}>
                            <img src={dotsMenu} alt="Open Menu" />
                        </button>
                        {show && <MobileMenuItem beat={beat} />}
                    </div>
                </div>
            </CustomBrowserView>
        </div>
    )
}
