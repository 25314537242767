import { createSelector } from 'reselect'
import { IItemsResult, shopAPI } from 'DAL/Services'
import * as dateFns from 'date-fns'
import { STATUSES } from './SupportingFile'
import { promoAPI, CreatePromoDto, PaginateType } from 'DAL/PromoAPI'
import {
    BeatType,
    BusyDaysPeriodType,
    BusyDaysType,
    PromoType,
    ResultCodesEnum,
    ThunkActionType,
    UpdatePromoType,
} from './types'
import { AppState, InferActionsTypes } from './Store'
import {
    actions as appActions,
    AuthActionsTypes,
    EditingEntityStatuses,
} from './AppReducer'
import { commonAsyncHandler, completeOperation } from './common-async-handler'
import { AppSettings } from 'settings/appSettings'

export interface OptionsPromoType {
    value: number
    label: number
    price: number
}

export type BeatsPaginationType = {
    page: number
    pagesCount: number
    totalCount: number
    pageSize: number
}

const initState = {
    promotions: [] as PromoType[],
    beats: [] as BeatType[],
    busyDays: [] as BusyDaysType[],
    price: null as number | null,
    promoType: [{ value: 0, price: 0, label: 0 }] as OptionsPromoType[],
    pageSize: 100,
    page: 1,
    totalCount: 0,
    statusBeats: '',
    statusBusyDays: '',
    statusPromo: '',
    pagesCount: 0,
    snackBarIsOpen: false,
    snackBarMessage: '',
    calendarIsOpen: false,
    currentPromo: null as PromoType | null,
    beatsPagination: {
        page: 0,
        pagesCount: 0,
        totalCount: 0,
        pageSize: 20,
    },
}

type IState = typeof initState
export type ActionsTypes = InferActionsTypes<typeof actions> | AuthActionsTypes

const CustomerBeatsPromotionReducer = (
    state = initState,
    action: ActionsTypes
): IState => {
    switch (action.type) {
        case 'BEATS_PROMO_REDUCER/SET_BEATS': {
            const beats = [...state.beats, ...action.beats.items]
            return {
                ...state,
                beats: [
                    ...new Map(
                        beats.map((item) => [item['beatId'], item])
                    ).values(),
                ],
                beatsPagination: {
                    page: action.beats.page,
                    pageSize: action.beats.pageSize,
                    totalCount: action.beats.totalCount,
                    pagesCount: action.beats.pagesCount,
                },
            }
        }
        case 'BEATS_PROMO_REDUCER/SET_PROMOS':
            return {
                ...state,
                promotions: action.promotions.items,
                page: action.promotions.currentPage,
                pageSize: action.promotions.pageSize,
                totalCount: action.promotions.totalCount,
            }
        case 'BEATS_PROMO_REDUCER/SET_BUSY_DAYS':
            return {
                ...state,
                busyDays: action.busyDays,
            }
        case 'BEATS_PROMO_REDUCER/SET_PROMO_TYPE':
            return {
                ...state,
                promoType: action.promoType,
            }
        case 'BEATS_PROMO_REDUCER/SET_PAGE':
            return {
                ...state,
                page: action.page,
            }
        case 'BEATS_PROMO_REDUCER/SET_STATUS_BUSY_DAYS':
            return {
                ...state,
                statusBusyDays: action.statusBusyDays,
            }
        case 'BEATS_PROMO_REDUCER/SET_CLEAR_BEATS':
            return {
                ...state,
                beats: [],
                beatsPagination: {
                    ...state.beatsPagination,
                    pagesCount: 0,
                    page: 0,
                },
            }
        case 'BEATS_PROMO_REDUCER/SET_CURRENT_PROMO':
            return {
                ...state,
                currentPromo: action.promo,
            }
        case 'BEATS_PROMO_REDUCER/SET_PAGE_SIZE':
            return {
                ...state,
                pageSize: action.pageSize,
            }
        case 'BEATS_PROMO_REDUCER/SET_BEATS_PAGINATION':
            return {
                ...state,
                beatsPagination: action.beatsPagination,
            }
        default:
            return state
    }
}

export default CustomerBeatsPromotionReducer

export const actions = {
    setBeats: (beats: IItemsResult<BeatType>) =>
        ({ type: 'BEATS_PROMO_REDUCER/SET_BEATS', beats } as const),
    setPromos: (promotions: PaginateType<PromoType[]>) =>
        ({ type: 'BEATS_PROMO_REDUCER/SET_PROMOS', promotions } as const),
    setBusyDays: (busyDays: BusyDaysType[]) =>
        ({ type: 'BEATS_PROMO_REDUCER/SET_BUSY_DAYS', busyDays } as const),
    setPromoType: (promoType: OptionsPromoType[]) =>
        ({
            type: 'BEATS_PROMO_REDUCER/SET_PROMO_TYPE',
            promoType,
        } as const),
    setPage: (page: number) =>
        ({ type: 'BEATS_PROMO_REDUCER/SET_PAGE', page } as const),
    setPageSize: (pageSize: number) =>
        ({ type: 'BEATS_PROMO_REDUCER/SET_PAGE_SIZE', pageSize } as const),
    setStatusBusyDays: (statusBusyDays: string) =>
        ({
            type: 'BEATS_PROMO_REDUCER/SET_STATUS_BUSY_DAYS',
            statusBusyDays,
        } as const),
    clearBeats: () =>
        ({ type: 'BEATS_PROMO_REDUCER/SET_CLEAR_BEATS' } as const),
    setCurrentPromo: (promo: PromoType) =>
        ({
            type: 'BEATS_PROMO_REDUCER/SET_CURRENT_PROMO',
            promo,
        } as const),
    setBeatsPagination: (beatsPagination: BeatsPaginationType) =>
        ({
            type: 'BEATS_PROMO_REDUCER/SET_BEATS_PAGINATION',
            beatsPagination,
        } as const),
}

export const getBeats =
    (page: number, term?: string): ThunkActionType<ActionsTypes> =>
    async (dispatch, getState) => {
        await commonAsyncHandler(async () => {
            const pageSize: number =
                getState().customerBeatsPromotion.beatsPagination.pageSize
            if (!page) {
                page = getState().customerBeatsPromotion.beatsPagination.page
            }
            const response = await shopAPI.getBeats(pageSize, page, term)
            // dispatch(actions.setPage(response.pagesCount));
            dispatch(actions.setBeats(response))
            return response
        }, dispatch)
    }

export const getPromoPrice =
    (): ThunkActionType<ActionsTypes> => async (dispatch) => {
        await commonAsyncHandler(async () => {
            let response = await promoAPI.getPrice()
            const isEng = AppSettings.api.isEngVersion()
            let promoType: OptionsPromoType[] = response.promoPrice
                .filter((p) => p.engMode === isEng)
                .map((p) => ({
                    value: p.type,
                    price: p.price,
                    label: p.type,
                }))
            dispatch(actions.setPromoType(promoType))
            return response
        }, dispatch)
    }

export const getPromos =
    (): ThunkActionType<ActionsTypes> => async (dispatch, getState) => {
        await commonAsyncHandler(async () => {
            const page = getState().customerBeatsPromotion.page
            const pageSize = getState().customerBeatsPromotion.pageSize
            let response = await promoAPI.getPromotions(page, pageSize)
            dispatch(actions.setPromos(response))
            return response
        }, dispatch)
    }

export const sendPromo =
    (promo: CreatePromoDto): ThunkActionType<ActionsTypes> =>
    async (dispatch) => {
        await commonAsyncHandler(async () => {
            const response = await promoAPI.reservePromotion(promo)
            completeOperation(response, dispatch, () => {
                dispatch(getPromos())
                dispatch(
                    appActions.setEditingEntityStatus(
                        EditingEntityStatuses.Success
                    )
                )
            })
            return response
        }, dispatch)
    }

export const getPromoById =
    (id: string): ThunkActionType<ActionsTypes> =>
    async (dispatch, getState) => {
        await commonAsyncHandler(async () => {
            const page = getState().customerBeatsPromotion.page
            const pageSize = getState().customerBeatsPromotion.pageSize
            let response = await promoAPI.getPromotions(page, pageSize)
            dispatch(actions.setPromos(response))
            const currentPromo = response.items.find(
                (promo) => promo._id === id
            )
            currentPromo && dispatch(actions.setCurrentPromo(currentPromo))
            return response
        }, dispatch)
    }

export const updatePromo =
    (updatePromo: UpdatePromoType): ThunkActionType<ActionsTypes> =>
    async (dispatch, getState) => {
        await commonAsyncHandler(async () => {
            const beats: BeatType[] = getState().customerBeatsPromotion.beats
            const beat = beats.find(
                (b) => b.beatId === updatePromo.beatId
            ) as BeatType
            const promo = { ...updatePromo.promo, beat }
            const response = await promoAPI.updatePromo(promo)
            completeOperation(response, dispatch, () => {
                dispatch(getPromos())
                dispatch(
                    appActions.setEditingEntityStatus(
                        EditingEntityStatuses.Success
                    )
                )
            })
            return response
        }, dispatch)
    }

export const getBusyDays =
    (period: BusyDaysPeriodType): ThunkActionType<ActionsTypes> =>
    async (dispatch) => {
        await commonAsyncHandler(async () => {
            dispatch(actions.setStatusBusyDays(STATUSES.LOADING))
            const { startOfMonth, promoType } = period
            // @ts-ignore
            const endOfMonth: Date = dateFns.endOfMonth(startOfMonth)
            const endMonth: string = dateFns.format(endOfMonth, 'yyyy-MM-dd')
            const startMonth: string = dateFns.format(
                startOfMonth,
                'yyyy-MM-dd'
            )
            const startDate: string = dateFns.format(
                dateFns.toDate(dateFns.parseISO(startMonth)),
                "yyyy-MM-dd'T'HH:mm:ss.SSS'+'ssss"
            )
            const endDate: string = dateFns.format(
                dateFns.toDate(dateFns.parseISO(endMonth)),
                "yyyy-MM-dd'T'HH:mm:ss.SSS'+'ssss"
            )
            const response = await promoAPI.getBusyDays({
                startOfMonth: startDate as any,
                endOfMonth: endDate,
                promoType,
            })
            if (response.resultCode === ResultCodesEnum.Success) {
                dispatch(actions.setStatusBusyDays(STATUSES.SUCCESS))
                dispatch(actions.setBusyDays(response.data))
            }
            return response
        }, dispatch)
    }

//SELECTORS
const getPromotionsSel = (state: AppState): PromoType[] =>
    state.customerBeatsPromotion.promotions

export const getAllPromo = createSelector([getPromotionsSel], (promo) => {
    return promo.sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
    )
})

const getPromoBeatsSel = (state: AppState): BeatType[] =>
    state.customerBeatsPromotion.beats

export const getAllBeatsForPromo = createSelector(
    [getPromoBeatsSel],
    (beats) => {
        return beats
    }
)

const getBusyDaysSel = (state: AppState): BusyDaysType[] =>
    state.customerBeatsPromotion.busyDays

export const getAllBusyDays = createSelector([getBusyDaysSel], (days) => {
    return days
})

const getPromoTypeSel = (state: AppState): OptionsPromoType[] =>
    state.customerBeatsPromotion.promoType

export const getPromoType = createSelector([getPromoTypeSel], (promoType) => {
    return promoType
})

const getPageSel = (state: AppState): number =>
    state.customerBeatsPromotion.page

export const getPage = createSelector([getPageSel], (page) => {
    return page
})

export const getPagesCount = (state: AppState): number =>
    state.customerBeatsPromotion.pagesCount

const getPageSizeSel = (state: AppState): number =>
    state.customerBeatsPromotion.pageSize

export const getPageSize = createSelector([getPageSizeSel], (pageSize) => {
    return pageSize
})

const getStatusBeatsSel = (state: AppState): string =>
    state.customerBeatsPromotion.statusBeats

export const getStatusBeats = createSelector(
    [getStatusBeatsSel],
    (statusBeats) => {
        return statusBeats
    }
)
const getStatusBusyDaysSel = (state: AppState): string =>
    state.customerBeatsPromotion.statusBusyDays

export const getStatusBusyDays = createSelector(
    [getStatusBusyDaysSel],
    (statusBusyDays) => {
        return statusBusyDays
    }
)

const getCalendarIsOpensSel = (state: AppState): boolean =>
    state.customerBeatsPromotion.calendarIsOpen

export const getCalendarIsOpen = createSelector(
    [getCalendarIsOpensSel],
    (calendarIsOpen) => {
        return calendarIsOpen
    }
)

export const getStatusPromoSel = (state: AppState): string =>
    state.customerBeatsPromotion.statusPromo
