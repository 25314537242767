import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { NavLink, Route, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Tooltip from '@material-ui/core/Tooltip'

import classes from './WidgetItem.module.scss'
import EditIcon from '../../../../assets/icons/edit.svg'
import DeleteIcon from '../../../../assets/icons/delete.svg'

import { WidgetType } from '../../../../../DAL/WidgetsAPI'
import { CustomerRM } from '../../../../routers/RouterManager'
import { LanguageType } from '../../../../../translations/ru/common'
import { Modal } from '../../../../common/modal/modal'
import { Button } from '../../../../common/elements/buttons/Button'
import { deleteWidgetTC } from '../../../../../BLL/WidgetsReducer'

type WidgetItemPropsType = {
    widget: WidgetType
    widgets: WidgetType[]
}

export const WidgetItem = (props: WidgetItemPropsType) => {
    const dispatch = useDispatch()
    const history = useHistory()

    /* i18n */
    const { i18n } = useTranslation()
    const { widget, buttons } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    const [modal, setModal] = useState(false)

    const deleteWidgetHandler = useCallback(
        (widgetId: string) => {
            dispatch(deleteWidgetTC(widgetId))
        },
        [deleteWidgetTC]
    )

    const getWidgetTitle = (widgetId: string): string => {
        let widget = props?.widgets.find((w) => {
            return w._id === widgetId
        })
        return widget ? widget.title : ''
    }

    return (
        <div className={classes.box}>
            <p className={classes.title}>{props.widget.title}</p>

            <div>
                <Tooltip title={buttons.EDIT}>
                    <NavLink
                        to={CustomerRM.widgets.getUrl.edit(props.widget._id)}
                    >
                        <img
                            src={EditIcon}
                            alt={'edit'}
                            className={classes.icon}
                        />
                    </NavLink>
                </Tooltip>

                <Tooltip title={buttons.DELETE}>
                    <NavLink
                        to={CustomerRM.widgets.getUrl.delete(props.widget._id)}
                    >
                        <img
                            className={classes.icon}
                            src={DeleteIcon}
                            alt={'delete'}
                        />
                    </NavLink>
                </Tooltip>
            </div>

            {/* Modal: delete widget */}
            <Route
                exact
                path={CustomerRM.widgets.path.delete}
                render={({ match }) => {
                    return (
                        <Modal
                            active={true}
                            setActive={setModal}
                            title={widget.popup.deleteTitle}
                            cancelRedirect={() =>
                                history.push(CustomerRM.widgets.path.list)
                            }
                        >
                            <p className={classes.modalTitle}>
                                {widget.popup.deleteMessage}
                                <b>
                                    {getWidgetTitle(match.params['widgetId'])}
                                </b>
                            </p>
                            <div className={classes.modalButtonsBox}>
                                <Button
                                    view={'Secondary'}
                                    title={buttons.CANCEL}
                                    onClick={() =>
                                        history.push(
                                            CustomerRM.widgets.path.list
                                        )
                                    }
                                />
                                <Button
                                    view={'Delete'}
                                    title={buttons.DELETE}
                                    onClick={() =>
                                        deleteWidgetHandler(
                                            match.params['widgetId']
                                        )
                                    }
                                />
                            </div>
                        </Modal>
                    )
                }}
            />
        </div>
    )
}
