import React, { ComponentPropsWithoutRef } from 'react'
import { FieldError } from 'react-hook-form'
import s from 'UI/common/elements/textFields/inputs.module.scss'
import t from 'UI/common/elements/textFields/newTextArea/newTextArea.module.scss'

export type Props = {
    label?: string
    error?: FieldError | undefined
    customStyles?: Record<string, any>
} & ComponentPropsWithoutRef<'textarea'>

export const NewTextArea = ({
    error,
    label,
    customStyles,
    ...restProps
}: Props) => {
    const defaultClass = `${s.Input} ${t.textAreaField}`
    const defaultErrorClass = `${s.Input} ${t.textAreaField} ${s.error}`

    let cond1 = customStyles ? customStyles.textAreaField : defaultErrorClass
    let cond2 = customStyles ? customStyles.textAreaFieldError : defaultClass
    const className = error?.message ? cond1 : cond2

    return (
        <>
            <div className={t.labelStyle}>
                {label && <label>{label}</label>}
            </div>
            <textarea className={className} {...restProps} />
            <span className={s.errorMessage}>{error?.message}</span>
        </>
    )
}
