import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from '../hooks/redux-hooks'
import { useSnackbar } from 'notistack'
import { selectSnackbar } from 'BLL/selectors/app-selectors'

export const Snackbar = () => {
    const snackbar = useSelector(selectSnackbar)

    const { i18n } = useTranslation()
    const { messages } = i18n.getResourceBundle(i18n.language, 'common')

    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        if (snackbar.message) {
            const message = messages[snackbar.message]
                ? messages[snackbar.message]
                : snackbar.message
            enqueueSnackbar(message, { variant: snackbar.variant })
        }
    }, [snackbar])

    return <div />
}
