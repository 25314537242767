import React from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import { getStyle } from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/utils'
import { BeatItem } from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/beatItem/BeatItem'
import { useSelector } from 'UI/common/hooks/redux-hooks'
import { getAllPublications } from 'BLL/CustomerBeatsPublicationReducer'
import { FreeBeatType, MyBeatType } from 'DAL/BeatSettingsAPI'
import s from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/draggableTable/draggableTable.module.scss'

type Props = {
    beatsReorder: (MyBeatType | FreeBeatType)[]
    isDrag: boolean
}
export const DroppableTableItem = ({ beatsReorder, isDrag }: Props) => {
    const publications = useSelector(getAllPublications)

    return (
        <Droppable droppableId="droppable" type="COLUMN">
            {(provided) => (
                <div
                    className={s.wrapperDrag}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                >
                    {beatsReorder.map((beat, index) => (
                        <Draggable
                            key={beat.id}
                            draggableId={String(beat.id)}
                            index={index}
                        >
                            {(provided, snapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    style={getStyle({
                                        draggableStyle:
                                            provided.draggableProps.style,
                                        isDragging: snapshot.isDragging,
                                    })}
                                >
                                    <BeatItem
                                        beat={beat as MyBeatType}
                                        provided={provided}
                                        isDragging={snapshot.isDragging}
                                        isDrag={isDrag}
                                        publication={publications.find(
                                            (p) => p.beat.beatId === beat.id
                                        )}
                                        index={index}
                                    />
                                </div>
                            )}
                        </Draggable>
                    ))}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    )
}
