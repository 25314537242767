import { axiosCore } from './createAxios'

export const ReferenceAPI = {
    getCountries() {
        return axiosCore
            .get<CountryType[]>(`/BeatsManagement/BeatsManagement/GetCountries`)
            .then((r) => r.data)
    },
}

export type CountryType = {
    id: number
    title: string
    englishName: string
}
