import { axiosCore } from './createAxios'
import { PaymentSources, PaymentSystemTypes } from './paymentsAPI'
import { CurrencyEnum } from './CashbackAPI'
import { NullableType, BaseResponse } from '../BLL/types'

export const MySalesAPI = {
    async getSales() {
        return axiosCore
            .get<MySalesResponse>('/BeatsManagement/BeatsSales/GetSales')
            .then((r) => r.data)
    },

    async exchangeMoneyToCoins(rootPaymentId: number) {
        return axiosCore
            .put<BaseResponse>(
                `/BeatsManagement/BeatsSales/ExchangeMoneyToCoins?id=${rootPaymentId}`
            )
            .then((r) => r.data)
    },
}

//server response types
export type SaleType = {
    beatId: number
    beatTitle: string
    beatmakerId: number
    beatmakerMoney: number
    beatmakerName: string
    buyerEmail: string
    buyerId: number
    buyerName: string
    id: number
    isDirectPayment: boolean
    money: number
    paidDate: NullableType<string>
    paymentId: number
    paymentSystem: number
    paymentType: number
    percent: number
    saleDate: NullableType<string>
    sourceName: NullableType<string>
}
export type PaymentType = {
    beatmakerPureMoney: number
    currencyName: CurrencyEnum
    serviceFeeAmount: number
    id: number
    userId: number
    initDate: string
    confirmDate: string
    paymentSystemObject: NullableType<string>
    status: number
    paymentSource: PaymentSources
    publicId: string
    cashbackAmount: number
    amount: number
    realMoney: number
    itemId: number
    paymentType: number
    data: string //"{\"PartnerCookie\":null,\"Data\":{\"BrowserClientId\":\"e708edbc-ccac-4dd7-b095-f8a90f0ea2bb\",\"UserIP\":\"89.40.63.187\",\"UserEmail\":null,\"LicenseText\":null}}",
    directWalletsId: NullableType<string>
    walletNumber: NullableType<number>
    paymentSystem: PaymentSystemTypes
    isGroupPayment: boolean
    rootPaymentId: number
    businessUserId: NullableType<number>
    altId: string
    moneyWithdrawalId: NullableType<any>
    moneyWithdrawal: NullableType<any>
}
export type PaymentBeatDetails = {
    paymentId: number
    licenseTitle: string
    licenseTitleEng: string
    licensePoints: string //"[\"\",\"\"]",
    licensePointsEng: string // "[\"ывсфывс\"]",
    beatLicenseId: string //"31f3fa5c-0e3d-44f0-839f-6fdaed964f19",
    beatLicense: NullableType<any>
    buyerFullName: NullableType<string>
    couponData: NullableType<string>
    clientPassport: string
    clientFullname: string
    pdfExists: boolean
    engPdfExists: boolean
    cloudFiles: NullableType<any>
}
export type MySalesType = {
    sale: SaleType
    payment: PaymentType
    paymentBeatDetails: PaymentBeatDetails
    rootPayment: PaymentType
}
export type MySalesResponse = {
    items: MySalesType[]
}
