import Tooltip from '@material-ui/core/Tooltip'
import { MyBeatType } from 'DAL/BeatSettingsAPI'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { LanguageType } from 'translations/ru/common'
import deleteIcon from 'UI/assets/icons/delete.svg'
import dragIcon from 'UI/assets/icons/drag-icon.svg'
import restore from 'UI/assets/icons/restore.svg'
import { BeatCover } from 'UI/common/components/BeatCover/BeatCover'
import { BeatLabeledDate } from 'UI/common/components/BeatAddedDate/BeatAddedDate'
import classes from 'UI/CustomerAdmin/Mobile/MobMyBeatsPage/common/styles/MobBeatItem.module.scss'
import { CustomerRM } from 'UI/routers/RouterManager'
import { MobBeatDescription } from '../../common/MobBeatDescription/MobBeatDescription'

type PropsType = {
    beat: MyBeatType
    playHandler: () => void
    isPlayingMe: boolean
}
export const MobArchiveItem: FC<PropsType> = ({
    beat,
    playHandler,
    isPlayingMe,
}) => {
    const { i18n } = useTranslation()
    const { buttons, customerBeatsPublication } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    return (
        <div className={classes.beatItem}>
            <div className={classes.beatBox}>
                <div className={classes.beatInfo}>
                    <BeatCover
                        beat={beat}
                        playHandler={playHandler}
                        isPlayingMe={isPlayingMe}
                        isMobile={true}
                    />
                    <div>
                        <span className={classes.track}>{beat.title}</span>
                        <p className={classes.trackDate}>
                            <BeatLabeledDate
                                title={
                                    customerBeatsPublication.tableColumns
                                        .publishedDate
                                }
                                date={beat.addedToCatalogDate}
                            />
                        </p>
                    </div>
                </div>
                <div className={classes.dragElement}>
                    <img
                        src={dragIcon}
                        alt={'drag'}
                        className={classes.disabled}
                    />
                </div>
            </div>
            <MobBeatDescription
                downloads={beat.downloads}
                plays={beat.plays}
                chartPosition={null}
            />
            <div className={classes.actions}>
                <Tooltip title={buttons.RESTORE}>
                    <NavLink
                        to={CustomerRM.myBeats.getUrl.archiveBeats.restore(
                            beat.id
                        )}
                    >
                        <img
                            className={classes.icons}
                            src={restore}
                            alt={'restore'}
                        />
                    </NavLink>
                </Tooltip>
                <Tooltip title={buttons.DELETE}>
                    <NavLink
                        to={CustomerRM.myBeats.getUrl.archiveBeats.delete(
                            beat.id
                        )}
                    >
                        <img
                            className={classes.icons}
                            src={deleteIcon}
                            alt={'delete'}
                        />
                    </NavLink>
                </Tooltip>
            </div>
        </div>
    )
}
