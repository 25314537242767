import { useCallback, useMemo } from 'react'

const range = (start: number, end: number) => {
    const length = end - start + 1
    return Array.from({ length }, (_, idx) => idx + start)
}

export const DOTS = '...'

type UsePaginationParamType = {
    pagesCount: number
    onChangePage: (pageNumber: number) => void
    currentPage: number
    siblings?: number
}

type PaginationRange = ('...' | number)[]

export const usePagination = ({
    pagesCount,
    onChangePage,
    currentPage,
    siblings = 1,
}: UsePaginationParamType) => {
    const paginationRange = useMemo(() => {
        const totalPageNumbers = siblings + 5

        if (totalPageNumbers >= pagesCount) {
            return range(1, pagesCount)
        }

        const leftSiblingIndex = Math.max(currentPage - siblings, 1)
        const rightSiblingIndex = Math.min(currentPage + siblings, pagesCount)

        const shouldShowLeftDots = leftSiblingIndex > 2
        const shouldShowRightDots = rightSiblingIndex < pagesCount - 2

        const firstPageIndex = 1
        const lastPageIndex = pagesCount

        if (!shouldShowLeftDots && shouldShowRightDots) {
            const leftItemCount = 3 + 2 * siblings
            const leftRange = range(1, leftItemCount)

            return [...leftRange, DOTS, pagesCount]
        }

        if (shouldShowLeftDots && !shouldShowRightDots) {
            const rightItemCount = 3 + 2 * siblings
            const rightRange = range(
                pagesCount - rightItemCount + 1,
                pagesCount
            )

            return [firstPageIndex, DOTS, ...rightRange]
        }

        if (shouldShowLeftDots && shouldShowRightDots) {
            const middleRange = range(leftSiblingIndex, rightSiblingIndex)

            return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex]
        }
    }, [siblings, currentPage, pagesCount]) as PaginationRange

    const lastPage = paginationRange[paginationRange.length - 1]
    const isFirstPage = currentPage === 1
    const isLastPage = currentPage === lastPage

    const onNextPage = useCallback(() => {
        onChangePage && onChangePage(currentPage + 1)
    }, [currentPage, onChangePage])

    const onPreviousPage = useCallback(() => {
        let changedPage = currentPage > 1 ? currentPage - 1 : currentPage
        onChangePage && onChangePage(changedPage)
    }, [currentPage, onChangePage])

    const handleMainPageClicked = (pageNumber: number) => {
        onChangePage(pageNumber)
    }

    return {
        handleMainPageClicked,
        onNextPage,
        onPreviousPage,
        isFirstPage,
        isLastPage,
        paginationRange,
    }
}
