import i18n from 'i18n'
import React from 'react'
import { SubmitHandler, useForm, Validate } from 'react-hook-form'
import { useParams, useHistory } from 'react-router-dom'
import { LanguageType } from 'translations/ru/common'
import { ControlledSelect } from 'UI/common/controls/controlledSelect/ControlledSelect'
import { ControlledTextArea } from 'UI/common/controls/controlledTextArea/ControlledTextArea'
import { ControlledTextField } from 'UI/common/controls/controlledTextField/ControlledTextField'
import s from 'UI/CustomerAdmin/FreeBeatsPage/editFreeBeat/editfreebeat.module.scss'
import { Button } from 'UI/common/elements/buttons/Button'
import { CustomerRM } from 'UI/routers/RouterManager'
import { BeatsConstants } from 'UI/constants/index'
import { BeatCreationMethod } from './enums'
import { EditFreeBeatForm } from './types'
import { useDispatch } from 'react-redux'
import { updateFreeBeat } from 'BLL/CustomerFreeBeatsReducer'
import { useBeatOptions } from './hooks'
import { useSelector } from 'UI/common/hooks/redux-hooks'
import { selectFreeBeatById } from 'BLL/selectors/freeBeats-selectors'

const EditFreeBeat = () => {
    const { myBeats, buttons } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    const history = useHistory()
    const dispatch = useDispatch()
    const { freeBeatId } = useParams()

    const beat = useSelector(selectFreeBeatById(Number(freeBeatId)))

    const { stylesOption, moodsOption, creationOption } = useBeatOptions()

    const beatCreationOption = creationOption.find((o) =>
        beat?.composing
            ? o.value === BeatCreationMethod.Composing
            : o.value === BeatCreationMethod.Sampling
    )

    const beatStyleOption =
        stylesOption.find((o) => o.value === beat?.styleId) || stylesOption[0]

    const beatMoodOption =
        moodsOption.find((o) => o.value === beat?.moodId) || moodsOption[0]

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<EditFreeBeatForm>({
        mode: 'onChange',
        defaultValues: {
            title: beat?.title || '',
            tempo:
                (beat?.tempo || 0) < BeatsConstants.MIN_BPM
                    ? BeatsConstants.MIN_BPM
                    : beat?.tempo,
            description: beat?.description || '',
            mood: beatMoodOption,
            style: beatStyleOption,
            creationMethod: beatCreationOption,
        },
    })

    const onSubmit: SubmitHandler<EditFreeBeatForm> = (update) => {
        dispatch(
            updateFreeBeat({
                id: Number(freeBeatId),
                name: update.title,
                description: update.description.trim(),
                tempo: update.tempo,
                moodId: Number(update.mood?.value),
                styleId: Number(update.style?.value),
                composing:
                    update.creationMethod?.value ===
                    BeatCreationMethod.Composing,
                sampling:
                    update.creationMethod?.value ===
                    BeatCreationMethod.Sampling,
            })
        )
    }

    const validateNumber: Validate<string> = (value) => {
        return Number.parseInt(value, 10)
            ? true
            : myBeats.formsField.errors.numberError
    }

    if (!beat) return null

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={s.contentWrapper}>
                    <div className={s.selectWrapper}>
                        <div className={s.title}>
                            {myBeats.formsField.beatTitle}
                        </div>
                        <ControlledTextField
                            name={'title'}
                            control={control}
                            required={true}
                            rules={{
                                maxLength: {
                                    value: BeatsConstants.MAX_TITLE_LENGTH,
                                    message:
                                        myBeats.formsField.errors.maxLengthError(
                                            BeatsConstants.MAX_TITLE_LENGTH
                                        ),
                                },
                            }}
                            error={errors?.title}
                            customStyles={s}
                        />
                        <div className={s.title}>{myBeats.formsField.bmp}</div>
                        <ControlledTextField
                            name={'tempo'}
                            control={control}
                            required={true}
                            rules={{
                                validate: validateNumber,
                                max: {
                                    value: BeatsConstants.MAX_BPM,
                                    message:
                                        myBeats.formsField.errors.maxBpmError,
                                },
                                min: {
                                    value: BeatsConstants.MIN_BPM,
                                    message:
                                        myBeats.formsField.errors.minBpmError,
                                },
                            }}
                            error={errors?.tempo}
                            customStyles={s}
                        />
                        <div className={s.title}>
                            {myBeats.formsField.creationMethod.title}
                        </div>
                        <ControlledSelect
                            name={'creationMethod'}
                            options={creationOption}
                            control={control}
                            errorMessage={errors?.creationMethod?.message}
                            customStyles={s}
                        />
                        <div className={s.title}>
                            {myBeats.formsField.genre}
                        </div>
                        <ControlledSelect
                            name={'style'}
                            options={stylesOption}
                            control={control}
                            errorMessage={errors?.style?.message}
                            customStyles={s}
                        />
                        <div className={s.title}>{myBeats.formsField.mood}</div>
                        <ControlledSelect
                            name={'mood'}
                            options={moodsOption}
                            control={control}
                            errorMessage={errors?.mood?.message}
                            customStyles={s}
                        />
                        <div className={s.title}>
                            {myBeats.formsField.description}
                        </div>
                        <ControlledTextArea
                            control={control}
                            name={'description'}
                            rules={{
                                maxLength: {
                                    value: BeatsConstants.MAX_DESCRIPTION_LENGTH,
                                    message:
                                        myBeats.formsField.errors.maxLengthError(
                                            BeatsConstants.MAX_DESCRIPTION_LENGTH
                                        ),
                                },
                            }}
                            error={errors?.description}
                        />
                        <div className={s.buttonsWrapper}>
                            <Button
                                view={'Secondary'}
                                title={buttons.CANCEL}
                                onClick={() =>
                                    history.push(
                                        CustomerRM.freeBeats.path.beats.base
                                    )
                                }
                            />
                            <Button
                                view={'Primary'}
                                title={buttons.SAVE}
                                type={'submit'}
                                disabled={Boolean(Object.keys(errors).length)}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default EditFreeBeat
