import { InferActionsTypes } from './Store'
import {
    actions as authActions,
    actions as appActions,
    AuthActionsTypes,
    EditingEntityStatuses,
} from './AppReducer'
import { commonAsyncHandler, completeOperation } from './common-async-handler'
import {
    BeatDetailsType,
    BeatSettingsAPI,
    FilesBindedToLicensesType,
    FileWithSelectedLicenses,
    MyBeatType,
    PaginationResponseType,
    UpdateBeatContract,
} from 'DAL/BeatSettingsAPI'
import { getBeatsPublications } from './CustomerBeatsPublicationReducer'
import { setFormat } from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/beatSettings/trackFiles/utils/setFormat'
import { downloadFile } from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/beatSettings/trackFiles/utils/downloadFile'
import { CONSTANTS } from 'BLL/SupportingFile'
import { ThunkActionType } from './types'

const initState = {
    pagesCount: 0,
    pageSize: 100,
    page: 1,
    totalCount: 0,
    items: [] as MyBeatType[],
    currentBeat: null as null | BeatDetailsType,
    filesBindedToLicenses: null as null | FilesBindedToLicensesType,
    tags: [] as string[],
}

type StateType = typeof initState

export type ActionsTypes =
    | InferActionsTypes<typeof myBeatsActions>
    | AuthActionsTypes

const customerMyBeatsReducer = (
    state = initState,
    action: ActionsTypes
): StateType => {
    switch (action.type) {
        case 'MY_BEATS_REDUCER/SET_BEATS':
            return {
                ...state,
                ...action.beatsData,
            }
        case 'MY_BEATS_REDUCER/SET_PAGE':
            return {
                ...state,
                page: action.page,
            }
        case 'MY_BEATS_REDUCER/SET_PAGE_COUNT':
            return {
                ...state,
                pageSize: action.pageSize,
            }
        case 'MY_BEATS_REDUCER/SET_CURRENT_BEAT':
            return {
                ...state,
                currentBeat: action.beat,
            }
        case 'MY_BEATS_REDUCER/SET_FILES_BINDED_TO_LICENSES':
            return {
                ...state,
                filesBindedToLicenses: action.filesBindedToLicenses,
            }
        case 'MY_BEATS_REDUCER/SET_TAGS':
            return {
                ...state,
                tags: action.tags,
            }
        case 'MY_BEATS_REDUCER/DELETE_FILE':
            return state.filesBindedToLicenses
                ? {
                      ...state,
                      filesBindedToLicenses: {
                          ...state.filesBindedToLicenses,
                          files: state.filesBindedToLicenses.files.filter(
                              (file) => file.id !== action.fileId
                          ),
                      },
                  }
                : state
        case 'MY_BEATS_REDUCER/CHANGE_BEATS':
            return {
                ...state,
                items: action.beats,
            }
        default:
            return state
    }
}

export const myBeatsActions = {
    setBeatsData: (beatsData: PaginationResponseType<MyBeatType>) =>
        ({
            type: 'MY_BEATS_REDUCER/SET_BEATS',
            beatsData,
        } as const),
    setPage: (page: number) =>
        ({ type: 'MY_BEATS_REDUCER/SET_PAGE', page } as const),
    changeBeats: (beats: MyBeatType[]) =>
        ({ type: 'MY_BEATS_REDUCER/CHANGE_BEATS', beats } as const),
    deleteFile: (fileId: number) =>
        ({ type: 'MY_BEATS_REDUCER/DELETE_FILE', fileId } as const),
    setTags: (tags: string[]) =>
        ({ type: 'MY_BEATS_REDUCER/SET_TAGS', tags } as const),
    setPageSize: (pageSize: number) =>
        ({ type: 'MY_BEATS_REDUCER/SET_PAGE_COUNT', pageSize } as const),
    setCurrentBeat: (beat: BeatDetailsType | null) =>
        ({ type: 'MY_BEATS_REDUCER/SET_CURRENT_BEAT', beat } as const),
    setFilesBindedToLicenses: (
        filesBindedToLicenses: FilesBindedToLicensesType | null
    ) =>
        ({
            type: 'MY_BEATS_REDUCER/SET_FILES_BINDED_TO_LICENSES',
            filesBindedToLicenses,
        } as const),
}

export const getBeats =
    (
        page: number = 1,
        pageSize: number = 50,
        term = '',
        isArchive = false
    ): ThunkActionType<ActionsTypes> =>
    async (dispatch, getState) => {
        await commonAsyncHandler(async () => {
            const beatsData = await BeatSettingsAPI.getBeats(
                pageSize,
                page,
                term,
                isArchive
            )

            dispatch(getBeatsPublications())
            dispatch(myBeatsActions.setBeatsData(beatsData))
            return beatsData
        }, dispatch)
    }

export const updateBeat =
    (updateBeatContract: UpdateBeatContract): ThunkActionType<ActionsTypes> =>
    async (dispatch, getState) => {
        await commonAsyncHandler(async () => {
            const response = await BeatSettingsAPI.updateBeat(
                updateBeatContract
            )
            completeOperation(response, dispatch)
            return response
        }, dispatch)
    }

export const getBeatById =
    (beatId: number): ThunkActionType<ActionsTypes> =>
    async (dispatch, getState) => {
        await commonAsyncHandler(async () => {
            const beatsData = await BeatSettingsAPI.getBeatDetails(beatId)
            dispatch(myBeatsActions.setCurrentBeat(beatsData))
            return beatsData
        }, dispatch)
    }

export const getFilesBindingsToLicenses =
    (beatId: number): ThunkActionType<ActionsTypes> =>
    async (dispatch, getState) => {
        await commonAsyncHandler(async () => {
            const filesBindedToLicensesData =
                await BeatSettingsAPI.getFilesBindingsToLicenses(beatId)
            dispatch(
                myBeatsActions.setFilesBindedToLicenses(
                    filesBindedToLicensesData
                )
            )
            return filesBindedToLicensesData
        }, dispatch)
    }

export const getTags =
    (term: string = ''): ThunkActionType<ActionsTypes> =>
    async (dispatch, getState) => {
        await commonAsyncHandler(async () => {
            const tags = await BeatSettingsAPI.getTags(term)
            dispatch(myBeatsActions.setTags(tags))
            return tags
        }, dispatch)
    }

export const bindFileForLicense =
    (
        fileId: number,
        licenseId: string,
        beatId: number
    ): ThunkActionType<ActionsTypes> =>
    async (dispatch, getState) => {
        await commonAsyncHandler(async () => {
            const bindData = await BeatSettingsAPI.bindFileForLicense(
                fileId,
                licenseId
            )
            dispatch(getFilesBindingsToLicenses(beatId))
            return bindData
        }, dispatch)
    }

export const unbindFileForLicense =
    (
        fileId: number,
        licenseId: string,
        beatId: number
    ): ThunkActionType<ActionsTypes> =>
    async (dispatch, getState) => {
        await commonAsyncHandler(async () => {
            const bindData = await BeatSettingsAPI.unbindFileForLicense(
                fileId,
                licenseId
            )
            dispatch(getFilesBindingsToLicenses(beatId))
            return bindData
        }, dispatch)
    }

export const fetchBeatReorder =
    (beatId: number, putAfterId?: number): ThunkActionType<ActionsTypes> =>
    async (dispatch, getState) => {
        await commonAsyncHandler(async () => {
            return await BeatSettingsAPI.beatReorder(beatId, putAfterId)
        }, dispatch)
    }

export const removeBeatFileForSale =
    (fileId: number): ThunkActionType<ActionsTypes> =>
    async (dispatch, getState) => {
        await commonAsyncHandler(async () => {
            const response = await BeatSettingsAPI.removeBeatFileForSale(fileId)
            completeOperation(response, dispatch, () => {
                dispatch(myBeatsActions.deleteFile(fileId))
            })
            return response
        }, dispatch)
    }

export const uploadBeatFileForSale =
    (
        beatId: number,
        formData: any,
        onUploadProgress: (progressEvent: any) => void,
        cancelToken: any
    ): ThunkActionType<ActionsTypes> =>
    async (dispatch, getState) => {
        await commonAsyncHandler(async () => {
            const response = await BeatSettingsAPI.uploadBeatFileForSale(
                +beatId,
                formData,
                onUploadProgress,
                cancelToken
            )
            completeOperation(response, dispatch, () => {
                dispatch(getFilesBindingsToLicenses(+beatId))
            })
            return response
        }, dispatch)
    }

export const uploadBeatFileFileByLinkForSale =
    (beatId: number, url: string): ThunkActionType<ActionsTypes> =>
    async (dispatch, getState) => {
        await commonAsyncHandler(async () => {
            const response = await BeatSettingsAPI.addFileByLink({
                beatId: +beatId,
                link: url,
            })
            completeOperation(response, dispatch, () => {
                dispatch(getFilesBindingsToLicenses(+beatId))
            })
            return response
        }, dispatch)
    }

export const uploadBeat =
    (
        formData: any,
        onUploadProgress: (progressEvent: any) => void,
        cancelToken: any
    ): ThunkActionType<ActionsTypes> =>
    async (dispatch, getState) => {
        await commonAsyncHandler(async () => {
            const response = await BeatSettingsAPI.uploadBeat(
                formData,
                onUploadProgress,
                cancelToken
            )
            completeOperation(response, dispatch)
            return response
        }, dispatch)
    }

export const uploadNewBeatFile =
    (
        formData: any,
        onUploadProgress: (progressEvent: any) => void,
        beatId: number,
        cancelToken: any
    ): ThunkActionType<ActionsTypes> =>
    async (dispatch, getState) => {
        await commonAsyncHandler(async () => {
            const response = await BeatSettingsAPI.uploadNewBeatFile(
                formData,
                onUploadProgress,
                beatId,
                cancelToken
            )
            completeOperation(response, dispatch)
            return response
        }, dispatch)
    }

export const uploadBeatImage =
    (beatId: number, formData: any): ThunkActionType<ActionsTypes> =>
    async (dispatch, getState) => {
        await commonAsyncHandler(async () => {
            const response = await BeatSettingsAPI.uploadBeatImage(
                beatId,
                formData
            )
            completeOperation(response, dispatch)
            return response
        }, dispatch)
    }

export const addToCatalogNow =
    (beatId: number): ThunkActionType<ActionsTypes> =>
    async (dispatch, getState) => {
        await commonAsyncHandler(async () => {
            const response = await BeatSettingsAPI.addToCatalogNow(beatId)
            completeOperation(response, dispatch, () => {
                const { pageSize, page } = getState().myBeatsPage
                dispatch(getBeats(page, pageSize))
                dispatch(
                    authActions.setEditingEntityStatus(
                        EditingEntityStatuses.Success
                    )
                )
            })
            return response
        }, dispatch)
    }

export const deleteBeat =
    (beatId: number): ThunkActionType<ActionsTypes> =>
    async (dispatch, getState) => {
        await commonAsyncHandler(async () => {
            const response = await BeatSettingsAPI.deleteBeat(beatId)
            completeOperation(response, dispatch, () => {
                dispatch(
                    appActions.setEditingEntityStatus(
                        EditingEntityStatuses.Success
                    )
                )
                const { pageSize, page } = getState().myBeatsPage
                dispatch(getBeats(page, pageSize))
            })
            return response
        }, dispatch)
    }

export const removeFromCatalog =
    (beatId: number): ThunkActionType<ActionsTypes> =>
    async (dispatch, getState) => {
        await commonAsyncHandler(async () => {
            const response = await BeatSettingsAPI.removeFromCatalog(beatId)
            completeOperation(response, dispatch, () => {
                dispatch(
                    appActions.setEditingEntityStatus(
                        EditingEntityStatuses.Success
                    )
                )
                const { pageSize, page } = getState().myBeatsPage
                dispatch(getBeats(page, pageSize))
            })
            return response
        }, dispatch)
    }

export const downloadFileLicense =
    (
        beatId: number,
        file: FileWithSelectedLicenses
    ): ThunkActionType<ActionsTypes> =>
    async (dispatch) => {
        const checkFile = await BeatSettingsAPI.checkFile(+beatId, file.id)
        dispatch(appActions.showSnackbar('info', checkFile.data.Extra.Message))
        if (checkFile.data.Extra.OK) {
            const response = await BeatSettingsAPI.downloadFileById(file.id)
            const format = await setFormat(response.data)
            const fileName = `${file.name}.${format}`
            downloadFile(response.data, fileName)
            dispatch(
                appActions.showSnackbar('success', CONSTANTS.SUCCESS_MESSAGE)
            )
        }
    }

export default customerMyBeatsReducer
