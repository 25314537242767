import React, { FC } from 'react'
import classes from './MobProducerPage.module.scss'
import { Button } from '../../../common/elements/buttons/Button'
import { LanguageType } from '../../../../translations/ru/common'
import { useTranslation } from 'react-i18next'
import { AppSettings } from 'settings/appSettings'

type PropsType = {
    domain: string
    subdomain: string
}
export const MobProducerPage: FC<PropsType> = ({ domain, subdomain }) => {
    const isEng = AppSettings.api.isEngVersion()
    const { i18n } = useTranslation()
    const { producerPage, buttons } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType
    const baseDomain = isEng ? 'beatmakers.tv' : 'beatmaker.tv'
    const linkToProducerPage = !domain
        ? `https://${subdomain}.${baseDomain}`
        : `https://${domain}`
    return (
        <div className={classes.MobProducerPage}>
            <div className={classes.headerBox}>
                <h1 className={classes.title}>
                    {producerPage.goToProducerPage}
                </h1>
                <p className={classes.description}>
                    {producerPage.customizeInfo}
                </p>
            </div>
            <div className={classes.btnBox}>
                <a href={linkToProducerPage} target={'_blank'} rel="noreferrer">
                    <Button
                        title={buttons.GO_TO_PRODUCER_PAGE}
                        type={'submit'}
                    />
                </a>
            </div>
        </div>
    )
}
