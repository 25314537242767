import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import {
    actions as contractActions,
    deleteLicenseOption,
    getLicensesOptions,
    getUserLicenses,
    updateLicenseOption,
    updateOrderForLicenseOptions,
} from '../../../../BLL/ContractsReducer'
import { useTranslation } from 'react-i18next'
import { LanguageType } from '../../../../translations/ru/common'
import { LanguageEnum } from '../../../../BLL/enums'
import { AppState } from '../../../../BLL/Store'
import { BeatLicenseType } from '../../../../DAL/Services'
import classes from './CustomerMyLicenses.module.scss'
import { OptionsItem, UpdateLicenseOption } from '../../../../DAL/LicenseAPI'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { MaterialInput } from '../../../common/MaterialInput'
import {
    DragDropContext,
    Draggable,
    DraggableProvided,
    Droppable,
} from 'react-beautiful-dnd'
import { AppSettings } from 'settings/appSettings'
import dragIcon from '../../../assets/icons/drag-icon.svg'
import { InputField } from '../../../common/elements/textFields/Input'
import deleteIcon from '../../../assets/icons/delete.svg'
import { deviceDetect } from 'react-device-detect'

type PropsType = {
    isEng: boolean
}
export const LicenseOptions: FC<PropsType> = ({ isEng }) => {
    let { id } = useParams<{ id: string }>()
    const [isDrag, setIsDrag] = useState(false)
    const dispatch = useDispatch()
    const { i18n } = useTranslation()
    const { licenses } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    const lang = i18n.language as LanguageEnum
    const licenseOptions = useSelector(
        (state: AppState) => state.beatmakerContracts.licenseOptions
    )
    console.log('licenseOptions', licenseOptions)

    const beatLicenses = useSelector(
        (state: AppState) => state.beatmakerContracts.beatLicenses
    )
    const beatLicense = beatLicenses.find(
        (lic) => lic.id === id
    ) as BeatLicenseType
    console.log('beatLicense', beatLicenses)
    console.log('id', id)
    useEffect(() => {
        !beatLicenses.length && dispatch(getUserLicenses())
        return () => {
            dispatch(contractActions.setLicenseOptions(null))
        }
    }, [id])

    useEffect(() => {
        if (beatLicenses.length) {
            dispatch(getLicensesOptions(id, lang))
        }
    }, [beatLicenses])

    const handleDragStop = (result: any) => {
        if (result.source && result.destination && licenseOptions) {
            const options = reorder(
                licenseOptions,
                result.source.index,
                result.destination.index
            )
            const optionsIds = options.map((option) => option.point.id)
            dispatch(updateOrderForLicenseOptions(optionsIds, id))
            setIsDrag(false)
        }
    }
    const handleDragStart = () => {
        setIsDrag(true)
    }

    const reorder = (
        options: OptionsItem[],
        startIndex: number,
        endIndex: number
    ): OptionsItem[] => {
        const [removed] = options.splice(startIndex, 1)
        options.splice(endIndex, 0, removed)
        return options
    }
    return (
        <div className={classes.wrapper}>
            {!AppSettings.api.isEngVersion() && !isEng && (
                <div>{licenses.licenseOptions.titleRu}</div>
            )}
            {!AppSettings.api.isEngVersion() && isEng && (
                <div>{licenses.licenseOptions.titleEn}</div>
            )}
            <DragDropContext
                onDragEnd={handleDragStop}
                onDragStart={handleDragStart}
            >
                {licenseOptions && licenseOptions.length && beatLicense ? (
                    <Droppable droppableId={beatLicense._id} type="COLUMN">
                        {(provided, snapshot) => (
                            <div
                                className={classes.wrapperDrag}
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {licenseOptions.map((option, index) => (
                                    <Draggable
                                        draggableId={option.point.id}
                                        index={index}
                                        key={option.point.id}
                                    >
                                        {(provided, snapshot) => (
                                            <div
                                                className={classes.optionsBox}
                                                key={option.point.id}
                                                ref={provided.innerRef}
                                                style={getStyle({
                                                    draggableStyle:
                                                        provided.draggableProps
                                                            .style,
                                                    isDragging:
                                                        snapshot.isDragging,
                                                })}
                                            >
                                                <LicenseOptionItem
                                                    key={option.point.id}
                                                    option={option}
                                                    isEng={isEng}
                                                    provided={provided}
                                                    isDrag={isDrag}
                                                    isDragging={
                                                        snapshot.isDragging
                                                    }
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                            </div>
                        )}
                    </Droppable>
                ) : (
                    <p>
                        {/*{licenseOptions &&*/}
                        {/*    !licenseOptions.length &&*/}
                        {/*    'Список пуст'}*/}
                    </p>
                )}
            </DragDropContext>
        </div>
    )
}

function getStyle({ draggableStyle, isDragging }: any) {
    return {
        ...draggableStyle,
        maxWidth: isDragging && '100%',
        maxHeight: isDragging && '65px',
        opacity: isDragging && 0.6,
    }
}

export const LicenseOptionItem = React.memo(
    ({
        option,
        isEng,
        provided,
        isDragging,
        isDrag,
    }: {
        option: OptionsItem
        isEng?: boolean
        provided: DraggableProvided
        isDragging: boolean
        isDrag: boolean
    }) => {
        const [editMode, setEditMode] = useState(false)
        const [value, setValue] = useState(
            !isEng ? option.point.title : option.translate
        )
        const dispatch = useDispatch()

        const handleUpdateOption = useCallback(() => {
            const optionUpdateDto: UpdateLicenseOption = {
                optionId: option.point.id,
                title: value,
            }
            if (isEng) {
                optionUpdateDto.lang = '7004a686-5c11-4d72-aea7-63c199b0518f'
            }
            dispatch(updateLicenseOption(optionUpdateDto))
        }, [value])

        const toggleEditMode = useCallback(() => {
            if (editMode) {
                handleUpdateOption()
            }
            setEditMode(!editMode)
        }, [editMode, handleUpdateOption])

        const handleOnChange = useCallback(
            (event: ChangeEvent<HTMLInputElement>) => {
                setValue(event.currentTarget.value)
            },
            [setValue]
        )

        const handleDeleteOption = useCallback(() => {
            dispatch(deleteLicenseOption(option.point.id))
        }, [dispatch, option])

        return (
            <div className={classes.optionItem}>
                <div
                    className={classes.drag}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    {!isDrag && <img src={dragIcon} alt="Drag" />}
                </div>
                <InputField
                    value={value}
                    onBlur={toggleEditMode}
                    onChange={handleOnChange}
                    onFocus={toggleEditMode}
                />
                <button
                    className={classes.delete}
                    onClick={handleDeleteOption}
                    type={'button'}
                >
                    <img src={deleteIcon} alt="Delete" />
                </button>
            </div>
        )
    }
)
