import React from 'react'
import { Control } from 'react-hook-form'
import { NewTextField } from 'UI/common/elements/textFields/newtextField/NewTextField'
import { DiscountType } from 'DAL/DiscountAPI'
import { ControlledTextField } from 'UI/common/controls/controlledTextField/ControlledTextField'
import { FormValues } from './types'
import s from 'UI/CustomerAdmin/CustomerDiscounts/couponEdit/couponsEdit.module.scss'

type Props = {
    discounts: any
    control: Control<FormValues>
    discount: DiscountType
}
export const CouponsSaleEditForm = ({
    discounts,
    control,
    discount,
}: Props) => {
    const fields = [
        {
            label: discounts.editPopUp.discount,
            name: 'discount',
            isControlled: true,
            isRequired: true,
            isDisabled: false,
            value: discount.discountSize,
            id: 1,
        },
        {
            label: discounts.editPopUp.purchased,
            name: '',
            isControlled: false,
            isRequired: false,
            isDisabled: true,
            value: discount.closedBeatsCount,
            id: 2,
        },
        {
            label: discounts.editPopUp.amountBeats,
            name: 'beatsCount',
            isControlled: true,
            isRequired: true,
            isDisabled: false,
            value: discount.beatsCount,
            id: 3,
        },
    ]

    const mappedFields = fields.map((el) => (
        <div key={`${el.id}`} className={s.discountItem}>
            <div className={s.inputSize}>
                {el.isControlled ? (
                    <ControlledTextField
                        name={el.name}
                        control={control}
                        defaultValue={el.value}
                        label={el.label}
                        required={el.isRequired}
                        disabled={el.isDisabled}
                        type={'text'}
                    />
                ) : (
                    <NewTextField
                        defaultValue={el.value}
                        disabled={el.isDisabled}
                        label={el.label}
                    />
                )}
            </div>
        </div>
    ))

    return <div className={s.discountsInner}>{mappedFields}</div>
}
