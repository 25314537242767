import { MoneyWithdrawal, WalletTypes } from 'DAL/paymentsAPI'
import { LanguageType } from 'translations/ru/common'
import React from 'react'
import { TableType } from '../types'
import { i18n } from 'i18next'

export const getOrdersTableData = (i18n: i18n): TableType<MoneyWithdrawal> => {
    const { orders } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType
    return [
        { title: orders.tableColumns.id, field: 'id' },
        { title: orders.tableColumns.beatmakerId, field: 'beatmakerId' },
        {
            title: orders.tableColumns.fromPaymentSystem,
            field: 'fromPaymentSystem',
            render: (details) => {
                return <div>{WalletTypes[details.fromPaymentSystem]}</div>
            },
        },
        { title: orders.tableColumns.date, field: 'addedDate' },
        { title: orders.tableColumns.managerId, field: 'managerId' },
        {
            title: orders.tableColumns.paymentSystem,
            field: 'paymentSystem',
            render: (details) => {
                return <div>{WalletTypes[details.paymentSystem]}</div>
            },
        },
        { title: orders.tableColumns.walletNumber, field: 'walletNumber' },
    ]
}
