import { ThunkDispatch } from 'redux-thunk'
import { AppState, InferActionsTypes } from './Store'
import { widgetsAPI, WidgetType } from '../DAL/WidgetsAPI'
import { commonAsyncHandler, completeOperation } from './common-async-handler'
import {
    actions as appActions,
    AuthActionsTypes,
    EditingEntityStatuses,
} from './AppReducer'
import { ThunkActionType } from './types'

const initState = [] as WidgetType[]

type StateType = typeof initState

type ActionsTypes = InferActionsTypes<typeof widgetsActions> | AuthActionsTypes

type ThunkDispatchType = ThunkDispatch<
    AppState,
    unknown,
    ActionsTypes | AuthActionsTypes
>

export const widgetsReducer = (
    state: StateType = initState,
    action: ActionsTypes
) => {
    switch (action.type) {
        case 'WIDGETS_REDUCER/GET_WIDGETS':
            return action.widgets

        case 'WIDGETS_REDUCER/CREATE_WIDGET':
            return [action.widget, ...state]

        case 'WIDGETS_REDUCER/DELETE_WIDGET':
            return state.filter((w) => w._id !== action.id)

        default:
            return state
    }
}

// ACTIONS
export const widgetsActions = {
    getWidgets: (widgets: WidgetType[]) =>
        ({
            type: 'WIDGETS_REDUCER/GET_WIDGETS',
            widgets,
        } as const),
    createWidget: (widget: WidgetType) =>
        ({
            type: 'WIDGETS_REDUCER/CREATE_WIDGET',
            widget,
        } as const),
    deleteWidget: (id: string) =>
        ({
            type: 'WIDGETS_REDUCER/DELETE_WIDGET',
            id,
        } as const),
}

// THUNKS
export const getWidgetsTC =
    (): ThunkActionType<ActionsTypes> =>
    async (dispatch: ThunkDispatchType) => {
        await commonAsyncHandler(async () => {
            const res = await widgetsAPI.getWidgets()
            dispatch(widgetsActions.getWidgets(res.data))
            return res
        }, dispatch)
    }

export const createWidgetTC =
    (widget: any): ThunkActionType<ActionsTypes> =>
    async (dispatch: ThunkDispatchType) => {
        await commonAsyncHandler(async () => {
            const data = await widgetsAPI.createWidget(widget)
            completeOperation(data, dispatch, () => {
                dispatch(widgetsActions.createWidget(data.data))
                dispatch(
                    appActions.setEditingEntityStatus(
                        EditingEntityStatuses.Success
                    )
                )
            })
            return data
        }, dispatch)
    }

export const deleteWidgetTC =
    (id: string): ThunkActionType<ActionsTypes> =>
    async (dispatch: ThunkDispatchType) => {
        await commonAsyncHandler(async () => {
            const response = await widgetsAPI.deleteWidget(id)
            completeOperation(response, dispatch, () => {
                dispatch(widgetsActions.deleteWidget(id))
                dispatch(
                    appActions.setEditingEntityStatus(
                        EditingEntityStatuses.Success
                    )
                )
            })
            return response
        }, dispatch)
    }
