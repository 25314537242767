import React, { FC } from 'react'
import classes from './SubItem.module.scss'
type PropsType = {
    subsDate: string
    email: string
    nickname: string
    subsPage: string
}

export const SubItem: FC<PropsType> = ({
    subsDate,
    email,
    nickname,
    subsPage,
}) => {
    return (
        <div className={classes.SubItem}>
            <div className={classes.item}>
                <span className={classes.title}>Subscription Date:</span>
                <span className={classes.value}>{subsDate}</span>
            </div>
            <div className={classes.item}>
                <span className={classes.title}>Email:</span>
                <span className={classes.value}>{email}</span>
            </div>
            <div className={classes.item}>
                <span className={classes.title}>Nickname:</span>
                <span className={classes.beatmaker}>{nickname}</span>
            </div>
            <div className={classes.item}>
                <span className={classes.title}>Subscription Page:</span>
                <span className={classes.value}>{subsPage}</span>
            </div>
        </div>
    )
}
