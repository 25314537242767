import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { AppSettings } from 'settings/appSettings'
import s from 'UI/common/progressBars/spinner.module.scss'

const CircularIndeterminate = () => (
    <div className={s.wrapper}>
        <CircularProgress
            color="secondary"
            style={{ color: AppSettings.api.getBaseColor() }}
        />
    </div>
)

export default CircularIndeterminate
