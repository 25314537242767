import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'UI/common/hooks/redux-hooks'
import { fetchedHistoryCoins } from 'BLL/CustomerCoinsReducer'
import Table from '../../../common/table/Table'
import { CoinsHistoryType } from 'DAL/CoinsAPI'
import { CoinsItem } from '../../Mobile/MobCoinsPage/CoinsItem/CoinsItem'
import { CustomMobileView } from 'UI/common/mobileView/CustomMobileView'
import { CustomBrowserView } from 'UI/common/browserView/CustomBrowserView'
import { selectCoinsHistory } from 'BLL/selectors/coins-selectors/selectCoins'
import { getCoinsHistoryTableData } from 'UI/common/table/tableData/GetCoinsHistoryTableData'

export const CoinsHistory = () => {
    const coinsHistory = useSelector(selectCoinsHistory)
    const dispatch = useDispatch()

    const { i18n } = useTranslation()

    useEffect(() => {
        dispatch(fetchedHistoryCoins())
    }, [])

    return (
        <div>
            <CustomBrowserView>
                <Table<CoinsHistoryType>
                    fields={getCoinsHistoryTableData(i18n)}
                    items={coinsHistory}
                    title={''}
                    toolbar={false}
                />
            </CustomBrowserView>
            <CustomMobileView>
                {coinsHistory.map((coinsHistoryItem) => (
                    <CoinsItem
                        key={coinsHistoryItem.id}
                        coinsHistory={coinsHistoryItem}
                    />
                ))}
            </CustomMobileView>
        </div>
    )
}
