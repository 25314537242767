import { axiosCore } from './createAxios'
import { BaseResponse } from '../BLL/types'

export const NotificationsAPI = {
    prefixURL: 'Auth/EmailSubscriptions',

    getSubscriptionsNotify() {
        return axiosCore
            .get<SubscriptionNotifyType[]>(`${this.prefixURL}/GetAll`)
            .then((r) => r.data)
    },

    updateSubscriptionsNotify(subscriptions: SubscriptionNotifyType[]) {
        return axiosCore
            .put<BaseResponse>(`${this.prefixURL}/Update`, subscriptions)
            .then((r) => r.data)
    },
}

export type SubscriptionNotifyType = {
    subsriptionType: SubscriptionTypes
    sendMessageAfter: SendMessageAfterEnum
}

export enum SubscriptionTypes {
    NewPrivateMessages = 0,
    BeatOrderNewComments = 1,
    NewBeatComments = 2,
    NewArticleComments = 3,
    // NewTrackComment = 4,
    // BeatUploadedToVK = 5,
    // BuyCoins = 6,
    // TrackRated = 7,
    // InfoProductNewComment = 8,
    ImportantMessages = 100,
    // EmailDirectMessageService = 101,
}

export enum SendMessageAfterEnum {
    Never = 0, //никогда
    Day1 = 2, //раз в день
    Immediately = 5, //моментально
}
