import {
    BaseResponse,
    BeatmakerContractsType,
    ContractsLangEnum,
    NullableType,
    ResultCodesEnum,
    ThunkActionType,
} from './types'
import { AppState, InferActionsTypes } from './Store'
import contractsAPI, { ContractLicenseType } from 'DAL/ContractsAPI'
import { BeatLicenseType } from 'DAL/Services'
import { STATUSES } from './SupportingFile'
import { commonAsyncHandler, completeOperation } from './common-async-handler'
import {
    actions as appActions,
    AuthActionsTypes,
    EditingEntityStatuses,
} from './AppReducer'
import { saveAs } from 'file-saver'
import {
    LicenseAPI,
    LicenseModel,
    OptionsItem,
    UpdateLicenseOption,
} from 'DAL/LicenseAPI'
import { LanguageEnum } from './enums'

const initState = {
    contracts: [] as BeatmakerContractsType[],
    beatLicenses: [] as BeatLicenseType[],
    editingEntityStatus: STATUSES.NOT_INIT,
    editingContract: null as BeatmakerContractsType | null,
    settingsContract: {} as ContractLicenseType,
    licenseOptions: null as OptionsItem[] | null,
    editLicense: null as NullableType<BeatLicenseType>,
}

type IState = typeof initState
export type ActionsTypes = InferActionsTypes<typeof actions> | AuthActionsTypes

const ContractsReducer = (state = initState, action: ActionsTypes): IState => {
    switch (action.type) {
        case 'CONTRACTS_REDUCER/SET_CONTRACTS':
            return {
                ...state,
                editLicense: state.editLicense && {
                    ...state.editLicense,
                    contract: true,
                },
            }
        case 'CONTRACTS_REDUCER/SET_BEAT_LICENSES':
            return {
                ...state,
                beatLicenses: action.beatLicenses,
            }
        case 'CONTRACTS_REDUCER/SET_EDITING_CONTRACTS':
            return {
                ...state,
                editingContract: action.contract,
            }
        case 'CONTRACTS_REDUCER/SET_SETTINGS_CONTRACT':
            return {
                ...state,
                settingsContract: action.settings,
            }
        case 'CONTRACTS_REDUCER/SET_LICENSE_OPTIONS':
            return {
                ...state,
                licenseOptions: action.options,
            }
        case 'CONTRACTS_REDUCER/SET_LICENSE_OPTION':
            return {
                ...state,
                licenseOptions: [...state.licenseOptions, action.option],
            }
        case 'CONTRACTS_REDUCER/SET_LICENSE_EDIT':
            return {
                ...state,
                editLicense: action.editLicense,
            }
        case 'CONTRACTS_REDUCER/ADD_LICENSE':
            return {
                ...state,
                beatLicenses: [...state.beatLicenses, action.license],
            }
        default:
            return state
    }
}

export const actions = {
    setContracts: (licenseId: string) =>
        ({
            type: 'CONTRACTS_REDUCER/SET_CONTRACTS',
            licenseId,
        } as const),
    setBeatLicenses: (beatLicenses: BeatLicenseType[]) =>
        ({
            type: 'CONTRACTS_REDUCER/SET_BEAT_LICENSES',
            beatLicenses,
        } as const),
    setEditingEntityStatus: (status: string) =>
        ({
            type: 'CONTRACTS_REDUCER/SET_EDITING_STATUS',
            status,
        } as const),
    setEditingContract: (contract: NullableType<BeatmakerContractsType>) =>
        ({
            type: 'CONTRACTS_REDUCER/SET_EDITING_CONTRACTS',
            contract,
        } as const),
    setSettingsContract: (settings: ContractLicenseType) =>
        ({
            type: 'CONTRACTS_REDUCER/SET_SETTINGS_CONTRACT',
            settings,
        } as const),
    setLicenseOptions: (options: OptionsItem[] | null) =>
        ({
            type: 'CONTRACTS_REDUCER/SET_LICENSE_OPTIONS',
            options,
        } as const),
    setLicenseOption: (option: OptionsItem) =>
        ({
            type: 'CONTRACTS_REDUCER/SET_LICENSE_OPTION',
            option,
        } as const),
    setLicenseEdit: (editLicense: NullableType<BeatLicenseType>) =>
        ({
            type: 'CONTRACTS_REDUCER/SET_LICENSE_EDIT',
            editLicense,
        } as const),
    addLicense: (license: BeatLicenseType) =>
        ({
            type: 'CONTRACTS_REDUCER/ADD_LICENSE',
            license,
        } as const),
}

export const getUserLicenses =
    (): ThunkActionType<ActionsTypes> => async (dispatch) => {
        await commonAsyncHandler(async () => {
            const userLicenses = await LicenseAPI.getLicenses()
            const response = await contractsAPI.getContracts()
            if (response.resultCode === ResultCodesEnum.Success) {
                let newUserLicenses: BeatLicenseType[] = userLicenses.map(
                    (uL) => {
                        return {
                            ...uL,
                            contract: response.data.some(
                                (c) => c.licenseId === uL.id
                            ),
                        }
                    }
                )
                dispatch(actions.setBeatLicenses(newUserLicenses))
            } else {
                dispatch(appActions.showSnackbar('error', response.message))
            }
            return response
        }, dispatch)
    }
export const getLicensesOptions =
    (licenseId: string, lang?: LanguageEnum): ThunkActionType<ActionsTypes> =>
    async (dispatch) => {
        await commonAsyncHandler(async () => {
            const response = await LicenseAPI.getLicenseOption(licenseId, lang)
            console.log('getLicensesOptions', response)
            dispatch(actions.setLicenseOptions(response.options))
            return response
        }, dispatch)
    }

export const addContracts =
    (lang: LanguageEnum, licenseId: string): ThunkActionType<ActionsTypes> =>
    async (dispatch) => {
        await commonAsyncHandler(async () => {
            const response = await contractsAPI.addContracts(lang, licenseId)
            completeOperation(response, dispatch, () => {
                dispatch(actions.setContracts(licenseId))
                dispatch(getContractsById(licenseId, lang))
            })
            return response
        }, dispatch)
    }

export const getContractsById =
    (licenseId: string, lang: LanguageEnum): ThunkActionType<ActionsTypes> =>
    async (dispatch) => {
        await commonAsyncHandler(async () => {
            const response = await contractsAPI.getContractById(licenseId, lang)
            if (response.resultCode === ResultCodesEnum.Success) {
                if (response.data) {
                    dispatch(actions.setEditingContract(response.data))
                } else {
                    dispatch(addContracts(lang, licenseId))
                }
            }
            return response
        }, dispatch)
    }

export const updateContract =
    (contract: BeatmakerContractsType): ThunkActionType<ActionsTypes> =>
    async (dispatch) => {
        await commonAsyncHandler(async () => {
            const response = await contractsAPI.updateContract(contract)
            completeOperation(response, dispatch)
            return response
        }, dispatch)
    }

export const getSettingsContract =
    (): ThunkActionType<ActionsTypes> => async (dispatch) => {
        await commonAsyncHandler(async () => {
            const response = await contractsAPI.getSettingsContract()
            dispatch(actions.setSettingsContract(response.data))
            return response
        }, dispatch)
    }

export const getBaseContract =
    (lang: LanguageEnum, licenseId: string): ThunkActionType<ActionsTypes> =>
    async (dispatch) => {
        await commonAsyncHandler(async () => {
            const contract = await contractsAPI.getContractById(licenseId, lang)
            if (!contract) {
                const response = await contractsAPI.getBaseContract(
                    lang,
                    licenseId
                )
                dispatch(actions.setEditingContract(response))
                return response
            }
        }, dispatch)
    }

export const getExampleContract =
    (html: string, lang: string): ThunkActionType<ActionsTypes> =>
    async (dispatch) => {
        await commonAsyncHandler(async () => {
            // todo: move pdf producing to the server
            const arrayBuffer = await contractsAPI.getExampleContract(
                html,
                lang
            )
            const blob = new Blob([arrayBuffer], { type: 'application/pdf' })
            saveAs(blob, 'contract.pdf')
            return arrayBuffer
        }, dispatch)
    }

export const createLicenseOption =
    (licenseId: string): ThunkActionType<ActionsTypes> =>
    async (dispatch) => {
        await commonAsyncHandler(async () => {
            const licenseOption = await LicenseAPI.createLicenseOption(
                licenseId
            )
            dispatch(
                actions.setLicenseOption({
                    point: licenseOption.data.item,
                    translate: '',
                })
            )
            console.log('createLicenseOption', licenseOption)
            // dispatch(getLicensesOptions(licenseId))
            return licenseOption
        }, dispatch)
    }

export const getLicenseById =
    (licenseId: string): ThunkActionType<ActionsTypes> =>
    async (dispatch) => {
        return commonAsyncHandler(async () => {
            const license = await LicenseAPI.getLicense(licenseId)
            const response = await contractsAPI.getContracts()
            let newUserLicense: BeatLicenseType = {
                ...license,
                contract: response.data.some((c) => c.licenseId === license.id),
            }
            license && dispatch(actions.setLicenseEdit(newUserLicense))
        }, dispatch)
    }
export const createLicense =
    (createLicenseDto: LicenseModel): ThunkActionType<ActionsTypes> =>
    async (dispatch) => {
        return commonAsyncHandler(async () => {
            const response = await LicenseAPI.createLicense(createLicenseDto)
            completeOperation(response, dispatch, async () => {
                dispatch(getLicenseById(response.data.itemId))
                const license = await LicenseAPI.getLicense(
                    response.data.itemId
                )
                const res = await contractsAPI.getContracts()
                let newUserLicense: BeatLicenseType = {
                    ...license,
                    contract: res.data.some((c) => c.licenseId === license.id),
                }
                license && dispatch(actions.addLicense(newUserLicense))
                dispatch(
                    appActions.setEditingEntityStatus(
                        EditingEntityStatuses.Success
                    )
                )
            })
            return response as BaseResponse<{ itemId: string }>
        }, dispatch)
    }

export const deleteLicense =
    (licenseId: string): ThunkActionType<ActionsTypes> =>
    async (dispatch, getState) => {
        await commonAsyncHandler(async () => {
            const response = await LicenseAPI.deleteLicense(licenseId)
            completeOperation(response, dispatch, () => {
                const licenses =
                    getState().beatmakerContracts.beatLicenses.filter(
                        (l) => l._id !== licenseId
                    )
                dispatch(actions.setBeatLicenses(licenses))
                dispatch(
                    appActions.setEditingEntityStatus(
                        EditingEntityStatuses.Success
                    )
                )
            })
            return response
        }, dispatch)
    }
export const updateLicense =
    (updateLicenseDto: LicenseModel): ThunkActionType<ActionsTypes> =>
    async (dispatch) => {
        await commonAsyncHandler(async () => {
            const response = await LicenseAPI.updateLicense(updateLicenseDto)
            completeOperation(response, dispatch, () => {
                dispatch(getUserLicenses())
                dispatch(
                    appActions.setEditingEntityStatus(
                        EditingEntityStatuses.Success
                    )
                )
            })
            return response
        }, dispatch)
    }

export const updateLicenseOption =
    (updateLicenseOption: UpdateLicenseOption): ThunkActionType<ActionsTypes> =>
    async (dispatch, getState) => {
        await commonAsyncHandler(async () => {
            const response = await LicenseAPI.updateLicenseOption(
                updateLicenseOption
            )
            // await completeOperation(response, dispatch, getUserLicenses)
            return response
        }, dispatch)
    }

export const deleteLicenseOption =
    (optionId: string): ThunkActionType<ActionsTypes> =>
    async (dispatch, getState) => {
        await commonAsyncHandler(async () => {
            const response = await LicenseAPI.deleteLicenseOption(optionId)
            completeOperation(response, dispatch, () => {
                const options = getState().beatmakerContracts.licenseOptions
                if (options) {
                    dispatch(
                        actions.setLicenseOptions(
                            options.filter(
                                (option) => option.point.id !== optionId
                            )
                        )
                    )
                }
            })
            return response
        }, dispatch)
    }

export const updateOrderForLicenses =
    (licenseIds: string[]): ThunkActionType<ActionsTypes> =>
    async (dispatch) => {
        await commonAsyncHandler(async () => {
            const response = await LicenseAPI.updateOrderForLicenses(licenseIds)
            // completeOperation(response, dispatch, () => {
            //     dispatch(getUserLicenses())
            // })
            return response
        }, dispatch)
    }

export const updateOrderForLicenseOptions =
    (orderIds: string[], licenseId: string): ThunkActionType<ActionsTypes> =>
    async (dispatch) => {
        await commonAsyncHandler(async () => {
            const response = await LicenseAPI.updateOrderForLicenseOptions(
                orderIds,
                licenseId
            )
            // await completeOperation(response, dispatch, getUserLicenses);
            return response
        }, dispatch)
    }

// const completeOperation = async <T = null>(
//     response: BaseResponse<T>,
//     dispatch: Dispatch,
//     successAction?: any
// ) => {
//     if (response.resultCode === ResultCodesEnum.Success) {
//         dispatch(appActions.showSnackbar('success', CONSTANTS.SUCCESS_MESSAGE))
//         successAction && (await dispatch(successAction()))
//     } else {
//         dispatch(
//             appActions.showSnackbar(
//                 'error',
//                 response.messages?.length
//                     ? response.messages[0]
//                     : CONSTANTS.ERROR_MESSAGE
//             )
//         )
//     }
// }

export const getContractsSelector = (
    state: AppState,
    lang: ContractsLangEnum
): BeatmakerContractsType[] => {
    return state.beatmakerContracts.contracts.filter((c) => c.lang === lang)
}

export default ContractsReducer
