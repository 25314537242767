import React from 'react'
import EmailDetailTable from './EmailDetailTable'
import StatisticsTabs from './StatisticsTabs'
import { SubscriptionPlansEnum } from 'BLL/enums'
import { SubscriptionType } from 'BLL/types'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'
import Table from '../../common/table/Table'
import { getAdminSubscriptionsTableData } from 'UI/common/table/tableData/GetAdminSubscriptionsTableData'
import s from './subscriptions.module.css'

interface Props {
    subscriptions: SubscriptionType[]
    currentPage: number
    pageSize: number
    totalSubscriptions: number
    handleChangePageSize: (event: any) => void
    handleChangePage: (page: number) => void
    handleChangePlan: (plan: SubscriptionPlansEnum | null) => void
    handleGetEmailsHistory: (
        subscriptionId: string,
        userId: number,
        templateKey: string
    ) => void
}

const SubscriptionTable = ({
    subscriptions,
    pageSize,
    totalSubscriptions,
    handleChangePage,
    handleChangePageSize,
    currentPage,
    handleChangePlan,
    handleGetEmailsHistory,
}: Props) => {
    const { i18n } = useTranslation()
    const { adminSubscription } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    const detailPanel = [
        {
            tooltip: adminSubscription.showMailing,
            render: (subscription: SubscriptionType) => {
                return (
                    <EmailDetailTable
                        subscription={subscription}
                        handleGetEmailsHistory={handleGetEmailsHistory}
                    />
                )
            },
        },
    ]
    return (
        <div className={s.root}>
            <div className={s.tableWrapper}>
                <div className={s.tableWrapper}>
                    <div
                        style={{
                            padding: '0px 0px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <StatisticsTabs handleChangePlan={handleChangePlan} />
                    </div>
                    <Table<SubscriptionType>
                        items={subscriptions}
                        fields={getAdminSubscriptionsTableData(i18n)}
                        title={''}
                        page={currentPage}
                        pageSize={pageSize}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangePageSize}
                        totalCount={totalSubscriptions}
                        detailPanel={detailPanel}
                    />
                </div>
            </div>
        </div>
    )
}

export default SubscriptionTable
