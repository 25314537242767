import React, { FC } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { useTranslation } from 'react-i18next'
import { LanguageType } from '../../../../translations/ru/common'
import { CustomButton } from '../../../common/elements/CustomButton'
import { FormControl, InputLabel } from '@material-ui/core'
import { MaterialInput } from '../../../common/MaterialInput'
import classes from './create-dialog.module.css'
import { SubmitHandler, useForm } from 'react-hook-form'
import { createEmailTemplate } from '../../../../BLL/AdminTemplatesEmailReducer'
import { useDispatch } from 'react-redux'
import {
    actions as appActions,
    EditingEntityStatuses,
} from '../../../../BLL/AppReducer'
import { useSelector } from '../../../common/hooks/redux-hooks'

type PropsType = {}

type FormValues = {
    name: string
    templateKey: string
}

export const CreateTemplateDialog: FC<PropsType> = () => {
    const [open, setOpen] = React.useState(false)
    const dispatch = useDispatch()
    const entityStatus = useSelector(
        (state) => state.appReducer.editingEntityStatus
    )

    const { handleSubmit, register } = useForm<FormValues>()

    const { i18n } = useTranslation()
    const { adminEditTemplate, buttons } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    const handleOpen = () => {
        dispatch(
            appActions.setEditingEntityStatus(EditingEntityStatuses.InProgress)
        )
        setOpen(true)
    }

    const handleClose = () => {
        dispatch(appActions.setEditingEntityStatus(EditingEntityStatuses.None))
        setOpen(false)
    }

    const onSubmit: SubmitHandler<FormValues> = (data) => {
        dispatch(createEmailTemplate(data))
    }

    if (entityStatus === EditingEntityStatuses.Success) {
        handleClose()
    }

    return (
        <div>
            <CustomButton
                title={buttons.ADD}
                variant="contained"
                onClick={handleOpen}
            />
            <form onSubmit={handleSubmit(onSubmit)}>
                <Dialog
                    open={open}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent className={classes.content}>
                        <FormControl className={classes.fromControl}>
                            <InputLabel
                                shrink
                                htmlFor={adminEditTemplate.tableColumns.name}
                            >
                                {adminEditTemplate.tableColumns.name}*
                            </InputLabel>
                            <MaterialInput
                                id={adminEditTemplate.tableColumns.name}
                                {...register('name', { required: true })}
                                className={classes.field}
                                // error={!!errors.name}
                            />
                        </FormControl>
                        <FormControl className={classes.fromControl}>
                            <InputLabel
                                shrink
                                htmlFor={
                                    adminEditTemplate.tableColumns.templateKey
                                }
                            >
                                {adminEditTemplate.tableColumns.templateKey}*
                            </InputLabel>
                            <MaterialInput
                                id={adminEditTemplate.tableColumns.templateKey}
                                {...register('templateKey', { required: true })}
                                className={classes.field}
                                // error={!!errors.templateKey}
                            />
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <CustomButton
                            title={buttons.CANCEL}
                            variant="contained"
                            onClick={handleClose}
                        />
                        <CustomButton
                            title={buttons.SAVE}
                            variant="contained"
                            onClick={handleSubmit(onSubmit)}
                        />
                    </DialogActions>
                </Dialog>
            </form>
        </div>
    )
}
