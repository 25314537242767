import {
    MoodsType,
    shopAPI,
    StylesType,
    UserDetailsType,
} from '../DAL/Services'
import { InferActionsTypes } from './Store'
import { RolesTypeEnum } from './enums'
import { settingsAPI, SettingsPromoServerType } from '../DAL/SettingsAPI'
import { VariantType } from 'notistack'
import { getExpert } from './CustomerBeatsCompilationReducer'
import { commonAsyncHandler } from './common-async-handler'
import { ThunkActionType } from './types'
import { CountryType, ReferenceAPI } from '../DAL/ReferenceAPI'
import { AppSettings } from 'settings/appSettings'

export enum EditingEntityStatuses {
    None,
    InProgress,
    Success,
    Error,
}

export enum RequestStatuses {
    None = 0,
    InProgress = 1,
    Success = 2,
    Error = 3,
}

let initialState = {
    isOwner: false,
    isOpen: false,
    isBeatmaker: false,
    roles: [] as Array<RolesTypeEnum>,
    isInit: false,
    requestStatus: RequestStatuses.None,
    userId: null as number | null,
    errorMessage: '',
    snackbar: {
        variant: 'default' as VariantType,
        message: null as string | null,
    },
    promoSettings: {
        compilations: {
            compilationSize: 8,
            timeOutDays: 1,
        },
    } as SettingsPromoServerType,
    editingEntityStatus: EditingEntityStatuses.None,
    moods: [] as MoodsType[],
    styles: [] as StylesType[],
    userDetails: null as null | UserDetailsType,
    countries: [] as CountryType[],
}

type IAuthState = typeof initialState
export type AuthActionsTypes = InferActionsTypes<typeof actions>

const AppReducer = (
    state = initialState,
    action: AuthActionsTypes
): IAuthState => {
    switch (action.type) {
        case 'CustomerAdminReducer/SET_IS_INIT':
            return {
                ...state,
                isInit: true,
            }
        case 'CustomerAdminReducer/SET_ROLES':
            return {
                ...state,
                roles: action.roles,
            }
        case 'CustomerAdminReducer/SET_PROMO_SETTINGS':
            return {
                ...state,
                promoSettings: action.promoServerSettings,
            }
        case 'CustomerAdminReducer/SHOW_SNACKBAR':
            return {
                ...state,
                snackbar: {
                    variant: action.variant,
                    message: action.message,
                },
            }
        case 'CustomerAdminReducer/SET_EDITING_ENTITY_STATUS':
            return {
                ...state,
                editingEntityStatus: action.status,
            }
        case 'CustomerAdminReducer/SET_REQUEST_STATUS':
            return {
                ...state,
                requestStatus: action.status,
            }
        case 'CustomerAdminReducer/SET_USER_ID':
            return {
                ...state,
                userId: action.userId,
            }
        case 'CustomerAdminReducer/SET_ERROR_MESSAGE':
            return {
                ...state,
                errorMessage: action.errorMessage,
            }
        case 'CustomerAdminReducer/SET_IS_OWNER':
            return {
                ...state,
                isOwner: action.isOwner,
            }
        case 'CustomerAdminReducer/SET_IS_BEATMAKER':
            return {
                ...state,
                isBeatmaker: action.isBeatmaker,
            }
        case 'CustomerAdminReducer/SET_REFERENCE_BOOKS':
            return {
                ...state,
                ...action.referenceBooks,
            }
        case 'CustomerAdminReducer/SET_USER_DETAILS':
            return {
                ...state,
                userDetails: action.userDetails,
            }
        case 'CustomerAdminReducer/SET_COUNTRIES':
            return {
                ...state,
                countries: action.countries,
            }
        default:
            return state
    }
}

export const actions = {
    setRoles: (roles: Array<RolesTypeEnum>) =>
        ({ type: 'CustomerAdminReducer/SET_ROLES', roles } as const),
    setIsOwner: (isOwner: boolean) =>
        ({ type: 'CustomerAdminReducer/SET_IS_OWNER', isOwner } as const),
    setErrorMessage: (errorMessage: string) =>
        ({
            type: 'CustomerAdminReducer/SET_ERROR_MESSAGE',
            errorMessage,
        } as const),
    showSnackbar: (variant: VariantType, message: string) =>
        ({
            type: 'CustomerAdminReducer/SHOW_SNACKBAR',
            variant,
            message,
        } as const),
    setIsInit: () => ({ type: 'CustomerAdminReducer/SET_IS_INIT' } as const),
    setUserId: (userId: number) =>
        ({ type: 'CustomerAdminReducer/SET_USER_ID', userId } as const),
    setRequestStatus: (status: RequestStatuses) =>
        ({ type: 'CustomerAdminReducer/SET_REQUEST_STATUS', status } as const),
    setSettings: (promoServerSettings: SettingsPromoServerType) =>
        ({
            type: 'CustomerAdminReducer/SET_PROMO_SETTINGS',
            promoServerSettings,
        } as const),
    setEditingEntityStatus: (status: EditingEntityStatuses) =>
        ({
            type: 'CustomerAdminReducer/SET_EDITING_ENTITY_STATUS',
            status,
        } as const),
    setIsBeatmaker: (isBeatmaker: boolean) =>
        ({
            type: 'CustomerAdminReducer/SET_IS_BEATMAKER',
            isBeatmaker,
        } as const),
    setReferenceBooks: (referenceBooks: {
        moods: MoodsType[]
        styles: StylesType[]
    }) =>
        ({
            type: 'CustomerAdminReducer/SET_REFERENCE_BOOKS',
            referenceBooks,
        } as const),
    setUserDetails: (userDetails: UserDetailsType) =>
        ({
            type: 'CustomerAdminReducer/SET_USER_DETAILS',
            userDetails,
        } as const),
    setCountries: (countries: CountryType[]) =>
        ({
            type: 'CustomerAdminReducer/SET_COUNTRIES',
            countries,
        } as const),
}

export const getUserDetails =
    (): ThunkActionType<AuthActionsTypes> => async (dispatch, getState) => {
        const userDetails: UserDetailsType = await shopAPI.getUserDetails()
        if (
            !AppSettings.env.isDevelopment() &&
            AppSettings.api.isEngVersion() !== userDetails.engMode
        ) {
            window.location.href = AppSettings.urls.REDIRECT_URL
            return
        }

        const roles = getState().appReducer.roles
        dispatch(actions.setUserDetails(userDetails))
        dispatch(actions.setRoles([...roles, ...userDetails.roles]))

        // dispatch(actions.setRoles([]))
        dispatch(actions.setUserId(userDetails.userId))
        if (userDetails.roles.indexOf(RolesTypeEnum.BEATMAKER) > -1) {
            dispatch(getExpert())
            dispatch(actions.setIsBeatmaker(true))
            // dispatch(actions.setIsBeatmaker(false))
        }
        // !userDetails.beatmakerInfo.isBeatmakerAccountExpired && dispatch(actions.setIsBeatmaker(true))

        // const response = await shopAPI.me();
        // dispatch(actions.setIsOwner(response.isOwner))

        // dispatch(actions.setRoles([RolesTypeEnum.ADMINISTRATOR]))

        const language = localStorage.getItem('i18nextLng')
        const isEng = language === 'en'
        await dispatch(getReferenceBooks(isEng))
        dispatch(actions.setIsInit())
    }

export const authMeShop =
    (): ThunkActionType<AuthActionsTypes> => async (dispatch) => {
        await commonAsyncHandler(async () => {
            const response = await shopAPI.me()
            dispatch(actions.setIsOwner(response.isOwner))
            // dispatch(actions.setIsOwner(false))
            return response
        }, dispatch)
    }

export const getSettings =
    (): ThunkActionType<AuthActionsTypes> => async (dispatch) => {
        const promoServerSettings = await settingsAPI.getSettingsPromoServer()
        dispatch(actions.setSettings(promoServerSettings))
    }

export const getCountries =
    (): ThunkActionType<AuthActionsTypes> => async (dispatch) => {
        const countries = await ReferenceAPI.getCountries()
        dispatch(actions.setCountries(countries))
    }

export const init =
    (): ThunkActionType<AuthActionsTypes> => async (dispatch) => {
        /* let token = await authManager.ensureToken()
    if (!token) {
        document.location.href = AppSettings.urls.loginPageUrl
    } else {*/
        dispatch(getUserDetails())
        dispatch(getSettings())
        dispatch(authMeShop())
        dispatch(getCountries())
        /*}*/
    }
export const getReferenceBooks =
    (eng: boolean): ThunkActionType<AuthActionsTypes> =>
    async (dispatch) => {
        const moodsData = await shopAPI.getMoods(eng)
        const stylesData = await shopAPI.getStyles(eng)
        dispatch(
            actions.setReferenceBooks({
                moods: moodsData.items,
                styles: stylesData.items,
            })
        )
    }

export default AppReducer
