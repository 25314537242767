import React, { ChangeEvent, useState } from 'react'
import s from './SoundRange.module.scss'
import soundIcon from '../../../assets/images/player-icons/sound-icon.svg'
import { PlayerLogic } from '../../../Domain/PlayerLogic'
import { AppSettings } from 'settings/appSettings'

type PropsType = {
    player: PlayerLogic
}

export const SoundRange = (props: PropsType) => {
    const [volume, setVolume] = useState(50)

    const changeVolumeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        let trackVolume = event.currentTarget.valueAsNumber
        setVolume(trackVolume)
        props.player.changeVolume(trackVolume / 100)
    }

    return (
        <div className={s.sound}>
            <img className={s.soundIcon} src={soundIcon} alt="sound icon" />
            <input
                className={s.soundRange}
                type="range"
                min="1"
                max="100"
                value={volume}
                onChange={changeVolumeHandler}
            />
        </div>
    )
}
