import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { MyBeatType } from 'DAL/BeatSettingsAPI'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'
import { useSelector } from 'UI/common/hooks/redux-hooks'
import { usePlayer } from 'Player/Hooks/Hooks'
import Tooltip from '@material-ui/core/Tooltip'
import { PlayerLogicTrackType } from 'Player/Domain/PlayerLogic'
import { BeatLabeledDate } from 'UI/common/components/BeatAddedDate/BeatAddedDate'
import { CustomerRM } from 'UI/routers/RouterManager'
import { CustomMobileView } from 'UI/common/mobileView/CustomMobileView'
import { CustomBrowserView } from 'UI/common/browserView/CustomBrowserView'
import { useDeviceDetect } from 'UI/common/hooks/useDeviceDetect'
import DragIcon from 'UI/assets/icons/drag-icon.svg'
import RestoreIcon from 'UI/assets/icons/restore.svg'
import DeleteIcon from 'UI/assets/icons/delete.svg'
import dotsMenu from 'UI/assets/icons/dotsMenu.svg'
import s from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/beatItem/beatItem.module.scss'
import { MobileMenuItem } from './mobileMenu/MobileMenuItem'
import { MobArchiveItem } from 'UI/CustomerAdmin/Mobile/MobMyBeatsPage/Archive/MobArchiveItem/MobArchiveItem'
import { BeatCover } from 'UI/common/components/BeatCover/BeatCover'

type Props = {
    beat: MyBeatType
}

export const ArchiveItem = ({ beat }: Props) => {
    const artist = useSelector(
        (state) => state.appReducer.userDetails?.userName
    )

    const { i18n } = useTranslation()
    const { customerBeatsPublication, buttons } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    let playerBeatDTO: PlayerLogicTrackType = {
        id: beat.id,
        artist: artist ? artist : '',
        title: beat.title,
        format: beat.format,
        url: beat.playUrl as string,
        type: 'beat',
        images: beat.images,
    }

    const [player, isPlayingMe] = usePlayer(false, playerBeatDTO)

    const [show, setShow] = useState(false)

    const playHandler = () => {
        if (isPlayingMe) {
            player.pause()
        } else {
            player.play(playerBeatDTO)
        }
    }

    const isMobile = useDeviceDetect()

    return (
        <div className={isMobile ? '' : s.card}>
            <div className={s.beatInfo}>
                <CustomBrowserView>
                    <div className={s.dragElement}>
                        <img
                            src={DragIcon}
                            alt={'drag'}
                            className={s.disabled}
                        />
                    </div>
                    <BeatCover
                        isPlayingMe={isPlayingMe}
                        playHandler={playHandler}
                        beat={beat}
                    />
                    <div>
                        <div className={s.beatTitle}>{beat.title}</div>
                        <div className={s.publicationInfo}>
                            <BeatLabeledDate
                                title={
                                    customerBeatsPublication.tableColumns
                                        .publishedDate
                                }
                                date={beat.addedToCatalogDate}
                            />
                        </div>
                    </div>
                </CustomBrowserView>
                <CustomMobileView>
                    <MobArchiveItem
                        beat={beat}
                        playHandler={playHandler}
                        isPlayingMe={isPlayingMe}
                    />
                </CustomMobileView>
            </div>
            <CustomBrowserView>
                <div className={s.beatChart}>
                    <div>{beat.chartPosition ?? '—'}</div>
                    <div>{beat.downloads}</div>
                    <div>{beat.plays}</div>
                </div>
            </CustomBrowserView>
            <CustomBrowserView>
                <div className={s.information}>
                    <div className={s.actions}>
                        <Tooltip title={buttons.RESTORE}>
                            <NavLink
                                to={CustomerRM.myBeats.getUrl.archiveBeats.restore(
                                    beat.id
                                )}
                            >
                                <img
                                    className={s.icons}
                                    src={RestoreIcon}
                                    alt={'restore'}
                                />
                            </NavLink>
                        </Tooltip>
                        <Tooltip title={buttons.DELETE}>
                            <NavLink
                                to={CustomerRM.myBeats.getUrl.archiveBeats.delete(
                                    beat.id
                                )}
                            >
                                <img
                                    className={s.icons}
                                    src={DeleteIcon}
                                    alt={'delete'}
                                />
                            </NavLink>
                        </Tooltip>
                    </div>
                    <div className={s.smallDesktopViewActions}>
                        <button onClick={() => setShow(!show)}>
                            <img src={dotsMenu} alt="Open Menu" />
                        </button>
                        {show && <MobileMenuItem beat={beat} />}
                    </div>
                </div>
            </CustomBrowserView>
        </div>
    )
}
