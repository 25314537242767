import { FreeBeatType } from 'DAL/BeatSettingsAPI'
import React, { useCallback } from 'react'
import { CustomBrowserView } from 'UI/common/browserView/CustomBrowserView'
import { Pagination } from 'UI/common/elements/pagination/Pagination'
import { TableHeader } from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/draggableTable/TableHeader'
import { FreeBeatsTableItem } from 'UI/CustomerAdmin/FreeBeatsPage/freeBeatsTableItem/FreeBeatsTableItem'

type Props = {
    totalCount: number
    pageSize: number
    page: number
    beats: FreeBeatType[]
    changePageSize: (pageSize: number) => void
    changePage: (page: number) => void
}

export const FreeBeatsTable = ({
    totalCount,
    pageSize,
    page,
    beats,
    changePageSize,
    changePage,
}: Props) => {
    const handleChangePageSize = useCallback(
        (pageSize: number) => {
            changePageSize(pageSize)
        },
        [page]
    )

    const handleChangePage = useCallback(
        (page: number) => {
            changePage(page)
        },
        [pageSize]
    )

    return (
        <>
            <CustomBrowserView>
                <TableHeader />
            </CustomBrowserView>
            <FreeBeatsTableItem beats={beats} />
            <Pagination
                totalCount={totalCount}
                onChangePage={handleChangePage}
                currentPage={page}
                onChangeRowsPerPage={handleChangePageSize}
                pageSize={pageSize}
            />
        </>
    )
}
