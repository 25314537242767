import React, { FC, useEffect, useState } from 'react'
import classes from './CustomerMyContracts.module.scss'
import { Button } from '../../../common/elements/buttons/Button'
import ArrowUpIcon from '../../../assets/icons/arrow-top.svg'
import ArrowDownIcon from '../../../assets/icons/arrow-bottom.svg'
import { ContentState, convertToRaw, EditorState } from 'draft-js'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../../../BLL/Store'
import { useTranslation } from 'react-i18next'
import { LanguageType } from '../../../../translations/ru/common'
import { useHistory, useParams } from 'react-router'
import { LanguageEnum } from '../../../../BLL/enums'
import draftToHtml from 'draftjs-to-html'
import {
    actions,
    getBaseContract,
    getExampleContract,
    getSettingsContract,
} from '../../../../BLL/ContractsReducer'
import { CustomerRM } from '../../../routers/RouterManager'
import {
    BeatmakerContractsType,
    ContractsLangEnum,
} from '../../../../BLL/types'
import htmlToDraft from 'html-to-draftjs'
import { copyBuffer } from '../../../utils/util-types'
import { RequestStatuses } from '../../../../BLL/AppReducer'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { NavLink } from 'react-router-dom'
import { useDeviceDetect } from '../../../common/hooks/useDeviceDetect'
import { CustomSelect } from '../../../common/elements/selects/CustomSelect'
import { useForm } from 'react-hook-form'
import { AppSettings } from 'settings/appSettings'
import BaseTemplateAlertDialog from '../Contracts/BaseTemplateAlertDialog'

type PropsType = {
    handleGetContractById: (contractId: string, lang: LanguageEnum) => void
    handleUpdateContract: (contract: BeatmakerContractsType) => void
}

export const CustomerMyContracts: FC<PropsType> = ({
    handleGetContractById,
    handleUpdateContract,
}) => {
    const [collapsed, setCollapsed] = useState(false)
    const [editorState, setEditorState] = useState<EditorState>(
        EditorState.createEmpty()
    )
    const editingContract = useSelector(
        (state: AppState) => state.beatmakerContracts.editingContract
    )
    let settingsContract = useSelector(
        (state: AppState) => state.beatmakerContracts.settingsContract
    )
    const requestStatus = useSelector(
        (state: AppState) => state.appReducer.requestStatus
    )

    const { i18n } = useTranslation()
    const {
        contracts,
        buttons,
        adminEditTemplate,
        license: customerMyLicenses,
    } = i18n.getResourceBundle(i18n.language, 'common') as LanguageType
    const [isChanged, setIsChanged] = useState<boolean>(false)
    const { id, lang } = useParams<{ id: string; lang: string }>()
    const [lng, setLng] = useState<LanguageEnum>(
        (lang as LanguageEnum) || ContractsLangEnum.English
    )
    const [isOpenAlertDialog, setIsOpenAlertDialog] = React.useState(false)
    const saveDocument = () => {
        const rawContentState = convertToRaw(editorState.getCurrentContent())
        const markup = draftToHtml(rawContentState)
        if (editingContract) {
            handleUpdateContract({ ...editingContract, body: markup })
        }
        setIsChanged(false)
    }

    const { register, watch } = useForm()

    const history = useHistory()
    const dispatch = useDispatch()

    useEffect(() => {
        if (id) {
            dispatch(getSettingsContract())
            history.push(
                CustomerRM.contracts.getUrl.contracts.edit(
                    id,
                    watch('templateLang') || ContractsLangEnum.English
                )
            )
            handleGetContractById(
                id,
                watch('templateLang') || ContractsLangEnum.English
            )
        }
        return () => {
            dispatch(actions.setEditingContract(null))
        }
    }, [id, watch('templateLang')])

    const optionsSelectLang = [
        { value: ContractsLangEnum.Russian, title: 'Русский' },
        { value: ContractsLangEnum.English, title: 'Английский' },
    ]

    useEffect(() => {
        const templateLang = lang || watch('templateLang')
        templateLang && setLng(templateLang as LanguageEnum)
        handleGetBaseTemplate()
    }, [lang, watch('templateLang')])

    const handleChangeSelectLang = (e: any) => {
        setLng(e.currentTarget.value)
    }

    const handleGetBaseTemplate = () => {
        id &&
            dispatch(
                getBaseContract(
                    watch('templateLang') || ContractsLangEnum.English,
                    id
                )
            )
        // setIsOpenAlertDialog(false)
    }

    useEffect(() => {
        if (editingContract) {
            const contentBlock = editingContract.body
                ? htmlToDraft(editingContract.body)
                : null
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(
                    contentBlock.contentBlocks
                )
                const editorState = EditorState.createWithContent(contentState)
                setEditorState(editorState)
            } else {
                setEditorState(EditorState.createEmpty())
            }
        }
    }, [editingContract])

    const onEditorStateChange = (editorState: any) => {
        setEditorState(editorState)
        setIsChanged(true)
    }

    const copyBufferHandler = (text: string) => {
        copyBuffer(text, dispatch)
    }

    const getPdf = () => {
        const rawContentState = convertToRaw(editorState.getCurrentContent())
        const html = draftToHtml(rawContentState)
        dispatch(getExampleContract(html, lng))
    }

    const isMobile = useDeviceDetect()
    const isEng = AppSettings.api.isEngVersion()
    if (isEng || lng === LanguageEnum.En) {
        settingsContract = {
            BEAT_PRICE: settingsContract.BEAT_PRICE,
            BEAT_TITLE: settingsContract.BEAT_TITLE,
            CLIENT_NAME: settingsContract.CLIENT_NAME,
            CONTRACT_DATE: settingsContract.CONTRACT_DATE,
            CURRENCY: settingsContract.CURRENCY,
            LICENSE_NAME: settingsContract.LICENSE_NAME,
            ADDRESS_CLIENT_DATA: settingsContract.ADDRESS_CLIENT_DATA,
        }
    } else {
        settingsContract = {
            BEAT_PRICE: settingsContract.BEAT_PRICE,
            BEAT_TITLE: settingsContract.BEAT_TITLE,
            CLIENT_NAME: settingsContract.CLIENT_NAME,
            CONTRACT_DATE: settingsContract.CONTRACT_DATE,
            CURRENCY: settingsContract.CURRENCY,
            LICENSE_NAME: settingsContract.LICENSE_NAME,
            PASSPORT_CLIENT_DATA: settingsContract.PASSPORT_CLIENT_DATA,
        }
    }
    return (
        <div className={classes.wrapper}>
            <div className={classes.header}>
                <h1 className={classes.title}>
                    {customerMyLicenses.contract.contract}
                </h1>
                <div className={classes.btnBox}>
                    <Button
                        view={'Outline'}
                        title={buttons.DOWNLOAD_PDF}
                        disabled={requestStatus === RequestStatuses.InProgress}
                        onClick={getPdf}
                    />
                    {/*<Button*/}
                    {/*    view={'Outline'}*/}
                    {/*    title={buttons.DEFAULT_TEMPLATE}*/}
                    {/*    onClick={handleGetBaseTemplate}*/}
                    {/*/>*/}
                    {/*<BaseTemplateAlertDialog*/}
                    {/*    setIsOpenAlertDialog={setIsOpenAlertDialog}*/}
                    {/*    isOpenAlertDialog={isOpenAlertDialog}*/}
                    {/*    handleGetBaseTemplate={handleGetBaseTemplate}*/}
                    {/*/>*/}
                </div>
            </div>
            <div
                className={classes.subtitleBox}
                onClick={() => setCollapsed(!collapsed)}
            >
                <span className={classes.subtitle}>
                    {customerMyLicenses.contract.variables}
                </span>
                {collapsed ? (
                    <img src={ArrowUpIcon} alt={'arrow-up'} />
                ) : (
                    <img src={ArrowDownIcon} alt={'arrow-down'} />
                )}
            </div>
            {collapsed && (
                <div className={classes.hideContent}>
                    {Object.values(settingsContract).map((s) => (
                        <div
                            className={classes.item}
                            key={s}
                            onClick={() => copyBufferHandler('{{' + s + '}}')}
                        >
                            <span className={classes.itemTitle}>
                                {'{{' + s + '}}'}
                                <span className={classes.itemDescription}>
                                    - {contracts.settings[s]}
                                </span>
                            </span>
                        </div>
                    ))}
                    <p className={classes.notification}>
                        {contracts.informationText} {'{{CONTRACT_DATE}}'}
                    </p>
                </div>
            )}
            <div>
                {!isEng && (
                    <div className={classes.wrapperLangTamplate}>
                        <label>Выберите язык договора</label>
                        <CustomSelect
                            items={optionsSelectLang}
                            register={register('templateLang')}
                        />
                    </div>
                )}
                <Editor
                    // editorStyle={{
                    //     background: 'black',
                    //     color: '#FFFFFF',
                    //     minHeight: 600,
                    //     maxHeight: 600,
                    //     maxWidth: isMobile ? 345 : 1062,
                    //     paddingLeft: isMobile ? 5 : 80,
                    //     paddingRight: isMobile ? 5 : 80,
                    // }}
                    editorState={editorState}
                    // toolbarStyle={{ maxWidth: isMobile ? 345 : 1050 }}
                    toolbarClassName={classes.toolbarEditor}
                    wrapperClassName={classes.wrapperClassName}
                    editorClassName={classes.editorClassName}
                    onEditorStateChange={onEditorStateChange}
                />
            </div>
            <div className={classes.ButtonsBox}>
                <NavLink
                    to={
                        editingContract
                            ? CustomerRM.contracts.getUrl.license.edit(
                                  editingContract.licenseId
                              )
                            : '#'
                    }
                >
                    <Button view={'Secondary'} title={buttons.CANCEL} />
                </NavLink>
                <Button title={buttons.SAVE_CONTRACT} onClick={saveDocument} />
            </div>
        </div>
    )
}
