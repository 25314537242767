import React, { FC, ReactNode } from 'react'
import classes from 'UI/common/pageWrapper/pageWrapper.module.css'

interface Props {
    children: ReactNode
}

export const PageWrapper: FC<Props> = ({ children }) => {
    return <div className={classes.PageWrapper}>{children}</div>
}
