import React, { useEffect } from 'react'
import { CustomSelect } from 'UI/common/elements/CustomSelect'
import { useForm } from 'react-hook-form'
import { CustomDatePicker } from 'UI/common/elements/datePicker/CustomDatePicker'
import { Button } from 'UI/common/elements/buttons/Button'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { DiscountType, UpdateDiscountDTO } from 'DAL/DiscountAPI'
import { AppState } from 'BLL/Store'
import { AppSettings } from 'settings/appSettings'
import { ColorLinearProgress } from 'UI/common/progressBars/ColorLinearProgress'
import { CustomerRM } from 'UI/routers/RouterManager'
import * as dateFns from 'date-fns'
import { updateDiscount } from 'BLL/CustomerDiscountsReducer'
import { getUserLicenses } from 'BLL/ContractsReducer'
import { CouponsSaleEditForm } from 'UI/CustomerAdmin/CustomerDiscounts/couponEdit/CouponsSaleEditForm'
import { ControlledTextField } from 'UI/common/controls/controlledTextField/ControlledTextField'
import { FormValues } from './types'
import s from 'UI/CustomerAdmin/CustomerDiscounts/couponEdit/couponsEdit.module.scss'

type Props = {
    discount?: DiscountType
}

export const CouponsEdit = ({ discount }: Props) => {
    const beatLicenses = useSelector(
        (state: AppState) => state.beatmakerContracts.beatLicenses
    )
    const dispatch = useDispatch()

    const {
        register,
        setValue,
        formState: { errors },
        handleSubmit,
        control,
        watch,
    } = useForm<FormValues>()

    const { i18n } = useTranslation()
    const history = useHistory()
    const { buttons, discounts, statuses } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    const isEng = AppSettings.api.isEngVersion()

    const options = [
        { value: 1, label: statuses.Active },
        { value: 0, label: statuses.Closed },
    ]

    useEffect(() => {
        if (discount) {
            setValue(
                'status',
                options.find((o) => o.value === discount.status)
            )
            const license = beatLicenses.find(
                (l) => l.id === discount.licenseId
            )
            setValue(
                'license',
                license
                    ? {
                          value: license.id,
                          label: isEng ? license.titleEn : license.title,
                      }
                    : {
                          value: 0,
                          label: discounts.allLicense,
                      }
            )
            setValue('finishDate', discount.finishDate)
            setValue('startDate', discount.startDate)
        }
    }, [discount])

    useEffect(() => {
        dispatch(getUserLicenses())
    }, [])

    const handleCancel = () => {
        history.push(CustomerRM.discounts.path.getUrl('coupons'))
    }

    const onSubmit = handleSubmit((values: any) => {
        if (discount) {
            const dto: UpdateDiscountDTO = {
                id: discount.id,
                forWhatBeats: values.license.value ? 2 : 1,
                licenseId: !values.license.value ? null : values.license.value,
                beatsCount: +values.beatsCount,
                discountSize: +values.discount,
                finishDate: dateFns.format(
                    new Date(values.finishDate),
                    'yyyy-MM-dd'
                ),
                startDate: dateFns.format(
                    new Date(values.startDate),
                    'yyyy-MM-dd'
                ),
                status: values.status.value,
                code: values.code,
            }
            dispatch(updateDiscount(dto))
        }
    })

    if (!discount) return <ColorLinearProgress />

    return (
        <div>
            <form onSubmit={onSubmit}>
                <div className={s.selectItem}>
                    <label className={s.titleItem}>
                        {discounts.editPopUp.status}
                    </label>
                    <CustomSelect
                        control={control}
                        register={register}
                        setValue={setValue}
                        options={options}
                        name={'status'}
                        // errorMessage={errors?.styleId?.message}
                    />
                </div>
                <div className={s.selectItem}>
                    <label className={s.titleItem}>
                        {discounts.editPopUp.license}
                    </label>
                    <CustomSelect
                        control={control}
                        register={register}
                        setValue={setValue}
                        options={[
                            { value: 0, label: discounts.allLicense },
                            ...beatLicenses.map((lic) => ({
                                value: lic.id,
                                label: isEng ? lic.titleEn : lic.title,
                            })),
                        ]}
                        name={'license'}
                        // errorMessage={errors?.styleId?.message}
                    />
                </div>
                <div className={s.dateInner}>
                    <div>
                        <label className={s.fieldItemTitle}>
                            {discounts.editPopUp.from}
                        </label>
                        <CustomDatePicker
                            date={watch('startDate')}
                            minDate={new Date()}
                            disabled={false}
                            handleChange={(date) => setValue('startDate', date)}
                            locale={i18n.language as 'ru' | 'en'}
                        />
                    </div>
                    <div>
                        <label className={s.fieldItemTitle}>
                            {discounts.editPopUp.due}
                        </label>
                        <CustomDatePicker
                            date={watch('finishDate')}
                            minDate={new Date()}
                            disabled={false}
                            handleChange={(date) =>
                                setValue('finishDate', date)
                            }
                            locale={i18n.language as 'ru' | 'en'}
                        />
                    </div>
                </div>
                <CouponsSaleEditForm
                    discount={discount}
                    discounts={discounts}
                    control={control}
                />
                <div className={s.couponInner}>
                    <ControlledTextField
                        name={'code'}
                        control={control}
                        label={discounts.editPopUp.code}
                        required={true}
                        defaultValue={discount.code}
                    />
                </div>
                <div className={s.btnsBox}>
                    <Button
                        view={'Secondary'}
                        title={buttons.CANCEL}
                        type={'button'}
                        onClick={handleCancel}
                    />
                    <Button title={buttons.SAVE} type={'submit'} />
                </div>
            </form>
        </div>
    )
}
