import React from 'react'
import { DraggableProvided } from 'react-beautiful-dnd'
import s from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/beatItem/beatItem.module.scss'
import sMobile from 'UI/CustomerAdmin/Mobile/MobMyBeatsPage/common/styles/MobBeatItem.module.scss'
import dragIcon from 'UI/assets/icons/drag-icon.svg'

type Props = {
    provided: DraggableProvided
    isDrag: boolean
    isMobile?: boolean
}

export const DragIcon = ({ provided, isDrag, isMobile }: Props) => {
    const classes = isMobile ? sMobile : s

    return (
        <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={classes.dragElement}
        >
            {!isDrag && <img src={dragIcon} alt={'drag'} />}
        </div>
    )
}
