import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { fetchExchangeMoneyToCoins } from 'BLL/MySalesReducer'
import { useEditingEntityStatus } from 'UI/common/hooks/useEditingEntityStatus'
import { EditingEntityStatuses } from 'BLL/AppReducer'
import { Modal } from 'UI/common/modal/modal'
import classes from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/beatspage.module.scss'
import { Button } from 'UI/common/elements/buttons/Button'

type PropsType = {
    onClose: (value: boolean) => void
    open: boolean
}

export const ConfirmExchangeDialog: FC<PropsType> = ({ onClose, open }) => {
    const { i18n } = useTranslation()
    const { buttons, mySales } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType
    const dispatch = useDispatch()
    const { id } = useParams<{ id: string }>()

    const { editingEntityStatus } = useEditingEntityStatus(id)

    const handleClose = (value: boolean) => {
        onClose(value)
    }

    const handleConfirmExchange = () => {
        id && dispatch(fetchExchangeMoneyToCoins(+id))
    }

    if (editingEntityStatus === EditingEntityStatuses.Success) {
        handleClose(false)
    }

    return (
        <Modal
            active={open}
            setActive={onClose}
            title={mySales.confirmDialog.title}
        >
            <div>
                <p>{mySales.confirmDialog.text}</p>
                <div className={classes.buttonsPopUp}>
                    <Button
                        view={'Secondary'}
                        title={buttons.CANCEL}
                        onClick={() => handleClose(false)}
                    />
                    <Button
                        title={buttons.ACCEPT}
                        onClick={handleConfirmExchange}
                    />
                </div>
            </div>
        </Modal>
    )
}
