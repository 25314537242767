export const downloadFile = (blobData: Blob, fileName: string) => {
    const blobUrl = URL.createObjectURL(blobData)

    const link = document.createElement('a')
    link.href = blobUrl
    link.download = fileName

    document.body.appendChild(link)
    link.click()

    document.body.removeChild(link)
    URL.revokeObjectURL(blobUrl)
}
