import React, { FC } from 'react'
import classes from 'UI/common/elements/customSelect.module.scss'

type PropsType = {
    title: string
    id: string
    handleChange: (value: any) => void
} & React.InputHTMLAttributes<HTMLInputElement>
export const CustomRadio: FC<PropsType> = ({
    title,
    id,
    handleChange,
    ...props
}) => {
    const handleOnClickAndChange = () => {
        handleChange(props.value)
    }
    return (
        <div className={classes.radio} onClick={handleOnClickAndChange}>
            <div>
                <input
                    type="radio"
                    id={id}
                    {...props}
                    onChange={handleOnClickAndChange}
                />
                <label className={classes.label} htmlFor={id} />
            </div>
            <span className={classes.text} onClick={handleOnClickAndChange}>
                {title}
            </span>
        </div>
    )
}
