import React, { FC, useEffect, useState } from 'react'
import Header from '../../common/header/Header'
import { useDispatch, useSelector } from 'react-redux'
import {
    getAlSubscriptions,
    getEmailsHistory,
    getSubscriptions,
} from '../../../BLL/AdminSubscriptionsReducer'
import SubscriptionTable from './SubscriptionTable'
import { RolesTypeEnum, SubscriptionPlansEnum } from '../../../BLL/enums'
import { AppState } from '../../../BLL/Store'
import { useRedirectCustomHook } from '../../common/hooks/useRedirectCustomHook'
import { useTranslation } from 'react-i18next'
import { LanguageType } from '../../../translations/ru/common'

const AdminSubscriptionsPage: FC = () => {
    const [page, setPage] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(50)
    const [currentPage, setCurrentPage] = useState<number>(0)
    const [plan, setPlan] = useState<SubscriptionPlansEnum | null>(null)

    const dispatch = useDispatch()
    const { i18n } = useTranslation()
    const { navigation } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType
    const totalSubscriptions = useSelector(
        (state: AppState) => state.adminSubscriptions.totalSubscriptions
    )
    const subscriptions = useSelector((state: AppState) =>
        getAlSubscriptions(state)
    )

    const handleChangePageSize = (event: any) => {
        setPageSize(event.target.value)
    }

    const handleChangePage = (page: number) => {
        setCurrentPage(page)
        setPage(page + 1)
    }

    const handleChangePlan = (plan: SubscriptionPlansEnum | null) => {
        setPlan(plan)
    }

    useEffect(() => {
        dispatch(getSubscriptions(page, pageSize, plan))
    }, [page, pageSize, plan, dispatch])

    const handleGetEmailsHistory = (
        subscriptionId: string,
        userId: number,
        templateKey: string
    ) => {
        dispatch(getEmailsHistory(subscriptionId, userId, templateKey))
    }

    return (
        <div>
            <Header title={navigation.mailingSubscription} />
            {useRedirectCustomHook(RolesTypeEnum.SUBSCRIPTION_MANAGER)}
            <SubscriptionTable
                totalSubscriptions={totalSubscriptions}
                subscriptions={subscriptions}
                currentPage={currentPage}
                pageSize={pageSize}
                handleChangePageSize={handleChangePageSize}
                handleChangePage={handleChangePage}
                handleChangePlan={handleChangePlan}
                handleGetEmailsHistory={handleGetEmailsHistory}
            />
        </div>
    )
}

export default AdminSubscriptionsPage
