import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Calendar } from './Calendar'
import styles from './Calendar.module.css'
import { getCurrentMonth, getCurrentYear } from '../../../../BLL/SupportingFile'
import {
    getAllBeatsForPromo,
    getBusyDays,
    getPromoType,
    OptionsPromoType,
    sendPromo,
} from '../../../../BLL/CustomerBeatsPromotionReducer'
import * as dateFns from 'date-fns'
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import ContactSupportSharpIcon from '@material-ui/icons/ContactSupportSharp'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { useTranslation } from 'react-i18next'
import { BeatType, BusyDaysType } from '../../../../BLL/types'
import { CreatePromoDto } from '../../../../DAL/PromoAPI'
import { useSelector } from '../../../common/hooks/redux-hooks'
import {
    EditingEntityStatuses,
    RequestStatuses,
} from '../../../../BLL/AppReducer'
import { useEditingEntityStatus } from '../../../common/hooks/useEditingEntityStatus'
import { Modal } from '../../../common/modal/modal'
import classes from './calendar-dialog.module.scss'
import { CustomSelect } from '../../../common/elements/selects/CustomSelect'
import { useForm } from 'react-hook-form'
import { Button } from '../../../common/elements/buttons/Button'
import { CustomerAdvertisingBeats } from '../CustomerAdvertisingBeats/CustomerAdvertisingBeats'
import { LanguageType } from '../../../../translations/ru/common'

type PropsType = {
    calendarIsOpen: boolean
    setCalendarIsOpen: (value: boolean) => void
}
export const CalendarDialog: FC<PropsType> = ({
    calendarIsOpen,
    setCalendarIsOpen,
}) => {
    const dispatch = useDispatch()
    const { register, control, watch, reset } = useForm()
    const [selectedDate, setSelectedDate] = useState<null | Date>(null)
    const [isOpenSelectBeat, setIsOpenSelectBeat] = useState(false)
    const [year, setYear] = useState<number>(new Date().getFullYear())
    const [monthNumber, setMonthNumber] = useState<number>(
        new Date().getMonth() + 1
    )
    const [selectedBeat, setSelectedBeat] = useState<null | BeatType>(null)
    const [selectedPromoType, setSelectedPromoType] = useState<null | number>(
        null
    )
    const [busyDayInfo, setBusyDayInfo] = useState<BusyDaysType>({
        busySlots: 0,
        busyUserSlots: 0,
        totalSlots: 0,
        isBusy: true,
        data: dateFns.format(new Date(), 'yyyy-MM-dd'),
    })

    const { i18n } = useTranslation()
    const { calendar, buttons, promotionsType, customerBeatsPromotion } =
        i18n.getResourceBundle(i18n.language, 'common') as LanguageType

    const beats = useSelector(getAllBeatsForPromo)
    const promoType = useSelector(getPromoType)
    const requestStatus = useSelector((state) => state.appReducer.requestStatus)
    const beatsPagination = useSelector(
        (state) => state.customerBeatsPromotion.beatsPagination
    )

    const { editingEntityStatus } = useEditingEntityStatus('calendar')

    useEffect(() => {
        if (editingEntityStatus === EditingEntityStatuses.Success) {
            handleClose()
        }
    }, [editingEntityStatus])

    const handleRemoveSelectedDate = () => {
        setSelectedDate(null)
    }

    const handleSelectValue = (selectedPromoType: number) => {
        setSelectedPromoType(selectedPromoType)
        setSelectedBeat(null)
        handleRemoveSelectedDate()
    }

    useEffect(() => {
        const selectedPromoType = Number(watch('promoType'))
        if (selectedPromoType !== null) {
            handleSelectValue(selectedPromoType)
        }
    }, [watch('promoType')])

    const refreshState = () => {
        setYear(getCurrentYear())
        setMonthNumber(getCurrentMonth())
        setSelectedPromoType(null)
        setSelectedDate(null)
        reset()
    }

    const handleClose = () => {
        refreshState()
        setCalendarIsOpen(false)
    }

    const handleCreatePromo = () => {
        //@ts-ignore
        const cost = promoType.find((t) => t.value === selectedPromoType)?.price
        if (
            selectedPromoType !== null &&
            selectedBeat &&
            selectedDate &&
            cost
        ) {
            let payload: CreatePromoDto = {
                date: dateFns.format(
                    new Date(selectedDate),
                    "yyyy-MM-dd'T'HH:mm:ss.SSS'+'ssss"
                ),
                beat: selectedBeat,
                //@ts-ignore
                promoType: selectedPromoType,
                amountCoins: cost,
            }
            dispatch(sendPromo(payload))
        }
    }

    const nextMonth = () => {
        handleRemoveSelectedDate()
        if (monthNumber === 12) {
            setMonthNumber(1)
            nextYear()
        } else {
            const nextMonth = monthNumber + 1
            setMonthNumber(nextMonth)
        }
    }
    const previousMonth = () => {
        handleRemoveSelectedDate()
        if (monthNumber === 1) {
            setMonthNumber(12)
            previousYear()
        } else {
            setMonthNumber(monthNumber - 1)
        }
    }
    const nextYear = () => {
        handleRemoveSelectedDate()
        setYear(year + 1)
        setMonthNumber(1)
    }
    const previousYear = () => {
        handleRemoveSelectedDate()
        const date = new Date()
        const yearNow = date.getFullYear()
        const month = date.getMonth() + 1
        setYear(year - 1)
        if (year - 1 === yearNow) {
            setMonthNumber(month)
        }
    }

    const handleGetBusyDays = () => {
        if (selectedPromoType !== null) {
            const startOfMonth = new Date(year, monthNumber - 1)
            const promoType = selectedPromoType
            //@ts-ignore
            dispatch(getBusyDays({ startOfMonth, promoType }))
        }
    }

    useEffect(() => {
        handleGetBusyDays()
    }, [year, monthNumber, selectedPromoType])

    const options = promoType.map((type) => {
        return {
            value: type.value,
            //@ts-ignore
            title: promotionsType[type.label],
        }
    })
    //@ts-ignore
    const cost = promoType.find(
        (t) => t.value === Number(watch('promoType'))
    )?.price
    return (
        <div>
            <Modal
                title={customerBeatsPromotion.selectAdOption}
                active={calendarIsOpen}
                setActive={setCalendarIsOpen}
            >
                <div>
                    {isOpenSelectBeat && selectedPromoType !== null ? (
                        <CustomerAdvertisingBeats
                            setIsOpenSelectBeat={setIsOpenSelectBeat}
                            promoTypeTitle={
                                //@ts-ignore
                                promotionsType[selectedPromoType]
                            }
                            beats={beats}
                            selectedBeat={setSelectedBeat}
                            sendPromoInfo={handleCreatePromo}
                            page={beatsPagination.page}
                            pagesCount={beatsPagination.pagesCount}
                        />
                    ) : (
                        <>
                            <div className={classes.wrapperSelect}>
                                <label className={classes.titleItem}>
                                    {calendar.selectPromoType}
                                </label>
                                <CustomSelect
                                    register={register('promoType')}
                                    items={options}
                                    name={'promoType'}
                                />
                                <p>
                                    {cost
                                        ? `${customerBeatsPromotion.cost} ${cost} ${customerBeatsPromotion.coins}`
                                        : null}
                                </p>
                            </div>
                            {selectedPromoType !== null && (
                                <div
                                    className={
                                        selectedPromoType !== null
                                            ? styles.calendarWrapper
                                            : styles.calendarWrapperDisabled
                                    }
                                >
                                    <span className={styles.dialogTitle}>
                                        {calendar.selectDate}
                                    </span>
                                    <Calendar
                                        selectedDate={selectedDate}
                                        year={year}
                                        monthNumber={monthNumber}
                                        busyDayInfo={busyDayInfo}
                                        setDate={setSelectedDate}
                                        nextMonth={nextMonth}
                                        previousMonth={previousMonth}
                                        nextYear={nextYear}
                                        previousYear={previousYear}
                                        refreshState={refreshState}
                                        setBusyDaysInfo={setBusyDayInfo}
                                    />
                                </div>
                            )}
                            <div className={classes.btnBox}>
                                <Button
                                    onClick={handleClose}
                                    title={buttons.CANCEL}
                                    view={'Secondary'}
                                />
                                {selectedDate &&
                                !busyDayInfo.isBusy &&
                                selectedPromoType !== null &&
                                selectedPromoType !== 3 ? (
                                    <Button
                                        title={buttons.CONTINUE}
                                        onClick={() =>
                                            setIsOpenSelectBeat(true)
                                        }
                                        disabled={
                                            requestStatus ===
                                            RequestStatuses.InProgress
                                        }
                                    />
                                ) : (
                                    selectedDate &&
                                    !busyDayInfo.isBusy && (
                                        <Button
                                            onClick={handleCreatePromo}
                                            disabled={
                                                requestStatus ===
                                                RequestStatuses.InProgress
                                            }
                                            title={buttons.RESERVE}
                                        />
                                    )
                                )}
                            </div>
                        </>
                    )}
                </div>
            </Modal>
        </div>
    )
}

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 500,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip)
const TolTip: FC = () => {
    const [tolTipIsOpen, setTolTipIsOpen] = useState<boolean>(false)
    const { i18n } = useTranslation()
    const { calendar, promotionsType } = i18n.getResourceBundle(
        i18n.language,
        'common'
    )
    const handleTooltipClose = () => {
        setTolTipIsOpen(false)
    }
    const handleTooltipOpen = () => {
        setTolTipIsOpen(true)
    }
    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <HtmlTooltip
                onClose={handleTooltipClose}
                open={tolTipIsOpen}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                interactive={true}
                leaveDelay={100}
                title={
                    <React.Fragment>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <ul>
                                <li>
                                    <b>{promotionsType[0]}</b>
                                    {calendar.htmlToltip.player}
                                </li>
                                <li>
                                    <b>{promotionsType[1]}</b>{' '}
                                    {calendar.htmlToltip.mainPage}
                                </li>
                                <li>
                                    <b>{promotionsType[2]}</b>{' '}
                                    {calendar.htmlToltip.catalog}
                                </li>
                                <li>
                                    <b>{promotionsType[4]}</b>{' '}
                                    {calendar.htmlToltip.PromoInTheChart}
                                </li>
                                <li>
                                    <b>{promotionsType[5]}</b>{' '}
                                    {calendar.htmlToltip.searchInCatalog}
                                </li>
                            </ul>
                            <a
                                href="https://beatmaker.tv/help/reklama_na_sajte"
                                target={'_blank'}
                                rel="noreferrer"
                            >
                                {calendar.more}
                            </a>
                        </div>
                    </React.Fragment>
                }
            >
                <ContactSupportSharpIcon
                    onClick={handleTooltipOpen}
                    style={{ color: 'black' }}
                    fontSize="large"
                />
            </HtmlTooltip>
        </ClickAwayListener>
    )
}
