import React, { FC } from 'react'
import classes from './conditions.module.scss'
import { Button } from '../../common/elements/buttons/Button'
import { NavLink } from 'react-router-dom'
import { CustomerRM } from '../../routers/RouterManager'

type PropsType = {}
export const Conditions: FC<PropsType> = () => {
    return (
        <div className={classes.conditions}>
            <div className={classes.inner}>
                <p className={classes.conditions__descr}>
                    Чтобы вывести деньги, которые поступили на кошельки сайта,
                    напишите в личные сообщения аккаунта поддержки{' '}
                    <a
                        className={classes.link}
                        href="https://beatmaker.tv/support"
                        target={'_blank'}
                        rel="noreferrer"
                    >
                        https://beatmaker.tv/support
                    </a>
                </p>
                <span className={classes.conditions__title}>
                    Условия для вывода:
                </span>
                <ul className={classes.list}>
                    <li>
                        Указан минимум один кошелек для вывода.{' '}
                        <a
                            href={`https://beatmaker.tv/BeatsManagement/Wallets/Withdrawal`}
                            target={'_blank'}
                            rel="noreferrer"
                            className={classes.link}
                        >
                            Указать кошельки для вывода
                        </a>
                    </li>
                    <li>На балансе больше 1000р.</li>
                    <li>С момента продажи прошло минимум 3 дня.</li>
                </ul>
                <p>
                    Для аккаунтов, у которых подключена подписка премиум или
                    эксперт, есть возможность настроить прием прямой оплаты на
                    личные кошельки YooMoney и Paypal.
                </p>
                <NavLink
                    to={CustomerRM.settings.path.getUrl('wallets')}
                    className={classes.link}
                >
                    Настроить кошельки
                </NavLink>
            </div>
            <a
                href={`https://beatmaker.tv/messages#3725`}
                target={'_blank'}
                rel="noreferrer"
            >
                <Button view={'Primary'} title={'Написать сообщение'} />
            </a>
        </div>
    )
}
