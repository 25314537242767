import { ISourceType } from 'BLL/SupportingFile'
import { BeatsConstants } from 'UI/constants'
import { LangListType } from '../en/common'

export type LanguageType = typeof ru

export const ru = {
    languageList: {
        ru: 'Русский' as LangListType,
        en: 'Английский' as LangListType,
    },
    buttons: {
        SAVE: 'Сохранить',
        SAVE_CHANGES: 'Сохранить',
        SAVE_LICENSES: 'Сохранить лицензии',
        SAVE_CONTRACT: 'Сохранить договор',
        DONE: 'Готово',
        SAVE_AND_RESULT: 'Сохранить и показать результат',
        CANCEL: 'Отменить',
        ACCEPT: 'Подтвердить',
        CLOSE: 'Закрыть',
        SEND: 'Отправить',
        RESERVE: 'Забронировать',
        CONTINUE: 'Продолжить',
        PUBLISHED: 'Опубликовать',
        SAVE_AND_PUBLISHED: 'Сохранить и опубликовать',
        MORE: 'Загрузить еще',
        DELETE: 'Удалить',
        RESTORE: 'Восстановить',
        LIST: 'Список опций',
        YES: 'Да',
        ADD: 'Добавить',
        ADD_ADVERTISING: '+ Добавить новую рекламу',
        ADD_OPTIONS: 'Добавить опцию',
        ADD_LICENSE: '+ Добавить новую лицензию',
        ADD_WIDGET: 'Добавить виджет',
        ADD_BEATS: 'Добавить биты',
        SKIP: 'Пропустить',
        NO: 'Нет',
        CLEAR_FILTER: 'Очистить фильтр',
        BACK_TO_TEMPLATES: 'К списку лицензий',
        REJECT: 'Отклонить',
        SEND_NOTE: 'Отправить заметку',
        EDIT: 'Редактировать',
        DEFAULT_TEMPLATE: 'Загрузить шаблон по умолчанию',
        DOWNLOAD_PDF: 'Скачать PDF',
        DELETE_FROM_CATALOG: 'Удалить из каталога',
        BACK_TO_ITEMS: 'Назад к списку',
        EDIT_CONTRACT: 'Редактировать договор',
        ADD_CONTRACT: 'Добавить договор',
        SHOW: 'Открыть',
        WRITE_MESSAGE: 'Написать сообщение',
        WITHDRAW: 'Вывести',
        DETAILS: 'Детали',
        GO_TO_PAYMENT: 'Перейти к оплате',
        GO: 'Перейти',
        CHOOSE_DAY: 'Выбрать дату',
        BUY_COINS: 'Купить монеты',
        LETS_GO: 'Начать',
        ADD_TO_COMPILATION: 'Добавить',
        ADD_COUPON: 'Добавить купон',
        COPY: 'Копировать',
        DOWNLOAD_EMAIL: 'Скачать емейлы',
        ADD_NEW_DISCOUNT: '+ Добавить скидку',
        GO_TO_PRODUCER_PAGE: 'Прейти на мою страницу продюсера',
        APPROVE: 'Подтвердить',
        DEACTIVATE: 'Деактивировать',
    },
    statuses: {
        Active: 'Активный',
        Waiting: 'Ожидание',
        Rejected: 'Отказано',
        Clarification: 'Информация',
        Confirmed: 'Одобрен',
        Closed: 'Закрыт',
        NotActive: 'Создан',
    },
    helpMessage: {
        edit: 'Редактировать',
        search: 'Поиск',
        loading: 'Загрузка...',
    },
    messages: {
        ERROR_MESSAGE: 'Ошибка. Пожалуйста, обратитесь к Администратору',
        SUCCESS_MESSAGE: 'Операция проведена успешно',
        COPY_SUCCESS: 'Скопировано',
        COPY_FAILED: 'Ваш браузер не поддерживает, скопируйте вручную',
    },
    customerBeatsCompilation: {
        startText: 'Собрать избранные анонимные биты',
        beatsCompilationText: 'Количество битов в компиляции',
        noBeats: 'Новых битов нет, попробуйте зайти позднее',
        countBeatsCompilation: 'Количество битов в компиляции',
        thanksAndPublished: 'Спасибо! Ваша подборка битов будет опубликована.',
        nextCompilation:
            'Возможность сформировать новую компиляцию будет открыта:',
        notExpertMessage:
            'Вы не являетесь экспертом или Ваш статус эксперта приостановлен.',
    },
    customerBeatsPublication: {
        publicationNow: 'Опубликовать сейчас',
        deletePopUpDialogMessage: 'Отменить публикацию по расписанию?',
        tableTitle: 'Неопубликованные биты',
        noBeatsForPublished: 'Нет битов для публикации',
        notPublished: 'Не опубликован',
        waitPublished: 'Ожидает публикации',
        edit: 'Редактировать',
        selectDateAndTime: 'Выберите дату и время',
        requireField: 'Обязательное поле',
        cancelPublication: 'Отменить публикацию',
        invalidDateMessage: 'Дата не может быть меньше текущей',
        time: 'Время',
        date: 'Дата',
        tableColumns: {
            beatTitle: 'Название бита',
            publishedDate: 'Дата публикации',
            status: 'Статус',
            error: 'Ошибка',
            management: 'Управление',
        },
    },
    statisticsTab: ['ВСЕ', 'БЕСПЛАТНЫЕ', 'ЭКСПЕРТЫ', 'ПРЕМИУМ'],
    statusExpert: ['Активный', 'Приостановлен', 'Отключен'],
    statusShop: ['Новый', 'Активный', 'Приостановлен', 'Удален'],
    templatesType: {
        0: 'Уведомление о дате окончания подписки',
        1: 'Уведомление о начислении кэшбека',
    } as ISourceType,
    promotionsType: {
        0: 'Плеер',
        1: 'Главная страница - блок "В центре внимания"',
        2: 'Каталог - блок "Обратите внимание"',
        3: 'Всплывающее окно',
        4: 'Каталог - топ 3',
        5: 'Каталог - поиск (жанр, настроение, теги)',
        100: 'Плеер',
        101: 'Главная страница',
        102: 'Каталог',
        103: 'Реклама в чарте',
    },
    subscriptionType: {
        20: 'Бесплатный',
        100: 'Премиум',
        9999: 'Эксперт',
    },
    subscriptionStatus: {
        0: 'Активная',
        1: 'Истекла',
    },
    sourceType: {
        1: 'Страница продюсера',
    } as ISourceType,
    pagination: {
        lines: 'Строк',
        from: 'из',
        firstTooltip: 'В начало',
        previousTooltip: 'Назад',
        nextTooltip: 'Вперед',
        lastTooltip: 'В конец',
        perPage: 'На странице',
    },
    customerBeatsPromotion: {
        selectBeatForPromo: 'Выберите бит для рекламы',
        edit: 'Редактировать',
        beatsPromoNow: 'Рекламируемый бит:',
        selectBeat: 'Выберите бит',
        tableTitle: 'Рекламы',
        tableNoPromoMessage: 'Нет рекламы',
        showStatistics: 'Показать статистику',
        statistics: 'Статистика',
        countShows: 'Количество просмотров:',
        tableColumns: {
            beat: 'Бит',
            promoType: 'Тип рекламы',
            reservedDate: 'Дата бронирования',
            viewedDate: 'Дата показа рекламы',
            management: 'Управление',
        },
        selectAdOption: 'Выберите тип рекламы',
        infoAdvertising: 'Информация по рекламе',
        advertisingBeatsInfo: 'Информация по рекламе',
        cost: 'Цена',
        coins: 'монет',
    },
    customerBeatSubscribers: {
        tableTitle: 'Ваши подписчики',
        noSubscribers: 'Нет подписчиков',
        pageSubscribed: 'Выерите страницу подписки',
        placeholderSelect: 'Подписки',
        selectPeriod: 'Выберите период',
        deletePopUpDialogMessage: 'Вы действительно хотите удалить подписчика?',
        tableColumns: {
            subscriptionDate: 'Дата подписки',
            email: 'E-mail',
            name: 'Имя',
            pageSubscription: 'Страница подписки',
        },
        period: {
            start: 'Начало',
            end: 'Конец',
        },
    },
    navigation: {
        beatsOfPublication: 'Публикация битов',
        beatsPromo: 'Реклама битов',
        mySubscribers: 'Мои подписчики',
        beatsCompilation: 'Компиляция битов',
        promoStats: 'Статистика рекламы',
        experts: 'Эксперты',
        mailingSubscription: 'Рассылка по подпискам',
        admin: 'Администрирование',
        emailTemplates: 'Email шаблоны',
        shop: 'Мой магазин',
        coupons: 'Мои купоны',
        adminShops: 'Магазины',
        adminCoupons: 'Купоны',
        adminPayments: 'Вывод денег',
        contracts: 'Лицензии и договоры',
        orders: 'Платежки',
        beats: 'Биты',
        settings: 'Настройки битмейкера',
        chatVK: 'Чат VK',
        faq: 'FAQ',
        cashback: 'Кэшбек',
        myBeats: 'Мои биты',
        beatEdit: 'Редактирование бита',
        templateEditor: 'Редактирование шаблона',
        myProfile: 'Мой профиль',
        mySales: 'Мои продажи',
        myCoins: 'Монеты',
        discounts: 'Скидки',
        producerPage: 'Страница продюсера',
        widgets: 'Виджеты',
        freeBeats: 'Мои бесплатные биты',
        archive: 'Архив',
    },
    calendar: {
        htmlToltip: {
            player: '- бит (максимум 5 битов в день) закрепляется в плеере и при посещении сайта пользователем автоматически начинает воспроизведение (из всех забронированных битов всегда будет вопроизводиться тот, у которого меньше всего прослушиваний).',
            mainPage:
                '- бит закрепляется на главной станице сайта в блоке «В ЦЕНТРЕ ВНИМАНИЯ».',
            catalog: '- бит закрепляется вверху страницы «КАТАЛОГ БИТОВ».',
            PromoInTheChart:
                '- бит будет показываться на одной из трех первых позиций в чарте, на странице «КАТАЛОГ БИТОВ».',
            searchInCatalog:
                '- биты будут отображаться в каталоге только при поиске, каждую 6 позицию, в случае если один или несколько фильтров поиска совпали с жанром, настроением или тегами бита.',
        },
        more: 'Подробнее',
        selectPromoType: 'Выберите тип рекламы',
        selectDate: 'Выберите дату',
        promoType: 'Тип рекламы',
        price: 'Цена',
        coins: 'монет',
        totalSlots: 'Всего слотов',
        busySlots: 'Занятых слотов',
        busyUserSlots: 'Ваших слотов',
    },
    adminBeatsPromotionTable: {
        beatmaker: 'Битмейкер',
        beatTitle: 'Название бита',
        reservedDate: 'Дата бронирования',
        emptyDataSourceMessage: 'Нет рекламы',
        promoDate: 'Дата рекламы',
    },
    adminExpertsCompilation: {
        tableColumns: {
            beatmakerId: 'Битмейкер ID',
            name: 'Имя',
            status: 'Статус',
            lastCompilationDate: 'Дата последней компиляции',
        },
        loading: 'Загрузка...',
        addExpert: 'Добавить эксперта',
        fieldIsRequired: 'Обазательное поле',
        description: 'Описание',
        uploadPhoto: 'Выбрать фото',
        changeArtWork: 'Изменить обложку',
        edit: 'Редактировать',
        emptyDataSourceMessage: 'Нет экспертов',
    },
    adminSubscription: {
        tableColumns: {
            beatmaker: 'Битмейкер',
            email: 'E-mail',
            subscriptionType: 'Тип подписки',
            status: 'Статус',
            expirationDate: 'Дата окончания подписки',
            lastSendingDate: 'Дата последней отправки',
            nextSendingDate: 'Дата следующей отправки',
            sendingDate: 'Дата отправки',
            message: 'Сообщение',
            isViewed: 'Просмотрено',
            isRedirect: 'Редирект',
        },
        emptyDataSourceMessage: 'Нет подписок',
        showMailing: 'Показать рассылку',
        subscriptions: 'Подписки',
        noMailing: 'Нет рассылок',
        all: 'Всего',
        active: 'Активных',
        expired: 'Истекли',
    },
    adminEditTemplate: {
        tableColumns: {
            name: 'Название',
            templateKey: 'Уникальное имя шаблона',
            createDate: 'Дата создания',
            changeDate: 'Дата изменения',
            redactor: 'Редактор',
            modelProperty: 'Тестовый JSON',
        },
        emptyDataSourceMessage: 'Нет шаблонов',
        templateTitle: 'Название шаблона',
        paramsToTemplate: 'Доступные переменные для шаблона',
        result: 'Результат',
        notSavedMessage: 'Имеются не сохраненные данные в шаблоне',
        closePopUpMessage: 'Сохранить внесенные изменения?',
    },
    license: {
        tableColumns: {
            title: 'Лицензия',
            price: 'Цена по умолчанию',
            createDate: 'Дата создания',
            contract: 'Договор',
            action: 'Действие',
        },
        tableTitle: 'Мои лицензии',
        licenseDetails: {
            general: 'Основная информация',
            licenseNameEng: 'Название лицензии (Англ)',
            licenseCostEng: 'Цена',
            licenseNameRus: 'Название лицензии (Рус)',
            licenseCostRus: 'Цена',
            licenseOptions: 'Опции лицензии',
        },
        contract: {
            contract: 'Договор',
            variables: 'Переменные, доступные для договора',
        },
        deletePopUp: {
            title: 'Удалить',
            desc: 'Вы действительно хотите удалить лицензию: ',
        },
    },
    shopCoupon: {
        tableTitle: 'Купоны',
        all: 'Все',
        searchText: 'Поиск',
        deletePopUpTitle: 'Удалить купон',
        deleteDescription: 'Вы действительно хотите удалить купон?',
        edit: 'Редактирование',
        checkBoxPopUpText: 'Отправить купон на проверку в магазин',
        checkBoxPopUpRefactorText: 'Отправить на уточнение деталей',
        tableColumns: {
            approve: 'Подтвердить',
            coupon: 'Купон',
            addedDate: 'Дата создания',
            status: 'Статус',
            action: 'Действия',
            price: 'Цена',
            productTitle: 'Товар',
            purchaseDate: 'Дата покупки',
            currency: 'Валюта',
            shop: 'Магазин',
            shopNote: 'Сообщение от магазина',
            feedBack: 'Отзыв',
            rating: 'Рейтинг',
        },
        currency: {
            0: 'RUB',
            1: 'USD',
        },
        popupEdit: {
            title: 'Редактировать купон',
            description: 'Этот купон предоставляет скидку 15%.',
            storeComment: 'Комментарий магазина',
            productName: 'Название товара',
            currency: 'Вылюта',
            cost: 'Цена',
            fieldIsRequired: 'Обязательное поле',
            purchaseDate: 'Дата покупки',
            yourAssessment: 'Ваша оценка',
            feedback: 'Отзыв',
        },
    },
    managerAdmin: {
        tableTitle: 'Магазины',
        editPopUpTitle: 'Редактирование магазина',
        tableColumns: {
            title: 'Название',
            status: 'Статус',
            feePercent: 'Процент',
            city: 'Город',
            country: 'Страна',
            description: 'Описание',
            allWorldDelivery: 'Доставка по всему миру',
            shortDescription: 'Краткое описание',
            userId: 'ID менеджера',
            image: 'Картинка магазина',
            contacts: {
                phone: 'Телефон',
                email: 'Email',
                website: 'Web site',
                address: 'Адрес',
            },
        },
    },
    paymentsAdmin: {
        tableTitle: 'Платежи',
        closePayment: 'Закрыть выплату',
        placeholder: 'Введите ID и нажмите Enter',
        tableColumns: {
            beatmakerName: 'Битмейкер',
            sumBeatsPrice: 'Продажи',
            sumForCash: 'Сумма для выплаты',
            fromWallet: 'Откуда',
            toWallet: 'Куда',
            validation: 'Валидация',
        },
        detailTableColumns: {
            beatmakerName: 'Битмейкер',
            beatTitle: 'Бит',
            licenseTitle: 'Лицензия',
            paymentSource: 'Страница',
            beatmakerMoney: 'Цена',
            discountSize: 'Скидочный купон',
            money: 'Сумма для вывода',
            cashback: 'Оплачено кешбеком',
            percent: 'Комиссия сайта',
            paymentSystem: 'Система оплаты',
            info: 'Информация',
            moneyWithdrawalId: 'MWD_ID',
            profile: 'Профиль',
        },
        wallet: {
            title: 'Кошельки',
        },
        validation: {
            attention: 'Внимание',
            validationTooltip: 'Информация',
            title: 'Валидация',
            shazam: 'Шазам',
            contacts: 'Контакты',
            passport: 'Паспорт',
            videoScreens: 'Видео работ',
            description: 'Описание',
            verified: 'Проверенный',
        },
        allMoney: 'Общая сумма не выведенных средств',
    },
    contracts: {
        helpText: 'Инструкция по настройке договора',
        variables: 'Доступные переменные для договора',
        informationText:
            '(!) Для правильного отображения данных в сгенерированном договоре переменные необходимо указывать в\n' +
            '                    тексте договора в двойных скобках\n' +
            '                    Пример:',
        alertText:
            'Вы действительно хотите загрузить шаблон по умолчанию, текущий шаблон будет заменен?',
        alertDialogTitle: 'Внимание',
        settings: {
            CONTRACT_DATE: 'Дата продажи бита',
            CLIENT_NAME: 'ФИО покупателя',
            BEAT_TITLE: 'Название бита',
            BEAT_PRICE: 'Цена бита',
            PASSPORT_CLIENT_DATA: 'Паспортные данные покупателя',
            ADDRESS_CLIENT_DATA: 'Адрес покупателя',
            LICENSE_NAME: 'Название лицензии',
            CURRENCY: 'Валюта',
        },
    },
    orders: {
        tableColumns: {
            id: 'Order ID',
            beatmakerId: 'Битмейкер ID',
            fromPaymentSystem: 'От куда',
            date: 'Дата',
            managerId: 'Менеджер ID',
            paymentSystem: 'Через',
            walletNumber: 'Номер кошелька',
        },
    },
    cashbacks: {
        tableColumns: {
            operation: 'Операция',
            amount: 'Количество',
            currency: 'Валюта',
            status: 'Статус',
            expirationDate: 'Действует до',
            paymentDate: 'Дата зачисления',
            payment: 'Покупка',
            income: 'Доход',
        },
        helpText: 'Можно потратить:',
        statuses: {
            notUser: 'Не использован',
            partiallyOverdue: 'Частично просрочен',
            isAvailable: 'Доступен',
            used: 'Использован',
        },
        tooltip:
            ' Система Cashback дает дополнительную скидку для постоянных покупателей, для автора бита разница скидки будет возвращена монетами с увеличением на 30%',
    },
    myBeats: {
        beatSettings: {
            general: 'Общая информация',
            trackDetails: 'Детали трека',
            license: 'Лицензии и стоимость',
            files: 'Файлы для лицензий',
            audiofileForStreaming: 'Аудиофайл для прослушивания',
            supportArt: 'JPG, PNG, TIF',
            changeCover: 'Изменить обложку',
            licenseFiles: 'Файлы для лицензии:',
            changeCost: 'Изменить цену',
            cost: 'Цена',
            addTag: '+ Добавить',
        },
        noLicenseMessage: 'Выберите лицензию',
        publish: 'Опубликовать',
        publicationsTextPopUp: 'Вы действительно хотите опубликовать бит',
        showArchive: 'Показать биты в архиве',
        fileProcessing: 'Идёт обработка файла, дождитесь окончания',
        changePrice: 'Изменить цену',
        licenseAndPrice: {
            title: 'Лицензии и цены',
            message:
                'Ниже приведён список ваших лицензий. Отметьте те, которые доступны для загружаемого бита и укажите цену покупки инструментала для отмеченной лицензии.',
        },
        filesForPlayer: {
            demoDownloading: 'Разрешить скачивание бита',
            title: 'Файлы для проигрывания в плеере',
            message:
                'Загрузите файл, который будет проигрываться на странице вашего профиля, в чарте или каталоге битов нашего сайта, а также на вашем личном сайте через встраиваемый виджет. Не забудьте защитить эту версию бита своим тегом.',
        },
        filesForSale: {
            title: 'Файлы для отправки покупателю',
            message:
                'Загрузите файлы, которые получит покупатель после приобретения вашего инструментала. Установите соответствие между загруженными файлами и типами лицензий. Один и тот же файл можно назначить для разных лицензий.',
        },
        deleteBeat: 'Удалить бит',
        removeFromCatalog: 'Вы действительно хотите удалить бит из каталога?',
        removeBeat: 'Вы действительно хотите удалить бит навсегда?',
        restoreBeat: 'Восстановить бит',
        restoreBeatInCatalog: 'Восстановить бит в каталоге?',
        general: 'Основная информация',
        selling: 'Предпочтения по продаже',
        details: 'Детали трека',
        popupUploadsMessage: {
            drag: 'Отпустите файл чтобы загрузить',
            noDrag: 'Перетащите файл, чтобы загрузить',
            urlFileCloud: 'Или ссылка на файл в облаке',
            selectFileButton: 'Выберите файл',
        },
        tableColumns: {
            title: 'Название',
            chart: 'Позиция',
            downloads: 'Скачиваний',
            listening: 'Прослушиваний',
            action: '',
        },
        addBeat: 'Загрузить бит',
        beatWithTag:
            'Убедитесь, что загружаете .mp3 с тегом, файл используется для воспроизведения на сайте и его могут скачать или записать.',
        addTags: '+ Добавить тег',
        addAnotherSample: 'Добавить другой образец',
        sampleSource: 'Ссылка на сайт',
        sampleTitle: 'Название',
        editLicense: 'Редактировать список',
        formsField: {
            beatTitle: 'Название бита',
            tags: 'Теги',
            trackType: 'Тип трека',
            releaseDate: 'Дата релиза',
            urlOfYourTrack: 'Сслыка на трек',
            audioFilesForDownload: 'Аудио файлы для загрузки',
            audioFileForStreaming: 'Файлы для прослушивания',
            mp3: '.MP3 без тегов',
            updateFileMp3: 'Обновить MP3',
            uploadFile: 'Загрузить файл',
            uploadFileDescription: 'Загружайте только .mp3 файлы с аудио тегом',
            wav: '.WAV без тегов',
            trackout: 'TRACKOUT .ZIP',
            mp3WithTag: '.MP3 с аудио тегом',
            notFoSale: 'Не для продажи',
            private: 'Приватный',
            isExclusive: 'Предложения только для эксклюзивной лицензии',
            artwork: 'Загрузить обложку',
            freeDownloadOption: {
                title: 'Настройка бесплатной загрузки',
                no: 'Нет',
                yesWithTags: 'Да, с голосовым тегом (MP3 Версия)',
                yesUntagged: 'Да, без тегов',
                allowAnonymousDownload:
                    'Разрешить загрузку анонимным пользователям',
            },
            loops: 'Я использовал образцы сторонних производителей',
            genre: 'Стиль',
            mood: 'Настроение',
            description: 'Описание',
            bmp: 'BPM',
            creationMethod: {
                title: 'Метод создания',
                composing: 'Авторское сочинение',
                sampling: 'Сэмплирование',
            },
            errors: {
                minBpmError: `не может быть меньше ${BeatsConstants.MIN_BPM}`,
                maxBpmError: `не может быть больше ${BeatsConstants.MAX_BPM}`,
                maxLengthError: (maxLength: number) =>
                    `Максимальная длина ${maxLength} символов`,
                numberError: 'Должно быть числом',
            },
        },
        editBeat: 'Редактировать бит',
        noBeats:
            'У Вас нет загруженных битов, нажмите загрузить и добавьте свой первый бит',
        noArchiveBeats: 'У Вас нет архивных битов',
        deletePopUpDialogMessage: {
            title: 'Удалить файл',
        },
    },
    licenses: {
        form: {
            title: 'Название лицензии',
            titleEn: 'Название лицензии на английском',
            removeBeatFromSale: 'Снять бит с продажи',
            recommended: 'Рекомендована',
            requireClientPassport:
                'Требовать для договора паспортные данные клиента',
            purchaseIsNotAvailable: 'Оплата недоступна',
        },
        helpTextForCheckbox: {
            removeBeatFromSale:
                'Если стоит галочка, то когда покупатель оплатит данный тип лицензии, бит будет автоматически снят с продажи.',
            recommended:
                'Если стоит галочка, то данная лицензия будет визуально выделена как рекомендованная к покупке на странице бита, а так же на "Странице продюсера"',
            requireClientPassport:
                'Если для вашего варианта договора вам неоходимы помимо ФИО ещё и паспортные данные покупателя, поставьте галочку и мы соберём эти данные у клиента',
            purchaseIsNotAvailable:
                '(в данный момент функция не работает) Если поставить галочку, то покупатель не сможет оплатить данный тип лицензии. Вместо этого ему будет предложено связаться с вами для обсуждения деталей.',
            priceInRubles:
                'Цена присваивается автоматически каждому биту. Выставить цену для отдельного бита можно на странице редактирования бита.',
            priceInDollars:
                'Настройки лицензии на английском языке используются в англоязычных версиях страницы продюсера, виджета и сайта BEATMAKER.TV (BEATMAKERS.TV)',
            defaultTrackPrice: 'Цена трека по умолчанию',
        },
        licenseOptions: {
            title: 'Список опций',
            titleRu: 'на русском',
            titleEn: 'на английском',
        },
    },
    mySales: {
        saleDetails: {
            paid: 'Дата покупки',
            status: 'Статус',
            salesInfo: 'Информация о продаже',
            buyer: 'Покупатель',
            emailBuyer: 'Email покупателя',
            fullName: 'Полное имя',
            orderSummary: 'Детали покупки',
            nameBeat: 'Название бита',
            contract: 'Договор',
            license: 'Лицензия',
            saleId: 'ID',
            totalGross: 'Общий итог',
            comissionSite: 'Комиссия сайта',
            cashback: 'Оплата кэшбеком',
            pay: 'Оплата через',
            total: 'Итого',
        },
        rootPayment: {
            fields: {
                id: 'ID',
                beatName: 'Бит',
                dateTime: 'Дата & Время',
                total: 'Сумма',
                withdrawalDate: 'Дата вывода',
                actions: 'Действия',
                money: 'Сумма',
                amount: 'Оплачено',
                cashback: 'Оплачено кешбеком',
                serviceFeeAmount: 'Комиссия сайта',
                beatmakerPureMoney: 'Итого',
                paidDate: 'Дата вывода',
                changeToCoins: 'Обменять на монеты',
            },
        },
        orderSummary: {
            title: 'Продажа',
            fields: {
                id: 'id',
                dateTime: 'Дата & Время',
                paymentSystem: 'Оплата через',
                paymentSource: 'Сервис',
            },
        },
        orderPayment: {
            title: 'Перевод',
            fields: {
                money: 'Сумма',
                coupon: 'Скидочный купон',
                cashback: 'Олачено кешбеком',
                percent: 'Комиссия сайта',
                conclusion: 'Итого',
                status: 'Статус',
            },
            isDirectWallet: 'Оплачено на личный кошелек',
            changeToCoins: 'Обменять на монеты',
            moneyWithdrawal: 'Деньги выведены ',
        },
        orderCustomer: {
            title: 'Покупатель',
            fields: {
                name: 'Профиль',
                fullName: 'Полное имя',
                email: 'E-mail',
            },
        },
        orderItems: {
            title: 'Биты',
            fields: {
                license: 'Лицензия',
                saveContract: 'Договор',
                coupon: 'Скидка',
            },
        },
        statistics: {
            fields: {
                itemsSold: 'Проданные биты',
                gross: 'Оплачено',
                discount: 'Сумма скидок',
                total: 'Полученная сумма',
                conclusion: 'Сумма к выводу',
                serviceFee: 'Комиссия сайта',
            },
            conclusionTitle: 'Условия для вывода',
            conclusionText:
                'Чтобы вывести деньги, которые поступили на кошельки сайта, напишите в личные сообщения аккаунта поддержки',
            condition: 'Условия для вывода:',
            condition1: '- На балансе больше 1000р.',
            condition2: '- С момента продажи прошло минимум 3 дня.',
            condition3: '- Указан минимум один кошелек для вывода.',
            condition4:
                'Для аккаунтов, у которых подключена подписка премиум или эксперт, есть возможность настроить прием прямой оплаты на личные кошельки YooMoney и Paypal.',
            wallets: 'Указать кошельки для вывода',
            directWallets: 'Настроить кошельки',
        },
        emailsBuyers: 'Скачать email покупателей',
        confirmDialog: {
            title: 'Обмен денег на монеты',
            text: 'Монеты будут куплены на всю сумму. Подтвердить?',
        },
        deletePopup: {
            title: 'Do you really want to delete license',
        },
    },
    settings: {
        cashback: 'Участвовать в программе cashback',
        tabs: {
            cashback: 'Кэшбек',
            wallets: 'Настройки кошельков',
            profile: 'Настройки профиля',
            notifications: 'Уведомления',
            security: 'Пользователь и безопастность',
            paidBeats: 'Платные',
            freeBeats: 'Бесплатные',
            archivedBeats: 'Архив',
        },
        wallets: {
            tinkoff: {
                confirmRemoveCard: 'Вы действительно хотите удалить?',
                removeCard: 'Удалить карту',
                bindNewCard: 'Привязать новую карту',
                cards: 'Привязка карты',
                termsTitle: 'Условия использования сервиса',
                terms: {
                    term1: '1. Комиссия сайта. На тарифе «Базовый» комиссия сайта составит 20%, «Премиум» 10%, «Эксперт» 10%. Минимальная комиссия не может быть меньше 50р.',
                    term2: '2. Вывод денежных средств. Вывод будет сделан автоматически, ровно через сутки с момента продажи. В случае если не привязана карта, то в течение пары часов после привязки карты при условии, что сутки с момента продажи уже прошли.',
                    term3: '3. Привязка карты. Если Продавец не привязал карту в течение 60 дней, сделка аннулируется и деньги возвращаются покупателю.',
                },
                cardList: 'Список карт',
                cardLinkInfo:
                    'Привяжите банковскую карту, чтобы начать продавать биты',
                steps: {
                    step1: 'Шаг 1',
                    step2: 'Шаг 2',
                },
                stepsInstructions: {
                    step1: `Привяжите карту нажав на кнопку "Привязать новую карту". Всего можно привязать три карты.
                            После успешной привязки карта отобразится в списке ниже.`,
                    step2: `Активируйте карту, на которую хотите получать выплаты, кликнув по ней. 
                            После успешной активации индикатор слева от номера карты станет синим. 
                            Активной может быть только одна карта.`,
                },
                extra: `Более подробную инструкцию, а также информацию о комиссиях можно прочитать `,
            },
            paypal: {
                inputTitle: 'Email от Paypal',
                notWalletMessage: 'Кошелек не указан',
                paypalActive: 'Активирован',
                linkTitle: 'Настройка',
                title: 'Инструкция по привязке',
                subTitle:
                    'Сама процедура привязки состоит из одного простого шага:',
                manuals: {
                    1: 'На стороне Beatmaker.tv на данной странице вам нужно указать номер вашего Paypal-кошелька (email)',
                },
                steps: {
                    step1: 'Шаг 1',
                    step1Desc:
                        'Укажите ваш кошелек Paypal, он же email от вашего аккаунта Paypal (может не совпадать с email, использующимся для входа на наш сервис beatmaker.tv)',
                },
            },
            yooMoney: {
                title: 'Привязка кошелька YooMoney',
                manuals: {
                    1: 'Подписку Премиум или Эксперт на аккаунте Beatmaker.tv оплатить подписку можно по ссылке -',
                    2: 'Идентифицированный кошелек YooMoney - все об идентификации -',
                },
                steps: {
                    step1: 'Шаг 1',
                    step2: 'Шаг 2',
                    step3: 'Шаг 3',
                    step4: 'Шаг 4',
                    step5: 'Шаг 5',
                    step6: 'Шаг 6',
                    step7: 'Шаг 7',
                },
                stepsInstructions: {
                    step1: 'Скопируйте секретный URL адрес',
                    step2: 'Переходим на сайт YooMoney. Ссылка на страницу нужных нам настроек',
                    step3: 'Вставляем ключ с Beatmaker.tv в поле «URL сайта»',
                    step4: 'Включаем функцию "Отправлять HTTP-уведомления"',
                    step5: 'Генерируем секретный ключ YooMoney.',
                    step6: 'Вставьте ниже секретный ключ и номер кошелька, после обязательно нажмите сохранить.',
                    step7: 'Проводим активацию кошелька.',
                },
                requirements:
                    'Для привязки личного кошелька YooMoney необходимо иметь 2 вещи:',
                secretKey: 'Секретный ключ',
                secretURL: 'Секретный URL адрес',
                identification: 'Идентификация',
                walletActivate: 'Активация кошелька',
                testingPayment:
                    'Для активации кошелька перейдите по ссылке и проведите тестовую оплату',
                activateDescription:
                    'Сайт перебросит на страницу выбора метода оплаты выбираем оплатить с помощью карты (не забудьте перед оплатой разлогиниться на сайте YooMoney) и оплачиваем 10р. Если все сделано правильно, то деньги поступят на указанный кошелек и статус кошелька изменится на «Активировано».',
            },
        },
    },
    myCoins: {
        coins: 'Монеты',
        buyCoinsInputTitle: 'Кол-во монет:',
        bonusCoins: 'Кол-во бонусных монет:',
        bonusItemTitle:
            'Покупая единоразово большее кол-во монет, Вы получаете бонус',
        bonusItems: {
            500: 'от 500 монет - размер бонуса 100 монет',
            300: 'от 300 монет - размер бонуса 40 монет',
            200: 'от 200 монет - размер бонуса 15 монет',
            100: 'от 100 монет - размер бонуса 10 монет',
        },
        tableTitle: 'История начисления монет',
        tableColumns: {
            operation: 'Операция',
            description: 'Описание',
            amount: 'Кол-во',
            balance: 'Баланс',
            date: 'Дата',
            payment: 'Списание',
            income: 'Поступление',
        },
        coinsBalance: 'Баланс монет',
        coinsSale: 'сейчас! скидка на монеты',
    },
    beatEdit: {
        filesTableColumn: {
            fileName: 'Имя файла',
            createdDate: 'Дата создания',
            fileSize: 'Размер',
            action: 'Действия',
        },
    },
    notifications: {
        subscriptions: {
            0: 'Личные сообщения',
            1: 'Комментарии к моим заявкам',
            2: 'Комментарии к моим битам',
            3: 'Комментариях к моим статьям',
            100: 'Важные новости сайта',
        },
        notifyTitle: {
            0: 'Никогда',
            2: 'Раз в день',
            5: 'моментально',
        },
    },
    discounts: {
        coupons: 'Купоны',
        discounts: 'Групповые скидки',
        tabs: {
            coupons: 'Купоны',
            groupDiscounts: 'Групповые скидки',
        },
        tableColumn: {
            coupon: 'Купон',
            status: 'Статус',
            discounts: 'Скидка',
            licenses: 'Лицензия',
            dueDate: 'Действительна до',
            type: 'Тип',
            purchasedBeats: 'Продано битов',
            action: 'Действия',
            name: 'Название',
        },
        deleteDialog: {
            title: 'Удалить скидку',
            description: 'Вы уверены, что хотите удалить?',
        },
        allLicense: 'Все лицензии',
        editPopUp: {
            title: 'Редактировать скидку',
            status: 'Статус',
            license: 'Лицензия',
            from: 'Начать',
            due: 'Завершить',
            discount: 'Скидка, %',
            purchased: 'Куплено битов',
            amountBeats: 'Кол-во битов',
            code: 'Купон',
        },
        groupDiscounts: {
            buy: 'Купи',
            tracks: 'Трек(-а,-ов)',
            get: 'Получи',
            free: 'Бесплатно',
            popUp: {
                title: 'Добавить новую скидку',
                countCart: 'Кол-во битов в корзине',
                countFree: 'Кол-во бесплатных битов',
                license: 'Лицензия',
            },
        },
    },
    beatmakerSettings: {
        fields: {
            myId: 'Мой ID',
            nickname: 'Nickname',
            country: 'Страна',
            instagram: 'Instagram',
            facebook: 'Facebook',
            twitter: 'Twitter',
            twitch: 'Twitch',
            email: 'Email',
            soundcloud: 'Soundcloud',
            whatsApp: 'WhatsApp',
            youtube: 'Youtube',
            vk: 'ВКонтакте',
            telegram: 'Телеграм',
            viber: 'Viber',
            software: 'Программное обеспечение',
            clients: 'Клиенты',
            description: 'Описание',
            social: 'Социальные сети',
            secInfo: 'Дополнительная информация',
            generalInfo: 'Основная информация',
            uniqueUrlName: 'Адрес моей страницы',
        },
        wallets: {
            form: 'Заполните форму',
            emailToPaypal: 'Email от Paypal*',
        },
        nicknameTooltip: {
            text: 'Nickname меняется в течении 24 часов',
        },
    },
    producerPage: {
        title: 'Страница продюсера',
        goToProducerPage: 'Перейти на страницу настроек',
        customizeInfo:
            'Настройте страницу по своему вкусу. Изменение цветов отдельного компонента. Просто сделай это!',
        domainName: 'Доменное имя',
        subDomainName: 'Имя субдомена',
        descriptionDomainName:
            'Щелкните здесь, чтобы узнать, как настроить имя домена',
        descriptionSubDomainName:
            'Щелкните здесь, чтобы узнать, как настроить имя субдомена',
    },
    errorMessages: {
        emailNotValid: 'Введите правильный email',
    },
    widget: {
        mainPageTitle: 'Виджет',
        settingsBlockTitle: 'Настройки виджета',
        analyticsBlockTitle: 'Аналитика',
        backToMainPage: 'Вернуться на страницу виджетов',
        name: 'Список виджетов',
        notFound: 'Еще не добавлено ни одного виджета',
        fields: {
            title: 'Название виджета',
            width: 'Ширина виджета (px)',
            height: 'Высота виджета (px)',
            isWidthResponsive: 'Адаптировать ширину виджета под экран',
            lang: 'Язык виджета',
            selectPlayingBeatTitle:
                'Выберите бит, который будет проигрываться автоматически при запуске виджета',
            automaticallyProduceBeat: 'Выбрать бит',
            iframe: 'Скопируйте и вставьте этот код на вашу страницу',
            url: 'Виджет URL',
            tracklistSelect: 'Выберите трек лист',
            selectAllBeats: 'Загрузить все биты из каталога',
            selectCustomBeats: 'Выберите биты',
        },
        popup: {
            addBeatsTitle: 'Добавьте биты в виджет',
            deleteTitle: 'Удалить',
            deleteMessage: 'Вы действительно хотите удалить виджет',
            publishedDate: 'Дата публикации',
            chooseAll: 'Выбрать все',
        },
    },
    formMessages: {
        requiredTitle: 'Поле не должно быть пустым',
        minTitle: 'Длина title быть больше 3 символов',
        maxTitle: 'Длина title не должна превышать 50 символов',
        minWidgetHeight: 'Мин. высота 100 px',
        maxWidgetHeight: 'Макс. высота 5000 px',
        minWidgetWidth: 'Мин. ширина 100 px',
        maxWidgetWidth: 'Макс. ширина 10000 px',
    },
}
