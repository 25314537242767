import { ISubscribers } from 'DAL/SubscribersAPI'
import { LanguageType } from 'translations/ru/common'
import { getLocaleDateString } from 'UI/utils/util-types'
import React from 'react'
import { TableType } from '../types'
import { i18n } from 'i18next'

export const getSubscribersTableData = (
    i18n: i18n
): TableType<ISubscribers> => {
    const { customerBeatSubscribers, sourceType } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType
    return [
        {
            title: customerBeatSubscribers.tableColumns.subscriptionDate,
            field: 'subscribedAt',
            render: (subscriber: ISubscribers) => (
                <div>
                    {getLocaleDateString(
                        i18n.language,
                        subscriber.subscribedAt
                    )}
                </div>
            ),
        },
        //@ts-ignore
        {
            title: customerBeatSubscribers.tableColumns.email,
            //@ts-ignore
            field: 'subscriber.email',
        },
        {
            title: customerBeatSubscribers.tableColumns.name,
            //@ts-ignore
            field: 'subscriber.name',
        },
        {
            title: customerBeatSubscribers.tableColumns.pageSubscription,
            field: 'sourceType',
            render: (subscriber: ISubscribers) => (
                <div>{sourceType[+subscriber.sourceType]}</div>
            ),
        },
    ]
}
