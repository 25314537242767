import React, { FC } from 'react'
import classes from './MobMySales.module.scss'
import usd from '../../../assets/icons/usd.svg'
import rub from '../../../assets/icons/rub.svg'
import { Button } from '../../../common/elements/buttons/Button'
import { MySalesTrackInfo } from './MySalesTrackInfo/MySalesTrackInfo'
import { AppSettings } from 'settings/appSettings'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { LanguageType } from '../../../../translations/ru/common'
import { MySalesPricePropsType } from '../../CustomerMySales/MySalesPrice/MySalesPrice'
import { PaymentTypeBLL } from '../../../../BLL/MySalesReducer'
import { generateAndDownloadTxtFile } from '../../../utils/util-types'
import { CustomerRM } from '../../../routers/RouterManager'
import { NavLink } from 'react-router-dom'

type PropsType = {
    payments: PaymentTypeBLL[]
    statistics: MySalesPricePropsType
}
export const MobMySales: FC<PropsType> = ({ statistics, payments }) => {
    const isEng = AppSettings.api.isEngVersion()
    const { i18n } = useTranslation()
    const dispatch = useDispatch()
    const { navigation, mySales, buttons } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType
    const handleDownloadBuyersList = () => {
        const items = payments.map((p) => {
            return `${
                p.sales[0].clientFullname
                    ? p.sales[0].clientFullname
                    : p.sales[0].buyerName
            }, ${p.sales[0].buyerEmail}`
        })
        generateAndDownloadTxtFile(items, 'buyer')
    }
    return (
        <div className={classes.MobMySales}>
            <div className={classes.amountsBox}>
                <span className={classes.text}>
                    {mySales.statistics.fields.conclusion}
                </span>
                <div className={classes.price}>
                    <span className={classes.usd}>
                        <img src={usd} alt="" />
                        {statistics?.usdAmounts}
                    </span>
                    {!isEng && (
                        <span className={classes.rub}>
                            <img src={rub} alt="" />
                            {statistics?.rubAmounts}
                        </span>
                    )}
                </div>
            </div>
            <div className={classes.btnBoxWithdraw}>
                <NavLink to={CustomerRM.mySales.path.withdrawal}>
                    <Button view={'Primary'} title={buttons.WITHDRAW} />
                </NavLink>
            </div>
            <div className={classes.stats}>
                <div className={classes.item}>
                    <span className={classes.titleItem}>
                        {mySales.statistics.fields.itemsSold}
                    </span>
                    <span className={classes.count}>
                        {statistics?.beatsCount}
                    </span>
                </div>
                <div className={classes.item}>
                    <span className={classes.titleItem}>
                        {mySales.statistics.fields.gross}
                    </span>
                    <span className={classes.count}>
                        <img src={usd} alt="" /> {statistics?.usdPaid}
                    </span>
                    {!isEng && (
                        <span className={classes.count}>
                            <img src={rub} alt="" /> {statistics?.rubPaid}
                        </span>
                    )}
                </div>
                <div className={classes.item}>
                    <span className={classes.titleItem}>
                        {mySales.statistics.fields.serviceFee}
                    </span>
                    <span className={classes.count}>
                        <img src={usd} alt="" />
                        {statistics?.usdComission}
                    </span>
                    {!isEng && (
                        <span className={classes.count}>
                            <img src={rub} alt="" />
                            {statistics?.rubComission}
                        </span>
                    )}
                </div>
                <div className={classes.item}>
                    <span className={classes.titleItem}>
                        {mySales.statistics.fields.total}
                    </span>
                    <span className={classes.count}>
                        <img src={usd} alt="" /> {statistics.usdReceived}
                    </span>
                    {!isEng && (
                        <span className={classes.count}>
                            <img src={rub} alt="" /> {statistics.rubReceived}
                        </span>
                    )}
                </div>
            </div>
            <div className={classes.boxBtnStats}>
                <Button
                    view={'Outline'}
                    title={mySales.emailsBuyers}
                    onClick={handleDownloadBuyersList}
                />
            </div>
            {payments.map((payment) => (
                <div className={classes.trackInfo} key={payment.id}>
                    <MySalesTrackInfo payment={payment} />
                </div>
            ))}
        </div>
    )
}
