import React from 'react'
import { LanguageType } from 'translations/ru/common'
import { useTranslation } from 'react-i18next'
import s from './status.module.scss'

export type StatusType =
    | 'Confirmed' //4
    | 'Waiting' //1
    | 'Rejected' //2
    | 'Clarification' //3
    | 'Closed'
    | 'Active' //0
    | 'NotActive' //0

type Props = {
    status: StatusType
}

export const Status = ({ status }: Props) => {
    const { i18n } = useTranslation()
    const { statuses } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    return (
        <div className={s.wrapper}>
            <span className={`${s.circle} ${s[status]}`} />
            <p className={s.textStatus}>{statuses[status]}</p>
        </div>
    )
}
