import { InferActionsTypes } from './Store'
import { AuthActionsTypes } from './AppReducer'
import { NullableType, P2PKeysType, ThunkActionType } from './types'
import { commonAsyncHandler, completeOperation } from './common-async-handler'
import {
    CardList,
    UpdateEnoWalletType,
    WalletsAPI,
    WalletsType,
    WalletsWithdrawal,
} from 'DAL/walletsAPI'

const initState = {
    wallets: null as NullableType<WalletsType>,
    walletsWithdrawal: [] as WalletsWithdrawal[],
    cards: null as NullableType<CardList[]>,
    redirectUrl: null as NullableType<string>,
}

type StateType = typeof initState

export type ActionsTypes =
    | InferActionsTypes<typeof walletsActions>
    | AuthActionsTypes

export const walletsReducer = (
    state = initState,
    action: ActionsTypes
): StateType => {
    switch (action.type) {
        case 'WALLETS_REDUCER/SET_WALLETS':
            return { ...state, wallets: action.wallets }
        case 'WALLETS_REDUCER/SET_WALLETS_WITHDRAWAL':
            return { ...state, walletsWithdrawal: action.wallets }
        case 'WALLETS_REDUCER/SET_CARDS':
            return { ...state, cards: action.cards }
        case 'WALLETS_REDUCER/SET_REDIRECT_URL':
            return { ...state, redirectUrl: action.redirectURL }
        default:
            return state
    }
}

export const walletsActions = {
    fetchedWallets: (wallets: WalletsType) =>
        ({
            type: 'WALLETS_REDUCER/SET_WALLETS',
            wallets,
        } as const),
    fetchWalletsWithdrawal: (wallets: WalletsWithdrawal[]) =>
        ({
            type: 'WALLETS_REDUCER/SET_WALLETS_WITHDRAWAL',
            wallets,
        } as const),
    fetchedCards: (cards: CardList[]) =>
        ({ type: 'WALLETS_REDUCER/SET_CARDS', cards } as const),
    fetchedRedirectURL: (redirectURL: string) =>
        ({ type: 'WALLETS_REDUCER/SET_REDIRECT_URL', redirectURL } as const),
}

export const fetchedCards =
    (): ThunkActionType<ActionsTypes> => async (dispatch) => {
        await commonAsyncHandler(async () => {
            const response = await WalletsAPI.getCards()
            dispatch(walletsActions.fetchedCards(response))
        }, dispatch)
    }

export const removeCard =
    (cardId: string): ThunkActionType<ActionsTypes> =>
    async (dispatch) => {
        await commonAsyncHandler(async () => {
            const response = await WalletsAPI.deleteCard(cardId)
            completeOperation(response, dispatch)
            await dispatch(fetchedCards())
        }, dispatch)
    }

export const selectActiveCard =
    (cardId: string): ThunkActionType<ActionsTypes> =>
    async (dispatch) => {
        const response = await WalletsAPI.activateCard(cardId)
        completeOperation(response, dispatch)
        await dispatch(fetchedCards())
    }

export const fetchedAddCard =
    (): ThunkActionType<ActionsTypes> => async (dispatch) => {
        const response = await WalletsAPI.addCard()
        dispatch(walletsActions.fetchedRedirectURL(response.data.url))
    }

export const fetchedWallets =
    (): ThunkActionType<ActionsTypes> => async (dispatch) => {
        await commonAsyncHandler(async () => {
            const response = await WalletsAPI.getWallets()
            dispatch(walletsActions.fetchedWallets(response))
        }, dispatch)
    }

export const fetchedEnotWallet =
    (): ThunkActionType<ActionsTypes> => async (dispatch) => {
        await commonAsyncHandler(async () => {
            const response = await WalletsAPI.getEnotWallet()
            dispatch(walletsActions.fetchedWallets(response))
        }, dispatch)
    }

export const fetchedTinkoffWallet =
    (): ThunkActionType<ActionsTypes> => async (dispatch) => {
        await commonAsyncHandler(async () => {
            const response = await WalletsAPI.tinkoffWallets()
            dispatch(walletsActions.fetchedWallets(response))
        }, dispatch)
    }

export const fetchedUpdatePaypal =
    (email: string): ThunkActionType<ActionsTypes> =>
    async (dispatch) => {
        await commonAsyncHandler(async () => {
            const response = await WalletsAPI.updatePaypal(email)
            completeOperation(response, dispatch, () => {
                dispatch(fetchedWallets())
            })
            return response
        }, dispatch)
    }

export const fetchedUpdateEnotWallet =
    (data: UpdateEnoWalletType): ThunkActionType<ActionsTypes> =>
    async (dispatch) => {
        await commonAsyncHandler(async () => {
            const response = await WalletsAPI.updateEnotWallet(data)
            completeOperation(response, dispatch, () => {
                dispatch(fetchedEnotWallet())
            })
            return response
        }, dispatch)
    }

export const fetchUpdateQiwiP2PKeys =
    (keys: P2PKeysType): ThunkActionType<ActionsTypes> =>
    async (dispatch) => {
        await commonAsyncHandler(async () => {
            const response = await WalletsAPI.updateQiwiP2PKeys(keys)
            completeOperation(response, dispatch, () => {
                dispatch(fetchedWallets())
            })
            return response
        }, dispatch)
    }

export const fetchedUpdateYandex =
    (secret: string, wallet: number): ThunkActionType<ActionsTypes> =>
    async (dispatch) => {
        await commonAsyncHandler(async () => {
            const response = await WalletsAPI.updateYandex(secret, wallet)
            completeOperation(response, dispatch, () => {
                dispatch(fetchedWallets())
            })
            return response
        }, dispatch)
    }

export const fetchedDeactivateYandex =
    (): ThunkActionType<ActionsTypes> => async (dispatch) => {
        await commonAsyncHandler(async () => {
            const response = await WalletsAPI.deactivateYandex()
            console.log(response)
            completeOperation(response, dispatch, () => {
                dispatch(fetchedWallets())
            })
            return response
        }, dispatch)
    }
