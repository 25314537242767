import { InferActionsTypes } from './Store'
import { actions as appActions, AuthActionsTypes } from './AppReducer'
import { commonAsyncHandler } from './common-async-handler'
import {
    BeatmakerValidation,
    BeatmakerWallets,
    ClosePaymentsType,
    MoneyWithdrawal,
    paymentsAPI,
    ResponseTypePayments,
    TablePaymentType,
} from '../DAL/paymentsAPI'
import { NullableType, ResultCodesEnum, ThunkActionType } from './types'
import { CONSTANTS } from './SupportingFile'
import { PaymentTypeBLL, reducePayments } from './MySalesReducer'
import { CurrencyEnum } from '../DAL/CashbackAPI'

const initState = {
    payments: null as NullableType<TablePaymentType[]>,
    paymentsRub: null as NullableType<PaymentTypeBLL[]>,
    paymentsUsd: null as NullableType<PaymentTypeBLL[]>,
    moneyWithdrawals: [] as MoneyWithdrawal[],
    beatmakerIdWithWallets: null as NullableType<BeatmakerWallets>,
    beatmakerIdWithBeatmakersValidations:
        null as NullableType<BeatmakerValidation>,
    page: 1,
    pageSize: 5,
    pagesCount: 0,
    totalCount: 0,
}

type StateType = typeof initState
export type ActionsTypes =
    | InferActionsTypes<typeof paymentsActions>
    | AuthActionsTypes

export const AdminPaymentsReducer = (
    state = initState,
    action: ActionsTypes
): StateType => {
    switch (action.type) {
        case 'PAYMENTS/SET_PAYMENTS':
            return {
                ...state,
                paymentsRub: action.paymentsRub,
                paymentsUsd: action.paymentsUsd,
            }
        case 'PAYMENTS/DELETE_PAYMENTS':
            return {
                ...state,
                payments: state.payments
                    ? state.payments.filter(
                          (p) => p.beatmakerId !== action.beatmakerId
                      )
                    : [],
            }
        case 'PAYMENTS/SET_MONEY_WITHDRAWAL':
            return {
                ...state,
                moneyWithdrawals: action.moneyWithdrawals.items,
                pagesCount: action.moneyWithdrawals.pagesCount,
                totalCount: action.moneyWithdrawals.totalCount,
            }
        case 'PAYMENTS/SET_PAGE':
            return {
                ...state,
                page: action.page,
            }
        case 'PAYMENTS/SET_PAGE_SIZE':
            return {
                ...state,
                pageSize: action.pageSize,
            }
        case 'PAYMENTS/SET_BEATMAKER_WALLETS':
            return {
                ...state,
                beatmakerIdWithWallets: action.beatmakerWallets,
            }
        case 'PAYMENTS/SET_BEATMAKER_VALIDATION':
            return {
                ...state,
                beatmakerIdWithBeatmakersValidations:
                    action.beatmakerIdWithBeatmakersValidations,
            }
        default:
            return state
    }
}

export const paymentsActions = {
    setPayments: (
        paymentsRub: PaymentTypeBLL[],
        paymentsUsd: PaymentTypeBLL[]
    ) =>
        ({
            type: 'PAYMENTS/SET_PAYMENTS',
            paymentsRub,
            paymentsUsd,
        } as const),
    deletePayments: (beatmakerId: number) =>
        ({ type: 'PAYMENTS/DELETE_PAYMENTS', beatmakerId } as const),
    setBeatmakerIdWithWallets: (beatmakerWallets: BeatmakerWallets) =>
        ({
            type: 'PAYMENTS/SET_BEATMAKER_WALLETS',
            beatmakerWallets,
        } as const),
    setBeatmakerIdWithValidation: (
        beatmakerIdWithBeatmakersValidations: BeatmakerValidation
    ) =>
        ({
            type: 'PAYMENTS/SET_BEATMAKER_VALIDATION',
            beatmakerIdWithBeatmakersValidations,
        } as const),
    setWithdrawals: (
        moneyWithdrawals: ResponseTypePayments<MoneyWithdrawal[]>
    ) =>
        ({
            type: 'PAYMENTS/SET_MONEY_WITHDRAWAL',
            moneyWithdrawals,
        } as const),
    setPage: (page: number) => ({ type: 'PAYMENTS/SET_PAGE', page } as const),
    setPageSize: (pageSize: number) =>
        ({ type: 'PAYMENTS/SET_PAGE_SIZE', pageSize } as const),
}

export const getPayments =
    (
        onlyNotPaid: boolean,
        beatmakerId?: number
    ): ThunkActionType<ActionsTypes> =>
    async (dispatch) => {
        await commonAsyncHandler(async () => {
            const response = await paymentsAPI.getPayments(
                onlyNotPaid,
                beatmakerId
            )
            dispatch(
                paymentsActions.setBeatmakerIdWithWallets(
                    response.beatmakerIdWithWallets
                )
            )
            dispatch(
                paymentsActions.setBeatmakerIdWithValidation(
                    response.beatmakerIdWithBeatmakersValidations
                )
            )
            const rootPayments = groupPaymentsByUser(
                reducePayments(response.items)
            )
            const paymentByUserRub = paymentByUserGroupForCurrency(
                rootPayments,
                CurrencyEnum.rub
            ) as PaymentTypeBLL[]
            const paymentByUserUsd = paymentByUserGroupForCurrency(
                rootPayments,
                CurrencyEnum.usd
            ) as PaymentTypeBLL[]
            if (paymentByUserRub && paymentByUserUsd) {
                dispatch(
                    paymentsActions.setPayments(
                        paymentByUserRub.filter((v) => v),
                        paymentByUserUsd.filter((v) => v)
                    )
                )
            }
            return response
        }, dispatch)
    }

export const closePayments =
    (
        payload: ClosePaymentsType,
        beatmakerId: number
    ): ThunkActionType<ActionsTypes> =>
    async (dispatch) => {
        await commonAsyncHandler(async () => {
            const data = await paymentsAPI.closePayments(payload)
            if (data.resultCode === ResultCodesEnum.Success) {
                dispatch(getPayments(true))
                dispatch(
                    appActions.showSnackbar(
                        'success',
                        CONSTANTS.SUCCESS_MESSAGE
                    )
                )
            } else {
                const message = data?.messages.length
                    ? data.messages[0]
                    : CONSTANTS.ERROR_MESSAGE
                dispatch(appActions.showSnackbar('error', message as string))
            }
            return data
        }, dispatch)
    }

export const getWithdrawals =
    (page: number, pageSize: number): ThunkActionType<ActionsTypes> =>
    async (dispatch) => {
        return await commonAsyncHandler(async () => {
            const data = await paymentsAPI.getWithdrawals(page, pageSize)

            dispatch(paymentsActions.setWithdrawals(data))
            return data
        }, dispatch)
    }

export const groupPaymentsByUser = (payments: PaymentTypeBLL[]) => {
    return payments.reduce((acc, item) => {
        if (acc[item.sales[0].beatmakerId]) {
            acc[item.sales[0].beatmakerId].push(item)
        } else {
            acc[item.sales[0].beatmakerId] = [item]
        }
        return acc
    }, {} as { [key: string]: PaymentTypeBLL[] })
}

export const paymentByUserGroupForCurrency = (
    rootPayments: { [key: string]: PaymentTypeBLL[] },
    currency: CurrencyEnum
) => {
    return Object.keys(rootPayments).map((key) => {
        return rootPayments[key].reduce((acc, v) => {
            if (v.currencyName === currency) {
                if (acc) {
                    acc = {
                        ...acc,
                        salesByPayment: {
                            ...acc.salesByPayment,
                            [v.id]: v.sales,
                        },
                        rootPaymentIds: [...acc.rootPaymentIds, v.id],
                        beatmakerPureMoney:
                            acc.beatmakerPureMoney + v.beatmakerPureMoney,
                        sales: [...acc.sales, ...v.sales],
                    }
                } else {
                    acc = { ...v, salesByPayment: { [v.id]: v.sales } }
                }
            }
            return acc
        }, null as NullableType<PaymentTypeBLL>)
    })
}

export const calculatePayments = (
    acc: { [key: string]: PaymentTypeBLL },
    item: PaymentTypeBLL
) => {
    if (acc[item.userId]) {
        acc[item.userId] = {
            ...acc[item.userId],
            beatmakerPureMoney:
                acc[item.userId].beatmakerPureMoney + item.beatmakerPureMoney,
            amount: acc[item.userId].amount + item.amount,
            sales: [...acc[item.userId].sales, ...item.sales],
        }
    } else {
        acc[item.userId] = item
    }
    return acc
}

// export const _getPayments = (onlyNotPaid: boolean, beatmakerId?: number): ThunkActionType => async (dispatch) => {
//     await commonAsyncHandler(async () => {
//         const response = await paymentsAPI.getPayments(onlyNotPaid, beatmakerId);
//         const payments = response.items.reduce((acc, value) => {
//             const couponData: CouponData = value.paymentDetails.couponData ? JSON.parse(value.paymentDetails.couponData) : null
//             let discountSize = null
//             let code = null
//             if (couponData) {
//                 discountSize = couponData.DiscountSize
//                 code = couponData.Code
//             }
//             let item = acc[value.beatmakerSale.beatmakerId]
//             const couponMoney = discountSize ? discountSize / 100 * value.beatmakerSale.money : 0
//             const percentMoney = value.beatmakerSale.percent / 100 * (value.beatmakerSale.money - value.paymentDetails.payment.cashbackAmount - couponMoney)
//             let sumForCashRub = item ? item.sumForCashRub : 0
//             let sumForCashUsd = item ? item.sumForCashUsd : 0
//             let sumBeatsPriceRub = item ? item.sumBeatsPriceRub : 0
//             let sumBeatsPriceUsd = item ? item.sumBeatsPriceUsd : 0
//             if (value.beatmakerSale.paymentSystem === PaymentSystemTypes.PaypalUsd) {
//                 sumBeatsPriceUsd = item ? item.sumBeatsPriceUsd + value.beatmakerSale.money : value.beatmakerSale.money
//                 sumForCashUsd = item ? item.sumForCashUsd + (value.beatmakerSale.money - percentMoney - couponMoney) : value.beatmakerSale.money - percentMoney - couponMoney
//             } else {
//                 sumBeatsPriceRub = item ? item.sumBeatsPriceRub + value.beatmakerSale.money : value.beatmakerSale.money
//                 sumForCashRub = item ? item.sumForCashRub + (value.beatmakerSale.money - percentMoney - couponMoney) : value.beatmakerSale.money - percentMoney - couponMoney
//             }
//             acc[value.beatmakerSale.beatmakerId] = {
//                 ...item,
//                 beatmakerId: value.beatmakerSale.beatmakerId,
//                 fromWallet: null,
//                 toWallet: null,
//                 beatmakerValidation: item ? item.beatmakerValidation : value.beatmakerValidation,
//                 blockPayment: item ? item.blockPayment : value.blockPayment,
//                 sumBeatsPriceRub: sumBeatsPriceRub,
//                 sumBeatsPriceUsd: sumBeatsPriceUsd,
//                 sumForCashRub: Number(sumForCashRub.toFixed(2)),
//                 sumForCashUsd: Number(sumForCashUsd.toFixed(2)),
//                 beatmakerName: value.beatmakerSale.beatmakerName,
//                 wallets: value.wallets,
//                 details: item ? [...item.details, {
//                     ...value.beatmakerSale,
//                     DiscountSize: discountSize,
//                     Code: code,
//                     cashbackAmount: value.paymentDetails.payment.cashbackAmount,
//                     paymentSource: value.paymentDetails.payment.paymentSource,
//                     licenseTitle: value.paymentDetails.licenseTitle,
//                     licenseTitleEng: value.paymentDetails.licenseTitleEng,
//                     paymentSystem: value.paymentDetails.payment.paymentSystem,
//                     paidDate: value.beatmakerSale.paidDate,
//                     directWalletsId: value.paymentDetails.payment.directWalletsId,
//                     moneyWithdrawalId: value.paymentDetails.payment.moneyWithdrawal && value.paymentDetails.payment.moneyWithdrawal.id
//                 }] : [{
//                     ...value.beatmakerSale,
//                     licenseTitle: value.paymentDetails.licenseTitle,
//                     cashbackAmount: value.paymentDetails.payment.cashbackAmount,
//                     licenseTitleEng: value.paymentDetails.licenseTitleEng,
//                     paymentSource: value.paymentDetails.payment.paymentSource,
//                     DiscountSize: discountSize,
//                     Code: code,
//                     paymentSystem: value.paymentDetails.payment.paymentSystem,
//                     paidDate: value.beatmakerSale.paidDate,
//                     directWalletsId: value.paymentDetails.payment.directWalletsId,
//                     moneyWithdrawalId: value.paymentDetails.payment.moneyWithdrawal && value.paymentDetails.payment.moneyWithdrawal.id
//                 }]
//             };
//             return acc;
//         }, {} as AccType);
//         dispatch(paymentsActions.setPayments(Object.values(payments)));
//         return response;
//     }, dispatch);
// };
