import { InferActionsTypes } from './Store'
import { commonAsyncHandler, completeOperation } from './common-async-handler'
import {
    actions as appActions,
    AuthActionsTypes,
    EditingEntityStatuses,
} from './AppReducer'
import {
    MySalesAPI,
    MySalesType,
    PaymentBeatDetails,
    PaymentType,
    SaleType,
} from '../DAL/mySalesAPI'
import { ThunkActionType } from './types'

export type PaymentTypeBLL = {
    money: number
    salesByPayment?: { [key: string]: Array<SaleType> }
    rootPaymentIds: number[]
    coupon?: boolean
    sales: Array<SaleType & PaymentBeatDetails>
} & PaymentType

const initState = {
    items: [] as MySalesType[],
    rootPayments: [] as PaymentTypeBLL[],
}

type IState = typeof initState
export type ActionsTypes = InferActionsTypes<typeof actions> | AuthActionsTypes

const MySalesReducer = (state = initState, action: ActionsTypes): IState => {
    switch (action.type) {
        case 'MY_SALES_REDUCER/SET_ITEMS':
            return { ...state, items: action.items }
        case 'MY_SALES_REDUCER/SET_ROOT_PAYMENTS':
            return { ...state, rootPayments: action.payments }
        default:
            return state
    }
}

export const actions = {
    setItems: (items: MySalesType[]) =>
        ({
            type: 'MY_SALES_REDUCER/SET_ITEMS',
            items,
        } as const),
    setRootPayment: (payments: PaymentTypeBLL[]) =>
        ({
            type: 'MY_SALES_REDUCER/SET_ROOT_PAYMENTS',
            payments,
        } as const),
}

export const fetchItems =
    (): ThunkActionType<ActionsTypes> => async (dispatch) => {
        await commonAsyncHandler(async () => {
            const response = await MySalesAPI.getSales()
            const rootPayments = reducePayments(response.items)
            dispatch(
                actions.setRootPayment(rootPayments.sort((a, b) => b.id - a.id))
            )
            dispatch(actions.setItems(response.items))
            return response
        }, dispatch)
    }

export const fetchExchangeMoneyToCoins =
    (paymentId: number): ThunkActionType<ActionsTypes> =>
    async (dispatch) => {
        await commonAsyncHandler(async () => {
            const response = await MySalesAPI.exchangeMoneyToCoins(paymentId)
            completeOperation(response, dispatch, () => {
                dispatch(fetchItems())
                dispatch(
                    appActions.setEditingEntityStatus(
                        EditingEntityStatuses.Success
                    )
                )
            })
            return response
        }, dispatch)
    }
//look at the file name Payments beatmaker doc Sales
//https://docs.google.com/presentation/d/16NZn4c1Ri4jlfq2Zx8vz5Xhc6xU3PLYmvr9SKP8Rf9c/edit#slide=id.ge16874c70e_0_0
export const reducePayments = (payments: MySalesType[]): PaymentTypeBLL[] => {
    return payments.reduce((acc, item) => {
        const paymentIndex = acc.findIndex(
            (a) => a.id === item.rootPayment?.id || a.id === item.payment.id
        )
        if (paymentIndex > -1) {
            acc[paymentIndex] = {
                ...acc[paymentIndex],
                money: acc[paymentIndex].money + item.sale.money,
                sales: [
                    ...acc[paymentIndex].sales,
                    { ...item.sale, ...item.paymentBeatDetails },
                ],
            }
        } else {
            const rootPayment = item.rootPayment
                ? item.rootPayment
                : item.payment
            const rootPaymentId = item.rootPayment
                ? item.rootPayment.id
                : item.payment.id
            acc.push({
                ...rootPayment,
                rootPaymentIds: [rootPaymentId],
                money: item.sale.money,
                sales: [{ ...item.sale, ...item.paymentBeatDetails }],
            })
        }
        return acc
    }, [] as PaymentTypeBLL[])
}

export default MySalesReducer
