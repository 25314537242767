export const readAsArrayBuffer = (blob: Blob): Promise<ArrayBuffer> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => {
            if (reader.result instanceof ArrayBuffer) {
                resolve(reader.result)
            } else {
                reject(new Error('Result is not an ArrayBuffer'))
            }
        }
        reader.onerror = reject
        reader.readAsArrayBuffer(blob)
    })
}
