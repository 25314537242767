import React, { ReactNode, useEffect, useState } from 'react'
import { deviceDetect } from 'react-device-detect'

type Props = {
    size?: number
    children?: ReactNode
}
export const CustomMobileView = ({ children, size = 1000 }: Props) => {
    const [isVisible, setIsVisible] = useState(false)

    const handleResize = (event?: any) => {
        if (
            event?.currentTarget.document.defaultView.innerWidth < size ||
            deviceDetect().isMobile
        ) {
            setIsVisible(true)
        } else {
            setIsVisible(false)
        }
    }
    useEffect(() => {
        if (window.innerWidth < size) {
            setIsVisible(true)
        } else {
            setIsVisible(false)
        }
    }, [])

    useEffect(() => {
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])
    return <> {isVisible && children}</>
}
