import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'
import { useFormContext } from 'react-hook-form'
import { BeatLicenseType } from 'DAL/Services'
import {
    actions as contractActions,
    getUserLicenses,
} from 'BLL/ContractsReducer'
import { useDispatch } from 'react-redux'
import {
    getBeatById,
    getFilesBindingsToLicenses,
    myBeatsActions,
    updateBeat,
    uploadBeatFileFileByLinkForSale,
    uploadBeatFileForSale,
    uploadNewBeatFile,
} from 'BLL/CustomerMyBeatsReducer'
import { useParams, NavLink } from 'react-router-dom'
import { UploadBeatModal } from '../uploadBeatModal/UploadBeatModal'
import { UpdateBeatContract } from 'DAL/BeatSettingsAPI'
import { getBeatsPublications } from 'BLL/CustomerBeatsPublicationReducer'
import { useRedirectCustomHook } from 'UI/common/hooks/useRedirectCustomHook'
import { RolesTypeEnum } from 'BLL/enums'
import { CustomerRM } from 'UI/routers/RouterManager'
import { AppSettings } from 'settings/appSettings'
import { UseBeatSettingsSelectors } from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/beatSettings/hooks/useBeatSettingsSelectors'
import { Button } from 'UI/common/elements/buttons/Button'
import { TrackFiles } from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/beatSettings/trackFiles/TrackFiles'
import { TrackLicenses } from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/beatSettings/trackLicenses/TrackLicenses'
import { TrackDetails } from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/beatSettings/trackDetails/TrackDetails'
import { useDeviceDetect } from 'UI/common/hooks/useDeviceDetect'
import { MainInfo } from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/beatSettings/mainForm/MainInfo'
import { actions as appActions } from 'BLL/AppReducer'
import { bindingsLicenseHandle } from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/beatSettings/handlers/bindingsLicenseHandle'
import { beatLicenseNotDefaultPriceHandle } from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/beatSettings/handlers/beatLicenseNotDefaultPriceHandle'
import { ChangeBindLicensePriceHandle } from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/beatSettings/handlers/changeBindLicensePriceHandle'
import { ChangeDefaultLicensePriceHandle } from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/beatSettings/handlers/changeDefaultLicensePriceHandle'
import { SelectedModal } from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/types/types'
import { submitDto } from './forms/submitDto'
import ArrowLeft from 'UI/assets/icons/arrow-left.svg'
import s from '../beatspage.module.scss'

export type UpdateBeat = {
    moodId: { value: number; label: string | undefined }
    styleId: { value: number; label: string | undefined }
    publicationDate?: string
    publicationTime?: string
    playUrl: string
} & Omit<UpdateBeatContract, 'moodId' | 'styleId'>

export const BeatSettings = () => {
    const { i18n } = useTranslation()
    const { beatId } = useParams<{ beatId: string }>()
    const { myBeats, buttons } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    const {
        currentBeat,
        filesBindedToLicenses,
        licenses,
        moods,
        styles,
        beatmakerId,
        publications,
    } = UseBeatSettingsSelectors()

    const dispatch = useDispatch()

    const [disable, setDisable] = useState(false)
    const [modalIsActive, setModalIsActive] = useState<{
        isActive: boolean
        type: SelectedModal
    }>({
        isActive: false,
        type: 'sale',
    })

    const [artwork, setArtWork] = useState('')
    const [demoDownloading, setDemoDownloading] = useState(false)
    const [tags, setTags] = useState<string[]>([])
    const [bindingsLicense, setBindingsLicense] = useState<BeatLicenseType[]>(
        []
    )
    const [beatLicenseNotDefaultPrice, setBeatLicenseNotDefaultPrice] =
        useState<BeatLicenseType[]>([])

    const { setValue, handleSubmit, setError } = useFormContext()

    useEffect(() => {
        dispatch(getUserLicenses())
        dispatch(getBeatById(+beatId))
        dispatch(getBeatsPublications())
        dispatch(getFilesBindingsToLicenses(+beatId))

        return () => {
            dispatch(myBeatsActions.setCurrentBeat(null))
            dispatch(myBeatsActions.setFilesBindedToLicenses(null))
            dispatch(contractActions.setBeatLicenses([]))
        }
    }, [beatId, beatmakerId, dispatch, i18n.language])

    useEffect(() => {
        if (currentBeat) {
            setDemoDownloading(currentBeat.contextBeat.demoDownloading)
            setArtWork(
                `${
                    AppSettings.urls.CORE_BASE_URL
                }/Default/General/Image?id=${beatId}&type=BeatImageOriginal&ver=${new Date().getTime()}`
            )
            setTags(currentBeat.tags)

            if (licenses.length) {
                setBindingsLicense(bindingsLicenseHandle(currentBeat, licenses))
                setBeatLicenseNotDefaultPrice(
                    beatLicenseNotDefaultPriceHandle(currentBeat, licenses)
                )
            }
        }
    }, [currentBeat, licenses, beatId, moods, publications, setValue, styles])

    useEffect(() => {
        modalIsActive && setDisable(false)
    }, [modalIsActive])

    const onSubmit = handleSubmit((values: UpdateBeat) => {
        if (!bindingsLicense.length) {
            dispatch(appActions.showSnackbar('info', myBeats.noLicenseMessage))
            return
        }
        const uniqueBindingsLicense = [
            ...new Map(bindingsLicense.map((item) => [item.id, item])).values(),
        ]
        if (currentBeat && values.moodId && values.styleId) {
            dispatch(
                updateBeat(
                    submitDto(
                        values,
                        beatId,
                        currentBeat,
                        demoDownloading,
                        tags,
                        uniqueBindingsLicense,
                        beatLicenseNotDefaultPrice
                    )
                )
            )
        } else {
            if (!values.moodId) {
                setError('moodId', {
                    type: 'string',
                    message: 'Field is required',
                })
            }
            !values.styleId &&
                setError('styleId', {
                    type: 'string',
                    message: 'Field is required',
                })
        }
    })

    const handleSelectLicense = (lic: BeatLicenseType, checked: boolean) => {
        if (checked) {
            setBindingsLicense([...bindingsLicense, lic])
        } else {
            setBindingsLicense(
                bindingsLicense.filter((license) => license.id !== lic.id)
            )
        }
    }

    const handleChangeDefaultLicensePrice = (
        lic: BeatLicenseType,
        checked: boolean,
        priceDollar: number | null,
        price: number | null
    ) => {
        ChangeDefaultLicensePriceHandle({
            checked,
            beatLicenseNotDefaultPrice,
            lic,
            setBeatLicenseNotDefaultPrice,
            price,
            priceDollar,
            setBindingsLicense,
            bindingsLicense,
        })
    }

    const handleChangeBindLicensePrice = (
        price: number,
        licId: string,
        isDollar?: boolean
    ) => {
        ChangeBindLicensePriceHandle({
            price,
            licId,
            isDollar,
            setBeatLicenseNotDefaultPrice,
            beatLicenseNotDefaultPrice,
        })
    }

    const handleSetTags = (tags: string[]) => {
        if (tags.length <= 5) {
            setTags(tags)
        }
    }

    const handleModalUploadFileIsActive = (isActive: boolean) => {
        setModalIsActive({ ...modalIsActive, isActive })
    }

    const saveFileHandler = async (
        formData: FormData,
        onUploadProgress: (progressEvent: any) => void,
        cancelToken: any,
        type?: SelectedModal
    ) => {
        setDisable(true)
        if (type === 'sale') {
            await dispatch(
                uploadBeatFileForSale(
                    +beatId,
                    formData,
                    onUploadProgress,
                    cancelToken
                )
            )
        } else {
            await dispatch(
                uploadNewBeatFile(
                    formData,
                    onUploadProgress,
                    +beatId,
                    cancelToken
                )
            )
        }
        setDisable(false)
    }

    const addFileByLink = async (url: string) => {
        setDisable(true)
        await dispatch(uploadBeatFileFileByLinkForSale(+beatId, url))
        setModalIsActive({ ...modalIsActive, isActive: false })
    }

    const wrapperContentClassName = useDeviceDetect()
        ? 'wrapperContentMobile'
        : 'wrapperContent'

    return (
        <div className={s.root}>
            {useRedirectCustomHook(RolesTypeEnum.BEATMAKER)}
            <div className={s.back}>
                <NavLink to={CustomerRM.myBeats.path.beats.base}>
                    <img src={ArrowLeft} alt={'back-items'} />
                    <span>{buttons.BACK_TO_ITEMS}</span>
                </NavLink>
            </div>
            <div className={s.titleBlock}>{myBeats.beatSettings.general}</div>
            <div className={s[wrapperContentClassName]}>
                <form onSubmit={onSubmit}>
                    {currentBeat && (
                        <MainInfo
                            artwork={artwork}
                            setArtWork={setArtWork}
                            beatName={currentBeat.beat.name}
                            beatId={+beatId}
                            setTags={handleSetTags}
                            tags={tags}
                            setModalIsActive={setModalIsActive}
                            demoDownloading={demoDownloading}
                            setDemoDownloading={setDemoDownloading}
                        />
                    )}
                    <TrackDetails />
                    <TrackLicenses
                        bindingsLicense={bindingsLicense}
                        beatLicenseNotDefaultPrice={beatLicenseNotDefaultPrice}
                        handleChangeBindLicensePrice={
                            handleChangeBindLicensePrice
                        }
                        handleSelectLicense={handleSelectLicense}
                        handleChangeDefaultLicensePrice={
                            handleChangeDefaultLicensePrice
                        }
                        filesBindedToLicenses={filesBindedToLicenses}
                    />
                    <div>
                        {filesBindedToLicenses && (
                            <TrackFiles
                                beatId={beatId}
                                files={filesBindedToLicenses.files}
                                setModalIsActive={setModalIsActive}
                            />
                        )}
                    </div>
                    <div className={s.wrapperButtonFooter}>
                        <NavLink to={CustomerRM.myBeats.path.beats.base}>
                            <Button view={'Secondary'} title={buttons.CANCEL} />
                        </NavLink>
                        <Button title={buttons.SAVE} />
                    </div>
                </form>
            </div>
            <UploadBeatModal
                modalIsActive={modalIsActive.isActive}
                type={modalIsActive.type}
                setModalIsActive={handleModalUploadFileIsActive}
                saveFileHandler={saveFileHandler}
                disable={disable}
                setDisable={setDisable}
                addFileByLink={addFileByLink}
            />
        </div>
    )
}
