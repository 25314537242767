import React, { FC } from 'react'
import classes from './GroupDiscount.module.scss'
import { DiscountGroupType } from '../../../../../DAL/DiscountAPI'
import { AppSettings } from 'settings/appSettings'
import { NavLink } from 'react-router-dom'
import { CustomerRM } from '../../../../routers/RouterManager'
import DeleteIcon from '../../../../assets/icons/delete.svg'
import { LanguageType } from '../../../../../translations/ru/common'
import { useTranslation } from 'react-i18next'

type PropsType = {
    bulkDiscount: DiscountGroupType
}
export const MobileGroupDiscount: FC<PropsType> = ({ bulkDiscount }) => {
    const isEng = AppSettings.api.isEngVersion()
    const { i18n } = useTranslation()
    const { discounts, buttons } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType
    return (
        <div className={classes.GroupDiscount}>
            <div className={classes.list}>
                <div className={classes.item}>
                    <span className={classes.descr}>
                        {discounts.tableColumn.name}:
                    </span>
                    <span className={classes.value}>
                        {discounts.groupDiscounts.buy}{' '}
                        {bulkDiscount.buyedLicenseCount}{' '}
                        {discounts.groupDiscounts.tracks},{' '}
                        {discounts.groupDiscounts.get}{' '}
                        {bulkDiscount.bonusLicenseCount}{' '}
                        {discounts.groupDiscounts.free}!
                    </span>
                </div>
                <div className={classes.item}>
                    <span className={classes.descr}>
                        {discounts.groupDiscounts.popUp.license}:
                    </span>
                    <span className={classes.licenses}>
                        {isEng
                            ? bulkDiscount.buyedLicense?.titleEn
                            : bulkDiscount.buyedLicense?.title}
                    </span>
                </div>
            </div>
            <div className={classes.btnBox}>
                <NavLink
                    to={CustomerRM.discounts.delete.getUrl(bulkDiscount.id)}
                >
                    <img
                        className={classes.icons}
                        src={DeleteIcon}
                        alt={'delete'}
                    />
                </NavLink>
            </div>
        </div>
    )
}
