import React from 'react'
import classes from './MySalesPayment.module.scss'
import usd from '../../../../../assets/icons/usd.svg'
import rub from '../../../../../assets/icons/rub.svg'
import { useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import { LanguageType } from '../../../../../../translations/ru/common'
import { useSelector } from '../../../../../common/hooks/redux-hooks'
import { paymentSystemToString } from '../../../../../utils/util-types'
import { CurrencyEnum } from '../../../../../../DAL/CashbackAPI'

export const MySalesPayment = () => {
    const { id } = useParams<{ id: string }>()
    const { i18n } = useTranslation()
    const { myBeats, buttons, mySales } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType
    const rootPayment = useSelector((state) =>
        state.mySales.rootPayments.find((p) => p.id === +id)
    )
    const isUsd = rootPayment?.currencyName === CurrencyEnum.usd
    const currencyIcon = isUsd ? (
        <img className={classes.usdIcon} src={usd} alt="" />
    ) : (
        <img className={classes.rubIcon} src={rub} alt="" />
    )
    return (
        <div className={classes.MySalesPayment}>
            <div className={classes.list}>
                <div className={classes.item}>
                    <span className={classes.title}>
                        {mySales.saleDetails.totalGross}
                    </span>
                    <span className={classes.price}>
                        {rootPayment?.money} {currencyIcon}
                    </span>
                </div>
                <div className={classes.item}>
                    <span className={classes.title}>
                        {mySales.saleDetails.comissionSite}
                    </span>
                    <span className={classes.promo}>
                        - {rootPayment?.serviceFeeAmount}
                        {currencyIcon}
                    </span>
                </div>
                <div className={classes.item}>
                    <span className={classes.title}>
                        {mySales.saleDetails.cashback}
                    </span>
                    <span className={classes.cashback}>
                        - {rootPayment?.cashbackAmount}
                        {currencyIcon}
                    </span>
                </div>
                <div className={classes.item}>
                    <span className={classes.title}>
                        {mySales.saleDetails.pay}
                    </span>
                    <span className={classes.payVia}>
                        {rootPayment &&
                            paymentSystemToString(rootPayment.paymentSystem)}
                    </span>
                </div>
            </div>
            <div className={classes.total}>
                <span className={classes.text}>
                    {mySales.saleDetails.total}
                </span>
                <span className={classes.value}>
                    {rootPayment?.beatmakerPureMoney}
                    {currencyIcon}
                </span>
            </div>
        </div>
    )
}
