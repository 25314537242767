import { adminBeatsAxios } from './createAxios'
import { M3U8Statuses, BaseResponse } from '../BLL/types'
import { PaginationResponseType } from './BeatSettingsAPI'

export type GetBeatsModelType = {
    M3U8Status?: M3U8Statuses
    beatId?: number
}

type AdminBeatEntityType = {
    id: number
    demoDownloading: boolean
    beatmakerId: number
    beatmaker: null
    minPrice: number | null
    minPriceInDollars: number | null
    name: string
    playUrl: string
    fileName: string
    m3U8Status: M3U8Statuses
    m3U8StatusMessage: string | null
}

export const adminBeatsAPI = {
    async getBeats(
        page: number = 1,
        pageSize: number = 100,
        params: GetBeatsModelType | null = null
    ) {
        if (!params) params = {}

        return await adminBeatsAxios
            .get<PaginationResponseType<AdminBeatEntityType>>(
                'manager/beats/beats',
                {
                    params: {
                        page,
                        pageSize,
                        ...params,
                    },
                }
            )
            .then((r) => r.data)
    },

    async getBeatFilesDetails(id: number) {
        return await adminBeatsAxios
            .get<BaseResponse<{ mp3: boolean; m3u8: boolean }>>(
                'manager/beats/beatFilesDetails?id=' + id
            )
            .then((r) => r.data)
    },
    async generateM3U8(id: number) {
        return await adminBeatsAxios
            .post<BaseResponse<any>>('manager/beats/GenerateM3U8?id=' + id)
            .then((r) => r.data)
    },
}
