import React from 'react'
import s from 'UI/CustomerAdmin/BeatmakerSettingsNew/profile-settings/settings-profile.module.scss'
import { BeatmakerContactsType } from 'DAL/BeatmakerAPI'
import { LanguageType } from 'translations/ru/common'
import { Control, FieldError } from 'react-hook-form'
import { BeatmakerFormValues } from 'UI/CustomerAdmin/BeatmakerSettingsNew/wallet-settings/wallets/enot/types'
import { ControlledTextField } from 'UI/common/controls/controlledTextField/ControlledTextField'

type ContactsType = {
    field: keyof BeatmakerContactsType
    title: string
    isShow: boolean
    placeholder?: string
}
type Props = {
    beatmakerSettings: Pick<
        LanguageType,
        'beatmakerSettings'
    >['beatmakerSettings']
    isEng: boolean
    control: Control<BeatmakerFormValues>
    error: FieldError
}
export const SocialMedia = ({
    beatmakerSettings,
    isEng,
    control,
    error,
}: Props) => {
    const contacts: ContactsType[] = [
        {
            field: 'instagram',
            title: beatmakerSettings.fields.instagram,
            isShow: true,
            placeholder: 'https://www.instagram.com/yourprofile',
        },
        {
            field: 'facebook',
            title: beatmakerSettings.fields.facebook,
            isShow: true,
            placeholder: 'https://www.facebook.com/yourprofile',
        },
        {
            field: 'twitter',
            title: beatmakerSettings.fields.twitter,
            isShow: true,
            placeholder: 'https://twitter.com/yourprofile',
        },
        {
            field: 'twitch',
            title: beatmakerSettings.fields.twitch,
            isShow: true,
            placeholder: 'https://www.twitch.tv/yourprofile',
        },
        {
            field: 'email',
            title: beatmakerSettings.fields.email,
            isShow: true,
            placeholder: 'mailto:yourname@example.com',
        },
        {
            field: 'soundcloud',
            title: beatmakerSettings.fields.soundcloud,
            isShow: true,
            placeholder: 'https://soundcloud.com/yourprofile',
        },
        {
            field: 'whatsApp',
            title: beatmakerSettings.fields.whatsApp,
            isShow: false,
            placeholder: 'https://wa.me/1234567890',
        },
        {
            field: 'youtube',
            title: beatmakerSettings.fields.youtube,
            isShow: true,
            placeholder: 'https://www.youtube.com/c/yourchannel',
        },
        {
            field: 'vk',
            title: beatmakerSettings.fields.vk,
            isShow: !isEng,
            placeholder: 'https://vk.com/yourprofile',
        },
        {
            field: 'telegram',
            title: beatmakerSettings.fields.telegram,
            isShow: !isEng,
            placeholder: 'https://t.me/yourprofile',
        },
        {
            field: 'viber',
            title: beatmakerSettings.fields.viber,
            isShow: false,
            placeholder: 'viber://chat?number=%2B1234567890',
        },
    ]

    return (
        <div className={s.social}>
            <h2 className={s.title}>{beatmakerSettings.fields.social}</h2>
            <div className={s.socialOptions}>
                {contacts.map((contact) => {
                    return contact.isShow ? (
                        <div key={contact.field} className={s.generalItem}>
                            <ControlledTextField
                                label={contact.title}
                                name={`contacts.${contact.field}`}
                                control={control}
                                variant={'primary'}
                                error={error}
                                placeholder={contact?.placeholder}
                            />
                        </div>
                    ) : null
                })}
            </div>
        </div>
    )
}
