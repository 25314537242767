import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'
import { useSelector } from '../hooks/redux-hooks'
import {
    selectBeatMaker,
    selectExpert,
    selectIsOwner,
    selectUserId,
} from 'BLL/selectors/app-selectors'
import { Items } from './Items'
import { useDispatch } from 'react-redux'
import { fetchedBonusCoins } from 'BLL/CustomerCoinsReducer'
import { DefaultCoinsCount } from './enums'
import { ShopsMenuItems } from 'UI/common/menus/menuItems/shopsMenuItems'
import { ExpertsMenuItems } from './menuItems/expertsMenuItems'
import { BeatmakerMenuItems } from 'UI/common/menus/menuItems/beatmakerMenuItems'
import { OutSideMenuItems } from 'UI/common/menus/menuItems/outSideMenuItems'
import { OtherMenuItems } from 'UI/common/menus/menuItems/otherMenuItems'
import s from './menu.module.scss'

export const Menu = () => {
    const isBeatMaker = useSelector(selectBeatMaker)
    const expert = useSelector(selectExpert)
    const isOwner = useSelector(selectIsOwner)
    const userId = useSelector(selectUserId)
    const dispatch = useDispatch()

    const location = useLocation()
    const { i18n } = useTranslation()
    const translate = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    useEffect(() => {
        dispatch(fetchedBonusCoins(DefaultCoinsCount.Value))
    }, [dispatch])

    const beatMakerMenuItems = isBeatMaker ? BeatmakerMenuItems(translate) : []
    const outSideMenuItems = OutSideMenuItems({ translate, userId })
    const shopsMenuItems = ShopsMenuItems(translate, isOwner)
    const otherMenuItems = OtherMenuItems(translate)
    const expertMenuItems = expert ? ExpertsMenuItems(translate) : []

    const path = location.pathname.split('/')[1]

    return (
        <div className={s.menu}>
            <Items items={beatMakerMenuItems} location={path} />
            <Items items={expertMenuItems} location={path} />
            <Items items={shopsMenuItems} location={path} />
            <Items items={otherMenuItems} location={path} />
            <Items items={outSideMenuItems} location={path} isExternal={true} />
        </div>
    )
}
