import React from 'react'
import s from './PlayerControls.module.scss'
import { PlayerLogic, PlayerLogicTrackType } from '../../../Domain/PlayerLogic'

type PropsType = {
    mobMode: boolean
    player: PlayerLogic
    track: PlayerLogicTrackType
}

export const PlayerControls = (props: PropsType) => {
    const pauseHandler = () => {
        props.player.pause()
    }

    const playHandler = () => {
        props.player.play(props.track)
    }

    const prevTrackHandler = () => {
        props.player.playPrev()
    }

    const nextTrackHandler = () => {
        props.player.playNext()
    }

    return (
        <div className={props.mobMode ? s.playerControlsMob : s.playerControls}>
            <button
                className={
                    props.mobMode ? s.prevBeatButtonMob : s.prevBeatButton
                }
                onClick={prevTrackHandler}
            >
                {' '}
            </button>
            {props.player.isPlaying ? (
                <button
                    className={`${s.playBeatButton} ${s.pause}`}
                    onClick={pauseHandler}
                >
                    {' '}
                </button>
            ) : (
                <button
                    className={`${s.playBeatButton} ${s.play}`}
                    onClick={playHandler}
                >
                    {' '}
                </button>
            )}
            <button
                className={
                    props.mobMode ? s.nextBeatButtonMob : s.nextBeatButton
                }
                onClick={nextTrackHandler}
            >
                {' '}
            </button>
        </div>
    )
}
