import { LanguageEnum, WidgetThemeType } from '../BLL/enums'
import { widgetsAxios } from './createAxios'
import { MessagesType, ResultCodesEnum } from '../BLL/types'
import { MyBeatType } from './BeatSettingsAPI'

export const widgetsAPI = {
    getWidgets() {
        return widgetsAxios.get<WidgetType[]>('widgets')
    },
    createWidget(widget: any) {
        return widgetsAxios
            .post<BaseWidgetResponse<WidgetType>>('widgets', widget)
            .then((res) => res.data)
    },
    deleteWidget(id: string) {
        return widgetsAxios
            .delete<BaseWidgetResponse<{ message: string }>>(`widgets/${id}`)
            .then((res) => res.data)
    },
}

// TYPES
// TODO: BaseResponse наследовать от BaseWidgetResponse (попробовать)
export type BaseWidgetResponse<T = null> = {
    resultCode: ResultCodesEnum
    messages: MessagesType[]
    data: T
}

export type WidgetAnalyticsType = {
    googleAnalyticId: string
    yandexMetricId: string
    facebookPixelId: string
    vkPixelId: string
}

export type WidgetBeatType = MyBeatType & { isSelected: boolean }

export type WidgetType = {
    __v: number
    _id: string
    title: string
    height: number
    width: number
    isWidthResponsive: boolean
    lang: LanguageEnum
    autoplay: boolean
    autoplayBeatId: number
    beatmakerId: number
    color: {
        theme: WidgetThemeType
        styles: string
    }
    analytics: WidgetAnalyticsType
    beats: WidgetBeatType[]
}
