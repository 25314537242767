import { InferActionsTypes } from './Store'
import { AuthActionsTypes } from './AppReducer'
import { BalanceType, CoinsAPI, CoinsHistoryType } from 'DAL/CoinsAPI'
import { NullableType, ResultCodesEnum, ThunkActionType } from './types'
import { commonAsyncHandler } from './common-async-handler'
import { useDataCalculate } from 'BLL/hooks/useDataCalculate'

const initState = {
    coinsHistory: [] as CoinsHistoryType[],
    balance: null as NullableType<BalanceType>,
    redirectUrl: null as NullableType<string>,
    coinsCount: 5,
    bonusCoins: 0,
    money: 0,
    defaultPrice: 10,
    saleCount: 0,
    promoSettings: {
        priceInRub: 10,
        priceInUsd: 1,
    },
}

type StateType = typeof initState

export type ActionsTypes =
    | InferActionsTypes<typeof coinsActions>
    | AuthActionsTypes

export const coinsReducer = (
    state = initState,
    action: ActionsTypes
): StateType => {
    switch (action.type) {
        case 'COINS_REDUCER/SET_HISTORY_COINS':
            return { ...state, coinsHistory: action.coinsHistory }
        case 'COINS_REDUCER/SET_BALANCE':
            return { ...state, balance: action.balance }
        case 'COINS_REDUCER/SET_REDIRECT_URL':
            return { ...state, redirectUrl: action.redirectUrl }
        case 'COINS_REDUCER/SET_COINS_COUNT':
            return { ...state, coinsCount: action.coinsCount }
        case 'COINS_REDUCER/SET_BONUS_COINS':
            return {
                ...state,
                bonusCoins: action.bonusCoins,
            }
        case 'COINS_REDUCER/SET_MONEY':
            return { ...state, money: action.money }
        case 'COINS_REDUCER/SET_DEFAULT_PRICE':
            return { ...state, defaultPrice: action.defaultPrice }
        case 'COINS_REDUCER/SET_PROMO_SETTINGS':
            return {
                ...state,
                promoSettings: {
                    priceInRub: action.priceInRub,
                    priceInUsd: action.priceInUsd,
                },
            }
        case 'COINS_REDUCER/SET_PROMO_SALE':
            return {
                ...state,
                saleCount: action.saleCount,
            }
        default:
            return state
    }
}

export const coinsActions = {
    setMoney: (money: number) =>
        ({
            type: 'COINS_REDUCER/SET_MONEY',
            money,
        } as const),
    setCoins: (coinsCount: number) =>
        ({
            type: 'COINS_REDUCER/SET_COINS_COUNT',
            coinsCount,
        } as const),
    setBonusCoins: (bonusCoins: number) =>
        ({
            type: 'COINS_REDUCER/SET_BONUS_COINS',
            bonusCoins,
        } as const),
    fetchedHistoryCoins: (coinsHistory: CoinsHistoryType[]) =>
        ({
            type: 'COINS_REDUCER/SET_HISTORY_COINS',
            coinsHistory,
        } as const),
    fetchedBalance: (balance: BalanceType) =>
        ({
            type: 'COINS_REDUCER/SET_BALANCE',
            balance,
        } as const),
    fetchedPaidCoins: (redirectUrl: NullableType<string>) =>
        ({
            type: 'COINS_REDUCER/SET_REDIRECT_URL',
            redirectUrl,
        } as const),
    setDefaultPrice: (defaultPrice: number) =>
        ({ type: 'COINS_REDUCER/SET_DEFAULT_PRICE', defaultPrice } as const),
    setPromoSettings: (priceInRub: number, priceInUsd: number) =>
        ({
            type: 'COINS_REDUCER/SET_PROMO_SETTINGS',
            priceInRub,
            priceInUsd,
        } as const),
    setPromoSale: (saleCount: number) =>
        ({ type: 'COINS_REDUCER/SET_PROMO_SALE', saleCount } as const),
}

export const fetchedHistoryCoins =
    (): ThunkActionType<ActionsTypes> => async (dispatch) => {
        await commonAsyncHandler(async () => {
            const response = await CoinsAPI.getHistoryCoins()
            dispatch(coinsActions.fetchedHistoryCoins(response))
            return response
        }, dispatch)
    }

export const fetchedBalance =
    (): ThunkActionType<ActionsTypes> => async (dispatch) => {
        await commonAsyncHandler(async () => {
            const response = await CoinsAPI.getBalance()
            dispatch(coinsActions.fetchedBalance(response))
            return response
        }, dispatch)
    }

export const fetchedPaidCoins =
    (amount: number): ThunkActionType<ActionsTypes> =>
    async (dispatch) => {
        await commonAsyncHandler(async () => {
            const response = await CoinsAPI.paidCoins(amount)
            if (response.resultCode === ResultCodesEnum.Success) {
                dispatch(
                    coinsActions.fetchedPaidCoins(response.data.redirectUrl)
                )
            }
            return response
        }, dispatch)
    }

export const fetchedBonusCoins =
    (coinsCount: number): ThunkActionType<ActionsTypes> =>
    async (dispatch, getState) => {
        await commonAsyncHandler(async () => {
            const response = await CoinsAPI.getBonusCoins(+coinsCount)

            if (response.resultCode === ResultCodesEnum.Success) {
                const { data } = response
                const { coinsReducer } = getState()
                const { defaultPrice } = coinsReducer
                const { saleDate, currentDate } = useDataCalculate(
                    data.promoSettings.promoEndDate
                )

                dispatch(coinsActions.setBonusCoins(data.bonusCoins))
                dispatch(coinsActions.setMoney(data.totalPrice))
                dispatch(coinsActions.setDefaultPrice(data.defaultPrice))
                dispatch(
                    coinsActions.setPromoSettings(
                        data.promoSettings.priceInRub,
                        data.promoSettings.priceInUsd
                    )
                )

                if (data.promoSettings.isActive && saleDate > currentDate) {
                    const discountPercent =
                        ((defaultPrice - data.promoSettings.priceInRub) /
                            defaultPrice) *
                        100
                    dispatch(
                        coinsActions.setPromoSale(+discountPercent.toFixed(0))
                    )
                } else {
                    dispatch(coinsActions.setPromoSale(0))
                }
            }
            return response
        }, dispatch)
    }
