import { InferActionsTypes } from './Store'
import { commonAsyncHandler, completeOperation } from './common-async-handler'
import {
    actions as appActions,
    AuthActionsTypes,
    EditingEntityStatuses,
} from './AppReducer'
import {
    CreateDiscountGroupDTO,
    DiscountAPI,
    DiscountGroupType,
    DiscountType,
    UpdateDiscountDTO,
} from 'DAL/DiscountAPI'
import { LicenseAPI } from 'DAL/LicenseAPI'
import { ThunkActionType } from './types'

const initState = {
    discounts: [] as DiscountType[],
    bulkDiscounts: [] as DiscountGroupType[],
}

type StateType = typeof initState
export type ActionsTypes =
    | InferActionsTypes<typeof discountsActions>
    | AuthActionsTypes

export const CustomerDiscountsReducer = (
    state = initState,
    action: ActionsTypes
): StateType => {
    switch (action.type) {
        case 'DISCOUNTS_REDUCER/SET_DISCOUNTS':
            return {
                ...state,
                discounts: action.discounts,
            }
        case 'DISCOUNTS_REDUCER/SET_BULK_DISCOUNTS':
            return {
                ...state,
                bulkDiscounts: action.bulkDiscounts,
            }
        default:
            return state
    }
}

export const discountsActions = {
    setDiscounts: (discounts: DiscountType[]) =>
        ({
            type: 'DISCOUNTS_REDUCER/SET_DISCOUNTS',
            discounts,
        } as const),
    setBulkDiscounts: (bulkDiscounts: DiscountGroupType[]) =>
        ({
            type: 'DISCOUNTS_REDUCER/SET_BULK_DISCOUNTS',
            bulkDiscounts,
        } as const),
}

export const getDiscounts =
    (): ThunkActionType<ActionsTypes> => async (dispatch) => {
        await commonAsyncHandler(async () => {
            const discounts = await DiscountAPI.getDiscounts()
            const userLicenses = await LicenseAPI.getLicenses()
            const discountsWithLicenses = discounts.map((d) => ({
                ...d,
                licenses: userLicenses,
            }))
            dispatch(discountsActions.setDiscounts(discountsWithLicenses))
            return discounts
        }, dispatch)
    }

export const deleteDiscount =
    (discountId: string): ThunkActionType<ActionsTypes> =>
    async (dispatch, getState) => {
        await commonAsyncHandler(async () => {
            const response = await DiscountAPI.deleteDiscount(discountId)
            completeOperation(response, dispatch, () => {
                dispatch(
                    discountsActions.setDiscounts(
                        getState().discounts.discounts.filter(
                            (d) => d.id !== discountId
                        )
                    )
                )
            })
            return response
        }, dispatch)
    }

export const getBulkDiscounts =
    (): ThunkActionType<ActionsTypes> => async (dispatch) => {
        await commonAsyncHandler(async () => {
            const bulkDiscounts = await DiscountAPI.getBulkDiscounts()
            const userLicenses = await LicenseAPI.getLicenses()
            const bulk = bulkDiscounts.map((b) => ({
                ...b,
                buyedLicense:
                    userLicenses.find((l) => l._id === b.buyedLicenseId) ||
                    null,
                bonusLicense:
                    userLicenses.find((l) => l._id === b.bonusLicenseId) ||
                    null,
            }))
            dispatch(discountsActions.setBulkDiscounts(bulk))
            return bulkDiscounts
        }, dispatch)
    }

export const deleteBulkDiscount =
    (discountId: string): ThunkActionType<ActionsTypes> =>
    async (dispatch, getState) => {
        await commonAsyncHandler(async () => {
            const bulkDiscounts = getState().discounts.bulkDiscounts
            const response = await DiscountAPI.deleteBulkDiscounts(discountId)
            completeOperation(response, dispatch, () => {
                dispatch(
                    discountsActions.setBulkDiscounts(
                        bulkDiscounts.filter((b) => b.id !== discountId)
                    )
                )
                dispatch(
                    appActions.setEditingEntityStatus(
                        EditingEntityStatuses.Success
                    )
                )
            })
            return response
        }, dispatch)
    }

export const createBulkDiscount =
    (dto: CreateDiscountGroupDTO): ThunkActionType<ActionsTypes> =>
    async (dispatch) => {
        await commonAsyncHandler(async () => {
            const response = await DiscountAPI.createBulkDiscounts(dto)
            completeOperation(response, dispatch, () => {
                dispatch(getBulkDiscounts())
                dispatch(
                    appActions.setEditingEntityStatus(
                        EditingEntityStatuses.Success
                    )
                )
            })
            return response
        }, dispatch)
    }

export const createDiscount =
    (): ThunkActionType<ActionsTypes> => async (dispatch) => {
        await commonAsyncHandler(async () => {
            const response = await DiscountAPI.createDiscount()
            completeOperation(response, dispatch, () => {
                dispatch(getDiscounts())
            })
            return response
        }, dispatch)
    }

export const updateDiscount =
    (dto: UpdateDiscountDTO): ThunkActionType<ActionsTypes> =>
    async (dispatch) => {
        await commonAsyncHandler(async () => {
            const response = await DiscountAPI.updateDiscount(dto)
            completeOperation(response, dispatch, () => {
                dispatch(getDiscounts())
                dispatch(
                    appActions.setEditingEntityStatus(
                        EditingEntityStatuses.Success
                    )
                )
            })
            return response
        }, dispatch)
    }
