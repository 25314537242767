import React from 'react'
import { FreeBeatType } from 'DAL/BeatSettingsAPI'
import { FreeBeatsItem } from 'UI/CustomerAdmin/FreeBeatsPage/freeBeatsItem/FreeBeatsItem'

type Props = {
    beats: FreeBeatType[]
}

export const FreeBeatsTableItem = ({ beats }: Props) => {
    return (
        <div>
            {beats.map((beat, index) => (
                <FreeBeatsItem beat={beat} index={index} key={beat.id} />
            ))}
        </div>
    )
}
