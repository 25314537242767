import React, { FC } from 'react'
import classes from './buttons.module.scss'

type PropsType = {
    handleSaveFile?: (files: File | FileList) => void
    title: string
}
export const BrowseLinkButton: FC<PropsType> = ({ handleSaveFile, title }) => {
    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.target.files &&
            handleSaveFile &&
            handleSaveFile(event.target.files)
    }

    return (
        <div>
            <input
                accept={''}
                className={classes.inputButton}
                id={'upload-file'}
                type="file"
                onChange={handleOnChange}
            />
            <label htmlFor={'upload-file'}>
                <div className={classes.browseButton}>{title}</div>
            </label>
        </div>
    )
}
