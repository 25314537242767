import { CoinsHistoryType } from 'DAL/CoinsAPI'
import { LanguageType } from 'translations/ru/common'
import { getLocaleDateString } from 'UI/utils/util-types'
import React from 'react'
import { i18n } from 'i18next'
import { TableType } from 'UI/common/table/types'

export const getCoinsHistoryTableData = (
    i18n: i18n
): TableType<CoinsHistoryType> => {
    const { myCoins } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType
    return [
        {
            title: myCoins.tableColumns.operation,
            field: 'id',
            render: (data) => {
                const isPayment = data.addedMoney < 0
                return (
                    <div>
                        {isPayment
                            ? myCoins.tableColumns.payment
                            : myCoins.tableColumns.income}
                    </div>
                )
            },
        },
        {
            title: myCoins.tableColumns.description,
            field: 'id',
            render: (data) => {
                return <div>{data.description}</div>
            },
        },
        {
            title: myCoins.tableColumns.amount,
            field: 'id',
            render: (data) => {
                return data.addedMoney <= 0 ? (
                    <div style={{ fontWeight: 700 }}>
                        {'- '}
                        {-data.addedMoney}
                    </div>
                ) : (
                    <div style={{ fontWeight: 700 }}>
                        {'+ '}
                        {data.addedMoney}
                    </div>
                )
            },
        },
        {
            title: myCoins.tableColumns.balance,
            field: 'id',
            render: (data) => {
                return <div style={{ fontWeight: 700 }}>{data.moneyAfter}</div>
            },
        },
        {
            title: myCoins.tableColumns.date,
            field: 'id',
            render: (data) => {
                return (
                    <div>
                        {getLocaleDateString(i18n.language, data.addedDate)}
                    </div>
                )
            },
        },
    ]
}
