import { Modal } from 'UI/common/modal/modal'
import { UploadFilesPopup } from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/uploadBeatModal/uploadFilesPopup'
import { FormControl } from '@material-ui/core'
import { LinearProgressWithLabel } from 'UI/common/progressBars/LeanerProgress'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'
import { Button } from 'UI/common/elements/buttons/Button'
import { useSelector } from 'UI/common/hooks/redux-hooks'
import { RequestStatuses } from 'BLL/AppReducer'
import { InputField } from 'UI/common/elements/textFields/Input'
import axios from 'axios'
import { selectRequestStatus } from 'BLL/selectors/app-selectors'
import { SelectedModal } from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/types/types'
import CloseWhite from 'UI/assets/icons/close-white.svg'
import FileIcon from 'UI/assets/icons/file.svg'
import s from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/uploadBeatModal/uploadBeatModal.module.scss'
import { useLocation } from 'react-router-dom'

type Props = {
    modalIsActive: boolean
    setModalIsActive: (value: boolean) => void
    saveFileHandler: (
        formData: any,
        onUploadProgress: (progressEvent: any) => void,
        cancelToken: any,
        type: SelectedModal
    ) => void
    addFileByLink?: (url: string) => void
    type?: SelectedModal
    disable?: boolean
    setDisable?: (value: boolean) => void
}
export const UploadBeatModal = ({
    modalIsActive,
    setModalIsActive,
    saveFileHandler,
    type = 'sale',
    addFileByLink,
}: Props) => {
    const requestStatus = useSelector(selectRequestStatus)

    const { i18n } = useTranslation()
    const { myBeats, buttons } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    const [progress, setProgress] = useState(0)
    const [progressLoaded, setProgressLoaded] = useState(0)
    const [file, setFile] = useState<FileList | null>(null)
    const [customMessage, setCustomMessage] = useState('')
    const [axiosToken, setAxiosToken] = useState<any>(null)
    const [url, setUrl] = useState('')

    const location = useLocation()
    const isMyBeatsPage = location.pathname.startsWith('/my-beats')

    useEffect(() => {
        setProgress(0)
        setCustomMessage('')
        setFile(null)
    }, [modalIsActive])

    const handleSaveFile = async (files: FileList | File) => {
        //@ts-ignore
        const arrayFiles = [...files]
        //@ts-ignore
        if (files instanceof FileList) {
            setFile(files)
        }
        const formData = new FormData()
        formData.append('file', arrayFiles[0])

        const cancelTokenSource = axios.CancelToken.source()
        setAxiosToken(cancelTokenSource)

        saveFileHandler(
            formData,
            progressHandler,
            cancelTokenSource.token,
            type
        )
    }

    const progressHandler = (progressEvent: any) => {
        setUrl('')
        const progress = Number(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
        )
        if (progress === 100) {
            setCustomMessage(myBeats.fileProcessing)
        }
        setProgress(progress)
        setProgressLoaded(Number((progressEvent.loaded / 1048576).toFixed(1)))
    }

    const handleAddFileByLink = () => {
        if (addFileByLink) {
            addFileByLink(url)
            setUrl('')
        }
    }

    const cancelUpload = () => {
        axiosToken.cancel()
    }

    const handleCloseModal = useCallback(() => {
        requestStatus !== RequestStatuses.InProgress && setModalIsActive(false)
    }, [requestStatus])

    return (
        <Modal
            active={modalIsActive}
            setActive={setModalIsActive}
            description={
                isMyBeatsPage && myBeats.formsField.uploadFileDescription
            }
            title={myBeats.formsField.uploadFile}
        >
            <div className={s.modalWrapper}>
                <div className={s.dragAndDrop}>
                    <UploadFilesPopup
                        uploadFilesHandler={handleSaveFile}
                        customMessage={customMessage}
                        type={type}
                    />
                </div>
                {addFileByLink && type === 'sale' && (
                    <div className={s.setLinkInput}>
                        <FormControl fullWidth>
                            <div className={s.link}>
                                {myBeats.popupUploadsMessage.urlFileCloud}
                            </div>
                            <InputField
                                id={myBeats.popupUploadsMessage.urlFileCloud}
                                value={url}
                                onChange={({ target: { value } }) =>
                                    setUrl(value)
                                }
                            />
                        </FormControl>
                    </div>
                )}
                {file && (
                    <div className={s.progress}>
                        <img src={FileIcon} alt={'file'} />
                        <LinearProgressWithLabel
                            value={progress}
                            file={file}
                            progressLoaded={progressLoaded}
                        />
                        {progress < 100 && (
                            <img
                                src={CloseWhite}
                                alt={'file'}
                                onClick={cancelUpload}
                            />
                        )}
                    </div>
                )}
                <div className={s.action}>
                    {progress <= 100 && (
                        <Button
                            view={'Secondary'}
                            title={buttons.CANCEL}
                            disabled={
                                requestStatus === RequestStatuses.InProgress ||
                                progress === 100
                            }
                            onClick={handleCloseModal}
                        />
                    )}

                    {!url ? (
                        <Button
                            title={buttons.DONE}
                            disabled={
                                requestStatus === RequestStatuses.InProgress ||
                                !progress
                            }
                            onClick={handleCloseModal}
                        />
                    ) : (
                        !file && (
                            <Button
                                title={buttons.DONE}
                                onClick={handleAddFileByLink}
                            />
                        )
                    )}
                </div>
            </div>
        </Modal>
    )
}
