import React, { FC } from 'react'
import classes from './MobCashback.module.scss'
import { CustomCheckbox } from '../../../common/elements/CustomCheckbox'
import { AppSettings } from 'settings/appSettings'
import { useTranslation } from 'react-i18next'
import { LanguageType } from '../../../../translations/ru/common'
import { RequestStatuses } from '../../../../BLL/AppReducer'
import { ButtonTooltip } from '../../../common/elements/buttons/ButtonTooltip'
import { useSelector } from '../../../common/hooks/redux-hooks'
import { MobButtonTooltip } from '../MobileCommon/MobileBtnToopltip/MobileBtnTooltip'

type PropsType = {
    amountRub: number
    amountUsd: number
    cashbackIsActive: boolean
    handleUpdateCashback: (value: any) => void
}
export const MobCashback: FC<PropsType> = ({
    amountRub,
    amountUsd,
    cashbackIsActive,
    handleUpdateCashback,
}) => {
    const isEng = AppSettings.api.isEngVersion()
    const { i18n } = useTranslation()
    const { navigation, cashbacks, settings } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType
    const requestStatus = useSelector((state) => state.appReducer.requestStatus)
    return (
        <div className={classes.MobCashback}>
            <div className={classes.total}>
                <span className={classes.title}>{cashbacks.helpText}</span>
                <div className={classes.sum}>
                    <span className={classes.usd}>$ {amountUsd}</span>
                    {!isEng && (
                        <span className={classes.rub}>₽ {amountRub}</span>
                    )}
                </div>
            </div>
            <div className={classes.flag}>
                <div className={classes.itemField}>
                    <CustomCheckbox
                        checked={cashbackIsActive}
                        onChange={handleUpdateCashback}
                        disabled={requestStatus === RequestStatuses.InProgress}
                    />
                    <label className={classes.checkBoxText}>
                        {settings.cashback}
                    </label>
                    <MobButtonTooltip>{cashbacks.tooltip}</MobButtonTooltip>
                </div>
            </div>
        </div>
    )
}
