import { FieldError } from 'react-hook-form'
import React, { ChangeEvent, ComponentPropsWithoutRef } from 'react'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'
import { getFinalType } from 'UI/common/elements/textFields/newtextField/utils/getFinalType'
import { TextFieldVariant } from 'UI/common/elements/textFields/newtextField/utils/types'
import s from 'UI/common/elements/textFields/newtextField/newTextField.module.scss'

export type Props = {
    onClick?: () => void
    disabled?: boolean
    onValueChange?: (value: string) => void
    variant?: TextFieldVariant
    label?: string
    customTheme?: 'dark' | 'light'
    currencyType?: 'RUB' | 'USD'
    error?: FieldError
    customStyles?: Record<string, any>
} & ComponentPropsWithoutRef<'input'>

export const NewTextField = ({
    className,
    disabled = false,
    onClick,
    onChange,
    onValueChange,
    error,
    label,
    customTheme,
    currencyType = 'USD',
    variant = 'primary',
    placeholder,
    customStyles,
    ...restProps
}: Props) => {
    const finalType = getFinalType({
        variant,
        currencyType,
        customTheme,
        disabled,
        className,
    })

    const { i18n } = useTranslation()
    const { buttons } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        onChange?.(e)
        onValueChange?.(e.target.value)
    }

    return (
        <div className={customStyles ? customStyles.textFieldWrapper : ''}>
            <div
                className={
                    customStyles ? customStyles.labelStyle : s.labelStyle
                }
            >
                {label && <label>{label}</label>}
            </div>
            <div
                className={
                    variant === 'copy'
                        ? customStyles?.wrapperCopy || s.wrapperCopy
                        : customStyles?.inputWrapper || ''
                }
            >
                <input
                    disabled={disabled}
                    className={finalType}
                    onChange={changeHandler}
                    placeholder={placeholder}
                    {...restProps}
                />
                {variant === 'copy' && (
                    <button
                        type="button"
                        className={
                            disabled
                                ? customStyles
                                    ? `${s.copyButton} ${customStyles.disabled}`
                                    : `${s.copyButton} ${s.disabled}`
                                : s.copyButton
                        }
                        onClick={onClick}
                    >
                        {buttons.COPY}
                    </button>
                )}
            </div>
            <span
                className={
                    customStyles ? customStyles.errorMessage : s.errorMessage
                }
            >
                {error?.message}
            </span>
        </div>
    )
}
