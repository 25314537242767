import React, { useEffect } from 'react'
import Table from '../../../common/table/Table'
import { DiscountGroupType } from 'DAL/DiscountAPI'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'
import { Route, useHistory } from 'react-router-dom'
import { CustomerRM } from 'UI/routers/RouterManager'
import { Modal } from 'UI/common/modal/modal'
import { DiscountEdit } from '../discountEdit/DiscountEdit'
import { getBulkDiscounts } from 'BLL/CustomerDiscountsReducer'
import { useSelector } from 'UI/common/hooks/redux-hooks'
import { DeleteDiscount } from '../discountDelete/DeleteDiscount'
import { MobileGroupDiscount } from '../../Mobile/MobDiscountsPage/GroupDiscount/GroupDiscount'
import { CustomBrowserView } from 'UI/common/browserView/CustomBrowserView'
import { CustomMobileView } from 'UI/common/mobileView/CustomMobileView'
import { selectBulkDiscounts } from 'BLL/selectors/discounts-selectors'
import { getGroupsDiscountsTableData } from 'UI/common/table/tableData/GetGroupsDiscountsTableData'

export const GroupDiscount = () => {
    const bulkDiscounts = useSelector(selectBulkDiscounts)
    const dispatch = useDispatch()

    const { i18n } = useTranslation()
    const history = useHistory()

    const { discounts } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    useEffect(() => {
        dispatch(getBulkDiscounts())
    }, [])

    return (
        <div>
            <CustomBrowserView>
                <Table<DiscountGroupType>
                    items={bulkDiscounts}
                    toolbar={false}
                    fields={getGroupsDiscountsTableData(i18n)}
                    title={''}
                />
            </CustomBrowserView>
            <CustomMobileView>
                {bulkDiscounts.map((bulkDiscount) => (
                    <MobileGroupDiscount
                        key={bulkDiscount.id}
                        bulkDiscount={bulkDiscount}
                    />
                ))}
            </CustomMobileView>
            <Route
                path={CustomerRM.discounts.create.path}
                render={({ match }) => (
                    <Modal
                        active={true}
                        setActive={() => {}}
                        title={discounts.groupDiscounts.popUp.title}
                        cancelRedirect={() =>
                            history.push(
                                CustomerRM.discounts.path.getUrl(
                                    'group-discounts'
                                )
                            )
                        }
                    >
                        <DiscountEdit />
                    </Modal>
                )}
            />
            <Route
                path={CustomerRM.discounts.delete.path}
                render={({ match }) => (
                    <Modal
                        active={true}
                        setActive={() => {}}
                        title={discounts.deleteDialog.title}
                        cancelRedirect={() =>
                            history.push(
                                CustomerRM.discounts.path.getUrl(
                                    'group-discounts'
                                )
                            )
                        }
                    >
                        <DeleteDiscount />
                    </Modal>
                )}
            />
        </div>
    )
}
