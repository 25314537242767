import React, { FC } from 'react'
import classes from './Operation.module.scss'
import UsdIcon from '../../../../assets/icons/usd.svg'
import RubIcon from '../../../../assets/icons/rub.svg'
import { AppSettings } from 'settings/appSettings'
import { CashbackType } from '../../../../../DAL/CashbackAPI'
import { useTranslation } from 'react-i18next'
import { LanguageType } from '../../../../../translations/ru/common'
import { getLocaleDateString } from '../../../../utils/util-types'

type PropsType = {
    data: CashbackType
}

export const Operation: FC<PropsType> = ({ data }) => {
    const isEng = AppSettings.api.isEngVersion()
    const isClosed = data.closedAmount
    const amount = isClosed ? data.closedAmount : data.amount
    const { i18n } = useTranslation()
    const { navigation, cashbacks } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType
    return (
        <div className={classes.Operation}>
            <div className={classes.item}>
                <span className={classes.title}>
                    {cashbacks.tableColumns.operation}:
                </span>
                <span className={classes.value}>
                    {data.closedAmount
                        ? cashbacks.tableColumns.payment
                        : cashbacks.tableColumns.income}
                </span>
            </div>
            <div className={classes.item}>
                <span className={classes.title}>
                    {cashbacks.tableColumns.amount}:
                </span>
                {isEng ? (
                    <span className={classes.value}>
                        {isClosed ? '- ' : '+ '}
                        <img src={UsdIcon} alt="" />
                        {amount}
                    </span>
                ) : (
                    <span className={classes.value}>
                        {isClosed ? '-' : '+'}
                        <img src={RubIcon} alt="" />
                        {amount}
                    </span>
                )}
            </div>
            <div className={classes.item}>
                <span className={classes.title}>
                    {cashbacks.tableColumns.paymentDate}:
                </span>
                <span className={classes.value}>
                    {!isClosed
                        ? getLocaleDateString(
                              i18n.language,
                              data.expirationDate
                          )
                        : '-'}
                </span>
            </div>

            {isClosed ? (
                <div className={classes.item}>
                    <span className={classes.title}>
                        {cashbacks.tableColumns.expirationDate}:
                    </span>
                    <span className={classes.value}>
                        {data.paymentDate &&
                            getLocaleDateString(
                                i18n.language,
                                data.paymentDate
                            )}
                    </span>
                </div>
            ) : null}
        </div>
    )
}
