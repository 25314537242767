import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { Button } from 'UI/common/elements/buttons/Button'
import { InputField } from 'UI/common/elements/textFields/Input'
import { CopyInput } from 'UI/common/elements/textFields/copy-input'
import classes from './BeatmakerProducerPage.module.scss'
import { AppSettings } from 'settings/appSettings'
import { MobProducerPage } from '../Mobile/MobProducerPage/MobProducerPage'
import { useDispatch } from 'react-redux'
import {
    fetchedProducerPageSettingsDetails,
    updateProducerPageSettings,
} from 'BLL/ProducerPageReducer'
import { useSelector } from 'UI/common/hooks/redux-hooks'
import { CustomBrowserView } from 'UI/common/browserView/CustomBrowserView'
import { CustomMobileView } from 'UI/common/mobileView/CustomMobileView'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'
import { copyBuffer } from 'UI/common/elements/textFields/newtextField/utils/copyBuffer'

export const BeatmakerProducerPage = () => {
    const isEng = AppSettings.api.isEngVersion()
    const dispatch = useDispatch()
    const [subdomain, setSubdomain] = useState('')
    const [domain, setDomain] = useState('')
    const [cname, setCName] = useState('')
    const { i18n } = useTranslation()
    const { producerPage, buttons } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType
    const settings = useSelector((state) => state.producerPage.settings)

    useEffect(() => {
        if (settings) {
            setSubdomain(settings.subdomain)
            setDomain(settings.domain)
            setCName(settings.herokuCname ? settings.herokuCname + '.' : '')
        }
    }, [settings])

    useEffect(() => {
        dispatch(fetchedProducerPageSettingsDetails())
    }, [])

    const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setDomain(event.target.value)
    }, [])

    const handleChangeDomain = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setSubdomain(event.target.value)
        },
        []
    )

    const handleCopyCname = useCallback(
        () => copyBuffer(cname, dispatch),
        [cname]
    )

    const handleSubmit = useCallback(async () => {
        dispatch(updateProducerPageSettings({ subdomain, domain }))
    }, [domain, subdomain])

    const baseDomain = isEng ? 'beatmakers.tv' : 'beatmaker.tv'

    const linkToProducerPage = domain
        ? `https://${domain}`
        : `https://${subdomain}.${baseDomain}`

    const linkToDomainBindingInstructions = `https://${baseDomain}/help/instrukciya_dlya_privyazki_domena_k_stranice_prodyusera`

    return (
        <div className={classes.wrapper}>
            <CustomBrowserView>
                <h1 className={classes.title}>{producerPage.title}</h1>
                <div className={classes.redirectBlock}>
                    <div className={classes.blockDescr}>
                        <h2 className={classes.subtitle}>
                            {producerPage.goToProducerPage}
                        </h2>
                        <p className={classes.descrText}>
                            {producerPage.customizeInfo}
                        </p>
                    </div>
                    <div className={classes.boxBtn}>
                        <a
                            href={linkToProducerPage}
                            target={'_blank'}
                            rel="noreferrer"
                        >
                            <Button
                                title={buttons.GO_TO_PRODUCER_PAGE}
                                type={'submit'}
                            />
                        </a>
                    </div>
                </div>
            </CustomBrowserView>
            <CustomMobileView>
                <MobProducerPage domain={domain} subdomain={subdomain} />
            </CustomMobileView>
            <div className={classes.container}>
                <div className={classes.domain}>
                    <h3 className={classes.blockTitle}>
                        {producerPage.domainName}
                    </h3>
                    <div className={classes.domainBlock}>
                        <div className={classes.fieldItem}>
                            <label className={classes.itemTitle}>
                                {producerPage.subDomainName}
                            </label>
                            <div className={classes.fieldSize}>
                                <InputField
                                    value={subdomain}
                                    onChange={handleChangeDomain}
                                />
                            </div>
                            <a
                                className={classes.fieldLink}
                                href={linkToDomainBindingInstructions}
                                rel={'noreferrer'}
                                target={'_blank'}
                            >
                                {producerPage.descriptionSubDomainName}
                            </a>
                            <div className={classes.baseDomain}>
                                {subdomain}
                                {isEng ? '.beatmakers.tv' : '.beatmaker.tv'}
                            </div>
                        </div>
                        <div className={classes.fieldItem}>
                            <label className={classes.itemTitle}>
                                {producerPage.domainName}
                            </label>
                            <div className={classes.fieldSize}>
                                <InputField
                                    value={domain}
                                    onChange={handleChange}
                                />
                            </div>
                            <a
                                className={classes.fieldLink}
                                href={linkToDomainBindingInstructions}
                                rel={'noreferrer'}
                                target={'_blank'}
                            >
                                {producerPage.descriptionDomainName}
                            </a>
                        </div>
                        <div className={classes.fieldItem}>
                            <label className={classes.itemTitle}>CNAME</label>
                            <div className={classes.fieldSizeCopy}>
                                <CopyInput
                                    onClick={handleCopyCname}
                                    disabled={!cname}
                                    value={cname}
                                />
                            </div>
                        </div>
                        <div className={classes.boxBtn}>
                            <Button
                                title={buttons.SAVE}
                                view={'Outline'}
                                onClick={handleSubmit}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
