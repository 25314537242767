import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { LanguageType } from '../../../translations/ru/common'
import {
    cashbackAPI,
    CashbackType,
    CurrencyEnum,
} from '../../../DAL/CashbackAPI'
import Table from '../../common/table/Table'

import { useDispatch } from 'react-redux'
import { useQuery } from 'react-query'
import { actions as appActions, RequestStatuses } from '../../../BLL/AppReducer'
import { BrowserView, MobileView } from 'react-device-detect'
import classes from './cashback.module.css'
import { AppSettings } from 'settings/appSettings'
import { CustomerCashbackHeader } from './CustomerCashbackHeader/CustomerCashbackHeader'
import { Operation } from '../Mobile/MobCashback/Operation/Operation'
import { CustomBrowserView } from '../../common/browserView/CustomBrowserView'
import { CustomMobileView } from '../../common/mobileView/CustomMobileView'
import { getCashbackTableData } from 'UI/common/table/tableData/GetCashbackTableData'

export const CashbackPage: FC = () => {
    const dispatch = useDispatch()
    const isEng = AppSettings.api.isEngVersion()
    const { i18n } = useTranslation()
    const { navigation, cashbacks } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    const {
        data = {
            amountUsd: 0,
            amountRub: 0,
            cashbackData: [],
        },
    } = useQuery(
        ['cashback'],
        async () => {
            dispatch(appActions.setRequestStatus(RequestStatuses.InProgress))
            let amountUsd = 0
            let amountRub = 0
            let cashbackData = []
            if (isEng) {
                amountUsd = (
                    await cashbackAPI.getCashbackAmount(CurrencyEnum.usd)
                ).amount
            } else {
                amountUsd = (
                    await cashbackAPI.getCashbackAmount(CurrencyEnum.usd)
                ).amount
                amountRub = (
                    await cashbackAPI.getCashbackAmount(CurrencyEnum.rub)
                ).amount
            }
            cashbackData = await cashbackAPI.getCashbacks()
            dispatch(appActions.setRequestStatus(RequestStatuses.Success))
            return { amountRub, amountUsd, cashbackData }
        },
        { refetchOnWindowFocus: false }
    )
    return (
        <div>
            <CustomerCashbackHeader
                amountRub={data?.amountRub || 0}
                amountUsd={data?.amountUsd || 0}
            />
            <CustomBrowserView>
                <div className={classes.settingsWrapper}>
                    <div className={classes.tableWrapper}>
                        <Table<CashbackType>
                            items={data.cashbackData}
                            title={''}
                            fields={getCashbackTableData(i18n)}
                        />
                    </div>
                </div>
            </CustomBrowserView>
            <CustomMobileView>
                {data.cashbackData.map((data) => (
                    <Operation key={data._id} data={data} />
                ))}
            </CustomMobileView>
        </div>
    )
}
