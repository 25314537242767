import React, { FC } from 'react'
import classes from './selects.module.scss'
import ClockIcon from '../../../assets/icons/clock.svg'
import { UseFormRegisterReturn } from 'react-hook-form/dist/types/form'

type PropsType = {
    items: Array<{ value: string | number; title: string }>
    width?: number | string
    isClock?: boolean
    register?: UseFormRegisterReturn
} & React.SelectHTMLAttributes<HTMLSelectElement>

export const CustomSelect: FC<PropsType> = ({
    items,
    width = 60,
    isClock,
    register,
    ...props
}) => {
    const className = isClock
        ? `${classes.Select} ${classes.noDefaultIcon}`
        : classes.Select
    return (
        <div className={classes.wrapper}>
            <select
                className={className}
                {...register}
                {...props}
                style={typeof width === 'string' ? { width } : { width }}
            >
                {items.map((item) => (
                    <option key={item.value} value={item.value}>
                        {item.title}
                    </option>
                ))}
            </select>
            {isClock && (
                <div className={classes.icon}>
                    <img src={ClockIcon} alt={'clock'} />
                </div>
            )}
        </div>
    )
}
