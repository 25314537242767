import React, { ChangeEvent } from 'react'
import { NewTextField } from 'UI/common/elements/textFields/newtextField/NewTextField'
import s from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/beatSettings/licenseInput/licenseInput.module.scss'

type Props = {
    eng?: boolean
    value?: number | null
    onChange: (value: string) => void
    disabled?: boolean
}

export const LicenseInput = ({ eng, value, onChange, disabled }: Props) => {
    const changeLicenseHandler = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value)
    }
    return (
        <div className={s.licenseInput}>
            <NewTextField
                currencyType={eng ? 'USD' : 'RUB'}
                variant={'price'}
                value={String(value)}
                disabled={disabled}
                onChange={changeLicenseHandler}
            />
        </div>
    )
}
