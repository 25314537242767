import { InferActionsTypes } from './Store'
import {
    actions as authActions,
    AuthActionsTypes,
    EditingEntityStatuses,
} from './AppReducer'
import { EditingShopType, ShopStatus, ShopTypesFull } from './shop-types'
import { commonAsyncHandler } from './common-async-handler'
import { shopAPI } from 'DAL/Services'
import { CONSTANTS, STATUSES } from './SupportingFile'
import { ResultCodesEnum, ThunkActionType } from './types'

const initState = {
    shops: [] as ShopTypesFull[],
    editingShop: null as ShopTypesFull | null,
    shopStatusUpdate: null as null | number,
    statusUpdate: STATUSES.NOT_INIT as string,
    newShopId: null as null | number,
}

type IState = typeof initState
export type ActionsTypes = InferActionsTypes<typeof actions> | AuthActionsTypes

const AdminShopsReducer = (state = initState, action: ActionsTypes): IState => {
    switch (action.type) {
        case 'ShopAdminReducer/SET_SHOPS':
            return {
                ...state,
                shops: action.shops,
            }
        case 'ShopAdminReducer/SET_EDITING_SHOP':
            return {
                ...state,
                editingShop: action.shop,
            }
        case 'ShopAdminReducer/SET_SHOP':
            return {
                ...state,
                shops: state.shops.map((shop) =>
                    shop.id === action.shop.id
                        ? { ...shop, ...action.shop }
                        : shop
                ),
            }
        case 'ShopAdminReducer/SET_SHOP_STATUS_UPDATE':
            return {
                ...state,
                statusUpdate: action.status,
                shopStatusUpdate: action.shopId,
            }
        case 'ShopAdminReducer/SET_STATUS_SHOP':
            return {
                ...state,
                shops: state.shops.map((shop) => {
                    if (shop.id === action.shopId) {
                        return { ...shop, status: action.status }
                    } else return shop
                }),
            }
        case 'ShopAdminReducer/SET_NEW_SHOP_ID':
            return {
                ...state,
                newShopId: action.id,
            }

        default:
            return state
    }
}
export default AdminShopsReducer

export const actions = {
    setShops: (shops: ShopTypesFull[]) =>
        ({
            type: 'ShopAdminReducer/SET_SHOPS',
            shops,
        } as const),
    setShop: (shop: ShopTypesFull | EditingShopType) =>
        ({ type: 'ShopAdminReducer/SET_SHOP', shop } as const),
    setEditingShop: (shop: ShopTypesFull | null) =>
        ({
            type: 'ShopAdminReducer/SET_EDITING_SHOP',
            shop,
        } as const),
    setShopStatusUpdate: (status: string, shopId: number) =>
        ({
            type: 'ShopAdminReducer/SET_SHOP_STATUS_UPDATE',
            status,
            shopId,
        } as const),
    setStatusShop: (status: ShopStatus, shopId: number) =>
        ({
            type: 'ShopAdminReducer/SET_STATUS_SHOP',
            status,
            shopId,
        } as const),
    setNewShopId: (id: number | null) =>
        ({ type: 'ShopAdminReducer/SET_NEW_SHOP_ID', id } as const),
}

export const changeStatusShop =
    (status: ShopStatus, id: number): ThunkActionType<ActionsTypes> =>
    async (dispatch, getState) => {
        await commonAsyncHandler(async () => {
            const shop = getState().managerShops.shops.find(
                (shop) => shop.id === id
            ) as EditingShopType
            dispatch(actions.setShopStatusUpdate(STATUSES.LOADING, id))
            const response = await shopAPI.updateShop({ ...shop, status })
            dispatch(actions.setStatusShop(status, id))
            dispatch(actions.setShopStatusUpdate(STATUSES.SUCCESS, id))
            dispatch(
                authActions.showSnackbar('success', CONSTANTS.SUCCESS_MESSAGE)
            )
            return response
        }, dispatch)
    }

export const getShops =
    (): ThunkActionType<ActionsTypes> => async (dispatch) => {
        await commonAsyncHandler(async () => {
            const shops = await shopAPI.getAdminShops()
            dispatch(actions.setShops(shops))
            return shops
        }, dispatch)
    }

export const addShop =
    (): ThunkActionType<ActionsTypes> => async (dispatch, getState) => {
        await commonAsyncHandler(async () => {
            const shops = getState().managerShops.shops
            const response = await shopAPI.addShop()
            dispatch(actions.setShops([...shops, response.data.item]))
            dispatch(actions.setNewShopId(response.data.item.id))
            return shops
        }, dispatch)
    }
export const getShopById =
    (shopId: number): ThunkActionType<ActionsTypes> =>
    async (dispatch, getState) => {
        await commonAsyncHandler(async () => {
            const shop = await shopAPI.getShopById(shopId)
            dispatch(actions.setEditingShop(shop))
            return shop
        }, dispatch)
    }

export const updateShop =
    (shop: EditingShopType): ThunkActionType<ActionsTypes> =>
    async (dispatch, getState) => {
        await commonAsyncHandler(async () => {
            const response = await shopAPI.updateShop(shop)
            if (response.resultCode === ResultCodesEnum.Success) {
                dispatch(actions.setShop(shop))
                dispatch(
                    authActions.setEditingEntityStatus(
                        EditingEntityStatuses.Success
                    )
                )
                dispatch(
                    authActions.showSnackbar(
                        'success',
                        CONSTANTS.SUCCESS_MESSAGE
                    )
                )
            } else {
                dispatch(
                    authActions.setEditingEntityStatus(
                        EditingEntityStatuses.Error
                    )
                )
                dispatch(
                    authActions.showSnackbar('error', CONSTANTS.ERROR_MESSAGE)
                )
            }
            return response
        }, dispatch)
    }
