import React, { FC, useCallback } from 'react'
import classes from 'UI/CustomerAdmin/Mobile/MobMyBeatsPage/common/styles/MobBeatItem.module.scss'
import dragIcon from 'UI/assets/icons/drag-icon.svg'
import charts from 'UI/assets/icons/charts.svg'
import download from 'UI/assets/icons/download.svg'
import listen from 'UI/assets/icons/listen.svg'
import deleteIcon from 'UI/assets/icons/delete.svg'
import edit from 'UI/assets/icons/edit.svg'
import { FreeBeatType } from 'DAL/BeatSettingsAPI'
import PlayIcon from 'Player/assets/images/player-icons/play_blue.svg'
import PauseIcon from 'Player/assets/images/player-icons/pause_blue.svg'
import { getLocaleDateString } from 'UI/utils/util-types'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'
import Tooltip from '@material-ui/core/Tooltip'
import { NavLink, useHistory } from 'react-router-dom'
import { CustomerRM } from 'UI/routers/RouterManager'

type PropsType = {
    beat: FreeBeatType
    playHandler: () => void
    isPlayingMe: boolean
}
export const MobFreeBeatItem: FC<PropsType> = ({
    beat,
    playHandler,
    isPlayingMe,
}) => {
    const { i18n } = useTranslation()
    const { customerBeatsPublication, buttons, myBeats } =
        i18n.getResourceBundle(i18n.language, 'common') as LanguageType
    const history = useHistory()
    const handleEditBeat = useCallback(() => {
        history.push(CustomerRM.freeBeats.getUrl.beats.edit(beat.id))
    }, [history, beat.id])
    return (
        <div className={classes.beatItem}>
            <div className={classes.beatBox}>
                <div className={classes.beatInfo}>
                    <div className={classes.imageWrapper}>
                        <img
                            className={classes.coverBeat}
                            src={beat.images.thumbnail}
                            onClick={playHandler}
                            alt={''}
                        />
                        <div className={classes.beatControl}>
                            {!isPlayingMe ? (
                                <img
                                    src={PlayIcon}
                                    alt={'play'}
                                    onClick={playHandler}
                                />
                            ) : (
                                <img
                                    src={PauseIcon}
                                    alt={'play'}
                                    onClick={playHandler}
                                />
                            )}
                        </div>
                    </div>
                    <div>
                        <span className={classes.track}>{beat.title}</span>
                        <p className={classes.trackDate}>
                            {beat.addedDate && (
                                <span>
                                    {`${
                                        customerBeatsPublication.tableColumns
                                            .publishedDate
                                    }: ${getLocaleDateString(
                                        i18n.language,
                                        beat.addedDate
                                    )}`}
                                </span>
                            )}
                        </p>
                    </div>
                </div>
                <div className={classes.dragIcon}>
                    <img
                        src={dragIcon}
                        alt="drag"
                        className={classes.disabled}
                    />
                </div>
            </div>
            <div className={classes.description}>
                <div className={classes.item}>
                    <span className={classes.itemText}>
                        <img className={classes.itemIcon} src={charts} alt="" />
                        {myBeats.tableColumns.chart}:
                    </span>
                    <span className={classes.itemCount}>{}</span>
                </div>
                <div className={classes.item}>
                    <span className={classes.itemText}>
                        <img
                            className={classes.itemIcon}
                            src={download}
                            alt={'download'}
                        />
                        {myBeats.tableColumns.downloads}:
                    </span>
                    <span className={classes.itemCount}>{beat.downloads}</span>
                </div>
                <div className={classes.item}>
                    <span className={classes.itemText}>
                        <img
                            className={classes.itemIcon}
                            src={listen}
                            alt={'listen'}
                        />
                        {myBeats.tableColumns.listening}:
                    </span>
                    <span className={classes.itemCount}>{beat.plays}</span>
                </div>
            </div>
            <div className={classes.actions}>
                <Tooltip title={buttons.EDIT}>
                    <img
                        className={classes.icons}
                        src={edit}
                        onClick={handleEditBeat}
                        alt={'edit'}
                    />
                </Tooltip>
                <Tooltip title={buttons.DELETE}>
                    <NavLink
                        to={CustomerRM.freeBeats.getUrl.beats.delete(beat.id)}
                    >
                        <img
                            className={classes.icons}
                            src={deleteIcon}
                            alt={'delete'}
                        />
                    </NavLink>
                </Tooltip>
            </div>
        </div>
    )
}
