import { ResultCodesEnum } from './types'

export type ShopsResponseType<T> = {
    resultCode: ResultCodesEnum
    messages: string[]
    data: {
        item: T
        status: CouponStatus
    }
}

export enum ShopStatus {
    New = 0,
    Approved = 1,
    Paused = 2,
    Deleted = 3,
}

export type ShopTypes = {
    city: string
    country: string
    id: number
    title: string
}

export interface ShopTypesFull extends ShopTypes {
    feePercent: number | null
    allWorldDelivery: boolean
    userId: number | null
    description: string
    shortDescription: string
    status: ShopStatus
    addedDate: Date
    image: string //base64string
    contacts: ShopContactsType
}

export type ShopContactsType = {
    phone: string
    address: string
    website: string
    email: string
}

export type EditingShopType = {
    id?: number
    city: string
    country: string
    title: string
    feePercent: number | null
    allWorldDelivery: boolean
    userId: number | null
    description: string
    shortDescription: string
    status: ShopStatus
    image: string //base64string
    contacts: ShopContactsType
}

export type ShopOwner = {
    isAuth: boolean
    isOwner: boolean
}

export type CouponType = {
    addedDate: string
    code: string
    currency: CouponCurrency
    customerFeedback: string
    customerShopRating: number
    id: number
    lastStatusChangeDate: string
    price: number
    productTitle: string | null
    purchaseDate: Date | null
    shopId: number
    shopNote: string | null
    status: CouponStatus
    shopTitle?: string
}

export type UpdateCouponType = {
    id: number
    currency: CouponCurrency
    price: number
    productTitle: string
    purchaseDate: Date
    isClosed?: boolean
    shopNote: string
    customerFeedback?: string
    customerShopRating?: number
}

export enum CouponCurrency {
    RUB = 0,
    USD = 1,
}

export enum CouponStatus {
    NotActive = 0,
    Pending = 1,
    Rejected = 2,
    SpecifyInformation = 3,
    Approve = 4,
}
