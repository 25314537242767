import React from 'react'
import s from './PlayerMob.module.scss'
import { TrackDescr } from '../TrackDescr/TrackDescr'
import { ProgressBar } from '../ProgressBar/ProgressBar'
import { PlayerControls } from '../PlayerControls/PlayerControls'
import { PlayerLogic, PlayerLogicTrackType } from '../../../Domain/PlayerLogic'

type PropsType = {
    toggleMobMode: () => void
    player: PlayerLogic
    track: PlayerLogicTrackType
}

export const PlayerMob = (props: PropsType) => {
    return (
        <div className={s.container}>
            <div className={s.btnCloseContainer}>
                <button className={s.btnClose} onClick={props.toggleMobMode}>
                    {' '}
                </button>
            </div>
            <div className={s.content}>
                <TrackDescr track={props.track} mobMode={true} />
                <div className={s.progressBarContainer}>
                    <ProgressBar player={props.player} />
                </div>
                <PlayerControls
                    player={props.player}
                    track={props.track}
                    mobMode={true}
                />
            </div>
        </div>
    )
}
