import { axiosCore } from './createAxios'
import { SubscriptionPlansEnum } from 'BLL/enums'
import { BaseResponse, NullableType } from 'BLL/types'
import { BeatLicenseType } from './Services'

export const DiscountAPI = {
    prefixURL: 'api',

    getDiscounts() {
        return axiosCore
            .get<DiscountType[]>(`${this.prefixURL}/discount`)
            .then((r) => r.data)
    },
    createDiscount() {
        return axiosCore
            .post<BaseResponse>(`${this.prefixURL}/discount`)
            .then((r) => r.data)
    },
    updateDiscount(dto: UpdateDiscountDTO) {
        return axiosCore
            .put<BaseResponse<any>>(`${this.prefixURL}/discount`, dto)
            .then((r) => r.data)
    },
    deleteDiscount(discountId: string) {
        return axiosCore
            .delete<BaseResponse>(`${this.prefixURL}/discount/${discountId}`)
            .then((r) => r.data)
    },
    getBulkDiscounts() {
        return axiosCore
            .get<DiscountGroupType[]>(`${this.prefixURL}/discount/bulk`)
            .then((r) => r.data)
    },
    createBulkDiscounts(dto: CreateDiscountGroupDTO) {
        return axiosCore
            .post<BaseResponse<null>>(`${this.prefixURL}/discount/bulk`, dto)
            .then((r) => r.data)
    },
    deleteBulkDiscounts(discountId: string) {
        return axiosCore
            .delete<BaseResponse<null>>(
                `${this.prefixURL}/discount/bulk/${discountId}`
            )
            .then((r) => r.data)
    },
}
export type UpdateDiscountDTO = {
    id: string
    forWhatBeats: number
    licenseId: NullableType<string>
    beatsCount: number
    discountSize: number
    finishDate: string
    startDate: string
    status: StatusDiscount
    code: string
}

export type CreateDiscountGroupDTO = {
    beatmakerId: number
    buyedLicenseId: string
    buyedLicenseCount: number
    bonusLicenseId: string
    bonusLicenseCount: number
}

export type DiscountGroupType = {
    id: string
    beatmakerId: number
    buyedLicenseId: string
    buyedLicenseCount: number
    bonusLicenseId: string
    bonusLicenseCount: number
    beatmaker: null
    bonusLicense: NullableType<BeatLicenseType>
    buyedLicense: NullableType<BeatLicenseType>
}
export type DiscountType = {
    addedDate: Date
    beatmaker: {
        id: number
        name: string
        subscriptionPlan: SubscriptionPlansEnum
        engMode: boolean
    }
    beatmakerId: number
    beatsCount: number
    closedBeatsCount: number
    code: string
    createdById: number
    description: null
    discountSize: number
    discountType: DiscountTypeEnum
    finishDate: Date
    forWhatBeats: number
    id: string
    licenseId: string | null
    licenses: null | BeatLicenseType[]
    startDate: Date
    status: StatusDiscount
}

export type LicensesDiscountType = {
    key: string
    value: string
}

export enum StatusDiscount {
    Closed = 0,
    Active = 1,
}

export enum DiscountTypeEnum {
    NotSelected = 0,
    BeatForRepost = 1,
    SaleOfDay = 2,
}
