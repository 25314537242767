import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { LanguageType } from '../../../translations/ru/common'
import { useDispatch } from 'react-redux'
import { useRedirectCustomHook } from '../../common/hooks/useRedirectCustomHook'
import { RolesTypeEnum } from '../../../BLL/enums'
import classes from './compilation-beats.module.scss'
import { Button } from '../../common/elements/buttons/Button'
import { CustomerRM } from '../../routers/RouterManager'
import { NavLink } from 'react-router-dom'
import { AppSettings } from 'settings/appSettings'
import { useSelector } from '../../common/hooks/redux-hooks'
import { getLocaleDateString } from '../../utils/util-types'
import * as dateFns from 'date-fns'
import { RequestStatuses } from '../../../BLL/AppReducer'

export const CustomerBeatsCompilation: FC = () => {
    const dispatch = useDispatch()
    const { t, i18n } = useTranslation()
    const { buttons, customerBeatsCompilation, navigation } =
        i18n.getResourceBundle(i18n.language, 'common') as LanguageType
    const isPauseCompilation = useSelector(
        (state) => state.customerBeatsCompilation.isPauseCompilation
    )
    const requestStatus = useSelector((state) => state.appReducer.requestStatus)
    const promoSettings = useSelector((state) => state.appReducer.promoSettings)
    const expert = useSelector((state) => state.customerBeatsCompilation.expert)
    let nextDate
    if (expert) {
        const lastCompilationDate = new Date(expert.lastCompilationDate)
        if (lastCompilationDate !== null) {
            nextDate = getLocaleDateString(
                i18n.language,
                dateFns.addDays(
                    lastCompilationDate,
                    promoSettings.compilations.timeOutDays
                )
            )
        }
    }
    return (
        <div>
            {useRedirectCustomHook(RolesTypeEnum.EXPERT)}
            <div className={classes.topWrapper}>
                <div className={classes.pageTitle}>
                    {navigation.beatsCompilation}
                </div>
            </div>

            <div className={classes.content}>
                {!isPauseCompilation ? (
                    <p>{customerBeatsCompilation.startText}</p>
                ) : (
                    <div className={classes.textInformation}>
                        <p>{customerBeatsCompilation.nextCompilation}</p>
                        <div>{nextDate}</div>
                    </div>
                )}
                <NavLink to={CustomerRM.compilations.path.create}>
                    <Button
                        title={buttons.LETS_GO}
                        disabled={
                            requestStatus === RequestStatuses.InProgress ||
                            isPauseCompilation
                        }
                    />
                </NavLink>
            </div>
        </div>
    )
}
