import { STATUSES } from './SupportingFile'
import * as dateFns from 'date-fns'
import { createSelector } from 'reselect'
import {
    EmailsHistoryType,
    StatisticsType,
    SubscriptionType,
    ThunkActionType,
} from './types'
import subscriptionsAPI from '../DAL/SubscriptionsAPI'
import { StatusSubscriptionEnum, SubscriptionPlansEnum } from './enums'
import { AppState, InferActionsTypes } from './Store'

const initState = {
    subscriptions: [] as SubscriptionType[],
    totalSubscriptions: 0,
    statusGetSubscriptions: STATUSES.NOT_INIT,
    statistics: null as StatisticsType | null,
    statusGetStatistics: STATUSES.NOT_INIT,
    statusGetEmails: STATUSES.NOT_INIT,
}

type StateType = typeof initState
export type ActionsTypes = InferActionsTypes<typeof actions>

const AdminSubscriptionsReducer = (
    state = initState,
    action: ActionsTypes
): StateType => {
    switch (action.type) {
        case 'ADMIN_SUBSCRIPTIONS_REDUCER/SET_SUBSCRIPTIONS':
            return {
                ...state,
                subscriptions: action.subscriptions,
                totalSubscriptions: action.totalSubscriptions,
            }
        case 'ADMIN_SUBSCRIPTIONS_REDUCER/SET_STATUS_GET_SUBSCRIPTIONS':
            return {
                ...state,
                statusGetSubscriptions: action.status,
            }
        case 'ADMIN_SUBSCRIPTIONS_REDUCER/SET_STATISTICS':
            return {
                ...state,
                statistics: action.statistics,
            }
        case 'ADMIN_SUBSCRIPTIONS_REDUCER/SET_STATUS_GET_STATISTICS':
            return {
                ...state,
                statusGetStatistics: action.status,
            }
        case 'ADMIN_SUBSCRIPTIONS_REDUCER/SET_STATUS_GET_EMAILS':
            return {
                ...state,
                statusGetEmails: action.status,
            }
        case 'ADMIN_SUBSCRIPTIONS_REDUCER/SET_EMAILS_HISTORY':
            return {
                ...state,
                subscriptions: state.subscriptions.map((subscription) => {
                    if (subscription._id === action.subscriptionId) {
                        if (action.emails.length) {
                            return {
                                ...subscription,
                                emailsHistory: action.emails,
                            }
                        } else {
                            // delete subscription['emailsHistory'];
                            subscription['emailsHistory'] = []
                            return subscription
                        }
                    } else {
                        return subscription
                    }
                }),
            }
        default:
            return state
    }
}

export const actions = {
    setSubscriptions: (
        subscriptions: SubscriptionType[],
        totalSubscriptions: number
    ) =>
        ({
            type: 'ADMIN_SUBSCRIPTIONS_REDUCER/SET_SUBSCRIPTIONS',
            subscriptions,
            totalSubscriptions,
        } as const),
    setStatusGetSubscriptions: (status: string) =>
        ({
            type: 'ADMIN_SUBSCRIPTIONS_REDUCER/SET_STATUS_GET_SUBSCRIPTIONS',
            status,
        } as const),
    setStatusGetStatistics: (status: string) =>
        ({
            type: 'ADMIN_SUBSCRIPTIONS_REDUCER/SET_STATUS_GET_STATISTICS',
            status,
        } as const),
    setStatusGetEmails: (status: string) =>
        ({
            type: 'ADMIN_SUBSCRIPTIONS_REDUCER/SET_STATUS_GET_EMAILS',
            status,
        } as const),
    setStatistics: (statistics: StatisticsType) =>
        ({
            type: 'ADMIN_SUBSCRIPTIONS_REDUCER/SET_STATISTICS',
            statistics,
        } as const),
    setEmailsHistory: (emails: EmailsHistoryType[], subscriptionId: string) =>
        ({
            type: 'ADMIN_SUBSCRIPTIONS_REDUCER/SET_EMAILS_HISTORY',
            emails,
            subscriptionId,
        } as const),
}

export const getSubscriptions =
    (
        page: number,
        pageSize: number,
        plan: SubscriptionPlansEnum | null
    ): ThunkActionType<ActionsTypes> =>
    async (dispatch) => {
        dispatch(actions.setStatusGetSubscriptions(STATUSES.LOADING))
        let now: string = dateFns.format(new Date(), 'yyyy-MM-dd')
        const subscriptionData = await subscriptionsAPI.getSubscriptions(
            page,
            pageSize,
            plan
        )
        const newSubscription = subscriptionData.docs.map((subscription) => {
            if (new Date(subscription.expirationDate) >= new Date(now)) {
                return {
                    ...subscription,
                    status: StatusSubscriptionEnum.Active,
                }
            } else {
                return {
                    ...subscription,
                    status: StatusSubscriptionEnum.Expired,
                }
            }
        })

        dispatch(
            actions.setStatistics({
                active: subscriptionData.statistics.active,
                expired: subscriptionData.statistics.expired,
                total: subscriptionData.statistics.total,
            })
        )
        dispatch(
            actions.setSubscriptions(
                newSubscription,
                subscriptionData.totalCount
            )
        )
        dispatch(actions.setStatusGetSubscriptions(STATUSES.SUCCESS))
    }

export const getEmailsHistory =
    (
        subscriptionId: string,
        userId: number,
        templateKey: string
    ): ThunkActionType<ActionsTypes> =>
    async (dispatch) => {
        dispatch(actions.setEmailsHistory([], subscriptionId))
        dispatch(actions.setStatusGetEmails(STATUSES.LOADING))
        const emailsHistoryData = await subscriptionsAPI.getEmailsHistory(
            userId,
            templateKey
        )
        dispatch(actions.setEmailsHistory(emailsHistoryData, subscriptionId))
        dispatch(actions.setStatusGetEmails(STATUSES.SUCCESS))
    }

//SELECTORS
const getAlSubscriptionsSel = (state: AppState): SubscriptionType[] =>
    state.adminSubscriptions.subscriptions

export const getAlSubscriptions = createSelector(
    [getAlSubscriptionsSel],
    (subscriptions) => {
        return subscriptions.sort((a, b) => {
            const dateA = new Date(a.expirationDate)
            const dateB = new Date(b.expirationDate)
            return dateA > dateB ? 1 : dateA < dateB ? -1 : 0
        })
    }
)

export default AdminSubscriptionsReducer
