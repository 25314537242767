import React, { useEffect } from 'react'
import { createStyles } from '@material-ui/core'
import { ExpertType } from 'BLL/types'
import { RolesTypeEnum, StatusExpertEnum } from 'BLL/enums'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'
import EditingItemPopUp from '../../utils/EditingItemPoUp'
import { getExpertFormData } from 'UI/utils/form-data'
import { useDispatch } from 'react-redux'
import {
    actions,
    addedExpert,
    changeStatusExpert,
    getExpertById,
    getExperts,
    updateExpert,
} from 'BLL/AdminExpertsCompilationReducer'
import { AppState } from 'BLL/Store'
import { Route, useHistory } from 'react-router-dom'
import { UpdateExpertModel } from 'DAL/CompilationAPI'
import { RenderUploadPhoto } from 'UI/common/RenderUploadPhoto'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Table from '../../common/table/Table'
import Header from '../../common/header/Header'
import { useRedirectCustomHook } from 'UI/common/hooks/useRedirectCustomHook'
import Button from '@material-ui/core/Button'
import { useSelector } from 'UI/common/hooks/redux-hooks'
import { AdminRM } from 'UI/routers/RouterManager'
import { AppSettings } from 'settings/appSettings'
import { getExpertsTableData } from 'UI/common/table/tableData/GetExpertsTableData'

export const ExpertsPage = () => {
    const { i18n } = useTranslation()
    const { adminExpertsCompilation, navigation, buttons } =
        i18n.getResourceBundle(i18n.language, 'common') as LanguageType

    const classes = useStyles()
    const dispatch = useDispatch()
    const history = useHistory()

    const experts = useSelector(
        (state: AppState) => state.adminExpertsCompilation.experts
    )
    const statusUpdate = useSelector(
        (state: AppState) => state.adminExpertsCompilation.statusUpdate
    )
    const expertStatusUpdate = useSelector(
        (state: AppState) => state.adminExpertsCompilation.expertStatusUpdate
    )
    const statusAddedExpert = useSelector(
        (state: AppState) => state.adminExpertsCompilation.statusAddedExpert
    )
    const expert = useSelector(
        (state: AppState) => state.adminExpertsCompilation.currentExpert
    )
    const { newExpertId } = useSelector(
        (state: AppState) => state.adminExpertsCompilation
    )

    const [imageFile, setImageFile] = React.useState<string>('')

    useEffect(() => {
        dispatch(getExperts())
    }, [dispatch])

    const handleChangeStatusExpert = (status: StatusExpertEnum, id: string) => {
        dispatch(changeStatusExpert(status, id))
    }

    const handleAddedExpert = () => {
        dispatch(addedExpert())
    }

    const handleUpdateExpert = (id: string, values: UpdateExpertModel) => {
        dispatch(updateExpert(id, { ...values, image: imageFile }))
    }

    const handleClear = () => {
        dispatch(actions.setCurrentExperts(null))
    }

    const handleGetExpertById = (id: string) => {
        dispatch(getExpertById(id))
    }

    useEffect(() => {
        if (newExpertId) {
            history.push(AdminRM.experts.getUrl.edit(newExpertId))
        }
        return () => {
            dispatch(actions.setNewExpertId(null))
        }
    }, [newExpertId, dispatch])

    return (
        <div>
            <Header title={navigation.experts} />
            {useRedirectCustomHook(RolesTypeEnum.COMPILATIONS_MANAGER)}
            <div>
                <div style={{ marginTop: 30 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        size={'small'}
                        onClick={handleAddedExpert}
                        style={{
                            background: AppSettings.api.getBaseColor(),
                            color: 'black',
                            fontWeight: 600,
                        }}
                    >
                        {buttons.ADD}
                    </Button>
                </div>
                <div className={classes.root}>
                    <div className={classes.tableWrapper}>
                        <Route
                            path={AdminRM.experts.path.edit}
                            render={() => (
                                <EditingItemPopUp<UpdateExpertModel>
                                    formData={getExpertFormData({ i18n })}
                                    status={statusAddedExpert}
                                    handleUpdateItem={handleUpdateExpert}
                                    loadItem={handleGetExpertById}
                                    item={expert}
                                    cancelRedirectUrl={
                                        AdminRM.experts.path.list
                                    }
                                    formTitle={adminExpertsCompilation.edit}
                                    clear={handleClear}
                                    renderComponent={
                                        <RenderUploadPhoto
                                            imageFile={imageFile}
                                            setImageFile={setImageFile}
                                        />
                                    }
                                />
                            )}
                        />
                        <div className={classes.tableWrapper}>
                            <Table<ExpertType>
                                items={experts}
                                title={''}
                                fields={getExpertsTableData(
                                    i18n,
                                    handleChangeStatusExpert,
                                    expertStatusUpdate,
                                    statusUpdate
                                )}
                            />
                        </div>
                    </div>
                </div>
                ;
            </div>
        </div>
    )
}

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            minWidth: '100%',
            paddingBottom: 20,
        },
        wrapper: {
            // margin: 20,
        },
        tableWrapper: {},
        toolbar: {
            display: 'flex',
            flexDirection: 'column',
        },
        red: {
            padding: 4,
            color: 'black',
            fontWeight: 700,
        },

        green: {
            background: '#77DD77',
            padding: 4,
            color: 'white',
            borderRadius: 3,
        },

        blue: {
            background: '#7fc7ff',
            padding: 4,
            color: 'white',
            borderRadius: 3,
        },
        detailPanel: {
            display: 'flex',
            justifyContent: 'center',
        },
        detail: {
            padding: 10,
        },
        detailHeader: {
            background: AppSettings.api.getBaseColor(),
            textAlign: 'center',
            color: 'white',
        },
        calendarWrapper: {
            textAlign: 'right',
            margin: 10,
        },

        buttonColor: {
            backgroundColor: 'black',
        },
    })
)
