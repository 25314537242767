import React, { useEffect } from 'react'
import { SubscriptionType } from 'BLL/types'
import { useTranslation } from 'react-i18next'
import Table from '../../common/table/Table'
import { getEmailHistoryTableData } from 'UI/common/table/tableData/GetEmailHistoryTableData'
import s from './email-detail.module.css'

interface IProps {
    subscription: SubscriptionType
    handleGetEmailsHistory: (
        subscriptionId: string,
        userId: number,
        templateKey: string
    ) => void
}

const EmailDetailTable = ({ subscription, handleGetEmailsHistory }: IProps) => {
    const { i18n } = useTranslation()

    useEffect(() => {
        handleGetEmailsHistory(
            subscription._id,
            subscription.userId,
            'subscription'
        )
    }, [handleGetEmailsHistory, subscription._id, subscription.userId])

    return (
        <div className={s.detailPanelWrapper}>
            <Table
                items={subscription.emailsHistory}
                fields={getEmailHistoryTableData(i18n, subscription)}
                title={''}
            />
        </div>
    )
}

export default EmailDetailTable
