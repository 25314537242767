import { apiCoreAxios, axiosCore, shopsAxiosAPI } from './createAxios'
import { BeatType, BaseResponse } from '../BLL/types'
import { RolesTypeEnum } from '../BLL/enums'
import {
    CouponStatus,
    CouponType,
    EditingShopType,
    ShopOwner,
    ShopsResponseType,
    ShopTypes,
    ShopTypesFull,
    UpdateCouponType,
} from '../BLL/shop-types'

type BeatmakerDetailsType = {
    isBeatmakerAccountExpired: boolean
}

export type UserRolesType = RolesTypeEnum

export type UserDetailsType = {
    userId: number
    userName: string
    photo: string
    roles: Array<UserRolesType>
    engMode: boolean
    beatmakerInfo: BeatmakerDetailsType
}

export const shopAPI = {
    //customerAdmin

    getShops: () =>
        shopsAxiosAPI.get<ShopTypes[]>('/shops').then((res) => res.data),
    getCoupons: () =>
        shopsAxiosAPI
            .get<CouponType[]>('/shops/coupons')
            .then((res) => res.data),
    getManagementCoupons: () =>
        shopsAxiosAPI
            .get<CouponType[]>('/management/shops/coupons')
            .then((res) => res.data),
    createCoupon: (shopId: number) =>
        shopsAxiosAPI
            .post<ShopsResponseType<CouponType>>(`shops/${shopId}/coupons`)
            .then((res) => res.data),
    deleteCoupon: (shopId: number, couponId: number) =>
        shopsAxiosAPI
            .delete<BaseResponse>(`shops/${shopId}/coupons/${couponId}`)
            .then((res) => res.data),
    editCoupon: (data: UpdateCouponType) =>
        shopsAxiosAPI
            .put<BaseResponse>(`shops/coupons/${data.id}`, { ...data })
            .then((res) => res.data),
    closeCoupon: (couponId: number) =>
        shopsAxiosAPI
            .put<ShopsResponseType<{ status: CouponStatus }>>(
                `shops/coupons/${couponId}/close`
            )
            .then((res) => res.data),
    leaveFeedback: (
        couponId: number,
        customerFeedback: string,
        customerShopRating: number
    ) =>
        shopsAxiosAPI
            .put<BaseResponse>(`shops/coupons/${couponId}/feedback`, {
                feedback: customerFeedback,
                shopRating: customerShopRating,
            })
            .then((res) => res.data),

    //shopAdmin

    me: () => shopsAxiosAPI.get<ShopOwner>('shop-owner/me').then((r) => r.data),
    getMyShop: () =>
        shopsAxiosAPI.get<ShopTypes>('shop-owner/my-shop').then((r) => r.data),
    getMyCoupons: () =>
        shopsAxiosAPI
            .get<CouponType[]>('shop-owner/coupons')
            .then((r) => r.data),
    approveCoupon: (couponId: number) =>
        shopsAxiosAPI
            .put<ShopsResponseType<{}>>(
                `shop-owner/coupons/${couponId}/approve`
            )
            .then((r) => r.data),
    rejectCoupon: (couponId: number) =>
        shopsAxiosAPI
            .put<ShopsResponseType<{}>>(`shop-owner/coupons/${couponId}/reject`)
            .then((r) => r.data),
    refactorCoupon: (couponId: number, note: string) =>
        shopsAxiosAPI
            .put<ShopsResponseType<{}>>(
                `shop-owner/coupons/${couponId}/need-refactoring`,
                { note }
            )
            .then((r) => r.data),

    //managerShopAdmin

    getAdminShops: () =>
        shopsAxiosAPI
            .get<ShopTypesFull[]>('/management/shops')
            .then((res) => res.data),
    getShopById: (shopId: number) => {
        return shopsAxiosAPI
            .get<ShopTypesFull>(`/management/shops/${shopId}`)
            .then((res) => res.data)
    },
    addShop: () => {
        return shopsAxiosAPI
            .post<BaseResponse<{ item: ShopTypesFull }>>('/management/shops')
            .then((res) => res.data)
    },

    updateShop: (shop: EditingShopType) => {
        return shopsAxiosAPI
            .put<BaseResponse<ShopTypesFull>>(`/management/shops`, shop)
            .then((res) => res.data)
    },

    getBeats: async (
        pageSize: number,
        page: number,
        term?: string
    ): Promise<IItemsResult<BeatType>> => {
        let response = await apiCoreAxios.get(
            `api/beats?pageSize=${pageSize}&page=${page}`,
            {
                params: {
                    term,
                },
            }
        )
        return {
            ...response.data,
            items: response.data.items.map((beat: any) => ({
                beatId: beat.id,
                beatTitle: beat.title,
            })),
        }
    },

    getBeatsNotPublished: async (): Promise<BeatType[]> => {
        let response = await apiCoreAxios.get(`api/beats/not-published`)
        return response.data.map((beat: any) => ({
            beatId: beat.id,
            beatTitle: beat.title,
        }))
    },

    getUserDetails: (): Promise<UserDetailsType> =>
        axiosCore
            .get<UserDetailsType>(`auth/auth/SecretGetUserDetails`)
            .then((r: any) => r.data),

    getUserLicenses: (beatmakerId: number, isEng: boolean) => {
        const lang = isEng ? 'en' : 'ru'
        return axiosCore
            .get<UserLicensesType<BeatLicenseType[]>>(
                `web/v1/licenses?beatmakerId=${beatmakerId}&eng=${isEng}`,
                {
                    headers: {
                        lang: `${lang}`,
                    },
                }
            )
            .then((r) => r.data)
    },

    uploadAudioFilesToServer(
        formData: any,
        onUploadProgress: (progressEvent: any) => void
    ) {
        return axiosCore.post('', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: onUploadProgress,
        })
    },

    getMoods(isEng: boolean) {
        const lang = isEng ? 'en' : 'ru'
        return axiosCore
            .get<UserLicensesType<MoodsType[]>>(`/web/v1/moods?eng=${isEng}`, {
                headers: {
                    lang: `${lang}`,
                },
            })
            .then((r) => r.data)
    },

    getStyles(isEng: boolean) {
        const lang = isEng ? 'en' : 'ru'
        return axiosCore
            .get<UserLicensesType<StylesType[]>>(
                `/web/v1/styles?eng=${isEng}`,
                {
                    headers: {
                        lang: `${lang}`,
                    },
                }
            )
            .then((r) => r.data)
    },
}

export type MoodsType = {
    title: string
    id: number
}

export type StylesType = {
    title: string
    id: number
    count: number
}

export type UserLicensesType<T> = {
    items: T
    count: number
}

export type BeatLicenseType = {
    _id: any
    title: string
    titleEn: string | null
    id: string
    price: number
    priceDollar: number | null
    beatId: number | null
    beatmakerId?: number
    recommended: boolean
    contract: boolean
    purchaseIsNotAvailable?: boolean
    requireClientPassport?: boolean
    removeBeatFromSale?: boolean
    createdDate: Date
}

export interface IItemsResult<T> {
    pagesCount: number
    page: number
    totalCount: number
    pageSize: number
    items: Array<T>
}
