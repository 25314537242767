import React from 'react'
import Popover from '@material-ui/core/Popover'
import Button from '@material-ui/core/Button'
import { STATUSES } from '../../../BLL/SupportingFile'
import Tooltip from '@material-ui/core/Tooltip'
import { StatusExpertEnum } from '../../../BLL/enums'
import { useTranslation } from 'react-i18next'
import { LanguageType } from '../../../translations/ru/common'

interface IProps {
    status: StatusExpertEnum
    changeStatusExpert: (status: StatusExpertEnum, id: string) => void
    id: string
    statusUpdate: string
    expertStatusUpdate: string
}

const ChangeStatusExpert = (props: IProps) => {
    const { i18n } = useTranslation()
    const { statusExpert, adminExpertsCompilation } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    )

    const { status, changeStatusExpert, statusUpdate, expertStatusUpdate } =
        props

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleChangeStatus = (status: StatusExpertEnum) => {
        changeStatusExpert(status, props.id)
        handleClose()
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    let style = {
        fontSize: 10,
        background: '#fff',
        color: 'black',
        width: '100%',
        opacity:
            expertStatusUpdate === props.id && statusUpdate === STATUSES.LOADING
                ? 0.3
                : 0.9,
    }

    switch (status) {
        case StatusExpertEnum.Active: {
            style = {
                ...style,
                background: '#77DD77',
            }
            break
        }
        case StatusExpertEnum.Paused: {
            style = {
                ...style,
                background: '#7fc7ff',
            }
            break
        }
        case StatusExpertEnum.Stopped: {
            style = {
                ...style,
                background: '#dd5758',
            }
            break
        }
    }

    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined

    return (
        <div>
            <Tooltip title={adminExpertsCompilation.edit}>
                <Button
                    aria-describedby={id}
                    variant="contained"
                    color="primary"
                    style={style}
                    onClick={handleClick}
                    disabled={
                        expertStatusUpdate === props.id &&
                        statusUpdate === STATUSES.LOADING
                    }
                >
                    {expertStatusUpdate === props.id &&
                    statusUpdate === STATUSES.LOADING
                        ? adminExpertsCompilation.loading
                        : statusExpert[props.status]}
                </Button>
            </Tooltip>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                {statusExpert.map((s: any, index: number) => {
                    let style = {
                        fontSize: 10,
                        background: '#fff',
                        color: 'black',
                        width: '100%',
                    }
                    switch (index) {
                        case StatusExpertEnum.Active: {
                            style = {
                                ...style,
                                background: '#77DD77',
                            }
                            break
                        }
                        case StatusExpertEnum.Paused: {
                            style = {
                                ...style,
                                background: '#7fc7ff',
                            }
                            break
                        }
                        case StatusExpertEnum.Stopped: {
                            style = {
                                ...style,
                                background: '#dd5758',
                            }
                            break
                        }
                    }
                    return (
                        <div
                            key={index}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                margin: 5,
                            }}
                        >
                            <Button
                                style={style}
                                onClick={() => handleChangeStatus(index)}
                            >
                                {s}
                            </Button>
                        </div>
                    )
                })}
            </Popover>
        </div>
    )
}
export default ChangeStatusExpert
