import React, { FC } from 'react'
import classes from 'UI/CustomerAdmin/Mobile/MobCouponsPage/mobCouponsPage.module.scss'
import { SearchField } from '../../../common/elements/textFields/search'
import { Button } from '../../../common/elements/buttons/Button'
import { CustomSelect } from '../../../common/elements/CustomSelect'
import { useTranslation } from 'react-i18next'
import { LanguageType } from '../../../../translations/ru/common'
import { useSelector } from 'react-redux'
import { AppState } from '../../../../BLL/Store'
import { RequestStatuses } from '../../../../BLL/AppReducer'
import { ShopSelectType } from '../../CustomerCoupons/CustomerCoupons'

type PropsType = {
    term: string
    setTerm: (term: string) => void
    control: any
    register: any
    selectOptions: ShopSelectType[]
    handleCreateCoupon: () => void
    setSelectShop: (shop: ShopSelectType) => void
    selectShop: ShopSelectType
}
export const MobCouponsPage: FC<PropsType> = ({
    selectOptions,
    handleCreateCoupon,
    control,
    term,
    setTerm,
    register,
    setSelectShop,
    selectShop,
}) => {
    const { i18n } = useTranslation()
    const {
        navigation,
        buttons,
        shopCoupon,
        adminExpertsCompilation,
        helpMessage,
    } = i18n.getResourceBundle(i18n.language, 'common') as LanguageType
    const requestStatus = useSelector(
        (state: AppState) => state.appReducer.requestStatus
    )
    const status = requestStatus === RequestStatuses.InProgress
    return (
        <div className={classes.MobCouponsPage}>
            <div className={classes.inputSize}>
                <SearchField
                    placeholder={helpMessage.search}
                    value={term}
                    onChange={({ target: { value } }) => setTerm(value)}
                />
            </div>
            <div className={classes.selectBox}>
                <label className={classes.selectDescr}>
                    {navigation.coupons}
                </label>
                <div className={classes.selectSize}>
                    <CustomSelect
                        control={control}
                        register={register}
                        options={selectOptions}
                        name={'shop'}
                        setValue={setSelectShop}
                    />
                </div>
            </div>
            <div className={classes.btnBoxAdd}>
                <Button
                    title={buttons.ADD_COUPON}
                    onClick={handleCreateCoupon}
                    disabled={status || !selectShop.value}
                />
            </div>
        </div>
    )
}
