import React from 'react'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'
import { CustomerRM } from 'UI/routers/RouterManager'
import { NavLink } from 'react-router-dom'
import { FreeBeatType } from 'DAL/BeatSettingsAPI'
import EditIcon from 'UI/assets/icons/edit.svg'
import DeleteIcon from 'UI/assets/icons/delete.svg'
import s from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/beatItem/beatItem.module.scss'

type Props = {
    beat: FreeBeatType
}
export const MobileMenuItem = ({ beat }: Props) => {
    const { i18n } = useTranslation()
    const { buttons } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType
    return (
        <div className={s.tooltip}>
            <div className={s.triangle} />
            <div className={s.description}>
                <ul className={s.actionsList}>
                    <li>
                        <NavLink
                            to={CustomerRM.freeBeats.getUrl.beats.edit(beat.id)}
                        >
                            <img src={EditIcon} alt="" />
                            {buttons.EDIT}
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to={CustomerRM.freeBeats.getUrl.beats.delete(
                                beat.id
                            )}
                        >
                            <img src={DeleteIcon} alt="" />
                            {buttons.DELETE}
                        </NavLink>
                    </li>
                </ul>
            </div>
        </div>
    )
}
