import { CouponCurrency, CouponStatus, CouponType } from 'BLL/shop-types'
import { LanguageType } from 'translations/ru/common'
import { Status, StatusType } from 'UI/common/status/Status'
import RubIcon from 'UI/assets/icons/rub.svg'
import UsdIcon from 'UI/assets/icons/usd.svg'
import { getLocaleDateString } from 'UI/utils/util-types'
import Tooltip from '@material-ui/core/Tooltip'
import { NavLink } from 'react-router-dom'
import { CustomerRM } from 'UI/routers/RouterManager'
import { Button as MyButton } from 'UI/common/elements/buttons/Button'
import EditIcon from 'UI/assets/icons/edit.svg'
import DeleteIcon from 'UI/assets/icons/delete.svg'
import React from 'react'
import { TableType } from '../types'
import { i18n } from 'i18next'
import s from 'UI/common/table/tableData.module.scss'

export const getCouponsTableData = (
    i18n: i18n,
    editType: 'coupons' | 'owner'
): TableType<CouponType> => {
    const { shopCoupon, statuses, buttons } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType
    return [
        {
            title: shopCoupon.tableColumns.coupon,
            field: 'code',
            render: (coupon) => (
                <div className={s.getCouponsTableDataCode}>{coupon.code}</div>
            ),
        },
        {
            title: shopCoupon.tableColumns.status,
            field: 'status',
            render: (coupon) => {
                const status: { [key: number]: StatusType } = {
                    0: 'NotActive',
                    1: 'Waiting',
                    2: 'Rejected',
                    3: 'Clarification',
                    4: 'Confirmed',
                }
                return <Status status={status[coupon.status] as StatusType} />
            },
        },

        {
            title: shopCoupon.tableColumns.price,
            field: 'price',
            render: (coupon) => {
                const currency =
                    coupon.currency === CouponCurrency.RUB ? RubIcon : UsdIcon
                return (
                    <div className={s.getCouponsTableDataPrice}>
                        {coupon.price ? (
                            <>
                                <img src={currency} alt={'currency'} />
                                <span>{coupon.price}</span>
                            </>
                        ) : (
                            '-'
                        )}
                    </div>
                )
            },
        },
        {
            title: shopCoupon.tableColumns.addedDate,
            field: 'addedDate',
            defaultSort: 'desc',
            render: (coupon) => (
                <div>
                    {getLocaleDateString(i18n.language, coupon.addedDate)}
                </div>
            ),
        },
        {
            title: shopCoupon.tableColumns.purchaseDate,
            field: 'purchaseDate',
            render: (coupon) => (
                <div>
                    {coupon.purchaseDate
                        ? getLocaleDateString(
                              i18n.language,
                              coupon.purchaseDate
                          )
                        : '-'}
                </div>
            ),
        },

        {
            title: shopCoupon.tableColumns.action,
            field: 'id',
            render: (coupon) => (
                <div className={s.getCouponsTableDataAction}>
                    {((coupon.status === CouponStatus.NotActive &&
                        editType === 'coupons') ||
                        (coupon.status === CouponStatus.SpecifyInformation &&
                            editType === 'coupons') ||
                        (coupon.status === CouponStatus.Approve &&
                            editType === 'coupons') ||
                        (coupon.status === CouponStatus.Pending &&
                            editType === 'owner')) && (
                        <Tooltip title={shopCoupon.edit}>
                            <NavLink
                                to={CustomerRM.shops[editType].edit.getUrl(
                                    coupon.id
                                )}
                            >
                                {coupon.customerFeedback ? (
                                    <MyButton
                                        view={'Details'}
                                        title={buttons.DETAILS}
                                    />
                                ) : (
                                    <img
                                        src={EditIcon}
                                        alt={'edit'}
                                        className={s.icons}
                                    />
                                )}
                            </NavLink>
                        </Tooltip>
                    )}
                    {editType === 'coupons' &&
                        coupon.status === CouponStatus.NotActive && (
                            <Tooltip title={shopCoupon.deletePopUpTitle}>
                                <NavLink
                                    to={CustomerRM.shops.coupons.delete.getUrl(
                                        coupon.id
                                    )}
                                >
                                    <img
                                        src={DeleteIcon}
                                        alt={'delete'}
                                        className={s.icons}
                                    />
                                </NavLink>
                            </Tooltip>
                        )}
                </div>
            ),
        },
    ]
}
