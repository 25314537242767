import React, { FC, useCallback } from 'react'
import Contracts from './Contracts/Contracts'
import { useDispatch } from 'react-redux'
import { getContractsById, updateContract } from '../../../BLL/ContractsReducer'
import { useTranslation } from 'react-i18next'
import { BeatmakerContractsType } from '../../../BLL/types'
import { Route } from 'react-router-dom'
import { LanguageEnum, RolesTypeEnum } from '../../../BLL/enums'
import { LanguageType } from '../../../translations/ru/common'
import { useRedirectCustomHook } from '../../common/hooks/useRedirectCustomHook'
import { CustomerRM } from '../../routers/RouterManager'
import { CustomerMyLicenses } from './CustomerMyLicenses/CustomerMyLicenses'
import { CustomerMyContracts } from './CustomerMyContracts/CustomerMyContracts'

interface IProps {}

const ContractsLicensesPage: FC<IProps> = () => {
    const dispatch = useDispatch()
    const { i18n } = useTranslation()
    const { navigation } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    const handleGetContractById = useCallback(
        (contractId: string, lang: LanguageEnum) => {
            dispatch(getContractsById(contractId, lang))
        },
        [dispatch, getContractsById]
    )

    const handleUpdateContract = useCallback(
        (contract: BeatmakerContractsType) => {
            dispatch(updateContract(contract))
        },
        [dispatch, updateContract]
    )

    return (
        <div>
            {useRedirectCustomHook(RolesTypeEnum.BEATMAKER)}
            <Route
                exact
                path={CustomerRM.contracts.path.contracts.edit}
                render={() => (
                    <CustomerMyContracts
                        handleGetContractById={handleGetContractById}
                        handleUpdateContract={handleUpdateContract}
                    />
                )}
            />
            <Route
                exact
                path={[
                    CustomerRM.contracts.path.license.base,
                    CustomerRM.contracts.path.license.delete,
                ]}
                render={() => <Contracts />}
            />
            <Route
                exact
                path={CustomerRM.contracts.path.license.edit}
                render={() => (
                    <div style={{ position: 'relative' }}>
                        <CustomerMyLicenses />
                    </div>
                )}
            />
        </div>
    )
}
export default ContractsLicensesPage
