import withStyles from '@material-ui/core/styles/withStyles'
import { CheckboxProps } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import React from 'react'

export const CustomCheckbox = withStyles({
    root: {
        '&.Mui-disabled': {
            color: 'gray',
        },
        color: '#FFFFFF',
        opacity: '50%',
        padding: '0 9px 0 9px',
        '&$checked': {
            opacity: '1',
            color: '#09BB56',
        },
        fontSize: 12,
    },
    checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />)
