import { Dispatch } from 'redux'
import { actions as appActions, RequestStatuses } from './AppReducer'
import { BaseResponse, ResultCodesEnum } from './types'
import { CONSTANTS } from './SupportingFile'
import { BaseWidgetResponse } from 'DAL/WidgetsAPI'

export const withTryCatch =
    (operation: any, dispatch: any, customDispatch?: any) => async () => {
        try {
            return await operation()
        } catch (error) {
            if (error.response?.data) {
                dispatch(appActions.showSnackbar('error', error.response.data))
            } else {
                dispatch(appActions.showSnackbar('error', error.message))
            }
            dispatch(appActions.setRequestStatus(RequestStatuses.Error))
            customDispatch && customDispatch()
        }
        return null
    }
export const withHandlingErrorResultCode = function <T>(
    operation: any,
    dispatch: any,
    isSuccess?: boolean
) {
    return async () => {
        let result: BaseResponse<T> = await operation()
        if (result && result?.resultCode === ResultCodesEnum.Error) {
            const messages = getErrorMessages(result)
            messages.forEach((message) =>
                dispatch(appActions.showSnackbar('error', message))
            )
        }
        if (isSuccess && result?.resultCode === ResultCodesEnum.Success) {
            dispatch(
                appActions.showSnackbar('success', CONSTANTS.SUCCESS_MESSAGE)
            )
        }
        return result
    }
}

export const withProcessVisualization = function (
    operation: any,
    dispatch: any
) {
    return async () => {
        dispatch(appActions.setRequestStatus(RequestStatuses.InProgress))
        const result = await operation()
        dispatch(appActions.setRequestStatus(RequestStatuses.Success))
        return result
    }
}
export const commonAsyncHandler = function (
    operation: () => void,
    dispatch: Dispatch,
    isSuccess?: boolean
) {
    let handledErrorResultCode = withHandlingErrorResultCode(
        operation,
        dispatch,
        isSuccess
    )
    let tryCatched = withTryCatch(handledErrorResultCode, dispatch)
    let visualized = withProcessVisualization(tryCatched, dispatch)
    return visualized()
}

const getErrorMessages = <T>(result: BaseResponse<T>): string[] => {
    const messages = []
    if (result.messages2?.length) {
        result.messages2.forEach((error) => {
            if (error.field) {
                messages.push(`${error.field}: ${error.message}`)
            } else if (error.message) {
                return messages.push(error.message)
            }
        })
    } else if (result.messages?.length) {
        result.messages.forEach((error: any) => {
            if (typeof error === 'string') {
                messages.push(`${error}`)
            } else {
                messages.push(`${error.field}: ${error.message}`)
            }
        })
    } else if (result.message) {
        messages.push(result.message)
    } else {
        messages.push(CONSTANTS.ERROR_MESSAGE)
    }

    return messages
}

export const completeOperation = <T = null>(
    response: BaseResponse<T> | BaseWidgetResponse<T>,
    dispatch: Dispatch,
    successOperation?: any
) => {
    if (response.resultCode === ResultCodesEnum.Success) {
        dispatch(appActions.showSnackbar('success', CONSTANTS.SUCCESS_MESSAGE))
        successOperation && successOperation()
    }
}

export const verifyApiResultResponse = <T = null>(
    response: BaseResponse<T>,
    dispatch: Dispatch,
    successOperation?: any
) => {
    if (response.resultCode === ResultCodesEnum.Success) {
        dispatch(appActions.showSnackbar('success', CONSTANTS.SUCCESS_MESSAGE))
        successOperation && successOperation()
    } else {
        if (response.messages.length) {
            throw new Error(response.messages[0].toString())
        }
    }
}

// Old version
const _getErrorMessages = <T>(result: BaseResponse<T>): string[] => {
    const messages = []
    if (result.messages2?.length) {
        result.messages2.forEach((error) => {
            if (error.field) {
                messages.push(`${error.field}: ${error.message}`)
            } else if (error.message) {
                return messages.push(error.message)
            }
        })
    } else if (result.messages?.length) {
        result.messages.forEach((error: any) =>
            messages.push(`${error}: ${error.message}`)
        )
    } else if (result.message) {
        messages.push(result.message)
    } else {
        messages.push(CONSTANTS.ERROR_MESSAGE)
    }

    return messages
}
