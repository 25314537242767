import React from 'react'
import { useTranslation } from 'react-i18next'
import { getLocaleDateString } from 'UI/utils/util-types'

type Props = {
    date: null | Date | string | undefined
    title: string
}

export const BeatLabeledDate = ({ date, title }: Props) => {
    const { i18n } = useTranslation()

    return (
        <>
            {date && (
                <span>
                    {`${title}: ${getLocaleDateString(i18n.language, date)}`}
                </span>
            )}
        </>
    )
}
