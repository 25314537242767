import React, { useState, useEffect } from 'react'
import PlayerBackground from '../../../assets/icons/player-background.svg'
import Player from '../../../assets/icons/player.svg'
import { usePlayer } from '../../../../Player/Hooks/Hooks'

export const Equalizer = () => {
    const [player, isPlayingMe] = usePlayer(true, null)
    return (
        <div>
            <svg
                width="100%"
                height="106"
                viewBox="0 0 945 106"
                xmlns="http://www.w3.org/2000/svg"
            >
                <image xlinkHref={PlayerBackground} />
                <defs>
                    <mask id="mask-1" x="0" y="0" width="100%" height="105">
                        <image xlinkHref={Player} />
                    </mask>
                </defs>
                <rect
                    width={player.playingProgressPercent * 9.45}
                    height="105"
                    fill="#3598DB"
                    mask="url(#mask-1)"
                />
            </svg>
        </div>
    )
}
