import { AppState } from 'BLL/Store'

export const selectWallet = (state: AppState) => state.walletsReducer.wallets
export const selectAddCard = (state: AppState) =>
    state.walletsReducer.redirectUrl

export const selectCards = (state: AppState) => state.walletsReducer.cards

export const getEnotNumber = (state: AppState): string | undefined =>
    state.walletsReducer.wallets?.enotNumber

export const getEnotSecret = (state: AppState): string | undefined =>
    state.walletsReducer.wallets?.enotSecret

export const getEnotApiSecretKey = (state: AppState): string | undefined =>
    state.walletsReducer.wallets?.enotApiSecretKey

export const getHtmlEnotFileName = (state: AppState): string | undefined =>
    state.walletsReducer.wallets?.htmlEnotFileName

export const getQiwip2pSecretKey = (state: AppState): string | undefined =>
    state.walletsReducer.wallets?.qiwiP2PPrivateKey

export const getQiwip2pPublicKey = (state: AppState): string | undefined =>
    state.walletsReducer.wallets?.qiwiP2PPublickKey
