import { beatsPromotionAxios } from './createAxios'

export enum CurrencyEnum {
    rub = 'RUB',
    usd = 'USD',
}

export enum StatusCashbackType {
    open = 'open',
    closed = 'closed',
}

export type CashbackType = {
    _id: any
    userId: number
    sourcePaymentId: number
    amount: number
    currency: CurrencyEnum
    expirationDate: string
    paymentDate: string
    status: StatusCashbackType
    closedAmount: number
    closedPaymentsIds: number[]
}

export const cashbackAPI = {
    getCashbacks() {
        return beatsPromotionAxios
            .get<CashbackType[]>(`/cashbacks`)
            .then((r) => r.data)
    },
    getCashbackAmount(currency: CurrencyEnum) {
        return beatsPromotionAxios
            .get<{ amount: number }>(`/cashbacks/active/${currency}`)
            .then((r) => r.data)
    },
}
