import React, { FC } from 'react'
import classes from './MySalesPrice.module.scss'
import rub from '../../../assets/icons/rub.svg'
import usd from '../../../assets/icons/usd.svg'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { LanguageType } from '../../../../translations/ru/common'
import { AppSettings } from 'settings/appSettings'

export type MySalesPricePropsType = {
    beatsCount: Number
    usdPaid: string
    usdComission: string
    usdReceived: string
    usdAmounts: string
    rubPaid: string
    rubComission: string
    rubReceived: string
    rubAmounts: string
}

export const MySalesPrice = (props: MySalesPricePropsType) => {
    const { i18n } = useTranslation()
    const { mySales } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType
    const isEng = AppSettings.api.isEngVersion()
    return (
        <div className={classes.salesTable}>
            <div className={classes.salesTableHeader}>
                <h3 className={classes.salesTableTitle}>
                    {mySales.statistics.fields.itemsSold}
                </h3>
                <h3 className={classes.salesTableTitle}>
                    {mySales.statistics.fields.gross}
                </h3>
                <h3 className={classes.salesTableTitle}>
                    {mySales.statistics.fields.serviceFee}
                </h3>
                <h3 className={classes.salesTableTitle}>
                    {mySales.statistics.fields.total}
                </h3>
                <h3 className={classes.salesTableTitle}>
                    {mySales.statistics.fields.conclusion}
                </h3>
            </div>
            <div className={classes.wrapper}>
                <span>{props.beatsCount}</span>
                <div className={classes.saleTableCellUSD}>
                    <span className={classes.usdPrice}>
                        <img src={usd} alt="usd" /> {props.usdPaid}
                    </span>
                    <span className={classes.usdPrice}>
                        <img src={usd} alt="usd" />
                        {props.usdComission}
                    </span>
                    <span className={classes.usdPrice}>
                        <img src={usd} alt="usd" />
                        {props.usdReceived}
                    </span>
                    <span className={classes.usdPrice}>
                        <img src={usd} alt="usd" />
                        {props.usdAmounts}
                    </span>
                </div>
                {!isEng && (
                    <div className={classes.saleTableCellRub}>
                        <span className={classes.rubPrice}>
                            <img src={rub} alt="rub" />
                            {props.rubPaid}
                        </span>
                        <span className={classes.rubPrice}>
                            <img src={rub} alt="rub" />
                            {props.rubComission}
                        </span>
                        <span className={classes.rubPrice}>
                            <img src={rub} alt="rub" />
                            {props.rubReceived}
                        </span>
                        <span className={classes.rubPrice}>
                            <img src={rub} alt="rub" />
                            {props.rubAmounts}
                        </span>
                    </div>
                )}
            </div>
        </div>
    )
}
