import { axiosCore } from './createAxios'
import { BaseResponse } from 'BLL/types'

export const CoinsAPI = {
    prefixURL: '/beatsManagement/beatsManagement',

    async getBalance(): Promise<BalanceType> {
        return axiosCore
            .get<{ data: BalanceType }>(`${this.prefixURL}/balance`)
            .then((r) => r.data.data)
    },

    async getHistoryCoins(): Promise<CoinsHistoryType[]> {
        return axiosCore
            .get<CoinsHistoryType[]>(`${this.prefixURL}/coinsHistory`)
            .then((r) => r.data)
    },

    async paidCoins(amount: number) {
        return axiosCore
            .post<BaseResponse<PaidCoins>>(`/default/payment/BuyCoinsPayment`, {
                amount,
            })
            .then((r) => r.data)
    },

    async getBonusCoins(coins: number) {
        return axiosCore
            .get<BaseResponse<BonusCoinsType>>(
                `/default/Payment/GetOverallCoinsPriceAndBonusCoins?coins=${coins}`
            )
            .then((r) => r.data)
    },
}

export type PaidCoins = {
    redirectUrl: string
}

export type BalanceType = {
    coins: number
}

export type CoinsHistoryType = {
    id: number
    addedDate: Date
    description: string
    addedMoney: number
    moneyBefore: number
    moneyAfter: number
    beatmakerId: number
    beatmakerName: string
    realMoney: number
}

export type BonusCoinsType = {
    totalPrice: number
    bonusCoins: number
    defaultPrice: number
    promoSettings: {
        priceInRub: number
        priceInUsd: number
        promoEndDate: string
        isActive: boolean
    }
}
