import {
    EmailTemplateType,
    NullableType,
    ResultCodesEnum,
    ThunkActionType,
} from './types'
import { InferActionsTypes } from './Store'
import {
    CreateTemplateDto,
    templatesAPI,
    UpdateTemplateDto,
} from '../DAL/TemplatesAPI'
import { commonAsyncHandler } from './common-async-handler'
import {
    actions as appActions,
    AuthActionsTypes,
    EditingEntityStatuses,
} from './AppReducer'
import { CONSTANTS } from './SupportingFile'

const initState = {
    templates: [] as EmailTemplateType[],
    currentTemplate: null as NullableType<EmailTemplateType>,
    htmlToRender: 'null',
}

type IStateType = typeof initState
export type ActionsTypes = InferActionsTypes<typeof actions> | AuthActionsTypes

const AdminTemplatesEmailReducer = (
    state = initState,
    action: ActionsTypes
): IStateType => {
    switch (action.type) {
        case 'ADMIN_TEMPLATES_EMAIL_REDUCER/SET_CURRENT_TEMPLATE':
            return {
                ...state,
                currentTemplate: action.template,
            }
        case 'ADMIN_TEMPLATES_EMAIL_REDUCER/SET_ADD_HTML_TEMPLATE':
            return {
                ...state,
                templates: [...state.templates, action.template],
            }
        case 'ADMIN_TEMPLATES_EMAIL_REDUCER/SET_TEMPLATES':
            return {
                ...state,
                templates: action.templates,
            }
        case 'ADMIN_TEMPLATES_EMAIL_REDUCER/DELETE_TEMPLATE':
            return {
                ...state,
                templates: state.templates.filter(
                    (template) => template._id !== action.id
                ),
            }
        case 'ADMIN_TEMPLATES_EMAIL_REDUCER/SET_HTML_TO_RENDER':
            return {
                ...state,
                htmlToRender: action.htmlToRender,
            }
        default:
            return state
    }
}

export const actions = {
    setCurrentTemplate: (template: EmailTemplateType | null) =>
        ({
            type: 'ADMIN_TEMPLATES_EMAIL_REDUCER/SET_CURRENT_TEMPLATE',
            template,
        } as const),
    setEmailTemplate: (template: EmailTemplateType) =>
        ({
            type: 'ADMIN_TEMPLATES_EMAIL_REDUCER/SET_ADD_HTML_TEMPLATE',
            template,
        } as const),
    setEmailTemplates: (templates: EmailTemplateType[]) =>
        ({
            type: 'ADMIN_TEMPLATES_EMAIL_REDUCER/SET_TEMPLATES',
            templates,
        } as const),
    deleteTemplate: (id: string) =>
        ({
            type: 'ADMIN_TEMPLATES_EMAIL_REDUCER/DELETE_TEMPLATE',
            id,
        } as const),
    setHtmlToRender: (htmlToRender: string) =>
        ({
            type: 'ADMIN_TEMPLATES_EMAIL_REDUCER/SET_HTML_TO_RENDER',
            htmlToRender,
        } as const),
}

export const updateTemplate =
    (updateTemplateDto: UpdateTemplateDto): ThunkActionType<ActionsTypes> =>
    async (dispatch) => {
        await commonAsyncHandler(async () => {
            const result = await templatesAPI.updateTemplate(updateTemplateDto)
            if (result.resultCode === ResultCodesEnum.Success) {
                dispatch(actions.setCurrentTemplate(result.data))
                dispatch(
                    appActions.showSnackbar(
                        'success',
                        CONSTANTS.SUCCESS_MESSAGE
                    )
                )
                dispatch(getHtmlToRender(updateTemplateDto.id))
            }
            return result
        }, dispatch)
    }

export const createEmailTemplate =
    (createTemplateDto: CreateTemplateDto): ThunkActionType<ActionsTypes> =>
    async (dispatch) => {
        await commonAsyncHandler(async () => {
            const templateData = await templatesAPI.createEmailTemplate(
                createTemplateDto
            )
            if (templateData.resultCode === ResultCodesEnum.Success) {
                dispatch(actions.setEmailTemplate(templateData.data))
                dispatch(
                    appActions.showSnackbar(
                        'success',
                        CONSTANTS.SUCCESS_MESSAGE
                    )
                )
                dispatch(
                    appActions.setEditingEntityStatus(
                        EditingEntityStatuses.Success
                    )
                )
            } else {
                dispatch(
                    appActions.showSnackbar(
                        'error',
                        templateData.messages[0] as string
                    )
                )
            }
            return templateData
        }, dispatch)
    }

export const getTemplateById =
    (id: string): ThunkActionType<ActionsTypes> =>
    async (dispatch) => {
        await commonAsyncHandler(async () => {
            const templateData = await templatesAPI.getTemplateById(id)
            dispatch(actions.setCurrentTemplate(templateData))
            return templateData
        }, dispatch)
    }

export const getHtmlToRender =
    (id: string): ThunkActionType<ActionsTypes> =>
    async (dispatch) => {
        await commonAsyncHandler(async () => {
            const htmlToRender = await templatesAPI.getHtmlToRender(id)
            dispatch(actions.setHtmlToRender(htmlToRender))
            return htmlToRender
        }, dispatch)
    }

export const getTemplatesAll =
    (): ThunkActionType<ActionsTypes> => async (dispatch) => {
        await commonAsyncHandler(async () => {
            const templates = await templatesAPI.getTemplatesAll()
            dispatch(actions.setEmailTemplates(templates))
            return templates
        }, dispatch)
    }

export const deleteTemplateById =
    (id: string): ThunkActionType<ActionsTypes> =>
    async (dispatch) => {
        await commonAsyncHandler(async () => {
            const result = await templatesAPI.deleteTemplateById(id)
            if (result.resultCode === ResultCodesEnum.Success) {
                dispatch(
                    appActions.setEditingEntityStatus(
                        EditingEntityStatuses.Success
                    )
                )
                dispatch(actions.deleteTemplate(id))
                dispatch(
                    appActions.showSnackbar(
                        'success',
                        CONSTANTS.SUCCESS_MESSAGE
                    )
                )
            }
            return result
        }, dispatch)
    }

export default AdminTemplatesEmailReducer
