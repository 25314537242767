import React, { useState } from 'react'
import classes from './MySalesOrder.module.scss'
import { Button } from '../../../../../common/elements/buttons/Button'
import { useSelector } from '../../../../../common/hooks/redux-hooks'
import { useParams } from 'react-router'
import { getLocaleDateFullString } from '../../../../../utils/util-types'
import { useTranslation } from 'react-i18next'
import { LanguageType } from '../../../../../../translations/ru/common'
import { AppSettings } from 'settings/appSettings'
import { ConfirmExchangeDialog } from '../../../../CustomerMySales/confirm-exchange'

export const MySalesOrder = () => {
    const { id } = useParams<{ id: string }>()
    const [isConfirmExchange, setIsConfirmExchange] = useState(false)
    const { i18n } = useTranslation()
    const { myBeats, buttons, mySales } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType
    const rootPayment = useSelector((state) =>
        state.mySales.rootPayments.find((p) => p.id === +id)
    )
    const isEng = AppSettings.api.isEngVersion()
    const statusRu = 'Средства выведены'
    return (
        <div className={classes.MySalesOrder}>
            <div className={classes.order}>
                <span className={classes.saleID}>
                    {mySales.saleDetails.saleId}: #{id}
                </span>
                {rootPayment?.sales[0].paidDate ? (
                    <span className={classes.label}>
                        {isEng ? 'Funds Withdrawn' : statusRu}
                    </span>
                ) : (
                    <span className={classes.label}>
                        <Button
                            view={'Outline'}
                            title={'Обменять на монеты'}
                            onClick={() => setIsConfirmExchange(true)}
                        />
                    </span>
                )}
            </div>
            <span className={classes.paidDate}>
                {mySales.saleDetails.paid}{' '}
                {rootPayment?.confirmDate &&
                    getLocaleDateFullString(
                        i18n.language,
                        rootPayment.confirmDate
                    )}
            </span>
            <div className={classes.customer}>
                <div className={classes.item}>
                    <span className={classes.itemTitle}>
                        {mySales.saleDetails.buyer}:
                    </span>
                    <a
                        className={classes.itemLink}
                        href={`${AppSettings.urls.MAIN_DOMAIN}beatmaker/${rootPayment?.sales[0].buyerId}`}
                        target={'_blank'}
                        rel="noreferrer"
                    >
                        {rootPayment?.sales[0].buyerName}
                    </a>
                </div>
                <div className={classes.item}>
                    <span className={classes.itemTitle}>
                        {mySales.saleDetails.emailBuyer}:
                    </span>
                    <span className={classes.itemValue}>
                        {rootPayment?.sales[0].buyerEmail}
                    </span>
                </div>
                <div className={classes.item}>
                    <span className={classes.itemTitle}>
                        {mySales.saleDetails.fullName}:
                    </span>
                    <span className={classes.itemValue}>
                        {rootPayment?.sales[0].clientFullname}
                    </span>
                </div>
            </div>
            <ConfirmExchangeDialog
                open={isConfirmExchange}
                onClose={setIsConfirmExchange}
            />
        </div>
    )
}
