import React, { useCallback } from 'react'
import { BeatLicenseType } from 'DAL/Services'
import { FilesBindedToLicensesType } from 'DAL/BeatSettingsAPI'
import { useTranslation } from 'react-i18next'
import { LanguageType } from 'translations/ru/common'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
    bindFileForLicense,
    unbindFileForLicense,
} from 'BLL/CustomerMyBeatsReducer'
import { AppSettings } from 'settings/appSettings'
import { CustomizedSwitches } from 'UI/common/elements/switch/Switch'
import { LicenseInput } from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/beatSettings/licenseInput/LicenseInput'
import { FormControlLabel } from '@material-ui/core'
import { CustomCheckbox } from 'UI/common/elements/CustomCheckbox'
import { FileItem } from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/beatSettings/trackLicenses/FileItem'
import s from 'UI/CustomerAdmin/MyBeatsPage/PaidBeats/beatSettings/trackLicenses/trackLicense.module.scss'

type Props = {
    license: BeatLicenseType
    bindingsLicense: BeatLicenseType[]
    beatLicenseNotDefaultPrice: BeatLicenseType[]
    handleChangeBindLicensePrice: (
        price: number,
        licId: string,
        isDollar?: boolean
    ) => void
    handleSelectLicense: (license: BeatLicenseType, checked: boolean) => void
    handleChangeDefaultLicensePrice: (
        lic: BeatLicenseType,
        checked: boolean,
        priceDollar: number | null,
        price: number | null
    ) => void
    filesBindedToLicenses: FilesBindedToLicensesType | null
}
export const LicenseItem = ({
    license,
    bindingsLicense,
    beatLicenseNotDefaultPrice,
    handleChangeBindLicensePrice,
    handleSelectLicense,
    handleChangeDefaultLicensePrice,
    filesBindedToLicenses,
}: Props) => {
    const { i18n } = useTranslation()
    const { myBeats } = i18n.getResourceBundle(
        i18n.language,
        'common'
    ) as LanguageType

    const selectBindingLicense = bindingsLicense.find(
        (l) => l.id === license.id
    )
    const selectLicenseNotDefaultPrice = beatLicenseNotDefaultPrice.find(
        (l) => l.id === license.id
    )
    const handleOnChangeBindLicense = (checked: boolean) => {
        handleSelectLicense(license, checked)
    }
    const { beatId } = useParams<{ beatId: string }>()

    const dispatch = useDispatch()

    const handleOnChangeBindFiles = useCallback(
        (fileId: number, isBind: boolean) => {
            isBind
                ? dispatch(bindFileForLicense(fileId, license.id, +beatId))
                : dispatch(unbindFileForLicense(fileId, license.id, +beatId))
        },
        [beatId, license]
    )
    const isEng = AppSettings.api.isEngVersion()

    return (
        <div className={s.contentWrapper}>
            <div className={s.licenseName}>
                <p>{isEng ? license.titleEn : license.title}</p>
                <CustomizedSwitches
                    checked={!!selectBindingLicense}
                    onChange={handleOnChangeBindLicense}
                />
            </div>
            <div
                className={
                    selectBindingLicense
                        ? s.filesWrapper
                        : `${s.filesWrapper} ${s.contentWrapperBlur}`
                }
            >
                <p className={s.licenseDescr}>
                    {myBeats.beatSettings.licenseFiles}
                </p>
                {filesBindedToLicenses &&
                    filesBindedToLicenses.files.map((file) => {
                        const exist =
                            file.selectedLicenses.indexOf(license.id) > -1
                        return (
                            <FileItem
                                key={file.id}
                                file={file}
                                exist={exist}
                                handleOnChangeBindFiles={
                                    handleOnChangeBindFiles
                                }
                            />
                        )
                    })}
            </div>
            <div
                className={
                    selectBindingLicense
                        ? s.costWrapper
                        : `${s.costWrapper} ${s.contentWrapperBlur}`
                }
            >
                <div className={s.cost}>{myBeats.beatSettings.cost}</div>
                <div className={s.costWrapperInput}>
                    <LicenseInput
                        eng={true}
                        value={
                            selectLicenseNotDefaultPrice
                                ? selectLicenseNotDefaultPrice.priceDollar
                                : selectBindingLicense
                                ? selectBindingLicense.priceDollar
                                : license.priceDollar
                        }
                        onChange={(value) =>
                            handleChangeBindLicensePrice(
                                +value,
                                license.id,
                                true
                            )
                        }
                        disabled={!selectLicenseNotDefaultPrice}
                    />
                    {!isEng && (
                        <LicenseInput
                            value={
                                selectLicenseNotDefaultPrice
                                    ? selectLicenseNotDefaultPrice.price
                                    : selectBindingLicense
                                    ? selectBindingLicense.price
                                    : license.price
                            }
                            onChange={(value) =>
                                handleChangeBindLicensePrice(
                                    +value,
                                    license.id,
                                    false
                                )
                            }
                            disabled={!selectLicenseNotDefaultPrice}
                        />
                    )}
                </div>
                <FormControlLabel
                    control={
                        <CustomCheckbox
                            name={
                                selectBindingLicense
                                    ? selectBindingLicense.titleEn ||
                                      selectBindingLicense.title
                                    : ''
                            }
                            checked={!!selectLicenseNotDefaultPrice}
                            onChange={({ target: { checked } }) =>
                                handleChangeDefaultLicensePrice(
                                    license,
                                    checked,
                                    license.priceDollar,
                                    license.price
                                )
                            }
                        />
                    }
                    label={myBeats.beatSettings.changeCost}
                    labelPlacement={'end'}
                />
            </div>
        </div>
    )
}
